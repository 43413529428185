import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {accountGOSReportFields} from '../../constants/accountGOS';
import {Table, Spin, Row, Col} from 'antd';
import {getIsUserSeller} from '../../reducers/userReducer';

export class AccountGOSReportSearchTable extends Component {
  static propTypes = {
    accountGOSReport: PropTypes.array,
    totalElements: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    userRole: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    pageSize: PropTypes.number.isRequired,
    searchAccountGOSReport: PropTypes.func.isRequired,
  };

  onPageChange = page => {
    const {searchAccountGOSReport} = this.props;

    searchAccountGOSReport(page);
  };

  getColumns = () => {
    const {t} = this.props;
    const columns = [
      {
        title: t('report.table.period'),
        dataIndex: accountGOSReportFields.TRANSACTION_DATE,
      },
      {
        title: t('report.table.issuedGOS'),
        dataIndex: accountGOSReportFields.ISSUED_GOS,
      },
      {
        title: t('report.table.outgoingGOS'),
        dataIndex: accountGOSReportFields.OUTGOING_GOS,
      },
      {
        title: t('report.table.incomingGOS'),
        dataIndex: accountGOSReportFields.INCOMING_GOS,
      },
      {
        title: t('report.table.lockedMj'),
        dataIndex: accountGOSReportFields.LOCKED_GOS,
      },
      {
        title: t('report.table.expirationGOS'),
        dataIndex: accountGOSReportFields.EXPIRATION_GOS,
      },
    ];

    if (!this.props.isUserSeller) columns.splice(1, 1);

    return columns;
  };

  render() {
    const {
      totalElements,
      accountGOSReport,
      loading,
      page,
      pageSize,
      t,
    } = this.props;

    return (
      <Spin spinning={loading}>
        <Table
          rowKey={accountGOSReportFields.ID}
          dataSource={accountGOSReport}
          columns={this.getColumns()}
          pagination={{
            onChange: this.onPageChange,
            current: page,
            total: totalElements,
            pageSize,
            defaultCurrent: 1,
          }}
        />
        <Row gutter={24}>
          <Col span={24} className="text-small">
            {t('report.table.bottomInfo')}
          </Col>
        </Row>
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  isUserSeller: getIsUserSeller(state),
});

export default connect(mapStateToProps)(AccountGOSReportSearchTable);
