import React, {Component} from 'react';
import PropTypesImmutable from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {TATransactionsSearchTable} from '../../components';
import {
  getAccountGOSTransactionsList,
  getAccountGOSTransactionsListData,
} from '../../reducers/accountGOSTransactionsListReducer';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {getRole} from '../../reducers/userReducer';
import * as actions from '../../actions/actionCreators/accountGOSTransactionsListActionCreators';

export class AccountGOSTransactionsList extends Component {
  static propTypes = {
    accountGOSTransactions: PropTypesImmutable.list,
    accountGOSTransactionsList: PropTypesImmutable.map.isRequired,
    t: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const {searchAccountGOSTransactions} = this.props;
    searchAccountGOSTransactions(0);
  }

  render() {
    const {
      accountGOSTransactions,
      accountGOSTransactionsList,
      ...rest
    } = this.props;

    return (
      <React.Fragment>
        <TATransactionsSearchTable
          tsTransactions={accountGOSTransactions.toJS()}
          {...rest}
          {...accountGOSTransactionsList.toJS()}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  accountGOSTransactionsList: getAccountGOSTransactionsList(state),
  accountGOSTransactions: getAccountGOSTransactionsListData(state),
  loading: getLoadingSelector(getAccountGOSTransactionsList)(state),
  userRole: getRole(state),
});

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  AccountGOSTransactionsList
);
