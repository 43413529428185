import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import './companyInfo.css';

export default class UserInfo extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    company: PropTypes.object.isRequired,
  };

  render() {
    const {company, t} = this.props;
    return (
      <div className="user-info">
        <dl className="inline">
          <dt>{t('companyName')}</dt>
          <dd>{company.name}</dd>
        </dl>
      </div>
    );
  }
}
