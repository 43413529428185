import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {classifierFields} from '../../constants/classifier';
import {Table, Button} from 'antd';
import {TableRowActions} from '../';
import {euroFormater} from '../../utils/formaters';
import {goToClassifierView} from '../../utils/gotoLink';
import classifierTypes from '../../constants/classificators';

class ClassifiersTable extends Component {
  static propTypes = {
    dataSource: PropTypes.array,
    t: PropTypes.func.isRequired,
  };

  getColumns = () => {
    const {t} = this.props;

    return [
      {
        title: t('table.name'),
        dataIndex: classifierFields.NAME,
      },
      {
        title: t('table.value'),
        dataIndex: classifierFields.VALUE,
        render: (value, values) => {
          if (
            values[classifierFields.ID] === classifierTypes.tax ||
            values[classifierFields.ID] === classifierTypes.averageStockPrice
          ) {
            return euroFormater(value);
          }
          return value;
        },
      },
      {
        title: t('table.actions'),
        dataIndex: classifierFields.ID,
        render: value => (
          <TableRowActions>
            <Button
              shape="circle"
              icon="edit"
              size="small"
              type="primary"
              onClick={() => goToClassifierView(value)}
            />
          </TableRowActions>
        ),
      },
    ];
  };

  render() {
    const {dataSource} = this.props;

    return (
      <Table
        rowKey={classifierFields.ID}
        dataSource={dataSource}
        columns={this.getColumns()}
        pagination={false}
      />
    );
  }
}

export default ClassifiersTable;
