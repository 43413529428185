import {isEmpty} from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, notification, Spin} from 'antd';
import {connect} from 'react-redux';
import {RegisterActions, TableActions} from '../../components';
import {
  changeMeteringPointConsumerAction,
  changeMeteringPointHasDirectConnection,
  changeMeteringPointProdUnitEicw,
  changeMeteringPointSelectionAction,
  fetchMeasurementPointsAction,
  resetMeasurementPointsAction,
  updateLoadingUnitAction,
} from '../../actions/actionCreators/loadingUnitActionCreator';
import {
  getMeasurementPointsData,
  getMeasurementPointsState,
  MEASUREMENT_POINT_MODIFIED,
} from '../../reducers/loadingUnitMeasurementPointReducer';
import {SubmissionError} from 'redux-form/immutable';
import PropTypesImmutable from 'react-immutable-proptypes';
import {ElectricMeasurementTable} from './ElectricMeasurementTable';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {getLoadingUnitsEditState} from '../../reducers/legalEntityLoadingUnitsReducer';
import {EIC} from '../../utils/eic';

export class RegisterElectricMeasurementPoints extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    hasPrevious: PropTypes.bool,
    hasDone: PropTypes.bool,
    onSubmitSuccess: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    goPrevious: PropTypes.func,
    getElectricMeasurementPoints: PropTypes.func.isRequired,
    resetElectricMeasurementPoints: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    loadingUnit: PropTypesImmutable.map,
    isEditMode: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    legalEntityId: PropTypes.number.isRequired,
    isLoadingUnitAdd: PropTypes.bool
  };

  state = {
    isEditing: false,
  };

  componentDidMount = () => {
    const {legalEntityId, isEditMode, getElectricMeasurementPoints} = this.props;

    if (!isEditMode) {
      getElectricMeasurementPoints(legalEntityId);
    }
  };

  componentWillUnmount() {
    const {resetElectricMeasurementPoints} = this.props;
    resetElectricMeasurementPoints();
  }

  componentWillUpdate(nextProps, nextState) {
    const {legalEntityId, isEditMode, getElectricMeasurementPoints} = this.props;

    if (isEditMode && nextState.isEditing && !this.state.isEditing) {
      getElectricMeasurementPoints(legalEntityId);
    }
  }

  onChangeSelection = eicZ => val => {
    this.props.changeSelection(eicZ, val.target.checked);
  };

  onChangeConsumer = eicZ => val => {
    this.props.changeConsumer(eicZ, val.target.value);
  };

  onChangeHasDirectConn = eicZ => val => {
    this.props.changeHasDirectConn(eicZ, val.target.value);
  };

  onChangeEicw = eicZ => val => {
    this.props.changeEicw(eicZ, val.target.value);
  };

  handleIsAdminEdit = () => {
    this.setState({
      isEditing: true,
    });
  };

  async goNext() {
    const {
      t,
      loadingUnit,
      data,
      onSubmitSuccess,
      isEditMode,
      updateLoadingUnit,
      legalEntityId,
      getElectricMeasurementPoints,
      isLoadingUnitAdd
    } = this.props;

    const general = loadingUnit.toJS();
    const measurementPoints = data.filter(point => point[MEASUREMENT_POINT_MODIFIED]);

    const pointsAreInvalid = measurementPoints.map(point => {
      const hasDirectConn = point.isDirectlyConnectedToProductionUnit;
      const eicw = point.productionUnitEicw;

      if (hasDirectConn) {
        return eicw !== undefined && eicw !== null && !isEmpty(eicw) && EIC.isValid(eicw)
      }

      return true
    }).includes(false);

    if (pointsAreInvalid) {
      notification.error({
        message: t('metering_point.direct_conn_error'),
      });

      return;
    }

    const payload = {...general, measurementPoints, loadingUnitAddLastStep: isLoadingUnitAdd};
    const result = await updateLoadingUnit(payload);

    if ('error' in result) {
      notification.error({
        message: t('metering_point.update_error'),
      });

      throw new SubmissionError();
    }

    if (!isEditMode) onSubmitSuccess();
    if (isEditMode) getElectricMeasurementPoints(legalEntityId);

    this.setState({
      isEditing: false,
    });
  }

  render() {
    const {
      goPrevious,
      t,
      isEditMode,
      isAdmin,
      hasPrevious,
      hasDone,
      loadingUnit
    } = this.props;

    const {
      isLoading,
    } = this.props;

    const {isEditing} = this.state;

    const loadingUnitId = loadingUnit.toJS().id;

    let {data} = this.props;

    const isNotEditingInEditMode = isEditMode && !isEditing;

    if (isNotEditingInEditMode) {
      data = loadingUnit.get('measurementPoints').toJS();
    }

    return (
      <Spin spinning={isLoading}>
        <h2>{t('metering_point.title')}</h2>
        <ElectricMeasurementTable
          t={t}
          onChangeSelection={this.onChangeSelection}
          onChangeConsumer={this.onChangeConsumer}
          onChangeHasDirectConn={this.onChangeHasDirectConn}
          onChangeEicw={this.onChangeEicw}
          isLoading={isLoading}
          loadingUnitId={loadingUnitId}
          isEditing={isEditing}
          isAdmin={isAdmin}
          isEditMode={isEditMode}
          data={data}
        />

        {!isEditing && isEditMode ? (
          <TableActions>
            <Button onClick={this.handleIsAdminEdit} type="primary">
              {t('metering_point.adminEditBtn')}
            </Button>
          </TableActions>
        ) : (
          <React.Fragment>
            <RegisterActions
              // showRequestChangeModal={showRequestChangeModal}
              hasPrevious={hasPrevious}
              hasDone={hasDone}
              goPrevious={goPrevious}
              goNext={() => this.goNext()}
              isEditMode={isEditMode}
              showSaveButton={true}
              isAdmin={isAdmin}
              showRequestChangeButton={false}
              t={t}
            />
          </React.Fragment>
        )}
      </Spin>
    );
  }
}

const getIsLoading = getLoadingSelector(getMeasurementPointsState, getLoadingUnitsEditState);

const mapStateToProps = state => {
  return {
    data: getMeasurementPointsData(state).toJS(),
    isLoading: getIsLoading(state),
  };
};

const mapDispatchToProps = {
  getElectricMeasurementPoints: fetchMeasurementPointsAction,
  resetElectricMeasurementPoints: resetMeasurementPointsAction,
  changeSelection: changeMeteringPointSelectionAction,
  changeConsumer: changeMeteringPointConsumerAction,
  changeHasDirectConn: changeMeteringPointHasDirectConnection,
  changeEicw: changeMeteringPointProdUnitEicw,
  updateLoadingUnit: updateLoadingUnitAction
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterElectricMeasurementPoints);
