import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'antd';
import {transactionFields} from '../../constants/transaction';
import {
  translateTransactionTypeClassificator,
  translateTransactionStatusClassificator,
} from '../../utils/translateHelpers';
import transactionType from "../../constants/classificators/transactionType";
import {accountFields} from "../../constants/account";

class TransactionInformation extends React.Component {
  static propTypes = {
    info: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  gosPriceShow = transactionDataJs => {
    const {accounts} = this.props;
    let accountIds = [];
    for (let i = 0; i < accounts.size; i++) {
      accountIds.push(accounts.get(i).get(accountFields.ID));
    }
    return (transactionDataJs[transactionFields.TYPE] === transactionType.INCOMING ||
        transactionDataJs[transactionFields.TYPE] === transactionType.OUTGOING) &&
      !(accountIds.includes(transactionDataJs[transactionFields.SENDER_ACCOUNT].id) &&
        accountIds.includes(transactionDataJs[transactionFields.RECEIVER_ACCOUNT].id));
  }


  render() {
    const {t, info} = this.props;

    return (
      <React.Fragment>
        <Row className="global-margin-bottom-10">
          <Col span={8}>
            <b>{t('info.transactionNumber')}: </b>
            {info[transactionFields.ID]}
          </Col>
          <Col span={8}>
            <b>{t('info.transactionType')}: </b>
            {translateTransactionTypeClassificator(
              info[transactionFields.TYPE]
            )}
          </Col>
          <Col span={8}>
            <b>{t('info.transactionStatus')}: </b>
            {translateTransactionStatusClassificator(
              info[transactionFields.STATUS]
            )}
          </Col>
        </Row>
        <Row className="global-margin-bottom-10">
          <Col span={8}>
            <b>{t('info.transactionStartTime')}: </b>
            {info[transactionFields.START_DATE]}
          </Col>
          <Col span={8}>
            <b>{t('info.transactionEndTime')}: </b>
            {info[transactionFields.END_DATE]}
          </Col>
          {this.gosPriceShow(info) && (
            <Col span={8}>
              <b>{t('info.gosPrice')}: </b>
              {info[transactionFields.GOS_PRICE].toFixed(2)}
            </Col>
          )}
        </Row>
      </React.Fragment>
    );
  }
}

export default TransactionInformation;
