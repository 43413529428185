import {handleActions} from 'redux-actions';
import {
  createAsyncPagingReducer,
  listInitialAsyncPagingState,
} from '../utils/asyncPagingHelpers';
import {createAsyncSelector} from '../utils/asyncHelpers';
import {SEARCH_LEGAL_ENTITIES} from '../constants/SearchLegalEntititesActionTypes';
import {legalEntitySchema} from '../schemas';

const searchLegalEntitiesReducer = handleActions(
  createAsyncPagingReducer(SEARCH_LEGAL_ENTITIES),
  listInitialAsyncPagingState
);

export const getLegalEntitiesSearchResults = state =>
  state.get('legalEntitiesSearchResults');

export const getLegalEntitiesSearchResultsData = createAsyncSelector(
  [legalEntitySchema],
  getLegalEntitiesSearchResults
);

export default searchLegalEntitiesReducer;
