import {normalizeHelper} from '../../utils/asyncHelpers';
import {
  createAsyncPagingAction,
  createAsyncActionStoreWrapper,
} from '../../utils/asyncPagingHelpers';
import {ACCOUNT_GOS_REPORT_LIST_FETCH} from '../../constants/accountGOSListActionTypes';
import {searchAccountGOSReport as accountGOSReportSearch} from '../actionHelpers/endpoints';
import accountGOSReportSchema from '../../schemas/accountGOSReport';
import {getFormValues} from 'redux-form/immutable';
import {getAccountGOSReportList} from '../../reducers/accountGOSReportListReducer';
import {FORM_ACCOUNT_GOS_REPORT_FILTER} from '../../constants/formAccountGOSReportFilter';

const _searchAccountGOSReport = createAsyncPagingAction(
  ACCOUNT_GOS_REPORT_LIST_FETCH,
  accountGOSReportSearch,
  normalizeHelper([accountGOSReportSchema])
);

const filterFormSelector = getFormValues(FORM_ACCOUNT_GOS_REPORT_FILTER);

export const searchAccountGOSReport = createAsyncActionStoreWrapper(
  _searchAccountGOSReport,
  filterFormSelector,
  getAccountGOSReportList
);
