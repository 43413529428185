import {handleActions} from "redux-actions";
import {createAsyncReducer, initialAsyncState} from "../utils/asyncHelpers";
import {
  PRODUCTION_UNIT_FUEL_UPDATE,
  PRODUCTION_UNIT_UPDATE_FUELS_DELETE_NON_INCLUDED
} from "../constants/productionUnitActionTypes";

const loadingUnitFuelsReducer = handleActions(
  {
    ...createAsyncReducer(PRODUCTION_UNIT_FUEL_UPDATE),
    ...createAsyncReducer(PRODUCTION_UNIT_UPDATE_FUELS_DELETE_NON_INCLUDED),
  },
  initialAsyncState
);

export default loadingUnitFuelsReducer;

export const getLoadingUnitFuels = state => state.get('loadingUnitFuels');
