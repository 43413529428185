import {createValidation, validatorRequired} from '../../utils/formValidators';
import {seller} from '../../constants/parameters';

export default createValidation([
  {
    name: seller.NAME,
    validators: validatorRequired(),
  },
  {
    name: seller.SOLD,
    validators: validatorRequired(),
  },
  {
    name: seller.CODE,
    validators: validatorRequired(),
  },
]);
