import {createAsyncSelector} from '../utils/asyncHelpers';
import {
  listInitialAsyncPagingState,
  createAsyncPagingReducer,
} from '../utils/asyncPagingHelpers';
import {handleActions} from 'redux-actions';
import {
  TRANSACTION_FOR_GOS_SEARCH_FETCH
} from '../constants/actionTypes/transactionForGosSearchActionTypes';
import {transactionGOSSchema} from '../schemas';

const transactionForGosSearch = handleActions(
  {
    ...createAsyncPagingReducer(TRANSACTION_FOR_GOS_SEARCH_FETCH),
  },
  listInitialAsyncPagingState
);

export const getTransactionForGosList = state => state.get('transactionForGosSearch');

export const getTransactionForGosListData = createAsyncSelector(
  [transactionGOSSchema],
  getTransactionForGosList
);

export default transactionForGosSearch;
