import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import PropTypesImmutable from 'react-immutable-proptypes';
import {
  ConsumptionPointAdd as AddForm,
  RegisterMeasurementAdd,
  AddConsumer,
} from '../../forms';
import * as actions from '../../actions/actionCreators/consumptionPointActionCreators';
import {Button, Form, Modal} from 'antd';
import {
  MeasurementTable,
  AutoCompleteConsumers,
  FormActions,
  ConsumerInfoTable,
  TableActions,
  AutoCompleteSellers,
} from '../../components';
import {
  getAddConsumptionPointConsumerModal,
  hideAddConsumptionPointConsumerModal,
} from '../../actions/actionCreators/modalsActionCreators';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {consumerFields} from '../../constants/consumer';
import {meteringPointFields} from '../../constants/meteringPoint';
import {meteringPointDataSource} from '../../constants/classificators';
import * as selectors from '../../reducers/consumptionPointAddReducer';
import {getRole, getUserLegalEntityId} from '../../reducers/userReducer';
import {isAdmin as isUserAdmin} from '../../utils/roles';

const FormItem = Form.Item;

class ConsumptionPointAdd extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    points: PropTypesImmutable.list.isRequired,
    selectPoint: PropTypes.func.isRequired,
    selectedPointId: PropTypes.string.isRequired,
    fetchAvpPoints: PropTypes.func.isRequired,
    startEditConsumptionPoint: PropTypes.func.isRequired,
    userRole: PropTypes.string.isRequired,
    createFormVisible: PropTypes.bool.isRequired,
    closePointForm: PropTypes.func.isRequired,
    startAddConsumptionPoint: PropTypes.func.isRequired,
    consumer: PropTypesImmutable.map,
    consumerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fetchConsumer: PropTypes.func.isRequired,
    submitAddConsumptionFrom: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    startEditConsumer: PropTypes.func.isRequired,
    startAddConsumer: PropTypes.func.isRequired,
    hideConsumerModal: PropTypes.func.isRequired,
    consumerModalVisible: PropTypes.bool.isRequired,
    selectOwner: PropTypes.func.isRequired,
    ownerId: PropTypes.number,
    legalEntityId: PropTypes.number,
    resetFields: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    const {resetFields} = this.props;

    resetFields();
  };

  componentDidUpdate = prevProps => {
    const {consumer: prevConsumer} = prevProps;
    const {fetchAvpPoints, consumer} = this.props;

    if (
      !consumer.isEmpty() &&
      consumer.get(consumerFields.EICX_CODE) !==
      prevConsumer.get(consumerFields.EICX_CODE)
    )
      fetchAvpPoints({eicxCode: consumer.get(consumerFields.EICX_CODE)});
  };

  render() {
    const {
      t,
      points,
      selectedPointId,
      selectPoint,
      startEditConsumptionPoint,
      userRole,
      createFormVisible,
      closePointForm,
      startAddConsumptionPoint,
      submitAddConsumptionFrom,
      consumerId,
      fetchConsumer,
      isLoading,
      consumer,
      startEditConsumer,
      hideConsumerModal,
      consumerModalVisible,
      startAddConsumer,
      legalEntityId,
      selectOwner,
      ownerId,
    } = this.props;

    const isAdmin = isUserAdmin(userRole);

    return (
      <React.Fragment>
        <h1>{t('info.title')}</h1>
        <h2>{t('info.consumerTitle')}</h2>
        {!legalEntityId && (
          <FormItem label={t('info.ownerSearch')}>
            <AutoCompleteSellers value={ownerId} onChange={selectOwner}/>
          </FormItem>
        )}
        {(ownerId || legalEntityId) && (
          <React.Fragment>
            <FormItem label={t('info.consumerSearch')}>
              <AutoCompleteConsumers
                value={typeof consumerId === 'string' ? null : consumerId}
                onChange={fetchConsumer}
              />
            </FormItem>
            <ConsumerInfoTable
              consumer={consumer.isEmpty() ? null : consumer.toJS()}
              onEdit={startEditConsumer}
            />
            <TableActions>
              <Button onClick={startAddConsumer}>
                {t('info.addConsumerBtn')}
              </Button>
            </TableActions>
          </React.Fragment>
        )}
        {consumerId && (
          <React.Fragment>
            <h2>{t('info.pointTitle')}</h2>
            <AddForm
              t={t}
              consumer={consumer}
              ownerId={ownerId || legalEntityId}
            />
            <MeasurementTable
              t={t}
              data={points.toJS()}
              onSelectMeasurement={selectPoint}
              selectedMeasurementId={selectedPointId}
              onEditClick={startEditConsumptionPoint}
              isAdmin={isAdmin}
              isEditMode={false}
              isLoading={isLoading}
            />
            {createFormVisible ? (
              <RegisterMeasurementAdd
                closeForm={closePointForm}
                t={t}
                inEditForNotAdmin={false}
                isAdmin={isAdmin}
                onSubmit={actions.submitPointForm}
              />
            ) : (
              <React.Fragment>
                {points
                  .filter(
                    point =>
                      point.get(meteringPointFields.DATA_SOURCE) ===
                      meteringPointDataSource.VIRTUAL
                  )
                  .isEmpty() && (
                  <div className="register-measurement-container__add-button">
                    <Button onClick={startAddConsumptionPoint} type="primary">
                      {t('measurement.buttonAdd')}
                    </Button>
                  </div>
                )}
                <FormActions>
                  <Button onClick={submitAddConsumptionFrom} type="primary">
                    {t('info.saveBtn')}
                  </Button>
                </FormActions>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        <Modal
          title={t('info.addConsumerTitle')}
          visible={consumerModalVisible}
          footer={null}
          onCancel={hideConsumerModal}
        >
          <AddConsumer
            legalEntityId={legalEntityId || ownerId}
            closeModal={hideConsumerModal}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

const getLoading = getLoadingSelector(
  selectors.getConsumptionPoint,
  selectors.getAvpPoints
);

const mapStateToProps = state => ({
  selectedPointId: selectors.getSelectedPointId(state),
  isLoading: getLoading(state),
  points: selectors.getAvpAndBmrPointsData(state),
  createFormVisible: selectors.isCreateFormShow(state),
  consumer: selectors.getConsumerData(state),
  consumerId: selectors.getConsumerId(state),
  userRole: getRole(state),
  consumerModalVisible: getAddConsumptionPointConsumerModal(state),
  ownerId: selectors.getOwnerId(state),
  legalEntityId: getUserLegalEntityId(state),
});

const mapDispatchToProps = {
  ...actions,
  hideConsumerModal: hideAddConsumptionPointConsumerModal,
};

export default translate(NAMESPACES.consumptionPointAdd)(
  connect(mapStateToProps, mapDispatchToProps)(ConsumptionPointAdd)
);
