import * as actionTypes from '../../constants/SearchLegalEntititesActionTypes';
import {
  searchLegalEntities,
  registerMandateByLegalEntity,
} from '../actionHelpers/endpoints';
import {getFormValues} from 'redux-form/immutable';
import {createAsyncAction, normalizeHelper} from '../../utils/asyncHelpers';
import {
  createAsyncActionStoreWrapper,
  createAsyncPagingAction,
} from '../../utils/asyncPagingHelpers';
import {legalEntitySchema} from '../../schemas';
import mandatePersonSchema from '../../schemas/mandatePerson';
import {getLegalEntitiesSearchResults} from '../../reducers/searchLegalEntitiesReducer';
import {FORM_SEARCH_FILTERS} from '../../constants/formSearchFilters';
import {selectUserMandate} from './userActionCreators';

const _searchLegalEntities = createAsyncPagingAction(
  actionTypes.SEARCH_LEGAL_ENTITIES,
  searchLegalEntities,
  normalizeHelper([legalEntitySchema])
);

const filterFormSelector = getFormValues(FORM_SEARCH_FILTERS);

export const fetchSearchLegalEntities = createAsyncActionStoreWrapper(
  _searchLegalEntities,
  filterFormSelector,
  getLegalEntitiesSearchResults
);

export const fetchLoginAsCompanyByAdmin = createAsyncAction(
  actionTypes.LOGIN_AS_COMPANY_AS_ADMIN,
  registerMandateByLegalEntity,
  async (data, dispatch) => {
    await dispatch(selectUserMandate(data.id));
    return normalizeHelper(mandatePersonSchema)(data);
  }
);
