export const tradingPlatformAgreementsFilter = {
  SELLER_REGISTER_CODE: 'sellerRegisterCode',
  BUYER_REGISTER_CODE: 'buyerRegisterCode',
  FINAL_CONSUMPTION_YEAR: 'finalConsumptionYear',
  FUEL_TYPE: 'fuelType',
  FUEL_GENERATION: 'fuelGeneration',
  AGREEMENT_TYPE: 'agreementType',
  STATUS: 'status',
  ID: 'id'
};

export const FORM_TRADING_PLATFORM_AGREEMENTS_FILTER = 'FORM_TRADING_PLATFORM_AGREEMENTS_FILTER'
