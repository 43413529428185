import React, {Component} from 'react';
import PropTypesImmutable from 'react-immutable-proptypes';
import {reduxForm, Field, Fields, getFormValues} from 'redux-form/immutable';
import PropTypes from 'prop-types';
import {MonthYearField, ClassificatorField} from '../../atoms';
import {
  FormActions,
  AutoCompleteCompaniesField,
  TableActions,
} from '../../components';
import {connect} from 'react-redux';
import {downloadFileByPostURL} from '../../utils/files';
import {links} from '../../utils/gotoLink';
import {FORM_INVOICE_FILTER} from '../../constants/formKeys';
import {fromInvoiceFilterFields} from '../../constants/forms/formInvoiceFilter';
import {Collapse, Row, Col, Button} from 'antd';
import {getRole} from '../../reducers/userReducer';
import {isAdmin} from '../../utils/roles';
import * as actions from '../../actions/actionCreators/invoiceSearchActionCreators';

const {Panel} = Collapse;

export class InvoiceFilter extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    filterValues: PropTypesImmutable.map,
    userRole: PropTypes.string.isRequired,
    searchInvoices: PropTypes.func.isRequired,
  };
  componentDidMount = () => this.props.searchInvoices();

  onClear = () => {
    const {submit, reset} = this.props;

    reset();
    submit();
  };

  handleExportInvoices = () => {
    const {filterValues} = this.props;

    downloadFileByPostURL(
      links.invoiceExportCsv,
      filterValues ? filterValues.toJS() : {},
      'invoices.csv'
    );
  };

  render() {
    const {t, handleSubmit, userRole} = this.props;

    const isUserAdmin = isAdmin(userRole);

    return (
      <React.Fragment>
        <Collapse defaultActiveKey={['filters']}>
          <Panel key="filters" header={t('filter.panelTitle')}>
            <form onSubmit={handleSubmit}>
              <Row gutter={32}>
                <Col span={8}>
                  <Field
                    label={t('filter.status')}
                    name={fromInvoiceFilterFields.STATUS}
                    component={ClassificatorField}
                    type={ClassificatorField.types.invoiceStatus}
                    hasFeedback={false}
                  />
                </Col>
                <Col span={8}>
                  <Fields
                    label={t('filter.period')}
                    names={[
                      fromInvoiceFilterFields.MONTH,
                      fromInvoiceFilterFields.YEAR,
                    ]}
                    className="date-field"
                    component={MonthYearField}
                    hasFeedback={false}
                  />
                </Col>
                {isUserAdmin && (
                  <Col span={8}>
                    <Field
                      label={t('filter.presenter')}
                      name={fromInvoiceFilterFields.PRESENTER}
                      component={AutoCompleteCompaniesField}
                      hasFeedback={false}
                    />
                  </Col>
                )}
              </Row>

              <FormActions>
                <Button onClick={this.onClear}>{t('filter.clearBtn')}</Button>
                <Button type="primary" htmlType="submit">
                  {t('filter.submitBtn')}
                </Button>
              </FormActions>
            </form>
          </Panel>
        </Collapse>
        <TableActions>
          <Button onClick={this.handleExportInvoices}>
            {t('filter.exportCsvBtn')}
          </Button>
        </TableActions>
      </React.Fragment>
    );
  }
}

const onSubmit = (values, dispatch) => dispatch(actions.searchInvoices());

const getValues = getFormValues(FORM_INVOICE_FILTER);

const mapStateToProps = state => ({
  filterValues: getValues(state),
  userRole: getRole(state),
});

const mapDispatchToProps = {
  searchInvoices: actions.searchInvoices,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_INVOICE_FILTER,
    onSubmit,
  })(InvoiceFilter)
);
