export const consumerChartFields = {
  YEAR: 'year',
  TOTAL: 'total',
  EMISSION: 'co2emission',
  CONSUMED: 'consumed',
  AMOUNT: 'amount',
  FUEL_TYPE: 'fuelType',
  MONTH: 'month',
  COUNTRY: 'country',
};
