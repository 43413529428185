import {createValidation, validatorRequired} from '../../utils/formValidators';

import {meteringPointFields} from '../../constants/meteringPoint';

export default createValidation([
  {
    name: meteringPointFields.AUTOMATIC_CANCELLATION,
    validators: [validatorRequired()],
  },
  {
    name: meteringPointFields.CONSUMPTION_TYPE,
    validators: validatorRequired(),
  },
  {
    name: meteringPointFields.START_DATE,
    validators: validatorRequired(),
  },
]);
