import React from 'react';
import { connect } from 'react-redux';
import { Steps, Spin } from 'antd';
import { formValueSelector } from 'redux-form/immutable';
import {
  FORM_REGISTER_GENERAL,
  formRegisterGeneralFields,
} from '../../constants/formRegister';
import {
  goPreviousStepActionCreator,
  goNextStepActionCreator,
  fetchLegalEntityActionCreator,
  resetMaster as resetMasterActionCreator,
} from '../../actions/actionCreators/registerActionCreators';
import * as registerSelectors from '../../reducers/registerReducer';
import {
  RegisterManagement,
  RegisterContacts,
  RegisterProductionUnits,
} from '../';
import { translate } from 'react-i18next';
import { NAMESPACES } from '../../i18n';
import PropTypes from 'prop-types';
import { getLegalEntity } from '../../schemas';
import { RegisterGeneral, RegisterAdditional, RegisterBank } from '../../forms';
import { getRole } from '../../reducers/userReducer';
import { isAdmin } from '../../utils/roles';
import { registerMandateIdSelector } from '../../reducers/registerGeneralReducer';
import * as generalActions from '../../actions/actionCreators/registerGeneralActionCreators';
import { getLoadingSelector } from '../../utils/asyncHelpers';
import './Register.css';

const getSteps = (isManufacturer, isHydroManufacturer, isOperator) => {
  if (isHydroManufacturer && isManufacturer) {
    return [
      RegisterGeneral,
      RegisterContacts,
      RegisterAdditional,
      RegisterProductionUnits,
      RegisterProductionUnits, // is set to hydrogen context
      RegisterBank,
      RegisterManagement,
    ];
  }

  if (isManufacturer) {
    return [
      RegisterGeneral,
      RegisterContacts,
      RegisterAdditional,
      RegisterProductionUnits,
      RegisterBank,
      RegisterManagement,
    ];
  }

  if (isHydroManufacturer) {
    return [
      RegisterGeneral,
      RegisterContacts,
      RegisterAdditional,
      RegisterProductionUnits,
      RegisterManagement,
    ];
  }

  if (isOperator) {
    return [
      RegisterGeneral,
      RegisterAdditional,
      RegisterContacts,
      RegisterManagement,
    ];
  }

  return [RegisterGeneral, RegisterContacts, RegisterManagement];
};

class Register extends React.Component {
  static propTypes = {
    currentStep: PropTypes.number.isRequired,
    goPrevious: PropTypes.func.isRequired,
    goNext: PropTypes.func.isRequired,
    hasDone: PropTypes.bool.isRequired,
    hasNext: PropTypes.bool.isRequired,
    hasPrevious: PropTypes.bool.isRequired,
    isTrader: PropTypes.bool.isRequired,
    isOperator: PropTypes.bool.isRequired,
    isManufacturer: PropTypes.bool.isRequired,
    isHydroManufacturer: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    fetchLegalEntity: PropTypes.func.isRequired,
    registerCode: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
    fetchRegisterMandate: PropTypes.func.isRequired,
    legalEntity: PropTypes.object,
    role: PropTypes.string.isRequired,
    resetMaster: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    const { fetchLegalEntity, registerCode, resetMaster } = this.props;

    if (registerCode) {
      fetchLegalEntity({ registerCode });
    } else resetMaster();
  };

  render() {
    const {
      currentStep,
      goPrevious,
      goNext,
      hasDone,
      hasPrevious,
      isTrader,
      isOperator,
      isManufacturer,
      isHydroManufacturer,
      t,
      isLoading,
      legalEntity,
      role,
      registerCode,
    } = this.props;

    const steps = getSteps(isManufacturer, isHydroManufacturer, isOperator);
    const numberOfSteps = steps.length;
    const StepComponent = steps[currentStep];

    const isHydrogenContext =
      (isHydroManufacturer && !isManufacturer && currentStep === 3) ||
      (isHydroManufacturer && isManufacturer && currentStep === 4);

    return (
      <Spin spinning={isLoading}>
        <div className="page-register">
          <h1 className="page-register__title">{t('title')}</h1>
          <Steps size="big" current={currentStep}>
            <Steps.Step title={t('title_general')} />
            {isOperator &&
              !isManufacturer && <Steps.Step title={t('title_additional')} />}
            <Steps.Step title={t('title_contact')} />
            {!isTrader && <Steps.Step title={t('title_additional')} />}
            {isManufacturer && <Steps.Step title={t('title_production')} />}
            {isHydroManufacturer && (
              <Steps.Step title={t('title_hydro_production')} />
            )}
            {!isTrader &&
              (!isHydroManufacturer || isManufacturer) && (
                <Steps.Step title={t('title_bank')} />
              )}
            <Steps.Step title={t('title_administration')} />
          </Steps>
          <div className="page-register__content">
            {!isLoading && (
              <StepComponent
                key={'step' + currentStep}
                hasPrevious={hasPrevious}
                goPrevious={goPrevious}
                onSubmitSuccess={() => goNext(isTrader)}
                hasDone={hasDone}
                isEditMode={false}
                t={t}
                registerCode={registerCode}
                legalEntity={legalEntity}
                numberOfSteps={numberOfSteps}
                legalEntityId={legalEntity.get('id')}
                isAdmin={isAdmin(role)}
                isHydrogenContext={isHydrogenContext}
              />
            )}
          </div>
        </div>
      </Spin>
    );
  }
}

const generalFormSelector = formValueSelector(FORM_REGISTER_GENERAL);

const mapStateToProps = state => {
  const isTrader =
    !generalFormSelector(state, formRegisterGeneralFields.IS_MANUFACTURER) &&
    !generalFormSelector(
      state,
      formRegisterGeneralFields.IS_HYDRO_MANUFACTURER
    );

  const isManufacturer = !!generalFormSelector(
    state,
    formRegisterGeneralFields.IS_MANUFACTURER
  );

  const isHydroManufacturer = !!generalFormSelector(
    state,
    formRegisterGeneralFields.IS_HYDRO_MANUFACTURER
  );

  const isOperator = !!generalFormSelector(
    state,
    formRegisterGeneralFields.IS_LOADING_OPERATOR
  );

  return {
    currentStep: registerSelectors.getCurrentStep(state),
    hasDone: registerSelectors.hasDoneNew(
      state,
      isOperator,
      isManufacturer,
      isHydroManufacturer
    ),
    hasNext: registerSelectors.hasNext(state, isTrader),
    hasPrevious: registerSelectors.hasPrevious(state),
    isTrader,
    isOperator,
    isManufacturer,
    isHydroManufacturer,
    isLoading: getLoadingSelector(
      registerSelectors.legalEntitySelector,
      registerMandateIdSelector
    )(state),
    legalEntity: getLegalEntity(
      state,
      registerSelectors.legalEntitySelector(state)
    ),
    role: getRole(state),
  };
};

const mapDispatchToProps = {
  goPrevious: goPreviousStepActionCreator,
  goNext: goNextStepActionCreator,
  fetchLegalEntity: fetchLegalEntityActionCreator,
  fetchRegisterMandate: generalActions.fetchRegisterMandate,
  resetMaster: resetMasterActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  translate(NAMESPACES.register)(Register)
);
