import {
  createAsyncSelector,
  getEntityById,
  createAsyncReducer,
  initialAsyncState,
} from '../utils/asyncHelpers';
import {
  listInitialAsyncPagingState,
  createAsyncPagingReducer,
} from '../utils/asyncPagingHelpers';
import {handleActions} from 'redux-actions';
import {Map} from 'immutable';
import {createSelector} from 'reselect';
import {
  INVOICE_SEARCH_FETCH,
  INVOICE_CONFIRM,
} from '../constants/actionTypes/invoiceActionTypes';
import {invoiceSchema} from '../schemas';
import {invoiceFields} from '../constants/invoice';
import moment from 'moment';
import {FORMAT_DEFAULT} from '../utils/dates';
import {selectInvoice} from '../actions/pureActions/invoiceActions';

const initialState = Map({
  list: listInitialAsyncPagingState,
  ui: Map({selectedInvoice: 0}),
  confirm: initialAsyncState,
});

const invoiceSearch = handleActions(
  {
    ...createAsyncPagingReducer(INVOICE_SEARCH_FETCH, 'list'),
    ...createAsyncReducer(INVOICE_CONFIRM, 'confirm'),
    [selectInvoice]: (state, {payload}) =>
      state.mergeIn(['ui', 'selectedInvoice'], payload),
  },
  initialState
);

export const getInvoiceList = state => state.getIn(['invoiceSearch', 'list']);
export const getSelectedInvoice = state =>
  state.getIn(['invoiceSearch', 'ui', 'selectedInvoice']);

export const getSelectedInvoiceData = state =>
  getEntityById(state, invoiceSchema, getSelectedInvoice(state));

export const getInvoiceForConfirm = createSelector(
  getSelectedInvoiceData,
  invoice =>
    invoice.set(
      invoiceFields.DATE,
      moment()
        .month(invoice.get(invoiceFields.MONTH) - 1)
        .endOf('month')
        .format(FORMAT_DEFAULT)
    )
);

export const getInvoiceListData = createAsyncSelector(
  [invoiceSchema],
  getInvoiceList
);

export const getInvoiceConfirm = state =>
  state.getIn(['invoiceSearch', 'confirm']);

export default invoiceSearch;
