import {schema} from 'normalizr';
import {entitiesSelectorList, entitiesSelectorItem} from '../utils/asyncHelpers';
import consumerSchema from './consumer';
import productionUnitSchema from './productionUnit';

const schemaEntity = new schema.Entity('measurement', {
  consumer: consumerSchema,
  productionUnit: productionUnitSchema,
});

export const getMeasurements = entitiesSelectorList(schemaEntity);
export const getMeasurement = entitiesSelectorItem(schemaEntity);
export default schemaEntity;
