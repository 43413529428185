import React, {useEffect, useState} from "react";
import {Button, Col, Form, InputNumber, notification, Row, Select, Spin} from "antd";
import {FormActions} from "../../components";
import {classifierLegalEntityConsumptionTypeField as field} from "../../constants/classifier";
import {
  classifiersGeneralFetch,
  classifiersLegalEntityConsumptionTypeCreate,
  classifiersLegalEntityConsumptionTypeDelete,
  classifiersLegalEntityConsumptionTypeFetch,
  classifiersLegalEntityConsumptionTypeUpdate,
  searchLegalEntitiesByName
} from "../../actions/actionHelpers/endpoints";
import {useForm, Controller} from "react-hook-form";
import './ParameterLegalEntityConsumptionTypeEdit.css';

export function ParameterLegalEntityConsumptionTypeEdit({
                                                          t,
                                                          selectedId,
                                                          onCancel,
                                                          onComplete,
                                                          isEdit
                                                        }) {

  const [loading, setLoading] = useState(true);
  const [legalEntityOptions, setLegalEntityOptions] = useState([]);
  const [consumptionTypeCodeOptions, setConsumptionTypeCodeOptions] = useState([]);

  const {control, handleSubmit, reset, formState: {errors}} = useForm({
    defaultValues: {
      [field.ID]: selectedId,
      [field.LEGAL_ENTITY_ID]: null,
      [field.CONSUMPTION_TYPE_CODE]: null,
      [field.SUPPORT_REGULATIONS_SUM]: null
    }
  });

  useEffect(() => {
    setLoading(selectedId != null);
    if (selectedId != null) {
      let item = {};
      classifiersLegalEntityConsumptionTypeFetch(selectedId)
        .then(response => {
          item = response.data;
          item[field.LEGAL_ENTITY_ID] = String(item[field.LEGAL_ENTITY_ID]);
          return searchLegalEntitiesByName(item[field.LEGAL_ENTITY_NAME]);
        })
        .then(legalEntitiesResponse => setLegalEntityOptions(legalEntitiesResponse.data) & reset(item) & setLoading(false))
        .catch(error => console.error(error) & setLoading(false));
    }

    classifiersGeneralFetch("CONSUMPTION_TYPE")
      .then(response => setConsumptionTypeCodeOptions(response.data.lines.map(({code}) => {
        return {code: code, name: code}
      })))
      .catch(error => console.error(error));
  }, [selectedId]);

  const onLegalEntitySearch = (searchText) => {
    if (!searchText) {
      return;
    }
    searchLegalEntitiesByName(searchText)
      .then(response => setLegalEntityOptions(response.data))
      .catch(error => console.error(error));
  };

  const saveItem = (data) => {
    setLoading(true);
    if (isEdit) {
      classifiersLegalEntityConsumptionTypeUpdate(selectedId, data)
        .then(() => {
          notification.success({
            message: t('parameterLegalEntityConsumptionType.saveSuccessTitle'),
            description: t('parameterLegalEntityConsumptionType.saveSuccessMessage'),
          });
          onComplete();
        })
        .catch(error => {
          notification.error({
            message: t('parameterLegalEntityConsumptionType.saveErrorTitle'),
            description: error.response.data.message
          });
          setLoading(false);
        });
    } else {
      classifiersLegalEntityConsumptionTypeCreate(data)
        .then(() => {
          notification.success({
            message: t('parameterLegalEntityConsumptionType.saveSuccessTitle'),
            description: t('parameterLegalEntityConsumptionType.saveSuccessMessage'),
          });
          onComplete();
        })
        .catch(error => {
          notification.error({
            message: t('parameterLegalEntityConsumptionType.saveErrorTitle'),
            description: error.response.data.message
          });
          setLoading(false);
        });
    }
  };

  const deleteItem = () => {
    setLoading(true);
    classifiersLegalEntityConsumptionTypeDelete(selectedId)
      .then(() => {
        notification.success({
          message: t('parameterLegalEntityConsumptionType.deleteSuccessTitle'),
          description: t('parameterLegalEntityConsumptionType.deleteSuccessMessage'),
        });
        onComplete();
      })
      .catch(error => {
        notification.error({
          message: t('parameterLegalEntityConsumptionType.deleteErrorTitle'),
          description: error.response.data.message
        });
        setLoading(false);
      });
  };

  return (
    <Spin spinning={loading}>
      <Form name="basic" onSubmit={handleSubmit(saveItem)}>
        <Row gutter={32}>
          <Col span={24}>
            <Controller
              name={field.LEGAL_ENTITY_ID}
              control={control}
              rules={{required: true}}
              render={({field: {onChange, onBlur, value}}) => (
                <Form.Item label={t('parameterLegalEntityConsumptionType.legalEntityName')}
                           className={errors[field.LEGAL_ENTITY_ID] ? 'has-error' : ''}>
                  <Select
                    onSearch={onLegalEntitySearch}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value ? parseInt(value) : null}
                    showSearch
                    filterOption={false}
                    defaultActiveFirstOption={false}
                  >
                    {legalEntityOptions.map(({id, name, registrationCode}) => <Select.Option
                      key={id} value={id}>{name}, {registrationCode}</Select.Option>)}
                  </Select>
                  {errors[field.LEGAL_ENTITY_ID] && errors[field.LEGAL_ENTITY_ID].type === 'required' &&
                    <div className={'text-error'}>This is required</div>}
                </Form.Item>
              )}
            />
            <Row gutter={32}>
              <Col span={12}>
                <Controller
                  name={field.CONSUMPTION_TYPE_CODE}
                  control={control}
                  rules={{required: true}}
                  render={({field: {onChange, onBlur, value}}) => (
                    <Form.Item label={t('parameterLegalEntityConsumptionType.consumptionTypeCode')}
                               className={errors[field.CONSUMPTION_TYPE_CODE] ? 'has-error' : ''}>
                      <Select
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                      >
                        {consumptionTypeCodeOptions.map(({code, name}) => <Select.Option key={code}
                                                                                         value={code}>{name}</Select.Option>)}
                      </Select>
                      {errors[field.CONSUMPTION_TYPE_CODE] && errors[field.CONSUMPTION_TYPE_CODE].type === 'required' &&
                        <div className={'text-error'}>This is required</div>}
                    </Form.Item>
                  )}
                />
              </Col>
              <Col span={12}>
                <Controller
                  name={field.SUPPORT_REGULATIONS_SUM}
                  control={control}
                  rules={{required: true, min: 0}}
                  render={({field: {onChange, onBlur, value}}) => (
                    <Form.Item
                      label={t('parameterLegalEntityConsumptionType.supportRegulationsSum')}
                      className={errors[field.SUPPORT_REGULATIONS_SUM] ? 'has-error' : ''}>
                      <InputNumber
                        min={0}
                        precision={2}
                        step={'0.01'}
                        // class={'ant-input'}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}/>
                      {errors[field.SUPPORT_REGULATIONS_SUM] && errors[field.SUPPORT_REGULATIONS_SUM].type === 'required' &&
                        <div className={'text-error'}>This is required</div>}
                      {errors[field.SUPPORT_REGULATIONS_SUM] && errors[field.SUPPORT_REGULATIONS_SUM].type === 'min' &&
                        <div className={'text-error'}>Min value is 0</div>}
                    </Form.Item>
                  )}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <FormActions>
          <Button onClick={onCancel}>{t('buttonBack')}</Button>
          {isEdit && <Button type="danger" onClick={deleteItem}>{t('buttonDelete')}</Button>}
          <Button type="primary" htmlType="submit">{t('buttonSave')}</Button>
        </FormActions>
      </Form>
    </Spin>
  );
}
