import React from 'react';
import {combineReducers} from 'redux-immutable';
import {routerReducer} from 'react-router-redux';
import {Provider} from 'react-redux';
import {reducer as form} from 'redux-form/immutable';
import {storeFactory} from './store';
import {translate, I18nextProvider} from 'react-i18next';
import i18n from './i18n';
import './styles/main.css';
import './styles/antd_theme/index.css';
import * as reducers from './reducers';
import classifiersStore from './utils/classifiersStore';
import {Spin} from 'antd';
import RootComponent from './Root';
import axios from "axios";

const reducer = combineReducers({
  routing: routerReducer,
  form,
  ...reducers,
});

const store = storeFactory(reducer);

class App extends React.Component {
  state = {
    classifiersLoading: true,
  };

  componentDidMount = async () => {
    axios.defaults.headers.common.lang = i18n.language;
    await classifiersStore.update();

    this.setState({
      classifiersLoading: false,
    });
  };

  render() {
    if (this.state.classifiersLoading) {
      return <Spin/>;
    }

    return (
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <RootComponent/>
        </Provider>
      </I18nextProvider>
    );
  }
}

export default translate('common')(App);
