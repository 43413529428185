import React, {Component} from 'react';
import {InputNumber} from 'antd';
import PropTypes from 'prop-types';
import {parseFloat, parseNumber} from '../../utils/formHelpers';
import ComponentWrapper from './ComponentWrapper';
import mapError from './mapError';

export default class NumberField extends Component {
  static propTypes = {
    isFloat: PropTypes.bool,
    precision: PropTypes.number,
    negativeAllowed: PropTypes.bool,
    blurAllowed: PropTypes.bool
  };

  static defaultProps = {
    isFloat: false,
    precision: 2,
    negativeAllowed: false,
    blurAllowed: false
  };

  /**
   * Prevents restoring value after parsing.
   *
   * @returns {boolean}
   */
  onBlur = () => false;

  /**
   * Removes all symbols.
   *
   * @param value
   */
  parser = value => {
    const {isFloat, precision, negativeAllowed} = this.props;

    const parser = isFloat ? parseFloat(precision, negativeAllowed) : parseNumber;

    return parser(value);
  };

  render() {
    const {blurAllowed} = this.props;
    return (
      <ComponentWrapper
        {...this.props}
        mapProps={mapError}
        render={props => (
          blurAllowed
            ? <InputNumber {...props} parser={this.parser}/>
            : <InputNumber {...props} onBlur={this.onBlur} parser={this.parser}/>
        )}
      />
    );
  }
}
