import React, { Component } from 'react';
import { searchCompany } from '../../actions/actionHelpers/endpoints';
import { AutoCompleteSelect } from '../';
import { legalEntityFields } from '../../constants/legalEntity';
import { legalEntityStatus } from '../../constants/classificators';
import PropTypes from 'prop-types';

const defaultCompaniesFilter = item => {
  return (
    item[legalEntityFields.STATUS] === legalEntityStatus.ACTIVE &&
    (item[legalEntityFields.MANUFACTURER] ||
      item[legalEntityFields.HYDROGEN_MANUFACTURER])
  );
};

const getDataSource = companiesFilter => async value => {
  const { data } = await searchCompany(value);
  return data.filter(companiesFilter).map(item => ({
    value: item.id,
    label: `${item.name}, ${item.registrationCode}`,
  }));
};

class AutoCompleteCompanies extends Component {
  static propTypes = {
    companiesFilter: PropTypes.func.isRequired,
  };

  static defaultProps = {
    companiesFilter: defaultCompaniesFilter,
  };

  render() {
    const { companiesFilter } = this.props;

    return (
      <AutoCompleteSelect
        {...this.props}
        getDataSource={getDataSource(companiesFilter)}
      />
    );
  }
}

export default AutoCompleteCompanies;
