import {
  NOTIFICATION_ADMIN_DECLINE,
  NOTIFICATION_ADMIN_APPROVE,
  NOTIFICATION_COMPANY_CLOSE,
} from '../constants/notificationActionTypes';
import {createAsyncReducer, initialAsyncState} from '../utils/asyncHelpers';
import {handleActions} from 'redux-actions';
import {Map} from 'immutable';

const initialState = Map({
  approveNotification: initialAsyncState,
  declineNotification: initialAsyncState,
  closeNotification: initialAsyncState,
});

const notificationActionReducer = handleActions(
  {
    ...createAsyncReducer(NOTIFICATION_ADMIN_APPROVE, 'approveNotification'),
    ...createAsyncReducer(NOTIFICATION_ADMIN_DECLINE, 'declineNotification'),
    ...createAsyncReducer(NOTIFICATION_COMPANY_CLOSE, 'closeNotification'),
  },
  initialState
);

export const getNotificationApprove = state =>
  state.getIn(['notificationAction', 'approveNotification']);

export const getNotificationDecline = state =>
  state.getIn(['notificationAction', 'declineNotification']);

export const getNotificationClose = state =>
  state.getIn(['notificationAction', 'closeNotification']);

export default notificationActionReducer;
