import PropTypes from "prop-types";
import React, {Component} from "react";
import {Button, Col, Icon, message, Modal, Row, Spin, Tooltip} from "antd";
import {connect} from "react-redux";
import './TsAgreementTransaction.scss';
import {
  translateAgreementTypeClassificator as tAgreementType,
  translateTsFuelGenerationClassificator as tFuelGeneration,
  translateTsFuelTypeClassificator as tFuelType
} from "../../utils/translateHelpers";
import {goToAccountGOSPreTradingAgreementDetails} from "../../utils/gotoLink";
import {getAgreementData} from "../../reducers/agreementViewReducer";
import * as actions from "../../actions/actionCreators/agreementViewActionCreators";
import {
  resetMaster as agreementResetMaster
} from "../../actions/actionCreators/agreementViewActionCreators";
import {FormActions} from "../../components";
import {change, Field, reduxForm} from "redux-form/immutable";
import {FORM_AGREEMENT_TRANSACTION} from "../../constants/formKeys";
import {CheckboxField, NumberField} from "../../atoms";
import {formTsAgreementTransactionFields} from "../../constants/forms/formTsAgreementTransaction";
import validate from './ValidateSchema';
import {tsAgreementTransactionAction} from "../../actions/actionCreators/tsAgreementActionCreators";
import {
  getCertificateDetailsViewModal,
  hideCertificateDetailsViewModal,
  showCertificateDetailsViewModal
} from "../../actions/actionCreators/modalsActionCreators";
import {TsReservationDetailsView} from "../../forms";

export class TsAgreementTransaction extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    assignment: PropTypes.object.isRequired,
    hideModal: PropTypes.func.isRequired,
    fetchAgreement: PropTypes.func.isRequired,
    stateCertificateDetailsModal: PropTypes.bool.isRequired,
    showCertificateDetailsModal: PropTypes.func.isRequired,
    isConfirmable: PropTypes.bool.isRequired,
    onSuccess: PropTypes.func,
    updateFormValue: PropTypes.func,
  }

  state = {
    checkboxState: false
  };

  componentDidMount = () => {
    const {assignment, fetchAgreement, updateFormValue, agreement} = this.props;
    fetchAgreement(assignment.sourceId);
    updateFormValue(formTsAgreementTransactionFields.PRICE, agreement.unitPrice); // In case agreement isn't reset
    if (assignment.completedDate) updateFormValue(formTsAgreementTransactionFields.CHECKBOX, true);
  };

  componentWillUnmount() {
    const {resetAgreement} = this.props;
    resetAgreement();
  }

  componentDidUpdate(prevProps) {
    const {agreement, updateFormValue} = this.props;
    if (agreement.id !== prevProps.agreement.id) {
      updateFormValue(formTsAgreementTransactionFields.PRICE, agreement.unitPrice);
    }
  }

  handleView(id) {
    this.props.hideModal();
    goToAccountGOSPreTradingAgreementDetails(this.props.registerCode, id);
  }

  handleCertificateModal = () => {
    const {showCertificateDetailsModal} = this.props;
    showCertificateDetailsModal();
  }

  renderRow = (label, value) => (
    <div>
      <span>{label}: </span>
      <span>{value}</span>
    </div>
  );

  render() {
    const {
      t,
      agreement,
      assignment,
      hideModal,
      handleSubmit,
      stateCertificateDetailsModal,
      hideCertificateDetailsModal,
      isConfirmable
    } = this.props;
    const {checkboxState} = this.state;

    const isLoading = Object.keys(agreement).length === 0;
    const completedByUser = assignment.completedDate && !assignment.modifiedBySystem;

    if (isLoading) return (<Spin/>)

    return (
      <form onSubmit={handleSubmit}>
        <Row gutter={32}>
          <Col span={24} className="detail-container">
            <div>
              <span>{t('assignments.transaction.agreementNr')}: </span>
              <a onClick={() => this.handleView(agreement.id)}>{agreement.id}</a>
            </div>
            {this.renderRow(t('assignments.transaction.counterparty'), agreement.counterpartyName)}
            {this.renderRow(t('assignments.transaction.createdAt'), agreement.createdAt.split(" ")[0])}
            {this.renderRow(t('assignments.transaction.dueDate'), assignment.dueDate)}
            <br/>
            {this.renderRow(t('assignments.transaction.agreementType'), tAgreementType(agreement.agreementType))}
            {this.renderRow(t('assignments.transaction.fuelType'), tFuelType(agreement.fuelType))}
            {this.renderRow(t('assignments.transaction.fuelGeneration'), tFuelGeneration(agreement.fuelGeneration))}
            {agreement.ghgCapacity &&
              this.renderRow(<span>
                    {t('agreements.table.ghgCapacity')}&nbsp;
                <Tooltip title={t('agreements.table.ghgTooltip')}>
                      <Icon type="question-circle-o"/>
                    </Tooltip>
                  </span>, agreement.ghgCapacity.toFixed(1))
            }
            <br/>
            {this.renderRow(t('assignments.transaction.naturalAmountMj'), agreement.naturalAmountMj.toFixed(1))}
            {this.renderRow(t('assignments.transaction.multiplier'), agreement.multiplier)}
            {this.renderRow(t('assignments.transaction.calculatedAmountMj'), agreement.calculatedAmountMj.toFixed(1))}
            {this.renderRow(t('assignments.transaction.fulfilledPercentage'),
              agreement.agreementType === 'ENTIRE' ?
                t('assignments.transaction.none') :
                agreement.fulfilledPercentage.toFixed(2))}
            <br/>
            {this.renderRow(t('assignments.transaction.details'),
              <Button
                shape="circle"
                icon="select"
                size="small"
                type="primary"
                title={t('assignments.transaction.viewBtn')}
                onClick={() => this.handleCertificateModal()}
              />)}
            <br/>
            <Field
              min={0}
              step={0.001}
              precision={3}
              name={formTsAgreementTransactionFields.PRICE}
              label={t('assignments.transaction.price')}
              component={NumberField}
              isFloat
              readOnly={!isConfirmable}
            />
            <br/>
          </Col>
        </Row>
        {completedByUser &&
          <div className="mb-1">
            <span><b>{t('assignments.transaction.completedDate')}: </b></span>
            <span>{assignment.completedDate}</span>
          </div>
        }
        {(isConfirmable || completedByUser) &&
          <Row>
            <Col className="checkbox-tooltip">
              <Field
                name={formTsAgreementTransactionFields.CHECKBOX}
                component={CheckboxField}
                checkboxText={t('assignments.transaction.checkboxInfo')}
                tooltip={t('assignments.transaction.tooltip')}
                hasFeedback={false}
                onChange={event => this.setState({checkboxState: event.target.checked})}
                disabled={!isConfirmable}
              />
            </Col>
          </Row>
        }
        {isConfirmable &&
          <FormActions>
            <Button onClick={hideModal}>{t('assignments.transaction.cancel')} </Button>
            <Button disabled={!checkboxState} type="primary" htmlType="submit">
              {t('assignments.transaction.confirm')}
            </Button>
          </FormActions>
        }
        {
          stateCertificateDetailsModal && (
            <Modal
              onCancel={hideCertificateDetailsModal}
              visible
              footer={false}
              title={t("assignments.reserveDetails.title")}
              witdth="600px"
            >
              <TsReservationDetailsView
                t={t}
                assignmentId={assignment.id}
                hideModal={hideCertificateDetailsModal}
              />
            </Modal>
          )
        }
      </form>
    );
  }
}

const onSubmit = async (values, dispatch, props) => {
  await dispatch(tsAgreementTransactionAction(props.assignment.id,
    {
      isAccepted: true,
      price: values.toJS().price,
    }))
};

const onSubmitSuccess = (result, dispatch, props) => {
  const {onSuccess, hideModal, t} = props;
  message.success(t('assignments.transaction.performSuccess'));
  hideModal();
  onSuccess();
};

const mapStateToProps = state => ({
  agreement: getAgreementData(state).toJS(),
  stateCertificateDetailsModal: getCertificateDetailsViewModal(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchAgreement: (id) => dispatch(actions.fetchAgreementByPreTransaction(id)),
  updateFormValue: (fieldName, value) => dispatch(change(FORM_AGREEMENT_TRANSACTION, fieldName, value)),
  showCertificateDetailsModal: () => dispatch(showCertificateDetailsViewModal()),
  hideCertificateDetailsModal: () => dispatch(hideCertificateDetailsViewModal()),
  resetAgreement: () => dispatch(agreementResetMaster()),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_AGREEMENT_TRANSACTION,
    validate,
    onSubmit,
    onSubmitSuccess
  })(TsAgreementTransaction)
);
