import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import {Layout} from 'antd';
import {
  ClassifierSubValuesView,
} from '../../containers';

class ClassifierSubValuesViewPage extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        classifierId: PropTypes.string.isRequired,
      }),
    }),
  };

  render() {
    const {t, match, location} = this.props;

    if (location.data !== undefined)
      localStorage.setItem('subId', location.data);

    let container = (
      <ClassifierSubValuesView t={t} classifier={match.params.classifierId}
                               classifierValue={match.params.subClassifierId}
                               classifierSub={match.params.subValuesClassifierId}/>
    );

    return <Layout className="main-container">{container}</Layout>;
  }
}

export default translate(NAMESPACES.classifiers)(ClassifierSubValuesViewPage);
