import {createAsyncAction, normalizeHelper} from '../../utils/asyncHelpers';
import productionUnitCertificateSchema from '../../schemas/productionUnitCertificate';
import {
  productionUnitCertificatesAdd,
  productionUnitCertificatesFetch,
} from '../actionHelpers/endpoints';
import {
  PRODUCTION_UNIT_CERTIFICATES_ADD,
  PRODUCTION_UNIT_CERTIFICATES_DELETE,
  PRODUCTION_UNIT_CERTIFICATES_FETCH,
} from '../../constants/productionUnitActionTypes';
import {identity} from "lodash";

export const fetchProductionUnitCertificates = createAsyncAction(
  PRODUCTION_UNIT_CERTIFICATES_FETCH,
  productionUnitCertificatesFetch,
  normalizeHelper([productionUnitCertificateSchema])
);

export const addProductionUnitCertificate = createAsyncAction(
  PRODUCTION_UNIT_CERTIFICATES_ADD,
  productionUnitCertificatesAdd,
  normalizeHelper(productionUnitCertificateSchema)
);

export const deleteProductionUnitCertificate = createAsyncAction(
  PRODUCTION_UNIT_CERTIFICATES_DELETE,
  identity
);
