import {normalizeHelper, createAsyncAction} from '../../utils/asyncHelpers';
import {TRANSACTION_GOS_FETCH} from '../../constants/actionTypes/transactionGOSViewActionTypes';
import {tradingAccountTransactionFetch} from '../actionHelpers/endpoints';
import {transactionGOSSchema} from '../../schemas';

export const fetchTradingAccountTransaction = createAsyncAction(
  TRANSACTION_GOS_FETCH,
  tradingAccountTransactionFetch,
  normalizeHelper(transactionGOSSchema)
);
