import React from 'react';
import {Layout} from 'antd';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import PropTypes from 'prop-types';
import {TransferringCertificates as TransferringCertificatesContainer} from '../../containers';

class TransferringCertificates extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        accountId: PropTypes.string.isRequired,
      }),
    }),
  };

  render() {
    const {match: {params: {accountId}}, t, accounts} = this.props;

    return (
      <Layout className="main-container">
        <h1>{t('title')}</h1>
        <TransferringCertificatesContainer t={t} accountId={accountId} accounts={accounts}/>
      </Layout>
    );
  }
}

export default translate(NAMESPACES.transferringCertificates)(
  TransferringCertificates
);
