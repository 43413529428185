import {REPORT_GOS_ITEMS_FETCH} from '../constants/actionTypes/reportGosActionTypes';
import {listInitialAsyncPagingState, createAsyncPagingReducer} from '../utils/asyncPagingHelpers';
import {createAction, handleActions} from 'redux-actions';

export const resetGosRenewableReport = createAction('GOS_RENEWABLE_REPORT_RESET_MASTER');

const reportGosReducer = handleActions(
  {
    ...createAsyncPagingReducer(REPORT_GOS_ITEMS_FETCH),
    [resetGosRenewableReport]: state => state.merge(listInitialAsyncPagingState),
  },
  listInitialAsyncPagingState,
);

export const getGosReport = state => {
  return state.get('reportGos');
};

export default reportGosReducer;
