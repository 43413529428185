import {handleActions} from 'redux-actions';
import {Map} from 'immutable';
import _ from 'lodash/fp';
import {
  goNextStepAction,
  goPrevStepAction,
  resetMaster,
} from '../actions/pureActions/loadingUnitActions';
import {selectOwner} from '../actions/pureActions/loadingUnitActions';

const STEPS_COUNT = 2;

const initialState = Map({
  currentStep: 0,
  owner: null
});

export default handleActions(
  {
    [goNextStepAction]: state => {
      return state.merge({
        currentStep: Math.min(state.get('currentStep') + 1, STEPS_COUNT),
      });
    },
    [goPrevStepAction]: state =>
      state.merge({
        currentStep: Math.max(state.get('currentStep') - 1, 0),
      }),
    [resetMaster]: state => state.merge(initialState),
    [selectOwner]: (state, {payload}) => state.set('owner', payload),
  },

  initialState
);

export const getCurrentStep = state =>
  state.getIn(['loadingUnitAdd', 'currentStep']);

export const getOwner = state => state.getIn(['loadingUnitAdd', 'owner']);

export const hasPrevious = _.flow(getCurrentStep, _.lt(0));
export const hasNext = _.flow(getCurrentStep, _.gt(STEPS_COUNT - 1));
export const hasDone = _.flow(getCurrentStep, _.eq(STEPS_COUNT - 1));
