import {
  createValidation,
  validatorPhone,
  validatorEmail,
  validatorMaxLength,
} from '../../utils/formValidators';

import {contactPersonFormFields} from '../../constants/formsAddEquipment';

export default createValidation([
  {
    name: contactPersonFormFields.PERSONAL_CODE,
    validators: validatorMaxLength(11),
  },
  {
    name: contactPersonFormFields.PHONE_NUMBER,
    validators: validatorPhone(),
  },
  {
    name: contactPersonFormFields.EMAIL,
    validators: validatorEmail(),
  },
]);
