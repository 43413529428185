import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form/immutable';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {TextField, ClassificatorField} from '../../atoms';
import {Row, Col, Spin} from 'antd';
import {
  FORM_REGISTER_BANK,
  formRegisterBankFields,
} from '../../constants/formRegister';
import {RegisterActions} from '../../components';
import {
  processAsyncResult,
  parseCharAndSpace,
  parseNumber,
} from '../../utils/formHelpers';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {updateLegalEntityActionCreator} from '../../actions/actionCreators/registerActionCreators';
import {getLegalEntity} from '../../schemas';
import {
  legalEntitySelector,
  getUpdateLegalEntity,
} from '../../reducers/registerReducer';
import validate from './validateSchema';
import './RegisterBank.css';

export class RegisterBank extends Component {
  static propTypes = {
    showRequestChangeModal: PropTypes.func,
  };

  render() {
    const {
      handleSubmit,
      hasPrevious,
      hasDone,
      goPrevious,
      t,
      isAdmin,
      isEditMode,
      isLoading,
      showRequestChangeModal,
    } = this.props;

    const inEditForNotAdmin = isEditMode && !isAdmin;

    return (
      <Spin spinning={isLoading}>
        <form className="form-register-bank" onSubmit={handleSubmit}>
          <h2 className="form-register-bank__title">
            {inEditForNotAdmin ? t('bank.titleView') : t('bank.title')}
          </h2>
          <Row gutter={32}>
            <Col span={12}>
              <Field
                name={formRegisterBankFields.RECEIVER_NAME}
                component={TextField}
                type="text"
                label={t('bank.receiverNameLabel')}
                parse={parseCharAndSpace}
                disabled={inEditForNotAdmin}
                required
              />
            </Col>
            <Col span={12}>
              <Field
                name={formRegisterBankFields.RECEIVER_REGISTRY_CODE}
                component={TextField}
                type="text"
                parse={parseNumber}
                label={t('bank.receiverRegistryCodeLabel')}
                disabled={inEditForNotAdmin}
                required
              />
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={12}>
              <Field
                name={formRegisterBankFields.REFERENCE_NUMBER}
                component={TextField}
                type="text"
                parse={parseNumber}
                label={t('bank.referenceNumberLabel')}
                disabled={inEditForNotAdmin}
              />
            </Col>
            <Col span={12}>
              <Field
                name={formRegisterBankFields.RECEIVER_KMKR}
                component={TextField}
                type="text"
                label={t('bank.receiverKMKRLabel')}
                disabled={inEditForNotAdmin}
                required
              />
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={12}>
              <Field
                label={t('bank.bankNameLabel')}
                name={formRegisterBankFields.BANK_NAME}
                component={ClassificatorField}
                type={ClassificatorField.types.bank}
                disabled={inEditForNotAdmin}
                required
              />
            </Col>
            <Col span={12}>
              <Field
                name={formRegisterBankFields.BANK_ACCOUNT}
                component={TextField}
                type="text"
                label={t('bank.bankAccountLabel')}
                disabled={inEditForNotAdmin}
                required
              />
            </Col>
          </Row>
          <RegisterActions
            showRequestChangeModal={showRequestChangeModal}
            hasPrevious={hasPrevious}
            hasDone={hasDone}
            goPrevious={goPrevious}
            isEditMode={isEditMode}
            showSaveButton={isAdmin || !isEditMode}
            isAdmin={isAdmin}
            t={t}
          />
        </form>
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  initialValues: getLegalEntity(state, legalEntitySelector(state)),
  isLoading: getLoadingSelector(getUpdateLegalEntity)(state),
});

const onSubmit = async (values, dispatch) => {
  const result = await dispatch(updateLegalEntityActionCreator(values.toJS()));
  processAsyncResult(result);
};

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_REGISTER_BANK,
    enableReinitialize: true,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
    onSubmit,
  })(RegisterBank)
);
