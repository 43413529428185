import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {classifierViewFields} from '../../constants/classifier';
import {Table, Button, Modal} from 'antd';
import {TableRowActions} from '../';
import {translateMonthClassificator} from '../../utils/translateHelpers';
import {euroFormater} from '../../utils/formaters';
import {ClassifierPriceEdit} from '../../forms';
import classifierTypes from '../../constants/classificators';

class ClassifierViewTable extends Component {
  static propTypes = {
    dataSource: PropTypes.array,
    t: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    stateModal: PropTypes.bool,
    selectedYear: PropTypes.number.isRequired,
    classifierId: PropTypes.string.isRequired,
  };

  getColumns = (classifierId) => {
    const {t, selectedYear, showModal} = this.props;

    return [
      {
        title: t('table.month'),
        dataIndex: classifierViewFields.MONTH,
        render: translateMonthClassificator,
      },
      {
        title: t('table.price'),
        dataIndex: classifierViewFields.PRICE,
        render: value => {
          if (classifierId === classifierTypes.averageStockPrice || classifierId === classifierTypes.tax) {
            return euroFormater(value);
          }
          return value;
        }
      },
      {
        title: t('table.actions'),
        dataIndex: classifierViewFields.MONTH,
        key: 'actions',
        render: value => {
          let currentYear = new Date().getFullYear();
          let currentMonth = new Date().getMonth() + 1;

          // Get previous month.
          if (currentMonth === 1) {
            currentYear--;
            currentMonth = 12;
          } else {
            currentMonth--;
          }

          if (currentYear !== selectedYear || currentMonth !== value) {
            return null;
          }

          return (
            <TableRowActions>
              <Button
                shape="circle"
                icon="edit"
                size="small"
                type="primary"
                onClick={() => {
                  this.selectedMonth = value;
                  showModal();
                }}
              />
            </TableRowActions>
          );
        },
      },
    ];
  };

  selectedMonth = null;

  hideModal = () => {
    const {hideModal} = this.props;

    this.selectedMonth = null;
    hideModal();
  };

  render() {
    const {
      dataSource,
      t,
      stateModal,
      selectedYear,
      classifierId,
    } = this.props;

    let selectedPrice = 0;
    if (this.selectedMonth) {
      const {price} = dataSource.find(
        item => item.month === this.selectedMonth
      );
      selectedPrice = price;
    }

    return (
      <React.Fragment>
        <Table
          rowKey={classifierViewFields.MONTH}
          dataSource={dataSource}
          columns={this.getColumns(classifierId)}
          pagination={false}
        />
        {stateModal && (
          <Modal
            onCancel={this.hideModal}
            visible
            footer={false}
            title={t('modalPriceTitle')}
            width="400px"
          >
            <ClassifierPriceEdit
              t={t}
              onCancel={this.hideModal}
              onComplete={this.hideModal}
              selectedMonth={this.selectedMonth}
              selectedYear={selectedYear}
              selectedPrice={selectedPrice}
              classifierId={classifierId}
            />
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

export default ClassifierViewTable;
