import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {accountGOSFields, TYPE_B, TYPE_E, TYPE_KHG, TYPE_S} from '../../constants/accountGOS';
import {Button, Col, Modal, Row, Spin, Table} from 'antd';
import _ from 'lodash/fp';
import {TableActions} from '../';
import './accountGOSSearchTable.css';
import {numberFormatter} from "../../utils/formaters";

// const getUniqueSelectedIds = _.flow(
//   _.map(_.get(accountGOSFields.ID_LIST)),
//   _.flatten,
//   _.uniq
// );

const getIds = _.flow(
  _.get(accountGOSFields.IDS),
  _.split(','),
  _.map(_.toInteger)
);

// const filterByType = type =>  _.flow(
//   _.get(accountGOSFields.TYPE),
//   _.map(_.toInteger)
// );

const pushIdList = _.map(item => ({
  ...item,
  [accountGOSFields.ID_LIST]: getIds(item),
}));

const hasField = field => _.flow(_.first, _.get(field), _.isEmpty, x => !x);

export class AccountGOSSearchTable extends Component {
  static propTypes = {
    accountGOS: PropTypes.array,
    totalElements: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    pageSize: PropTypes.number.isRequired,
    searchAccountGOS: PropTypes.func.isRequired,
    goToTransferCertificates: PropTypes.func.isRequired,
    goToAddAuction: PropTypes.func.isRequired,
    sendToMarketGOSCertificates: PropTypes.func.isRequired,
    goToReportCertificates: PropTypes.func.isRequired,
    accountId: PropTypes.string,
    isUserProvider: PropTypes.bool.isRequired,
    isUserTrader: PropTypes.bool.isRequired,
    isUserLoadingOperator: PropTypes.bool.isRequired,
    isAuctionsEnabled: PropTypes.bool.isRequired,
    isBilateralTradingEnabled: PropTypes.bool.isRequired,
  };

  state = {
    selectedType: null,
    selectedRows: [],
    selectedIds: [],
    modalDetails: {},
    modalDetailsVisible: false
  };

  onPageChange = page => {
    const {searchAccountGOS} = this.props;

    this.setState({
      selectedIds: [],
      selectedRows: [],
      selectedType: null
    });
    searchAccountGOS(page);
  };

  handleShowDetailsModal = (details) => {
    this.setState({modalDetails: details});
    this.setState({modalDetailsVisible: true});
  };

  hideDetailModal = () => {
    this.setState({modalDetailsVisible: false});
    this.setState({modalDetails: {}});
  };


  renderModalDetails = () => {
    const {t} = this.props;
    const {
      type,
      expirationDate,
      naturalEnergyAmount,
      calculatedMultiplier,
      calculatedEnergyAmount,
      biofuelType,
      ghgAmount,
      consumptionMonth,
      consumptionYear,
      consumptionProvider,
      consumptionProviderTransactionId,
      productionPeriod,
      productionUnitEicwCode,
      posNumber,
      energyType,
      biofuelComponentCn,
      sustainableBiofuel,
      fuelType,
      feedstock,
      productionPathway,
      lowerCalorificValueKg,
      landUseCategory,
      landUseEmissions,
    } = this.state.modalDetails;

    const isB = type === TYPE_B;
    const isE = type === TYPE_E;
    const isS = type === TYPE_S;
    const isKHG = type === TYPE_KHG;

    return (
      <div>
        <Row className="global-margin-bottom-10">
          <Col>
            <div><b>{t('overview.details.type')}: </b>{t('overview.details.type' + type)}</div>
            <div><b>{t('overview.details.expirationDate')}: </b>{expirationDate}</div>
            {!isKHG &&
              <div><b>{t('overview.details.naturalEnergyAmount')}: </b>{naturalEnergyAmount}</div>}
            {!isKHG &&
              <div><b>{t('overview.details.calculatedMultiplier')}: </b>{calculatedMultiplier}
              </div>}
            {!isKHG &&
              <div><b>{t('overview.details.calculatedEnergyAmount')}: </b>{calculatedEnergyAmount}
              </div>}
            {(isB || isS) && <div><b>{t('overview.details.biofuelType')}: </b>{biofuelType}</div>}
            <div><b>{t('overview.details.ghgAmount')}: </b>{ghgAmount}</div>
          </Col>
        </Row>
        {!isKHG && <Row className="global-margin-bottom-10">
          <Col>
            {!isKHG && <div>
              <b>{t('overview.details.consumptionPeriod')}: </b>{(isB || isE) ? consumptionMonth + '.' : ''}{consumptionYear}
            </div>}
            {(isB || isE) &&
              <div><b>{t('overview.details.consumptionProvider')}: </b>{consumptionProvider}</div>}
            {(isB || isE) && <div>
              <b>{t('overview.details.consumptionProviderTransactionId')}: </b>{consumptionProviderTransactionId}
            </div>}
            {isB && <div><b>{t('overview.details.productionPeriod')}: </b>{productionPeriod}</div>}
            {isB &&
              <div><b>{t('overview.details.productionUnitEicwCode')}: </b>{productionUnitEicwCode}
              </div>}
            {isB && <div><b>{t('overview.details.posNumber')}: </b>{posNumber}</div>}
          </Col>
        </Row>}
        {isB && <Row className="global-margin-bottom-10">
          <Col>
            <div><b>{t('overview.details.energyType')}: </b>{energyType}</div>
            <div><b>{t('overview.details.biofuelComponentCn')}: </b>{biofuelComponentCn}</div>
            <div><b>{t('overview.details.sustainableBiofuel')}: </b>{sustainableBiofuel}</div>
            <div><b>{t('overview.details.fuelType')}: </b>{fuelType}</div>
            <div><b>{t('overview.details.feedstock')}: </b>{feedstock}</div>
            <div><b>{t('overview.details.productionPathway')}: </b>{productionPathway}</div>
            <div><b>{t('overview.details.lowerCalorificValueKg')}: </b>{lowerCalorificValueKg}</div>
            <div><b>{t('overview.details.landUseCategory')}: </b>{landUseCategory}</div>
            <div><b>{t('overview.details.landUseEmissions')}: </b>{landUseEmissions}</div>
          </Col>
        </Row>}
      </div>
    );
  };

  getColumns = () => {
    const {accountGOS} = this.props;

    const {t} = this.props;
    const columns = [];

    columns.push({
      title: t('overview.table.type'),
      dataIndex: accountGOSFields.TYPE,
      render: v => t('overview.details.type' + v)
    });

    if (hasField(accountGOSFields.EXPIRATION_DATE)(accountGOS)) {
      columns.push({
        title: t('overview.table.expirationDate'),
        dataIndex: accountGOSFields.EXPIRATION_DATE,
      });
    }

    columns.push({
      title: t('overview.table.naturalEnergyAmount'),
      dataIndex: accountGOSFields.NATURAL_ENERGY_AMOUNT,
      render: (value, record) => record.reservedNaturalEnergyAmount
        ? `${numberFormatter(value)} (${numberFormatter(record.reservedNaturalEnergyAmount)})`
        : numberFormatter(value)
    });

    columns.push({
      title: t('overview.table.calculatedEnergyAmount'),
      dataIndex: accountGOSFields.CALCULATED_ENERGY_AMOUNT,
      render: (value, record) => record.reservedCalculatedEnergyAmount
        ? `${numberFormatter(value)} (${numberFormatter(record.reservedCalculatedEnergyAmount)})`
        : numberFormatter(value)
    });

    columns.push({
      title: t('overview.table.ghgAmount'),
      dataIndex: accountGOSFields.GHG_AMOUNT,
      render: (value, record) => record.reservedGhgAmount
        ? `${numberFormatter(value)} (${numberFormatter(record.reservedGhgAmount)})`
        : numberFormatter(value)
    });

    return columns;
  };

  getTodaysDate() {
    const td = new Date();
    const today = new Date(
      Date.UTC(td.getFullYear(), td.getMonth() + 1, td.getDate(), 0, 0, 0)
    );

    return today;
  }

  getValidUntilDate(validUntil) {
    let day;
    let month;
    let year;

    if (typeof validUntil !== 'string') {
      day = validUntil.getDate();
      month = validUntil.getMonth() + 1;
      year = validUntil.getFullYear();
    } else {
      day = validUntil.substring(0, 2);
      month = validUntil.substring(3, 5);
      year = validUntil.substring(6, 10);
    }

    return new Date(Date.UTC(year, month, day, 0, 0, 0));
  }

  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      let {selectedType} = this.state;

      if (selectedRows.length === 0) {
        selectedType = null;
      } else if (selectedRows.length === 1) {
        selectedType = selectedRows[0].type;
      }

      this.setState({
        selectedRows: selectedRows.filter(row => row.type === selectedType),
        selectedType
      });
    },
    getCheckboxProps: record => {
      //TODO GREEN-386 quick way to disable actions for Electric TS

      const {selectedType} = this.state;

      return {
        disabled: record.id < 0 || (selectedType !== null && selectedType !== record.type)
      };
    }
  };

  render() {
    const {
      totalElements,
      accountGOS,
      loading,
      page,
      pageSize,
      t,
      goToTransferCertificates,
      goToReportCertificates,
      goToAddAuction,
      isUserProvider,
      isUserTrader,
      isUserLoadingOperator,
      isAuctionsEnabled,
      isBilateralTradingEnabled
    } = this.props;

    const {selectedType, selectedRows} = this.state;

    const accountGOSIdList = pushIdList(accountGOS);

    const isTypeB = selectedType === TYPE_B && isUserTrader;
    const isTypeE = selectedType === TYPE_E && isUserLoadingOperator;
    const isTypeS = selectedType === TYPE_S && isUserProvider;
    const isTypeGhg = selectedType === TYPE_KHG && (isUserTrader || isUserLoadingOperator || isUserProvider);

    const isDisplayTransfer = ((isTypeB || isTypeE || isTypeS) && isBilateralTradingEnabled) || isTypeGhg;
    const isDisplayAuction = (isTypeB || isTypeE || isTypeS || isTypeGhg) && isAuctionsEnabled

    return (
      <div>
        <Spin spinning={loading}>
          <Table
            rowKey={accountGOSFields.ID}
            dataSource={accountGOSIdList}
            columns={this.getColumns()}
            className="account-gos-search-table"
            pagination={{
              onChange: this.onPageChange,
              current: page,
              total: totalElements,
              pageSize,
              defaultCurrent: 1,
            }}
            rowSelection={this.rowSelection}
          />
          {selectedRows.length > 0 ? (
            <TableActions>
              {
                <>
                  {isDisplayTransfer &&
                    <Button type="primary"
                            onClick={() => goToTransferCertificates(selectedType, selectedRows)}>
                      {t('overview.buttonTransfer')}
                    </Button>
                  }

                  {isDisplayAuction &&
                    <Button type="primary"
                            onClick={() => goToAddAuction(selectedType, selectedRows)}
                            className="global-margin-left-10">
                      {t('overview.buttonSendToAuction')}
                    </Button>
                  }
                </>
              }

              {((selectedType !== TYPE_KHG && isUserProvider) ||
                  (selectedType === TYPE_KHG && (isUserTrader || isUserLoadingOperator || isUserProvider))) &&
                <Button type="primary"
                        onClick={() => goToReportCertificates(selectedType, selectedRows)}
                        className="global-margin-left-10">
                  {t('overview.buttonReport')}
                </Button>
              }
            </TableActions>
          ) : (
            totalElements > 0 && (
              <Row gutter={24}>
                <Col span={24} className="text-small">
                  {t('overview.actionInfo')}
                </Col>
              </Row>
            )
          )}
        </Spin>
        <Modal
          title={t('overview.detailsTitle')}
          visible={this.state.modalDetailsVisible}
          footer={false}
          width="400px"
          onCancel={this.hideDetailModal}
        >
          {this.renderModalDetails()}
        </Modal>
      </div>
    );
  }
}

export default AccountGOSSearchTable;
