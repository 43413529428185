import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {SelectLocale as SelectLocaleComponent} from '../../components';
import * as settingsActions from '../../actions/actionCreators/settingsActionCreators';
import {getLocale} from '../../reducers/settingsReducer';
import i18n from '../../i18n';
import classifiersStore from '../../utils/classifiersStore';
import axios from "axios";

export class SelectLocale extends Component {
  static propTypes = {
    changeLocale: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
  };

  onClick = async locale => {
    i18n.changeLanguage(locale, () => this.props.changeLocale(locale));
    axios.defaults.headers.common.lang = locale;
    await classifiersStore.update();
  };

  render() {
    const {locale} = this.props;

    return <SelectLocaleComponent onClick={this.onClick} locale={locale}/>;
  }
}

const mapStateToProps = state => ({
  locale: getLocale(state),
});

const mapDispatchToProps = {
  changeLocale: settingsActions.changeLocaleActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectLocale);
