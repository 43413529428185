import React, {Component} from 'react';
import {Input} from 'antd';
import ComponentWrapper from './ComponentWrapper';
import {textFieldMap} from './componentMappers';

export default class TextField extends Component {
  render() {
    return (
      <ComponentWrapper
        {...this.props}
        mapProps={textFieldMap}
        render={props => <Input {...props} />}
      />
    );
  }
}
