export const ACCOUNT_GOS_LIST_FETCH = 'ACCOUNT_GOS_LIST_FETCH';
export const ACCOUNT_GOS_TRANSACTOINS_LIST_FETCH = 'ACCOUNT_GOS_TRANSACTOINS_LIST_FETCH';
export const ACCOUNT_GOS_REPORT_LIST_FETCH = 'ACCOUNT_GOS_REPORT_LIST_FETCH';
export const ACCOUNT_GOS_PURCHASING_AGREEMENTS_LIST_FETCH = 'ACCOUNT_GOS_PURCHASING_AGREEMENTS_LIST_FETCH';
export const ACCOUNT_GOS_SELLING_AGREEMENTS_LIST_FETCH = 'ACCOUNT_GOS_SELLING_AGREEMENTS_LIST_FETCH';
export const ACCOUNT_GOS_TS_ASSIGNMENTS_LIST_FETCH = 'ACCOUNT_GOS_TS_ASSIGNMENTS_LIST_FETCH';
export const ACCOUNT_GOS_TS_ASSIGNMENTS_COUNT_FETCH = 'ACCOUNT_GOS_TS_ASSIGNMENTS_COUNT_FETCH';
export const ACCOUNT_GOS_AVAILABLE_STATISTICS_FETCH = 'ACCOUNT_GOS_AVAILABLE_STATISTICS_FETCH';
export const ACCOUNT_GOS_AVAILABLE_STATISTICS_RESET = 'ACCOUNT_GOS_AVAILABLE_STATISTICS_RESET';
export const ACCOUNT_GOS_TS_AUCTIONS_LIST_FETCH = 'ACCOUNT_GOS_TS_AUCTIONS_LIST_FETCH';
export const COMPLETED_TS_AUCTIONS_RESULT_FETCH = 'COMPLETED_TS_AUCTIONS_RESULT_FETCH';
export const REFRESH_SEARCH_TIMER = 'REFRESH_SEARCH_TIMER';