import React, {Component} from 'react';
import {Table, Button, Popconfirm} from 'antd';
import {formRegisterRepresentativePersonFields} from '../../constants/formRegister';
import _ from 'lodash';
import {tCommon} from '../../i18n';
import {getDateFromDateTime} from '../../utils';

const pushFullName = item => ({
  ...item,
  fullName: `${_.get(item, [
    'physicalPerson',
    formRegisterRepresentativePersonFields.FIRST_NAME,
  ])} ${_.get(item, [
    'physicalPerson',
    formRegisterRepresentativePersonFields.LAST_NAME,
  ])}`,
});

const pushFullNameToList = list => _.map(list, pushFullName);

class MandatePersonsTable extends Component {
  static propTypes = {};

  getColumns = (t, editRepresent, deleteRepresent, physicalPersonId) => [
    {
      title: t('representation.mandatePersonTitleName'),
      dataIndex: 'fullName',
    },
    {
      title: t('representation.mandatePersonTitlePersonalCode'),
      dataIndex: `physicalPerson.${
        formRegisterRepresentativePersonFields.PERSONAL_CODE
      }`,
    },
    {
      title: t('representation.mandatePersonTitleStartTime'),
      dataIndex: formRegisterRepresentativePersonFields.MANDATE_DATE_START,
      render: dateTime => getDateFromDateTime(dateTime),
    },
    {
      title: t('representation.mandatePersonTitleEndTime'),
      dataIndex: formRegisterRepresentativePersonFields.MANDATE_DATE_END,
      render: dateTime => getDateFromDateTime(dateTime),
    },
    {
      title: t('representation.mandatePersonTitleActions'),
      render: (value, values) => {
        const currentPhysicalPerson = _.get(values, ['physicalPerson', 'id']);
        const isDelete =
          !physicalPersonId ||
          currentPhysicalPerson.toString() !== physicalPersonId.toString();

        return (
          <span>
            <Button onClick={() => editRepresent(this.normalizeValues(values))}>
              {t('representation.mandatePersonButtonEdit')}
            </Button>
            {isDelete && (
              <span>
                <span className="ant-divider"/>
                <Popconfirm
                  title={tCommon('popconfirm.title')}
                  onConfirm={() => deleteRepresent(values.id)}
                  okText={tCommon('popconfirm.buttonYes')}
                  cancelText={tCommon('popconfirm.buttonNo')}
                >
                  <Button>
                    {t('representation.mandatePersonButtonDelete')}
                  </Button>
                </Popconfirm>
              </span>
            )}
          </span>
        );
      },
    },
  ];

  normalizeValues = values => {
    const allowed = _.values(formRegisterRepresentativePersonFields);
    const filterValues = _.keys(values)
      .filter(key => allowed.includes(key))
      .reduce((obj, key) => {
        obj[key] = values[key];
        return obj;
      }, {});

    return {
      ...filterValues,
      name: values.physicalPerson.name,
      lastName: values.physicalPerson.lastName,
      personalCode: values.physicalPerson.personalCode,
      mandateId: values.id,
    };
  };

  render() {
    const {
      data,
      t,
      editRepresent,
      deleteRepresent,
      physicalPersonId,
    } = this.props;

    return (
      <Table
        columns={this.getColumns(
          t,
          editRepresent,
          deleteRepresent,
          physicalPersonId
        )}
        dataSource={pushFullNameToList(data)}
        bordered
        pagination={false}
      />
    );
  }
}

export default MandatePersonsTable;
