import {handleActions} from 'redux-actions';
import {List, Map} from 'immutable';
import {
  changeMeteringPointConsumerAction,
  changeMeteringPointHasDirectConnectionAction,
  changeMeteringPointProdUnitEICWAction,
  changeMeteringPointSelectionAction,
} from '../actions/pureActions/loadingUnitActions';
import {
  createAsyncReducer,
  listInitialAsyncState,
} from '../utils/asyncHelpers';
import {
  LOADING_UNIT_MEASUREMENT_POINTS_FETCH, LOADING_UNIT_MEASUREMENT_POINTS_RESET,
} from '../constants/loadingUnit';

export const MEASUREMENT_POINT_MODIFIED = 'MEASUREMENT_POINT_MODIFIED';

const initialState = Map({
  measurementPoints: listInitialAsyncState,
});

const getNewState = (state, payload, stateKey, payloadKey) => {
  return state.getIn(['measurementPoints', 'data'])
    .toJS()
    .map(row => {
      return row.eicZ === payload.eicZ ? {
        ...row,
        [MEASUREMENT_POINT_MODIFIED]: true,
        [stateKey]: payload[payloadKey]
      } : row;
    });
};

export default handleActions(
  {
    ...createAsyncReducer(LOADING_UNIT_MEASUREMENT_POINTS_FETCH, 'measurementPoints'),
    [changeMeteringPointSelectionAction]: (state, {payload}) => {
      const newDataState = state.getIn(['measurementPoints', 'data'])
        .toJS()
        .map(row => {
          return row.eicZ === payload.eicZ ? {
            ...row,
            [MEASUREMENT_POINT_MODIFIED]: true,
            selected: payload.selected
          } : row;
        });

      return state.setIn(['measurementPoints', 'data'], List(newDataState));
    },
    [changeMeteringPointConsumerAction]: (state, {payload}) => {
      const newDataState = state.getIn(['measurementPoints', 'data'])
        .toJS()
        .map(row => {
          return row.eicZ === payload.eicZ ? {
            ...row,
            [MEASUREMENT_POINT_MODIFIED]: true,
            consumer: payload.consumer
          } : row;
        });

      return state.setIn(['measurementPoints', 'data'], List(newDataState));
    },
    [changeMeteringPointHasDirectConnectionAction]: (state, {payload}) => {
      const newDataState = getNewState(state, payload, 'isDirectlyConnectedToProductionUnit', 'hasDirectConn');
      return state.setIn(['measurementPoints', 'data'], List(newDataState));
    },
    [changeMeteringPointProdUnitEICWAction]: (state, {payload}) => {
      const newDataState = getNewState(state, payload, 'productionUnitEicw', 'eicw');
      return state.setIn(['measurementPoints', 'data'], List(newDataState));
    },
    [LOADING_UNIT_MEASUREMENT_POINTS_RESET]: (state) => {
      return state.setIn(['measurementPoints'], listInitialAsyncState);
    },
  },

  initialState
);

export const getMeasurementPointsState = state =>
  state.getIn(['loadingUnitMeasurementPoint', 'measurementPoints']);

export const getMeasurementPointsData = state =>
  state.getIn(['loadingUnitMeasurementPoint', 'measurementPoints', 'data']);
