import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import './logo.css';

class Logo extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  render() {
    const {t} = this.props;

    return (
      <div className="company-logo">
        {t('company_title')}
        <div className="company-logo__subheader">{t('company_subtitle')}</div>
      </div>
    );
  }
}

export default Logo;
