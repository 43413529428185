import {handleActions} from 'redux-actions';
import {combineReducers} from 'redux-immutable';
import {Map} from 'immutable';
import {
  createAsyncReducer,
  initialAsyncState,
  toSuccess,
  createAsyncSelector,
} from '../utils/asyncHelpers';
import {
  PRODUCTION_UNIT_MEASUREMENT_ADD,
  PRODUCTION_UNIT_MEASUREMENT_DELETE,
  PRODUCTION_UNIT_MEASUREMENT_FETCH,
  PRODUCTION_UNIT_MEASUREMENT_UPDATE,
} from '../constants/productionUnitActionTypes';
import {
  toggleMeasurementForm,
  selectMeasurement,
} from '../actions/pureActions/productionUnitActions';
import {measurement as meteringSchema} from '../schemas';

const point = handleActions(
  {
    ...createAsyncReducer(PRODUCTION_UNIT_MEASUREMENT_FETCH),
    [toSuccess(PRODUCTION_UNIT_MEASUREMENT_ADD)]: (state, {payload}) =>
      state.set('data', payload.result),
    [toSuccess(PRODUCTION_UNIT_MEASUREMENT_DELETE)]: state =>
      state.set('data', null),
  },
  initialAsyncState
);
const ui = handleActions(
  {
    [toggleMeasurementForm]: (state, {payload}) =>
      state.set('showMeasurementForm', payload),
    [selectMeasurement]: (state, {payload}) =>
      state.set('selectedMeasurementId', payload),
    [toSuccess(PRODUCTION_UNIT_MEASUREMENT_ADD)]: (state, {payload}) =>
      state.set('selectedMeasurementId', payload.result),
    [toSuccess(PRODUCTION_UNIT_MEASUREMENT_FETCH)]: (state, {payload}) => {
      if (payload && payload.result) {
        return state.set('selectedMeasurementId', payload.result);
      }
      return state;
    },
  },
  Map({selectedMeasurementId: '0', showMeasurementForm: false})
);
const prodUnitMeasurementPointReducer = combineReducers({
  point,
  ui,
  add: handleActions(
    createAsyncReducer(PRODUCTION_UNIT_MEASUREMENT_ADD),
    initialAsyncState
  ),
  edit: handleActions(
    createAsyncReducer(PRODUCTION_UNIT_MEASUREMENT_UPDATE),
    initialAsyncState
  ),
  delete: handleActions(
    createAsyncReducer(PRODUCTION_UNIT_MEASUREMENT_DELETE),
    initialAsyncState
  ),
});

export const getMeteringPoint = state =>
  state.getIn(['prodUnitMeasurementPoint', 'point']);

export const getProdUnitMeasurementAddState = state =>
  state.getIn(['prodUnitMeasurementPoint', 'add']);

export const getProdUnitMeasurementEditState = state =>
  state.getIn(['prodUnitMeasurementPoint', 'update']);

export const getProdUnitMeasurementDeleteState = state =>
  state.getIn(['prodUnitMeasurementPoint', 'delete']);

export const getProdUnitMeasurementFormState = state =>
  state.getIn(['prodUnitMeasurementPoint', 'ui', 'showMeasurementForm']);

export const getProdUnitSelectedMeasurement = state =>
  state.getIn(['prodUnitMeasurementPoint', 'ui', 'selectedMeasurementId']);

export const getMeteringPointData = createAsyncSelector(
  meteringSchema,
  getMeteringPoint
);

export default prodUnitMeasurementPointReducer;
