import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {gridOperator} from '../../constants/parameters';
import {Table, Button, Popconfirm} from 'antd';
import {TableRowActions} from '../';

class ParameterGridOperatorTable extends Component {
  static propTypes = {
    dataSource: PropTypes.array,
    t: PropTypes.func.isRequired,
    deleteClassifier: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
  };

  getColumns = () => {
    const {t, deleteClassifier, showModal} = this.props;

    return [
      {
        title: t('tableGridOperator.code'),
        dataIndex: gridOperator.CODE,
      },
      {
        title: t('tableGridOperator.name'),
        dataIndex: gridOperator.NAME,
      },
      {
        title: t('tableGridOperator.email'),
        dataIndex: gridOperator.EMAIL,
      },
      {
        title: t('tableGridOperator.date'),
        dataIndex: gridOperator.DATE_CRON,
      },
      {
        title: t('tableGridOperator.actions'),
        render: value => (
          <TableRowActions>
            <Button
              shape="circle"
              icon="edit"
              size="small"
              type="primary"
              onClick={() => showModal(value[gridOperator.CODE])}
            />
            <span className="ant-divider"/>
            <Popconfirm
              title={t('popconfirm.title')}
              onConfirm={() => deleteClassifier(value[gridOperator.CODE])}
              okText={t('popconfirm.buttonYes')}
              cancelText={t('popconfirm.buttonNo')}
            >
              <Button
                shape="circle"
                icon="delete"
                size="small"
                type="primary"
              />
            </Popconfirm>
          </TableRowActions>
        ),
      },
    ];
  };

  render() {
    const {dataSource} = this.props;

    return (
      <React.Fragment>
        <Table
          rowKey={gridOperator.CODE}
          dataSource={dataSource}
          columns={this.getColumns()}
          pagination={false}
        />
      </React.Fragment>
    );
  }
}

export default ParameterGridOperatorTable;
