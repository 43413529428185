import {normalizeHelper} from '../../utils/asyncHelpers';
import {
  createAsyncPagingAction,
  createAsyncActionStoreWrapper,
} from '../../utils/asyncPagingHelpers';
import {
  TRANSACTION_FOR_GOS_SEARCH_FETCH
} from '../../constants/actionTypes/transactionForGosSearchActionTypes';
import {searchTATransactions} from '../actionHelpers/endpoints';
import {transactionGOSSchema} from '../../schemas';
import {getFormValues} from 'redux-form/immutable';
import {getTransactionList} from '../../reducers/transactionSearchReducer';
import {FORM_TRANSACTION_FOR_GOS_SEARCH} from '../../constants/formKeys';

const _searchTransactionsForGos = createAsyncPagingAction(
  TRANSACTION_FOR_GOS_SEARCH_FETCH,
  searchTATransactions,
  normalizeHelper([transactionGOSSchema])
);

const filterFormSelector = getFormValues(FORM_TRANSACTION_FOR_GOS_SEARCH);

export const searchTATransactionsAction = createAsyncActionStoreWrapper(
  _searchTransactionsForGos,
  filterFormSelector,
  getTransactionList
);
