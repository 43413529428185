import {createAsyncAction, normalizeHelper} from '../../utils/asyncHelpers';
import * as actionTypes from '../../constants/notificationActionTypes';
import {NOTIFICATION_COUNT_FETCH} from '../../constants/notificationActionTypes';
import {notification} from '../../schemas';
import {
  getNotificationsCount,
  notificationsAdminApprove,
  notificationsAdminDecline,
  notificationsFetch,
  notificationsRemove,
} from '../actionHelpers/endpoints';
import {hideNotificationViewModal} from '../actionCreators/modalsActionCreators';

const closeModal = (data, dispatch) => {
  dispatch(hideNotificationViewModal());
  return data;
};

export const fetchNotifications = createAsyncAction(
  actionTypes.NOTIFICATION_FETCH,
  notificationsFetch,
  normalizeHelper([notification])
);

export const fetchNotificationsCount = createAsyncAction(
  NOTIFICATION_COUNT_FETCH,
  getNotificationsCount,
);

export const approveNotification = createAsyncAction(
  actionTypes.NOTIFICATION_ADMIN_APPROVE,
  notificationsAdminApprove,
  closeModal
);

export const declineNotification = createAsyncAction(
  actionTypes.NOTIFICATION_ADMIN_DECLINE,
  notificationsAdminDecline,
  closeModal
);

export const closeNotification = createAsyncAction(
  actionTypes.NOTIFICATION_COMPANY_CLOSE,
  notificationsRemove,
  closeModal
);
