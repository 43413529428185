import {createAsyncReducer, createAsyncSelector, initialAsyncState,} from '../utils/asyncHelpers';
import {handleActions} from 'redux-actions';
import {Map} from 'immutable';
import {AGREEMENT_WITHDRAW_FETCH} from "../constants/actionTypes/agreementWithdrawViewActionsTypes";
import agreementWithdraw from "../schemas/agreementWithdraw";

const initialState = Map({
  view: initialAsyncState,
});

const agreementWithdrawView = handleActions(
  {
    ...createAsyncReducer(AGREEMENT_WITHDRAW_FETCH, 'view'),
  },
  initialState
);

export const getAgreementWithdraw = state => state.getIn(['agreementWithdrawView', 'view']);

export const getAgreementWithdrawData = createAsyncSelector(
  agreementWithdraw,
  getAgreementWithdraw
);

export default agreementWithdrawView;
