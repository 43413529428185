import React from 'react';
import PropTypes from 'prop-types';

class InvoiceViewItemColComponent extends React.Component {
  static propTypes = {
    itemTitle: PropTypes.string.isRequired,
    itemValue: PropTypes.any,
  };

  render() {
    const {itemTitle, itemValue} = this.props;

    return (
      <React.Fragment>
        <b>{itemTitle}: </b> {itemValue}
      </React.Fragment>
    );
  }
}

export default InvoiceViewItemColComponent;
