import React from 'react';
import {AutoCompleteSelectGOS} from '../';

const getDataSource = searchByName => async value => {
  const {data} = await searchByName(value);
  return data.map(item => ({
    value: item.id,
    label: `${item.companyName} [${item.registrationCode}] `,
  }));
};

function AutoCompleteLegalEntitySelect({
                                         value,
                                         onChange,
                                         searchByName,
                                         placeholder,
                                         unacceptableValues
                                       }) {
  return (
    <AutoCompleteSelectGOS
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      unacceptableValues={unacceptableValues}
      getDataSource={getDataSource(searchByName)}
    />
  );
}

export default AutoCompleteLegalEntitySelect;
