import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import {Steps} from 'antd';
import {AddLoadingUnitGeneral, RegisterElectricMeasurementPoints} from '../../forms';
import * as loadingUnitSelectors from '../../reducers/legalEntityLoadingUnitsReducer';
import * as loadingUnitAddSelectors from '../../reducers/loadingUnitAddReducer';
import * as loadingUnitMeasurementPoint from '../../reducers/loadingUnitMeasurementPointReducer';
import {
  goNextActionCreator,
  goPrevActionCreator,
  resetMasterActionCreator,
} from '../../actions/actionCreators/loadingUnitActionCreator';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import PropTypesImmutable from 'react-immutable-proptypes';
import {getLoadingUnit} from '../../schemas/loadingUnit';

const steps = [
  AddLoadingUnitGeneral,
  RegisterElectricMeasurementPoints,
];

export class LoadingUnitAdd extends Component {
  static defaultProps = {
    isLoadingUnitAdd: false,
    allowToSelectCompany: false,
  };

  static propTypes = {
    t: PropTypes.func.isRequired,
    currentStep: PropTypes.number.isRequired,
    goPrevious: PropTypes.func.isRequired,
    onSubmitSuccess: PropTypes.func.isRequired,
    goNext: PropTypes.func.isRequired,
    hasDone: PropTypes.bool.isRequired,
    hasNext: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    hasPrevious: PropTypes.bool.isRequired,
    loadingUnit: PropTypesImmutable.map,
    legalEntityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isLoadingUnitAdd: PropTypes.bool,
    allowToSelectCompany: PropTypes.bool
  };

  componentDidMount = () => {
    this.props.resetMaster();
  };

  render() {
    const {
      t,
      currentStep,
      goPrevious,
      onSubmitSuccess,
      goNext,
      hasDone,
      hasNext,
      hasPrevious,
      legalEntityId,
      isLoading,
      loadingUnit,
      isEditMode,
      isAdmin,
      isLoadingUnitAdd,
      allowToSelectCompany
    } = this.props;

    const StepComponent = steps[currentStep];

    return (
      <div>
        <Steps size="big" current={currentStep}>
          <Steps.Step title={t('title_general')}/>
          <Steps.Step title={t('title_metering_point')}/>
        </Steps>
        <div className="page-register__content">
          <StepComponent
            hasPrevious={hasPrevious}
            goPrevious={goPrevious}
            onSubmitSuccess={hasNext ? goNext : onSubmitSuccess}
            hasDone={hasDone}
            t={t}
            isLoading={isLoading}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
            inEditForNotAdmin={isEditMode && !isAdmin}
            legalEntityId={legalEntityId}
            loadingUnit={loadingUnit}
            isLoadingUnitAdd={isLoadingUnitAdd}
            allowToSelectCompany={allowToSelectCompany}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentStep: loadingUnitAddSelectors.getCurrentStep(state),
  hasDone: loadingUnitAddSelectors.hasDone(state),
  hasNext: loadingUnitAddSelectors.hasNext(state),
  hasPrevious: loadingUnitAddSelectors.hasPrevious(state),
  loadingUnit: getLoadingUnit(state, loadingUnitSelectors.getLoadingUnitsEditState(state)),
  isLoading: getLoadingSelector(
    loadingUnitSelectors.getLoadingUnitsAddState,
    loadingUnitMeasurementPoint.getMeasurementPointsState,
  )(state),
});

const mapDispatchToProps = {
  goNext: goNextActionCreator,
  goPrevious: goPrevActionCreator,
  resetMaster: resetMasterActionCreator,
};

export default translate(NAMESPACES.loadingUnit)(
  connect(mapStateToProps, mapDispatchToProps)(LoadingUnitAdd)
);
