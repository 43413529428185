import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Select as AntSelect} from 'antd';

const {Option} = AntSelect;

export default class Select extends Component {
  static propTypes = {
    valueKey: PropTypes.string,
    labelKey: PropTypes.string,
    options: PropTypes.array.isRequired,
  };

  static defaultProps = {
    valueKey: 'value',
    labelKey: 'label',
  };

  render() {
    const {
      valueKey,
      labelKey,
      value,
      defaultValue,
      options,
      ...restProps
    } = this.props;

    const valueCurrent = value || defaultValue || undefined;

    return (
      <AntSelect className="date-field" {...restProps} value={valueCurrent}>
        {options.map(
          ({[valueKey]: valueOption, [labelKey]: label, ...rest}, key) => (
            <Option {...rest} key={key} value={valueOption}>
              {label || '[empty label]'}
            </Option>
          )
        )}
      </AntSelect>
    );
  }
}
