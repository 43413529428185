import {handleActions} from 'redux-actions';
import {combineReducers} from 'redux-immutable';
import {createAsyncReducer, initialAsyncState} from '../utils/asyncHelpers';
import {
  REGISTER_MANDATE_FETCH,
  REGISTER_MANDATE_UPDATE,
} from '../constants/registerActionTypes';

const registerGeneralReducer = combineReducers({
  id: handleActions(
    createAsyncReducer(REGISTER_MANDATE_FETCH),
    initialAsyncState
  ),
  delete: handleActions(
    createAsyncReducer(REGISTER_MANDATE_UPDATE),
    initialAsyncState
  ),
});

export const registerMandateIdSelector = state =>
  state.getIn(['registerGeneral', 'id']);

export const entityDocumentsUpdateStateSelector = state =>
  state.getIn(['registerGeneral', 'add']);

export default registerGeneralReducer;
