import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {consumerGoReportDetailsFields} from '../../constants/consumerGoReport';
import {
  translateFuelTypeClassificator,
  translateCleaningTechnologyClassificator,
} from '../../utils/translateHelpers';
import {Table} from 'antd';

class ConsumerGoTableDetails extends Component {
  static propTypes = {
    consumerGoDetails: PropTypes.array,
    t: PropTypes.func.isRequired,
  };

  getColumns = () => {
    const {t} = this.props;

    return [
      {
        title: t('goTable.details.amount'),
        dataIndex: consumerGoReportDetailsFields.AMOUNT,
      },
      {
        title: t('goTable.details.technologyType'),
        dataIndex: consumerGoReportDetailsFields.TECHNOLOGY_TYPE,
        render: translateCleaningTechnologyClassificator,
      },
      {
        title: t('goTable.details.fuelType'),
        dataIndex: consumerGoReportDetailsFields.FUEL_TYPE,
        render: translateFuelTypeClassificator,
      },
      {
        title: t('goTable.details.country'),
        dataIndex: consumerGoReportDetailsFields.PRODUCTION_COUNTRY,
      },
    ];
  };

  render() {
    const {consumerGoDetails} = this.props;

    return (
      <Table
        rowKey={consumerGoReportDetailsFields.FUEL_TYPE}
        dataSource={consumerGoDetails}
        columns={this.getColumns()}
        pagination={false}
      />
    );
  }
}

export default ConsumerGoTableDetails;
