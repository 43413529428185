import {createAsyncAction} from '../../utils/asyncHelpers';
import {REQUEST_CHANGE} from '../../constants/actionTypes/requestChangeActionTypes';
import {
  legalEntityChangeRequest,
  productionUnitChangeRequest,
  meteringPointChangeRequest,
} from '../actionHelpers/endpoints';

export const requestChangeLegalEntity = createAsyncAction(
  REQUEST_CHANGE,
  legalEntityChangeRequest
);

export const requestChangeProductionUnit = createAsyncAction(
  REQUEST_CHANGE,
  productionUnitChangeRequest
);

export const requestChangeMeteringPoint = createAsyncAction(
  REQUEST_CHANGE,
  meteringPointChangeRequest
);
