import {createAsyncReducer, createAsyncSelector, initialAsyncState,} from '../utils/asyncHelpers';
import {handleActions} from 'redux-actions';
import {Map} from 'immutable';
import {
  ACCOUNT_GOS_AGREEMENT_TRANSACTIONS_LIST_FETCH,
  AGREEMENT_FETCH,
  AGREEMENT_TRANSACTION_TRANSPORT_STATISTICS_FETCH
} from "../constants/actionTypes/agreementViewActionTypes";
import agreement from "../schemas/agreement";
import {resetMaster} from "../actions/pureActions/agreementActions";
import {createAsyncPagingReducer, listInitialAsyncPagingState} from "../utils/asyncPagingHelpers";
import {accountGOSAgreementTransactions} from "../schemas";
import accountGOSAvailableStatistics from "../schemas/accountGOSAvailableStatistics";

const initialState = Map({
  view: initialAsyncState,
  transportStatistics: initialAsyncState,
  transactions: listInitialAsyncPagingState,
});

const agreementView = handleActions(
  {
    ...createAsyncPagingReducer(ACCOUNT_GOS_AGREEMENT_TRANSACTIONS_LIST_FETCH, 'transactions'),
    ...createAsyncReducer(AGREEMENT_FETCH, 'view'),
    ...createAsyncReducer(AGREEMENT_TRANSACTION_TRANSPORT_STATISTICS_FETCH, 'transportStatistics'),
    [resetMaster]: state =>
      state.merge({
        view: initialAsyncState,
        transportStatistics: initialAsyncState,
        transactions: listInitialAsyncPagingState
      }),
  },
  initialState
);

export const getAgreement = state => state.getIn(['agreementView', 'view']);

export const getAgreementData = createAsyncSelector(
  agreement,
  getAgreement
);

export const getTransportStatisticsDetails = state => {
  return state.getIn(['agreementView', 'transportStatistics'])
}

export const getTransportStatisticsDetailsEntity = createAsyncSelector(
  accountGOSAvailableStatistics,
  getTransportStatisticsDetails
)

export const getTransactionRecall = state =>
  state.getIn(['agreementView', 'recall']);


export const getAgreementTransactionList = state => {
  return state.getIn(['agreementView', 'transactions']);
};

export const getAgreementTransactionsEntities = createAsyncSelector(
  [accountGOSAgreementTransactions],
  getAgreementTransactionList
);

export default agreementView;
