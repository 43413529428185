import {
  createValidation,
  validatorRequired,
  validatorNumber,
  validatorZip,
} from '../../utils/formValidators';

import {formRegisterMeasurementFields} from '../../constants/formRegister';

const validatorRequiredForAdmin = (value, form, props) =>
  props.isAdmin ? validatorRequired()(value) : undefined;

export default createValidation([
  {
    name: formRegisterMeasurementFields.ZIP_CODE,
    validators: [validatorRequired(), validatorNumber(), validatorZip()],
  },
  {
    name: formRegisterMeasurementFields.ADDRESS,
    validators: validatorRequired(),
  },
  {
    name: formRegisterMeasurementFields.DISTRICT,
    validators: validatorRequired(),
  },
  {
    name: formRegisterMeasurementFields.CITY,
    validators: validatorRequired(),
  },
  {
    name: formRegisterMeasurementFields.COUNTY,
    validators: validatorRequired(),
  },
  {
    name: formRegisterMeasurementFields.EICZ_CODE,
    validators: validatorRequiredForAdmin,
  },
]);
