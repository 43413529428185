import React from 'react';
import {ProductionUnitList} from '../../containers';
import {ProductionUnitFilter} from '../../forms';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';

const ProductionUnitSearch = props => (
  <div className="main-container">
    <ProductionUnitFilter {...props} />
    <ProductionUnitList {...props} />
  </div>
);

export default translate(NAMESPACES.productionUnitSearch)(ProductionUnitSearch);
