import {handleActions} from 'redux-actions';
import {Map} from 'immutable';
import * as actions from '../actions/pureActions/settingsActions';
import {createAsyncReducer, initialAsyncState} from "../utils/asyncHelpers";
import {APPLICATION_SETTINGS_FETCH} from "../constants/actionTypes/settingsActionTypes";

const initialState = Map({
  locale: 'en',
  applicationSettings: initialAsyncState
});

export default handleActions(
  {
    ...createAsyncReducer(APPLICATION_SETTINGS_FETCH),
    [actions.changeLocaleAction]: (state, {payload}) =>
      state.merge({locale: payload}),
  },
  initialState
);

export const getLocale = state => state.get('settings').get('locale');
