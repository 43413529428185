import React from 'react';
import {Layout} from 'antd';
import {TradingPlatform as TradingPlatformContainer} from '../../containers';

class TradingPlatform extends React.Component {
  render() {
    return (
      <Layout className="main-container">
        <Layout.Content>
          <TradingPlatformContainer/>
        </Layout.Content>
      </Layout>
    );
  }
}

export default TradingPlatform;
