import React, {Component} from 'react';
import {searchPersonsAndCompanies} from '../../actions/actionHelpers/endpoints';
import {AutoCompleteSelectField} from '../';
import {consumerType} from '../../constants/classificators';

const getDataSource = async value => {
  const {data} = await searchPersonsAndCompanies(value);
  return data.map(item => ({
    value: item.id,
    label: `${item.name}, ${
      item.consumerType === consumerType.CONSUMER
        ? item.personalCode
        : item.registrationCode
    }`,
  }));
};

export class AutoCompleteConsumersField extends Component {

  render() {
    return <AutoCompleteSelectField {...this.props} getDataSource={getDataSource}/>
  }
}

export default AutoCompleteConsumersField;
