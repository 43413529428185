import {normalizeHelper, createAsyncAction} from '../../utils/asyncHelpers';
import {
  createAsyncPagingAction,
  createAsyncActionStoreWrapper,
} from '../../utils/asyncPagingHelpers';
import {
  INVOICE_SEARCH_FETCH,
  INVOICE_CONFIRM,
} from '../../constants/actionTypes/invoiceActionTypes';
import {
  invoicesSearch,
  invoiceConfirm,
} from '../actionHelpers/endpoints';
import {invoiceSchema} from '../../schemas';
import {getFormValues, submit} from 'redux-form/immutable';
import {FORM_INVOICE_CONFIRM, FORM_INVOICE_FILTER} from '../../constants/formKeys';
import {getInvoiceList} from '../../reducers/invoiceSearchReducer';
import {selectInvoice as selectInvoicePure} from '../pureActions/invoiceActions';

const _searchInvoices = createAsyncPagingAction(
  INVOICE_SEARCH_FETCH,
  invoicesSearch,
  normalizeHelper([invoiceSchema])
);

const filterFormSelector = getFormValues(FORM_INVOICE_FILTER);

export const searchInvoices = createAsyncActionStoreWrapper(
  _searchInvoices,
  filterFormSelector,
  getInvoiceList
);

export const selectInvoice = invoiceId => dispatch =>
  dispatch(selectInvoicePure(invoiceId));

export const confirmInvoice = () => dispatch => {
  dispatch(submit(FORM_INVOICE_CONFIRM));
};

export const confirmInvoicePost = createAsyncAction(
  INVOICE_CONFIRM,
  invoiceConfirm,
  normalizeHelper(invoiceSchema)
);
