import {Component} from "react";
import {connect} from "react-redux";
import {getLoadingSelector} from "../../utils/asyncHelpers";
import * as selectors from "../../reducers/lockingGOSCertificateReducer";
import * as actions from "../../actions/actionCreators/gosCertificateActionCreators";
import PropTypes from "prop-types";
import PropTypesImmutable from "react-immutable-proptypes";
import {Spin} from "antd";
import {TradingAccountOverviewDetailsTable} from "../../components";
import React from "react";
import {YearSelect} from "../../atoms";
import {getSelectedReportRowsData} from "../../reducers/lockingGOSCertificateReducer";
import {TYPE_B, TYPE_E, TYPE_KHG, TYPE_S} from "../../constants/accountGOS";

class TradingAccountLock extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    selectReceiver: PropTypes.func.isRequired,
    tradingAccountLockAction: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    groupedGOSCertificates: PropTypesImmutable.list.isRequired,
    type: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    const curDate = new Date();
    // Before 16th January, display the previous year period by default
    const pre15th = curDate.getMonth() === 0 && curDate.getDate() < 16;
    this.state = {
      pre15th,
      bioDatePeriod: pre15th
        ? curDate.getFullYear() - 1
        : curDate.getFullYear(),
      isB: props.type === TYPE_B,
      isE: props.type === TYPE_E,
      isS: props.type === TYPE_S,
      isKHG: props.type === TYPE_KHG
    };
  }

  render() {
    const {
      isLoading,
      t,
      type,
      groupedGOSCertificates,
      tradingAccountLockAction
    } = this.props;

    const {pre15th, bioDatePeriod, isB, isE, isS, isKHG} = this.state;

    if (!isLoading && groupedGOSCertificates.isEmpty()) {
      return t('emptyLocking');
    }

    return (
      <Spin spinning={isLoading}>
        {(isB || isE) && (<h2>{t('defineBioAdditionPeriod')}</h2>)}
        {(isB || isE) && (
          <YearSelect
            yearDistance={pre15th ? 1 : 0}
            beforeCurrent
            onChange={value => {
              this.setState({
                bioDatePeriod: value,
              });
            }}
            value={bioDatePeriod}
          />)}
        {((typeof bioDatePeriod === 'number') || isS || isKHG) && (
          <React.Fragment>
            <h2 className="global-margin-top-24">{t(`define${type}Amount`)}</h2>
            <TradingAccountOverviewDetailsTable
              t={t}
              type={type}
              popconfirmTitle={t('popconfirmTitleLock')}
              okText={t('popconfirmButtonYes')}
              cancelText={t('popconfirmButtonNo')}
              dataList={groupedGOSCertificates.toJS()}
              isLocking
              performAction={rows =>
                tradingAccountLockAction({
                  period: bioDatePeriod,
                  rows
                })
              }
            />
          </React.Fragment>
        )}
      </Spin>
    );
  }
}

const getLoading = getLoadingSelector(
  getSelectedReportRowsData,
  selectors.getLocking
);

const mapStateToProps = state => ({
  isLoading: getLoading(state),
  groupedGOSCertificates: getSelectedReportRowsData(state)
});

const mapDispatchToProps = {
  tradingAccountLockAction: actions.tradingAccountLockAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  TradingAccountLock
);
