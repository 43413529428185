import {createAsyncAction} from '../../utils/asyncHelpers';
import {
  CLASSIFIER_EAVP_LOAD_CHANGE,
  CLASSIFIER_EAVP_LOAD_FETCH,
  CLASSIFIER_ELERING_CHANGE,
  CLASSIFIER_ELERING_FETCH,
  CLASSIFIER_GENERAL_CHANGE,
  CLASSIFIER_GENERAL_FETCH,
  CLASSIFIER_GO_AUTO_CHANGE,
  CLASSIFIER_GO_AUTO_FETCH,
  CLASSIFIER_GOS_AUTO_CHANGE,
  CLASSIFIER_GOS_AUTO_FETCH,
  CLASSIFIER_GRID_OPERATOR_CHANGE,
  CLASSIFIER_GRID_OPERATOR_DELETE,
  CLASSIFIER_GRID_OPERATOR_FETCH,
  CLASSIFIER_GRID_OPERATOR_LIST_FETCH,
  CLASSIFIER_INVOICE_CONFIRMATION_CHANGE,
  CLASSIFIER_INVOICE_CONFIRMATION_FETCH,
  CLASSIFIER_INVOICE_DATE_CHANGE,
  CLASSIFIER_INVOICE_DATE_FETCH,
  CLASSIFIER_ITEM_FETCH,
  CLASSIFIER_LIST_FETCH,
  CLASSIFIER_PRICE_CHANGE,
  CLASSIFIER_SELLER_CHANGE,
  CLASSIFIER_SELLER_DELETE,
  CLASSIFIER_SELLER_FETCH,
  CLASSIFIER_SELLER_LIST_FETCH,
  CLASSIFIER_SUB_CHANGE,
  CLASSIFIER_SUB_DELETE,
  CLASSIFIER_SUB_FETCH,
  CLASSIFIER_SUB_VALUES_CHANGE,
  CLASSIFIER_SUB_VALUES_DELETE,
  CLASSIFIER_SUB_VALUES_FETCH,
  CLASSIFIER_SUBSIDY_DATE_CHANGE,
  CLASSIFIER_SUBSIDY_DATE_FETCH,
  CLASSIFIER_TAX_CHANGE,
  CLASSIFIER_TAX_FETCH
} from '../../constants/actionTypes/classifiersActionTypes';
import {
  classifiersEAVPLoadChange,
  classifiersEAVPLoadFetch,
  classifiersEleringChange,
  classifiersEleringFetch,
  classifiersGeneralChange,
  classifiersGeneralFetch,
  classifiersGoAutoChange,
  classifiersGoAutoFetch,
  classifiersGosAutoChange,
  classifiersGosAutoFetch,
  classifiersGridOperatorChange,
  classifiersGridOperatorDelete,
  classifiersGridOperatorFetch,
  classifiersGridOperatorListFetch,
  classifiersInvoiceConfirmationChange,
  classifiersInvoiceConfirmationFetch,
  classifiersInvoiceDateChange,
  classifiersInvoiceDateFetch,
  classifiersItemFetch,
  classifiersListFetch,
  classifiersPriceChange,
  classifiersSellerChange,
  classifiersSellerDelete,
  classifiersSellerFetch,
  classifiersSellerListFetch,
  classifiersSubChange,
  classifiersSubDelete,
  classifiersSubFetch,
  classifiersSubsidyDateChange,
  classifiersSubsidyDateFetch,
  classifiersSubValuesChange,
  classifiersSubValuesDelete,
  classifiersSubValuesFetch,
  classifiersTaxChange,
  classifiersTaxFetch
} from '../actionHelpers/endpoints';
import {classifierGeneralField, classifierGeneralItemField,} from '../../constants/classifier';
import {mapPropertiesToObject} from '../../utils/classifiersStore';

export const fetchClassifiers = createAsyncAction(
  CLASSIFIER_LIST_FETCH,
  classifiersListFetch
);

export const fetchClassifierItem = createAsyncAction(
  CLASSIFIER_ITEM_FETCH,
  classifiersItemFetch
);

export const changeClassifierPrice = createAsyncAction(
  CLASSIFIER_PRICE_CHANGE,
  classifiersPriceChange,
  (data, dispatch) => {
    const {year, code} = data;
    dispatch(
      fetchClassifierItem({
        year,
        classifierId: code,
      })
    );
    return data;
  }
);

export const fetchClassifierTax = createAsyncAction(
  CLASSIFIER_TAX_FETCH,
  classifiersTaxFetch
);

export const changeClassifierTax = createAsyncAction(
  CLASSIFIER_TAX_CHANGE,
  classifiersTaxChange
);

export const fetchClassifierGoAuto = createAsyncAction(
  CLASSIFIER_GO_AUTO_FETCH,
  classifiersGoAutoFetch
);

export const fetchClassifierEAVPLoad = createAsyncAction(
  CLASSIFIER_EAVP_LOAD_FETCH,
  classifiersEAVPLoadFetch
);

export const fetchClassifierGosAuto = createAsyncAction(
  CLASSIFIER_GOS_AUTO_FETCH,
  classifiersGosAutoFetch
);

export const changeClassifierGoAuto = createAsyncAction(
  CLASSIFIER_GO_AUTO_CHANGE,
  classifiersGoAutoChange
);

export const changeClassifierEAVPLoad = createAsyncAction(
  CLASSIFIER_EAVP_LOAD_CHANGE,
  classifiersEAVPLoadChange
);

export const changeClassifierGosAuto = createAsyncAction(
  CLASSIFIER_GOS_AUTO_CHANGE,
  classifiersGosAutoChange
);

export const fetchClassifierInvoiceDate = createAsyncAction(
  CLASSIFIER_INVOICE_DATE_FETCH,
  classifiersInvoiceDateFetch
);

export const changeClassifierInvoiceDate = createAsyncAction(
  CLASSIFIER_INVOICE_DATE_CHANGE,
  classifiersInvoiceDateChange
);

export const fetchClassifierSubsidyDate = createAsyncAction(
  CLASSIFIER_SUBSIDY_DATE_FETCH,
  classifiersSubsidyDateFetch
);

export const changeClassifierSubsidyDate = createAsyncAction(
  CLASSIFIER_SUBSIDY_DATE_CHANGE,
  classifiersSubsidyDateChange
);

export const fetchClassifierElering = createAsyncAction(
  CLASSIFIER_ELERING_FETCH,
  classifiersEleringFetch
);

export const changeClassifierElering = createAsyncAction(
  CLASSIFIER_ELERING_CHANGE,
  classifiersEleringChange
);

export const fetchClassifierGridOperatorList = createAsyncAction(
  CLASSIFIER_GRID_OPERATOR_LIST_FETCH,
  classifiersGridOperatorListFetch
);

export const deleteClassifierGridOperator = createAsyncAction(
  CLASSIFIER_GRID_OPERATOR_DELETE,
  classifiersGridOperatorDelete
);

export const fetchClassifierGridOperator = createAsyncAction(
  CLASSIFIER_GRID_OPERATOR_FETCH,
  classifiersGridOperatorFetch
);

export const changeClassifierGridOperator = createAsyncAction(
  CLASSIFIER_GRID_OPERATOR_CHANGE,
  classifiersGridOperatorChange
);

export const fetchClassifierSellerList = createAsyncAction(
  CLASSIFIER_SELLER_LIST_FETCH,
  classifiersSellerListFetch
);

export const deleteClassifierSeller = createAsyncAction(
  CLASSIFIER_SELLER_DELETE,
  classifiersSellerDelete
);

export const fetchClassifierSeller = createAsyncAction(
  CLASSIFIER_SELLER_FETCH,
  classifiersSellerFetch
);

export const changeClassifierSeller = createAsyncAction(
  CLASSIFIER_SELLER_CHANGE,
  classifiersSellerChange
);

export const fetchClassifierInvoiceConfirmation = createAsyncAction(
  CLASSIFIER_INVOICE_CONFIRMATION_FETCH,
  classifiersInvoiceConfirmationFetch
);

export const changeClassifierInvoiceConfirmation = createAsyncAction(
  CLASSIFIER_INVOICE_CONFIRMATION_CHANGE,
  classifiersInvoiceConfirmationChange
);

export const fetchClassifierGeneral = createAsyncAction(
  CLASSIFIER_GENERAL_FETCH,
  classifiersGeneralFetch,
  data => {
    data[classifierGeneralField.ITEMS]
      .filter(item => item[classifierGeneralItemField.ACTIVE])
      .forEach(item => {
        item[classifierGeneralItemField.PROPERTIES] = mapPropertiesToObject(
          item[classifierGeneralItemField.PROPERTIES]
        );
      });

    return data;
  }
);

export const changeClassifierGeneral = createAsyncAction(
  CLASSIFIER_GENERAL_CHANGE,
  classifiersGeneralChange
);

export const fetchClassifierSub = createAsyncAction(
  CLASSIFIER_SUB_FETCH,
  classifiersSubFetch
);

export const changeClassifierSub = createAsyncAction(
  CLASSIFIER_SUB_CHANGE,
  classifiersSubChange
);

export const deleteClassifierSub = createAsyncAction(
  CLASSIFIER_SUB_DELETE,
  classifiersSubDelete
);

export const fetchClassifierSubValues = createAsyncAction(
  CLASSIFIER_SUB_VALUES_FETCH,
  classifiersSubValuesFetch
);

export const changeClassifierSubValues = createAsyncAction(
  CLASSIFIER_SUB_VALUES_CHANGE,
  classifiersSubValuesChange
);

export const deleteClassifierSubValues = createAsyncAction(
  CLASSIFIER_SUB_VALUES_DELETE,
  classifiersSubValuesDelete
);
