import {schema} from 'normalizr';
import {entitiesSelectorList, entitiesSelectorItem} from '../utils/asyncHelpers';
import accountSchema from './account';

const schemaEntity = new schema.Entity('legalEntities', {
  accounts: [accountSchema],
});

export const getLegalEntities = entitiesSelectorList(schemaEntity);
export const getLegalEntity = entitiesSelectorItem(schemaEntity);
export default schemaEntity;
