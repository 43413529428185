import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'antd';
import {invoiceViewPresenterInfoFields} from '../../constants/invoice';
import {InvoiceViewItemColComponent} from '../';

class InvoiceViewItemPresenterInfo extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const {t, data} = this.props;

    return (
      <div className="global-margin-top-16">
        <Row className="global-margin-bottom-10">
          <Col span={8}>
            <InvoiceViewItemColComponent
              itemTitle={t('presenterInfo.name')}
              itemValue={data.get(invoiceViewPresenterInfoFields.NAME)}
            />
          </Col>
          <Col span={8}>
            <InvoiceViewItemColComponent
              itemTitle={t('presenterInfo.registerCode')}
              itemValue={data.get(invoiceViewPresenterInfoFields.REGISTER_CODE)}
            />
          </Col>
          <Col span={8}>
            <InvoiceViewItemColComponent
              itemTitle={t('presenterInfo.kmkrCode')}
              itemValue={data.get(invoiceViewPresenterInfoFields.KMKR_CODE)}
            />
          </Col>
        </Row>
        <Row className="global-margin-bottom-10">
          <Col span={8}>
            <InvoiceViewItemColComponent
              itemTitle={t('presenterInfo.eicxCode')}
              itemValue={data.get(invoiceViewPresenterInfoFields.EICX_CODE)}
            />
          </Col>
          <Col span={8}>
            <InvoiceViewItemColComponent
              itemTitle={t('presenterInfo.form')}
              itemValue={data.get(invoiceViewPresenterInfoFields.FORM)}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default InvoiceViewItemPresenterInfo;
