import React, {Component} from 'react';
import {searchPersonsAndCompanies} from '../../actions/actionHelpers/endpoints';
import {AutoCompleteSelect} from '../';

const getDataSource = async value => {
  const {data} = await searchPersonsAndCompanies(value);
  return data.map(item => ({
    value: item.id,
    label: `${item.name}, ${item.personalCode || item.registrationCode}`,
  }));
};

class AutoCompleteConsumers extends Component {
  render() {
    return <AutoCompleteSelect {...this.props} getDataSource={getDataSource}/>;
  }
}

export default AutoCompleteConsumers;
