import React from 'react';
import PropTypes from 'prop-types';
import {TransactionView as TransactionViewComponent} from '../../containers';
import {Layout} from 'antd';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';

const TransactionView = ({match: {params: {transactionId}}, t, accounts}) => (
  <Layout className="main-container">
    <h1>{t('title', {transactionId})}</h1>
    <TransactionViewComponent transactionId={transactionId} t={t} accounts={accounts}/>
  </Layout>
);

TransactionView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      transactionId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default translate(NAMESPACES.transactionView)(TransactionView);
