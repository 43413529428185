import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import {Layout} from 'antd';
import {
  ClassifierSubView,
} from '../../containers';

class ClassifierSubViewPage extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        classifierId: PropTypes.string.isRequired,
      }),
    }),
  };

  render() {
    const {t, match} = this.props;

    let container = (
      <ClassifierSubView t={t} classifierId={match.params.classifierId}
                         classifierSubId={match.params.subClassifierId}/>
    );

    return <Layout className="main-container">{container}</Layout>;
  }
}

export default translate(NAMESPACES.classifiers)(ClassifierSubViewPage);
