import {ACCOUNT_GOS_AVAILABLE_STATISTICS_FETCH} from "../../constants/accountGOSListActionTypes";
import {getAvailableStatistics, reserveTS} from '../actionHelpers/endpoints';
import {createAsyncAction} from "../../utils/asyncHelpers";
import {createAction} from "redux-actions";
import {
  RESERVE_AND_CONFIRM_STATISTICS,
  RESERVE_STATISTICS
} from "../../constants/actionTypes/tsAgreementActionTypes";


export const searchAccountGOSAvailableStatistics = createAsyncAction(
  ACCOUNT_GOS_AVAILABLE_STATISTICS_FETCH,
  getAvailableStatistics
);

export const reserveAndConfirm = createAsyncAction(
  RESERVE_AND_CONFIRM_STATISTICS,
  reserveTS
)

export const reserve = createAsyncAction(
  RESERVE_STATISTICS,
  reserveTS
)

export const resetAccountGOSAvailableStatistics = createAction('ACCOUNT_GOS_AVAILABLE_STATISTICS_RESET');

