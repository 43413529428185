import {normalizeHelper} from '../../utils/asyncHelpers';
import {
  createAsyncPagingAction,
  createAsyncActionStoreWrapper,
} from '../../utils/asyncPagingHelpers';
import {CONSUMPTION_POINT_LIST_FETCH} from '../../constants/consumptionPointListActionTypes';
import {consumptionPointListSearch} from '../actionHelpers/endpoints';
import consumptionPointSchema from '../../schemas/consumptionPoint';
import {getFormValues} from 'redux-form/immutable';
import {getConsumptionPointList} from '../../reducers/consumptionPointListReducer';
import {METERING_POINT_FILTER_FORM} from '../../constants/formConsumptionPointFilter';

const _searchConsumptionPoints = createAsyncPagingAction(
  CONSUMPTION_POINT_LIST_FETCH,
  consumptionPointListSearch,
  normalizeHelper([consumptionPointSchema])
);

const filterFormSelector = getFormValues(METERING_POINT_FILTER_FORM);

export const searchConsumptionPoints = createAsyncActionStoreWrapper(
  _searchConsumptionPoints,
  filterFormSelector,
  getConsumptionPointList
);
