import {createAsyncAction, normalizeHelper} from '../../utils/asyncHelpers';
import productionUnitDocumentSchema from '../../schemas/productionUnitDocument';
import {
  productionUnitDocumentsAdd,
  productionUnitDocumentsDelete,
  productionUnitDocumentsFetch,
} from '../actionHelpers/endpoints';
import {
  PRODUCTION_UNIT_DOCUMENTS_ADD,
  PRODUCTION_UNIT_DOCUMENTS_DELETE,
  PRODUCTION_UNIT_DOCUMENTS_FETCH,
} from '../../constants/productionUnitActionTypes';

export const fetchProductionUnitDocuments = createAsyncAction(
  PRODUCTION_UNIT_DOCUMENTS_FETCH,
  productionUnitDocumentsFetch,
  normalizeHelper([productionUnitDocumentSchema])
);

export const addProductionUnitDocument = createAsyncAction(
  PRODUCTION_UNIT_DOCUMENTS_ADD,
  productionUnitDocumentsAdd,
  normalizeHelper(productionUnitDocumentSchema)
);

export const deleteProductionUnitDocument = createAsyncAction(
  PRODUCTION_UNIT_DOCUMENTS_DELETE,
  productionUnitDocumentsDelete
);
