import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'antd';
import {invoiceViewInfoFields} from '../../constants/invoice';
import {InvoiceViewItemColComponent} from '../';
import {translateInvoiceTypeClassificator} from '../../utils/translateHelpers';

class InvoiceViewItemInvoiceInfo extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const {t, data} = this.props;

    return (
      <div className="global-margin-top-16">
        <Row className="global-margin-bottom-10">
          <Col span={8}>
            <InvoiceViewItemColComponent
              itemTitle={t('info.type')}
              itemValue={translateInvoiceTypeClassificator(
                data.get(invoiceViewInfoFields.TYPE)
              )}
            />
          </Col>
          <Col span={8}>
            <InvoiceViewItemColComponent
              itemTitle={t('info.number')}
              itemValue={data.get(invoiceViewInfoFields.NUMBER)}
            />
          </Col>
          <Col span={8}>
            <InvoiceViewItemColComponent
              itemTitle={t('info.submissionDate')}
              itemValue={data.get(invoiceViewInfoFields.SUBMISSION_DATE)}
            />
          </Col>
        </Row>
        <Row className="global-margin-bottom-10">
          <Col span={8}>
            <InvoiceViewItemColComponent
              itemTitle={t('info.invoiceDate')}
              itemValue={data.get(invoiceViewInfoFields.INVOICE_DATE)}
            />
          </Col>
          <Col span={8}>
            <InvoiceViewItemColComponent
              itemTitle={t('info.subsidyPeriod')}
              itemValue={`${data.get(
                invoiceViewInfoFields.SUBSIDY_PERIOD_MONTH
              )}.${data.get(invoiceViewInfoFields.SUBSIDY_PERIOD_YEAR)}`}
            />
          </Col>
          <Col span={8}>
            <InvoiceViewItemColComponent
              itemTitle={t('info.eicwCode')}
              itemValue={data.get(invoiceViewInfoFields.EICW_CODE)}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default InvoiceViewItemInvoiceInfo;
