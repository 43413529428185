import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  reduxForm,
  reset,
  formValueSelector,
} from 'redux-form/immutable';
import {connect} from 'react-redux';
import {TextField, DateField} from '../../atoms';
import {Row, Col, Button, Spin} from 'antd';
import {
  FORM_REGISTER_REPRESENTATIVE_COMPANY,
  formRegisterRepresentativeCompanyFields,
} from '../../constants/formRegister';
import {
  getAddMandateCompany,
  getUpdateMandateCompany,
} from '../../reducers/registerAddMandateReducer';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {
  addMandateCompany,
  updateMandateCompany,
} from '../../actions/actionCreators/registerActionCreators';
import {AutoCompleteCompaniesField} from '../../components';
import validate from './validateSchema';
import './RegisterRepresentCompany.css';

export class RegisterRepresentCompany extends Component {
  static propTypes = {
    companyName: PropTypes.string,
  };

  render() {
    const {handleSubmit, t, isLoading, onClose, companyName} = this.props;

    return (
      <Spin spinning={isLoading}>
        <form
          className="form-register-representation-company"
          onSubmit={handleSubmit}
        >
          <Row gutter={32}>
            {companyName ? (
              <Col span={12}>
                <Field
                  name={formRegisterRepresentativeCompanyFields.COMPANY_NAME}
                  component={TextField}
                  type="text"
                  label={t('representation.company_name_label')}
                  disabled
                />
              </Col>
            ) : (
              <Col span={12}>
                <Field
                  name={formRegisterRepresentativeCompanyFields.LEGAL_ENTITY_ID}
                  component={AutoCompleteCompaniesField}
                  type="text"
                  label={t('representation.company_name_label')}
                  required
                />
              </Col>
            )}
          </Row>
          <Row gutter={32}>
            <Col span={12}>
              <Field
                name={
                  formRegisterRepresentativeCompanyFields.MANDATE_DATE_START
                }
                component={DateField}
                showTime
                label={t('representation.mandate_date_start_label')}
                className="form-register-representation-company__date-field"
                required
              />
            </Col>
            <Col span={12}>
              <Field
                name={formRegisterRepresentativeCompanyFields.MANDATE_DATE_END}
                component={DateField}
                showTime
                label={t('representation.mandate_date_end_label')}
                className="form-register-representation-company__date-field"
              />
            </Col>
          </Row>
          <div className="component-register-actions">
            <Button
              className="component-register-actions__button-previous"
              size="large"
              onClick={onClose}
            >
              {t('representation.company_cancel')}
            </Button>
            <Button
              className="component-register-actions__button-next"
              size="large"
              type="primary"
              htmlType="submit"
            >
              {t('representation.company_add')}
            </Button>
          </div>
        </form>
      </Spin>
    );
  }
}

const valueSelector = formValueSelector(FORM_REGISTER_REPRESENTATIVE_COMPANY);

const mapStateToProps = state => {
  const isLoading = getLoadingSelector(
    getAddMandateCompany,
    getUpdateMandateCompany
  )(state);

  return {
    isLoading,
    companyName: valueSelector(
      state,
      formRegisterRepresentativeCompanyFields.COMPANY_NAME
    ),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClose: () => {
    dispatch(reset(FORM_REGISTER_REPRESENTATIVE_COMPANY));
    ownProps.closeModal();
  },
});

const onSubmit = async (data, dispatch, ownProps) => {
  const formData = data.toJS();
  if (formData[formRegisterRepresentativeCompanyFields.MANDATE_ID]) {
    await dispatch(updateMandateCompany(formData));
  } else {
    await dispatch(addMandateCompany(formData));
  }
  dispatch(reset(FORM_REGISTER_REPRESENTATIVE_COMPANY));
  ownProps.closeModal();
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_REGISTER_REPRESENTATIVE_COMPANY,
    validate,
    onSubmit,
  })(RegisterRepresentCompany)
);
