import React, {useEffect, useState} from 'react';
import {YearSelect} from '../../atoms';
import {Col, Row, Spin} from 'antd';
import {electricMeteringPointAgrDataFetch} from "../../actions/actionHelpers/endpoints";
import {
  ElectricMeteringPointAgrDataTable
} from "../../components/ElectricMeteringPointAgrDataTable/ElectricMeteringPointAgrDataTable";

export default function ElectricMeteringPointAgrData({t, meteringPointId}) {
  const [loading, setLoading] = useState(true);
  const [meteringData, setMeteringData] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    !loading && setLoading(true);
    !!meteringPointId && electricMeteringPointAgrDataFetch({meteringPointId, year})
      .then(response => setMeteringData(response.data) & setLoading(false))
      .catch(error => console.error(error) & setLoading(false));
  }, [year, meteringPointId]);

  return (
    <Spin spinning={loading} className="metering-point-agr-data-container">
      <Row className="metering-point-agr-data-container__year-container">
        <Col span={8}>
          <YearSelect
            className="metering-point-agr-data-container__year-container__select"
            onChange={setYear}
            value={year}
          />
        </Col>
      </Row>
      <Row>
        <ElectricMeteringPointAgrDataTable t={t} data={meteringData}/>
      </Row>
    </Spin>
  );
}
