import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { productionUnitFields } from '../../constants/productionUnit';
import { legalEntityFields } from '../../constants/legalEntity';
import { meteringPointFields } from '../../constants/meteringPoint';
import {
  goToHydrogenUnitAdd,
  goToProductionUnitAdd,
  goToProductionUnitView,
} from '../../utils/gotoLink';
import { isHydrogen } from '../../utils/productionUnitUtil';
import { TableRowActions, TableActions } from '../';
import { translateProductionUnitStatusClassificator } from '../../utils/translateHelpers';
import { Table, Button, Spin } from 'antd';

export class ProductionUnitSearchTable extends Component {
  static propTypes = {
    fuelType: PropTypes.string,
    productionUnits: PropTypes.array,
    totalElements: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
    searchProductionUnits: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    pageSize: PropTypes.number.isRequired,
  };

  onPageChange = page => {
    const { searchProductionUnits } = this.props;

    searchProductionUnits(page);
  };

  getColumns = () => {
    const { t } = this.props;

    return [
      {
        title: t('table.companyName'),
        dataIndex: `${productionUnitFields.LEGAL_ENTITY}.${
          legalEntityFields.NAME
        }`,
      },
      {
        title: t('table.companyEicxCode'),
        dataIndex: `${productionUnitFields.LEGAL_ENTITY}.${
          legalEntityFields.EICX_CODE_B
        }`,
      },
      {
        title: t('table.name'),
        dataIndex: productionUnitFields.NAME,
      },
      {
        title: t('table.eicwCode'),
        dataIndex: productionUnitFields.EICW_CODE,
      },
      {
        title: t('table.nominalPower'),
        dataIndex: productionUnitFields.NOMINAL_POWER,
      },
      {
        title: t('table.eiczCode'),
        dataIndex: `${productionUnitFields.METERING_POINT}.${
          meteringPointFields.EICZ_CODE
        }`,
      },
      {
        title: t('table.status'),
        dataIndex: productionUnitFields.STATUS,
        render: translateProductionUnitStatusClassificator,
      },
      {
        title: t('table.actions'),
        dataIndex: productionUnitFields.ID,
        render: value => (
          <TableRowActions>
            <Button
              shape="circle"
              type="primary"
              icon="edit"
              size="small"
              title={t('table.editBtn')}
              onClick={() => goToProductionUnitView(value)}
            />
          </TableRowActions>
        ),
        fixed: 'right',
        width: 100,
      },
    ];
  };

  selectedIdToImport = null;

  render() {
    const {
      fuelType,
      productionUnits,
      totalElements,
      loading,
      page,
      pageSize,
      t,
    } = this.props;

    const isHydro = isHydrogen(fuelType);

    let columns = this.getColumns();
    if (isHydro) {
      columns.splice(5, 1);
    }

    return (
      <div>
        <TableActions>
          <Button
            onClick={isHydro ? goToHydrogenUnitAdd : goToProductionUnitAdd}
            type="primary"
          >
            {isHydro
              ? t('table.addHydroProductionUnit')
              : t('table.addProductionUnit')}
          </Button>
        </TableActions>
        <div className="search-results-container">
          <Spin spinning={loading}>
            <Table
              locale={{
                emptyText: t('table.noData'),
              }}
              scroll={{ x: 1000 }}
              rowKey={productionUnitFields.ID}
              dataSource={productionUnits}
              columns={columns}
              pagination={{
                onChange: this.onPageChange,
                current: page,
                total: totalElements,
                pageSize,
                defaultCurrent: 1,
              }}
            />
          </Spin>
        </div>
      </div>
    );
  }
}

export default ProductionUnitSearchTable;
