import {REPORT_GO_ITEMS_FETCH} from '../constants/actionTypes/reportGoActionTypes';
import {listInitialAsyncPagingState, createAsyncPagingReducer} from '../utils/asyncPagingHelpers';
import {createAction, handleActions} from 'redux-actions';

export const goBiomethaneReportResetMaster = createAction('GO_BIOMETHANE_REPORT_RESET_MASTER');

const reportGoReducer = handleActions(
  {
    ...createAsyncPagingReducer(REPORT_GO_ITEMS_FETCH),
    [goBiomethaneReportResetMaster]: state => state.merge(listInitialAsyncPagingState),
  },
  listInitialAsyncPagingState,
);

export const getGoReport = state => {
  return state.get('reportGo');
};

export default reportGoReducer;
