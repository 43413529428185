import React, {useEffect, useState} from "react";
import {Button, Checkbox, Col, InputNumber, notification, Row, Spin} from "antd";
import {FormActions} from "../../components";
import {goToClassifiers} from "../../utils/gotoLink";
import {translate} from "react-i18next";
import {NAMESPACES} from "../../i18n";
import {
  classifiersConsumptionReportEndDayChange,
  classifiersConsumptionReportEndDayFetch
} from "../../actions/actionHelpers/endpoints";
import consumptionReportEndDay from "../../constants/parameters/consumptionReportEndDay";

function ParameterReportEndDayEdit({t}) {
  const [classifier, setClassifier] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    classifiersConsumptionReportEndDayFetch()
      .then(response => setClassifier({
        value: response.data.value,
        active: response.data.active
      }) & setLoading(false))
      .catch(error => console.error(error) & setLoading(false));
  }, []);

  function save() {
    if (!classifier.value) {
      notification.error({
        message: t('saveErrorTitle'),
        description: t('missingEndDayValue'),
      })
      return;
    }

    setLoading(true);
    classifiersConsumptionReportEndDayChange({value: classifier.value, active: classifier.active})
      .then(() => {
        setLoading(false);
        notification.info({
          message: t('completeTitle'),
          description: t('completeBody'),
        });
      })
      .catch(error => {
        setLoading(false);
        notification.error({
          message: t('saveErrorTitle'),
          description: error.response.data.message
        })
      });
  }

  return (
    <Spin spinning={loading} className="search-results-container">
      <h1>{t('parameterConsumptionReportEndDayTitle')}</h1>
      <form>
        <Row gutter={32}>
          <Col span={24}>
            <InputNumber
              min={1}
              max={31}
              value={classifier.value}
              name={consumptionReportEndDay.VALUE}
              onChange={value => setClassifier({...classifier, value})}
            />
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={24}>
            <Checkbox
              name={consumptionReportEndDay.ACTIVE}
              onChange={value => setClassifier({...classifier, active: !value.target.checked})}
              checked={!classifier.active}
            >
              {t('parameterConsumptionReportEndDayDeactivate')}
            </Checkbox>
          </Col>
        </Row>
        <FormActions>
          <Button onClick={goToClassifiers}>{t('buttonBack')}</Button>
          <Button type="primary" onClick={save}>{t('buttonSave')}</Button>
        </FormActions>
      </form>
    </Spin>
  );
}

export default translate(NAMESPACES.classifiers)(ParameterReportEndDayEdit);
