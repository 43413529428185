import React, {Component} from 'react';
import {Button, Table} from 'antd';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import {
  luGeneralFormFields,
  luMeteringPointsFields,
} from '../../constants/loadingUnit';

class LoadingUnitsTable extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    totalElements: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    legalEntityId: PropTypes.number.isRequired,
    fetchLegalEntityLoadingUnitsPage: PropTypes.func.isRequired,
  };

  onPageChange = page => {
    const {
      fetchLegalEntityLoadingUnitsPage,
      legalEntityId,
      pageSize,
    } = this.props;

    fetchLegalEntityLoadingUnitsPage({
      filter: {legalEntityId},
      pager: {
        page: page - 1,
        size: pageSize,
      },
    })
  };

  getColumns = () => {
    const {t} = this.props;

    return [
      {
        title: t('loadingUnit.name'),
        dataIndex: luGeneralFormFields.NAME,
      },
      {
        title: t('loadingUnit.model'),
        dataIndex: luGeneralFormFields.UNIT_MODEL,
      },
      {
        title: t('loadingUnit.power'),
        dataIndex: luGeneralFormFields.POWER,
      },
      {
        title: t('loadingUnit.measurementPoints'),
        dataIndex: luMeteringPointsFields.EICZ_CODE,
        render: (value, row) => {
          return row.measurementPoints.map(p => p.eicZ).join(', ');
        },
      },
      {
        title: t('loadingUnit.status'),
        dataIndex: luGeneralFormFields.STATUS,
        render: (value, row) => t(`loadingUnit.status_${row.status}`),
      },
      {
        title: t('loadingUnit.actions'),
        key: 'actions',
        render: (value, values) => (
          <span>
            <Button
              shape="circle"
              icon="edit"
              size="small"
              title={t('loadingUnit.edit')}
              type="primary"
              onClick={() =>
                this.props.onEdit(get(values, luGeneralFormFields.ID))
              }
            />
            {/*{this.renderImport(t, values)}*/}
          </span>
        ),
      },
    ];
  };

  render() {
    const {
      data,
      t,
      isEditMode,
      page,
      totalElements,
      pageSize,
    } = this.props;

    return (
      <div>
        <Table
          rowKey={record => record[luGeneralFormFields.ID]}
          columns={this.getColumns(t)}
          dataSource={data}
          bordered
          pagination={
            isEditMode
              ? {
                onChange: this.onPageChange,
                current: page,
                total: totalElements,
                pageSize,
                defaultCurrent: 1,
              }
              : false
          }
        />
      </div>
    );
  }
}

export default LoadingUnitsTable;
