import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, formValueSelector, reduxForm } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { TextField, CheckboxField } from '../../atoms';
import { Row, Col, Button, Spin, Modal } from 'antd';
import {
  FORM_REGISTER_GENERAL,
  formRegisterGeneralFields,
} from '../../constants/formRegister';
import { RegisterActions } from '../../components';
import {
  updateLegalEntityActionCreator,
  addLegalEntity,
  activateLegalEntity,
  deactivateLegalEntity,
  fetchCompanyDataFromBusinessRegisterActionCreator,
  resetBusinessRegisterValues,
} from '../../actions/actionCreators/registerActionCreators';
import { ManualGoIssuing, ManualElectricGosIssuing } from '../../containers';
import {
  getRecalculateElectricGosModal,
  showRecalculateElectricGosModal,
  hideRecalculateElectricGosModal,
  getRecalculateGoModal,
  hideRecalculateGoModal,
  showRecalculateGoModal,
} from '../../actions/actionCreators/modalsActionCreators';
import {
  legalEntityFields,
  REGISTER_CODE_LENGTH,
} from '../../constants/legalEntity';
import * as registerSelectors from '../../reducers/registerReducer';
import legalEntityStatuses from '../../constants/classificators/legalEntityStatus';
import { getLoadingSelector } from '../../utils/asyncHelpers';
import { parseNumber, processAsyncResult } from '../../utils/formHelpers';
import validate from './validateSchema';
import classifiersStore from '../../utils/classifiersStore';
import './RegisterGeneral.css';

export class RegisterGeneral extends Component {
  static propTypes = {
    isAdmin: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    activate: PropTypes.func.isRequired,
    deactivate: PropTypes.func.isRequired,
    showRequestChangeModal: PropTypes.func,
    registerCode: PropTypes.string,
    showRecalcElectricGosModal: PropTypes.func.isRequired,
    hideRecalcElectricGosModal: PropTypes.func.isRequired,
    isRecalcElectricGosModalVisible: PropTypes.bool.isRequired,
    showRecalcGoModal: PropTypes.func.isRequired,
    hideRecalcGoModal: PropTypes.func.isRequired,
    isRecalcGoModalVisible: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    registerCode: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      isConfirmModalVisibleTrader: false,
      isConfirmModalVisibleProvider: false,
    };
  }

  handleTraderCheckboxChange(e) {
    this.setState({
      isConfirmModalVisibleTrader: !e.target.checked,
    });
  }

  handleProviderCheckboxChange(e) {
    this.setState({
      isConfirmModalVisibleProvider: !e.target.checked,
    });
  }

  render() {
    const {
      handleSubmit,
      hasPrevious,
      hasDone,
      goPrevious,
      t,
      isEditMode,
      isAdmin,
      isLoadingOperatorSelected,
      isManufacturerSelected,
      isTraderSelected,
      initialValues,
      isLoading,
      activate,
      deactivate,
      showRequestChangeModal,
      registerCode,
      showRecalcElectricGosModal,
      hideRecalcElectricGosModal,
      isRecalcElectricGosModalVisible,
      showRecalcGoModal,
      hideRecalcGoModal,
      isRecalcGoModalVisible,
    } = this.props;

    const inEditForNotAdmin = isEditMode && !isAdmin;
    const legalEntityStatus = initialValues.get(
      formRegisterGeneralFields.STATUS
    );
    const legalEntityId = initialValues.get(formRegisterGeneralFields.ID);
    const isNew = registerCode === '';

    const {
      isConfirmModalVisibleTrader,
      isConfirmModalVisibleProvider,
    } = this.state;

    return (
      <Spin spinning={isLoading}>
        <form className="form-register-general" onSubmit={handleSubmit}>
          <h2 className="form-register-general__title">
            {inEditForNotAdmin ? t('general.titleView') : t('general.title')}
          </h2>
          <Row gutter={32}>
            <Col className="gutter-row" span={4}>
              <Field
                label={t('general.isTrader')}
                name={formRegisterGeneralFields.IS_TRADER}
                component={CheckboxField}
                disabled={inEditForNotAdmin}
                hasFeedback={false}
                onChange={this.handleTraderCheckboxChange.bind(this)}
              />
            </Col>
            <Col className="gutter-row" span={4}>
              <Field
                label={t('general.isManufacturer')}
                name={formRegisterGeneralFields.IS_MANUFACTURER}
                component={CheckboxField}
                disabled={inEditForNotAdmin}
                hasFeedback={false}
              />
            </Col>
            {classifiersStore.getBooleanProperty('IS_HYDROGEN_ENABLED') && (
              <Col className="gutter-row" span={4}>
                <Field
                  label={t('general.isHydrogenManufacturer')}
                  name={formRegisterGeneralFields.IS_HYDRO_MANUFACTURER}
                  component={CheckboxField}
                  disabled={inEditForNotAdmin}
                  hasFeedback={false}
                />
              </Col>
            )}
            <Col className="gutter-row" span={4}>
              <Field
                label={t('general.isProvider')}
                name={formRegisterGeneralFields.IS_PROVIDER}
                component={CheckboxField}
                disabled={inEditForNotAdmin}
                hasFeedback={false}
                onChange={this.handleProviderCheckboxChange.bind(this)}
              />
            </Col>
            <Col className="gutter-row" span={4}>
              <Field
                label={t('general.isOperator')}
                name={formRegisterGeneralFields.IS_LOADING_OPERATOR}
                component={CheckboxField}
                disabled={inEditForNotAdmin}
                hasFeedback={false}
              />
            </Col>
            <Col className="gutter-row" span={12}>
              <Field
                name={formRegisterGeneralFields.REGISTER_CODE}
                component={TextField}
                type="text"
                parse={parseNumber}
                placeholder={t('general.register_code_placeholder')}
                label={t('general.register_code_label')}
                disabled={!isNew}
              />
            </Col>
          </Row>
          <Row gutter={32}>
            <Col className="gutter-row" span={12}>
              <Field
                name={formRegisterGeneralFields.COMPANY_NAME}
                component={TextField}
                type="text"
                placeholder={t('general.company_name_placeholder')}
                label={t('general.company_name_label')}
                disabled={false}
              />
            </Col>
            <Col className="gutter-row" span={12}>
              <Field
                name={formRegisterGeneralFields.KMKR_CODE}
                component={TextField}
                type="text"
                label={t('general.kmkr_code_label')}
                tooltip={t('general.kmkr_code_tooltip')}
                disabled={inEditForNotAdmin}
              />
            </Col>
          </Row>
          <Row gutter={32}>
            {(isManufacturerSelected || isTraderSelected) && (
              <Col className="gutter-row" span={12}>
                <Field
                  name={formRegisterGeneralFields.EICX_CODE_B}
                  component={TextField}
                  type="text"
                  placeholder={t('general.eicx_code_b_placeholder')}
                  label={t('general.eicx_code_b_label')}
                  disabled={!isAdmin}
                />
              </Col>
            )}
            <Col className="gutter-row" span={12}>
              <Field
                name={legalEntityFields.BUSINESS_FORM}
                component={TextField}
                type="text"
                label={t('general.businessFrom')}
                disabled={false}
              />
            </Col>
          </Row>
          <Row gutter={32}>
            {isLoadingOperatorSelected && (
              <Col className="gutter-row" span={12}>
                <Field
                  name={formRegisterGeneralFields.EICX_CODE_E}
                  component={TextField}
                  type="text"
                  placeholder={t('general.eicx_code_e_placeholder')}
                  label={t('general.eicx_code_e_label')}
                  disabled={!isAdmin}
                />
              </Col>
            )}
          </Row>
          <Row gutter={32}>
            <Col className="gutter-row" span={12}>
              {isLoadingOperatorSelected && (
                <Field
                  name={formRegisterGeneralFields.ELECTRIC_GOS_AUTO_ISSUE}
                  component={CheckboxField}
                  disabled={inEditForNotAdmin}
                  hasFeedback={false}
                  checkboxText={t('general.electricGOSAutoIssue')}
                />
              )}
            </Col>
          </Row>

          <RegisterActions
            hasPrevious={hasPrevious}
            hasDone={hasDone}
            hasExit={!isEditMode && isAdmin}
            goPrevious={goPrevious}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
            showSaveButton={isAdmin || !isEditMode}
            showRequestChangeModal={showRequestChangeModal}
            t={t}
          >
            {isEditMode &&
              isAdmin && (
                <React.Fragment>
                  {isLoadingOperatorSelected && (
                    <Button
                      size="large"
                      type="primary"
                      onClick={showRecalcElectricGosModal}
                    >
                      {t('general.refreshEAVPData')}
                    </Button>
                  )}
                  <Button
                    size="large"
                    type="primary"
                    onClick={showRecalcGoModal}
                  >
                    {t('general.refreshGAVPData')}
                  </Button>
                  <Button
                    size="large"
                    type="primary"
                    onClick={
                      legalEntityStatus === legalEntityStatuses.ACTIVE
                        ? () => deactivate(legalEntityId)
                        : () => activate(legalEntityId)
                    }
                  >
                    {legalEntityStatus === legalEntityStatuses.ACTIVE
                      ? t('general.deactivateBtn')
                      : t('general.activateBtn')}
                  </Button>
                </React.Fragment>
              )}
          </RegisterActions>
        </form>
        {isRecalcElectricGosModalVisible && (
          <Modal
            onCancel={hideRecalcElectricGosModal}
            visible
            footer={false}
            title={t('general.modalPriceTitle')}
            width="800px"
          >
            <ManualElectricGosIssuing
              onDone={hideRecalcElectricGosModal}
              legalEntityId={legalEntityId}
            />
          </Modal>
        )}
        {isRecalcGoModalVisible && (
          <Modal
            onCancel={hideRecalcGoModal}
            visible
            footer={false}
            title={t('general.modalPriceTitle')}
            width="800px"
          >
            <ManualGoIssuing
              onDone={hideRecalcGoModal}
              legalEntityId={legalEntityId}
            />
          </Modal>
        )}
        <Modal
          closable={false}
          title={t('general.modalConfirmTitle')}
          visible={isConfirmModalVisibleProvider || isConfirmModalVisibleTrader}
          footer={false}
        >
          <div style={{ textAlign: 'center' }}>
            <p>{t('general.modalConfirmTxt')}</p>
            <br />
            <Button
              type="primary"
              onClick={() =>
                this.setState({
                  isConfirmModalVisibleProvider: false,
                  isConfirmModalVisibleTrader: false,
                })
              }
            >
              OK
            </Button>
          </div>
        </Modal>
      </Spin>
    );
  }
}

const valueSelector = formValueSelector(FORM_REGISTER_GENERAL);

const getBrFetchLoading = getLoadingSelector(
  registerSelectors.getFetchFromBr,
  registerSelectors.getActivationState
);

const mapStateToProps = (state, props) => {
  const initialValues = props.legalEntity;
  return {
    initialValues,
    isLoadingOperatorSelected: valueSelector(
      state,
      formRegisterGeneralFields.IS_LOADING_OPERATOR
    ),
    isManufacturerSelected: valueSelector(
      state,
      formRegisterGeneralFields.IS_MANUFACTURER
    ),
    isTraderSelected: valueSelector(state, formRegisterGeneralFields.IS_TRADER),
    isLoading: getBrFetchLoading(state),
    isRecalcElectricGosModalVisible: getRecalculateElectricGosModal(state),
    isRecalcGoModalVisible: getRecalculateGoModal(state),
  };
};

const mapDispatchToProps = {
  activate: activateLegalEntity,
  deactivate: deactivateLegalEntity,
  showRecalcElectricGosModal: showRecalculateElectricGosModal,
  hideRecalcElectricGosModal: hideRecalculateElectricGosModal,
  showRecalcGoModal: showRecalculateGoModal,
  hideRecalcGoModal: hideRecalculateGoModal,
};

const onChange = (values, dispatch, props, previousValues) => {
  const registerCode = values.get(formRegisterGeneralFields.REGISTER_CODE);
  if (
    values.get(formRegisterGeneralFields.ID) ||
    registerCode === previousValues.get(formRegisterGeneralFields.REGISTER_CODE)
  )
    return;

  if (registerCode && registerCode.length === REGISTER_CODE_LENGTH) {
    dispatch(fetchCompanyDataFromBusinessRegisterActionCreator(registerCode));
  } else {
    dispatch(resetBusinessRegisterValues());
  }
};

const onSubmit = async (values, dispatch) => {
  const result = values.get(legalEntityFields.ID)
    ? await dispatch(updateLegalEntityActionCreator(values.toJS()))
    : await dispatch(addLegalEntity(values.toJS()));

  processAsyncResult(result);
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_REGISTER_GENERAL,
    validate,
    onChange,
    enableReinitialize: true,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmit,
  })(RegisterGeneral)
);
