import PropTypes from "prop-types";
import React, {Component} from "react";
import {Button, Col, Icon, message, Row, Spin, Tooltip} from "antd";

import {change, Field, formValueSelector, reduxForm} from "redux-form/immutable";
import {connect} from "react-redux";
import {FORM_AGREEMENT_CONFIRM} from "../../constants/formKeys";
import {FormActions} from "../../components";
import {tsAgreementConfirmAction} from "../../actions/actionCreators/tsAgreementActionCreators";
import {RadioField} from "../../atoms";
import './TsAgreementConfirm.scss';
import {formTsAgreementConfirmFields} from "../../constants/forms/formTsAgreementConfirm";
import {
  translateAgreementTypeClassificator as tAgreementType,
  translateTsFuelGenerationClassificator as tFuelGeneration,
  translateTsFuelTypeClassificator as tFuelType
} from "../../utils/translateHelpers";
import {goToAccountGOSPreTradingAgreementDetails} from "../../utils/gotoLink";
import {getAgreementData} from "../../reducers/agreementViewReducer";
import * as actions from "../../actions/actionCreators/agreementViewActionCreators";
import {
  resetMaster as agreementResetMaster
} from "../../actions/actionCreators/agreementViewActionCreators";

export class TsAgreementConfirm extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    assignment: PropTypes.object.isRequired,
    hideConfirmModal: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    fetchAgreement: PropTypes.func.isRequired,
    isConfirmable: PropTypes.bool.isRequired,
    onSuccess: PropTypes.func,
    confirmAgreement: PropTypes.bool,
  }

  componentDidMount = () => {
    const {assignment, fetchAgreement, updateFormValue} = this.props;
    fetchAgreement(assignment.sourceId);
    if (assignment.completedDate)
      updateFormValue(formTsAgreementConfirmFields.CONFIRM, assignment.result === 'ACCEPTED');
  };

  componentWillUnmount() {
    const {resetAgreement} = this.props;
    resetAgreement();
  }

  handleView(id) {
    this.props.hideConfirmModal();
    goToAccountGOSPreTradingAgreementDetails(this.props.registerCode, id);
  }

  isSubmitDisabled = () => {
    const {confirmAgreement} = this.props;
    return confirmAgreement === undefined;
  }

  renderRow = (label, value) => (
    <div>
      <span>{label}: </span>
      <span>{value}</span>
    </div>
  );

  render() {
    const {t, agreement, assignment, hideConfirmModal, handleSubmit, isConfirmable} = this.props;

    const isLoading = Object.keys(agreement).length === 0;
    if (isLoading) return (<Spin/>)

    const isCompleted = !isConfirmable && assignment.completedDate;
    const isAgreementTypeEntire = agreement.agreementType === 'ENTIRE';

    return (
      <form onSubmit={handleSubmit}>
        <Row gutter={32}>
          <Col span={24} className="detail-container">
            <div>
              <span>{t('assignments.confirm.agreementNr')}: </span>
              <a onClick={() => this.handleView(agreement.id)}>{agreement.id}</a>
            </div>
            {this.renderRow(t('assignments.confirm.agreementType'), tAgreementType(agreement.agreementType))}
            {this.renderRow(t('assignments.confirm.finalConsumptionYear'), agreement.finalConsumptionYear)}
            {agreement.queueNo && this.renderRow(t('assignments.confirm.queueNo'), agreement.queueNo)}
            {this.renderRow(t('assignments.confirm.dueDate'), assignment.dueDate)}
            <br/>
            {this.renderRow(t('assignments.confirm.fuelType'), tFuelType(agreement.fuelType))}
            {this.renderRow(t('assignments.confirm.fuelGeneration'), tFuelGeneration(agreement.fuelGeneration))}
            {agreement.ghgCapacity &&
              <div>
                    <span>{t('assignments.confirm.ghgCapacity')}&nbsp;
                      <Tooltip title={t('agreements.table.ghgTooltip')}>
                        <Icon type="question-circle-o"/>
                      </Tooltip>:
                    </span>
                <span>{agreement.ghgCapacity.toFixed(1)}</span>
              </div>
            }
            {this.renderRow(t('assignments.confirm.naturalAmountMj'),
              isAgreementTypeEntire ?
                t('assignments.confirm.all') :
                agreement.naturalAmountMj.toFixed(1)
            )}
            {this.renderRow(t('assignments.confirm.multiplier'), agreement.multiplier)}
            {this.renderRow(t('assignments.confirm.calculatedAmountMj'),
              isAgreementTypeEntire ?
                t('assignments.confirm.all') :
                agreement.calculatedAmountMj.toFixed(1)
            )}
            <br/>
            {this.renderRow(t('assignments.confirm.price'), agreement.unitPrice.toFixed(3))}
            {!isAgreementTypeEntire && this.renderRow(t('assignments.confirm.transactionCost'), agreement.totalTransactionCost)}
          </Col>
        </Row>
        {isCompleted &&
          <div className="pt-4 mb-1">
            <span><b>{t('assignments.confirm.completedDate')}: </b></span>
            <span>{assignment.completedDate}</span>
          </div>
        }
        {(isConfirmable || isCompleted) && (<>
          <Row gutter={32}>
            <Col span={12}>
              <Field
                className={isConfirmable ? "radio-field pt-5" : "radio-field"}
                name={formTsAgreementConfirmFields.CONFIRM}
                component={RadioField}
                disabled={!isConfirmable}
                options={[
                  {
                    value: true,
                    label: t('assignments.confirm.agree'),
                  },
                  {
                    value: false,
                    label: t('assignments.confirm.refuse'),
                  },
                ]}
              />
            </Col>
          </Row>
        </>)}
        {isConfirmable && (
          <FormActions>
            <Button onClick={hideConfirmModal}>{t('assignments.confirm.cancel')} </Button>
            <Button disabled={this.isSubmitDisabled()} type="primary" htmlType="submit">
              {t('assignments.confirm.confirm')}
            </Button>
          </FormActions>
        )}
      </form>
    );
  }
}

const confirmForm = formValueSelector(FORM_AGREEMENT_CONFIRM);

const onSubmit = async (values, dispatch, props) => {
  await dispatch(tsAgreementConfirmAction(
      props.assignment.id, props.confirmAgreement
    )
  );
};

const onSubmitSuccess = async (result, dispatch, props) => {
  const {t, hideConfirmModal, onSuccess} = props;
  message.success(t('assignments.confirm.performSuccess'));

  await onSuccess();
  hideConfirmModal();
};

const mapStateToProps = state => ({
  confirmAgreement: confirmForm(state, formTsAgreementConfirmFields.CONFIRM),
  agreement: getAgreementData(state).toJS(),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchAgreement: id => {
    dispatch(actions.fetchAgreement(id));
  },
  resetAgreement: () => dispatch(agreementResetMaster()),
  updateFormValue: (fieldName, value) => dispatch(change(FORM_AGREEMENT_CONFIRM, fieldName, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_AGREEMENT_CONFIRM,
    onSubmit,
    onSubmitSuccess
  })(TsAgreementConfirm)
);
