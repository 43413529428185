export const luGeneralFormFields = {
  ID: 'id',
  NAME: 'unitName',
  POWER: 'power',
  UNIT_MODEL: 'unitModel',
  LEGAL_ENTITY_ID: 'legalEntityId',

  STATUS: 'status'
};

export const luMeteringPointsFields = {
  ID: 'id',
  SELECTED: 'selected',
  TYPE: 'type',
  EICX_CODE: 'eicxCode',
  EICZ_CODE: 'eicZ',
  ADDRESS: 'address',
  COUNTY: 'county',
  MUNICIPALITY: 'municipality',
  DISTRICT: 'district',
  CONSUMER: 'consumer',
  LOADING_UNIT_ID: 'loadingUnitId',

  IS_DIRECTLY_CONNECTED_TO_PROD_UNIT: 'isDirectlyConnectedToProductionUnit',
  PRODUCTION_UNIT_EICW: 'productionUnitEicw',
};

export const loadingUnitFields = {
  ID: 'id',
  LEGAL_ENTITY: 'legalEntity',
  LEGAL_ENTITY_ELECTRICITY_EICX_CODE: 'legalEntityEicxElectricityCode',
  LEGAL_ENTITY_NAME: 'legalEntityName',
  NAME: 'unitName',
  POWER: 'power',
  MODEL: 'unitModel',
  MEASUREMENT_POINTS: 'measurementPoints',
  STATUS: 'status',
};

export const loadingUnitStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const FORM_LU_ADD_GENERAL = 'FORM_LU_ADD_GENERAL';

// actions
export const LOADING_UNIT_MEASUREMENT_POINTS_FETCH = 'LOADING_UNIT_ADD_METERING_POINTS_FETCH';
export const LOADING_UNIT_MEASUREMENT_POINTS_RESET = 'LOADING_UNIT_MEASUREMENT_POINTS_RESET';

export const LOADING_UNIT_ADD = 'LOADING_UNIT_ADD';
export const LOADING_UNIT_UPDATE = 'LOADING_UNIT_UPDATE';
export const LOADING_UNIT_FETCH = 'LOADING_UNIT_FETCH';
export const LOADING_UNIT_ENTITY_FETCH = 'LOADING_UNIT_ENTITY_FETCH';
export const LOADING_UNIT_DELETE = 'LOADING_UNIT_DELETE';
export const LOADING_UNIT_ACTIVATION = 'LOADING_UNIT_ACTIVATION';
export const LOADING_UNIT_LIST_FETCH = 'LOADING_UNIT_LIST_FETCH';

// search form
export const loadingUnitFilterFields = {
  NAME: 'unitName',
  EICX_CODE: 'companyEicxCode',
  REGISTER_CODE: 'companyRegistryCode',
  MODEL: 'unitModel',
  IS_DIRECTLY_CONNECTED_TO_PROD_UNIT: 'isDirectlyConnectedToProductionUnit',
  STATUS: 'status',
};

export const LOADING_UNIT_FILTER_FORM = 'LOADING_UNIT_FILTER_FORM';
