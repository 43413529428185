import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Icon, Radio, Tooltip} from 'antd';

const AntRadioGroup = Radio.Group;

export default class RadioGroup extends Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    valueKey: PropTypes.string,
    labelKey: PropTypes.string,
  };

  static defaultProps = {
    valueKey: 'value',
    labelKey: 'label',
  };

  render() {
    const {valueKey, labelKey, options, tooltips, ...restProps} = this.props;

    return (
      <AntRadioGroup {...restProps}>
        {options.map(({[valueKey]: valueOption, [labelKey]: label}, key) => {
          return (
            <Radio key={key} value={valueOption}>
              {label}&nbsp;{options[key].tooltip && <Tooltip title={options[key].tooltip}>
              <Icon type="question-circle-o"/>
            </Tooltip>}
            </Radio>
          );
        })}
      </AntRadioGroup>
    );
  }
}
