import React, {Component} from 'react';
import {
  searchTraderProviderLoadingOperatorLegalEntityByName
} from '../../actions/actionHelpers/endpoints';
import {AutoCompleteSelectField} from '../';

const getDataSource = async value => {
  const {data} = await searchTraderProviderLoadingOperatorLegalEntityByName(value);
  return data.map(item => ({
    value: item.id,
    label: `${item.companyName} [${item.registrationCode}]`,
  }));
};

class AutoCompleteAccountsIdField extends Component {
  render() {
    return <AutoCompleteSelectField {...this.props} getDataSource={getDataSource}/>;
  }
}

export default AutoCompleteAccountsIdField;
