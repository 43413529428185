export const tradingAccountOverviewTableFields = {
  ID: 'id',
  EXPIRATION_DATE: 'expirationDate',
  PRODUCTION_UNIT_EICW_CODE: 'productionUnitEicwCode',
  QUANTITY: 'quantity',
  PRODUCTION_PERIOD: 'productionPeriod',
  CONSUMPTION_YEAR: 'consumptionYear',
  CONSUMPTION_MONTH: 'consumptionMonth',
  CONSUMPTION_DAY: 'consumptionDay',
  CONSUMPTION_TIME: 'consumptionTime',
  EXPIRATION_TIME: 'expirationDate',
  TECHNOLOGY: 'technologyType',
  ENERGY_TYPE: 'energyType',
  FUEL_TYPE: 'fuelType',
  FEEDSTOCK: 'feedstock',
  BIOFUEL_TYPE: 'biofuelType',
  PRODUCTION_PATHWAY: 'productionPathway',

  NATURAL_ENERGY_AMOUNT: 'naturalEnergyAmount',
  CALCULATED_MULTIPLIER: 'calculatedMultiplier',
  CALCULATED_ENERGY_AMOUNT: 'calculatedEnergyAmount',

  GHG_AMOUNT: 'ghgAmount',
  GHG_CAPACITY: 'ghgCapacity',

  NATURAL_ENERGY_TRANSFER_AMOUNT: 'naturalEnergyTransferAmount',
  CALCULATED_ENERGY_TRANSFER_AMOUNT: 'calculatedEnergyTransferAmount',
  GHG_TRANSFER_AMOUNT: 'ghgTransferAmount',
  COST: 'cost',

  MIN_UNIT_PRICE: 'minUnitPrice',
  TRANSACTION_COST: 'transactionCost',
  MIN_BID_STEP: 'minBidStep',
  END_DATE: 'endDate',
  ADDITIONAL_INFO: 'additionalInfo'
};
