import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {connect} from 'react-redux';
import * as actions from '../../actions/actionCreators/meteringPointActionCreators';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import {isAdmin} from '../../utils/roles';
import {
  getMeteringPointEntities,
  getMeteringPoint,
  getMeteringPointActivation,
} from '../../reducers/meteringPointReducer';
import {
  showMeasuringUploadModal,
  hideMeasuringUploadModal,
  getMeasuringUploadModal,
  hideViewMeteringPointConsumerModal,
  getViewMeteringPointConsumerModal,
  showRequestChangeMeteringModal,
} from '../../actions/actionCreators/modalsActionCreators';
import {getRole} from '../../reducers/userReducer';
import {
  MeasuringUpload,
  MeteringPointAgrData,
  MeteringReview,
  RequestChangeModal,
} from '../';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {meteringPointFields} from '../../constants/meteringPoint';
import {legalEntityFields} from '../../constants/legalEntity';
import {
  meteringPointType,
  meteringPointDataSource,
  notificationRelatedObjectType,
  meteringPointStatus,
} from '../../constants/classificators';
import {
  MeteringPointOwnerInfo,
  FormActions,
  ConsumerInfoTable,
} from '../../components';
import {MeteringPoint, AddConsumer} from '../../forms';
import {Tabs, Spin, Button, Modal} from 'antd';
import {Link} from "react-router-dom";

const {TabPane} = Tabs;

export class MeteringPointEdit extends Component {
  static propTypes = {
    fetchMeteringPoint: PropTypes.func.isRequired,
    meteringPointId: PropTypes.string.isRequired,
    meteringPoint: PropTypesImmutable.map,
    saveMeteringPointForm: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    role: PropTypes.string.isRequired,
    showImportModal: PropTypes.func.isRequired,
    hideImportModal: PropTypes.func.isRequired,
    importModalState: PropTypes.bool.isRequired,
    consumerModalVisible: PropTypes.bool.isRequired,
    hideConsumerModal: PropTypes.func.isRequired,
    startEditConsumer: PropTypes.func.isRequired,
    activateMeteringPoint: PropTypes.func.isRequired,
    deactivateMeteringPoint: PropTypes.func.isRequired,
    generateMeteringPointsGOs: PropTypes.func.isRequired,
    showRequestChange: PropTypes.func.isRequired,
    tabKey: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      activeTab: props.tabKey || 'general'
    };
  }

  componentDidMount = () => {
    const {meteringPointId, fetchMeteringPoint} = this.props;
    fetchMeteringPoint(meteringPointId);
  };

  handleTabChange = key => {
    this.setState({
      activeTab: key,
    });
  };

  isGeneralTabActive = () => this.state.activeTab === 'general';

  isDataTabActive = () => this.state.activeTab === 'data';

  isPointVirtual = () =>
    this.props.meteringPoint.get(meteringPointFields.DATA_SOURCE) ===
    meteringPointDataSource.VIRTUAL;

  handleActivate = () => {
    const {meteringPoint, activateMeteringPoint} = this.props;

    activateMeteringPoint(meteringPoint.get(meteringPointFields.ID));
  };

  handleDeactivate = () => {
    const {meteringPoint, deactivateMeteringPoint} = this.props;

    deactivateMeteringPoint(meteringPoint.get(meteringPointFields.ID));
  };

  handleGenerateGo = async () => {
    const {meteringPoint, generateMeteringPointsGOs} = this.props;

    await generateMeteringPointsGOs(meteringPoint.get(meteringPointFields.ID));
  };

  render() {
    const {
      t,
      meteringPoint,
      isLoading,
      role,
      saveMeteringPointForm,
      showImportModal,
      hideImportModal,
      importModalState,
      consumerModalVisible,
      hideConsumerModal,
      startEditConsumer,
      showRequestChange
    } = this.props;

    const isUserAdmin = isAdmin(role);
    const isConsumption =
      meteringPoint.get(meteringPointFields.METERING_POINT_TYPE) ===
      meteringPointType.CONSUMPTION;
    const pointStatus = meteringPoint.get(meteringPointFields.STATUS);
    const legalEntity = meteringPoint.get(meteringPointFields.LEGAL_ENTITY);
    const ownerName = legalEntity
      ? legalEntity.get(legalEntityFields.NAME)
      : '';
    const ownerCode = legalEntity
      ? legalEntity.get(legalEntityFields.EICX_CODE)
      : '';

    const isConsumerEditable =
      meteringPoint.get(meteringPointFields.DATA_SOURCE) !==
      meteringPointDataSource.AVP;

    const registrationCode = legalEntity ? legalEntity.get(legalEntityFields.REGISTER_CODE) : '';

    const meteringPointId = meteringPoint.get(meteringPointFields.ID);

    return (
      <div>
        <h1>{isConsumption ? t('titleConsumption') : t('titleProduction')}</h1>
        <Spin spinning={isLoading}>
          <Tabs
            type="card"
            onChange={this.handleTabChange}
            activeKey={this.state.activeTab}
          >
            <TabPane key="general" tab={t('generalTab')}>
              <MeteringPointOwnerInfo t={t} name={ownerName} code={ownerCode}/>
              {isConsumption && (
                <div>
                  <h2>{t('info.consumerTitle')}</h2>
                  <ConsumerInfoTable
                    consumer={meteringPoint
                      .get(meteringPointFields.CONSUMER)
                      .toJS()}
                    onEdit={startEditConsumer}
                    isEditable={isConsumerEditable}
                  />
                  <br/>
                </div>
              )}
              <MeteringPoint
                meteringPoint={meteringPoint}
                t={t}
                isAdmin={isUserAdmin}
              />
            </TabPane>
            <TabPane key="data" tab={t('dataTab')}>
              {meteringPointId && (
                <MeteringPointAgrData
                  isConsumption={isConsumption}
                  t={t}
                  meteringPointId={meteringPointId}
                />
              )}
            </TabPane>
            {isUserAdmin &&
              meteringPoint.get(meteringPointFields.ID) && (
                <TabPane key="review" tab={t('reviewTab')}>
                  <MeteringReview
                    t={t}
                    meteringPointId={meteringPoint.get(meteringPointFields.ID)}
                  />
                </TabPane>
              )}
          </Tabs>
          <FormActions>
            {this.isDataTabActive() && isUserAdmin && !isConsumption && (
              <Link
                className="production-point__goto-unit pull-left"
                href
                to={`/productionUnit/${meteringPoint.get(meteringPointFields.PRODUCTION_UNIT_ID)}?tab=3`}
              >
                {t('productionDeviceBiofuelDataLink')}
              </Link>
            )}
            {this.isDataTabActive() && !isUserAdmin && !isConsumption && (
              <Link
                className="production-point__goto-unit pull-left"
                href
                to={`/legalEntity/${registrationCode}?tab=5&productionUnitId=${meteringPoint.get(meteringPointFields.PRODUCTION_UNIT_ID)}&productionUnitTab=3`}
              >
                {t('productionDeviceBiofuelDataLink')}
              </Link>
            )}


            {!isUserAdmin && (
              <Button onClick={showRequestChange}>
                {t('requestChangeBtn')}
              </Button>
            )}
            {this.isPointVirtual() &&
              !isUserAdmin && (
                <Button onClick={showImportModal}>{t('importBtn')}</Button>
              )}
            {this.isGeneralTabActive() &&
              isUserAdmin && (
                <React.Fragment>
                  {!isConsumption && (
                    <Button
                      type="primary"
                      onClick={
                        pointStatus === meteringPointStatus.ACTIVE
                          ? () => this.handleDeactivate()
                          : () => this.handleActivate()
                      }
                    >
                      {pointStatus === meteringPointStatus.ACTIVE
                        ? t('deactivateBtn')
                        : t('activateBtn')}
                    </Button>
                  )}
                </React.Fragment>
              )}
            {((this.isPointVirtual() && isUserAdmin) || isConsumption) && (
              <Button type="primary" onClick={saveMeteringPointForm}>
                {t('saveBtn')}
              </Button>
            )}
          </FormActions>
        </Spin>
        {importModalState && (
          <Modal
            onCancel={hideImportModal}
            visible
            footer={false}
            title={t('importTitle')}
            width="600px"
          >
            <MeasuringUpload
              onComplete={hideImportModal}
              meteringPointId={meteringPoint.get(meteringPointFields.ID)}
            />
          </Modal>
        )}
        <Modal
          title={t('info.addConsumerTitle')}
          visible={consumerModalVisible}
          footer={null}
          onCancel={hideConsumerModal}
        >
          <AddConsumer
            legalEntityId={
              legalEntity ? legalEntity.get(legalEntityFields.ID) : null
            }
            closeModal={hideConsumerModal}
            isAdmin={isUserAdmin}
          />
        </Modal>
        <RequestChangeModal
          relatedObjectId={meteringPoint.get(meteringPointFields.ID)}
          relatedObjectType={notificationRelatedObjectType.MEASUREMENT_POINT}
        />
      </div>
    );
  }
}

const getIsLoading = getLoadingSelector(
  getMeteringPoint,
  getMeteringPointActivation
);

const mapStateToProps = state => ({
  meteringPoint: getMeteringPointEntities(state),
  isLoading: getIsLoading(state),
  role: getRole(state),
  importModalState: getMeasuringUploadModal(state),
  consumerModalVisible: getViewMeteringPointConsumerModal(state),
});

const mapDispatchToProps = {
  ...actions,
  showImportModal: showMeasuringUploadModal,
  hideImportModal: hideMeasuringUploadModal,
  hideConsumerModal: hideViewMeteringPointConsumerModal,
  showRequestChange: showRequestChangeMeteringModal,
};

export default translate(NAMESPACES.meteringPoint)(
  connect(mapStateToProps, mapDispatchToProps)(MeteringPointEdit)
);
