import React from 'react';
import {Row, Col} from 'antd';

function TATransactionAccountInfo({t, accountNumber, companyName, registrationCode}) {

  return (
    <React.Fragment>
      <Row className="global-margin-bottom-10">
        <Row>
          <Col span={8}>
            <Row>{t('gosAccountInfo.accountNumber')}:</Row>
            <Row>{accountNumber}</Row>
          </Col>
          <Col span={8}>
            <Row>{t('gosAccountInfo.name')}:</Row>
            <Row>{companyName}</Row>
          </Col>
          <Col span={8}>
            <Row>{t('gosAccountInfo.registrationCode')}:</Row>
            <Row>
              {registrationCode}
            </Row>
          </Col>
        </Row>
      </Row>
    </React.Fragment>
  );
}

export default TATransactionAccountInfo;
