import {normalizeHelper} from '../../utils/asyncHelpers';
import {
  createAsyncPagingAction,
  createAsyncActionStoreWrapper,
} from '../../utils/asyncPagingHelpers';
import {ACCOUNT_GOS_TRANSACTOINS_LIST_FETCH} from '../../constants/accountGOSListActionTypes';
import {searchTATransactions as accountGOSTransactionsSearch} from '../actionHelpers/endpoints';
import accountGOSTransactionsSchema from '../../schemas/accountGOSTransactions';
import {getFormValues} from 'redux-form/immutable';
import {getAccountGOSTransactionsList} from '../../reducers/accountGOSTransactionsListReducer';
import {
  FORM_ACCOUNT_GOS_TRANSACTIONS_FILTER
} from '../../constants/formAccountGOSTransactionsFilter';

const _searchAccountGOSTransactions = createAsyncPagingAction(
  ACCOUNT_GOS_TRANSACTOINS_LIST_FETCH,
  accountGOSTransactionsSearch,
  normalizeHelper([accountGOSTransactionsSchema])
);

const filterFormSelector = getFormValues(FORM_ACCOUNT_GOS_TRANSACTIONS_FILTER);

export const searchAccountGOSTransactions = createAsyncActionStoreWrapper(
  _searchAccountGOSTransactions,
  filterFormSelector,
  getAccountGOSTransactionsList
);
