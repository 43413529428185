import {schema} from 'normalizr';
import {entitiesSelectorList, entitiesSelectorItem} from '../utils/asyncHelpers';
import legalEntity from './legalEntity';

const schemaEntity = new schema.Entity('mandateCompany', {
  legalEntity,
});

export const getMandateCompanies = entitiesSelectorList(schemaEntity);
export const getMandateCompany = entitiesSelectorItem(schemaEntity);
export default schemaEntity;
