export const meteringPointFilterFields = {
  CONSUMER_ID: 'consumerId',
  TYPE: 'measuringPointType',
  DATA_SOURCE: 'dataSource',
  EICZ_CODE: 'eiczCode',
  OWNER_ID: 'legalEntityId',
  STATUS: 'status',
};

export const METERING_POINT_FILTER_FORM = 'CONSUMPTION_POINT_FILTER_FORM';
