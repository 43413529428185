import React from 'react';
import {Layout, Tabs} from 'antd';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import PropTypes from 'prop-types';
import {ReportGrid, AccountReportTranslated} from '../../containers';
import {
  GoReport,
  GosReport,
  AccountReportFilterTranslated,
  ElectricConsumptionReport
} from '../../forms';
import {currentPathSelector} from '../../utils/gotoLink';
import {connect} from 'react-redux';
import * as userSelectors from '../../reducers/userReducer';
import {accountFields} from '../../constants/account';
import {accountType} from '../../constants/classificators';
import {isAdmin} from '../../utils/roles';
import accountStatus from "../../constants/classificators/accountStatus";

const tabKeys = {
  REPORT_GO: 'reportGo',
  REPORT_GRID: 'reportGrid',
  REPORT_PERIOD: 'reportPeriod',
  REPORT_PERIOD_GOS: 'reportPeriodGos',
  REPORT_ELECTRIC_CONSUMPTION: 'reportElectricConsumption'
};

class ReportPage extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    currentPath: PropTypes.string.isRequired,
    userRole: PropTypes.string.isRequired,
    userAccounts: PropTypes.array
  };

  getActiveTab = () => {
    const {currentPath} = this.props;

    if (currentPath.includes('/grid')) return tabKeys.REPORT_GRID;
    if (currentPath.includes('/go')) return tabKeys.REPORT_GO;
    if (currentPath.includes('/periodGos')) return tabKeys.REPORT_PERIOD_GOS;
    if (currentPath.includes('/period')) return tabKeys.REPORT_PERIOD;
    if (currentPath.includes('/electricConsumption')) return tabKeys.REPORT_ELECTRIC_CONSUMPTION;
  };

  render() {
    const {t, userRole, userAccounts} = this.props;

    if (!isAdmin(userRole)) {
      return (
        <Layout className="main-container">
          <Tabs type="card" defaultActiveKey={this.getActiveTab()}>
            {userAccounts.some(
              userAccount => userAccount[accountFields.STATUS] === accountStatus.ACTIVE && userAccount[accountFields.TYPE] === accountType.TRADER
            ) && (
              <Tabs.TabPane
                key={tabKeys.REPORT_PERIOD}
                tab={t('goReport.title')}
              >
                <h1>{t('goReport.title')}</h1>
                <GoReport t={t}/>
              </Tabs.TabPane>
            )}
            {userAccounts.some(
              userAccount => {
                return userAccount[accountFields.STATUS] === accountStatus.ACTIVE && userAccount[accountFields.TYPE] === accountType.PROVIDER
              }
            ) && (
              <Tabs.TabPane
                key={tabKeys.REPORT_PERIOD_GOS}
                tab={t('gosReport.title')}
              >
                <h1>{t('gosReport.title')}</h1>
                <GosReport t={t}/>
              </Tabs.TabPane>
            )}
            {userAccounts.some(
              userAccount => userAccount[accountFields.STATUS] === accountStatus.ACTIVE && userAccount[accountFields.TYPE] === accountType.LOADING_OPERATOR
            ) && (
              <Tabs.TabPane
                key={tabKeys.REPORT_ELECTRIC_CONSUMPTION}
                tab={t('electricConsumptionReport.title')}
              >
                <h1>{t('electricConsumptionReport.title')}</h1>
                <ElectricConsumptionReport t={t}/>
              </Tabs.TabPane>
            )}
          </Tabs>
        </Layout>
      );
    }

    if (isAdmin(userRole)) {
      return (
        <Layout className="main-container">
          <h1>{t('title')}</h1>
          <Tabs type="card" defaultActiveKey={this.getActiveTab()}>
            <Tabs.TabPane
              key={tabKeys.REPORT_PERIOD}
              tab={t('reportPeriodTab')}
            >
              <h2>{t('goReport.title')}</h2>
              <GoReport t={t}/>
            </Tabs.TabPane>
            <Tabs.TabPane
              key={tabKeys.REPORT_PERIOD_GOS}
              tab={t('gosReport.title')}
            >
              <h2>{t('gosReport.title')}</h2>
              <GosReport t={t}/>
            </Tabs.TabPane>
            <Tabs.TabPane
              key={tabKeys.REPORT_ELECTRIC_CONSUMPTION}
              tab={t('electricConsumptionReportTab')}
            >
              <h2>{t('electricConsumptionReport.title')}</h2>
              <ElectricConsumptionReport t={t}/>
            </Tabs.TabPane>
            <Tabs.TabPane key={tabKeys.REPORT_GRID} tab={t('reportGridTab')}>
              <h2>{t('gridReport.title')}</h2>
              <ReportGrid t={t}/>
            </Tabs.TabPane>
            <Tabs.TabPane key={tabKeys.REPORT_GO} tab={t('reportGoTab')}>
              <h2>{t('periodReport.title')}</h2>
              <AccountReportFilterTranslated/>
              <AccountReportTranslated t={t}/>
            </Tabs.TabPane>
          </Tabs>
        </Layout>
      );
    }

    return 'No rights';
  }
}

const mapStateToProps = state => ({
  currentPath: currentPathSelector(state),
  userRole: userSelectors.getRole(state),
  userAccounts: userSelectors.getUserLegalEntityAccounts(state).toJS(),
});

export default translate(NAMESPACES.report)(
  connect(mapStateToProps)(ReportPage)
);
