import {normalizeHelper, createAsyncAction} from '../../utils/asyncHelpers';
import {
  AVP_METERING_POINTS_FETCH,
  RECALCULATE_GO,
} from '../../constants/actionTypes/manualGoIssuingActionTypes';
import {
  producerAvpPointsFetch,
  recalculateGoPost,
} from '../actionHelpers/endpoints';
import {measurement as meteringPoint} from '../../schemas';

export const fetchAvpMeteringPoints = createAsyncAction(
  AVP_METERING_POINTS_FETCH,
  producerAvpPointsFetch,
  normalizeHelper([meteringPoint])
);

export const recalculateGo = createAsyncAction(
  RECALCULATE_GO,
  recalculateGoPost
);
