import React, {Component} from 'react';
import {Alert, Spin} from "antd";
import {generateSalesCancellationRequestToVDSXML, parseResponse} from "../../utils/vdsHelpers";
import {sendSalesCancellationRequestToVDS} from "../../actions/actionHelpers/endpoints";
import {VDSSalesCancellationForm} from "../../forms";

export class VDSSalesCancellationView extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: '',
      request: '',
      response: null
    };
  }

  clearMessages = () => {
    this.setState({error: '', request: '', response: null});
  };

  send = async (values) => {
    this.clearMessages();
    const xml = generateSalesCancellationRequestToVDSXML(values);
    this.setState({loading: true, request: xml, response: null});
    try {
      const responseObject = await sendSalesCancellationRequestToVDS({xml});
      const response = parseResponse(responseObject);
      this.setState({
        loading: false,
        error: response.error ? 'Viga andmete saatmisel VDS-i' : '',
        response
      });
    } catch (error) {
      console.error(error);
      const response = {error: error.message};
      this.setState({loading: false, error: 'Viga andmete saatmisel VDS-i', response});
    }
  };

  render() {
    const {loading, error, response, request} = this.state;

    return (
      <Spin spinning={loading}>
        <h1>{'Müügipakkumise osaline või täielik tühistus'}</h1>
        <VDSSalesCancellationForm onSubmit={this.send}
                                  request={request}
                                  response={response}
                                  onReset={this.clearMessages}>
          {!error && !!response &&
            <Alert message={'Andmed saadeti VDS-ile'} type="success" closable/>}
          {!!error && <Alert message={error} type="error" closable/>}
        </VDSSalesCancellationForm>
      </Spin>
    );
  }
}

export default VDSSalesCancellationView;
