import React from 'react';
import {
  LoadingUnitAdd as LoadingUnitAddContainer,
} from '../../containers';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import {Layout} from 'antd';
import {isAdmin as isAdminUtil} from '../../utils/roles';
import {goToLoadingUnitList, goToLoadingUnitSearch} from '../../utils/gotoLink';
import PropTypes from 'prop-types';
import {getRole, getUserLegalEntityId} from '../../reducers/userReducer';
import {resetMasterActionCreator} from '../../actions/actionCreators/loadingUnitActionCreator';
import {NAMESPACES} from '../../i18n';
import {getOwner} from '../../reducers/loadingUnitAddReducer';

class LoadingUnitAdd extends React.Component {
  static propTypes = {
    legalEntityId: PropTypes.number,
    isAdmin: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    resetMasterActionCreator: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    this.props.resetMasterActionCreator();
  };

  render() {
    const {t, legalEntityId, isAdmin} = this.props;

    return (
      <Layout className="main-container">
        <Layout.Content>
          <h2 className="register-management-container__subtitle">
            {t('loadingUnit.title')}
          </h2>
          <LoadingUnitAddContainer
            legalEntityId={legalEntityId}
            isLoadingUnitAdd={true}
            isAdmin={isAdmin}
            allowToSelectCompany={true}
            isEditMode={false}
            onSubmitSuccess={isAdmin ? goToLoadingUnitSearch : goToLoadingUnitList}
          />
        </Layout.Content>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  const isAdmin = isAdminUtil(getRole(state));

  let legalEntityId;
  if (isAdmin) {
    legalEntityId = getOwner(state);
  } else {
    legalEntityId = getUserLegalEntityId(state);
  }

  return ({
    legalEntityId,
    isAdmin,
  });
};

const mapDispatchToProps = {
  resetMasterActionCreator,
};

export default translate(NAMESPACES.register)(
  connect(mapStateToProps, mapDispatchToProps)(LoadingUnitAdd),
);
