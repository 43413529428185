import React, {Component} from 'react';
import {searchAccount} from '../../actions/actionHelpers/endpoints';
import {AutoCompleteSelectField} from '../';
import {translateLegalEntityTypeClassificator} from '../../utils/translateHelpers';

const getDataSource = async value => {
  const {data} = await searchAccount(value);
  return data.map(item => ({
    value: item.id,
    label: `${item.companyName}, ${translateLegalEntityTypeClassificator(
      item.accountType
    )}`,
  }));
};

class AutoCompleteAccountsField extends Component {
  render() {
    return (
      <AutoCompleteSelectField {...this.props} getDataSource={getDataSource}/>
    );
  }
}

export default AutoCompleteAccountsField;
