import React, {useEffect, useState} from 'react';
import {ElectricMeteringPointSearchTable} from '../../components';
import {Collapse, Layout} from "antd";
import {
  ElectricMeteringPointFilter
} from "../../forms/ElectricMeteringPointFilter/ElectricMeteringPointFilter";
import {electricMeteringPointListSearch} from "../../actions/actionHelpers/endpoints";

export default function ElectricMeteringPointList({t, isUserAdmin, isUserLoadingOperator}) {
  const [filter, setFilter] = useState({});
  const [pager, setPager] = useState({page: 1, size: 10});
  const {page, size} = pager;
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState({content: [], totalElements: 0, size: 0});
  const {content, totalElements} = response;

  useEffect(() => {
    !loading && setLoading(true);
    electricMeteringPointListSearch({pager: {...pager, page: pager.page - 1}, filter})
      .then(response => setResponse(response.data) & setLoading(false))
      .catch(error => console.error(error) & setLoading(false));
  }, [page, filter]);

  const setPage = (page) => setPager({...pager, page: page});
  const onFilter = (filter) => {
    setPage(1);
    const newFilter = {};
    Object.keys(filter).forEach(key => {
      if (filter[key]) {
        newFilter[key] = filter[key];
      }
    });
    setFilter(newFilter);
  };

  return (
    <Layout.Content>
      <div className="search-form-container">
        <Collapse>
          <Collapse.Panel key="filters" header={t('filter.title')}>
            <ElectricMeteringPointFilter
              onFilter={onFilter}
              isUserAdmin={isUserAdmin}
              isUserLoadingOperator={isUserLoadingOperator}
              t={t}
            />
          </Collapse.Panel>
        </Collapse>
      </div>
      <div className="search-results-container">
        <ElectricMeteringPointSearchTable
          content={content}
          totalElements={totalElements}
          loading={loading}
          page={page}
          pageSize={size}
          setPage={setPage}
          t={t}
        />
      </div>
    </Layout.Content>
  );
}
