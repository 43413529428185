import {handleActions} from 'redux-actions';
import {Map} from 'immutable';
import * as actions from '../actions/pureActions/statisticsActions';
import {
  createAsyncReducer,
  initialAsyncState,
  listInitialAsyncState,
} from '../utils/asyncHelpers';
import {
  ACCOUNT_STATISTICS_LIST_FETCH,
  LOCK_STATISTICS,
  TRANSFER_STATISTICS,
} from '../constants/actionTypes/transferringStatisticsActionTypes';

const initialState = Map({
  selectedReceiver: null,
  selectSender: null,
  selectedReportYears: [],
  statisticsType: null,
  statisticsTransferring: initialAsyncState,
  statisticsLocking: initialAsyncState,
  accountStatisticsList: listInitialAsyncState,
});

export default handleActions(
  {
    ...createAsyncReducer(TRANSFER_STATISTICS, 'statisticsTransferring'),
    ...createAsyncReducer(LOCK_STATISTICS, 'statisticsLocking'),
    ...createAsyncReducer(ACCOUNT_STATISTICS_LIST_FETCH, 'accountStatisticsList'),
    [actions.selectReceiver]: (state, {payload}) =>
      state.merge({selectedReceiver: payload}),
    [actions.selectReportYears]: (state, {payload}) =>
      state.merge({selectedReportYears: payload}),
    [actions.selectReportRows]: (state, {payload}) =>
      state.merge({selectedReportRows: payload}),
    [actions.selectSender]: (state, {payload}) =>
      state.merge({selectSender: payload}),
    [actions.statisticsType]: (state, {payload}) =>
      state.merge({statisticsType: payload}),
    [actions.resetStatisticsTransferTable]: state => state.merge(initialState),
  },
  initialState
);

export const getSelectedReceiver = state =>
  state.getIn(['statistics', 'selectedReceiver']);

export const getSelectedSender = state =>
  state.getIn(['statistics', 'selectSender']);

export const getSelectReportYears = state =>
  state.getIn(['statistics', 'selectedReportYears']);

export const getSelectReportRows = state =>
  state.getIn(['statistics', 'selectedReportRows']);

export const getStatisticsType = state =>
  state.getIn(['statistics', 'statisticsType']);

export const getAccountStatisticsList = state =>
  state.getIn(['statistics', 'accountStatisticsList']);

export const getAccountStatisticsData = state =>
  state.getIn(['statistics', 'accountStatisticsList', 'data']);
