import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Spin, message, Modal} from 'antd';
import {RegisterActions, TableActions} from '../../components';
import {MeasuringUpload} from '../';
import {RegisterMeasurementAdd} from '../../forms';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import * as actions from '../../actions/actionCreators/prodUnitMeasurementPointActionCreators';
import {
  getMeasurementList,
  getMeasurementListInactive,
  getEntityMeteringPointsData,
} from '../../reducers/registerReducer';
import {
  getMeasuringUploadModal, hideMeasuringUploadModal, showMeasuringUploadModal,
} from '../../actions/actionCreators/modalsActionCreators';
import * as measurementSelectors from '../../reducers/prodUnitMeasurementPointReducer';
import MeasurementTable from '../../components/MeasurementTable/MeasurementTable';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {generalFormFields} from '../../constants/formsAddEquipment';
import {meteringPointFields} from '../../constants/meteringPoint';
import {productionUnitFields} from '../../constants/productionUnit';
import './RegisterMeasurement.css';
import {
  fetchMeasurementsActionCreator,
  resetMeasurementsActionCreator,
  resetProductionUnitMeasurementActionCreator
} from "../../actions/actionCreators/registerActionCreators";

class RegisterMeasurement extends Component {
  static propTypes = {
    resetMeasurements: PropTypes.func.isRequired,
    resetProductionUnitMeasurement: PropTypes.func.isRequired,
    resetInactiveMeasurements: PropTypes.func.isRequired,
    fetchMeasurements: PropTypes.func.isRequired,
    fetchMeasurementsInactive: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    measurements: PropTypesImmutable.list.isRequired,
    hideMeasurementForm: PropTypes.func.isRequired,
    fetchProductionUnitMeasurement: PropTypes.func.isRequired,
    fetchProductionUnitInactiveMeasurement: PropTypes.func.isRequired,
    legalEntityId: PropTypes.number.isRequired,
    productionUnit: PropTypesImmutable.map.isRequired,
    measurementFormVisible: PropTypes.bool.isRequired,
    startEditProductionUnitMeasurement: PropTypes.func.isRequired,
    deleteProductionUnitMeasurement: PropTypes.func.isRequired,
    startAddProductionUnitMeasurement: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    inEditForNotAdmin: PropTypes.bool.isRequired,
    addProductionUnitMeasurement: PropTypes.func.isRequired,
    showRequestChangeModal: PropTypes.func,
    stateImportModal: PropTypes.bool.isRequired,
    hideImportModal: PropTypes.func.isRequired,
    showImportModal: PropTypes.func.isRequired,
  };

  state = {
    isAdminEdit: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      fetchMeasurements,
      productionUnit,
      legalEntityId,
      fetchProductionUnitMeasurement
    } = this.props;

    if (this.state.isAdminEdit && !prevState.isAdminEdit) {
      fetchMeasurements(legalEntityId);
    }

    if ((!this.state.isAdminEdit && prevState.isAdminEdit)) {
      fetchProductionUnitMeasurement(productionUnit.get(generalFormFields.ID));
    }

  }

  handleIsAdminEdit = () => {
    this.setState({
      isAdminEdit: true,
    });
  };

  componentDidMount() {
    const {
      resetMeasurements,
      resetProductionUnitMeasurement,
      isEditMode,
      fetchMeasurements,
      legalEntityId
    } = this.props;
    resetMeasurements();
    resetProductionUnitMeasurement();
    const {
      fetchProductionUnitMeasurement,
      productionUnit,
    } = this.props;

    fetchProductionUnitMeasurement(productionUnit.get(generalFormFields.ID));

    if (!isEditMode) {
      fetchMeasurements(legalEntityId);
    }

  }

  async goNext() {
    const {
      selectedMeasurementId,
      onSubmitSuccess,
      t,
      updateProductionUnitMeasurement,
      resetMeasurements,
      resetProductionUnitMeasurement,
      measurements,
      isEditMode,
      productionUnit
    } = this.props;

    if (selectedMeasurementId === '0' || !selectedMeasurementId) {
      message.error(t('measurement.warningBody'));
    } else {
      let meteringPoint = measurements.find(
        m => m.get(meteringPointFields.EICZ_CODE) === selectedMeasurementId
      );
      if (!meteringPoint) {
        meteringPoint = measurements.find(
          m => m.get(meteringPointFields.ID) === selectedMeasurementId
        );
      }

      // set id null if new
      if (selectedMeasurementId != null && selectedMeasurementId.search && selectedMeasurementId.search('NEW') >= 0) {
        meteringPoint = meteringPoint.delete(meteringPointFields.ID);
      }

      await updateProductionUnitMeasurement(
        meteringPoint
          .merge({
            [meteringPointFields.PRODUCTION_UNIT_ID]: productionUnit.get(productionUnitFields.ID),
            [meteringPointFields.LEGAL_ENTITY_ID]: productionUnit.get(productionUnitFields.LEGAL_ENTITY_ID)
          })
          .toJS()
      );

      if (!isEditMode) onSubmitSuccess();

      this.setState({
        isAdminEdit: false
      });

      resetMeasurements();
      resetProductionUnitMeasurement();

    }
  }

  render() {
    const {
      hasPrevious,
      hasDone,
      goPrevious,
      t,
      isLoading,
      measurements,
      measurementFormVisible,
      hideMeasurementForm,
      selectMeasurement,
      selectedMeasurementId,
      productionUnit,
      startEditProductionUnitMeasurement,
      deleteProductionUnitMeasurement,
      startAddProductionUnitMeasurement,
      isEditMode,
      isAdmin,
      inEditForNotAdmin,
      showRequestChangeModal,
      stateImportModal,
      hideImportModal,
      showImportModal,
    } = this.props;

    const {isAdminEdit} = this.state;

    return (
      <Spin spinning={isLoading}>
        <div className="register-measurement-container">
          <h2 className="register-measurement-container__subtitle">
            {isEditMode ? t('measurement.titleEdit') : t('measurement.title')}
          </h2>
          <MeasurementTable
            t={t}
            data={measurements.toJS()}
            onSelectMeasurement={selectMeasurement}
            selectedMeasurementId={selectedMeasurementId}
            onEditClick={startEditProductionUnitMeasurement}
            onDeleteClick={deleteProductionUnitMeasurement}
            isAdmin={isAdmin}
            isEditMode={isEditMode}
            isLoading={isLoading}
            showImportModal={showImportModal}
            isAdminEdit={isAdminEdit}
          />
          {(isAdmin && !isAdminEdit && isEditMode) ? (
            <TableActions>
              <Button onClick={this.handleIsAdminEdit} type="primary">
                {t('measurement.adminEditBtn')}
              </Button>
            </TableActions>
          ) : (
            <React.Fragment>
              {measurementFormVisible ? (
                <RegisterMeasurementAdd
                  productionUnitId={productionUnit.get(generalFormFields.ID)}
                  closeForm={hideMeasurementForm}
                  onSubmit={actions.onMetringFormSubmit}
                  t={t}
                  inEditForNotAdmin={inEditForNotAdmin}
                  isAdmin={isAdmin}
                />
              ) : (
                <div>
                  {(!isEditMode || isAdmin) && (
                    <TableActions>
                      <Button
                        onClick={startAddProductionUnitMeasurement}
                        size="large"
                        type="primary"
                      >
                        {t('measurement.buttonAdd')}
                      </Button>
                    </TableActions>
                  )}
                  <RegisterActions
                    showRequestChangeModal={showRequestChangeModal}
                    hasPrevious={hasPrevious}
                    hasDone={hasDone}
                    goPrevious={goPrevious}
                    goNext={() => this.goNext()}
                    isEditMode={isEditMode}
                    isAdmin={isAdmin}
                    showSaveButton={!isEditMode || isAdmin}
                    t={t}
                  />
                </div>
              )}
            </React.Fragment>
          )}
        </div>
        {stateImportModal && (
          <Modal
            onCancel={hideImportModal}
            visible={stateImportModal}
            footer={false}
            title={t('productionUnit.importTitle')}
            width="600px"
          >
            <MeasuringUpload
              onComplete={hideImportModal}
              productionUnitId={this.selectedIdToImport}
            />
          </Modal>
        )}
      </Spin>
    );
  }
}

const isLoading = getLoadingSelector(
  getMeasurementList,
  getMeasurementListInactive,
  measurementSelectors.getMeteringPoint
);

const mapStateToProps = state => {

  const measurementList = getEntityMeteringPointsData(state);
  const prodMeasurement = measurementSelectors.getMeteringPointData(state);

  let measurements = measurementList.filter((item) => item.get(meteringPointFields.EICZ_CODE) != prodMeasurement.get(meteringPointFields.EICZ_CODE));

  measurements =
    (prodMeasurement.size !== 0 && prodMeasurement.get(meteringPointFields.ADDRESS) !== undefined)
      ? measurements.push(prodMeasurement)
      : measurements;

  measurements = measurements.filter((v, i) => {
    return measurements.map((val) => val.get(meteringPointFields.EICZ_CODE)).indexOf(v.get(meteringPointFields.EICZ_CODE)) == i
  })

  const measurementFormVisible = measurementSelectors.getProdUnitMeasurementFormState(
    state
  );
  const selectedMeasurementId = measurementSelectors.getProdUnitSelectedMeasurement(
    state
  );

  return {
    selectedMeasurementId,
    isLoading: isLoading(state),
    measurements,
    measurementFormVisible,
    stateImportModal: getMeasuringUploadModal(state),
  };
};

const mapDispatchToProps = {
    resetMeasurements: resetMeasurementsActionCreator,
    resetProductionUnitMeasurement: resetProductionUnitMeasurementActionCreator,
    fetchMeasurements: fetchMeasurementsActionCreator,
    showImportModal: showMeasuringUploadModal,
    hideImportModal: hideMeasuringUploadModal,
    ...actions,

  }

;


export default connect(mapStateToProps, mapDispatchToProps)(
  RegisterMeasurement
);
