import React, {Component} from 'react';
import PropTypesImmutable from 'react-immutable-proptypes';
import {connect} from 'react-redux';
import {LoadingUnitSearchTable} from '../../components';
import {getLoadingUnitList, getLoadingUnitListData} from '../../reducers/loadingUnitListReducer';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {searchLoadingUnits} from '../../actions/actionCreators/loadingUnitActionCreator';

export class LoadingUnitList extends Component {
  static propTypes = {
    loadingUnits: PropTypesImmutable.list,
    loadingUnitListState: PropTypesImmutable.map.isRequired,
  };

  render() {
    const {loadingUnits, loadingUnitListState, ...rest} = this.props;

    return (
      <React.Fragment>
        <LoadingUnitSearchTable
          loadingUnits={loadingUnits.toJS()}
          {...rest}
          {...loadingUnitListState.toJS()}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const loadingUnitListState = getLoadingUnitList(state);

  return {
    loadingUnitListState,
    loading: getLoadingSelector(getLoadingUnitList)(state),
    loadingUnits: getLoadingUnitListData(state),
  };
};

const mapDispatchToProps = {
  searchLoadingUnits
};

export default connect(mapStateToProps, mapDispatchToProps)(LoadingUnitList);
