import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import PropTypesImmutable from 'react-immutable-proptypes';
import {
  Field,
  reduxForm,
  formValueSelector,
  SubmissionError,
} from 'redux-form/immutable';
import {
  TextField,
  ClassificatorField,
  RadioField,
  DateField,
} from '../../atoms';
import { Row, Col, message } from 'antd';
import { get, isString } from 'lodash';
import { connect } from 'react-redux';
import { tAddEquipment } from '../../i18n';
import {
  FORM_TECHNICAL_INFORMATION,
  technicalInformationFormFields,
  certificateFormFields,
} from '../../constants/formsAddEquipment';
import { parseNumber, parseMegawatt } from '../../utils/formHelpers';
import { RegisterActions, FileUpload } from '../../components';
import { getLoadingSelector } from '../../utils/asyncHelpers';
import economicCertificateName from '../../constants/classificators/economicCertificateName';
import { updateProductionUnit } from '../../actions/actionCreators/productionUinitActionCreators';
import economicCertificateClassificator from '../../constants/classificators/economicCertificate';
import * as certificateSelectors from '../../reducers/productionUnitCertificatesReducer';
import * as certificateActions from '../../actions/actionCreators/productionUnitCertificatesActionCreators';
import { getProductionUnitCertificates } from '../../schemas/productionUnitCertificate';
import { FILE_URL } from '../../constants/file';
import validate from './validateSchema';
import hydroValidate from './validateHydroSchema';
import './addEquipmentTechnicalInformation.css';
import { hydrogenTechnologyType } from '../../constants/classificators';

const tCertTypeKey = key =>
  `common:classificators.economicCertificateName.${key}`;

const mapCertificatesToFiles = certificates => {
  return certificates.toJS().map(d => ({
    uid: get(d, certificateFormFields.FILE),
    name: get(d, certificateFormFields.NAME),
    status: 'done',
    url: `${FILE_URL}/${get(d, certificateFormFields.FILE)}`,
  }));
};

const isCertificateCustom = certificate =>
  ![economicCertificateName.iscc, economicCertificateName.redcert].includes(
    certificate
  );

export class AddEquipmentTechnicalInformation extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hasPrevious: PropTypes.bool,
    hasDone: PropTypes.bool,
    goPrevious: PropTypes.func,
    economicCertificate: PropTypes.string,
    certificateName: PropTypes.string,
    certificates: PropTypesImmutable.list,
    toBeRemovedCertificates: PropTypesImmutable.list,
    fetchProductionUnitCertificates: PropTypes.func.isRequired,
    initialValues: PropTypesImmutable.map.isRequired,
    isFilesLoading: PropTypes.bool.isRequired,
    productionUnit: PropTypesImmutable.map,
    inEditForNotAdmin: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    showRequestChangeModal: PropTypes.func,
    isHydrogenContext: PropTypes.bool,
  };

  state = {
    hydrogenTechnologyValue: this.props.productionUnit.hydrogenTechnologyType,
  };

  static defaultProps = {
    certificates: List(),
  };

  componentDidMount = () => {
    const { fetchProductionUnitCertificates, initialValues } = this.props;
    const id = initialValues.get('id');

    if (id) fetchProductionUnitCertificates(id);
  };

  onFileChange = info => {
    const {
      addProductionUnitCertificate,
      initialValues,
      deleteProductionUnitCertificate,
    } = this.props;

    if (info.file.status === 'done') {
      addProductionUnitCertificate({
        [certificateFormFields.FILE]: info.file.response,
        [certificateFormFields.PRODUCTION_UNIT_ID]: initialValues.get('id'),
        [certificateFormFields.NAME]: info.file.name,
      });
    } else if (info.file.status === 'removed') {
      deleteProductionUnitCertificate(info.file);
    }
  };

  isCustomCertificateShow = () => {
    const { economicCertificate, certificateName } = this.props;

    return (
      economicCertificate !==
        economicCertificateClassificator.VOLUNTARY_SCHEME ||
      certificateName === economicCertificateName.other
    );
  };

  render() {
    const {
      handleSubmit,
      hasPrevious,
      hasDone,
      goPrevious,
      t,
      economicCertificate,
      certificates,
      isFilesLoading,
      inEditForNotAdmin,
      isEditMode,
      isAdmin,
      showRequestChangeModal,
      toBeRemovedCertificates,
      isHydrogenContext,
      productionUnit,
    } = this.props;
    const { hydrogenTechnologyValue } = this.state;

    const isElectrolysis =
      hydrogenTechnologyValue === hydrogenTechnologyType.ELECTROLYSIS ||
      productionUnit.get('hydrogenTechnologyType') ===
        hydrogenTechnologyType.ELECTROLYSIS;

    return (
      <div className="production-unit-tech-info">
        <form onSubmit={handleSubmit}>
          <h2>
            {isEditMode
              ? t('technicalInformation.titleEdit')
              : t('technicalInformation.title')}
          </h2>
          <Row gutter={32}>
            <Col span={12}>
              {isHydrogenContext ? (
                <Field
                  label={t('technicalInformation.hydrogenTechnologyType')}
                  name={technicalInformationFormFields.HYDROGEN_TECHNOLOGY_TYPE}
                  component={ClassificatorField}
                  type={ClassificatorField.types.hydrogenTechnologyType}
                  required
                  disabled={
                    inEditForNotAdmin ||
                    productionUnit.get('hydrogenTechnologyType')
                  }
                  onChange={e => this.setState({ hydrogenTechnologyValue: e })}
                />
              ) : (
                <Field
                  label={t('technicalInformation.cleaningTechnologyType')}
                  name={technicalInformationFormFields.CLEANING_TECHNOLOGY_TYPE}
                  component={ClassificatorField}
                  type={ClassificatorField.types.cleaningTechnologyType}
                  required
                  disabled={
                    inEditForNotAdmin || productionUnit.get('technologyType')
                  }
                />
              )}
            </Col>
            <Col span={12}>
              <Field
                name={technicalInformationFormFields.NOMINAL_POWER}
                component={TextField}
                addonAfter={t('technicalInformation.nominalPower.units')}
                type="text"
                parse={parseMegawatt}
                label={t('technicalInformation.nominalPower.label')}
                required
                disabled={inEditForNotAdmin}
              />
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={12}>
              {!isHydrogenContext && (
                <Field
                  name={technicalInformationFormFields.ECONOMIC_CERTIFICATE}
                  component={ClassificatorField}
                  label={t('technicalInformation.economicCertificate')}
                  type={ClassificatorField.types.economicCertificate}
                  required
                  disabled={inEditForNotAdmin}
                />
              )}
              {isElectrolysis && (
                <Field
                  label={t('technicalInformation.electrolysisTechnologyType')}
                  name={
                    technicalInformationFormFields.ELECTROLYSIS_TECHNOLOGY_TYPE
                  }
                  component={ClassificatorField}
                  type={ClassificatorField.types.electrolysisTechnologyType}
                  required
                  disabled={
                    inEditForNotAdmin ||
                    productionUnit.get('electrolysisTechnologyType')
                  }
                />
              )}
            </Col>
            <Col span={12}>
              {!isHydrogenContext && (
                <Field
                  name={technicalInformationFormFields.SELF_SUFFICIENT_WEIGHT}
                  component={TextField}
                  addonAfter="%"
                  type="text"
                  parse={parseNumber}
                  label={t('technicalInformation.selfSufficientWeight')}
                  required
                  disabled={inEditForNotAdmin}
                />
              )}
            </Col>
          </Row>
          {isHydrogenContext && (
            <Row gutter={32}>
              <Col span={12}>
                <Field
                  name={technicalInformationFormFields.RFNBO}
                  component={RadioField}
                  required
                  options={[
                    {
                      value: true,
                      label: t('technicalInformation.rfnbo.trueLabel'),
                    },
                    {
                      value: false,
                      label: t('technicalInformation.rfnbo.falseLabel'),
                    },
                  ]}
                  type="text"
                  label={t('technicalInformation.rfnbo.label')}
                  disabled={inEditForNotAdmin}
                />
              </Col>
            </Row>
          )}
          {!isHydrogenContext && (
            <Row gutter={32}>
              {economicCertificate ===
                economicCertificateClassificator.VOLUNTARY_SCHEME && (
                <Col span={12}>
                  <Field
                    name={technicalInformationFormFields.CERTIFICATE_NAME}
                    component={RadioField}
                    label={t('technicalInformation.certificateName')}
                    required
                    disabled={inEditForNotAdmin}
                    options={[
                      {
                        value: economicCertificateName.redcert,
                        label: t(tCertTypeKey(economicCertificateName.redcert)),
                      },
                      {
                        value: economicCertificateName.iscc,
                        label: t(tCertTypeKey(economicCertificateName.iscc)),
                      },
                      {
                        value: economicCertificateName.other,
                        label: t(tCertTypeKey(economicCertificateName.other)),
                      },
                    ]}
                  />
                </Col>
              )}
              {this.isCustomCertificateShow() && (
                <Col span={12}>
                  <Field
                    name={
                      technicalInformationFormFields.CUSTOM_CERTIFICATE_NAME
                    }
                    component={TextField}
                    type="text"
                    label={t('technicalInformation.certificateName')}
                    required
                    disabled={inEditForNotAdmin}
                  />
                </Col>
              )}
            </Row>
          )}
          {!isHydrogenContext && (
            <Row gutter={32}>
              <Col span={12}>
                <Field
                  className="date-field"
                  name={technicalInformationFormFields.CERTIFICATE_END_DATE}
                  component={DateField}
                  showTime={false}
                  label={t('technicalInformation.certificateEndDate')}
                  required
                  disabled={inEditForNotAdmin}
                />
              </Col>
            </Row>
          )}
          <Row gutter={32}>
            <Col className="gutter-row" span={12}>
              <h3 className="form-register-additional__document-title">
                {t('technicalInformation.uploadTitle')}
              </h3>
              {!isFilesLoading && (
                <FileUpload
                  t={t}
                  onChange={this.onFileChange}
                  defaultFileList={mapCertificatesToFiles(certificates)}
                  showUploadList={{ showRemoveIcon: !inEditForNotAdmin }}
                  disabled={inEditForNotAdmin}
                />
              )}
            </Col>
          </Row>
          {toBeRemovedCertificates.size !== 0 && (
            <Row gutter={32}>
              <Col className="gutter-row pt-3" span={12}>
                <h3 className="dfn">
                  {`${t('technicalInformation.toBeRemovedCertificatesTitle')}:`}
                </h3>
                {toBeRemovedCertificates.toJS().map((d, i) => (
                  <div key={i} className="deleted-item">
                    {get(d, certificateFormFields.NAME)}
                  </div>
                ))}
              </Col>
            </Row>
          )}
          <RegisterActions
            showRequestChangeModal={showRequestChangeModal}
            hasPrevious={hasPrevious}
            hasDone={hasDone}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
            goPrevious={goPrevious}
            showSaveButton={isAdmin || !isEditMode}
            t={t}
          />
        </form>
      </div>
    );
  }
}

const selector = formValueSelector(FORM_TECHNICAL_INFORMATION);

const mapStateToProps = (state, ownProps) => {
  let initialValues = ownProps.productionUnit;
  if (
    isCertificateCustom(
      initialValues.get(technicalInformationFormFields.CERTIFICATE_NAME)
    )
  ) {
    initialValues = initialValues.merge({
      [technicalInformationFormFields.CERTIFICATE_NAME]:
        economicCertificateName.other,
      [technicalInformationFormFields.CUSTOM_CERTIFICATE_NAME]: initialValues.get(
        technicalInformationFormFields.CERTIFICATE_NAME
      ),
    });
  }

  return {
    economicCertificate: selector(
      state,
      technicalInformationFormFields.ECONOMIC_CERTIFICATE
    ),
    certificateName: selector(
      state,
      technicalInformationFormFields.CERTIFICATE_NAME
    ),
    isFilesLoading: getLoadingSelector(
      certificateSelectors.getProductionUnitCertificatesAddState,
      certificateSelectors.getProductionUnitCertificatesList
    )(state),
    certificates: getProductionUnitCertificates(
      state,
      certificateSelectors.getProductionUnitCertificatesList(state)
    ),
    toBeRemovedCertificates: certificateSelectors.getProductionUnitCertificatesDeleteState(
      state
    ),
    initialValues,
  };
};

const mapDispatchToProps = {
  ...certificateActions,
};

const onSubmit = (values, dispatch, ownProps) => {
  if (!ownProps.isHydrogenContext && ownProps.certificates.isEmpty()) {
    throw new SubmissionError(
      tAddEquipment('technicalInformation.certificateError')
    );
  }

  const customCertificate = values.get(
    technicalInformationFormFields.CUSTOM_CERTIFICATE_NAME
  );
  let passedValues = values;

  if (
    isCertificateCustom(
      values.get(technicalInformationFormFields.CERTIFICATE_NAME)
    ) &&
    customCertificate
  ) {
    passedValues = values.set(
      technicalInformationFormFields.CERTIFICATE_NAME,
      customCertificate
    );
  }

  if (!ownProps.toBeRemovedCertificates.isEmpty()) {
    passedValues = values.set(
      technicalInformationFormFields.IDS_OF_FILES,
      ownProps.toBeRemovedCertificates.map(o => o.uid)
    );
  }

  dispatch(updateProductionUnit(passedValues.toJS()));
};

const onSubmitFail = errors => {
  if (isString(errors)) {
    message.error(errors);
  }
};

const getValidator = props => {
  return props.isHydrogenContext ? hydroValidate : validate;
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_TECHNICAL_INFORMATION,
    enableReinitialize: true,
    validate: (values, props) => getValidator(props)(values),
    onSubmit,
    onSubmitFail,
  })(AddEquipmentTechnicalInformation)
);
