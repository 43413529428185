import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Modal} from 'antd';
import {RequestChange} from '../../forms';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import notificationRelatedObjectType
  from '../../constants/classificators/notificationRelatedObjectType';
import {
  hideRequestChangeEquipmentModal,
  hideRequestChangeLegalEntityModal,
  hideRequestChangeMeteringModal,
  getRequestChangeLegalEntityModal,
  getRequestChangeMeteringModal,
  getRequestChangeEquipmentModal,
} from '../../actions/actionCreators/modalsActionCreators';

export class RequestChangeModal extends Component {
  static propTypes = {
    relatedObjectId: PropTypes.number,
    stateRequestChangeMeteringModal: PropTypes.bool.isRequired,
    stateRequestChangeLegalEntityModal: PropTypes.bool.isRequired,
    stateRequestChangeEquipmentModal: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    hideModalEquipment: PropTypes.func.isRequired,
    hideModalLegalEntity: PropTypes.func.isRequired,
    hideModalMetering: PropTypes.func.isRequired,
    relatedObjectType: PropTypes.string.isRequired,
  };

  hideModals = () => {
    const {
      hideModalEquipment,
      hideModalLegalEntity,
      hideModalMetering,
    } = this.props;

    hideModalEquipment();
    hideModalLegalEntity();
    hideModalMetering();
  };

  render() {
    const {
      relatedObjectId,
      t,
      relatedObjectType,
      stateRequestChangeMeteringModal,
      stateRequestChangeLegalEntityModal,
      stateRequestChangeEquipmentModal,
    } = this.props;

    let isShowModal = false;
    if (relatedObjectType === notificationRelatedObjectType.MEASUREMENT_POINT) {
      isShowModal = stateRequestChangeMeteringModal;
    } else if (
      relatedObjectType === notificationRelatedObjectType.LEGAL_ENTITY
    ) {
      isShowModal = stateRequestChangeLegalEntityModal;
    } else if (
      relatedObjectType === notificationRelatedObjectType.PRODUCTION_UNIT
    ) {
      isShowModal = stateRequestChangeEquipmentModal;
    }

    return isShowModal ? (
      <Modal
        onCancel={this.hideModals}
        visible
        footer={false}
        title={t('modalTitle')}
        width="600px"
      >
        <RequestChange
          onCancel={this.hideModals}
          onComplete={this.hideModals}
          relatedObjectId={relatedObjectId}
          relatedObjectType={relatedObjectType}
        />
      </Modal>
    ) : null;
  }
}

const mapStateToProps = state => ({
  stateRequestChangeLegalEntityModal: getRequestChangeLegalEntityModal(state),
  stateRequestChangeMeteringModal: getRequestChangeMeteringModal(state),
  stateRequestChangeEquipmentModal: getRequestChangeEquipmentModal(state),
});

const mapDispatchToProps = {
  hideModalEquipment: hideRequestChangeEquipmentModal,
  hideModalLegalEntity: hideRequestChangeLegalEntityModal,
  hideModalMetering: hideRequestChangeMeteringModal,
};

export default translate(NAMESPACES.requestChange)(
  connect(mapStateToProps, mapDispatchToProps)(RequestChangeModal)
);
