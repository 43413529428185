import {
  ACCOUNT_OVERVIEW_SEARCH_FETCH,
  ACCOUNT_REPORT_SEARCH_FETCH,
  ACCOUNT_GROUPED_CERTIFICATES_FETCH,
} from '../constants/actionTypes/accountActionTypes';
import {
  createAsyncReducer,
  listInitialAsyncState,
  initialAsyncState,
} from '../utils/asyncHelpers';
import {handleActions} from 'redux-actions';
import {Map} from 'immutable';

const initialState = Map({
  listOverview: listInitialAsyncState,
  listReport: listInitialAsyncState,
  groupedCertificates: listInitialAsyncState,
  cancelAvailability: initialAsyncState,
});

const accountOverviewReducer = handleActions(
  {
    ...createAsyncReducer(ACCOUNT_OVERVIEW_SEARCH_FETCH, 'listOverview'),
    ...createAsyncReducer(ACCOUNT_REPORT_SEARCH_FETCH, 'listReport'),
    ...createAsyncReducer(
      ACCOUNT_GROUPED_CERTIFICATES_FETCH,
      'groupedCertificates'
    ),
  },
  initialState
);

export const getOverviewList = state =>
  state.getIn(['accountOverview', 'listOverview']);

export const getOverviewListData = state => getOverviewList(state).get('data');

export const getReportList = state =>
  state.getIn(['accountOverview', 'listReport']);

export const getReportListData = state => getReportList(state).get('data');

export const getGroupedCertificates = state =>
  state.getIn(['accountOverview', 'groupedCertificates']);

export const getGroupedCertificatesData = state =>
  getGroupedCertificates(state).get('data');

export default accountOverviewReducer;
