import {REPORT_GRID_ITEMS_FETCH} from '../constants/actionTypes/reportGridActionTypes';
import {createAsyncReducer, initialAsyncState} from '../utils/asyncHelpers';
import {handleActions} from 'redux-actions';
import {Map} from 'immutable';

const initialState = Map({
  gridItem: initialAsyncState,
});

const classifierReducer = handleActions(
  {
    ...createAsyncReducer(REPORT_GRID_ITEMS_FETCH, 'gridItem'),
  },
  initialState
);

export const getGridItem = state => state.getIn(['reportGrid', 'gridItem']);

export const getGridItemData = state =>
  state.getIn(['reportGrid', 'gridItem', 'data']);

export default classifierReducer;
