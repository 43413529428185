import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {consumerGoReportFields} from '../../constants/consumerGoReport';
import {Table, Spin} from 'antd';
import ConsumerGoTableDetails from './ConsumerGoTableDetails';

export class ConsumerGoTable extends Component {
  static propTypes = {
    consumerGo: PropTypes.array,
    totalElements: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
    searchConsumerGo: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    pageSize: PropTypes.number.isRequired,
  };

  onPageChange = page => {
    const {searchConsumerGo} = this.props;

    searchConsumerGo(page);
  };

  getColumns = () => {
    const {t} = this.props;

    return [
      {
        title: t('goTable.period'),
        dataIndex: consumerGoReportFields.MONTH,
        render: (value, values) =>
          `${values[consumerGoReportFields.MONTH]}.${
            values[consumerGoReportFields.YEAR]
          }`,
      },
      {
        title: t('goTable.consumedAmount'),
        dataIndex: consumerGoReportFields.CONSUMED_AMOUNT,
      },
      {
        title: t('goTable.consumedAmountBiomethane'),
        dataIndex: consumerGoReportFields.CONSUMED_AMOUNT_BIOMETHANE,
      },
      {
        title: t('goTable.percentage'),
        dataIndex: consumerGoReportFields.PERCENTAGE,
        render: value => `${value}%`,
      },
    ];
  };

  render() {
    const {
      consumerGo,
      totalElements,
      loading,
      page,
      pageSize,
      t,
    } = this.props;

    return (
      <div className="global-margin-top-16">
        <Spin spinning={loading}>
          <Table
            rowKey={values =>
              `${values[consumerGoReportFields.MONTH]}.${
                values[consumerGoReportFields.YEAR]
              }`
            }
            dataSource={consumerGo}
            columns={this.getColumns()}
            expandedRowRender={record => (
              <ConsumerGoTableDetails
                consumerGoDetails={record.details}
                t={t}
              />
            )}
            pagination={{
              onChange: this.onPageChange,
              current: page,
              total: totalElements,
              pageSize,
              defaultCurrent: 1,
            }}
          />
        </Spin>
      </div>
    );
  }
}

export default ConsumerGoTable;
