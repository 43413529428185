import React, {Component} from 'react';
import {DatePicker} from 'antd';
import ComponentWrapper from './ComponentWrapper';
import {dateRangeMap, dateRangeNoTimeMap} from './componentMappers';
import PropTypes from 'prop-types';

const {RangePicker} = DatePicker;

export default class DateField extends Component {
  static propTypes = {
    useTime: PropTypes.bool,
  };

  static defaultProps = {
    useTime: true,
  };

  render() {
    return (
      <ComponentWrapper
        {...this.props}
        mapProps={this.props.useTime ? dateRangeMap : dateRangeNoTimeMap}
        render={props => <RangePicker {...props} />}
      />
    );
  }
}
