import React, { Component } from 'react';
import PropTypes from 'prop-types';
import roles from '../../constants/classificators/mandateRole';
import { Badge, Menu } from 'antd';
import {
  goToAccountGOSAuctions,
  goToAccountGOSPreTradingAgreements,
  goToAccountGOSReport,
  goToAccountGOSSearch,
  goToAccountGOSTransactions,
  goToAccountGOSTsAssignments,
  goToAccountOverview,
  goToAccountReport,
  goToClassifiers,
  goToCookies,
  goToElectricConsumptionReport,
  goToElectricMeteringPointList,
  goToHydrogenUnitSearch,
  goToInvoices,
  goToLegalEntity,
  goToLoadingUnitList,
  goToLoadingUnitSearch,
  goToMeteringPointList,
  goToNotifications,
  goToProductionUnitSearch,
  goToReportGo,
  goToReportGrid,
  goToReportPeriod,
  goToReportPeriodGos,
  goToSearch,
  goToTradingPlatformAgreements,
  goToTradingPlatformAuctions,
  goToTransactionsGo,
  goToTransactionsGos,
  goToUserTransactions,
  goToVDSMessages,
  links,
} from '../../utils/gotoLink';
import { translateAccountTypeClassificator } from '../../utils/translateHelpers';
import { accountFields } from '../../constants/account';
import { accountStatus, accountType } from '../../constants/classificators';
import { isConsumer } from '../../utils/roles';
import './mainMenu.css';
import classifiersStore from '../../utils/classifiersStore';

const { SubMenu } = Menu;

export default class MainMenu extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    role: PropTypes.string.isRequired,
    companyCode: PropTypes.string,
    notificationCount: PropTypes.number,
    currentPath: PropTypes.string.isRequired,
    userAccounts: PropTypes.array,
    assignmentCount: PropTypes.number,
    isAuctionsEnabled: PropTypes.bool.isRequired,
  };

  determineActive = url => {
    if (url.includes(links.notifications)) {
      return ['notifications'];
    } else if (url === links.search) {
      return ['marketParticipants'];
    } else if (url === links.transactionsGo) {
      return ['transactionsGo'];
    } else if (url === links.transactionsGos) {
      return ['transactionsGos'];
    } else if (url.includes('/transaction/search/')) {
      const splitArr = url.split('/transaction/search/');
      return [`transactions::${splitArr[1]}`];
    } else if (url.includes(links.biomethaneProductionUnitSearch)) {
      return ['biomethaneProductionUnitSearch'];
    } else if (url.includes(links.hydrogenProductionUnitSearch)) {
      return ['hydrogenProductionUnitSearch'];
    } else if (url.includes('/account/')) {
      const splitArr = url.split('/account/');
      return [`accountOverview::${splitArr[1]}`];
    } else if (url.includes('/accountReport')) {
      const splitArr = url.split('/accountReport/');
      if (splitArr.length === 1) {
        return ['accountReport'];
      }
      return [`accountReport::${splitArr[1]}`];
    } else if (
      url === links.consumptionPointList ||
      url === links.meteringPointList
    ) {
      return ['consumptionPointList'];
    } else if (url === links.electricMeteringPointList) {
      return ['electricMeteringPointList'];
    } else if (url.includes('/legalEntity/')) {
      return ['companyInfo', 'marketParticipants'];
    } else if (url.includes('/transferring/')) {
      const splitArr = url.split('/transferring/');
      return [`accountOverview::${splitArr[1]}`];
    } else if (url.includes(links.classifiers)) {
      return ['classifiers'];
    } else if (url === links.invoices || url.includes('/invoice/view/')) {
      return ['invoices'];
    } else if (url.includes(links.reportGo)) {
      return ['reportGo'];
    } else if (url.includes(links.reportPeriodGos)) {
      return ['reportPeriodGos'];
    } else if (url.includes(links.reportPeriod)) {
      return ['reportPeriod'];
    } else if (url.includes(links.electricConsumptionReport)) {
      return ['electricConsumptionReport'];
    } else if (url.includes(links.reportGrid)) {
      return ['reportGrid'];
    } else if (url.includes('/accountGOS/search')) {
      return ['accountGOSOverview'];
    } else if (url.includes('/accountGOS/ts-assignments')) {
      return ['accountGOSTsAssignments'];
    } else if (url.includes('/accountGOS/pre-trading')) {
      return ['accountGOSPreTradingAgreements'];
    } else if (url.includes('/accountGOS/auctions')) {
      return ['accountGOSAuctions'];
    } else if (url.includes('/accountGOS/transactions')) {
      return ['accountGOSTransactions'];
    } else if (url.includes('/accountGOS/report')) {
      return ['accountGOSReport'];
    } else if (url.includes(links.loadingUnitSearch)) {
      return ['loadingUnitSearch'];
    } else if (url.includes(links.loadingUnitList)) {
      return ['loadingUnitList'];
    } else if (url.includes('/vdsMessages')) {
      return ['VDSMessages'];
    } else if (url.includes(links.cookies)) {
      return ['cookies'];
    } else if (url.includes(links.tradingPlatformAgreements)) {
      return ['tradingPlatformAgreements'];
    } else if (url.includes(links.tradingPlatformAuctions)) {
      return ['tradingPlatformAuctions'];
    }

    return [];
  };

  determineOpenKeys = url => {
    const { userAccounts } = this.props;

    const defaultKeys = ['admin', 'user'];

    if (url.includes('/account/')) {
      const splitArr = url.split('/account/');
      defaultKeys.push(`account::${splitArr[1]}`);
    }

    if (url.includes('/accountReport')) {
      const splitArr = url.split('/accountReport/');
      if (splitArr.length === 1) {
        defaultKeys.push('adminReports');
      } else {
        defaultKeys.push(`account::${splitArr[1]}`);
      }
    }

    if (url.includes('/transaction/search/')) {
      const splitArr = url.split('/transaction/search/');
      defaultKeys.push(`account::${splitArr[1]}`);
    }

    if (
      url.includes(links.transactionsGo) ||
      url.includes(links.transactionsGos)
    ) {
      defaultKeys.push('adminTransactions');
    }

    if (url.includes('/transferring/')) {
      const splitArr = url.split('/transferring/');
      defaultKeys.push(`account::${splitArr[1]}`);
    }

    if (
      url.includes(links.reportGo) ||
      url.includes(links.reportGrid) ||
      url.includes(links.reportPeriod) ||
      url.includes(links.electricConsumptionReport)
    ) {
      defaultKeys.push('adminReports');
    }

    if (
      url.includes(links.reportPeriodGos) ||
      url.includes(links.reportPeriod) ||
      url.includes(links.electricConsumptionReport)
    ) {
      defaultKeys.push('userReports');
    }

    if (url.includes(links.invoices)) {
      const manufacturerUserAccount = userAccounts.find(
        userAccount =>
          userAccount[accountFields.STATUS] === accountStatus.ACTIVE &&
          (userAccount[accountFields.TYPE] === accountType.MANUFACTURER ||
            userAccount[accountFields.TYPE] ===
              accountType.HYDROGEN_MANUFACTURER)
      );

      if (manufacturerUserAccount) {
        defaultKeys.push(
          `account::${manufacturerUserAccount[accountFields.ID]}`
        );
      }
    }
    return defaultKeys;
  };

  handleClick = e => {
    const { companyCode } = this.props;
    const menuHandlers = {
      companyInfo: () => goToLegalEntity(companyCode),
      notifications: () => goToNotifications(),
      cookies: () => goToCookies(),
      biomethaneProductionUnitSearch: goToProductionUnitSearch,
      hydrogenProductionUnitSearch: goToHydrogenUnitSearch,
      accountReport: goToAccountReport,
      marketParticipants: goToSearch,
      consumptionPointList: goToMeteringPointList,
      electricMeteringPointList: goToElectricMeteringPointList,
      transactionsGo: goToTransactionsGo,
      transactionsGos: goToTransactionsGos,
      classifiers: goToClassifiers,
      invoices: goToInvoices,
      reportGo: goToReportGo,
      VDSMessages: goToVDSMessages,
      reportPeriod: goToReportPeriod,
      reportPeriodGos: goToReportPeriodGos,
      electricConsumptionReport: goToElectricConsumptionReport,
      reportGrid: goToReportGrid,
      accountGOSOverview: () => goToAccountGOSSearch(companyCode),
      accountGOSTsAssignments: () => goToAccountGOSTsAssignments(companyCode),
      accountGOSPreTradingAgreements: () =>
        goToAccountGOSPreTradingAgreements(companyCode),
      accountGOSAuctions: () => goToAccountGOSAuctions(companyCode),
      accountGOSTransactions: () => goToAccountGOSTransactions(companyCode),
      accountGOSReport: () => goToAccountGOSReport(companyCode),
      loadingUnitList: goToLoadingUnitList,
      loadingUnitSearch: goToLoadingUnitSearch,
      tradingPlatformAgreements: goToTradingPlatformAgreements,
      tradingPlatformAuctions: goToTradingPlatformAuctions,
    };

    const splitKey = e.key.split('::');
    if (splitKey.length > 1) {
      if (splitKey[0] === 'accountOverview') {
        goToAccountOverview(splitKey[1]);
      }

      if (splitKey[0] === 'accountReport') {
        goToAccountReport(splitKey[1]);
      }

      if (splitKey[0] === 'transactions') {
        goToUserTransactions(splitKey[1]);
      }
    } else {
      const handler = menuHandlers[e.key];
      if (handler) handler();
    }
  };

  render() {
    const {
      role,
      t,
      notificationCount,
      userAccounts,
      currentPath,
      assignmentCount,
      isAuctionsEnabled,
    } = this.props;

    if (isConsumer(role)) {
      return null;
    }

    return (
      <Menu
        mode="inline"
        defaultOpenKeys={this.determineOpenKeys(currentPath)}
        onClick={this.handleClick}
        selectedKeys={this.determineActive(currentPath)}
      >
        {role === roles.ELERING_ADMINISTRATOR && (
          <SubMenu key="admin" title={<span>{t('menu.admin.title')}</span>}>
            <Menu.Item key="notifications">
              {t('menu.admin.notifications')}
              <Badge
                className="sidebar__notifications"
                count={notificationCount}
              />
            </Menu.Item>
            <Menu.Item key="loadingUnitSearch">
              {t('menu.admin.loadingUnitSearch')}
            </Menu.Item>
            <Menu.Item key="marketParticipants">
              {t('menu.admin.marketParticipants')}
            </Menu.Item>
            {classifiersStore.getBooleanProperty('IS_HYDROGEN_ENABLED') ? (
              <SubMenu
                key="productionUnits"
                title={t('menu.admin.productionUnits')}
              >
                <Menu.Item key="biomethaneProductionUnitSearch">
                  {t('menu.admin.biomethaneProductionUnitSearch')}
                </Menu.Item>
                <Menu.Item key="hydrogenProductionUnitSearch">
                  {t('menu.admin.hydrogenProductionUnitSearch')}
                </Menu.Item>
              </SubMenu>
            ) : (
              <Menu.Item key="biomethaneProductionUnitSearch">
                {t('menu.admin.biomethaneProductionUnitSearch')}
              </Menu.Item>
            )}
            <Menu.Item key="consumptionPointList">
              {t('menu.user.consumptionPointList')}
            </Menu.Item>
            <Menu.Item key="electricMeteringPointList">
              {t('menu.user.electricMeteringPointList')}
            </Menu.Item>
            <SubMenu
              key="tradingPlatform"
              title={t('menu.admin.tradingPlatform')}
            >
              <Menu.Item key="tradingPlatformAgreements">
                {t('menu.admin.tradingPlatformAgreements')}
              </Menu.Item>
              {isAuctionsEnabled && (
                <Menu.Item key="tradingPlatformAuctions">
                  {t('menu.admin.tradingPlatformAuctions')}
                </Menu.Item>
              )}
            </SubMenu>
            <SubMenu
              key="adminTransactions"
              title={t('menu.admin.transactions')}
            >
              <Menu.Item key="transactionsGo">
                {t('menu.admin.transactionsGo')}
              </Menu.Item>
              <Menu.Item key="transactionsGos">
                {t('menu.admin.transactionsGos')}
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="classifiers">
              {t('menu.admin.classifiers')}
            </Menu.Item>
            <Menu.Item key="invoices">{t('menu.admin.invoices')}</Menu.Item>
            <SubMenu key="adminReports" title={t('menu.admin.reports')}>
              <Menu.Item key="reportPeriod">
                {t('menu.admin.accountReport')}
              </Menu.Item>
              <Menu.Item key="reportPeriodGos">
                {t('menu.admin.accountReportGos')}
              </Menu.Item>
              <Menu.Item key="electricConsumptionReport">
                {t('menu.admin.electricConsumptionReport')}
              </Menu.Item>
              <Menu.Item key="reportGrid">
                {t('menu.admin.reportGrid')}
              </Menu.Item>
              <Menu.Item key="reportGo">{t('menu.admin.reportGo')}</Menu.Item>
            </SubMenu>
            <Menu.Item key="VDSMessages">
              {t('menu.admin.VDSMessages')}
            </Menu.Item>
            <Menu.Item key="cookies">{t('menu.admin.cookies')}</Menu.Item>
          </SubMenu>
        )}
        {role !== roles.ELERING_ADMINISTRATOR && (
          <SubMenu key="user" title={t('menu.user.title')}>
            <Menu.Item key="notifications">
              {t('menu.admin.notifications')}
              <Badge
                className="sidebar__notifications"
                count={notificationCount}
              />
            </Menu.Item>
            <Menu.Item key="companyInfo">
              {t('menu.user.companyInformation')}
            </Menu.Item>

            {userAccounts.some(
              userAccount =>
                userAccount[accountFields.STATUS] === accountStatus.ACTIVE &&
                userAccount[accountFields.TYPE] === accountType.LOADING_OPERATOR
            ) && (
              <Menu.Item key="loadingUnitList">
                {t('menu.user.loadingUnitList')}
              </Menu.Item>
            )}

            {userAccounts.some(
              userAccount =>
                userAccount[accountFields.STATUS] === accountStatus.ACTIVE &&
                (userAccount[accountFields.TYPE] === accountType.MANUFACTURER ||
                  userAccount[accountFields.TYPE] === accountType.TRADER ||
                  userAccount[accountFields.TYPE] ===
                    accountType.HYDROGEN_MANUFACTURER)
            ) && (
              <Menu.Item key="consumptionPointList">
                {t('menu.user.consumptionPointList')}
              </Menu.Item>
            )}

            {userAccounts.some(
              userAccount =>
                userAccount[accountFields.STATUS] === accountStatus.ACTIVE &&
                userAccount[accountFields.TYPE] === accountType.LOADING_OPERATOR
            ) && (
              <Menu.Item key="electricMeteringPointList">
                {t('menu.user.electricMeteringPointList')}
              </Menu.Item>
            )}

            {userAccounts.some(
              userAccount =>
                userAccount[accountFields.STATUS] === accountStatus.ACTIVE &&
                (userAccount[accountFields.TYPE] === accountType.PROVIDER ||
                  userAccount[accountFields.TYPE] ===
                    accountType.LOADING_OPERATOR ||
                  userAccount[accountFields.TYPE] === accountType.TRADER)
            ) && (
              <SubMenu key="accountGOS" title={t('menu.user.accountGOS')}>
                <Menu.Item key="accountGOSOverview">
                  {t('menu.user.accountGOSOverviewPage')}
                </Menu.Item>
                <Menu.Item key="accountGOSTsAssignments">
                  {t('menu.user.accountGOSTsAssignmentsPage')}
                  <Badge
                    className="sidebar__notifications"
                    count={assignmentCount}
                  />
                </Menu.Item>
                <Menu.Item key="accountGOSPreTradingAgreements">
                  {t('menu.user.accountGOSPreTradingPage')}
                </Menu.Item>
                {isAuctionsEnabled && (
                  <Menu.Item key="accountGOSAuctions">
                    {t('menu.user.accountGOSAuctionsPage')}
                  </Menu.Item>
                )}
                <Menu.Item key="accountGOSTransactions">
                  {t('menu.user.accountGOSTransactionsPage')}
                </Menu.Item>
                <Menu.Item key="accountGOSReport">
                  {t('menu.user.accountGOSReportPage')}
                </Menu.Item>
              </SubMenu>
            )}

            {userAccounts &&
              userAccounts
                .filter(
                  userAccount =>
                    userAccount[accountFields.STATUS] ===
                      accountStatus.ACTIVE &&
                    userAccount[accountFields.TYPE] !== accountType.CONSUMER &&
                    userAccount[accountFields.TYPE] !== accountType.PROVIDER &&
                    userAccount[accountFields.TYPE] !==
                      accountType.LOADING_OPERATOR
                )
                .map(userAccount => {
                  const roleCaption = translateAccountTypeClassificator(
                    userAccount[accountFields.TYPE]
                  );
                  return (
                    <SubMenu
                      key={`account::${userAccount[accountFields.ID]}`}
                      title={t('menu.user.accountOverview', {
                        roleCaption: roleCaption.toLowerCase(),
                      })}
                    >
                      <Menu.Item
                        key={`accountOverview::${
                          userAccount[accountFields.ID]
                        }`}
                      >
                        {t('menu.user.accountOverviewPage')}
                      </Menu.Item>
                      <Menu.Item
                        key={`accountReport::${userAccount[accountFields.ID]}`}
                      >
                        {t('menu.user.accountOverviewReport')}
                      </Menu.Item>
                      <Menu.Item
                        key={`transactions::${userAccount[accountFields.ID]}`}
                      >
                        {t('menu.user.transactions')}
                      </Menu.Item>
                      {(userAccount[accountFields.TYPE] ===
                        accountType.MANUFACTURER ||
                        userAccount[accountFields.TYPE] ===
                          accountType.HYDROGEN_MANUFACTURER) && (
                        <Menu.Item key="invoices">
                          {t('menu.user.invoices')}
                        </Menu.Item>
                      )}
                    </SubMenu>
                  );
                })}
            {userAccounts.some(
              userAccount =>
                userAccount[accountFields.STATUS] === accountStatus.ACTIVE &&
                (userAccount[accountFields.TYPE] === accountType.TRADER ||
                  userAccount[accountFields.TYPE] === accountType.PROVIDER ||
                  userAccount[accountFields.TYPE] ===
                    accountType.LOADING_OPERATOR)
            ) && (
              <SubMenu key="userReports" title={t('menu.user.reports')}>
                {userAccounts.some(
                  userAccount =>
                    userAccount[accountFields.STATUS] ===
                      accountStatus.ACTIVE &&
                    userAccount[accountFields.TYPE] === accountType.TRADER
                ) && (
                  <Menu.Item key="reportPeriod">
                    {t('menu.user.reportGo')}
                  </Menu.Item>
                )}
                {userAccounts.some(
                  userAccount =>
                    userAccount[accountFields.STATUS] ===
                      accountStatus.ACTIVE &&
                    userAccount[accountFields.TYPE] === accountType.PROVIDER
                ) && (
                  <Menu.Item key="reportPeriodGos">
                    {t('menu.user.reportGos')}
                  </Menu.Item>
                )}
                {userAccounts.some(
                  userAccount =>
                    userAccount[accountFields.STATUS] ===
                      accountStatus.ACTIVE &&
                    userAccount[accountFields.TYPE] ===
                      accountType.LOADING_OPERATOR
                ) && (
                  <Menu.Item key="electricConsumptionReport">
                    {t('menu.user.electricConsumptionReport')}
                  </Menu.Item>
                )}
              </SubMenu>
            )}
            <Menu.Item key="cookies">{t('menu.admin.cookies')}</Menu.Item>
          </SubMenu>
        )}
      </Menu>
    );
  }
}
