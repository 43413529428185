import React from "react";
import {translate} from "react-i18next";
import {NAMESPACES} from "../../i18n";
import PropTypes from "prop-types";
import {Button, Layout} from "antd";
import {TableActions, TsAuctionHistorySearchTable} from "../../components";
import {
  deleteAction,
  fetchAuctionHistoryCreated,
  fetchAuctionHistoryEnded,
  fetchAuctionHistoryWon
} from "../../actions/actionCreators/tsAuctionActionCreators";
import {connect} from "react-redux";
import {getLoadingSelector} from "../../utils/asyncHelpers";
import {
  getAuctionHistoryCreated,
  getAuctionHistoryCreatedEntities,
  getAuctionHistoryEnded,
  getAuctionHistoryEndedEntities,
  getAuctionHistoryWon,
  getAuctionHistoryWonEntities
} from "../../reducers/tsAuctionReducer";
import {
  goToAccountGOSAuctionDetails,
  goToAccountGOSAuctions,
  goToTradingPlatformAuctions,
  links
} from "../../utils/gotoLink";
import {isAdmin} from "../../utils/roles";
import {getRole} from "../../reducers/userReducer";

class TsAuctionHistory extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        registerCode: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired
  };

  componentDidMount = () => {
    const {
      fetchAuctionHistoryCreated,
      fetchAuctionHistoryWon,
      fetchAuctionHistoryEnded,
      isUserAdmin
    } = this.props;

    if (isUserAdmin) {
      fetchAuctionHistoryEnded({page: 0, size: 10});
    } else {
      fetchAuctionHistoryCreated({page: 0, size: 10});
      fetchAuctionHistoryWon({page: 0, size: 10});
    }
  };

  goToAuctionDetails = (id) => {
    goToAccountGOSAuctionDetails(this.props.match.params.registerCode, id);
  }

  getWithTimestamp = (filename) => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('et-EE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    const formattedTime = currentDate.toLocaleTimeString('et-EE', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
    });

    return `${filename}_${formattedDate.replace(/\./g, '')}_${formattedTime.replace(/:/g, '')}`;
  }

  render() {
    const {
      t,
      isUserAdmin,
      createdAuctions,
      createdAuctionsState,
      isCreatedAuctionsLoading,
      fetchAuctionHistoryCreated,

      wonAuctions,
      wonAuctionsState,
      isWonAuctionsLoading,
      fetchAuctionHistoryWon,

      endedAuctions,
      endedAuctionsState,
      isEndedAuctionsLoading,
      fetchAuctionHistoryEnded,
      ...rest
    } = this.props;

    return (
      <Layout className="main-container">
        <h1 className="mb-3">{t('history.title')}</h1>
        {isUserAdmin &&
          <TsAuctionHistorySearchTable t={t}
                                       title={t('history.ended')}
                                       csv={{
                                         button: t('history.button.csv.ended'),
                                         filename: this.getWithTimestamp(t('history.csv.filename.ended')),
                                         url: links.tsAuctionHistoryEndedCsv
                                       }}
                                       auctions={endedAuctions.toJS()}
                                       isAuctionsLoading={isEndedAuctionsLoading}
                                       goToAuctionDetails={this.goToAuctionDetails}
                                       search={fetchAuctionHistoryEnded}
                                       {...rest}
                                       {...endedAuctionsState.toJS()}>
          </TsAuctionHistorySearchTable>}

        {!isUserAdmin && <>
          <TsAuctionHistorySearchTable t={t}
                                       title={t('history.created')}
                                       csv={{
                                         button: t('history.button.csv.created'),
                                         filename: t('history.csv.filename.created'),
                                         url: links.tsAuctionHistoryCreatedCsv
                                       }}
                                       auctions={createdAuctions.toJS()}
                                       isAuctionsLoading={isCreatedAuctionsLoading}
                                       goToAuctionDetails={this.goToAuctionDetails}
                                       search={fetchAuctionHistoryCreated}
                                       {...rest}
                                       {...createdAuctionsState.toJS()}>
          </TsAuctionHistorySearchTable>
          <TsAuctionHistorySearchTable t={t}
                                       title={t('history.won')}
                                       csv={{
                                         button: t('history.button.csv.won'),
                                         filename: t('history.csv.filename.won'),
                                         url: links.tsAuctionHistoryWonCsv
                                       }}
                                       auctions={wonAuctions.toJS()}
                                       isAuctionsLoading={isWonAuctionsLoading}
                                       goToAuctionDetails={this.goToAuctionDetails}
                                       search={fetchAuctionHistoryWon}
                                       {...rest}
                                       {...wonAuctionsState.toJS()}>
          </TsAuctionHistorySearchTable>
        </>}

        <TableActions>
          <Button
            onClick={() => {
              isUserAdmin ?
                goToTradingPlatformAuctions() :
                goToAccountGOSAuctions(this.props.match.params.registerCode)
            }}
            className="global-margin-left-10">
            {t('history.button.back')}
          </Button>
        </TableActions>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  createdAuctionsState: getAuctionHistoryCreated(state),
  createdAuctions: getAuctionHistoryCreatedEntities(state),
  isCreatedAuctionsLoading: getLoadingSelector(getAuctionHistoryCreated)(state),

  wonAuctionsState: getAuctionHistoryWon(state),
  wonAuctions: getAuctionHistoryWonEntities(state),
  isWonAuctionsLoading: getLoadingSelector(getAuctionHistoryWon)(state),

  endedAuctionsState: getAuctionHistoryEnded(state),
  endedAuctions: getAuctionHistoryEndedEntities(state),
  isEndedAuctionsLoading: getLoadingSelector(getAuctionHistoryEnded)(state),

  isUserAdmin: isAdmin(getRole(state))
});

const mapDispatchToProps = (dispatch) => ({
  fetchAuctionHistoryCreated: pager => {
    dispatch(fetchAuctionHistoryCreated(pager))
  },
  fetchAuctionHistoryWon: pager => {
    dispatch(fetchAuctionHistoryWon(pager))
  },
  fetchAuctionHistoryEnded: pager => {
    dispatch(fetchAuctionHistoryEnded(pager))
  },
  deleteAuction: id => dispatch(deleteAction(id))
})

export default translate(NAMESPACES.auctions)(connect(mapStateToProps, mapDispatchToProps)(TsAuctionHistory));
