export const taTransactionsFilterFields = {
  REGISTER_CODE: 'registerCode',
  DATE_FROM: "transactionDateFrom",
  DATE_TO: "transactionDateTo",
  TRANSACTION_TYPE: "transactionType",
  TRANSACTION_STATUS: "transactionStatus",
  TRANSACTION_ID: "transactionId",
  RECEIVER_ID: 'receiverId',
  SENDER_ID: 'senderId',
  CURRENT_ACCOUNT_ID: 'currentAccountId',
  TYPE: "type"
};

export const FORM_ACCOUNT_GOS_TRANSACTIONS_FILTER = 'FORM_ACCOUNT_GOS_TRANSACTIONS_FILTER';
