import React from 'react';
import {LegalEntityEdit as LegalEntityEditContainer} from '../../containers';
import {Layout} from 'antd';
import queryString from 'query-string';

class LegalEntityEdit extends React.Component {
  render() {
    const query = queryString.parse(this.props.location.search);

    if (query.productionUnitId) {
      query.productionUnitId = +query.productionUnitId;
    }

    return (
      <Layout className="main-container">
        <Layout.Content>
          <LegalEntityEditContainer
            registerCode={this.props.match.params.registerCode}
            isEditMode
            queryParams={query}
          />
        </Layout.Content>
      </Layout>
    );
  }
}

export default LegalEntityEdit;
