import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Alert} from 'antd';

export default class CancelingAvailabilityWarning extends Component {
  static propTypes = {
    from: PropTypes.number.isRequired,
    to: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const {from, to, t} = this.props;
    const count = to - from + 1;

    return (
      <div className="global-margin-bottom-10">
        <Alert
          message={t('certificatesCancelingWarning', {
            from,
            to,
            count,
          })}
          type="warning"
        />
      </div>
    );
  }
}
