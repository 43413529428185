import React, {Component} from 'react';
import {reduxForm, Field} from 'redux-form/immutable';
import {TextField} from '../../atoms';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {Row, Col, Button, Spin, notification} from 'antd';
import {Map} from 'immutable';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormActions} from '../../components';
import {FORM_CLASSIFIERS_GO_AUTO_EDIT} from '../../constants/formKeys';
import {
  getClassifiersGoAutoChange,
  getClassifiersGoAutoItem,
  getClassifiersGoAutoItemData,
} from '../../reducers/classifiersReducer';
import * as actions from '../../actions/actionCreators/classifiersActionCreators';
import {goAutomaticCancel} from '../../constants/parameters';
import {goToClassifiers} from '../../utils/gotoLink';

export class ParameterGoAutoEdit extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    fetchClassifierGoAuto: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  componentDidMount = () => {
    this.props.fetchClassifierGoAuto();
  };

  render() {
    const {t, handleSubmit, isLoading} = this.props;

    return (
      <Spin spinning={isLoading}>
        <h1>{t('parameterGoAutoTitle')}</h1>
        <form onSubmit={handleSubmit}>
          <Row gutter={32}>
            <Col span={24}>
              <Field
                className="date-field"
                name={goAutomaticCancel.VALUE}
                label={t('fieldGoAuto')}
                component={TextField}
              />
            </Col>
          </Row>
          <FormActions>
            <Button onClick={goToClassifiers}>{t('buttonBack')}</Button>
            <Button htmlType="submit" type="primary">
              {t('buttonSave')}
            </Button>
          </FormActions>
        </form>
      </Spin>
    );
  }
}

const onSubmit = async (values, dispatch, ownProps) => {
  await dispatch(actions.changeClassifierGoAuto(values.toJS()));

  notification.info({
    message: ownProps.t('completeTitle'),
    description: ownProps.t('completeBody'),
  });
};

const getIsLoading = getLoadingSelector(
  getClassifiersGoAutoChange,
  getClassifiersGoAutoItem
);

const mapStateToProps = state => ({
  initialValues: Map({
    [goAutomaticCancel.VALUE]: getClassifiersGoAutoItemData(state),
  }),
  isLoading: getIsLoading(state),
});

const mapDispatchToProps = {
  fetchClassifierGoAuto: actions.fetchClassifierGoAuto,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_CLASSIFIERS_GO_AUTO_EDIT,
    onSubmit,
    enableReinitialize: true,
  })(ParameterGoAutoEdit)
);
