import {reset, initialize} from 'redux-form';
import {
  FORM_MEASUREMENT,
  formRegisterMeasurementFields,
} from '../../constants/formRegister';
import {measurement} from '../../schemas';
import {v4} from 'uuid';
import * as actionTypes from '../../constants/productionUnitActionTypes';
import {identity} from 'lodash';
import {createAsyncAction, normalizeHelper} from '../../utils/asyncHelpers';
import {
  productionUnitMeasurementFetch,
  meteringPointUpdate,
  meteringPointDelete,
} from '../actionHelpers/endpoints';
import {meteringPointFields} from '../../constants/meteringPoint';
import {meteringPointDataSource} from '../../constants/classificators';
import {processAsyncResult} from '../../utils/formHelpers';
import {
  toggleMeasurementForm,
  selectMeasurement as selectMeasurementPure,
} from '../pureActions/productionUnitActions';

export const fetchProductionUnitMeasurement = createAsyncAction(
  actionTypes.PRODUCTION_UNIT_MEASUREMENT_FETCH,
  productionUnitMeasurementFetch,
  normalizeHelper(measurement)
);

export const addProductionUnitMeasurement = createAsyncAction(
  actionTypes.PRODUCTION_UNIT_MEASUREMENT_ADD,
  mp => mp,
  normalizeHelper(measurement)
);

export const startAddProductionUnitMeasurement = () => dispatch => {
  dispatch(reset(FORM_MEASUREMENT));
  dispatch(toggleMeasurementForm(true));
};
export const startEditProductionUnitMeasurement = values => dispatch => {
  dispatch(initialize(FORM_MEASUREMENT, values));
  dispatch(toggleMeasurementForm(true));
};

export const updateProductionUnitMeasurement = createAsyncAction(
  actionTypes.PRODUCTION_UNIT_MEASUREMENT_UPDATE,
  meteringPointUpdate,
  normalizeHelper(measurement)
);

export const editProductionUnitMeasurement = createAsyncAction(
  actionTypes.PRODUCTION_UNIT_MEASUREMENT_EDIT,
  identity,
  normalizeHelper(measurement)
);

export const deleteProductionUnitMeasurement = createAsyncAction(
  actionTypes.PRODUCTION_UNIT_MEASUREMENT_DELETE,
  meteringPointDelete
);

export const onMetringFormSubmit = async (data, dispatch, ownProps) => {
  let result;
  if (data.get(formRegisterMeasurementFields.ID)) {
    if (
      !data.get(formRegisterMeasurementFields.PRODUCTION_UNIT_ID) &&
      !data.get(meteringPointFields.CONSUMER)
    ) {
      result = dispatch(editProductionUnitMeasurement(data.toJS()));
    } else {
      result = dispatch(updateProductionUnitMeasurement(data.toJS()));
    }
  } else {
    result = dispatch(
      addProductionUnitMeasurement(
        data
          .merge({
            [formRegisterMeasurementFields.ID]: 'NEW-' + v4(),
            [meteringPointFields.DATA_SOURCE]: meteringPointDataSource.VIRTUAL,
          })
          .toJS()
      )
    );
  }
  processAsyncResult(result);
  ownProps.closeForm();
};

export const showMeasurementForm = () => dispatch =>
  dispatch(toggleMeasurementForm(true));
export const hideMeasurementForm = () => dispatch =>
  dispatch(toggleMeasurementForm(false));

export const selectMeasurement = measurementId => dispatch =>
  dispatch(selectMeasurementPure(measurementId));
