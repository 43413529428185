import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'antd';
import {invoiceViewPresenterRegisterInfoFields} from '../../constants/invoice';
import {InvoiceViewItemColComponent} from '../';

class InvoiceViewItemPresenterRegisterInfo extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const {t, data} = this.props;

    return (
      <div className="global-margin-top-16">
        <Row className="global-margin-bottom-10">
          <Col span={8}>
            <InvoiceViewItemColComponent
              itemTitle={t('presenterRegisterInfo.emtakCode')}
              itemValue={data.get(
                invoiceViewPresenterRegisterInfoFields.EMTAK_CODE
              )}
            />
          </Col>
          <Col span={8}>
            <InvoiceViewItemColComponent
              itemTitle={t('presenterRegisterInfo.ehakCode')}
              itemValue={data.get(
                invoiceViewPresenterRegisterInfoFields.EHAK_CODE
              )}
            />
          </Col>
          <Col span={8}>
            <InvoiceViewItemColComponent
              itemTitle={t('presenterRegisterInfo.employees')}
              itemValue={data.get(
                invoiceViewPresenterRegisterInfoFields.EMPLOYEES
              )}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default InvoiceViewItemPresenterRegisterInfo;
