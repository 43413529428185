import React, {Component} from 'react';
import {
  Field,
  formValueSelector,
  reduxForm,
  reset,
} from 'redux-form/immutable';
import {get} from 'lodash';
import {v4} from 'uuid';
import {connect} from 'react-redux';
import {TextField, ClassificatorField} from '../../atoms';
import consumerTypeClassificator from '../../constants/classificators/consumerType';
import {Row, Col, Button, Spin} from 'antd';
import {FormActions} from '../../components';
import {FORM_ADD_CONSUMER} from '../../constants/formKeys';
import {consumerFields} from '../../constants/consumer';
import {addConsumer} from '../../actions/actionCreators/consumerAddActionCreators';
import {getAddConsumer} from '../../reducers/consumerReducer';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import PropTypes from 'prop-types';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {parseNumber} from '../../utils/formHelpers';
import validate from './validateSchema';

const AddConsumerCompany = ({ t, isAdmin }) => (
  <React.Fragment>
    <Row gutter={32}>
      <Col span={12}>
        <Field
          name={consumerFields.COMPANY_NAME}
          component={TextField}
          type="text"
          label={t('consumer.companyNameLabel')}
          required
          disabled={!isAdmin}
        />
      </Col>
      <Col span={12}>
        <Field
          name={consumerFields.EICX_CODE}
          component={TextField}
          type="text"
          label={t('consumer.eicxCode')}
          required
          disabled={!isAdmin}
        />
      </Col>
    </Row>
    <Row gutter={32}>
      <Col span={12}>
        <Field
          name={consumerFields.REGISTER_CODE}
          component={TextField}
          type="text"
          label={t('consumer.registerCodeLabel')}
          required
          parse={parseNumber}
          disabled={!isAdmin}
        />
      </Col>
    </Row>
  </React.Fragment>
);

const AddConsumerPerson = ({ t, isAdmin }) => (
  <React.Fragment>
    <Row gutter={32}>
      <Col span={12}>
        <Field
          name={consumerFields.NAME}
          component={TextField}
          type="text"
          label={t('consumer.nameLabel')}
          required
          disabled={!isAdmin}
        />
      </Col>
      <Col span={12}>
        <Field
          name={consumerFields.EICX_CODE}
          component={TextField}
          type="text"
          label={t('consumer.eicxCode')}
          required
          disabled={!isAdmin}
        />
      </Col>
    </Row>
    <Row gutter={32}>
      <Col span={12}>
        <Field
          name={consumerFields.PERSONAL_CODE}
          component={TextField}
          type="text"
          label={t('consumer.personalCodeLabel')}
          required
          parse={parseNumber}
          disabled={!isAdmin}
        />
      </Col>
    </Row>
  </React.Fragment>
);

AddConsumerCompany.propTypes = {
  t: PropTypes.func.isRequired,
};

AddConsumerPerson.propTypes = {
  t: PropTypes.func.isRequired,
};

export class AddConsumer extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    consumerType: PropTypes.string,
    legalEntityId: PropTypes.number,
    consumerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  render() {
    const {
      handleSubmit,
      t,
      isLoading,
      onClose,
      consumerType,
      consumerId,
      isAdmin
    } = this.props;
    const isCompany =
      !consumerType || consumerType === consumerTypeClassificator.MANUFACTURER;
    const isEdit = !!consumerId;

    return (
      <Spin spinning={isLoading}>
        <form className="form-add-consumer" onSubmit={handleSubmit}>
          <Row gutter={32}>
            <Col span={12}>
              <Field
                label={t('consumer.consumerType')}
                name={consumerFields.CONSUMER_TYPE}
                component={ClassificatorField}
                type={ClassificatorField.types.consumerType}
                hasFeedback={false}
                required
                disabled={!isAdmin}
              />
            </Col>
          </Row>
          {isCompany && <AddConsumerCompany t={t} isAdmin={isAdmin} />}
          {!isCompany && <AddConsumerPerson t={t} isAdmin={isAdmin} />}
          <FormActions>
            <Button
              className="component-register-actions__button-previous"
              size="large"
              onClick={onClose}
            >
              {t('consumer.buttonCancel')}
            </Button>
            <Button
              className="component-register-actions__button-next"
              size="large"
              type="primary"
              htmlType="submit"
              disabled={!isAdmin}
            >
              {isEdit ? t('consumer.buttonSave') : t('consumer.buttonAdd')}
            </Button>
          </FormActions>
        </form>
      </Spin>
    );
  }
}

const selector = formValueSelector(FORM_ADD_CONSUMER);
const getLoading = getLoadingSelector(getAddConsumer);

const mapStateToProps = state => ({
  isLoading: getLoading(state),
  consumerType: selector(state, consumerFields.CONSUMER_TYPE),
  consumerId: selector(state, consumerFields.ID),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClose: () => {
    dispatch(reset(FORM_ADD_CONSUMER));
    ownProps.closeModal();
  },
});

function nullifyRegistryCodeOrPersonalCode(formData) {
  if (formData.consumerType === consumerTypeClassificator.MANUFACTURER) {
    formData.personalCode = null;
  } else {
    formData.registrationCode = null;
  }
}



const onSubmit = async (data, dispatch, ownProps) => {
  const legalEntityId =
    ownProps.legalEntityId || data.get(consumerFields.legalEntityId);
  const formData = data.toJS();
  nullifyRegistryCodeOrPersonalCode(formData);
  const consumer = await dispatch(
    addConsumer({ [consumerFields.ID]: v4(), ...formData, legalEntityId }),
  );
  dispatch(reset(FORM_ADD_CONSUMER));
  ownProps.closeModal();

  return get(consumer, consumerFields.ID);
};

export default translate(NAMESPACES.consumer)(
  connect(mapStateToProps, mapDispatchToProps)(
    reduxForm({
      form: FORM_ADD_CONSUMER,
      validate,
      onSubmit,
    })(AddConsumer),
  ),
);
