import {
  createValidation,
  validatorRequired,
  validatorEmail,
} from '../../utils/formValidators';
import {gridOperator} from '../../constants/parameters';

export default createValidation([
  {
    name: gridOperator.NAME,
    validators: validatorRequired(),
  },
  {
    name: gridOperator.EMAIL,
    validators: [validatorRequired(), validatorEmail()],
  },
  {
    name: gridOperator.CODE,
    validators: validatorRequired(),
  },
]);
