import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'antd';
import {invoiceViewPresenterContactFields} from '../../constants/invoice';
import {InvoiceViewItemColComponent} from '../';

class InvoiceViewItemPresenterContact extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const {t, data} = this.props;

    return (
      <div className="global-margin-top-16">
        <Row className="global-margin-bottom-10">
          <Col span={8}>
            <InvoiceViewItemColComponent
              itemTitle={t('presenterContact.address')}
              itemValue={data.get(invoiceViewPresenterContactFields.ADDRESS)}
            />
          </Col>
          <Col span={8}>
            <InvoiceViewItemColComponent
              itemTitle={t('presenterContact.county')}
              itemValue={data.get(invoiceViewPresenterContactFields.COUNTY)}
            />
          </Col>
          <Col span={8}>
            <InvoiceViewItemColComponent
              itemTitle={t('presenterContact.zipCode')}
              itemValue={data.get(invoiceViewPresenterContactFields.ZIP_CODE)}
            />
          </Col>
        </Row>
        <Row className="global-margin-bottom-10">
          <Col span={8}>
            <InvoiceViewItemColComponent
              itemTitle={t('presenterContact.country')}
              itemValue={data.get(invoiceViewPresenterContactFields.COUNTRY)}
            />
          </Col>
          <Col span={8}>
            <InvoiceViewItemColComponent
              itemTitle={t('presenterContact.phone')}
              itemValue={data.get(invoiceViewPresenterContactFields.PHONE)}
            />
          </Col>
          <Col span={8}>
            <InvoiceViewItemColComponent
              itemTitle={t('presenterContact.email')}
              itemValue={data.get(invoiceViewPresenterContactFields.EMAIL)}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default InvoiceViewItemPresenterContact;
