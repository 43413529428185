import React from 'react';
import {Landing as LandingContainer} from '../../containers';
import './Landing.css';

class Landing extends React.Component {
  render() {
    return <LandingContainer params={this.props.match.params}/>;
  }
}

export default Landing;
