import {createAsyncReducer, listInitialAsyncState} from '../utils/asyncHelpers';
import {handleActions} from 'redux-actions';
import {ACCOUNT_GOS_AVAILABLE_STATISTICS_FETCH} from '../constants/accountGOSListActionTypes';
import {availableStatisticsTableFields} from "../constants/availableStatisticsTableFields";
import {
  resetAccountGOSAvailableStatistics
} from "../actions/actionCreators/accountGOSAvailableStatisticsActionCreators";

const accountGosAvailableStatistics = handleActions(
  {
    ...createAsyncReducer(ACCOUNT_GOS_AVAILABLE_STATISTICS_FETCH),
    [resetAccountGOSAvailableStatistics]: state => state.merge(listInitialAsyncState),
  },
  listInitialAsyncState
);

export const getAccountGOSAvailableStatisticsList = state => {
  return state.get('accountGOSAvailableStatistics');
};

export const getAccountGOSAvailableStatisticsListData = state => {
  const data = getAccountGOSAvailableStatisticsList(state).get('data').toJS();
  data.forEach(item => {
    item[availableStatisticsTableFields.CALCULATED_ENERGY_TRANSFER_AMOUNT] = item[availableStatisticsTableFields.CALCULATED_ENERGY_AMOUNT];
    item[availableStatisticsTableFields.NATURAL_ENERGY_TRANSFER_AMOUNT] = item[availableStatisticsTableFields.NATURAL_ENERGY_AMOUNT];
  });
  return data;
}

export default accountGosAvailableStatistics;
