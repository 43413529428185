import React from 'react';
import {LoadingUnitList} from '../../containers';
import {LoadingUnitFilter} from '../../forms';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import PropTypes from 'prop-types';

class LoadingUnitSearch extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired
  };

  render() {
    const {t} = this.props;

    return (
      <div className="main-container">
        <h2 className="main-header">{t('filter.title')}</h2>
        <LoadingUnitFilter
          t={t}
          isAdmin={true}
        />
        <LoadingUnitList
          t={t}
          isAdmin={true}
        />
      </div>
    );
  }
}

export default translate(NAMESPACES.loadingUnitSearch)(LoadingUnitSearch);
