import React from 'react';
import {Table} from 'antd';
import {isEmpty} from 'lodash';
import {electricIssuesTSCertificatesAmountsDataFields} from '../../constants/meteringPoint';
import {translateMonthClassificator} from '../../utils/translateHelpers';

const totalName = 'total';

const sumObjects = (base, adding) => {
  const result = {...base};
  Object.keys(result)
    .filter(key => key !== electricIssuesTSCertificatesAmountsDataFields.MONTH)
    .forEach(key => result[key] += adding[key] ? adding[key] : 0);
  return result;
};

const totalToFixed = total => {
  const result = {...total};
  Object.keys(result)
    .filter(key => key !== electricIssuesTSCertificatesAmountsDataFields.MONTH)
    .forEach(key => result[key] = result[key].toFixed(2));
  return result;
};

const calculateTotal = (data) => {
  if (isEmpty(data)) return [];

  let totalRow = {
    [electricIssuesTSCertificatesAmountsDataFields.MONTH]: totalName,
    [electricIssuesTSCertificatesAmountsDataFields.DIRECT_LINE_NATURAL_CONSUMPTION]: 0,
    [electricIssuesTSCertificatesAmountsDataFields.NON_DIRECT_LINE_NATURAL_CONSUMPTION]: 0,
    [electricIssuesTSCertificatesAmountsDataFields.DIRECT_LINE_ACCOUNTING_CONSUMPTION]: 0,
    [electricIssuesTSCertificatesAmountsDataFields.NON_DIRECT_LINE_ACCOUNTING_CONSUMPTION]: 0,
    [electricIssuesTSCertificatesAmountsDataFields.NATURAL_QUANTITY_MJ]: 0,
    [electricIssuesTSCertificatesAmountsDataFields.ACCOUNTING_QUANTITY_MJ]: 0
  };

  totalRow = data.reduce(sumObjects, totalRow);

  return [...data, totalToFixed(totalRow)];
};

export function ElectricIssuesTSCertificatesAmountsTable({t, data}) {
  const totalTitle = t(`issued.${totalName}`);
  const columns = [
    {
      title: t('issued.naturalQuantityMJ'),
      children: [
        {
          dataIndex: electricIssuesTSCertificatesAmountsDataFields.MONTH,
          title: t('issued.month'),
          render: value => value !== totalName ? translateMonthClassificator(value) : totalTitle
        },
        {
          title: t('issued.directLineConsumption'),
          dataIndex: electricIssuesTSCertificatesAmountsDataFields.DIRECT_LINE_NATURAL_CONSUMPTION
        },
        {
          dataIndex: electricIssuesTSCertificatesAmountsDataFields.NON_DIRECT_LINE_NATURAL_CONSUMPTION,
          title: t('issued.nonDirectLineConsumption')
        },
        {
          dataIndex: electricIssuesTSCertificatesAmountsDataFields.NATURAL_QUANTITY_MJ,
          title: totalTitle
        }
      ]
    },
    {
      title: t('issued.accountingQuantityMJ'),
      children: [
        {
          title: t('issued.directLineConsumption'),
          dataIndex: electricIssuesTSCertificatesAmountsDataFields.DIRECT_LINE_ACCOUNTING_CONSUMPTION
        },
        {
          title: t('issued.nonDirectLineConsumption'),
          dataIndex: electricIssuesTSCertificatesAmountsDataFields.NON_DIRECT_LINE_ACCOUNTING_CONSUMPTION
        },
        {
          dataIndex: electricIssuesTSCertificatesAmountsDataFields.ACCOUNTING_QUANTITY_MJ,
          title: totalTitle
        }
      ]
    }
  ];

  return (
    <Table
      columns={columns}
      rowKey={electricIssuesTSCertificatesAmountsDataFields.MONTH}
      dataSource={calculateTotal(data)}
      pagination={false}
    />
  );
}
