import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {connect} from 'react-redux';
import * as actions from '../../actions/actionCreators/transactionGOSViewActionCreators';
import {getRole} from '../../reducers/userReducer';
import {isAdmin} from '../../utils/roles';
import {
  TATransactionInfo,
  TATransactionAmount,
  TableActions,
  TATransactionAccountInfo,
  TransactionGOSHistory,
  TransactionGOSSetInformationList,
} from '../../components';
import {Spin, Button, Popconfirm, notification, Input} from 'antd';
import {isEmpty} from 'lodash';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {
  getTransaction,
  getTransactionData,
} from '../../reducers/transactionGOSViewReducer';
import {goBack, goToTransactionsGos} from '../../utils/gotoLink';
import {transactionFields, transactionGOSAccountFields} from '../../constants/transactionGOS';
import {hasGosRecallButton} from '../../utils/transactionForGosHelpers';
import * as viewActions from '../../actions/actionCreators/transactionForGosViewActionCreators';
import {history} from '../../store';
import {TYPE_B} from '../../constants/accountGOS';

export class TransactionGOSView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    transactionId: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    fetchTransactionGOS: PropTypes.func.isRequired,
    transactionData: PropTypesImmutable.map,
    recallTransaction: PropTypes.func.isRequired,
    locationState: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      recallReason: null,
    };
  }

  componentDidMount = () => {
    const {transactionId, fetchTransactionGOS} = this.props;
    fetchTransactionGOS(transactionId, TYPE_B);
  };

  onRecall = (id, recallReason) => {
    const {recallTransaction} = this.props;

    recallTransaction(id, recallReason);
    goToTransactionsGos();
  };

  onChangeRecallReason = e => {
    this.setState({recallReason: e.target.value});
  };

  goBackTolist(locationState) {
    if (locationState && locationState.prevPath) {
      history.push({
        pathname: locationState.prevPath,
        state: {activeTab: 2},
      });
    } else {
      goBack();
    }
  }

  render() {
    const {
      t,
      isLoading,
      transactionData,
      role,
      transactionId,
      locationState,
    } = this.props;
    const {recallReason} = this.state;
    const transactionDataJs = transactionData.toJS();
    const gosTransactionType = transactionDataJs[transactionFields.TYPE];
    const isTransferTransaction =
      gosTransactionType === 'INCOMING' || gosTransactionType === 'OUTGOING';
    const otherSideAccount =
      gosTransactionType === 'INCOMING'
        ? transactionFields.SENDER_ACCOUNT
        : transactionFields.RECEIVER_ACCOUNT;

    if (isEmpty(transactionDataJs) || isLoading) {
      return <Spin spinning/>;
    }

    return (
      <React.Fragment>
        <h2>{t('subtitle.transactionInfo')}</h2>
        <div className="global-margin-top-24">
          <TATransactionInfo
            t={t}
            id={transactionDataJs[transactionFields.ID]}
            type={TYPE_B}
            txType={transactionDataJs[transactionFields.TYPE]}
            tradeType={transactionDataJs[transactionFields.TRADE_TYPE]}
            status={transactionDataJs[transactionFields.STATUS]}
            txCreateTime={transactionDataJs[transactionFields.TRANSACTION_CREATE_TIME]}
            senderAccountNumber={transactionDataJs[transactionFields.SENDER_INFORMATION].accountNumber}
            comment={transactionDataJs[transactionFields.COMMENT]}
            isAdmin={isAdmin(role)}
          />
        </div>

        <div>
          <h2 className="global-margin-top-42">
            {t('subtitle.transactionGOS')}
          </h2>
          <div className="global-margin-top-24">
            <TATransactionAmount
              t={t}
              naturalAmount={transactionDataJs[transactionFields.TRANSACTION_GOS_NATURAL_AMOUNT_MJ]}
              accountingAmount={transactionDataJs[transactionFields.TRANSACTION_GOS_AMOUNT_MJ]}/>
            <div className="global-margin-bottom-10">
              <TransactionGOSSetInformationList
                t={t}
                info={transactionDataJs}
                transactionType={gosTransactionType}/>
            </div>
          </div>
        </div>

        {isTransferTransaction && (
          <div>
            <h2 className="global-margin-top-42">
              {gosTransactionType === 'INCOMING' &&
                t('gosAccountInfo.senderInfo')}
              {gosTransactionType === 'OUTGOING' &&
                t('gosAccountInfo.recipientInfo')}
            </h2>
            <div className="global-margin-top-24">
              <TATransactionAccountInfo
                t={t}
                accountNumber={transactionDataJs[otherSideAccount][transactionGOSAccountFields.ACCOUNT_NUMBER]}
                companyName={transactionDataJs[otherSideAccount][transactionGOSAccountFields.COMPANY_NAME]}
                registrationCode={transactionDataJs[otherSideAccount][transactionGOSAccountFields.REGISTRATION_CODE]}
              />
            </div>
          </div>
        )}

        {isAdmin(role) && (
          <div>
            <h2 className="global-margin-top-42">{t('subtitle.history')}</h2>
            <div className="global-margin-top-24">
              <TransactionGOSHistory t={t} info={transactionDataJs}/>
            </div>
          </div>
        )}

        <TableActions>
          <Button
            onClick={() => {
              this.goBackTolist(locationState);
            }}
            className="global-margin-left-10"
          >
            {t('buttonBack')}
          </Button>
        </TableActions>

        {hasGosRecallButton(
          role,
          transactionDataJs[transactionFields.STATUS],
          transactionDataJs[transactionFields.TYPE],
          transactionDataJs[transactionFields.STILL_OWNED_BY_TO_ACCOUNT]
        ) && (
          <React.Fragment>
            <h2>{t('subtitle.recall')}</h2>
            <Input
              className="global-margin-top-10"
              placeholder={t('recallReason')}
              required
              value={this.state.recallReason || ''}
              onChange={this.onChangeRecallReason}
            />
            <div className="global-margin-top-24">
              <Popconfirm
                title={t('popconfirm.title')}
                onConfirm={() => this.onRecall(transactionId, recallReason)}
                okText={t('popconfirm.buttonYes')}
                cancelText={t('popconfirm.buttonNo')}
              >
                <Button
                  className="global-margin-left-10"
                  disabled={!recallReason}
                >
                  {t('buttonRecall')}
                </Button>
              </Popconfirm>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const getLoading = getLoadingSelector(getTransaction);

const mapStateToProps = state => ({
  isLoading: getLoading(state),
  transactionData: getTransactionData(state),
  role: getRole(state),
  locationState: state.get('routing').location.state,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  recallTransaction: async (id, recallReason) => {
    await dispatch(viewActions.recallGosTransaction(id, recallReason));

    notification.info({
      message: ownProps.t('recallInfoTitle'),
      description: ownProps.t('recallInfoDescription'),
    });
  },
  fetchTransactionGOS: (id, type) => {
    dispatch(actions.fetchTradingAccountTransaction(id, type));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionGOSView);
