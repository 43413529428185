import React, {Component} from 'react';
import {searchCompany} from '../../actions/actionHelpers/endpoints';
import {AutoCompleteSelect} from '../';
import {legalEntityFields} from '../../constants/legalEntity';
import {
  legalEntityStatus,
  accountStatus,
  accountType,
} from '../../constants/classificators';
import {accountFields} from '../../constants/account';

const getDataSource = async value => {
  const {data} = await searchCompany(value);
  return data
    .filter(
      item =>
        item[legalEntityFields.STATUS] === legalEntityStatus.ACTIVE &&
        item[legalEntityFields.ACCOUNTS] &&
        item[legalEntityFields.ACCOUNTS].some(
          account =>
            account[accountFields.TYPE] === accountType.TRADER &&
            account[accountFields.STATUS] === accountStatus.ACTIVE
        )
    )
    .map(item => ({
      value: item[legalEntityFields.ID],
      label: `${item[legalEntityFields.NAME]}, ${
        item[legalEntityFields.REGISTER_CODE]
      }`,
    }));
};

class AutoCompleteSellers extends Component {
  render() {
    return <AutoCompleteSelect {...this.props} getDataSource={getDataSource}/>;
  }
}

export default AutoCompleteSellers;
