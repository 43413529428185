import {schema} from 'normalizr';
import {entitiesSelectorList, entitiesSelectorItem} from '../utils/asyncHelpers';
import legalEntityScheme from './legalEntity';

// const meteringPointScheme = new schema.Entity('measurement');

const schemaEntity = new schema.Entity('loadingUnit', {
  legalEntity: legalEntityScheme,
});

export const getLoadingUnits = entitiesSelectorList(schemaEntity);
export const getLoadingUnit = entitiesSelectorItem(schemaEntity);
export default schemaEntity;
