import {
  createValidation,
  validatorRequired,
  validatorMaxLength,
} from '../../utils/formValidators';

import {generalFormFields} from '../../constants/formsAddEquipment';

const eicwCodeValidator = (value, values, props) =>
  props.isAdmin ? validatorRequired()(value, values, props) : undefined;

export default createValidation([
  {
    name: generalFormFields.NAME,
    validators: [validatorRequired(), validatorMaxLength(40)],
  },
  {
    name: generalFormFields.EICW_CODE,
    validators: eicwCodeValidator,
  },
  {
    name: generalFormFields.ADDRESS,
    validators: [validatorRequired(), validatorMaxLength(40)],
  },
  {
    name: generalFormFields.FIRST_TIME_USE,
    validators: validatorRequired(),
  },
  {
    name: generalFormFields.IS_INVESTMENT_SUPPORT,
    validators: validatorRequired(),
  },
  {
    name: generalFormFields.AMOUNT_OF_INVESTMENT,
    validators: (field, form) =>
      form.get(generalFormFields.IS_INVESTMENT_SUPPORT)
        ? validatorRequired()(field)
        : '',
  },
]);
