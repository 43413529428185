import React, {Component} from 'react';
import {DatePicker} from 'antd';
import {FORMAT_DEFAULT_MONTH} from '../../utils/dates';
import ComponentWrapper from './ComponentWrapper';
import {monthYearMap} from './componentMappers';

const {MonthPicker} = DatePicker;

export default class DateField extends Component {
  render() {
    return (
      <ComponentWrapper
        {...this.props}
        mapProps={monthYearMap}
        render={props => (
          <MonthPicker {...props} format={FORMAT_DEFAULT_MONTH}/>
        )}
      />
    );
  }
}
