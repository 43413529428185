import * as actionTypes from '../../constants/measurementImportActionTypes';
import {createAsyncAction} from '../../utils/asyncHelpers';
import {
  measurementImportFilePointAdd,
  measurementImportFileUnitAdd,
} from '../actionHelpers/endpoints';
import {hideMeasuringUploadModal} from '../actionCreators/modalsActionCreators';
import {notification} from 'antd';
import {tMeasuring} from '../../i18n';

const afterImport = dispatch => {
  notification.info({
    message: tMeasuring('completeTitle'),
    description: tMeasuring('completeBody'),
  });

  dispatch(hideMeasuringUploadModal());
};

export const measurementImportFilePointAddActionCreator = createAsyncAction(
  actionTypes.MEASUREMENT_IMPORT_FILE_ADD,
  measurementImportFilePointAdd,
  (data, dispatch) => afterImport(dispatch)
);

export const measurementImportFileUnitAddActionCreator = createAsyncAction(
  actionTypes.MEASUREMENT_IMPORT_FILE_ADD,
  measurementImportFileUnitAdd,
  (data, dispatch) => afterImport(dispatch)
);
