import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Modal, Spin} from 'antd';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {
  getNotificationList,
  getNotificationListCount,
  getNotificationListData,
} from '../../reducers/notificationReducer';
import {getNotificationClose} from '../../reducers/notificationActionReducer';
import {
  getMeasuringUploadModal,
  getNotificationViewModal,
  hideMeasuringUploadModal,
  hideNotificationViewModal,
  showMeasuringUploadModal,
  showNotificationViewModal,
} from '../../actions/actionCreators/modalsActionCreators';
import * as ActionCreators from '../../actions/actionCreators/notificationActionCreators';
import {NotificationList} from '../../components';
import {NAMESPACES} from '../../i18n';
import {translate} from 'react-i18next';
import {NotificationView} from '../';
import * as userSelectors from '../../reducers/userReducer';
import NotificationsFilter from "../../forms/NotificationsFilter/NotificationsFilter";
import {FORM_NOTIFICATIONS_FILTER} from "../../constants/formNotificationsFilter";
import roles from "../../constants/classificators/mandateRole";
import classifiersStore from "../../utils/classifiersStore";

export class Notifications extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isViewModal: PropTypes.bool.isRequired,
    stateImportModal: PropTypes.bool.isRequired,
    fetchNotification: PropTypes.func.isRequired,
    fetchNotificationCount: PropTypes.func.isRequired,
    closeNotification: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    userLegalEntity: PropTypes.number,
    showModal: PropTypes.func.isRequired,
    showImportModal: PropTypes.func.isRequired,
    hideImportModal: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    notifications: PropTypesImmutable.list,
  };

  componentDidMount = () => {
    const {fetchNotification, fetchNotificationCount} = this.props;

    fetchNotification();
    fetchNotificationCount();
  };

  selectedId = null;

  showModalWithParams = id => {
    this.selectedId = id;
    this.props.showModal();
  };

  hideModalWithParams = () => {
    this.selectedId = null;
    this.props.hideModal();
  };

  render() {
    const {
      notifications,
      isLoading,
      t,
      isViewModal,
      hideModal,
      userRole,
      notificationCount,
      isTradingAccountUser
    } = this.props;

    if (notifications.isEmpty()) {
      return (
        <Spin spinning={isLoading}>
          <h1>{t('titleField')}</h1>
          {(notificationCount > 0 && userRole !== roles.ELERING_ADMINISTRATOR) &&
            <div className="w-40 mb-3">
              <NotificationsFilter
                t={t}
                form={FORM_NOTIFICATIONS_FILTER}
                onSubmit={(values, dispatch) =>
                  dispatch(ActionCreators.fetchNotifications({filter: values}))
                }
                isAuctionsEnabled={classifiersStore.getBooleanProperty('IS_AUCTIONS_ENABLED')}
              />
            </div>}
          <h3>{t('noItems')}</h3>
        </Spin>
      );
    }

    return (
      <Spin spinning={isLoading}>
        <h1>{t('title')}</h1>
        {isTradingAccountUser &&
          <div className="w-40 mb-3">
            <NotificationsFilter
              t={t}
              form={FORM_NOTIFICATIONS_FILTER}
              onSubmit={(values, dispatch) =>
                dispatch(ActionCreators.fetchNotifications({filter: values}))
              }
              isAuctionsEnabled={classifiersStore.getBooleanProperty('IS_AUCTIONS_ENABLED')}
            />
          </div>}
        <NotificationList
          t={t}
          list={notifications.toJS()}
          showModal={this.showModalWithParams}
        />
        {isViewModal && (
          <Modal
            title={t('modalTitle')}
            visible={isViewModal}
            footer={null}
            onCancel={this.hideModalWithParams}
            width={840}
          >
            <NotificationView
              selectedId={this.selectedId}
              t={t}
              hideModal={hideModal}
            />
          </Modal>
        )}
      </Spin>
    );
  }
}

const getLoading = getLoadingSelector(
  getNotificationList,
  getNotificationClose
);

const mapStateToProps = state => ({
  isLoading: getLoading(state),
  notifications: getNotificationListData(state),
  isViewModal: getNotificationViewModal(state),
  userLegalEntity: userSelectors.getUserLegalEntityId(state),
  stateImportModal: getMeasuringUploadModal(state),
  userRole: userSelectors.getRole(state),
  notificationCount: getNotificationListCount(state),
  isTradingAccountUser: userSelectors.getIsTradingAccountUser(state)
});

const mapDispatchToProps = {
  fetchNotification: ActionCreators.fetchNotifications,
  fetchNotificationCount: ActionCreators.fetchNotificationsCount,
  closeNotification: ActionCreators.closeNotification,
  hideModal: hideNotificationViewModal,
  showModal: showNotificationViewModal,
  showImportModal: showMeasuringUploadModal,
  hideImportModal: hideMeasuringUploadModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  translate(NAMESPACES.notification)(Notifications)
);
