import {handleActions} from 'redux-actions';
import {combineReducers} from 'redux-immutable';
import {createSelector} from 'reselect';
import * as ActionTypes from '../constants/UserActionTypes';
import {getPhysicalPerson} from '../schemas/physicalPerson';
import {getMandatePerson} from '../schemas/mandatePerson';
import * as actionTypes from '../constants/SearchLegalEntititesActionTypes';
import * as registerActionTypes from '../constants/registerActionTypes';
import {legalEntityFields} from '../constants/legalEntity';
import {legalEntityStatus, mandateRole} from '../constants/classificators';
import {mandateFields} from '../constants/mandate';
import {
  createAsyncReducer,
  createAsyncSelector,
  initialAsyncState,
  toSuccess,
} from '../utils/asyncHelpers';
import {mandatePerson} from '../schemas';
import {List} from 'immutable';

const userReducer = combineReducers({
  user: handleActions(
    {
      ...createAsyncReducer(ActionTypes.USER_FETCH),
      [ActionTypes.USER_LOGOUT]: state => state.merge(initialAsyncState),
    },
    initialAsyncState
  ),
  selectedMandate: handleActions(
    {
      ...createAsyncReducer(ActionTypes.USER_MANDATE_FETCH),
      [toSuccess(ActionTypes.USER_MANDATE_SELECT)]: (
        state,
        {payload: result}
      ) => state.set('data', result),
      [ActionTypes.USER_LOGOUT]: state => state.merge(initialAsyncState),
    },
    initialAsyncState
  ),
  userMandates: handleActions(
    {
      ...createAsyncReducer(ActionTypes.USER_MANDATES_FETCH),
      [ActionTypes.USER_LOGOUT]: state => state.merge(initialAsyncState),
      [actionTypes.LOGIN_AS_COMPANY_AS_ADMIN]: (state, {payload}) => {
        state.set('data', state.get('data').concat(payload.result));
      },
      [registerActionTypes.MANDATE_PERSON_ADD]: (state, {payload}) => {
        state.set('data', state.get('data').concat(payload.result));
      },
      [toSuccess(ActionTypes.USER_MANDATE_SELECT)]: (state, {payload}) =>
        state.set(
          'data',
          state
            .get('data')
            .push(payload)
            .toSet()
            .toList()
        ),
    },

    initialAsyncState
  ),
  userMandateSelect: handleActions(
    {
      ...createAsyncReducer(ActionTypes.USER_MANDATE_SELECT),
      [ActionTypes.USER_LOGOUT]: state => state.merge(initialAsyncState),
    },
    initialAsyncState
  ),
});

export const getRole = state => {
  const mandate = getMandatePerson(
    state,
    state.getIn(['user', 'selectedMandate'])
  );

  return mandate ? mandate.get('role') : null;
};

export const getPhysicalPersonId = state =>
  getMandatePerson(state, state.getIn(['user', 'selectedMandate'])).get(
    'physicalPersonId'
  );

const getPerson = state =>
  getMandatePerson(state, state.getIn(['user', 'selectedMandate']));

export const getUserLegalEntityId = createSelector(
  getPerson,
  person =>
    person &&
    person.get(mandateFields.ROLE) !== mandateRole.ELERING_ADMINISTRATOR
      ? person.getIn([mandateFields.ORIGINATOR_ENTITY, legalEntityFields.ID])
      : null
);

export const getUserLegalEntityName = state =>
  getMandatePerson(state, state.getIn(['user', 'selectedMandate'])).getIn([
    'originatorLegalEntity',
    legalEntityFields.NAME,
  ]);
export const getUserLegalEntityAccounts = state => {
  const mandate = getMandatePerson(
    state,
    state.getIn(['user', 'selectedMandate'])
  );

  const list = mandate
    ? mandate.getIn(['originatorLegalEntity', legalEntityFields.ACCOUNTS])
    : null;
  return list || List();
};

export const getUserLegalEntity = state => {
  const mandate = getMandatePerson(
    state,
    state.getIn(['user', 'selectedMandate'])
  );

  return mandate ? mandate.get('originatorLegalEntity') : null;
};

export const getIsUserSeller = createSelector(
  getUserLegalEntity,
  legalEntity =>
    legalEntity ? legalEntity.get(legalEntityFields.TRADER) : false
);

export const getIsUserProducer = createSelector(
  getUserLegalEntity,
  legalEntity =>
    legalEntity ? legalEntity.get(legalEntityFields.MANUFACTURER) : false
);

export const getIsUserProvider = createSelector(
  getUserLegalEntity,
  legalEntity =>
    legalEntity ? legalEntity.get(legalEntityFields.PROVIDER) : false
);

export const getIsUserLoadingOperator = createSelector(
  getUserLegalEntity,
  legalEntity =>
    legalEntity ? legalEntity.get(legalEntityFields.LOADING_OPERATOR) : false
);

export const getIsTradingAccountUser = createSelector(
  getUserLegalEntity,
  legalEntity => !!legalEntity && (legalEntity.get(legalEntityFields.TRADER) || legalEntity.get(legalEntityFields.PROVIDER) || legalEntity.get(legalEntityFields.LOADING_OPERATOR))
);


export const isLegalEntityActive = createSelector(
  getUserLegalEntity,
  legalEntity =>
    legalEntity
      ? legalEntity.get(legalEntityFields.STATUS) === legalEntityStatus.ACTIVE
      : false
);

export const getUser = state => state.getIn(['user', 'user']);
export const getUserData = state =>
  getPhysicalPerson(state, state.getIn(['user', 'user']));
export const getUserPersonalCode = state => {
  const person = getPhysicalPerson(state, state.getIn(['user', 'user']));

  return person ? person.get('personalCode') : null;
};

export const getUserMandates = state => state.getIn(['user', 'userMandates']);
export const getUserMandatesData = createAsyncSelector(
  [mandatePerson],
  getUserMandates
);

export const getUserSelectedMandate = state =>
  state.getIn(['user', 'selectedMandate']);

export default userReducer;
