import React from 'react';
import PropTypes from 'prop-types';
import {Table} from 'antd';
import {
  transactionFields,
  transactionConsumptionFields,
} from '../../constants/transaction';
import {
  translateMeteringPointDataSourceClassificator,
  translateConsumptionUsageClassificator,
} from '../../utils/translateHelpers';

class TransactionConsumerInfo extends React.Component {
  static propTypes = {
    info: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  getColumns = () => {
    const {t} = this.props;

    return [
      {
        title: t('consumption.eixz'),
        dataIndex: transactionConsumptionFields.METERING_EIXZ,
      },
      {
        title: t('consumption.address'),
        dataIndex: transactionConsumptionFields.ADDRESS,
      },
      {
        title: t('consumption.consumptionUsage'),
        dataIndex: transactionConsumptionFields.CONSUMPTION_USAGE,
        render: translateConsumptionUsageClassificator,
      },
      {
        title: t('consumption.period'),
        dataIndex: transactionConsumptionFields.PERIOD,
      },
      {
        title: t('consumption.dataSource'),
        dataIndex: transactionConsumptionFields.DATA_SOURCE,
        render: translateMeteringPointDataSourceClassificator,
      },
      {
        title: t('consumption.amount'),
        dataIndex: transactionConsumptionFields.CONSUMED_AMOUNT,
      },
    ];
  };

  render() {
    const {info} = this.props;

    const consumptionInfo = info[transactionFields.CONSUMPTION_INFORMATION];

    return (
      <Table
        rowKey={transactionConsumptionFields.METERING_EIXZ}
        dataSource={consumptionInfo}
        columns={this.getColumns()}
        pagination={false}
      />
    );
  }
}

export default TransactionConsumerInfo;
