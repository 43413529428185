import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Form, Input, InputNumber, Row} from "antd";
import {VDSRequestResultModal} from "../../containers";
import {STATISTICS_TYPE_GHG, STATISTICS_TYPE_VDS} from "../../constants/StatisticsTypes";
import {getStatisticsTypeOptions} from "../../utils/vdsHelpers";
import RadioGroup from "../../atoms/RadioGroup";
import {v4} from 'uuid';

const typeOptions = getStatisticsTypeOptions();

export class VDSSalesCancellationForm extends Component {
  static propTypes = {
    form: PropTypes.any,
    onSubmit: PropTypes.func,
    onReset: PropTypes.func,
    children: PropTypes.any,
    request: PropTypes.any,
    response: PropTypes.any,
    data: PropTypes.any
  };

  constructor(props) {
    super(props);

    this.state = {
      validated: false,
      type: STATISTICS_TYPE_VDS,
      resultsModalShown: false
    };
  }

  componentDidMount() {
    const {data} = this.props;
    if (data) {
      const values = {};
      let isGHG = false;
      if (data.OfferID) {
        values["OfferSource.OfferID"] = data.OfferID;
        if (data.OfferType && data.OfferType.GHG && data.OfferType.GHG.GHGAmount) {
          values["Cancelled.GHGAmount"] = data.OfferType.GHG.GHGAmount;
          isGHG = true;
        } else if (data.OfferType && data.OfferType.Energy && data.OfferType.Energy.QuantityEnergyNatural) {
          values["Cancelled.QuantityEnergyNatural2"] = data.OfferType.Energy.QuantityEnergyNatural;
        }
      } else if (data.SourceID) {
        values["OfferSource.SourceID"] = data.SourceID;
        if (data.OfferType && data.OfferType.Energy && data.OfferType.Energy.QuantityEnergyNatural) {
          values["Cancelled.QuantityEnergyNatural"] = data.OfferType.Energy.QuantityEnergyNatural;
        }
      }
      if (isGHG) {
        this.setState({type: STATISTICS_TYPE_GHG}, () => this.setValues(values));
      } else {
        this.setValues(values);
      }
    }
    this.resetCancelID();
  }

  resetCancelID() {
    const {setFieldsValue} = this.props.form;
    setFieldsValue({
      CancelID: v4()
    });
  }

  setValues(values) {
    const {setFieldsValue} = this.props.form;
    setFieldsValue(values);
  }

  isFirstMarketSale() {
    const {getFieldValue} = this.props.form;
    return !!getFieldValue('OfferSource.SourceID') || !!getFieldValue('Cancelled.QuantityEnergyNatural');
  }

  isSecondMarketSale() {
    const {getFieldValue} = this.props.form;
    return !!getFieldValue('OfferSource.OfferID') || !!getFieldValue('Cancelled.GHGAmount') || !!getFieldValue('Cancelled.QuantityEnergyNatural2');
  }

  submit = (event) => {
    event.preventDefault();
    const {onSubmit, form} = this.props;
    this.setState({validated: true});
    form.validateFields((error, values) => {
      if (!error) {
        onSubmit && onSubmit(values);
      }
    });
  };

  reset = () => {
    const {form, onReset} = this.props;
    const {resetFields} = form;
    resetFields();
    this.resetCancelID();
    this.setState({validated: false});
    onReset && onReset();
  };

  onTypeChange = (event) => {
    this.setState({type: event.target.value});
  };

  validateStatus = (fieldName) => {
    const {form} = this.props;
    const {getFieldError, isFieldTouched} = form;
    const {validated} = this.state;
    return (validated || isFieldTouched(fieldName)) && getFieldError(fieldName) ? 'error' : '';
  };

  showResult = () => {
    this.setState({resultsModalShown: true});
  };

  hideResult = () => {
    this.setState({resultsModalShown: false});
  };

  render() {
    const {form, children, request, response} = this.props;
    const {getFieldDecorator} = form;
    const {type, resultsModalShown} = this.state;
    const firstMarketSale = this.isFirstMarketSale();
    const secondMarketSale = this.isSecondMarketSale();

    return (
      <Form>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item validateStatus={this.validateStatus('CancelID')} label="CancelID">
              {getFieldDecorator('CancelID', {
                rules: [
                  {required: true, message: 'Sisesta CancelID'},
                  {max: 50, message: 'Maksimaalne pikkus on 50'}
                ]
              })(
                <Input placeholder="CancelID"/>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <h2>{'Esimese turu müük'}</h2>
            <div className={'block'}>
              {!secondMarketSale ?
                <Form.Item key={'SourceID'} label="OfferSource"
                           validateStatus={this.validateStatus('OfferSource.SourceID')}>
                  {getFieldDecorator('OfferSource.SourceID', {
                    rules: [
                      {required: true, message: 'Sisesta OfferSource SourceID'},
                      {max: 50, message: 'Maksimaalne pikkus on 50'}
                    ]
                  })(
                    <Input placeholder="SourceID"/>
                  )}
                </Form.Item>
                :
                <Form.Item key={'SourceID_'} label="OfferSource">
                  <Input placeholder="SourceID" disabled={true}/>
                </Form.Item>
              }
              {!secondMarketSale ?
                <Form.Item key={'QuantityEnergyNatural'} label="Cancelled"
                           validateStatus={this.validateStatus('Cancelled.QuantityEnergyNatural')}>
                  {getFieldDecorator('Cancelled.QuantityEnergyNatural', {
                    rules: [{required: true, message: 'Sisesta Cancelled QuantityEnergyNatural'}]
                  })(
                    <InputNumber className={'w-100'}
                                 min={0.1} max={99999999999999.9} step={0.1} precision={1}
                                 placeholder="QuantityEnergyNatural (MJ)"/>
                  )}
                </Form.Item>
                :
                <Form.Item key={'QuantityEnergyNatural_'} label="Cancelled">
                  <InputNumber className={'w-100'} placeholder="QuantityEnergyNatural (MJ)"
                               disabled={true}/>
                </Form.Item>
              }
            </div>
          </Col>
          <Col span={12}>
            <h2>{'Teise turu müük'}</h2>
            <div className={'block'}>
              {!firstMarketSale ?
                <Form.Item key={'OfferID'} label="OfferSource"
                           validateStatus={this.validateStatus('OfferSource.OfferID')}>
                  {getFieldDecorator('OfferSource.OfferID', {
                    rules: [
                      {required: true, message: 'Sisesta OfferSource OfferID'},
                      {max: 50, message: 'Maksimaalne pikkus on 50'}
                    ]
                  })(
                    <Input placeholder="OfferID"/>
                  )}
                </Form.Item>
                :
                <Form.Item key={'OfferID_'} label="OfferSource">
                  <Input placeholder="OfferID" disabled={true}/>
                </Form.Item>
              }
              <Form.Item label="Source type">
                <RadioGroup options={typeOptions}
                            valueKey={'value'}
                            labelKey={'name'}
                            value={type}
                            disabled={firstMarketSale}
                            onChange={this.onTypeChange}
                />
              </Form.Item>
              {firstMarketSale && <Form.Item key={'Cancelled_'} label="Cancelled">
                <InputNumber className={'w-100'} disabled={true}
                             placeholder={type === STATISTICS_TYPE_GHG ? 'GHGAmount (t)' : 'QuantityEnergyNatural (MJ)'}/>
              </Form.Item>}
              {!firstMarketSale && type === STATISTICS_TYPE_GHG &&
                <Form.Item key={'Cancelled'} label="Cancelled"
                           validateStatus={this.validateStatus('Cancelled.GHGAmount', !firstMarketSale)}>
                  {getFieldDecorator('Cancelled.GHGAmount', {
                    rules: [{required: !firstMarketSale, message: 'Sisesta Cancelled GHGAmount'}]
                  })(
                    <InputNumber className={'w-100'}
                                 min={-99999999999999.9}
                                 max={-0.1}
                                 step={0.1} precision={1}
                                 disabled={firstMarketSale}
                                 placeholder={'GHGAmount (t)'}/>
                  )}
                </Form.Item>
              }
              {!firstMarketSale && type === STATISTICS_TYPE_VDS &&
                <Form.Item key={'Cancelled'} label="Cancelled"
                           validateStatus={this.validateStatus('Cancelled.QuantityEnergyNatural2', !firstMarketSale)}>
                  {getFieldDecorator('Cancelled.QuantityEnergyNatural2', {
                    rules: [{
                      required: !firstMarketSale,
                      message: 'Sisesta Cancelled QuantityEnergyNatural'
                    }]
                  })(
                    <InputNumber className={'w-100'}
                                 min={0.1} max={99999999999999.9} step={0.1} precision={1}
                                 disabled={firstMarketSale}
                                 placeholder="QuantityEnergyNatural (MJ)"/>
                  )}
                </Form.Item>
              }
            </div>
          </Col>
        </Row>
        <Row>
          <Col className={'form-footer'}>
            <Button type="primary" onClick={this.submit}>{'Saada'}</Button>
            <Button onClick={this.reset}>{'Puhasta'}</Button>
            <Button onClick={this.showResult} disabled={!request}>{'Päringu tulemus'}</Button>
          </Col>
        </Row>
        {children}
        <VDSRequestResultModal request={request} response={response}
                               show={resultsModalShown} hideModal={this.hideResult}/>
      </Form>
    );
  }
}

export default Form.create()(VDSSalesCancellationForm);
