import {createStore, applyMiddleware, compose} from 'redux';
import {routerMiddleware} from 'react-router-redux';
import createHistory from 'history/createBrowserHistory';
import thunk from 'redux-thunk';
import {languageMiddleware} from "./middlewares";

export const history = createHistory();

export const storeFactory = reducer => {
  const middlewareHistory = routerMiddleware(history);
  const middlewares = [languageMiddleware, middlewareHistory, thunk];

  const enhancers = [applyMiddleware(...middlewares)];

  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;

  const store = createStore(reducer, composeEnhancers(...enhancers));

  return store;
};
