import React from 'react';
import {TransactionSearch as TransactionSearchContainer} from '../../containers';
import {Layout} from 'antd';
import PropTypes from 'prop-types';

class TransactionSearch extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        accountId: PropTypes.string,
      }),
    }),
  };

  render() {
    return (
      <Layout className="main-container">
        <Layout.Content>
          <TransactionSearchContainer
            accountId={this.props.match.params.accountId}
          />
        </Layout.Content>
      </Layout>
    );
  }
}

export default TransactionSearch;
