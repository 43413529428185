import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {connect} from 'react-redux';
import {Button, message, Modal, Spin} from 'antd';
import {List} from 'immutable';
import {LoadingUnitsTable, RegisterActions, TableActions} from '../../components';
import {
  hideLoadingUnitModal,
  showLoadingUnitModal,
} from '../../actions/actionCreators/modalsActionCreators';
import * as actions from '../../actions/actionCreators/loadingUnitActionCreator';
import {
  fetchLegalEntityLoadingUnitsPage,
  resetMasterLegalListActionCreator,
} from '../../actions/actionCreators/loadingUnitActionCreator';
import {modalNames} from '../../constants/ModalsActionTypes';
import {getModalState} from '../../reducers/modalsReducer';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {formRegisterGeneralFields} from '../../constants/formRegister';
import {legalEntityFields} from '../../constants/legalEntity';
import * as loadingUnitSelectors from '../../reducers/legalEntityLoadingUnitsReducer';
import {LoadingUnitEdit, LoadingUnitAdd} from '../';

export class RegisterLoadingUnits extends Component {
  static propTypes = {

    legalEntity: PropTypesImmutable.map.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    showRequestChangeModal: PropTypes.func,

    fetchLegalEntityLoadingUnitsPage: PropTypes.func.isRequired,
    loadingUnits: PropTypesImmutable.list,
    modalAddEquipmentVisible: PropTypes.bool.isRequired,
    loadingUnitsState: PropTypesImmutable.map.isRequired,
    loadingUnitId: PropTypes.number,
  };

  static defaultProps = {
    loadingUnits: List(),
  };

  componentDidMount = () => {
    const {
      fetchLegalEntityLoadingUnitsPage,
      legalEntity,
      resetMasterLegalListActionCreator
    } = this.props;

    resetMasterLegalListActionCreator();

    // TODO change later; hard-coded from default vals
    fetchLegalEntityLoadingUnitsPage({
      filter: {legalEntityId: legalEntity.get(formRegisterGeneralFields.ID)},
      pager: {
        page: 0,
        size: 10,
      },
    });
  };

  goNext = () => {
    const {onSubmitSuccess, loadingUnits, t} = this.props;

    if (loadingUnits.isEmpty()) {
      message.error(t('loadingUnit.emptyError'));
    } else {
      onSubmitSuccess();
    }
  };

  render() {
    const {
      t,
      loadingUnits,
      modalAddEquipmentVisible,
      startAddLoadingUnit,
      hideModal,
      hasPrevious,
      hasDone,
      goPrevious,
      legalEntity,
      isLoading,
      startEditLoadingUnit,
      // deleteProductionUnit,
      isEditMode,
      isAdmin,
      loadingUnitId,
      isLoadingUnitAdd,
      loadingUnitsState,
      showRequestChangeModal,
      fetchLegalEntityLoadingUnitsPage,
    } = this.props;

    const inEditForNotAdmin = isEditMode && !isAdmin;
    const modalCancelProps = isLoadingUnitAdd
      ? {
        onCancel: () => {
          hideModal();
        },
      }
      : {onCancel: hideModal};

    return (
      <Spin spinning={isLoading}>
        <h2 className="register-management-container__subtitle">
          {inEditForNotAdmin
            ? t('loadingUnit.titleView')
            : t('loadingUnit.title')}
        </h2>
        <LoadingUnitsTable
          t={t}
          data={loadingUnits.toJS()}
          onEdit={startEditLoadingUnit}
          // onDelete={deleteProductionUnit}
          isAdmin={isAdmin}
          isEditMode={isEditMode}
          legalEntityId={legalEntity.get(formRegisterGeneralFields.ID)}
          fetchLegalEntityLoadingUnitsPage={
            fetchLegalEntityLoadingUnitsPage
          }
          {...loadingUnitsState.delete('data').toJS()}
        />

        {(isEditMode || loadingUnits.isEmpty()) && (
          <TableActions>
            <Button
              onClick={startAddLoadingUnit}
              size="large"
              type="primary"
            >
              {t('loadingUnit.add')}
            </Button>
          </TableActions>
        )}

        {modalAddEquipmentVisible && (
          <Modal
            {...modalCancelProps}
            visible
            footer={false}
            title={t('loadingUnit.addLoadingUnitTitle')}
            width="80%"
          >
            {isEditMode && !isLoadingUnitAdd ? (
              <LoadingUnitEdit
                legalEntity={legalEntity}
                legalEntityId={legalEntity.get(legalEntityFields.ID)}
                isAdmin={isAdmin}
                isEditMode={isEditMode}
                loadingUnitId={loadingUnitId}
              />
            ) : (
              <LoadingUnitAdd
                onSubmitSuccess={hideModal}
                legalEntity={legalEntity}
                legalEntityId={legalEntity.get(legalEntityFields.ID)}
                isAdmin={isAdmin}
                isEditMode={false}
                isLoadingUnitAdd={isLoadingUnitAdd}
              />
            )}
          </Modal>
        )}
        <RegisterActions
          showRequestChangeModal={showRequestChangeModal}
          showRequestChangeButton={false}
          hasPrevious={hasPrevious}
          hasDone={hasDone}
          goPrevious={goPrevious}
          goNext={this.goNext}
          isEditMode={isEditMode}
          isAdmin={isAdmin}
          showSaveButton={!isEditMode}
          t={t}
        />
      </Spin>
    );
  }
}

const mapStateToProps = state => {
  const modalAddEquipmentVisible = getModalState(
    state,
    modalNames.LOADING_UNIT
  );

  return {
    modalAddEquipmentVisible,
    isLoading: getLoadingSelector(
      loadingUnitSelectors.getLoadingUnitList,
    )(state),
    loadingUnits: loadingUnitSelectors.getLoadingUnitListEntities(state),
    loadingUnitsState: loadingUnitSelectors.getLoadingUnitList(state),
    loadingUnitId: loadingUnitSelectors.getLoadingUnitsEditId(state),
    isLoadingUnitAdd: loadingUnitSelectors.getIsAddMode(state),
  };
};

const mapDispatchToProps = {
  hideModal: hideLoadingUnitModal,
  showModal: showLoadingUnitModal,
  fetchLegalEntityLoadingUnitsPage: fetchLegalEntityLoadingUnitsPage,
  resetMasterLegalListActionCreator: resetMasterLegalListActionCreator,
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  RegisterLoadingUnits
);
