import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {invoiceFields} from '../../constants/invoice';
import {legalEntityFields} from '../../constants/legalEntity';
import {TableRowActions} from '../';
import {Table, Button, Spin} from 'antd';
import {translateInvoiceStatusClassificator} from '../../utils/translateHelpers';
import {euroFormater} from '../../utils/formaters';
import {invoiceStatus} from '../../constants/classificators';
import {goToInvoiceView, getLinkInvoiceExport} from '../../utils/gotoLink';
import {downloadFileByPostURL} from '../../utils/files';
import {isAdmin} from '../../utils/roles';

export class InvoiceTable extends Component {
  static propTypes = {
    invoices: PropTypes.array,
    totalElements: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
    searchInvoices: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    userRole: PropTypes.string.isRequired,
    showConfirmModal: PropTypes.func.isRequired,
    selectInvoice: PropTypes.func.isRequired,
  };

  onPageChange = page => {
    const {searchInvoices} = this.props;

    searchInvoices(page);
  };

  getColumns = () => {
    const {t, userRole} = this.props;

    const isUserAdmin = isAdmin(userRole);

    const columns = [
      {
        title: t('table.number'),
        dataIndex: invoiceFields.NUMBER,
      },
      {
        title: t('table.period'),
        dataIndex: invoiceFields.MONTH,
        render: (value, values) =>
          `${values[invoiceFields.MONTH]}.${values[invoiceFields.YEAR]}`,
      },
      {
        title: t('table.goAmount'),
        dataIndex: invoiceFields.GO_AMOUNT,
      },
      {
        title: t('table.sumWithoutVat'),
        dataIndex: invoiceFields.SUM_WITHOUT_VAT,
        render: euroFormater,
      },
      {
        title: t('table.status'),
        dataIndex: invoiceFields.STATUS,
        render: translateInvoiceStatusClassificator,
      },
    ];

    if (isUserAdmin) {
      columns.push({
        title: t('table.presenter'),
        dataIndex: `${invoiceFields.PRESENTER}.${legalEntityFields.NAME}`,
      });
    }

    columns.push({
      title: t('table.actions'),
      dataIndex: invoiceFields.ID,
      render: (value, values) => (
        <TableRowActions>
          <Button
            shape="circle"
            icon="select"
            size="small"
            type="primary"
            title={t('table.viewBtn')}
            onClick={() => this.handleView(value)}
          />
          {!isUserAdmin &&
            values[invoiceFields.STATUS] === invoiceStatus.NEW && (
              <Button
                shape="circle"
                icon="exclamation-circle-o"
                size="small"
                type="primary"
                title={t('table.confirmBtn')}
                onClick={() => this.handleConfirm(value)}
              />
            )}
        </TableRowActions>
      ),
    });

    return columns;
  };

  handleView = id => {
    const {userRole} = this.props;

    if (isAdmin(userRole)) {
      goToInvoiceView(id);
    } else {
      downloadFileByPostURL(
        getLinkInvoiceExport(id),
        {},
        `invoice-${id}.pdf`,
        'get'
      );
    }
  };

  handleConfirm = invoiceId => {
    const {showConfirmModal, selectInvoice} = this.props;
    selectInvoice(invoiceId);
    showConfirmModal();
  };

  render() {
    const {invoices, totalElements, loading, page, pageSize} = this.props;

    return (
      <Spin spinning={loading} className="search-results-container">
        <Table
          rowKey={invoiceFields.ID}
          dataSource={invoices}
          columns={this.getColumns()}
          pagination={{
            onChange: this.onPageChange,
            current: page,
            total: totalElements,
            pageSize,
            defaultCurrent: 1,
          }}
        />
      </Spin>
    );
  }
}

export default InvoiceTable;
