import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {connect} from 'react-redux';
import {Spin, Modal, Button, message} from 'antd';
import {List} from 'immutable';
import {
  RegisterContactsTable,
  RegisterActions,
  TableActions,
} from '../../components';
import {hideRegisterContactModal} from '../../actions/actionCreators/modalsActionCreators';
import * as actions from '../../actions/actionCreators/registerContactsActionCreators';
import * as selectors from '../../reducers/registerContactsReducer';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {modalNames} from '../../constants/ModalsActionTypes';
import {formRegisterGeneralFields} from '../../constants/formRegister';
import {getModalState} from '../../reducers/modalsReducer';
import {getLegalEntityContacts} from '../../schemas';
import {RegisterContact, RegisterLegalEntityContact} from '../../forms';
import './registerContacts.css';

export class RegisterContacts extends Component {
  static propTypes = {
    isContactsLoading: PropTypes.bool.isRequired,
    isContactsDeleteLoading: PropTypes.bool.isRequired,
    contactModalVisible: PropTypes.bool.isRequired,
    contacts: PropTypesImmutable.list,
    fetchLegalEntityContacts: PropTypes.func.isRequired,
    startAddLegalEntityContact: PropTypes.func.isRequired,
    startEditLegalEntityContact: PropTypes.func.isRequired,
    deleteLegalEntityContact: PropTypes.func.isRequired,
    hideContactModal: PropTypes.func.isRequired,
    hasPrevious: PropTypes.bool,
    hasDone: PropTypes.bool,
    goPrevious: PropTypes.func,
    onSubmitSuccess: PropTypes.func,
    legalEntity: PropTypes.object.isRequired,
    isEditMode: PropTypes.bool,
    isAdmin: PropTypes.bool,
    submitEntityContactForm: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    showRequestChangeModal: PropTypes.func,
  };

  static defaultProps = {
    contacts: List(),
  };

  componentDidMount = () => {
    const {fetchLegalEntityContacts, legalEntity} = this.props;

    fetchLegalEntityContacts(legalEntity.get(formRegisterGeneralFields.ID));
  };

  goNext = () => {
    const {submitEntityContactForm, contacts, t} = this.props;

    if (contacts.isEmpty()) {
      message.error(t('contacts.emptyError'));
    } else {
      submitEntityContactForm();
    }
  };

  render() {
    const {
      t,
      isContactsLoading,
      isContactsDeleteLoading,
      contactModalVisible,
      contacts,
      hideContactModal,
      deleteLegalEntityContact,
      startEditLegalEntityContact,
      startAddLegalEntityContact,
      hasPrevious,
      hasDone,
      goPrevious,
      legalEntity,
      isEditMode,
      onSubmitSuccess,
      isAdmin,
      showRequestChangeModal,
    } = this.props;

    const inEditForNotAdmin = isEditMode && !isAdmin;

    return (
      <div className="register-contacts">
        <Spin spinning={isContactsLoading}>
          <h2>
            {inEditForNotAdmin ? t('contacts.titleView') : t('contacts.title')}
          </h2>
          <RegisterLegalEntityContact
            onSubmitSuccess={onSubmitSuccess}
            t={t}
            legalEntity={legalEntity}
            inEditForNotAdmin={inEditForNotAdmin}
          />
          <Spin spinning={isContactsDeleteLoading}>
            <RegisterContactsTable
              t={t}
              data={contacts.toJS()}
              onDeleteContact={deleteLegalEntityContact}
              onEditContact={startEditLegalEntityContact}
              inEditForNotAdmin={inEditForNotAdmin}
            />
            {!inEditForNotAdmin && (
              <TableActions>
                <Button
                  onClick={() =>
                    startAddLegalEntityContact(
                      legalEntity.get(formRegisterGeneralFields.ID)
                    )
                  }
                  size="large"
                  type="primary"
                >
                  {t('contacts.addContact')}
                </Button>
              </TableActions>
            )}
          </Spin>
          <Modal
            onCancel={hideContactModal}
            visible={contactModalVisible}
            footer={false}
            title={t('contacts.contactModalTitle')}
            width="80%"
          >
            <RegisterContact t={t}/>
          </Modal>
          <RegisterActions
            hasPrevious={hasPrevious}
            hasDone={hasDone}
            goPrevious={goPrevious}
            goNext={this.goNext}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
            showSaveButton={!inEditForNotAdmin}
            showRequestChangeModal={showRequestChangeModal}
            t={t}
          />
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const contactModalVisible = getModalState(state, modalNames.REGISTER_CONTACT);
  const contactIds = selectors.getEntityContactsList(state);

  const isContactsLoading = getLoadingSelector(selectors.getEntityContactsList)(
    state
  );
  const isContactsDeleteLoading = getLoadingSelector(
    selectors.getEntityContactsDeleteState
  )(state);
  const contacts = getLegalEntityContacts(state, contactIds);

  return {
    contactModalVisible,
    isContactsDeleteLoading,
    isContactsLoading,
    contacts,
  };
};

const mapDispatchToProps = {
  ...actions,
  hideContactModal: hideRegisterContactModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterContacts);
