import React from 'react';
import {Button, Col, Form, Input, Row} from 'antd';

export const ElectricMeteringPointFilter = Form.create({})(({
                                                              t,
                                                              onFilter,
                                                              form,
                                                              isUserAdmin,
                                                              isUserLoadingOperator
                                                            }) => {
  const {getFieldDecorator} = form;

  const onClear = () => {
    form.resetFields();
    !!onFilter && onFilter({});
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    form.validateFields((error, values) => !!onFilter && onFilter(values));
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item label={t('filter.eicz')} hasFeedback={false}>
            {getFieldDecorator('eiczCode')(
              <Input placeholder={t('filter.eiczPlaceholder')}/>
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t('filter.consumer')} hasFeedback={false}>
            {getFieldDecorator('consumer')(
              <Input placeholder={t('filter.consumerPlaceholder')}/>
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          {isUserAdmin &&
            <Form.Item label={t('filter.loadingOperator')} hasFeedback={false}>
              {getFieldDecorator('loadingOperator')(
                <Input placeholder={t('filter.loadingOperatorPlaceholder')}/>
              )}
            </Form.Item>
          }
        </Col>
      </Row>
      <div className={'component-form-actions'}>
        <Button onClick={onClear}>{t('filter.clearBtn')}</Button>
        <Button type="primary" htmlType="submit">
          {t('filter.submitBtn')}
        </Button>
      </div>
    </Form>
  );
});
