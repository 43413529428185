import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, Field, formValueSelector} from 'redux-form/immutable';
import {Button, Spin} from 'antd';
import {CheckboxField} from '../../atoms';
import {FORM_INVOICE_TERMS} from '../../constants/formKeys';
import {FormActions} from '../../components';
import {fromInvoiceTermsFields} from '../../constants/forms/formInvoiceTerms';
import {confirmInvoice} from '../../actions/actionCreators/invoiceSearchActionCreators';
import classifiersStore from '../../utils/classifiersStore';
import validate from './validateSchema';

import './termsConditions.css';

export class InvoiceTermsConditions extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hideConfirmModal: PropTypes.func.isRequired,
    hideTermsModal: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isAgree: PropTypes.bool,
  };

  static defaultProps = {
    isAgree: false,
  };

  handleCancel = () => {
    const {hideConfirmModal, hideTermsModal} = this.props;

    hideConfirmModal();
    hideTermsModal();
  };

  render() {
    const {t, handleSubmit, isLoading, isAgree} = this.props;

    return (
      <Spin spinning={isLoading}>
        <form className="from-invoice-terms" onSubmit={handleSubmit}>
          <div className="from-invoice-terms__text">
            {classifiersStore.getInvoiceConfirmationText()}
          </div>
          <Field
            className="from-invoice-terms__agree-field"
            name={fromInvoiceTermsFields.AGREE}
            component={CheckboxField}
            checkboxText={t('terms.agree')}
            hasFeedback={false}
          />
          <FormActions>
            <Button onClick={this.handleCancel}>{t('terms.cancelBtn')}</Button>
            <Button type="primary" htmlType="submit" disabled={!isAgree}>
              {t('terms.confirmBtn')}
            </Button>
          </FormActions>
        </form>
      </Spin>
    );
  }
}

const onSubmit = (values, dispatch) => dispatch(confirmInvoice());

const getFormValue = formValueSelector(FORM_INVOICE_TERMS);

const mapStateToProps = state => ({
  isAgree: getFormValue(state, fromInvoiceTermsFields.AGREE),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_INVOICE_TERMS,
    validate,
    onSubmit,
  })(InvoiceTermsConditions)
);
