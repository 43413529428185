export const FORM_ADD_CONSUMPTION_POINT = 'ADD_CONSUMPTION_POINT_FORM';
export const FORM_ADD_CONSUMER = 'FORM_ADD_CONSUMER';
export const FORM_TRANSACTION_SEARCH = 'FORM_TRANSACTION_SEARCH';
export const FORM_TRANSACTION_FOR_GOS_SEARCH =
  'FORM_TRANSACTION_FOR_GOS_SEARCH';
export const FORM_ACCOUNT_OVERVIEW_FILTER = 'FORM_ACCOUNT_OVERVIEW_FILTER';
export const FORM_ACCOUNT_REPORT_FILTER = 'FORM_ACCOUNT_REPORT_FILTER';
export const FORM_REQUEST_CHANGE = 'FORM_REQUEST_CHANGE';
export const FORM_FUEL_UNIT_MODAL = 'FORM_FUEL_UNIT_MODAL';
export const FORM_CLASSIFIERS_PRICE_EDIT = 'FORM_CLASSIFIERS_PRICE_EDIT';
export const FORM_CLASSIFIERS_GENERAL_EDIT = 'FORM_CLASSIFIERS_GENERAL_EDIT';
export const FORM_CLASSIFIERS_SUB_EDIT = 'FORM_CLASSIFIERS_SUB_EDIT';
export const FORM_CLASSIFIERS_SUB_VALUES_EDIT = 'FORM_CLASSIFIERS_SUB_EDIT';
export const FORM_CLASSIFIERS_TAX_EDIT = 'FORM_CLASSIFIERS_TAX_EDIT';
export const FORM_CLASSIFIERS_GO_AUTO_EDIT = 'FORM_CLASSIFIERS_GO_AUTO_EDIT';
export const FORM_CLASSIFIERS_EAVP_LOAD_EDIT = 'FORM_CLASSIFIERS_EAVP_LOAD_EDIT';
export const FORM_CLASSIFIERS_SUBSIDY_DATE_EDIT =
  'FORM_CLASSIFIERS_SUBSIDY_DATE_EDIT';
export const FORM_CLASSIFIERS_INVOICE_DATE_EDIT =
  'FORM_CLASSIFIERS_INVOICE_DATE_EDIT';
export const FORM_CLASSIFIERS_ELERING_EDIT = 'FORM_CLASSIFIERS_ELERING_EDIT';
export const FORM_CLASSIFIERS_GRID_OPERATOR_EDIT =
  'FORM_CLASSIFIERS_GRID_OPERATOR_EDIT';
export const FORM_CLASSIFIERS_SELLER_EDIT = 'FORM_CLASSIFIERS_SELLER_EDIT';
export const FORM_CLASSIFIERS_INVOICE_CONFIRMATION_EDIT =
  'FORM_CLASSIFIERS_INVOICE_CONFIRMATION_EDIT';
export const FORM_INVOICE_FILTER = 'FORM_INVOICE_FILTER';
export const FORM_INVOICE_CONFIRM = 'FORM_INVOICE_CONFIRM';
export const FORM_INVOICE_TERMS = 'FORM_INVOICE_TERMS';
export const FORM_GO_REPORT = 'FORM_GO_REPORT';
export const FORM_GOS_REPORT = 'FORM_GOS_REPORT';
export const FORM_ELECTRIC_CONSUMPTION_REPORT = 'FORM_ELECTRIC_CONSUMPTION_REPORT';
export const FORM_CONSUMER_GO_SEARCH = 'FORM_CONSUMER_GO_SEARCH';
export const FORM_CLASSIFIERS_GOS_AUTO_EDIT = 'FORM_CLASSIFIERS_GOS_AUTO_EDIT';
export const FORM_ACCOUNT_GOS_REPORT_FILTER =
  'FORM_ACCOUNT_GOS_OVERVIEW_FILTER';
export const FORM_AGREEMENT_WITHDRAW = 'FORM_AGREEMENT_WITHDRAW';
export const FORM_AGREEMENT_CONFIRM = 'FORM_AGREEMENT_CONFIRM';
export const FROM_AGREEMENT_WITHDRAW_ASSIGNMENT = 'FORM_AGREEMENT_CONFIRM';
export const FORM_AGREEMENT_TRANSACTION = "FORM_AGREEMENT_TRANSACTION";
export const FORM_RESERVATION_CONFIRM = "FORM_RESERVATION_CONFIRM";
export const FORM_TS_AUCTION_BID = "FORM_TS_AUCTION_BID";
export const FORM_AUCTION_RESULT_CONFIRM = 'FORM_AUCTION_RESULT_CONFIRM';