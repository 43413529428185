import React from 'react';
import PropTypes from 'prop-types';
import {Row} from 'antd';
import {TransactionGOSSetInformation} from '../';
import {transactionFields} from '../../constants/transactionGOS';

class TransactionGOSSetInformationList extends React.Component {
  static propTypes = {
    info: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const {t, info, transactionType} = this.props;

    const setInformationList = info[
      transactionFields.GOS_SET_INFORMATION_LIST
      ].map(gosSetInformation => (
      <Row>
        <TransactionGOSSetInformation
          t={t}
          info={gosSetInformation}
          transactionType={transactionType}
        />
      </Row>
    ));

    return <React.Fragment>{setInformationList}</React.Fragment>;
  }
}

export default TransactionGOSSetInformationList;
