import {Map} from 'immutable';
import {handleActions} from 'redux-actions';
import {createAsyncReducer, initialAsyncState} from '../utils/asyncHelpers';
import {CONSUMER_ADD} from '../constants/consumerAddActionTypes';

const initialState = Map({
  addConsumer: initialAsyncState,
});

const consumerReducer = handleActions(
  {
    ...createAsyncReducer(CONSUMER_ADD, 'addConsumer'),
  },
  initialState
);

export const getAddConsumer = state => state.getIn(['consumer', 'addConsumer']);

export default consumerReducer;
