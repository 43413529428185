import {fromJS, Map} from 'immutable';
import {toSuccess} from '../utils/asyncHelpers';
import {
  PRODUCTION_UNIT_FUEL_UPDATE,
  PRODUCTION_UNIT_UPDATE_FUELS_DELETE_NON_INCLUDED,
} from '../constants/productionUnitActionTypes';
import {LOADING_UNIT_UPDATE} from '../constants/loadingUnit';

const initialState = Map();

function productionUnitFuelsUpdate(state, action) {
  if (action.payload && action.payload.entities) {
    const entitiesList = action.payload.entities;
    const suggestedNewState = state.mergeDeep(action.payload.entities);
    const puNode = suggestedNewState.get('productionUnit');
    const newProdUEntitylist = entitiesList.productionUnit;
    const arrayOfPotentialKeys = Object.keys(newProdUEntitylist);
    let it = 0;
    let updatingPuNode = puNode;
    for (; it < arrayOfPotentialKeys.length; it++) {
      const k1 = arrayOfPotentialKeys[it];
      const newProdUOb = newProdUEntitylist[k1];
      const puAtState = puNode.get(k1);
      const newProdFuels = newProdUOb.productionUnitFuels;

      const thirdSuggestedFuels = fromJS(newProdFuels);
      const newPuAtState = puAtState.set(
        'productionUnitFuels',
        thirdSuggestedFuels
      );
      updatingPuNode = updatingPuNode.set(k1, newPuAtState);
    }

    return suggestedNewState.set('productionUnit', updatingPuNode);
  }
  return state;
}

const entities = (state = initialState, action) => {
  if (
    action.type === toSuccess(PRODUCTION_UNIT_FUEL_UPDATE) ||
    action.type === toSuccess(PRODUCTION_UNIT_UPDATE_FUELS_DELETE_NON_INCLUDED)
  ) {
    return productionUnitFuelsUpdate(state, action);
  }

  if (action.type === toSuccess(LOADING_UNIT_UPDATE)) {
    if (action.payload && action.payload.entities) {

      const loadingUnits = action.payload.entities.loadingUnit;
      const loadingUnitId = Object.keys(loadingUnits)[0];

      // assumes that action.payload.entities looks like {loadingUnit: {n: {id: n, ...}}}
      return state.setIn(['loadingUnit', loadingUnitId.toString()], fromJS(loadingUnits[loadingUnitId]));
    }
  }

  if (action.payload && action.payload.entities) {
    return state.mergeDeep(action.payload.entities);
  }

  return state;
};

export default entities;

export const getEntities = state => state.get('entities');
