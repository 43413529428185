import {useEffect, useState} from "react";
import {Button, Col, Row, Spin} from "antd";
import React from "react";
import {FormActions} from "../../components";
import {goToClassifiers} from "../../utils/gotoLink";
import {translate} from "react-i18next";
import {NAMESPACES} from "../../i18n";
import goExpirationChangeDate from "../../constants/parameters/goExpirationChangeDate";
import {
  classifiersGoExpirationChange,
  classifiersGoExpirationFetch
} from "../../actions/actionHelpers/endpoints";
import moment from "moment";

function ParameterGoExpirationEdit({t}) {
  const [classifier, setClassifier] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    classifiersGoExpirationFetch()
      .then(response => setClassifier({value: moment(response.data.value, 'DD.MM.YYYY').format('YYYY-MM-DD')}) & setLoading(false))
      .catch(error => console.error(error) & setLoading(false));
  }, []);

  function save() {
    setLoading(true);
    classifiersGoExpirationChange({value: moment(classifier.value, 'YYYY-MM-DD').format('DD.MM.YYYY')})
      .then(() => setLoading(false))
      .catch(error => console.error(error) & setLoading(false));
  }

  return (
    <Spin spinning={loading} className="search-results-container">
      <h1>{t('parameterGoExpirationTitle')}</h1>
      <form>
        <Row gutter={32}>
          <Col span={24}>
            <input type={'date'} className="date-field" name={goExpirationChangeDate.VALUE}
                   value={classifier.value} onChange={e => setClassifier({value: e.target.value})}/>
          </Col>
        </Row>
        <FormActions>
          <Button onClick={goToClassifiers}>{t('buttonBack')}</Button>
          <Button type="primary" onClick={save}>{t('buttonSave')}</Button>
        </FormActions>
      </form>
    </Spin>
  );
}

export default translate(NAMESPACES.classifiers)(ParameterGoExpirationEdit);
