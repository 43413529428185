import {createAsyncSelector} from '../utils/asyncHelpers';
import {
  listInitialAsyncPagingState,
  createAsyncPagingReducer,
} from '../utils/asyncPagingHelpers';
import {handleActions} from 'redux-actions';
import loadingUnitSchema from '../schemas/loadingUnit';
import {LOADING_UNIT_LIST_FETCH} from '../constants/loadingUnit';

const loadingUnitList = handleActions(
  {
    ...createAsyncPagingReducer(LOADING_UNIT_LIST_FETCH),
  },
  listInitialAsyncPagingState
);

export const getLoadingUnitList = state => state.get('loadingUnitList');

export const getLoadingUnitListData = createAsyncSelector(
  [loadingUnitSchema],
  getLoadingUnitList
);

export default loadingUnitList;
