export const MANDATE_COMPANY_ADD = 'MANDATE_COMPANY_ADD';
export const MANDATE_COMPANY_FETCH = 'MANDATE_COMPANY_FETCH';
export const MANDATE_COMPANY_UPDATE = 'MANDATE_COMPANY_UPDATE';
export const MANDATE_COMPANY_DELETE = 'MANDATE_COMPANY_DELETE';
export const MANDATE_PERSON_ADD = 'MANDATE_PERSON_ADD';
export const MANDATE_PERSON_UPDATE = 'MANDATE_PERSON_UPDATE';
export const MANDATE_PERSON_DELETE = 'MANDATE_PERSON_DELETE';
export const MANDATE_PERSON_FETCH = 'MANDATE_PERSON_FETCH';
export const LEGAL_ENTITY_FETCH = 'REGISTER_LEGAL_ENTITY_FETCH';
export const LEGAL_ENTITY_FROM_BR_FETCH = 'LEGAL_ENTITY_FROM_BR_FETCH';
export const LEGAL_ENTITY_UPDATE = 'REGISTER_LEGAL_ENTITY_UPDATE';
export const LEGAL_ENTITY_ADD = 'REGISTER_LEGAL_ENTITY_ADD';
export const REGISTER_MANDATE_FETCH = 'REGISTER_MANDATE_FETCH';
export const REGISTER_MANDATE_UPDATE = 'REGISTER_MANDATE_UPDATE';
export const MEASUREMENT_FETCH = 'MEASUREMENT_FETCH';
export const PRODUCTION_UNIT_MEASUREMENT_INACTIVE_FETCH = 'PRODUCTION_UNIT_MEASUREMENT_INACTIVE_FETCH';
export const MEASUREMENT_ADD = 'MEASUREMENT_ADD';
export const ENTITY_DOCUMENT_ADD = 'REGISTER_ENTITY_DOCUMENT_ADD';
export const ENTITY_DOCUMENT_DELETE = 'REGISTER_ENTITY_DOCUMENT_DELETE';
export const ENTITY_DOCUMENT_FETCH = 'REGISTER_ENTITY_DOCUMENT_FETCH';
export const LEGAL_ENTITY_ACTIVATE = 'LEGAL_ENTITY_ACTIVATE';
export const LEGAL_ENTITY_DEACTIVATE = 'LEGAL_ENTITY_DEACTIVATE';
export const ACCOUNT_GOS_FETCH = 'ACCOUNT_GOS_FETCH';
export const ACCOUNT_GOS_TRANSACTIONS_FETCH = 'ACCOUNT_GOS_TRANSACTIONS_FETCH';
export const ACCOUNT_GOS_REPORT_FETCH = 'ACCOUNT_GOS_REPORT_FETCH';
