import {createValidation, validatorChecked} from '../../utils/formValidators';

import {fromInvoiceTermsFields} from '../../constants/forms/formInvoiceTerms';

export default createValidation([
  {
    name: fromInvoiceTermsFields.AGREE,
    validators: validatorChecked(),
  },
]);
