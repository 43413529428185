export const notification = {
  ID: 'id',
  CREATED_DATE: 'createdDate',
  DESCRIPTION: 'description',
  LEGAL_ENTITY_ID: 'legalEntityId',
  LEGAL_ENTITY: 'legalEntity',
  RELATED_OBJECT_ID: 'relatedObjectId',
  RELATED_OBJECT_NAME: 'relatedObjectName',
  RELATED_OBJECT_TYPE: 'relatedObjectType',
  SENDER: 'sender',
  ADMIN: 'admin',
  STATE: 'state',
  TITLE: 'title',
};
