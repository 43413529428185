import PropTypes from "prop-types";
import {Spin} from "antd";
import {AutoCompleteLegalEntity,} from '../../components';
import React, {Component} from 'react';
import {getLoadingSelector} from "../../utils/asyncHelpers";
import * as selectors from "../../reducers/tsAgreementReducer";
import * as actions from "../../actions/actionCreators/tsAgreementActionCreators";
import {connect} from "react-redux";
import {searchProviderLegalEntityByName} from '../../actions/actionHelpers/endpoints';
import {getUserLegalEntityId} from '../../reducers/userReducer';
import TsAgreementRegistrationTable
  from "../../components/TsAgreement/TsAgreementRegistrationTable";

class TsAgreementRegistration extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    selectReceiver: PropTypes.func.isRequired,
    tsAgreementRegistrationAction: PropTypes.func.isRequired,
    tsAgreementQueueNumFetchAction: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    selectedReceiver: PropTypes.number,
    userLegalEntityId: PropTypes.number,
  };

  componentDidMount = () => {
    this.props.selectReceiver(null);
  };

  render() {
    const {
      isLoading,
      t,
      selectedReceiver,
      selectReceiver,
      tsAgreementRegistrationAction,
      tsAgreementQueueNumFetchAction,
      userLegalEntityId
    } = this.props;

    return (
      <Spin spinning={isLoading}>
        <h2>{t('buyer')}</h2>
        <AutoCompleteLegalEntity
          value={selectedReceiver}
          onChange={selectReceiver}
          searchByName={searchProviderLegalEntityByName}
          unacceptableValues={[userLegalEntityId.toString()]}
          placeholder={t('chooseBuyer')}
        />

        {selectedReceiver && (
          <React.Fragment>
            <h2 className="global-margin-top-24">{t('addPreliminaryAgreementData')}</h2>
            <TsAgreementRegistrationTable
              t={t}
              fetchQueueNum={tsAgreementQueueNumFetchAction}
              performAction={rows =>
                tsAgreementRegistrationAction({
                  sellerId: userLegalEntityId,
                  buyerId: selectedReceiver,
                  ...rows,
                })
              }
            />
          </React.Fragment>
        )}
      </Spin>
    );
  }
}

const getLoading = getLoadingSelector(
  selectors.getTsAgreementRow
);

const mapStateToProps = state => ({
  isLoading: getLoading(state),
  selectedReceiver: selectors.getSelectedReceiver(state),
  userLegalEntityId: getUserLegalEntityId(state),
});

const mapDispatchToProps = {
  selectReceiver: actions.selectReceiver,
  tsAgreementRegistrationAction: actions.tsAgreementRegistrationAction,
  tsAgreementQueueNumFetchAction: actions.tsAgreementQueueNumFetchAction
};

export default connect(mapStateToProps, mapDispatchToProps)(
  TsAgreementRegistration
);
