import React from 'react';
import PropTypes from 'prop-types';
import {MeteringPointEdit} from '../../containers';
import {Layout} from 'antd';

const MeteringPoint = ({match: {params: {meteringPointId, tabKey}}}) => (
  <Layout className="main-container">
    <MeteringPointEdit meteringPointId={meteringPointId} tabKey={tabKey}/>
  </Layout>
);

MeteringPoint.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      meteringPointId: PropTypes.string.isRequired,
      tabKey: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default MeteringPoint;
