export const CLASSIFIER_LIST_FETCH = 'CLASSIFIER_LIST_FETCH';
export const CLASSIFIER_ITEM_FETCH = 'CLASSIFIER_ITEM_FETCH';
export const CLASSIFIER_PRICE_CHANGE = 'CLASSIFIER_PRICE_CHANGE';
export const CLASSIFIER_TAX_FETCH = 'CLASSIFIER_TAX_FETCH';
export const CLASSIFIER_TAX_CHANGE = 'CLASSIFIER_TAX_CHANGE';
export const CLASSIFIER_INVOICE_CONFIRMATION_FETCH =
  'CLASSIFIER_INVOICE_CONFIRMATION_FETCH';
export const CLASSIFIER_INVOICE_CONFIRMATION_CHANGE =
  'CLASSIFIER_INVOICE_CONFIRMATION_CHANGE';
export const CLASSIFIER_GENERAL_FETCH = 'CLASSIFIER_GENERAL_FETCH';
export const CLASSIFIER_GENERAL_CHANGE = 'CLASSIFIER_GENERAL_CHANGE';
export const CLASSIFIER_SUB_FETCH = 'CLASSIFIER_SUB_FETCH';
export const CLASSIFIER_SUB_CHANGE = 'CLASSIFIER_SUB_CHANGE';
export const CLASSIFIER_SUB_DELETE = 'CLASSIFIER_SUB_DELETE';
export const CLASSIFIER_SUB_VALUES_FETCH = 'CLASSIFIER_SUB_VALUES_FETCH';
export const CLASSIFIER_SUB_VALUES_CHANGE = 'CLASSIFIER_SUB_VALUES_CHANGE';
export const CLASSIFIER_SUB_VALUES_DELETE = 'CLASSIFIER_SUB_VALUES_DELETE';
export const CLASSIFIER_GO_AUTO_FETCH = 'CLASSIFIER_GO_AUTO_FETCH';
export const CLASSIFIER_EAVP_LOAD_FETCH = 'CLASSIFIER_EAVP_LOAD_FETCH';
export const CLASSIFIER_GOS_AUTO_FETCH = 'CLASSIFIER_GOS_AUTO_FETCH';
export const CLASSIFIER_GO_AUTO_CHANGE = 'CLASSIFIER_GO_AUTO_CHANGE';
export const CLASSIFIER_EAVP_LOAD_CHANGE = 'CLASSIFIER_EAVP_LOAD_CHANGE';
export const CLASSIFIER_GOS_AUTO_CHANGE = 'CLASSIFIER_GOS_AUTO_CHANGE';
export const CLASSIFIER_SUBSIDY_DATE_FETCH = 'CLASSIFIER_SUBSIDY_DATE_FETCH';
export const CLASSIFIER_INVOICE_DATE_FETCH = 'CLASSIFIER_INVOICE_DATE_FETCH';
export const CLASSIFIER_SUBSIDY_DATE_CHANGE = 'CLASSIFIER_SUBSIDY_DATE_CHANGE';
export const CLASSIFIER_INVOICE_DATE_CHANGE = 'CLASSIFIER_INVOICE_DATE_CHANGE';
export const CLASSIFIER_ELERING_FETCH = 'CLASSIFIER_ELERING_FETCH';
export const CLASSIFIER_ELERING_CHANGE = 'CLASSIFIER_ELERING_CHANGE';
export const CLASSIFIER_GRID_OPERATOR_LIST_FETCH =
  'CLASSIFIER_GRID_OPERATOR_LIST_FETCH';
export const CLASSIFIER_GRID_OPERATOR_DELETE =
  'CLASSIFIER_GRID_OPERATOR_DELETE';
export const CLASSIFIER_GRID_OPERATOR_FETCH = 'CLASSIFIER_GRID_OPERATOR_FETCH';
export const CLASSIFIER_GRID_OPERATOR_CHANGE =
  'CLASSIFIER_GRID_OPERATOR_CHANGE';
export const CLASSIFIER_SELLER_LIST_FETCH = 'CLASSIFIER_SELLER_LIST_FETCH';
export const CLASSIFIER_SELLER_DELETE = 'CLASSIFIER_SELLER_DELETE';
export const CLASSIFIER_SELLER_FETCH = 'CLASSIFIER_SELLER_FETCH';
export const CLASSIFIER_SELLER_CHANGE = 'CLASSIFIER_SELLER_CHANGE';