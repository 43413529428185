import React from 'react';
import PropTypes from 'prop-types';
import {InvoiceView as InvoiceViewContainer} from '../../containers';
import {Layout} from 'antd';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';

const InvoiceView = ({match: {params: {invoiceId}}, t}) => (
  <Layout className="main-container">
    <InvoiceViewContainer invoiceId={invoiceId} t={t}/>
  </Layout>
);

InvoiceView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      invoiceId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default translate(NAMESPACES.invoiceView)(InvoiceView);
