import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  classifierGeneralField,
  classifierSubValuesItemField,
  classifierGeneralItemLangField,
} from '../../constants/classifier';
import {Table, Button, Popconfirm} from 'antd';
import {TableRowActions} from '../';

class ClassifierSubValuesTable extends Component {
  static propTypes = {
    dataSource: PropTypes.object,
    t: PropTypes.func.isRequired,
    classifierId: PropTypes.string.isRequired,
    changeClassifier: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
  };

  getColumns = () => {
    const {t, deleteClassifier, showModal} = this.props;

    return [
      {
        title: t('tableGeneral.code'),
        dataIndex: classifierSubValuesItemField.CODE,
      },
      {
        title: t('tableGeneral.en'),
        dataIndex: `${classifierSubValuesItemField.LANG}.${
          classifierGeneralItemLangField.EN
        }`,
      },
      {
        title: t('tableGeneral.et'),
        dataIndex: `${classifierSubValuesItemField.LANG}.${
          classifierGeneralItemLangField.ET
        }`,
      },
      {
        title: t('tableGeneral.actions'),
        render: value => (
          <TableRowActions>
            <Button
              shape="circle"
              icon="edit"
              size="small"
              type="primary"
              onClick={() => showModal(value[classifierSubValuesItemField.CODE])}
            />
            <span className="ant-divider"/>

            <Popconfirm
              title={t('popconfirm.title')}
              onConfirm={() =>
                deleteClassifier(value[classifierSubValuesItemField.CODE])
              }
              okText={t('popconfirm.buttonYes')}
              cancelText={t('popconfirm.buttonNo')}
            >
              <Button
                shape="circle"
                icon="delete"
                size="small"
                type="primary"
              />
            </Popconfirm>
          </TableRowActions>
        ),
      },
    ];
  };

  render() {
    const {dataSource} = this.props;

    return (
      <React.Fragment>
        <h1>{dataSource[classifierGeneralField.TITLE]}</h1>
        <Table
          rowKey={classifierSubValuesItemField.CODE}
          dataSource={dataSource[classifierGeneralField.ITEMS]}
          columns={this.getColumns()}
          pagination={false}
        />
      </React.Fragment>
    );
  }
}

export default ClassifierSubValuesTable;
