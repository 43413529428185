import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {luMeteringPointsFields} from '../../constants/loadingUnit';
import TableRowActions from '../../components/TableRowActions/TableRowActions';
import {Checkbox, Form, Input, Spin, Table} from 'antd';
import RadioGroup from '../../atoms/RadioGroup';

const isRowTaken = (rowLoadingUnitId, currentLoadingUnitId) => {
  return !(rowLoadingUnitId === null || rowLoadingUnitId === currentLoadingUnitId)
};

export class ElectricMeasurementTable extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onChangeSelection: PropTypes.func.isRequired,
    onChangeConsumer: PropTypes.func.isRequired,
    onChangeHasDirectConn: PropTypes.func.isRequired,
    onChangeEicw: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    loadingUnitId: PropTypes.number.isRequired,
    isEditing: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
  };

  getColumns = () => {
    const {
      t,
      onChangeSelection,
      onChangeConsumer,
      onChangeHasDirectConn,
      onChangeEicw,
      loadingUnitId,
      isEditing,
      isEditMode,
    } = this.props;

    const columns = [];

    const isNotEditingInEditMode = isEditMode && !isEditing;

    const directConnOptions = [
      {
        value: true,
        label: t('true'),
      },
      {
        value: false,
        label: t('false'),
      },
    ];

    if (!isNotEditingInEditMode) {
      columns.push({
        title: '',
        dataIndex: luMeteringPointsFields.SELECTED,
        render: (value, row) => (
          <TableRowActions>
            <Checkbox
              disabled={isRowTaken(row.loadingUnitId, loadingUnitId)}
              checked={value}
              onChange={onChangeSelection(row.eicZ)}
            />
          </TableRowActions>),
      });
    }

    columns.push(
      {
        title: t('metering_point.type'),
        dataIndex: luMeteringPointsFields.TYPE,
        render: () => t('eavp'),
      },
      {
        title: t('metering_point.metering_point_code'),
        dataIndex: luMeteringPointsFields.EICZ_CODE,
      },
      {
        title: t('metering_point.county'),
        dataIndex: luMeteringPointsFields.COUNTY,
      },
      {
        title: t('metering_point.municipality'),
        dataIndex: luMeteringPointsFields.MUNICIPALITY,
      },
      {
        title: t('metering_point.district'),
        dataIndex: luMeteringPointsFields.DISTRICT,
      },
      {
        title: t('metering_point.address'),
        dataIndex: luMeteringPointsFields.ADDRESS,
      },
    );

    if (!isNotEditingInEditMode) {
      columns.push(
        {
          title: t('metering_point.direct_conn'),
          dataIndex: luMeteringPointsFields.IS_DIRECTLY_CONNECTED_TO_PROD_UNIT,
          render: (val, row) => {
            const isTaken = isRowTaken(row.loadingUnitId, loadingUnitId);
            return (
              <React.Fragment>
                <Form.Item required={true} label={t('metering_point.has_direct_conn_to_prod_unit')}>
                  <RadioGroup
                    options={directConnOptions}
                    disabled={isTaken}
                    value={val}
                    onChange={onChangeHasDirectConn(row.eicZ)}
                  />
                </Form.Item>
                {val && (
                  <Form.Item required={!!val} label={t('metering_point.production_unit_eicw_code')}>
                    <Input
                      disabled={isTaken}
                      value={row[luMeteringPointsFields.PRODUCTION_UNIT_EICW]}
                      onChange={onChangeEicw(row.eicZ)}
                    />
                  </Form.Item>
                )}
              </React.Fragment>
            );
          },
        },
      );
      columns.push(
        {
          title: t('metering_point.consumer'),
          dataIndex: luMeteringPointsFields.CONSUMER,
          render: (value, row) => (
            <TableRowActions>
              <Input
                disabled={isRowTaken(row.loadingUnitId, loadingUnitId)}
                value={value}
                onChange={onChangeConsumer(row.eicZ)}
              />
            </TableRowActions>),
        },
      );
    } else {
      columns.push(
        {
          title: t('metering_point.direct_conn'),
          dataIndex: luMeteringPointsFields.IS_DIRECTLY_CONNECTED_TO_PROD_UNIT,
          render: (val, row) => {
            return (
              <React.Fragment>
                <Form.Item label={t('metering_point.has_direct_conn_to_prod_unit')}>
                  <RadioGroup
                    options={directConnOptions}
                    value={val}
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item label={t('metering_point.production_unit_eicw_code')}>
                  <Input
                    disabled={true}
                    value={row[luMeteringPointsFields.PRODUCTION_UNIT_EICW]}
                  />
                </Form.Item>
              </React.Fragment>
            );
          },
        }
      );
      columns.push(
        {
          title: t('metering_point.consumer'),
          dataIndex: luMeteringPointsFields.CONSUMER,
        },
      );
    }

    return columns;
  };

  renderTable = () => {
    const {isEditing, loadingUnitId, isEditMode} = this.props;

    let {data} = this.props;

    const isNotEditingInEditMode = isEditMode && !isEditing;

    if (isNotEditingInEditMode) {
      data = data.filter(point => point.loadingUnitId === loadingUnitId);
    }

    return (
      <Table
        rowKey={record =>
          record[luMeteringPointsFields.ID] ||
          record[luMeteringPointsFields.EICZ_CODE]
        }
        columns={this.getColumns()}
        dataSource={data}
        bordered
      />
    );
  };

  render() {

    return (
      <Spin spinning={false}>
        {this.renderTable()}
      </Spin>
    );
  }
}
