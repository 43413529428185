import {normalizeHelper, createAsyncAction} from '../../utils/asyncHelpers';
import {
  TRANSACTION_FETCH,
  TRANSACTION_RECALL,
  TRANSACTION_MANUAL_ISSUE,
} from '../../constants/actionTypes/transactionViewActionTypes';
import {
  transactionFetch,
  transactionRecall,
  transactionManualIssue,
} from '../actionHelpers/endpoints';
import {transactionSchema} from '../../schemas';

export const fetchTransaction = createAsyncAction(
  TRANSACTION_FETCH,
  transactionFetch,
  normalizeHelper(transactionSchema)
);

export const recallTransaction = createAsyncAction(
  TRANSACTION_RECALL,
  transactionRecall
);

export const issueTransaction = createAsyncAction(
  TRANSACTION_MANUAL_ISSUE,
  transactionManualIssue
);
