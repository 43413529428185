import {changeLocaleAction} from "../actions/pureActions/settingsActions";

const getLocale = state => state.get('settings').get('locale');

const cookieBotUrl = (lang) => `https://consent.cookiebot.com/uc.js?cbid=d3bf18f8-af4a-4607-a2f7-7fe048c1f83a&blockingmode=auto&culture=${lang}`;

function isHidden(el) {
  const style = window.getComputedStyle(el);
  return (style.display === 'none')
}

function isCookieBotDialogVisible() {
  const dialog = document.getElementById('CybotCookiebotDialog');
  let wasVisible = false;
  if (dialog !== null) {
    return !isHidden(dialog);
  }

  return wasVisible;
}

export default ({getState}) => next => action => {

  if (action.type === changeLocaleAction.toString()) {
    const stateLocale = getLocale(getState());

    // change cookie bot language
    if (stateLocale !== action.payload) {
      document.getElementById("Cookiebot").src = cookieBotUrl(action.payload);

      if (isCookieBotDialogVisible()) {
        window.Cookiebot.show();
      }
    }
  }

  return next(action);
};
