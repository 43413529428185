export const productionUnitFilterFields = {
  NAME: 'productionUnitName',
  EICW_CODE: 'equipmentEicwCode',
  EICX_CODE: 'companyEicxCode',
  REGISTER_CODE: 'companyRegistryCode',
  STATUS: 'status',
  PRODUCTION_DEVICE_TYPE: 'productionDeviceType',
};

export const PRODUCTION_UNIT_FILTER_FORM = 'PRODUCTION_UNIT_FILTER_FORM';
