import React, {Component} from "react";
import {translate} from "react-i18next";
import {NAMESPACES} from "../../i18n";
import {connect} from "react-redux";
import {Layout, Modal, Spin} from "antd";
import PropTypes from "prop-types";
import moment from "moment";
import './TsAuctionDetailsView.scss';
import {
  fetchAuctionForView,
  fetchStatisticsForView,
  fetchStatisticsOverviewDetails
} from "../../actions/actionCreators/tsAuctionActionCreators";
import {
  getAuctionDetailsData,
  getStatisticsDetails,
  getStatisticsDetailsDataAndSummary
} from "../../reducers/tsAuctionReducer";
import {getLoadingSelector} from "../../utils/asyncHelpers";
import {TsAuctionDetails} from "./TsAuctionDetails";
import {TsAuctionStatistics} from "./TsAuctionStatistics";
import {auctionDetailResetMaster} from "../../actions/pureActions/auctionActions";
import {renderModalDetails} from "../../components/TradingAccount/OverviewModalDetails";
import FuelType from "../../constants/classificators/fuelType";

export class TsAuctionDetailsView extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        auctionId: PropTypes.string.isRequired,
        registerCode: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      auctionId: this.props.match.params.auctionId,
      modalDetailsVisible: false,
      modalDetails: {}
    }
  }

  componentDidMount() {
    const {fetchAuction, fetchStatistics} = this.props;
    const {auctionId} = this.state;

    fetchAuction(auctionId);
    fetchStatistics(auctionId);
  }

  componentWillUnmount() {
    const {resetMaster} = this.props;
    resetMaster();
  }

  fetchStatisticsOverview = (record, fuelType) => {
    const {fetchStatisticsOverview} = this.props;
    fetchStatisticsOverview(record.id, fuelType)
      .then(data => this.setState({
        modalDetails: this.modifyDetails(data, record, fuelType),
        modalDetailsVisible: true
      }));
  }

  modifyDetails = (data, record, fuelType) => {
    if (fuelType === FuelType.KHG) {
      data.ghgAmount = record.ghgAmount;
    } else {
      data.naturalEnergyAmount = record.naturalAmountMj;
      data.calculatedEnergyAmount = record.naturalAmountMj * data.calculatedMultiplier;
    }
    return data;
  }

  hideDetailModal = () => {
    this.setState({
      modalDetails: {},
      modalDetailsVisible: false
    })
  }

  render() {
    const {
      t,
      auction,
      isAuctionLoading,
      statistics,
      isStatisticsLoading,
    } = this.props;
    const {auctionId, modalDetailsVisible, modalDetails} = this.state;

    return (
      <Layout className="main-container">
        <h1 className="mb-3">
          <span className="text-bold">{t('details.currentDateTime')}: </span>
          <span className="datetime">{moment().format("DD.MM.YYYY HH:mm:ss")}</span>
        </h1>

        <Modal
          title={t('detailsTitle')}
          visible={modalDetailsVisible}
          footer={false}
          width="400px"
          onCancel={this.hideDetailModal}
        >
          {renderModalDetails(t, modalDetails)}
        </Modal>

        <Spin spinning={isAuctionLoading}>
          {!isAuctionLoading &&
            <TsAuctionDetails
              t={t}
              auctionId={auctionId}
              auction={auction}
              year={statistics.length ? statistics[0].year : null}
            />
          }
        </Spin>
        <br/>
        <TsAuctionStatistics
          t={t}
          statistics={statistics}
          fuelType={auction.fuelType}
          isStatisticsLoading={isStatisticsLoading}
          fetchOverview={this.fetchStatisticsOverview}
        />
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  auction: getAuctionDetailsData(state).toJS(),
  isAuctionLoading: getLoadingSelector(getAuctionDetailsData)(state),
  statistics: getStatisticsDetailsDataAndSummary(state),
  isStatisticsLoading: getLoadingSelector(getStatisticsDetails)(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchAuction: (id) => dispatch(fetchAuctionForView(id)),
  fetchStatistics: (id) => dispatch(fetchStatisticsForView(id)),
  fetchStatisticsOverview: (id, fuelType) => dispatch(fetchStatisticsOverviewDetails(id, fuelType)),
  resetMaster: () => dispatch(auctionDetailResetMaster())
});

export default translate(NAMESPACES.auctions)(
  connect(mapStateToProps, mapDispatchToProps)(TsAuctionDetailsView)
);
