import PropTypes from "prop-types";
import React, {Component} from "react";
import {Col, Row, Spin} from "antd";
import {connect} from "react-redux";
import './TsReservationsConfirmDetailsView.scss';
import {
  translateClassificator,
  translateTsFuelGenerationClassificator as tFuelGeneration
} from "../../utils/translateHelpers";
import {numberFormatter} from "../../utils/formaters";
import {availableStatisticsTableFields} from "../../constants/availableStatisticsTableFields";

export class TsReservationsConfirmDetailsView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    agreement: PropTypes.object.isRequired,
    statistics: PropTypes.array.isRequired,
  }

  state = {
    statistics: []
  }

  componentDidMount = () => {
    const {statistics} = this.props;
    this.setState({statistics})
  };

  renderRow = (label, value) => (
    <div>
      <span>{label}: </span>
      <span>{value}</span>
    </div>
  );

  render() {
    const {t, agreement, statistics} = this.props;

    if (statistics.length === 0) return <Spin/>

    const isB = agreement.fuelType === 'BIOMETHANE'
    const isE = agreement.fuelType === 'RENEWABLE_ELECTRICITY';
    const isS = agreement.fuelType === 'RENEWABLE_ENERGY';

    return (
      <form>
        {statistics.map(item => {
          return (
            <div key={item.statisticsId}>
              <Row gutter={32}>
                <Col span={24} className="detail-container global-margin-bottom-10">
                  {this.renderRow(t('details.type.title'), t('details.type.' + agreement.fuelType))}
                  {this.renderRow(t('details.expirationDate'), item.expirationDate)}
                  {this.renderRow(t('details.naturalAmountMj'), numberFormatter(item[availableStatisticsTableFields.NATURAL_ENERGY_TRANSFER_AMOUNT]))}
                  {this.renderRow(t('details.calculatedMultiplier'), item.calculatedMultiplier)}
                  {this.renderRow(t('details.calculatedAmountMj'), numberFormatter(item[availableStatisticsTableFields.CALCULATED_ENERGY_TRANSFER_AMOUNT]))}
                  {!isE && this.renderRow(t('details.biofuelType'), tFuelGeneration(item.fuelGeneration))}
                  {this.renderRow(t('details.ghgCapacity'), numberFormatter(item.ghgCapacity))}
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={24} className="detail-container global-margin-bottom-10">
                  {this.renderRow(t('details.consumptionPeriod'), (!isS ? item.consumptionMonth + '.' : '') + item.consumptionYear)}
                  {!isS && this.renderRow(t('details.consumptionProvider'), item.consumptionProvider)}
                  {!isS && this.renderRow(t('details.consumptionProviderTransactionId'), item.consumptionProviderTransactionId)}
                  {isB && this.renderRow(t('details.productionPeriod'), item.productionPeriod)}
                  {isB && this.renderRow(t('details.productionUnitEicwCode'), item.productionUnitEicwCode)}
                  {isB && this.renderRow(t('details.posNumber'), item.posNumber)}
                </Col>
              </Row>
              {isB && <Row gutter={32}>
                <Col span={24} className="detail-container global-margin-bottom-10">
                  {this.renderRow(t('details.energyType'), translateClassificator('energyType')(item.energyType))}
                  {this.renderRow(t('details.biofuelComponentCn'), item.biofuelComponentCn)}
                  {this.renderRow(t('details.sustainableBiofuel'), translateClassificator('sustainableBiofuel')(item.sustainableBiofuel + ''))}
                  {this.renderRow(t('details.fuelType'), item.fuelType)}
                  {this.renderRow(t('details.feedstock'), translateClassificator('feedstock')(item.feedstock))}
                  {this.renderRow(t('details.productionPathway'), translateClassificator('productionPathway')(item.productionPathway))}
                  {this.renderRow(t('details.lowerCalorificValueKg'), item.lowerCalorificValueKg)}
                  {this.renderRow(t('details.landUseCategory'), translateClassificator('landUseCategory')(item.landUseCategory))}
                  {this.renderRow(t('details.landUseEmissions'), item.landUseEmissions)}
                </Col>
              </Row>}
              <br/>
            </div>
          );
        })}
      </form>
    );
  }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TsReservationsConfirmDetailsView);
