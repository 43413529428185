import React, {Component} from 'react';
import PropTypesImmutable from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {MeteringPointSearchTable} from '../../components';
import {
  getConsumptionPointList,
  getConsumptionPointListData,
} from '../../reducers/consumptionPointListReducer';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {Button} from 'antd';
import * as actions from '../../actions/actionCreators/consumptionPointListActionCreators';
import {
  hideMeasuringUploadModal,
  showMeasuringUploadModal,
  getMeasuringUploadModal,
} from '../../actions/actionCreators/modalsActionCreators';
import {goToConsumptionPoint} from '../../utils/gotoLink';
import {getRole, getIsUserSeller} from '../../reducers/userReducer';
import {isAdmin} from '../../utils/roles';

export class MeteringPointList extends Component {
  static propTypes = {
    consumptionPoint: PropTypesImmutable.list,
    consumptionPointList: PropTypesImmutable.map.isRequired,
    t: PropTypes.func.isRequired,
    searchConsumptionPoints: PropTypes.func.isRequired,
    showImportModal: PropTypes.func.isRequired,
    hideImportModal: PropTypes.func.isRequired,
    stateImportModal: PropTypes.bool.isRequired,
    userRole: PropTypes.string.isRequired,
    isUserSeller: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    const {searchConsumptionPoints} = this.props;
    searchConsumptionPoints(0);
  }

  render() {
    const {
      consumptionPoint,
      consumptionPointList,
      showImportModal,
      hideImportModal,
      stateImportModal,
      userRole,
      isUserSeller,
      ...rest
    } = this.props;

    const isUserAdmin = isAdmin(userRole);

    return (
      <React.Fragment>
        {(isUserSeller || isUserAdmin) && (
          <div className="page-ConsumptionPointList__new-button">
            <Button onClick={goToConsumptionPoint} type="primary">
              {rest.t('newButton')}
            </Button>
          </div>
        )}
        <MeteringPointSearchTable
          consumptionPoints={consumptionPoint.toJS()}
          hideImportModal={hideImportModal}
          showImportModal={showImportModal}
          stateImportModal={stateImportModal}
          isAdmin={isUserAdmin}
          {...rest}
          {...consumptionPointList.toJS()}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  consumptionPointList: getConsumptionPointList(state),
  consumptionPoint: getConsumptionPointListData(state),
  loading: getLoadingSelector(getConsumptionPointList)(state),
  stateImportModal: getMeasuringUploadModal(state),
  userRole: getRole(state),
  isUserSeller: getIsUserSeller(state),
});

const mapDispatchToProps = {
  ...actions,
  showImportModal: showMeasuringUploadModal,
  hideImportModal: hideMeasuringUploadModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(MeteringPointList);
