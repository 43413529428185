import {handleActions} from 'redux-actions';
import {combineReducers} from 'redux-immutable';
import {
  createAsyncReducer,
  initialAsyncState,
  toSuccess,
} from '../utils/asyncHelpers';
import {
  PRODUCTION_UNIT_DOCUMENTS_ADD,
  PRODUCTION_UNIT_DOCUMENTS_DELETE,
  PRODUCTION_UNIT_DOCUMENTS_FETCH,
} from '../constants/productionUnitActionTypes';

const list = handleActions(
  {
    ...createAsyncReducer(PRODUCTION_UNIT_DOCUMENTS_FETCH),
    [toSuccess(PRODUCTION_UNIT_DOCUMENTS_ADD)]: (state, {payload}) =>
      state.set('data', state.get('data').concat(payload.result)),
    [toSuccess(PRODUCTION_UNIT_DOCUMENTS_DELETE)]: (state, {payload}) =>
      state.set('data', state.get('data').filter(id => id !== payload)),
  },
  initialAsyncState
);
const productionUnitDocumentsReducer = combineReducers({
  list,
  add: handleActions(
    createAsyncReducer(PRODUCTION_UNIT_DOCUMENTS_ADD),
    initialAsyncState
  ),
  delete: handleActions(
    createAsyncReducer(PRODUCTION_UNIT_DOCUMENTS_DELETE),
    initialAsyncState
  ),
});

export const getProductionUnitDocumentsList = state =>
  state.getIn(['productionUnitDocuments', 'list']);

export const getProductionUnitDocumentsAddState = state =>
  state.getIn(['productionUnitDocuments', 'add']);

export const getProductionUnitDocumentsDeleteState = state =>
  state.getIn(['productionUnitDocuments', 'delete']);

export default productionUnitDocumentsReducer;
