import React from 'react';
import {connect} from 'react-redux';
import {Button, Spin, Tabs} from 'antd';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import PropTypes from 'prop-types';
import {
  getIsUserLoadingOperator,
  getIsUserProvider,
  getIsUserSeller,
  getRole
} from '../../reducers/userReducer';
import AccountGOSOverviewFilter
  from '../../forms/AccountGOSOverviewFilter/AccountGOSOverviewFilter';
import AccountGOSTransactionsFilter
  from '../../forms/AccountGOSTransactionsFilter/AccountGOSTransactionsFilter';
import AccountGOSReportFilter from '../../forms/AccountGOSReportFilter/AccountGOSReportFilter';
import AccountGOSList from '../AccountGOSList/AccountGOSList';
import AccountGOSTransactionsList from '../AccountGOSTransactionsList/AccountGOSTransactionsList';
import AccountGOSReportList from '../AccountGOSReportList/AccountGOSReportList';
import {accountGOSFilterFields} from '../../constants/formAccountGOSFilter';
import {taTransactionsFilterFields} from '../../constants/formAccountGOSTransactionsFilter';
import {accountGOSReportFilterFields} from '../../constants/formAccountGOSReportFilter';
import moment from 'moment';
import {currentPathSelector, goToTsAgreementRegistration, links} from "../../utils/gotoLink";
import AccountGOSPurchasingAgreementsList
  from "../AccountGOSAgreementsList/AccountGOSPurchasingAgreementsList";
import AccountGOSSellingAgreementsList
  from "../AccountGOSAgreementsList/AccountGOSSellingAgreementsList";
import {TableActions} from "../../components";
import AccountGOSTsAssignmentsFilter
  from "../../forms/AccountGOSTsAssignmentsFilter/AccountGOSTsAssignmentsFilter";
import {AccountGOSTsAssignmentsList} from "../index";
import AccountGOSAgreementsFilter
  from "../../forms/AccountGOSAgreementsFilter/AccountGOSAgreementsFilter";
import {
  FORM_ACCOUNT_GOS_SELLING_AGREEMENTS_FILTER,
  sellingAgreementsFilterFields
} from "../../constants/formAccountGOSSellingAgreementsFilter";
import * as actions from "../../actions/actionCreators/accountGOSAgreementsActionCreators";
import {
  FORM_ACCOUNT_GOS_PURCHASING_AGREEMENTS_FILTER
} from "../../constants/formAccountGOSPurchasingAgreementsFilter";
import AccountGOSAuctionsFilter
  from "../../forms/AccountGOSAuctionsFilter/AccountGOSAuctionsFilter";
import AccountGOSAuctionsList from "../AccountGOSAuctionsList/AccountGOSAuctionsList";


const {TabPane} = Tabs;

const tabKeys = {
  tab0: '0',
  tab1: '1',
  tab2: '2',
  tab3: '3',
  tab4: '4',
  tab5: '5',
  tab6: '6'
};

const TAB_PATHS = {
  'search': tabKeys.tab1,
  'ts-assignment': tabKeys.tab2,
  'pre-trading': tabKeys.tab3,
  'auctions': tabKeys.tab4,
  'transactions': tabKeys.tab5,
  'report': tabKeys.tab6,
}

class AccountGOSOverview extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    registerCode: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    legalEntity: PropTypes.object,
    locationState: PropTypes.object,
    currentPath: PropTypes.string.isRequired,
    isAuctionsEnabled: PropTypes.bool.isRequired,
  };

  state = {
    activeTab: '',
    expirationDate: null,
  };

  resolveTab(currentPath) {
    return TAB_PATHS[Object.keys(TAB_PATHS).find(tabPath => currentPath.includes(tabPath))];
  }

  getDefaultActiveTab = () => {
    const {locationState, currentPath} = this.props;

    if (
      locationState &&
      locationState.activeTab &&
      locationState.activeTab === '2'
    ) {
      return tabKeys.tab2;
    }

    return this.resolveTab(currentPath);
  };

  expirationDateChanged = value => {
    const date = moment(value, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY');
    this.setState({expirationDate: date});
  };

  render() {
    const {
      t,
      registerCode,
      isLoading,
      isUserProvider,
      isUserTrader,
      isUserLoadingOperator,
      isAuctionsEnabled,
    } = this.props;

    const isSellingAgreementsDisplayed = isUserProvider || isUserTrader || isUserLoadingOperator;

    return (
      <Spin spinning={isLoading}>
        <div>
          <h1>{t('title')}</h1>
          <Tabs type="card" defaultActiveKey={this.getDefaultActiveTab()}>
            <TabPane tab={t('title_overview')} key="1">
              <AccountGOSOverviewFilter
                t={t}
                initialValues={{
                  expirationDate: this.state.expirationDate,
                  [accountGOSFilterFields.REGISTER_CODE]: registerCode,
                }}
                expirationDateChanged={this.expirationDateChanged}
              />
              <AccountGOSList t={t} isAuctionsEnabled={isAuctionsEnabled}/>
            </TabPane>

            <TabPane tab={t('title_ts_assignments')} key="2">
              <AccountGOSTsAssignmentsFilter
                t={t}
                initialValues={{}}
                isAuctionsEnabled={isAuctionsEnabled}
              />
              <AccountGOSTsAssignmentsList t={t} registerCode={registerCode}/>
            </TabPane>
            <TabPane tab={t('title_pre_trading_agreements')} key="3">
              {
                isSellingAgreementsDisplayed && (
                  <span>
                        <AccountGOSAgreementsFilter t={t}
                                                    form={FORM_ACCOUNT_GOS_SELLING_AGREEMENTS_FILTER}
                                                    onSubmit={(values, dispatch) =>
                                                      dispatch(actions.searchAccountGOSSellingAgreements(values))}
                                                    counterparty={sellingAgreementsFilterFields.BUYER_REGISTER_CODE}
                                                    initialValues={{sellerRegisterCode: registerCode}}
                                                    csvUrl={links.tsSellingAgreementsCsv}
                                                    csvFilename={t('agreements.filter.csvSellingFilename')}
                                                    csvButton={t('agreements.filter.csvSellingBtn')}/>
                        <div className="table-title">{t('title_selling_agreements')}</div>
                        <AccountGOSSellingAgreementsList t={t} registerCode={registerCode}/>
                      </span>
                )
              }
              {
                isUserProvider && (
                  <span>
                        <AccountGOSAgreementsFilter t={t}
                                                    form={FORM_ACCOUNT_GOS_PURCHASING_AGREEMENTS_FILTER}
                                                    onSubmit={(values, dispatch) =>
                                                      dispatch(actions.searchAccountGOSPurchasingAgreements(values))}
                                                    counterparty={sellingAgreementsFilterFields.SELLER_REGISTER_CODE}
                                                    initialValues={{buyerRegisterCode: registerCode}}
                                                    csvUrl={links.tsPurchasingAgreementsCsv}
                                                    csvFilename={t('agreements.filter.csvPurchasingFilename')}
                                                    csvButton={t('agreements.filter.csvPurchasingBtn')}/>
                        <div className="table-title">{t('title_purchasing_agreements')}</div>
                        <AccountGOSPurchasingAgreementsList t={t} registerCode={registerCode}/>
                      </span>
                )
              }
              <TableActions>
                <Button type="primary"
                        onClick={() => goToTsAgreementRegistration()}>
                  {t('agreements.table.registerBtn')}
                </Button>
              </TableActions>
            </TabPane>

            {
              isAuctionsEnabled &&
              <TabPane tab={t('title_auctions')} key="4">
                <AccountGOSAuctionsFilter t={t} registerCode={registerCode}/>
                <AccountGOSAuctionsList t={t} registerCode={registerCode}/>
              </TabPane>
            }

            <TabPane tab={t('title_transactions')} key="5">
              <AccountGOSTransactionsFilter
                initialValues={{
                  [taTransactionsFilterFields.REGISTER_CODE]: registerCode,
                }}
                t={t}
              />
              <AccountGOSTransactionsList t={t}/>
            </TabPane>
            <TabPane tab={t('title_report')} key="6">
              <AccountGOSReportFilter
                initialValues={{
                  [accountGOSReportFilterFields.REGISTER_CODE]: registerCode,
                }}
                t={t}
              />
              <AccountGOSReportList t={t}/>
            </TabPane>
          </Tabs>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: false,
    role: getRole(state),
    locationState: state.get('routing').location.state,
    currentPath: currentPathSelector(state),
    isUserProvider: getIsUserProvider(state),
    isUserTrader: getIsUserSeller(state),
    isUserLoadingOperator: getIsUserLoadingOperator(state),
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(
  translate(NAMESPACES.accountGOS)(AccountGOSOverview)
);
