import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {connect} from 'react-redux';
import {Field, reduxForm, formValueSelector} from 'redux-form/immutable';
import {DateField, RadioField, TextField} from '../../atoms';
import {Row, Col, Alert} from 'antd';
import {
  FORM_ACTIVITY_PERIOD,
  activityPeriodFormFields,
} from '../../constants/formsAddEquipment';
import {RegisterActions} from '../../components';
import {parseNumber} from '../../utils/formHelpers';
import {updateProductionUnit} from '../../actions/actionCreators/productionUinitActionCreators';
import validate from './validateSchema';

export class AddEquipmentActivityPeriod extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hasPrevious: PropTypes.bool,
    hasDone: PropTypes.bool,
    goPrevious: PropTypes.func,
    productionUnit: PropTypesImmutable.map.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    inEditForNotAdmin: PropTypes.bool.isRequired,
    showRequestChangeModal: PropTypes.func,
    subsidyEligible: PropTypes.bool,
  };

  render() {
    const {
      handleSubmit,
      hasPrevious,
      hasDone,
      goPrevious,
      t,
      isAdmin,
      isEditMode,
      showRequestChangeModal,
      subsidyEligible,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <h2>{t('activityPeriod.title')}</h2>
        {!isEditMode &&
          !isAdmin && (
            <Alert
              message={t('activityPeriod.registerWarning')}
              type="warning"
            />
          )}
        <Row gutter={32}>
          <Col span={12}>
            <Field
              name={activityPeriodFormFields.ELIGIBLE_FOR_SUBSIDY}
              component={RadioField}
              required
              options={[
                {
                  value: true,
                  label: t('activityPeriod.subsidyEligible.trueLabel'),
                },
                {
                  value: false,
                  label: t('activityPeriod.subsidyEligible.falseLabel'),
                },
              ]}
              type="text"
              label={t('activityPeriod.subsidyEligible.label')}
              disabled={!isAdmin}
              hasFeedback={isAdmin}
            />
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={8}>
            <Field
              label={t('activityPeriod.decisionDate')}
              name={activityPeriodFormFields.DECISION_DATE}
              required
              component={DateField}
              disabled={!isAdmin}
              hasFeedback={isAdmin}
            />
          </Col>
          <Col span={8}>
            <Field
              name={activityPeriodFormFields.DECISION_NUMBER}
              component={TextField}
              parse={parseNumber}
              type="text"
              label={t('activityPeriod.decisionNumber')}
              disabled={!isAdmin}
              hasFeedback={isAdmin}
            />
          </Col>
        </Row>
        {subsidyEligible && (
          <Row gutter={32}>
            <Col span={8}>
              <Field
                label={t('activityPeriod.startDate')}
                name={activityPeriodFormFields.START_DATE}
                required
                type="text"
                component={DateField}
                disabled={!isAdmin}
              />
            </Col>
            <Col span={8}>
              <Field
                name={activityPeriodFormFields.END_DATE}
                component={DateField}
                type="text"
                label={t('activityPeriod.endDate')}
                disabled={!isAdmin}
                hasFeedback={isAdmin}
              />
            </Col>
          </Row>
        )}
        <RegisterActions
          showRequestChangeModal={showRequestChangeModal}
          hasPrevious={hasPrevious}
          hasDone={hasDone}
          goPrevious={goPrevious}
          t={t}
          isEditMode={isEditMode}
          showSaveButton={isAdmin || !isEditMode}
          isAdmin={isAdmin}
        />
      </form>
    );
  }
}

const onSubmit = async (values, dispatch) => {
  await dispatch(updateProductionUnit(values.toJS()));
};

const getFormValue = formValueSelector(FORM_ACTIVITY_PERIOD);

const mapStateToProps = (state, ownProps) => ({
  initialValues: ownProps.productionUnit,
  subsidyEligible: getFormValue(
    state,
    activityPeriodFormFields.ELIGIBLE_FOR_SUBSIDY
  ),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_ACTIVITY_PERIOD,
    enableReinitialize: true,
    onSubmit,
    validate: (values, props) =>
      props.isAdmin ? validate(values, props) : undefined,
  })(AddEquipmentActivityPeriod)
);
