import React from 'react';
import {searchCompany} from '../../actions/actionHelpers/endpoints';
import {AutoCompleteSelectField} from '../';

const getDataSource = async value => {
  const {data} = await searchCompany(value);
  return data.map(item => ({
    value: item.id,
    label: `${item.name}, ${item.registrationCode}`,
  }));
};

const AutoCompleteCompaniesField = props => (
  <AutoCompleteSelectField {...props} getDataSource={getDataSource}/>
);

export default AutoCompleteCompaniesField;
