import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Modal, Spin} from 'antd';
import {RegisterRepresentCompany} from '../../forms';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {
  fetchMandateCompany,
  editRepresentCompanyActionCreator,
  deleteMandateCompany,
} from '../../actions/actionCreators/registerActionCreators';
import {
  showRegisterRepresentCompanyModal,
  hideRegisterRepresentCompanyModal,
  getRegisterRepresentCompanyModal,
} from '../../actions/actionCreators/modalsActionCreators';
import {getDeleteMandateCompany} from '../../reducers/registerAddMandateReducer';
import {
  getMandateCompanyList,
  getMandateCompanyListPure,
} from '../../reducers/registerReducer';
import MandateCompaniesTable from '../../components/MandateCompaniesTable/MandateCompaniesTable';
import {getLoadingSelector} from '../../utils/asyncHelpers';

export class RegisterManagementCompany extends Component {
  static propTypes = {
    fetchMandateCompany: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    mandateCompanies: PropTypesImmutable.list.isRequired,
    t: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.fetchMandateCompany(this.props.legalEntity.get('id'));
  }

  render() {
    const {
      t,
      isLoading,
      mandateCompanies,
      modalCompanyVisible,
      hideRepresentCompanyModal,
      showRepresentCompanyModal,
      editRepresentCompany,
      deleteRepresentCompany,
      legalEntity,
    } = this.props;

    return (
      <Spin spinning={isLoading}>
        <div className="register-management-container">
          <h2 className="register-management-container__subtitle">
            {t('representation.subtitleCompanies')}
          </h2>
          <MandateCompaniesTable
            editRepresent={editRepresentCompany}
            deleteRepresent={deleteRepresentCompany}
            t={t}
            data={mandateCompanies.toJS()}
          />
          <div className="register-management-container__add-button">
            <Button
              onClick={showRepresentCompanyModal}
              size="large"
              type="primary"
            >
              {t('representation.buttonAddCompanies')}
            </Button>
          </div>
          {modalCompanyVisible && (
            <Modal
              title={t('representation.titleModalCompanies')}
              wrapClassName="register-management-container__modal"
              visible={modalCompanyVisible}
              footer={null}
              onCancel={hideRepresentCompanyModal}
              width={640}
            >
              <RegisterRepresentCompany
                closeModal={hideRepresentCompanyModal}
                initialValues={{
                  originatorLegalEntityId: legalEntity.get('id'),
                }}
                t={t}
              />
            </Modal>
          )}
        </div>
      </Spin>
    );
  }
}

const getLoading = getLoadingSelector(
  getDeleteMandateCompany,
  getMandateCompanyListPure
);

const mapStateToProps = state => ({
  isLoading: getLoading(state),
  mandateCompanies: getMandateCompanyList(state),
  modalCompanyVisible: getRegisterRepresentCompanyModal(state),
});

const mapDispatchToProps = {
  editRepresentCompany: editRepresentCompanyActionCreator,
  deleteRepresentCompany: deleteMandateCompany,
  hideRepresentCompanyModal: hideRegisterRepresentCompanyModal,
  showRepresentCompanyModal: showRegisterRepresentCompanyModal,
  fetchMandateCompany,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  RegisterManagementCompany
);
