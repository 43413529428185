import React, {Component} from 'react';
import {connect} from 'react-redux';
import {List} from 'immutable';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {Button} from 'antd';
import {
  goToDashboard,
  goToRegister,
  goToConsumerReports,
  links,
} from '../../utils/gotoLink';
import {FormActions, SelectMandate} from '../../components';
import * as userActions from '../../actions/actionCreators/userActionCreators';
import * as userSelectors from '../../reducers/userReducer';
import {getMandatePersons} from '../../schemas/mandatePerson';
import {legalEntityStatus} from '../../constants/classificators';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {mandateFields} from '../../constants/mandate';
import {legalEntityFields} from '../../constants/legalEntity';
import {isAdmin, isConsumer} from '../../utils/roles';

import './Login.css';
import axios from "axios/index";

export class Login extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    fetchUserWithMandates: PropTypes.func.isRequired,
    mandates: PropTypesImmutable.list,
    mandate: PropTypes.number,
    logout: PropTypes.func.isRequired,
    selectUserMandate: PropTypes.func.isRequired,
  };

  static defaultProps = {
    mandates: List(),
  };

  state = {
    mandate: 0,
  };

  componentDidMount = () => {
    this.props.fetchUserWithMandates();
  };

  onMandateChange = mandate => {
    this.setState({mandate});
  };

  onSubmit = async e => {
    e.preventDefault();
    const {selectUserMandate} = this.props;

    const isRegister = this.isRegister();
    const isConsumerSelected = this.isConsumerSelected();
    const selectedLegalEntity = this.selectedLegalEntity();

    await selectUserMandate(this.state.mandate);
    if (isRegister) {
      goToRegister(selectedLegalEntity.get(legalEntityFields.REGISTER_CODE));
      return;
    }

    if (isConsumerSelected) {
      goToConsumerReports();
    } else {
      goToDashboard();
    }
  };

  selectedMandate = () => {
    const {mandates} = this.props;

    return mandates.find(m => m.get(mandateFields.ID) === this.state.mandate);
  };

  selectedLegalEntity = () => {
    const selectedMandate = this.selectedMandate();

    return selectedMandate
      ? selectedMandate.get(mandateFields.ORIGINATOR_ENTITY)
      : null;
  };

  isAdminSelected = () => {
    const selectedMandate = this.selectedMandate();

    return selectedMandate && isAdmin(selectedMandate.get(mandateFields.ROLE));
  };

  isConsumerSelected = () => {
    const selectedMandate = this.selectedMandate();

    return (
      selectedMandate && isConsumer(selectedMandate.get(mandateFields.ROLE))
    );
  };

  isRegister = () => {
    const isAdminSelected = this.isAdminSelected();
    const isConsumerSelected = this.isConsumerSelected();
    const selectedLegalEntity = this.selectedLegalEntity();

    return (
      !isAdminSelected &&
      !isConsumerSelected &&
      selectedLegalEntity &&
      [legalEntityStatus.DRAFT, legalEntityStatus.UNREGISTERED].includes(
        selectedLegalEntity.get(legalEntityFields.STATUS)
      )
    );
  };

  isSubmitEnabled = () => {
    const isAdminSelected = this.isAdminSelected();
    const selectedLegalEntity = this.selectedLegalEntity();

    return (
      isAdminSelected ||
      (selectedLegalEntity &&
        selectedLegalEntity.get(legalEntityFields.STATUS) !==
        legalEntityStatus.NEW)
    );
  };

  render() {
    const {t, mandates} = this.props;

    const submitEnabled = this.isSubmitEnabled();
    const isRegister = this.isRegister();

    const filteredMandates = mandates.filter(
      m =>
        m.get(mandateFields.ACTIVE) &&
        m.getIn([mandateFields.ORIGINATOR_ENTITY, legalEntityFields.STATUS]) !==
        legalEntityStatus.INACTIVE
    );

    return (
      <div className="login">
        <h2>{t('selectCompany')}:</h2>
        <form onSubmit={this.onSubmit}>
          <SelectMandate
            value={this.state.mandate}
            onChange={this.onMandateChange}
            hasFeedback={false}
            mandates={filteredMandates.toJS()}
          />
          <FormActions>
            <a className="ant-btn" href={links.oauthLogout}>
              {t('cancelButton')}
            </a>
            <Button htmlType="submit" type="primary" disabled={!submitEnabled}>
              {isRegister ? t('registerBtn') : t('loginBtn')}
            </Button>
          </FormActions>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const userMandates = userSelectors.getUserMandates(state);

  return {
    mandates: getMandatePersons(state, userMandates),
    isMandatesLoading: getLoadingSelector(userSelectors.getUserMandates)(state),
  };
};

const mapDispatchToProps = {
  ...userActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

export const logoutByPostURL = (
  method = 'POST'
) => {
  axios
    .request({
      url: links.apiLogout,
      method
    })
    .then(response => {
      window.location.href = links.eleringEe;
    });
};


