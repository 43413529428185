import {useEffect, useState} from "react";
import React from "react";
import {vdsMessageXMLFetch, vdsMessageXMLSend} from "../../actions/actionHelpers/endpoints";
import {translate} from "react-i18next";
import {NAMESPACES} from "../../i18n";
import {Button, Collapse, notification, Row, Spin} from "antd";

const {Panel} = Collapse;

function VDSMessageModal({t, transactionId, transactionType, vdsMessageType, onClose}) {
  const [vdsMessages, setVDSMessages] = useState([]);
  const [vdsMessagesCopy, setVDSMessagesCopy] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    vdsMessageXMLFetch({transactionId, transactionType, vdsMessageType})
      .then(response => setVDSMessages(response.data) & setVDSMessagesCopy(response.data) & setLoading(false))
      .catch(error => console.error(error) & setLoading(false));
  }, [transactionId, transactionType, vdsMessageType]);

  const handleRequestXml = (e, item, i) => {
    let newVdsMessages = [...vdsMessages];
    newVdsMessages[i] = {...item, requestXml: e.target.value};
    setVDSMessages(newVdsMessages);
  };

  const resetVDSMessages = (i) => {
    let newVdsMessages = [...vdsMessages];
    newVdsMessages[i] = {...vdsMessagesCopy[i]};
    setVDSMessages(newVdsMessages);
  };

  const sendVDSMessages = (data, i) => {
    setLoading(true);
    vdsMessageXMLSend(data.id, data.requestXml)
      .then(response => {
        if (response.data.success) {
          notification.success({
            message: t('vdsResponseSuccessTitle'),
            description: t('vdsResponseSuccessDescription'),
          });
          onClose();
        } else {
          notification.error({
            message: t('vdsResponseErrorTitle'),
            description: t('vdsResponseErrorDescription'),
          });
          let newVdsMessages = [...vdsMessages];
          newVdsMessages[i] = {...response.data};
          setVDSMessages(newVdsMessages);
        }
        setLoading(false);
      })
      .catch(error => console.error(error) & setLoading(false));
  };

  return (
    <Spin spinning={loading} className="search-results-container">
      <Collapse defaultActiveKey={[0]}>
        {vdsMessages.map((item, i) => {
          return (
            <Panel header={t('panelTitle', {id: item.requestId})} key={i}>
              <Row className="global-margin-bottom-10">
                <b>{t('requestField')}: </b>
                <textarea className={'ant-input'} placeholder={'XML-sisu'} rows={12}
                          value={item.requestXml} onChange={(e) => handleRequestXml(e, item, i)}/>
              </Row>
              <Row className="global-margin-bottom-10">
                <b>{t('responseField')}: </b>
                <pre className={'block scroll-block'}>{item.responseXml}</pre>
              </Row>
              <Row className="main-options" type="flex" justify="end">
                <Button type="secondary"
                        onClick={() => resetVDSMessages(i)}>{t('btnReset')}</Button>
                <Button type="primary"
                        onClick={() => sendVDSMessages(item, i)}>{t('btnSend')}</Button>
              </Row>
            </Panel>
          );
        })}
      </Collapse>
    </Spin>
  );
}

export default translate(NAMESPACES.vdsMessageModal)(VDSMessageModal);
