import React, {Component} from 'react';
import {Form} from 'antd';
import ComponentWrapper from './ComponentWrapper';
import mapError from './mapError';

const FormItem = Form.Item;

export default class StaticField extends Component {
  render() {
    return (
      <ComponentWrapper
        {...this.props}
        mapProps={mapError}
        render={props => (
          <FormItem {...props}>
            <span>{props.value}</span>
          </FormItem>
        )}
      />
    );
  }
}
