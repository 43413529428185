import {translate} from "react-i18next";
import {NAMESPACES} from "../../i18n";
import {Button, Col, Row, Spin} from "antd";
import {FormActions} from "../../components";
import {goToClassifiers} from "../../utils/gotoLink";
import React, {useEffect, useState} from "react";
import {
  classifiersAggregatedDataConfirmationChange,
  classifiersAggregatedDataConfirmationFetch
} from "../../actions/actionHelpers/endpoints";
import monthlyAggregatedDataAutoConfirmationCron
  from "../../constants/parameters/monthlyAggregatedDataAutoConfirmationCron";

function ParameterAggregatedDataConfirmationCronEdit({t}) {
  const [classifier, setClassifier] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    classifiersAggregatedDataConfirmationFetch()
      .then(response => setClassifier(response.data) & setLoading(false))
      .catch(error => console.error(error) & setLoading(false));
  }, []);

  function save() {
    setLoading(true);
    classifiersAggregatedDataConfirmationChange({value: classifier.value})
      .then(() => setLoading(false))
      .catch(error => console.error(error) & setLoading(false));
  }

  return (
    <Spin spinning={loading} className="search-results-container">
      <h1>{t('parameterMonthlyAggregatedDataAutoConfirmationCronTitle')}</h1>
      <form>
        <Row gutter={32}>
          <Col span={24}>
            <input type={'text'} className="date-field"
                   name={monthlyAggregatedDataAutoConfirmationCron.VALUE} value={classifier.value}
                   onChange={e => setClassifier({value: e.target.value})}/>
          </Col>
        </Row>
        <FormActions>
          <Button onClick={goToClassifiers}>{t('buttonBack')}</Button>
          <Button type="primary" onClick={save}>{t('buttonSave')}</Button>
        </FormActions>
      </form>
    </Spin>
  );
}

export default translate(NAMESPACES.classifiers)(ParameterAggregatedDataConfirmationCronEdit);
