import React from 'react';
import {TableRowActions} from '../';
import {Button, Spin, Table} from 'antd';
import {goToElectricMeteringPointView} from "../../utils/gotoLink";
import {electricMeteringPointSearchFields} from "../../constants/meteringPoint";

export default function ElectricMeteringPointSearchTable({
                                                           content,
                                                           totalElements,
                                                           loading,
                                                           page,
                                                           pageSize,
                                                           setPage,
                                                           t
                                                         }) {

  const columns = [
    {
      title: t('table.eicz'),
      dataIndex: electricMeteringPointSearchFields.EICZ_CODE
    },
    {
      title: t('table.consumerName'),
      dataIndex: electricMeteringPointSearchFields.CUSTOMER
    },
    {
      title: t('table.loadingOperator'),
      dataIndex: electricMeteringPointSearchFields.LOADING_OPERATOR
    },
    {
      title: t('table.actions'),
      dataIndex: electricMeteringPointSearchFields.ID,
      render: (value) => (
        <TableRowActions>
          <Button
            shape="circle"
            icon="edit"
            size="small"
            type="primary"
            title={t('viewButton')}
            onClick={() => goToElectricMeteringPointView(value)}
          />
        </TableRowActions>
      ),
    },
  ];

  return (
    <Spin spinning={loading}>
      <Table
        rowKey={electricMeteringPointSearchFields.ID}
        dataSource={content}
        columns={columns}
        pagination={{
          onChange: setPage,
          current: page,
          total: totalElements,
          pageSize,
          defaultCurrent: 1
        }}
      />
    </Spin>
  );
}
