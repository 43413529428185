import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from "antd";
import {agreementFields} from "../../constants/agreement";

export class PreTradingAgreementReservationsAgreementDetails extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    agreement: PropTypes.object.isRequired,
  };

  render() {
    const {
      t,
      agreement
    } = this.props;

    return (
      <React.Fragment>
        <h2>{t('agreement.details')}</h2>
        <Row className="global-margin-bottom-10 global-margin-top-24">
          <Col span={6}>
            <b>{t('agreement.agreementId')}: </b>
            {agreement[agreementFields.ID]}
          </Col>
          <Col span={6}>
            <b>{t('agreement.naturalAmountMj')}: </b>
            {
              agreement[agreementFields.AGREEMENT_TYPE] === 'ENTIRE' ?
                t('info.all') :
                agreement[agreementFields.NATURAL_AMOUNT_MJ]
            }
          </Col>
        </Row>

        <Row className="">
          <Col span={6}>
            <b>{t('agreement.counterpartyName')}: </b>
            {agreement[agreementFields.COUNTERPARTY_NAME]}
          </Col>
          <Col span={6}>
            <b>{t('agreement.calculatedAmountMj')}: </b>
            {
              agreement[agreementFields.AGREEMENT_TYPE] === 'ENTIRE' ?
                t('info.all') :
                agreement[agreementFields.CALCULATED_AMOUNT_MJ]
            }
          </Col>
        </Row>

        <h2 className="pt-3">{t('agreement.fulfillmentDetails')}</h2>

        <Row className="pb-3 pt-3">
          <Col span={6}>
            <b>{t('agreement.fulfillmentPercentage')}: </b>
            {agreement[agreementFields.AGREEMENT_TYPE] === 'ENTIRE' ? '-' : agreement[agreementFields.FULFILLED_PERCENTAGE]}
          </Col>
          <Col span={6}>
            <b>{t('agreement.unfulfilledNaturalAmountMj')}: </b>
            {agreement[agreementFields.AGREEMENT_TYPE] === 'ENTIRE' ? '-' : agreement[agreementFields.NATURAL_AMOUNT_MJ] - agreement[agreementFields.FULFILLED_NATURAL_AMOUNT_MJ]}
          </Col>
          <Col span={6}>
            <b>{t('agreement.unfulfilledCalculatedAmountMj')}: </b>
            {agreement[agreementFields.AGREEMENT_TYPE] === 'ENTIRE' ? '-' : agreement[agreementFields.CALCULATED_AMOUNT_MJ] - agreement[agreementFields.FULFILLED_CALCULATED_AMOUNT_MJ]}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}


export default (PreTradingAgreementReservationsAgreementDetails);
