import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {loadingUnitFields, luMeteringPointsFields} from '../../constants/loadingUnit';
import {
  goToLoadingUnitAdd,
  goToLoadingUnitView,
} from '../../utils/gotoLink';
import {TableRowActions, TableActions} from '../';
import {Table, Button, Spin, Popconfirm, message} from 'antd';
import {connect} from 'react-redux';
import {deleteAction} from '../../actions/actionCreators/loadingUnitActionCreator';

export class LoadingUnitSearchTable extends Component {
  static propTypes = {
    loadingUnits: PropTypes.array,
    totalElements: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
    searchLoadingUnits: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    pageSize: PropTypes.number.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    deleteLoadingUnit: PropTypes.func.isRequired,
  };

  onPageChange = page => {
    const {searchLoadingUnits} = this.props;

    searchLoadingUnits(page);
  };
  delete = async id => {
    const {t, deleteLoadingUnit, searchLoadingUnits} = this.props;
    const result = await deleteLoadingUnit(id);
    if (result === "attemptToDeleteElectricUnitFailed") {
      message.error(t('actions.delete.failure'))
    } else if (!result || !result.error) {
      message.success(t('actions.delete.success'));
    }
    searchLoadingUnits(0);
  };
  getColumns = () => {
    const {t, isAdmin} = this.props;

    const columns = [];

    if (isAdmin) {
      columns.push(
        {
          title: t('table.companyName'),
          dataIndex: loadingUnitFields.LEGAL_ENTITY_NAME,
        },
        {
          title: t('table.companyEicxCode'),
          dataIndex: loadingUnitFields.LEGAL_ENTITY_ELECTRICITY_EICX_CODE,
        },
      );
    }

    columns.push(
      {
        title: t('table.name'),
        dataIndex: loadingUnitFields.NAME,
      },
    );

    if (!isAdmin) {
      columns.push(
        {
          title: t('table.marker'),
          dataIndex: loadingUnitFields.MODEL,
        }
      );
    }

    columns.push(
      {
        title: t('table.power'),
        dataIndex: loadingUnitFields.POWER,
      },
      {
        title: t('table.eiczCodes'),
        dataIndex: luMeteringPointsFields.EICZ_CODE,
        render: (value, row) => {
          return row.measurementPoints.map(p => p.eicZ).join(', ');
        },
      },
      {
        title: t('table.status'),
        dataIndex: loadingUnitFields.STATUS,
        render: (value, row) => t(`table.status_${row.status}`),
      },
      {
        title: t('table.actions'),
        dataIndex: loadingUnitFields.ID,
        render: value => (
          <TableRowActions>
            <Button
              shape="circle"
              type="primary"
              icon="edit"
              size="small"
              title={t('table.editBtn')}
              onClick={() => goToLoadingUnitView(value)}
            />
            {isAdmin &&
              <Popconfirm
                title={t('popconfirm.title')}
                onConfirm={() => {
                  this.delete(value);
                }}
                okText={t('popconfirm.buttonYes')}
                cancelText={t('popconfirm.buttonNo')}
              >
                <Button
                  shape="circle"
                  type="primary"
                  icon="delete"
                  size="small"
                  title={t('table.deleteBtn')}
                />
              </Popconfirm>
            }
          </TableRowActions>
        ),
        fixed: 'right',
        width: 100,
      },
    );

    return columns;
  };

  // selectedIdToImport = null;

  render() {
    const {
      loadingUnits,
      totalElements,
      loading,
      page,
      pageSize,
      t,
    } = this.props;

    return (
      <div>
        <TableActions>
          <Button onClick={goToLoadingUnitAdd} type="primary">
            {t('table.addLoadingUnit')}
          </Button>
        </TableActions>
        <div className="search-results-container">
          <Spin spinning={loading}>
            <Table
              locale={{
                emptyText: t('table.noData'),
              }}
              scroll={{x: 1000}}
              rowKey={loadingUnitFields.ID}
              dataSource={loadingUnits}
              columns={this.getColumns()}
              pagination={{
                onChange: this.onPageChange,
                current: page,
                total: totalElements,
                pageSize,
                defaultCurrent: 1,
              }}
            />
          </Spin>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  deleteLoadingUnit: id => dispatch(deleteAction(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LoadingUnitSearchTable);
