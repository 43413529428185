import React from 'react';
import {Layout, Row} from 'antd';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {SearchFilters} from '../../forms';
import {getFormValues} from 'redux-form/immutable';
import {MarketParticipantsSearchResults} from '../../components';
import {
  getLegalEntitiesSearchResults,
  getLegalEntitiesSearchResultsData,
} from '../../reducers/searchLegalEntitiesReducer';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {FORM_SEARCH_FILTERS} from '../../constants/formSearchFilters';
import {
  fetchSearchLegalEntities,
  fetchLoginAsCompanyByAdmin,
} from '../../actions/actionCreators/searchLegalEntitiesActionCreators';

const {Content} = Layout;

class MarketParticipants extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    searchLegalEntities: PropTypes.func.isRequired,
    loginByCompanyAsAdmin: PropTypes.func.isRequired,
    searchResults: PropTypes.object.isRequired,
    searchResultsData: PropTypesImmutable.list.isRequired,
    filterForm: PropTypesImmutable.map,
    loading: PropTypes.bool.isRequired,
    selectUserMandate: PropTypes.func,
  };

  componentDidMount = () => this.props.searchLegalEntities();

  render() {
    const {
      t,
      searchResultsData,
      searchResults,
      searchLegalEntities,
      loginByCompanyAsAdmin,
      filterForm,
      loading,
    } = this.props;

    return (
      <Content>
        <h2 className="main-header">{t('market_participants')}</h2>
        <SearchFilters
          t={t}
          searchLegalEntities={searchLegalEntities}
          filterForm={filterForm}
        />
        <Row>
          <MarketParticipantsSearchResults
            t={t}
            searchLegalEntities={searchLegalEntities}
            searchResults={searchResultsData.toJS()}
            loginByCompanyAsAdmin={loginByCompanyAsAdmin}
            loading={loading}
            {...searchResults.toJS()}
          />
        </Row>
      </Content>
    );
  }
}

const filterFormSelector = getFormValues(FORM_SEARCH_FILTERS);

const mapStateToProps = state => ({
  searchResultsData: getLegalEntitiesSearchResultsData(state),
  searchResults: getLegalEntitiesSearchResults(state),
  loading: getLoadingSelector(getLegalEntitiesSearchResults)(state),
  filterForm: filterFormSelector(state),
});

const mapDispatchToProps = {
  searchLegalEntities: fetchSearchLegalEntities,
  loginByCompanyAsAdmin: fetchLoginAsCompanyByAdmin,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  translate(NAMESPACES.dashboard)(MarketParticipants)
);
