import React, {Component} from 'react';
import {reduxForm, Field} from 'redux-form/immutable';
import {TextField} from '../../atoms';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {Row, Col, Button, Spin, notification} from 'antd';
import {Map} from 'immutable';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormActions} from '../../components';
import {FORM_CLASSIFIERS_EAVP_LOAD_EDIT} from '../../constants/formKeys';
import {
  getClassifiersEAVPLoadChange,
  getClassifiersEAVPLoadItem,
  getClassifiersEAVPLoadItemData,
} from '../../reducers/classifiersReducer';
import * as actions from '../../actions/actionCreators/classifiersActionCreators';
import {eavpLoadDataCron} from '../../constants/parameters';
import {goToClassifiers} from '../../utils/gotoLink';

export class ParameterEAVPLoadCronEdit extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    fetchClassifierEAVPLoad: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  componentDidMount = () => {
    this.props.fetchClassifierEAVPLoad();
  };

  render() {
    const {t, handleSubmit, isLoading} = this.props;

    console.log();

    return (
      <Spin spinning={isLoading}>
        <h1>{t('parameterEAVPLoadCronTitle')}</h1>
        <form onSubmit={handleSubmit}>
          <Row gutter={32}>
            <Col span={24}>
              <Field
                className="date-field"
                name={eavpLoadDataCron.VALUE}
                label={t('fieldEAVPLoad')}
                component={TextField}
              />
            </Col>
          </Row>
          <FormActions>
            <Button onClick={goToClassifiers}>{t('buttonBack')}</Button>
            <Button htmlType="submit" type="primary">
              {t('buttonSave')}
            </Button>
          </FormActions>
        </form>
      </Spin>
    );
  }
}

const onSubmit = async (values, dispatch, ownProps) => {
  await dispatch(actions.changeClassifierEAVPLoad(values.toJS()));

  notification.info({
    message: ownProps.t('completeTitle'),
    description: ownProps.t('completeBody'),
  });
};

const getIsLoading = getLoadingSelector(
  getClassifiersEAVPLoadChange,
  getClassifiersEAVPLoadItem
);

const mapStateToProps = state => ({
  initialValues: Map({
    [eavpLoadDataCron.VALUE]: getClassifiersEAVPLoadItemData(state),
  }),
  isLoading: getIsLoading(state),
});

const mapDispatchToProps = {
  fetchClassifierEAVPLoad: actions.fetchClassifierEAVPLoad,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_CLASSIFIERS_EAVP_LOAD_EDIT,
    onSubmit,
    enableReinitialize: true,
  })(ParameterEAVPLoadCronEdit)
);
