import {createValidation, validatorRequired} from '../../utils/formValidators';
import {
  classifierGeneralItemField,
  classifierGeneralItemLangField,
} from '../../constants/classifier';

export default createValidation([
  {
    name: classifierGeneralItemField.CODE,
    validators: validatorRequired(),
  },
  {
    name: `${classifierGeneralItemField.LANG}.${
      classifierGeneralItemLangField.EN
    }`,
    validators: validatorRequired(),
  },
  {
    name: `${classifierGeneralItemField.LANG}.${
      classifierGeneralItemLangField.ET
    }`,
    validators: validatorRequired(),
  },
]);
