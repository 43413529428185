import React from 'react';
import {Register as RegisterContainer} from '../../containers';
import {Layout} from 'antd';

class Register extends React.Component {
  render() {
    return (
      <Layout className="main-container">
        <Layout.Content>
          <RegisterContainer
            registerCode={this.props.match.params.registerCode}
            isEditMode={false}
          />
        </Layout.Content>
      </Layout>
    );
  }
}

export default Register;
