export const euroFormater = value => (value ? `${value} €` : value);
export const normalFormater = value => (value ? `${value}` : value);

const etNumberFormat = new Intl.NumberFormat('et-EE', {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1
});
export const numberFormatter = value => (value ? etNumberFormat.formatToParts(value).map(({
                                                                                            type,
                                                                                            value
                                                                                          }) => {
  switch (type) {
    case 'decimal':
      return '.';
    default :
      return value;
  }
}).reduce((string, part) => string + part) : value);
