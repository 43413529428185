import React from 'react';
import PropTypes from 'prop-types';
import {Layout} from 'antd';
import {ElectricMeteringPoint} from "../../containers";

const ElectricMeteringPointPage = ({match: {params: {meteringPointId, tabKey}}}) => (
  <Layout className="main-container">
    <ElectricMeteringPoint meteringPointId={meteringPointId} tabKey={tabKey}/>
  </Layout>
);

ElectricMeteringPointPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      meteringPointId: PropTypes.string.isRequired,
      tabKey: PropTypes.string,
    }).isRequired,
  }).isRequired
};


export default ElectricMeteringPointPage;
