import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, DatePicker, Form, Input, Row} from "antd";
import {VDSRequestResultModal} from "../../containers";
import moment from "moment";
import {v4} from 'uuid';

export class VDSTransmissionCancellationForm extends Component {
  static propTypes = {
    form: PropTypes.any,
    onSubmit: PropTypes.func,
    onReset: PropTypes.func,
    children: PropTypes.any,
    request: PropTypes.any,
    response: PropTypes.any,
    data: PropTypes.any
  };

  constructor(props) {
    super(props);

    this.state = {
      validated: false,
      resultsModalShown: false
    };
  }

  componentDidMount() {
    const {data, form} = this.props;
    const {setFieldsValue} = form;
    if (data && data.MessageID) {
      setFieldsValue({
        TransferID: data.MessageID
      });
    }
    this.resetCancelID();
    this.resetCancelTime();
  }

  resetCancelID() {
    const {setFieldsValue} = this.props.form;
    setFieldsValue({
      CancelID: v4()
    });
  }

  resetCancelTime() {
    const {setFieldsValue} = this.props.form;
    setFieldsValue({
      CancelTime: moment()
    });
  }

  submit = (event) => {
    event.preventDefault();
    const {onSubmit, form} = this.props;
    this.setState({validated: true});
    form.validateFields((error, values) => {
      if (!error) {
        onSubmit && onSubmit(values);
      }
    });
  };

  reset = () => {
    const {form, onReset} = this.props;
    const {resetFields} = form;
    resetFields();
    this.resetCancelID();
    this.resetCancelTime();
    this.setState({validated: false});
    onReset && onReset();
  };

  validateStatus = (fieldName) => {
    const {form} = this.props;
    const {getFieldError, isFieldTouched} = form;
    const {validated} = this.state;
    return (validated || isFieldTouched(fieldName)) && getFieldError(fieldName) ? 'error' : '';
  };

  showResult = () => {
    this.setState({resultsModalShown: true});
  };

  hideResult = () => {
    this.setState({resultsModalShown: false});
  };

  render() {
    const {form, children, request, response} = this.props;
    const {getFieldDecorator} = form;
    const {resultsModalShown} = this.state;

    return (
      <Form>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item validateStatus={this.validateStatus('CancelID')} label="CancelID">
              {getFieldDecorator('CancelID', {
                rules: [
                  {required: true, message: 'Sisesta CancelID'},
                  {max: 50, message: 'Maksimaalne pikkus on 50'}
                ]
              })(
                <Input placeholder="CancelID"/>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item validateStatus={this.validateStatus('TransferID')} label="TransferID">
              {getFieldDecorator('TransferID', {
                rules: [
                  {required: true, message: 'Sisesta TransferID'},
                  {max: 50, message: 'Maksimaalne pikkus on 50'}
                ]
              })(
                <Input placeholder="TransferID"/>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item validateStatus={this.validateStatus('CancelTime')} label="CancelTime">
              {getFieldDecorator('CancelTime', {
                rules: [{required: true, message: 'Sisesta CancelTime'}]
              })(
                <DatePicker className={'w-100'} showTime={{format: 'HH:mm'}}
                            format="YYYY-MM-DD HH:mm"
                            placeholder="CancelTime"/>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col className={'form-footer'}>
            <Button type="primary" onClick={this.submit}>{'Saada'}</Button>
            <Button onClick={this.reset}>{'Puhasta'}</Button>
            <Button onClick={this.showResult} disabled={!request}>{'Päringu tulemus'}</Button>
          </Col>
        </Row>
        {children}
        <VDSRequestResultModal request={request} response={response}
                               show={resultsModalShown} hideModal={this.hideResult}/>
      </Form>
    );
  }
}

export default Form.create()(VDSTransmissionCancellationForm);
