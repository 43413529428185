import {
  createValidation,
  validatorDateGreaterThen,
  validatorRequired
} from '../../utils/formValidators';
import {formTsAgreementWithdrawFields} from "../../constants/forms/formTsAgreementWithdraw";
import moment from "moment";

const customValidatorDateGreaterThen = () => (field, form) =>
  form.get(formTsAgreementWithdrawFields.VIOLATION)
    ? validatorDateGreaterThen(moment().date())(field)
    : validatorDateGreaterThen()(field);

export default createValidation([
  {
    name: formTsAgreementWithdrawFields.EFFECTIVE_DATE,
    validators: [validatorRequired(), customValidatorDateGreaterThen()]
  },
  {
    name: formTsAgreementWithdrawFields.REASON,
    validators: validatorRequired(),
  },
]);
