import {createAsyncAction, normalizeHelper} from '../../utils/asyncHelpers';
import {submit, initialize} from 'redux-form';
import {
  METERING_POINT_FETCH,
  METERING_POINT_UPDATE,
  METERING_POINT_AGR_DATA_FETCH,
  CONSUMPTION_METERING_POINT_UPDATE,
  METERING_POINT_ACTIVATION,
  METERING_POINT_REVIEW_FETCH,
  METERING_POINT_GENERATE_GO,
} from '../../constants/meteringPointActionTypes';
import {FORM_ADD_CONSUMER} from '../../constants/formKeys';
import {
  measurement as meteringPointSchema,
  meteringPointReviewSchema,
} from '../../schemas';
import {
  meteringPointUpdate,
  meteringPointFetch,
  meteringPointAgrDataFetch,
  consumptionPointUpdateOrAdd,
  activatePointPut,
  deactivatePointPut,
  meteringReviewFetch,
  pointGenerateGo,
} from '../actionHelpers/endpoints';
import {METERING_POINT_FORM} from '../../constants/formMeteringPoint';
import {consumerFields} from '../../constants/consumer';
import {consumerType} from '../../constants/classificators';
import {showViewMeteringPointConsumerModal} from '../actionCreators/modalsActionCreators';

export const fetchMeteringPoint = createAsyncAction(
  METERING_POINT_FETCH,
  meteringPointFetch,
  normalizeHelper(meteringPointSchema)
);

export const updateMeteringPoint = createAsyncAction(
  METERING_POINT_UPDATE,
  meteringPointUpdate,
  normalizeHelper(meteringPointSchema)
);

export const fetchMeteringPointAgrData = createAsyncAction(
  METERING_POINT_AGR_DATA_FETCH,
  meteringPointAgrDataFetch
);

export const saveMeteringPointForm = () => dispatch => {
  dispatch(submit(METERING_POINT_FORM));
};

export const activateMeteringPoint = createAsyncAction(
  METERING_POINT_ACTIVATION,
  activatePointPut,
  normalizeHelper(meteringPointSchema)
);

export const fetchMeteringPointReview = createAsyncAction(
  METERING_POINT_REVIEW_FETCH,
  meteringReviewFetch,
  normalizeHelper([meteringPointReviewSchema])
);

export const deactivateMeteringPoint = createAsyncAction(
  METERING_POINT_ACTIVATION,
  deactivatePointPut,
  normalizeHelper(meteringPointSchema)
);

export const generateMeteringPointsGOs = createAsyncAction(
  METERING_POINT_GENERATE_GO,
  pointGenerateGo,
  normalizeHelper(meteringPointSchema)
);

export const updateConsumptionPoint = createAsyncAction(
  CONSUMPTION_METERING_POINT_UPDATE,
  consumptionPointUpdateOrAdd,
  normalizeHelper(meteringPointSchema)
);

export const startEditConsumer = values => dispatch => {
  dispatch(
    initialize(FORM_ADD_CONSUMER, {
      ...values,
      [consumerFields.CONSUMER_TYPE]: values[consumerFields.REGISTER_CODE]
        ? consumerType.MANUFACTURER
        : consumerType.CONSUMER,
    })
  );
  dispatch(showViewMeteringPointConsumerModal());
};
