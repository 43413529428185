import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {connect} from 'react-redux';
import {
  getClassifiersItem,
  getClassifiersItemData,
} from '../../reducers/classifiersReducer';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {Spin, Button} from 'antd';
import * as actions from '../../actions/actionCreators/classifiersActionCreators';
import {YearSelect} from '../../atoms';
import {ClassifierViewTable, TableActions} from '../../components';
import {classifierFields} from '../../constants/classifier';
import {goToClassifiers} from '../../utils/gotoLink';
import {
  showClassifierPriceEditModal,
  hideClassifierPriceEditModal,
  getClassifierPriceEditModal,
} from '../../actions/actionCreators/modalsActionCreators';

export class ClassifierView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    classifierId: PropTypes.string.isRequired,
    fetchClassifierItem: PropTypes.func.isRequired,
    classifierData: PropTypesImmutable.map,
    showModal: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    stateModal: PropTypes.bool,
  };

  state = {
    selectedYear: new Date().getFullYear(),
  };

  componentDidMount = () => {
    const {fetchClassifierItem, classifierId} = this.props;

    fetchClassifierItem({classifierId, year: this.state.selectedYear});
  };

  handleYearChange = year => {
    const {fetchClassifierItem, classifierId} = this.props;

    fetchClassifierItem({classifierId, year});

    this.setState({
      selectedYear: year,
    });
  };

  render() {
    const {
      classifierData,
      loading,
      t,
      showModal,
      hideModal,
      stateModal,
      classifierId,
    } = this.props;
    const {selectedYear} = this.state;

    const dataSourceJs = classifierData
      ? classifierData.get(classifierFields.VALUES).toJS()
      : [];

    const name = classifierData
      ? classifierData.get(classifierFields.NAME)
      : '';

    return (
      <Spin spinning={loading}>
        <h1>{name}</h1>
        <div style={{width: '200px', marginBottom: '24px'}}>
          <YearSelect
            beforeCurrent
            onChange={this.handleYearChange}
            value={this.state.selectedYear}
          />
        </div>
        <ClassifierViewTable
          t={t}
          dataSource={dataSourceJs}
          selectedYear={selectedYear}
          stateModal={stateModal}
          showModal={showModal}
          hideModal={hideModal}
          classifierId={classifierId}
        />
        <TableActions>
          <Button onClick={goToClassifiers}>{t('buttonBack')}</Button>
        </TableActions>
      </Spin>
    );
  }
}

const getIsLoading = getLoadingSelector(getClassifiersItem);

const mapStateToProps = state => ({
  loading: getIsLoading(state),
  classifierData: getClassifiersItemData(state),
  stateModal: getClassifierPriceEditModal(state),
});

const mapDispatchToProps = {
  fetchClassifierItem: actions.fetchClassifierItem,
  showModal: showClassifierPriceEditModal,
  hideModal: hideClassifierPriceEditModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClassifierView);
