import {handleActions} from 'redux-actions';
import {combineReducers} from 'redux-immutable';
import {Map} from 'immutable';
import {
  createAsyncReducer,
  initialAsyncState,
  toSuccess,
  createAsyncSelector,
} from '../utils/asyncHelpers';
import {
  createAsyncPagingReducer,
  listInitialAsyncPagingState,
} from '../utils/asyncPagingHelpers';
import {
  PRODUCTION_UNIT_ADD,
  PRODUCTION_UNIT_UPDATE,
  PRODUCTION_UNIT_ENTITY_FETCH,
  PRODUCTION_UNIT_DELETE,
} from '../constants/productionUnitActionTypes';
import {
  toggleAddMode,
  resetMaster,
} from '../actions/pureActions/addEquipmentActions';
import productionUnitSchema from '../schemas/productionUnit';

const list = handleActions(
  {
    ...createAsyncPagingReducer(PRODUCTION_UNIT_ENTITY_FETCH),
    [toSuccess(PRODUCTION_UNIT_ADD)]: (state, {payload}) =>
      state.set('data', state.get('data').concat([payload.result])),
    [toSuccess(PRODUCTION_UNIT_DELETE)]: (state, {payload}) =>
      state.set('data', state.get('data').filter(id => id !== payload)),
  },
  listInitialAsyncPagingState
);
const ui = handleActions(
  {
    [toggleAddMode]: (state, {payload}) => state.set('addMode', payload),
  },
  Map({addMode: false})
);
const legalEntityProductionUnitsReducer = combineReducers({
  list,
  add: handleActions(
    createAsyncReducer(PRODUCTION_UNIT_ADD),
    initialAsyncState
  ),
  edit: handleActions(
    {
      ...createAsyncReducer(PRODUCTION_UNIT_UPDATE),
      [resetMaster]: state => state.merge(initialAsyncState),
    },
    initialAsyncState
  ),
  delete: handleActions(
    createAsyncReducer(PRODUCTION_UNIT_DELETE),
    initialAsyncState
  ),
  ui,
});

export const getProductionUnitList = state =>
  state.getIn(['legalEntityProductionUnits', 'list']);

export const getProductionUnitListEntities = createAsyncSelector(
  [productionUnitSchema],
  getProductionUnitList
);

export const getProductionUnitsAddState = state =>
  state.getIn(['legalEntityProductionUnits', 'add']);

export const getProductionUnitsEditState = state =>
  state.getIn(['legalEntityProductionUnits', 'edit']);

export const getIsAddMode = state =>
  state.getIn(['legalEntityProductionUnits', 'ui', 'addMode']);

export const getProductionUnitsEditId = state =>
  state.getIn(['legalEntityProductionUnits', 'edit', 'data']);

export const getProductionUnitsAddId = state =>
  state.getIn(['legalEntityProductionUnits', 'add', 'data']);

export const getProductionUnitsDeleteState = state =>
  state.getIn(['legalEntityProductionUnits', 'delete']);

export default legalEntityProductionUnitsReducer;
