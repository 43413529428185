import * as actions from '../pureActions/userActions';
import {createAsyncAction, normalizeHelper} from '../../utils/asyncHelpers';
import {goToConsumerReports, goToLogin} from '../../utils/gotoLink';
import * as actionTypes from '../../constants/UserActionTypes';
import mandatePersonSchema from '../../schemas/mandatePerson';
import physicalPersonSchema from '../../schemas/physicalPerson';
import {mandateFields} from '../../constants/mandate';
import {mandateRole} from '../../constants/classificators';
import {getUserMandatesData} from '../../reducers/userReducer';
import {
  userFetch,
  userLogout,
  userMandatesFetch,
  userSelectedMandateFetch,
  userSelectMandate,
} from '../actionHelpers/endpoints';
import {v4} from 'uuid'
import {normalize} from 'normalizr'

export const changeUserRole = role => dispatch =>
  dispatch(actions.userChangeRole(role));

export const fetchCurrentUser = createAsyncAction(
  actionTypes.USER_FETCH,
  userFetch,
  normalizeHelper(physicalPersonSchema)
);

export const fetchCurrentMandate = createAsyncAction(
  actionTypes.USER_MANDATE_FETCH,
  userSelectedMandateFetch,
  data => {
    return normalize({...data, id: v4()}, mandatePersonSchema);
  }
);

export const fetchUserMandates = createAsyncAction(
  actionTypes.USER_MANDATES_FETCH,
  userMandatesFetch,
  normalizeHelper([mandatePersonSchema])
);

export const findConsumerMandate = () => async (dispatch, getState) => {
  await dispatch(fetchUserMandates());

  const allMandates = getUserMandatesData(getState());
  const consumerMandate = allMandates.find(
    m => m.get(mandateFields.ROLE) === mandateRole.CONSUMER
  );

  if (consumerMandate) {
    await dispatch(selectUserMandate(consumerMandate.get(mandateFields.ID)));
  }

  goToConsumerReports();
};

export const fetchUserWithMandates = () => dispatch => {
  dispatch(fetchCurrentUser());
  dispatch(fetchUserMandates());
};

export const selectUserMandate = createAsyncAction(
  actionTypes.USER_MANDATE_SELECT,
  userSelectMandate,
  data => data
);

export const logout = () => async dispatch => {
  await userLogout();
  dispatch(actions.userLogout());
  goToLogin();
};
