import React, {Component} from 'react';
import {reduxForm, Field} from 'redux-form/immutable';
import {TextField} from '../../atoms';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {Row, Col, Button, Spin} from 'antd';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormActions} from '../../components';
import {Link} from 'react-router-dom';
import {FORM_CLASSIFIERS_SUB_EDIT} from '../../constants/formKeys';
import {getClassifierSubChange} from '../../reducers/classifiersReducer';
import * as actions from '../../actions/actionCreators/classifiersActionCreators';
import {
  classifierGeneralItemField,
  classifierGeneralItemLangField,
} from '../../constants/classifier';
import validate from './validateSchema';
import classifiersStore from "../../utils/classifiersStore";

export class ClassifierSubEdit extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isEdit: PropTypes.bool.isRequired,
    classifierCode: PropTypes.string,
  };

  render() {
    const {
      t,
      handleSubmit,
      onCancel,
      isLoading,
      classifierCode,
      classifierSubId,
      selectedCode,
      initialValues,
      isEdit,
    } = this.props;

    let id = initialValues.get(classifierGeneralItemField.ID);

    return (
      <Spin spinning={isLoading}>
        <form onSubmit={handleSubmit}>
          <Row gutter={32}>
            <Col span={24}>
              <Field
                name={classifierGeneralItemField.CODE}
                label={t('fieldCode')}
                component={TextField}
                disabled={isEdit}
              />
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={12}>
              <Field
                name={`${classifierGeneralItemField.LANG}.${
                  classifierGeneralItemLangField.EN
                }`}
                label={t('fieldEn')}
                component={TextField}
              />
            </Col>
            <Col span={12}>
              <Field
                name={`${classifierGeneralItemField.LANG}.${
                  classifierGeneralItemLangField.ET
                }`}
                label={t('fieldEt')}
                component={TextField}
              />
            </Col>
          </Row>
          <FormActions>
            {selectedCode !== null && (
              <Link
                to={{
                  pathname: `/classifiers/${classifierCode}/${classifierSubId}/${initialValues.get("code")}`,
                  data: id
                }}
              >
                <Button>{t('buttonSubValues')}</Button>
              </Link>
            )}
            <Button onClick={onCancel}>{t('buttonCancel')}</Button>
            <Button htmlType="submit" type="primary">
              {t('buttonSave')}
            </Button>
          </FormActions>
        </form>
      </Spin>
    );
  }
}

const onSubmit = async (values, dispatch, ownProps) => {
  const valuesJS = values.toJS();

  await dispatch(
    actions.changeClassifierSub(ownProps.classifierSubId, valuesJS)
  );
  dispatch(actions.fetchClassifierSub(ownProps.classifierSubId));
  dispatch(ownProps.onComplete);

  await classifiersStore.update();
};

const getIsLoading = getLoadingSelector(getClassifierSubChange);

const mapStateToProps = state => ({
  isLoading: getIsLoading(state),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_CLASSIFIERS_SUB_EDIT,
    onSubmit,
    validate,
  })(ClassifierSubEdit)
);
