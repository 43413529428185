import React from 'react';
import PropTypes from 'prop-types';

class EmissionHeatmap extends React.Component {
  static propTypes = {
    heatmapHeight: PropTypes.number,
    heatmapWidth: PropTypes.number,
    pointerPercent: PropTypes.number,
    pointerLineWidth: PropTypes.number,
    pointerWidth: PropTypes.number,
    pointerHeight: PropTypes.number,
    gapLeftRight: PropTypes.number,
    gapTop: PropTypes.number,
  };

  static defaultProps = {
    heatmapHeight: 35,
    heatmapWidth: 350,
    pointerPercent: 1,
    pointerLineWidth: 0.5,
    pointerWidth: 10,
    pointerHeight: 5,
    gapLeftRight: 10,
    gapTop: 10,
  };

  render() {
    const {
      heatmapHeight,
      heatmapWidth,
      pointerPercent,
      pointerLineWidth,
      pointerWidth,
      pointerHeight,
      gapLeftRight,
      gapTop,
    } = this.props;

    const pointerXBase = gapLeftRight + pointerPercent * heatmapWidth;

    const polygon = `
      ${pointerXBase - pointerWidth / 2},${heatmapHeight + pointerHeight}
      ${pointerXBase + pointerWidth / 2},${heatmapHeight + pointerHeight}
      ${pointerXBase + pointerLineWidth},${heatmapHeight}
      ${pointerXBase + pointerLineWidth},0
      ${pointerXBase - pointerLineWidth},0
      ${pointerXBase - pointerLineWidth},${heatmapHeight}
    `;

    return (
      <svg
        width={heatmapWidth + gapLeftRight * 2}
        height={heatmapHeight + gapTop}
      >
        <defs>
          <linearGradient id="gradient">
            <stop offset="5%" stopColor="lime"/>
            <stop offset="50%" stopColor="orange"/>
            <stop offset="95%" stopColor="red"/>
          </linearGradient>
        </defs>

        <rect
          fill="url(#gradient)"
          x={gapLeftRight}
          width={heatmapWidth}
          height={heatmapHeight}
        />
        <polygon points={polygon} style={{fill: 'black'}}/>
      </svg>
    );
  }
}

export default EmissionHeatmap;
