if (!Object.groupBy) {

  Object.groupBy = function (array, keyGetter) {
    return array.reduce((result, item) => {
      const key = keyGetter(item);
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(item);
      return result;
    }, {});
  };
}