import React from 'react';
import {connect} from 'react-redux';
import {Tabs} from 'antd';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import PropTypes from 'prop-types';
import {currentPathSelector} from "../../utils/gotoLink";
import {AccountGOSAuctionsList, TradingPlatformAgreementsList} from "../index";
import AccountGOSAuctionsFilter
  from "../../forms/AccountGOSAuctionsFilter/AccountGOSAuctionsFilter";
import {TradingPlatformAgreementsFilter} from "../../forms";
import classifiersStore from "../../utils/classifiersStore";


const {TabPane} = Tabs;

const KEY = {
  tab1: '1',
  tab2: '2',
};

const PATH_MAPPING = {
  '/tradingPlatform/agreements': KEY.tab1,
  '/tradingPlatform/auctions': KEY.tab2,
}

class TradingPlatform extends React.Component {

  static propTypes = {
    t: PropTypes.func.isRequired,
    currentPath: PropTypes.string.isRequired,
  };

  render() {
    const {
      t,
    } = this.props;

    const isAuctionsEnabled = classifiersStore.getBooleanProperty('IS_AUCTIONS_ENABLED');

    return (
      <div>
        <h1>{t('title_trading_platform')}</h1>
        <Tabs type="card" defaultActiveKey={PATH_MAPPING[this.props.currentPath]}>
          <TabPane tab={t('title_pre_trading_agreements')} key={KEY.tab1}>
            <TradingPlatformAgreementsFilter t={t}>
            </TradingPlatformAgreementsFilter>
            <div className="table-title">{t('title_purchasing_agreements')}</div>
            <TradingPlatformAgreementsList t={t}>
            </TradingPlatformAgreementsList>
          </TabPane>
          {isAuctionsEnabled &&
            <TabPane tab={t('title_auctions')} key={KEY.tab2}>
              <AccountGOSAuctionsFilter t={t}/>
              <AccountGOSAuctionsList t={t}/>
            </TabPane>}
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentPath: currentPathSelector(state),
  }
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(
  translate(NAMESPACES.accountGOS)(TradingPlatform)
);
