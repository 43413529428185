import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form/immutable';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {TextField, ClassificatorField} from '../../atoms';
import {Row, Col} from 'antd';
import {get} from 'lodash';
import {
  FORM_REGISTER_ADDITIONAL,
  formRegisterAdditionalFields,
  formRegisterDocumentsFields,
} from '../../constants/formRegister';
import {RegisterActions, FileUpload} from '../../components';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {updateLegalEntityActionCreator} from '../../actions/actionCreators/registerActionCreators';
import * as documentActions
  from '../../actions/actionCreators/registerEntityDocumnetActionCreators';
import * as documentSelectors from '../../reducers/registerEntityDocumentsReducer';
import {getLegalEntityDocuments} from '../../schemas/legalEntityDocument';
import {FILE_URL} from '../../constants/file';
import validate from './validateSchema';
import './RegisterAdditional.css';

const mapDocumentsToFiles = documents =>
  documents.map(d => ({
    uid: get(d, formRegisterDocumentsFields.ID),
    name: get(d, formRegisterDocumentsFields.NAME),
    status: 'done',
    url: `${FILE_URL}/${get(d, formRegisterDocumentsFields.ID)}`,
  }));

const RegisterAdditionalFields = (props) => {
  const {t, isEditMode, isAdmin} = props;

  return (
    <Row gutter={32}>
      <Col className="gutter-row" span={12}>
        <Field
          label={t('additional.workers_number_label')}
          required
          name={formRegisterAdditionalFields.WORKERS_NUMBER}
          component={ClassificatorField}
          type={ClassificatorField.types.workersAmount}
          placeholder={t('additional.workers_number_placeholder')}
          disabled={isEditMode && !isAdmin}
        />
      </Col>
      <Col className="gutter-row" span={12}>
        <Field
          name={formRegisterAdditionalFields.EMTAK_CODE}
          component={TextField}
          type="text"
          label={t('additional.emtak_code_label')}
          tooltip={t('additional.emtak_code_tooltip')}
          required
          disabled={isEditMode && !isAdmin}
        />
      </Col>
      {(isEditMode || isAdmin) && (
        <Col className="gutter-row" span={12}>
          <Field
            name={formRegisterAdditionalFields.EHAK_CODE}
            component={TextField}
            type="text"
            label={t('additional.ehak_code')}
            tooltip={t('additional.ehak_code_tooltip')}
            required
            disabled={!isAdmin}
          />
        </Col>
      )}
    </Row>
  );
};

class RegisterAdditional extends Component {
  static propTypes = {
    addLegalEntityDocument: PropTypes.func.isRequired,
    fetchLegalEntityDocuments: PropTypes.func.isRequired,
    deleteLegalEntityDocument: PropTypes.func.isRequired,
    legalEntity: PropTypes.object.isRequired,
    documents: PropTypes.array.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    numberOfSteps: PropTypes.number.isRequired,
    showRequestChangeModal: PropTypes.func,
  };

  componentDidMount = () => {
    const {fetchLegalEntityDocuments, legalEntity} = this.props;

    fetchLegalEntityDocuments(legalEntity.get('id'));
  };

  onFileChange = info => {
    const {
      addLegalEntityDocument,
      legalEntity,
      deleteLegalEntityDocument,
    } = this.props;

    if (info.file.status === 'done') {
      addLegalEntityDocument({
        [formRegisterDocumentsFields.FILE]: info.file.response,
        [formRegisterDocumentsFields.LEGAL_ENTITY_ID]: legalEntity.get('id'),
        [formRegisterDocumentsFields.NAME]: info.file.name,
      });
    } else if (info.file.status === 'removed') {
      deleteLegalEntityDocument(info.file.uid);
    }
  };

  render() {
    const {
      handleSubmit,
      hasPrevious,
      hasDone,
      goPrevious,
      t,
      isEditMode,
      documents,
      isLoading,
      isAdmin,
      showRequestChangeModal,
      numberOfSteps
    } = this.props;

    const inEditForNotAdmin = isEditMode && !isAdmin;

    const showFields = numberOfSteps !== 4;

    return (
      <form className="form-register-additional" onSubmit={handleSubmit}>
        <h2 className="form-register-additional__title">
          {inEditForNotAdmin
            ? t('additional.titleView')
            : t('additional.title')}
        </h2>
        {showFields &&
          <RegisterAdditionalFields
            t={t}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
          />
        }
        <Row gutter={32}>
          <Col className="gutter-row" span={12}>
            <h3 className="form-register-additional__document-title">
              {t('additional.document_title')}
            </h3>
            {!isLoading && (
              <FileUpload
                disabled={isEditMode && !isAdmin}
                showUploadList={{showRemoveIcon: !isEditMode || isAdmin}}
                t={t}
                onChange={this.onFileChange}
                defaultFileList={mapDocumentsToFiles(documents)}
              />
            )}
          </Col>
        </Row>
        <RegisterActions
          showRequestChangeModal={showRequestChangeModal}
          hasPrevious={hasPrevious}
          hasDone={hasDone}
          isEditMode={isEditMode}
          isAdmin={isAdmin}
          showSaveButton={isAdmin || !isEditMode}
          goPrevious={goPrevious}
          t={t}
        />
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const entityDocumentsList = documentSelectors.getEntityDocumentsList(state);
  const isLoading = getLoadingSelector(
    documentSelectors.getEntityDocumentsList,
    documentSelectors.getEntityDocumentsAddState
  )(state);
  const initialValues = ownProps.legalEntity;

  return {
    initialValues,
    isLoading,
    documents: getLegalEntityDocuments(state, entityDocumentsList).toJS(),
  };
};

const mapDispatchToProps = {
  ...documentActions,
};

const onSubmit = (values, dispatch) => {
  dispatch(updateLegalEntityActionCreator(values));
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_REGISTER_ADDITIONAL,
    destroyOnUnmount: false,
    enableReinitialize: true,
    forceUnregisterOnUnmount: true,
    validate,
    onSubmit,
  })(RegisterAdditional)
);
