import * as actions from '../pureActions/addEquipmentActions';
import {resetListCertificatesAction} from '../pureActions/productionUnitListActions';

export const goNextActionCreator = () => dispatch =>
  dispatch(actions.goNextStepAction());

export const goPrevActionCreator = () => dispatch =>
  dispatch(actions.goPrevStepAction());

export const resetMasterActionCreator = () => dispatch =>
  dispatch(actions.resetMaster());

export const resetListCertificatesCreator = () => dispatch =>
  dispatch(resetListCertificatesAction());
