import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {connect} from 'react-redux';
import {getYear, getYearData} from '../../reducers/consumerChartReducer';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {Spin} from 'antd';
import * as actions from '../../actions/actionCreators/consumerChartActionCreators';
import {ConsumerReportSection} from '../../components';

export class ConsumerChartYear extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    fetchConsumerChart: PropTypes.func.isRequired,
    reportData: PropTypesImmutable.map,
    isAvp: PropTypes.bool.isRequired,
  };

  componentDidMount = () => {
    const {fetchConsumerChart} = this.props;
    fetchConsumerChart();
  };

  render() {
    const {reportData, loading, t, isAvp} = this.props;

    const data = reportData ? reportData.toJS() : {};

    return (
      <Spin spinning={loading}>
        <ConsumerReportSection
          data={data}
          t={t}
          showProductionCountry
          isFillChangeable
          showLegendValues
          showTotal
          isAvp={isAvp}
        />
      </Spin>
    );
  }
}

const getIsLoading = getLoadingSelector(getYear);

const mapStateToProps = state => ({
  loading: getIsLoading(state),
  reportData: getYearData(state),
});

const mapDispatchToProps = {
  fetchConsumerChart: actions.fetchConsumerChartYear,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsumerChartYear);
