import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Spin} from 'antd';
import {translate} from 'react-i18next';
import {formValueSelector} from 'redux-form/immutable';
import {FORM_ACCOUNT_REPORT_FILTER} from '../../constants/formKeys';
import {formAccountOverviewFilter} from '../../constants/forms/formAccountOverviewFilter';
import {NAMESPACES} from '../../i18n';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {AccountReportTable} from '../../components';
import * as actions from '../../actions/actionCreators/accountActionCreators';
import {
  getReportList,
  getReportListData,
} from '../../reducers/accountOverviewReducer';

export class AccountReport extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    dataList: PropTypesImmutable.list.isRequired,
    pagingData: PropTypesImmutable.map,
    accountId: PropTypes.string,
    fetchAccountReport: PropTypes.func.isRequired,
    filterValues: PropTypes.object.isRequired,
  };

  render() {
    const {
      isLoading,
      t,
      dataList,
      fetchAccountReport,
      filterValues,
    } = this.props;

    return (
      <Spin spinning={isLoading}>
        <AccountReportTable
          t={t}
          dataList={dataList.toJS()}
          fetchAccountReport={fetchAccountReport}
          filterValues={filterValues}
        />
      </Spin>
    );
  }
}

const getLoading = getLoadingSelector(getReportList);
const filterSelector = formValueSelector(FORM_ACCOUNT_REPORT_FILTER);

const mapStateToProps = state => ({
  isLoading: getLoading(state),
  dataList: getReportListData(state),
  filterValues: filterSelector(
    state,
    formAccountOverviewFilter.GROUP_BY_TECHNOLOGY,
    formAccountOverviewFilter.GROUP_BY_FUEL_TYPE,
    formAccountOverviewFilter.GROUP_BY_PRODUCTION_UNIT
  ),
});

const mapDispatchToProps = {fetchAccountReport: actions.fetchAccountReport};

const AccountReportHoc = connect(mapStateToProps, mapDispatchToProps)(
  AccountReport
);

export default AccountReportHoc;

export const AccountReportTranslated = translate(NAMESPACES.account)(
  AccountReportHoc
);
