import {
  NOTIFICATION_COMPANY_CLOSE,
  NOTIFICATION_COUNT_FETCH,
  NOTIFICATION_FETCH,
} from '../constants/notificationActionTypes';
import {
  createAsyncReducer,
  createAsyncSelector,
  initialAsyncState,
  toSuccess,
} from '../utils/asyncHelpers';
import {handleActions} from 'redux-actions';
import {Map} from 'immutable';
import {notification} from '../schemas';

const initialState = Map({
  listNotification: initialAsyncState,
  count: initialAsyncState,
});

const notificationReducer = handleActions(
  {
    ...createAsyncReducer(NOTIFICATION_FETCH, 'listNotification'),
    ...createAsyncReducer(NOTIFICATION_COUNT_FETCH, 'count'),
    [toSuccess(NOTIFICATION_COMPANY_CLOSE)]: (state, {payload}) =>
      state.setIn(
        ['listNotification', 'data'],
        state.getIn(['listNotification', 'data']).filter(id => id !== payload)
      ),
  },
  initialState
);

export const getNotificationList = state =>
  state.getIn(['notification', 'listNotification']);

export const getNotificationListCount = state => {
  return state.getIn(['notification', 'count', 'data', 'count']);
};

export const getNotificationListData = createAsyncSelector(
  [notification],
  getNotificationList
);

export default notificationReducer;
