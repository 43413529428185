import React from 'react';
import RadioGroup from "../../atoms/RadioGroup";
import {Alert, Button, Col, Icon, Row, Spin, Upload} from "antd";
import {getFileTextContent} from "../../utils/files";
import {
  generateBioToVDSXML,
  generateElectricityToVDSXML,
  parseResponse
} from "../../utils/vdsHelpers";
import {
  sendBioFuelReportToVDS,
  sendElectricityReportToVDS
} from "../../actions/actionHelpers/endpoints";
import {VDSRequestResultModal} from "../";

const TYPE_BIO_FUEL = 'BIO_FUEL';
const TYPE_ELECTRICITY = 'ELECTRICITY';

const typeOptions = [{
  value: TYPE_ELECTRICITY,
  name: 'Elekter'
}, {
  value: TYPE_BIO_FUEL,
  name: 'Biokütus'
}];

export class VDSStatisticsSendView extends React.Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    this.state = {
      type: null,
      content: '',
      loading: false,
      uploadError: '',
      sendError: '',
      request: '',
      response: null,
      resultsModalShown: false
    };
  }

  clearMessages() {
    this.setState({uploadError: '', sendError: '', response: null});
  }

  onTypeChange = (event) => {
    this.clearMessages();
    this.setState({type: event.target.value, content: ''});
  };

  onContentChange = (event) => {
    this.clearMessages();
    this.setState({content: event.target.value});
  };

  handleBeforeUpload = (file) => {
    this.clearMessages();
    const {type} = this.state;
    getFileTextContent(file).then((csv) => {
      let content;
      if (type === TYPE_BIO_FUEL) {
        content = generateBioToVDSXML(csv);
      } else {
        content = generateElectricityToVDSXML(csv);
      }
      if (content) {
        this.setState({content});
      } else {
        this.setState({uploadError: 'Faili sisu XML-ile sõelumine ebaõnnestus'});
      }
    }, () => {
      this.setState({uploadError: 'Faili sisu ei saa laadida'});
    });
    return false;
  };

  send = async () => {
    this.clearMessages();
    const {type, content} = this.state;
    this.setState({loading: true, response: null, request: content});
    try {
      let responseObject;
      if (type === TYPE_BIO_FUEL) {
        responseObject = await sendBioFuelReportToVDS({xml: content});
      } else {
        responseObject = await sendElectricityReportToVDS({xml: content});
      }
      const response = parseResponse(responseObject);
      this.setState({
        loading: false,
        sendError: response.error ? 'Viga andmete saatmisel VDS-i' : '',
        response
      });
    } catch (error) {
      console.error(error);
      const response = {error: error.message};
      this.setState({loading: false, sendError: 'Viga andmete saatmisel VDS-i', response});
    }
  };

  clear = () => {
    this.clearMessages();
    this.setState({content: '', type: '', request: '', response: null});
  };

  showResult = () => {
    this.setState({resultsModalShown: true});
  };

  hideResult = () => {
    this.setState({resultsModalShown: false});
  };

  render() {
    const {
      type,
      content,
      loading,
      uploadError,
      sendError,
      response,
      request,
      resultsModalShown
    } = this.state;
    return (
      <div className={'ant-form'}>
        <Spin spinning={loading}>
          <h1>{'Lõpptarbimisse antud biokütuse ja elektriandmete saatmine VDSi'}</h1>
          <Row>
            <Col>
              <RadioGroup options={typeOptions}
                          valueKey={'value'}
                          labelKey={'name'}
                          value={type}
                          onChange={this.onTypeChange}
              />
              <Upload beforeUpload={this.handleBeforeUpload}
                      multiple={false}
                      showUploadList={false}
                      accept={'.csv'}>
                <Button disabled={!type}>
                  <Icon type="upload"/> {'Lae CSV'}
                </Button>
              </Upload>
            </Col>
            {!!uploadError && <Col className={'form-item'}>
              <Alert message={uploadError} type="error" closable/>
            </Col>}
            <Col className={'form-item'}>
              <textarea className={'ant-input'} placeholder={'XML-sisu'} rows={12} value={content}
                        onChange={this.onContentChange}/>
            </Col>
            <Col>
              <Button type="primary" onClick={this.send}
                      disabled={!content || !type}>{'Saada'}</Button>
              <Button onClick={this.clear}>{'Puhasta'}</Button>
              <Button onClick={this.showResult} disabled={!request}>{'Päringu tulemus'}</Button>
            </Col>
          </Row>
          {!sendError && !!response &&
            <Alert message={'Andmed saadeti VDS-ile'} type="success" closable/>}
          {!!sendError && <Alert message={sendError} type="error" closable/>}
        </Spin>
        <VDSRequestResultModal request={request} response={response}
                               show={resultsModalShown} hideModal={this.hideResult}/>
      </div>
    );
  }
}

export default VDSStatisticsSendView;
