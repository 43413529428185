import React, {useEffect, useState} from "react";
import {Button, Modal, notification, Popconfirm, Spin, Table} from "antd";
import {classifierLegalEntityConsumptionTypeField as field} from "../../constants/classifier";
import {TableActions, TableRowActions} from "../index";
import {
  classifiersLegalEntityConsumptionTypeDelete,
  classifiersLegalEntityConsumptionTypeListFetch
} from "../../actions/actionHelpers/endpoints";
import {
  ParameterLegalEntityConsumptionTypeEdit
} from "../../forms/ParameterLegalEntityConsumptionTypeEdit/ParameterLegalEntityConsumptionTypeEdit";

export function ParameterLegalEntityConsumptionTypeTable({t}) {

  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    !loading && setLoading(true);
    classifiersLegalEntityConsumptionTypeListFetch()
      .then(response => setDataSource(response.data) & setLoading(false))
      .catch(error => console.error(error) & setLoading(false));
  };

  const handleDelete = (id) => {
    !loading && setLoading(true);
    classifiersLegalEntityConsumptionTypeDelete(id)
      .then(() => {
        notification.success({
          message: t('tableLegalEntityConsumptionType.deleteSuccessTitle'),
          description: t('tableLegalEntityConsumptionType.deleteSuccessMessage')
        });
        loadData();
      })
      .catch(error => {
        notification.error({
          message: t('tableLegalEntityConsumptionType.deleteErrorTitle'),
          description: error.response.data.message
        });
        setLoading(false);
      });
  };

  const showModal = () => {
    setModalVisible(true);
  };

  const hideModal = () => {
    setModalVisible(false);
  };

  const getColumns = () => {
    return [
      {
        title: t('tableLegalEntityConsumptionType.legalEntityName'),
        dataIndex: field.LEGAL_ENTITY_NAME
      },
      {
        title: t('tableLegalEntityConsumptionType.legalEntityRegistryCode'),
        dataIndex: field.LEGAL_ENTITY_REGISTRY_CODE
      },
      {
        title: t('tableLegalEntityConsumptionType.consumptionTypeCode'),
        dataIndex: field.CONSUMPTION_TYPE_CODE
      },
      {
        title: t('tableLegalEntityConsumptionType.supportRegulationsSum'),
        dataIndex: field.SUPPORT_REGULATIONS_SUM
      },
      {
        title: t('tableGeneral.actions'),
        render: value => (
          <TableRowActions>
            <Button
              shape="circle"
              icon="edit"
              size="small"
              type="primary"
              onClick={() => {
                setSelectedId(value[field.ID]);
                showModal();
              }}
            />
            <span className="ant-divider"/>
            <Popconfirm
              title={t('popconfirm.title')}
              onConfirm={() => handleDelete(value[field.ID])}
              okText={t('popconfirm.buttonYes')}
              cancelText={t('popconfirm.buttonNo')}
            >
              <Button
                shape="circle"
                icon="delete"
                size="small"
                type="primary"
              />
            </Popconfirm>
          </TableRowActions>
        )
      }
    ];
  };

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <h1>{t('parameterLegalEntityConsumptionTypeTitle')}</h1>
        {dataSource && (
          <Table
            rowKey={field.ID}
            dataSource={dataSource}
            columns={getColumns()}
            pagination={false}
          />
        )}
        {modalVisible && (
          <Modal
            title={t('modalTitle')}
            visible
            footer={null}
            onCancel={hideModal}
            width={640}
          >
            <ParameterLegalEntityConsumptionTypeEdit
              onCancel={hideModal}
              selectedId={selectedId}
              onComplete={() => {
                hideModal();
                loadData()
              }}
              isEdit={!!selectedId}
              t={t}
            />
          </Modal>
        )}
        <TableActions>
          <Button
            type="primary"
            onClick={() => {
              setSelectedId(null);
              showModal();
            }}
            className="global-margin-left-10"
          >
            {t('buttonValueAdd')}
          </Button>
        </TableActions>
      </Spin>
    </React.Fragment>
  );
}
