import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {connect} from 'react-redux';
import {
  getClassifiersSellerList,
  getClassifiersSellerListData,
  getClassifiersSellerDelete,
} from '../../reducers/classifiersReducer';
import {
  showParameterSellerEditModal,
  hideParameterSellerEditModal,
  getParameterSellerEditModal,
} from '../../actions/actionCreators/modalsActionCreators';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {Spin, Modal, Button} from 'antd';
import * as actions from '../../actions/actionCreators/classifiersActionCreators';
import {ParameterSellerTable, TableActions} from '../../components';
import {ParameterSellerEdit as ParameterSellerEditForm} from '../../forms';
import {goToClassifiers} from '../../utils/gotoLink';

export class ParameterSellerList extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    fetchClassifier: PropTypes.func.isRequired,
    deleteClassifier: PropTypes.func.isRequired,
    classifierData: PropTypesImmutable.list,
    modalVisible: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    const {fetchClassifier} = this.props;
    fetchClassifier();
  };

  selectedCode = null;

  render() {
    const {
      classifierData,
      loading,
      t,
      deleteClassifier,
      modalVisible,
      hideModal,
      showModal,
    } = this.props;

    return (
      <Spin spinning={loading}>
        <h1>{t('parameterSellerTitle')}</h1>
        {classifierData && (
          <ParameterSellerTable
            t={t}
            dataSource={classifierData.toJS()}
            deleteClassifier={deleteClassifier}
            showModal={code => {
              this.selectedCode = code;
              showModal();
            }}
          />
        )}
        {modalVisible && (
          <Modal
            title={t('modalSellerTitle')}
            visible
            footer={null}
            onCancel={hideModal}
            width={640}
          >
            <ParameterSellerEditForm
              onCancel={hideModal}
              onComplete={hideModal}
              ownCode={this.selectedCode}
              t={t}
            />
          </Modal>
        )}
        <TableActions>
          <Button onClick={goToClassifiers}>{t('buttonBack')}</Button>
          <Button
            type="primary"
            onClick={() => {
              this.selectedCode = null;
              showModal();
            }}
            className="global-margin-left-10"
          >
            {t('buttonValueAdd')}
          </Button>
        </TableActions>
      </Spin>
    );
  }
}

const getIsLoading = getLoadingSelector(
  getClassifiersSellerList,
  getClassifiersSellerDelete
);

const mapStateToProps = state => ({
  loading: getIsLoading(state),
  classifierData: getClassifiersSellerListData(state),
  modalVisible: getParameterSellerEditModal(state),
});

const mapDispatchToProps = dispatch => ({
  fetchClassifier: () => {
    dispatch(actions.fetchClassifierSellerList());
  },
  deleteClassifier: async code => {
    await dispatch(actions.deleteClassifierSeller(code));
    dispatch(actions.fetchClassifierSellerList());
  },
  showModal: () => {
    dispatch(showParameterSellerEditModal());
  },
  hideModal: () => {
    dispatch(hideParameterSellerEditModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  ParameterSellerList
);
