import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import {pick, values, isEmpty, mapValues, flow, filter} from 'lodash/fp';
import {meteringPointFields} from '../../constants/meteringPoint';
import {legalEntityFields} from '../../constants/legalEntity';
import {translateCountyClassificator} from '../../utils/translateHelpers';

const getAddress = flow(
  pick([
    meteringPointFields.ADDRESS,
    meteringPointFields.DISTRICT,
    meteringPointFields.COUNTY,
  ]),
  mapValues.convert({cap: false})(
    (value, key) =>
      key === meteringPointFields.COUNTY
        ? translateCountyClassificator(value)
        : value
  ),
  values,
  filter(v => !isEmpty(v))
);

class MeteringPoint extends PureComponent {
  static propTypes = {
    meteringPoint: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const {t, meteringPoint} = this.props;

    return (
      <div className="global-margin-top-16">
        <div>
          <b>{t('meteringPoint.eiczCode')}: </b>
          {get(meteringPoint, meteringPointFields.EICZ_CODE)}
        </div>
        <div>
          <b>{t('meteringPoint.address')}: </b>
          {getAddress(meteringPoint).join(', ')}
        </div>
        <div>
          <b>{t('meteringPoint.seller')}: </b>
          {get(meteringPoint, [
            meteringPointFields.LEGAL_ENTITY,
            legalEntityFields.NAME,
          ])}
        </div>
      </div>
    );
  }
}

export default MeteringPoint;
