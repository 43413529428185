import {Map} from 'immutable';
import {handleActions} from 'redux-actions';
import * as ActionTypes from '../constants/ModalsActionTypes';

const initialModalsState = Map({});

const modalsReducer = handleActions(
  {
    [ActionTypes.ADD_MODAL]: (state, {payload}) =>
      state.merge({[payload]: true}),
    [ActionTypes.HIDE_MODAL]: (state, {payload}) => state.delete(payload),
    [ActionTypes.HIDE_ALL_MODALS]: state => state.clear(),
  },
  initialModalsState
);

export const getModalState = (state, name) =>
  state.getIn(['modals', name], false);

export default modalsReducer;
