import React, {Component} from 'react';
import {AutoComplete, Input, Icon} from 'antd';
import ComponentWrapper from './ComponentWrapper';
import {autoCompleteFieldMap} from './componentMappers';

export default class AutoCompleteField extends Component {
  render() {
    return (
      <ComponentWrapper
        {...this.props}
        mapProps={autoCompleteFieldMap}
        hasFeedback={false}
        render={props => (
          <AutoComplete {...props}>
            <Input suffix={props.isLoading && <Icon type="loading" spin/>}/>
          </AutoComplete>
        )}
      />
    );
  }
}
