import React, {useState} from 'react';
import {Layout, Tabs} from 'antd';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import {ElectricMeteringPointList} from '../../containers';
import {getIsUserLoadingOperator, getRole} from "../../reducers/userReducer";
import {connect} from "react-redux";
import {isAdmin} from "../../utils/roles";
import {
  ElectricIssuedTSCertificatesAmounts
} from "../../containers/ElectricIssuedTSCertificatesAmounts/ElectricIssuedTSCertificatesAmounts";

function ElectricMeteringPointListPage({
                                         t,
                                         userRole,
                                         isUserLoadingOperator,
                                         match: {params: {tabKey}}
                                       }) {
  const [activeTab, setActiveTab] = useState(tabKey || 'points');

  const isUserAdmin = isAdmin(userRole);

  return (
    <Layout className="main-container">
      <h1>{t('electricityTitle')}</h1>
      <h4>{t('electricitySubtitle')}</h4>

      <Tabs type="card" onChange={setActiveTab} activeKey={activeTab}>
        <Tabs.TabPane key="points" tab={t('electricityTitle')}>
          <ElectricMeteringPointList
            isUserAdmin={isUserAdmin}
            isUserLoadingOperator={isUserLoadingOperator}
            t={t}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="issued" tab={t('issuedAmountsTitle')}>
          <ElectricIssuedTSCertificatesAmounts t={t}/>
        </Tabs.TabPane>
      </Tabs>
    </Layout>
  );
}

const mapStateToProps = state => ({
  userRole: getRole(state),
  isUserLoadingOperator: getIsUserLoadingOperator(state),
});

export default connect(mapStateToProps)(translate(NAMESPACES.meteringPointList)(ElectricMeteringPointListPage));
