import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Layout, Spin} from 'antd';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import {PreTradingAgreementReservationsContainer} from "../../containers";
import {connect} from "react-redux";
import {
  getAccountGOSAvailableStatisticsList,
  getAccountGOSAvailableStatisticsListData
} from "../../reducers/accountGOSAvailableStatisticsReducer";
import {
  resetAccountGOSAvailableStatistics,
  searchAccountGOSAvailableStatistics
} from "../../actions/actionCreators/accountGOSAvailableStatisticsActionCreators";
import {getLoadingSelector} from "../../utils/asyncHelpers";
import * as agreementActions from "../../actions/actionCreators/agreementViewActionCreators";
import {getAgreementData} from "../../reducers/agreementViewReducer";
import PreTradingAgreementReservationsAgreementDetails
  from "../../containers/PreTradingAgreementReservations/PreTradingAgreementReservationsAgreementDetails";

export class PreTradingAgreementReservations extends Component {

  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        agreementId: PropTypes.string.isRequired,
        registerCode: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,
    statistics: PropTypes.array.isRequired,
    isStatisticsLoading: PropTypes.bool.isRequired,
    agreement: PropTypes.object.isRequired,
    isAgreementLoading: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    const {agreementId} = this.props.match.params;
    const {fetchAgreement, fetchStatistics} = this.props;

    fetchAgreement(agreementId);
    fetchStatistics(agreementId);
  }

  componentWillUnmount() {
    const {resetStatistics} = this.props;

    resetStatistics();
  }

  render() {
    const {registerCode} = this.props.match.params;
    const {
      t,
      agreement,
      isAgreementLoading,
      statistics,
      isStatisticsLoading,
      resetStatistics,
      fetchStatistics,
      fetchAgreement
    } = this.props;

    const fetch = () => {
      resetStatistics();
      fetchStatistics(agreement.id);
      fetchAgreement(agreement.id)
    }

    return (
      <Layout className="main-container">
        <h1>{t('title')}</h1>
        {
          isAgreementLoading &&
          <Spin spinning/>
        }
        {
          !isAgreementLoading &&
          <PreTradingAgreementReservationsAgreementDetails
            agreement={agreement}
            t={t}>
          </PreTradingAgreementReservationsAgreementDetails>
        }
        <PreTradingAgreementReservationsContainer
          isStatisticsLoading={isStatisticsLoading}
          registerCode={registerCode}
          statistics={statistics}
          agreement={agreement}
          fetchStatistics={fetch}
          t={t}/>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  statistics: getAccountGOSAvailableStatisticsListData(state),
  isStatisticsLoading: getLoadingSelector(getAccountGOSAvailableStatisticsList)(state),
  agreement: getAgreementData(state).toJS(),
  isAgreementLoading: getLoadingSelector(getAgreementData)(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchStatistics: id => {
    dispatch(searchAccountGOSAvailableStatistics(id));
  },
  resetStatistics: () => {
    dispatch(resetAccountGOSAvailableStatistics());
  },
  fetchAgreement: id => {
    dispatch(agreementActions.fetchAgreement(id));
  },
});

export default translate(NAMESPACES.reservations)(
  connect(mapStateToProps, mapDispatchToProps)(PreTradingAgreementReservations)
);
