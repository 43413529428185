import * as actions from '../pureActions/settingsActions';
import axios from 'axios';
import {createAsyncAction} from "../../utils/asyncHelpers";
import {applicationSettingsFetch} from "../actionHelpers/endpoints";
import {APPLICATION_SETTINGS_FETCH} from "../../constants/actionTypes/settingsActionTypes";

export const changeLocaleActionCreator = locale => dispatch => {
  dispatch(actions.changeLocaleAction(locale));
  axios.defaults.headers.common.lang = locale;
};

export const fetchApplicationSettings = createAsyncAction(APPLICATION_SETTINGS_FETCH, applicationSettingsFetch);
