import React from 'react';
import {searchRelatedConsumers} from '../../actions/actionHelpers/endpoints';
import {AutoCompleteSelect} from '../';

const getDataSource = async value => {
  const {data} = await searchRelatedConsumers(value);
  return data.map(item => ({
    value: item.id,
    label: `${item.name}, ${item.personalCode || item.registrationCode}`,
  }));
};

export default props => (
  <AutoCompleteSelect {...props} getDataSource={getDataSource}/>
);
