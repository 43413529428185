export const SELECT_TS_AUCTION_ROWS = 'SELECT_TS_AUCTION_ROWS';
export const SEND_TO_AUCTION = 'SEND_TO_AUCTION';
export const TS_BID_DETAILS = 'TS_BID_DETAILS';
export const BID_ON_AUCTION = 'BID_ON_AUCTION';
export const CONFIRM_RESULT = 'CONFIRM_RESULT';
export const AUCTION_FETCH = 'FETCH';
export const HISTORY_AUCTIONS_CREATED = 'HISTORY_AUCTIONS_CREATED';
export const HISTORY_AUCTIONS_WON = 'HISTORY_AUCTIONS_WON';
export const HISTORY_AUCTIONS_ENDED = 'HISTORY_AUCTIONS_ENDED';
export const AUCTION_DETAILS = 'AUCTION_DETAILS';
export const STATISTICS_DETAILS = 'STATISTICS_DETAILS';
export const STATISTICS_OVERVIEW_DETAILS = 'STATISTICS_OVERVIEW_DETAILS';
export const AUCTION_DELETE = 'AUCTION_DELETE';