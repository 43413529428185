import _ from "lodash";
import {fuelType as fuelTypeClf} from "../constants/classificators";
import {availableStatisticsTableFields} from "../constants/availableStatisticsTableFields";

export const groupTs = (transportStatistics, fuelType) => {
  const groups = _.groupBy(transportStatistics, groupKey(fuelType))
  return Object.values(groups).map(group => flatten(group));
}

function groupKey(fuelType) {
  switch (fuelType) {
    case fuelTypeClf.BIOMETHANE: {
      return getBioMethaneGroupKey;
    }
    case fuelTypeClf.RENEWABLE_ELECTRICITY: {
      return getRenewableElectricityGroupKey;
    }
    default: {
      return i => (i[availableStatisticsTableFields.ID] || i.id);
    }
  }
}

function getRenewableElectricityGroupKey(item) {
  return item[availableStatisticsTableFields.CONSUMPTION_PROVIDER]
    + item[availableStatisticsTableFields.CONSUMPTION_PROVIDER_TRANSACTION_ID]
    + item[availableStatisticsTableFields.EXPIRATION_DATE]
    + item[availableStatisticsTableFields.CONSUMPTION_YEAR]
    + item[availableStatisticsTableFields.CONSUMPTION_MONTH];
}

function getBioMethaneGroupKey(item) {
  return item[availableStatisticsTableFields.EXPIRATION_DATE]
    + item[availableStatisticsTableFields.PRODUCTION_PERIOD]
    + item[availableStatisticsTableFields.CONSUMPTION_YEAR]
    + item[availableStatisticsTableFields.CONSUMPTION_MONTH]
    + item[availableStatisticsTableFields.PRODUCTION_UNIT_EICW_CODE]
    + item[availableStatisticsTableFields.CONSUMPTION_PROVIDER_TRANSACTION_ID]
    + item[availableStatisticsTableFields.CONSUMPTION_PROVIDER]
    + item[availableStatisticsTableFields.FEEDSTOCK]
    + item[availableStatisticsTableFields.PRODUCTION_PATHWAY]
    + item[availableStatisticsTableFields.LAND_USE_CATEGORY];
}

function flatten(group) {
  if (group.length === 1) {
    const ts = group[0];
    ts[availableStatisticsTableFields.GROUPED_STATISTICS_IDS] = [ts[availableStatisticsTableFields.ID]];
    return ts;
  }
  return group.reduce((tsA, tsB) => merge(tsA, tsB));
}

function merge(tsA, tsB) {
  const naturalAmount = availableStatisticsTableFields.NATURAL_ENERGY_AMOUNT;
  const naturalTransferAmount = availableStatisticsTableFields.NATURAL_ENERGY_TRANSFER_AMOUNT;
  const calculatedAmount = availableStatisticsTableFields.CALCULATED_ENERGY_AMOUNT;
  const calculatedTransferAmount = availableStatisticsTableFields.CALCULATED_ENERGY_TRANSFER_AMOUNT;

  tsA[naturalAmount] += tsB[naturalAmount];
  tsA[naturalTransferAmount] += tsB[naturalTransferAmount];
  tsA[calculatedAmount] += tsB[calculatedAmount];
  tsA[calculatedTransferAmount] += tsB[calculatedTransferAmount];

  if (!tsA[availableStatisticsTableFields.GROUPED_STATISTICS_IDS]) {
    tsA[availableStatisticsTableFields.GROUPED_STATISTICS_IDS] = [tsA[availableStatisticsTableFields.ID] || tsA.id];
  }
  tsA[availableStatisticsTableFields.GROUPED_STATISTICS_IDS].push(tsB[availableStatisticsTableFields.ID] || tsB.id);

  return tsA;
}
