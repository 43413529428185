import React, {Component} from 'react';
import PropTypesImmutable from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {formValueSelector} from 'redux-form/immutable';
import {FORM_TRANSACTION_SEARCH} from '../../constants/formKeys';
import {formTransactionSearchFields} from '../../constants/forms/formTransactionSearch';
import {TransactionTable, TableActions} from '../../components';
import {Button} from 'antd';
import {
  getTransactionList,
  getTransactionListData,
} from '../../reducers/transactionSearchReducer';
import {getRole} from '../../reducers/userReducer';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {downloadFileByPostURL} from '../../utils/files';
import {links} from '../../utils/gotoLink';
import * as actions from '../../actions/actionCreators/transactionSearchActionCreators';

export class TransactionList extends Component {
  static propTypes = {
    transactionsData: PropTypesImmutable.list,
    transactions: PropTypesImmutable.map.isRequired,
    filterValues: PropTypes.object.isRequired,
  };

  handleExportTransactions = () => {
    const {filterValues} = this.props;

    downloadFileByPostURL(
      links.transactionExportCsv,
      filterValues,
      'transaction-report.csv'
    );
  };

  render() {
    const {transactions, transactionsData, ...rest} = this.props;

    return (
      <React.Fragment>
        <TableActions>
          <Button onClick={this.handleExportTransactions}>
            {rest.t('exportCsvBtn')}
          </Button>
        </TableActions>
        <TransactionTable
          transactions={transactionsData.toJS()}
          {...rest}
          {...transactions.toJS()}
        />
      </React.Fragment>
    );
  }
}

const getIsLoading = getLoadingSelector(getTransactionList);

const searchForm = formValueSelector(FORM_TRANSACTION_SEARCH);

const mapStateToProps = state => ({
  transactions: getTransactionList(state),
  loading: getIsLoading(state),
  transactionsData: getTransactionListData(state),
  userRole: getRole(state),
  filterValues: searchForm(
    state,
    ...Object.values(formTransactionSearchFields)
  ),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  searchTransactions: page => {
    dispatch(actions.searchTransactions(page));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionList);
