import {handleActions} from 'redux-actions';
import {
  createAsyncReducer,
  initialAsyncState,
  createAsyncSelector,
} from '../utils/asyncHelpers';
import {productionUnitScheme} from '../schemas';
import {PRODUCTION_UNIT_FETCH} from '../constants/productionUnitActionTypes';

const productionUnitViewReducer = handleActions(
  createAsyncReducer(PRODUCTION_UNIT_FETCH),
  initialAsyncState
);

export default productionUnitViewReducer;

export const getProductionUnit = state => state.get('productionUnitView');

export const getProductionUnitData = createAsyncSelector(
  productionUnitScheme,
  getProductionUnit
);
