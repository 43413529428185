export const tsAgreementRegistrationTableFields = {
  ID: 'id',
  queueNo: 'queueNo',
  fuelType: 'fuelType',
  fuelGeneration: 'fuelGeneration',
  ghgCapacity: 'ghgCapacity',
  agreementType: 'agreementType',
  finalConsumptionYear: 'finalConsumptionYear',
  naturalAmountMj: 'naturalAmountMj',
  multiplier: 'multiplier',
  calculatedEnergyMj: 'calculatedEnergyMj',
  price: 'price',
  transactionCost: 'transactionCost',
};
