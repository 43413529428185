import {createAction, handleActions} from 'redux-actions';
import {createAsyncPagingReducer, listInitialAsyncPagingState} from "../utils/asyncPagingHelpers";
import {
  ELECTRIC_CONSUMPTION_REPORT_ITEMS_FETCH
} from "../constants/actionTypes/electricConsumptionReportActionTypes";

export const ecrResetMaster = createAction('ELECTRIC_CONSUMPTION_REPORT_RESET_MASTER');

const electricConsumptionReportReducer = handleActions(
  {
    ...createAsyncPagingReducer(ELECTRIC_CONSUMPTION_REPORT_ITEMS_FETCH),
    [ecrResetMaster]: state => state.merge(listInitialAsyncPagingState),
  },
  listInitialAsyncPagingState
);

export const getElectricConsumptionReport = state => {
  return state.get('electricConsumptionReport');
};

export default electricConsumptionReportReducer;
