import * as actions from '../pureActions/gosCertificateActions';
import {
  tradingAccountLockPost,
  tradingAccountOverviewDetailsPost,
  tradingAccountTransferPost,
} from '../actionHelpers/endpoints';
import {createAsyncAction} from '../../utils/asyncHelpers';
import {
  SELECT_TRANSFER_ROWS,
  TRANSFER_GOS_CERTIFICATES,
} from '../../constants/actionTypes/transferringCertificatesActionTypes';
import {
  LOCK_GOS_CERTIFICATES,
  SELECT_REPORT_ROWS,
} from '../../constants/actionTypes/lockingGosCertificatesActionTypes';

export const selectReceiver = accountId => dispatch =>
  dispatch(actions.selectReceiver(accountId));

export const tradingAccountTransferAction = createAsyncAction(
  TRANSFER_GOS_CERTIFICATES,
  tradingAccountTransferPost
);

export const tradingAccountLockAction = createAsyncAction(
  LOCK_GOS_CERTIFICATES,
  tradingAccountLockPost
);

export const selectTransferRows = createAsyncAction(
  SELECT_TRANSFER_ROWS,
  tradingAccountOverviewDetailsPost
);

export const selectReportRows = createAsyncAction(
  SELECT_REPORT_ROWS,
  tradingAccountOverviewDetailsPost
);
