import {createAsyncAction} from "../../utils/asyncHelpers";
import {
  DELETE_TS_AGREEMENT,
  CONFIRM_TS_AGREEMENT,
  REGISTER_TS_AGREEMENT,
  WITHDRAW_TS_AGREEMENT,
  FETCH_TS_AGREEMENT_QUEUE_NUM,
  TRANSFER_TRANSPORT_STATISTICS
} from "../../constants/actionTypes/tsAgreementActionTypes";
import {
  tsAgreementDelete,
  tsAgreementConfirm,
  tsAgreementPost,
  tsAgreementWithdraw,
  tsAgreementQueueNumFetch,
  transportStatisticsTransfer
} from "../actionHelpers/endpoints";
import * as actions from "../pureActions/tsAgreementActions";

export const tsAgreementRegistrationAction = createAsyncAction(
  REGISTER_TS_AGREEMENT,
  tsAgreementPost
);

export const tsAgreementWithdrawAction = createAsyncAction(
  WITHDRAW_TS_AGREEMENT,
  tsAgreementWithdraw,
);

export const tsAgreementConfirmAction = createAsyncAction(
  CONFIRM_TS_AGREEMENT,
  tsAgreementConfirm,
)

export const tsAgreementDeleteAction = createAsyncAction(
  DELETE_TS_AGREEMENT,
  tsAgreementDelete,
);

export const tsAgreementQueueNumFetchAction = createAsyncAction(
  FETCH_TS_AGREEMENT_QUEUE_NUM,
  tsAgreementQueueNumFetch
)

export const tsAgreementTransactionAction = createAsyncAction(
  TRANSFER_TRANSPORT_STATISTICS,
  transportStatisticsTransfer
)

export const selectReceiver = accountId => dispatch =>
  dispatch(actions.selectReceiver(accountId));
