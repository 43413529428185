import React, {Component} from 'react';
import PropTypesImmutable from 'react-immutable-proptypes';
import {reduxForm, getFormValues, Field, Fields} from 'redux-form/immutable';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Collapse, Row, Col, Button} from 'antd';
import {getRole} from '../../reducers/userReducer';
import * as actions from '../../actions/actionCreators/accountGOSTransactionsListActionCreators';
import {TextField, DateRangeField, ClassificatorField} from '../../atoms';
import {FormActions} from '../../components';
import {downloadTradingAccountFileByGetURL} from '../../utils/files';
import {links} from '../../utils/gotoLink';
import {
  taTransactionsFilterFields,
  FORM_ACCOUNT_GOS_TRANSACTIONS_FILTER,
} from '../../constants/formAccountGOSTransactionsFilter';
import moment from 'moment';

const {Panel} = Collapse;

export class AccountGOSTransactionsFilter extends Component {
  transactionDateFrom = '';
  transactionDateTo = '';
  transactionStatus = undefined;
  transactionType = undefined;
  type = undefined;

  static propTypes = {
    t: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    filterValues: PropTypesImmutable.map,
    userRole: PropTypes.string.isRequired,
  };

  handleExportTransactions = () => {
    const params = {
      registerCode: this.props.initialValues.get(taTransactionsFilterFields.REGISTER_CODE),
      transactionDateFrom: this.transactionDateFrom,
      transactionDateTo: this.transactionDateTo,
      transactionStatus: this.transactionStatus,
      transactionType: this.transactionType,
      type: this.type
    };

    downloadTradingAccountFileByGetURL(
      links.tradingAccountCsv,
      params,
      'gos-account-transactions.csv'
    );
  };

  updateFilterFields = () => {
    let filterString = this.props.filterValues.toString();

    let filterJson = JSON.parse(
      filterString.substring(filterString.indexOf('{'))
    );

    if (filterJson.transactionDateFrom !== 'undefined')
      this.transactionDateFrom = filterJson.transactionDateFrom;
    if (filterJson.transactionDateTo !== 'undefined')
      this.transactionDateTo = filterJson.transactionDateTo;
    if (filterJson.transactionStatus !== 'undefined')
      this.transactionStatus = filterJson.transactionStatus;
    if (filterJson.transactionType !== 'undefined')
      this.transactionType = filterJson.transactionType;
    if (filterJson.type !== 'undefined') {
      this.type = filterJson.type;
    }
  };

  render() {
    const {t, handleSubmit, reset} = this.props;

    return (
      <React.Fragment>
        <Collapse>
          <Panel key="filters" header={t('transactions.filter.panelTitle')}>
            <form onSubmit={handleSubmit}>
              <Row gutter={24}>
                <Col span={5}>
                  <Field
                    label={t('transactions.filter.transactionType')}
                    name={taTransactionsFilterFields.TRANSACTION_TYPE}
                    component={ClassificatorField}
                    type={ClassificatorField.types.gosTransactionType}
                    hasFeedback={false}
                  />
                </Col>
                <Col span={5}>
                  <Field
                    label={t('transactions.filter.type')}
                    name={taTransactionsFilterFields.TYPE}
                    component={ClassificatorField}
                    type={ClassificatorField.types.tradingAccountTransactionRowType}
                    hasFeedback={false}
                  />
                </Col>
                <Col span={5}>
                  <Field
                    label={t('transactions.filter.transactionStatus')}
                    name={taTransactionsFilterFields.TRANSACTION_STATUS}
                    component={ClassificatorField}
                    type={ClassificatorField.types.transactionForGosStatus}
                    hasFeedback={false}
                  />
                </Col>
                <Col span={9}>
                  <Fields
                    ref="dateRange"
                    label={t('transactions.filter.dateRange.title')}
                    names={[
                      taTransactionsFilterFields.DATE_FROM,
                      taTransactionsFilterFields.DATE_TO,
                    ]}
                    placeholder={[
                      t('transactions.filter.dateRange.dateFromPlaceholder'),
                      t('transactions.filter.dateRange.dateToPlaceholder'),
                    ]}
                    component={DateRangeField}
                    allowClear={false}
                    hasFeedback={false}
                    ranges={{
                      [t('transactions.filter.dateRange.currentMonth')]: [
                        moment().startOf('month'),
                        moment(),
                      ],
                      [t('transactions.filter.dateRange.beginLastMonth')]: [
                        moment()
                          .subtract(1, 'months')
                          .startOf('month'),
                        moment(),
                      ],
                      [t('transactions.filter.dateRange.lastMonth')]: [
                        moment()
                          .subtract(1, 'months')
                          .startOf('month'),
                        moment()
                          .subtract(1, 'months')
                          .endOf('month'),
                      ],
                      [t('transactions.filter.dateRange.currentYear')]: [
                        moment().startOf('year'),
                        moment(),
                      ],
                      [t('transactions.filter.dateRange.lastYear')]: [
                        moment()
                          .subtract(1, 'years')
                          .startOf('year'),
                        moment()
                          .subtract(1, 'years')
                          .endOf('year'),
                      ],
                      [t('transactions.filter.dateRange.last12Months')]: [
                        moment().subtract(12, 'months'),
                        moment(),
                      ],
                    }}
                  />
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <Field
                    name={taTransactionsFilterFields.REGISTER_CODE}
                    component={TextField}
                    hasFeedback={false}
                    type="hidden"
                  />
                </Col>
              </Row>
              <FormActions>
                <Button onClick={reset}>
                  {t('transactions.filter.clearBtn')}
                </Button>
                <Button
                  onClick={this.updateFilterFields}
                  type="primary"
                  htmlType="submit"
                >
                  {t('transactions.filter.submitBtn')}
                </Button>
              </FormActions>
            </form>
          </Panel>
        </Collapse>
        <FormActions>
          <Button onClick={this.handleExportTransactions}>
            {t('exportCsvBtn')}
          </Button>
        </FormActions>
      </React.Fragment>
    );
  }
}

const onSubmit = (values, dispatch) =>
  dispatch(actions.searchAccountGOSTransactions(values));

const getValues = getFormValues(FORM_ACCOUNT_GOS_TRANSACTIONS_FILTER);

const mapStateToProps = state => ({
  filterValues: getValues(state),
  userRole: getRole(state),
});

const mapDispatchToProps = {
  accountGOSTransactions: actions.searchAccountGOSTransactions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: FORM_ACCOUNT_GOS_TRANSACTIONS_FILTER,
    onSubmit,
  })(AccountGOSTransactionsFilter)
);
