import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm} from 'redux-form/immutable';
import {TextField} from '../../atoms';
import {Row, Col} from 'antd';
import {
  FORM_CONTACT_PERSON,
  contactPersonFormFields,
} from '../../constants/formsAddEquipment';
import {RegisterActions} from '../../components';
import validate from './validateSchema';

export class AddEquipmentContact extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hasPrevious: PropTypes.bool.isRequired,
    hasDone: PropTypes.bool.isRequired,
    goPrevious: PropTypes.func.isRequired,
  };

  render() {
    const {handleSubmit, hasPrevious, hasDone, goPrevious, t} = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <h2>{t('contactPerson.title')}</h2>
        <Row gutter={32}>
          <Col span={12}>
            <Field
              label={t('contactPerson.name')}
              name={contactPersonFormFields.NAME}
              component={TextField}
            />
          </Col>
          <Col span={12}>
            <Field
              name={contactPersonFormFields.LAST_NAME}
              component={TextField}
              type="text"
              label={t('contactPerson.lastName')}
            />
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={12}>
            <Field
              name={contactPersonFormFields.PERSONAL_CODE}
              component={TextField}
              type="text"
              label={t('contactPerson.personalCode')}
            />
          </Col>
          <Col span={12}>
            <Field
              name={contactPersonFormFields.EMAIL}
              component={TextField}
              required
              type="text"
              label={t('contactPerson.email')}
            />
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={12}>
            <Field
              name={contactPersonFormFields.PHONE_NUMBER}
              component={TextField}
              required
              type="text"
              label={t('contactPerson.phoneNumber')}
            />
          </Col>
        </Row>
        <RegisterActions
          hasPrevious={hasPrevious}
          hasDone={hasDone}
          goPrevious={goPrevious}
          t={t}
        />
      </form>
    );
  }
}

export default reduxForm({
  form: FORM_CONTACT_PERSON,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
  onSubmit: () => {
  },
})(AddEquipmentContact);
