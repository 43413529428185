import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {connect} from 'react-redux';
import {
  getClassifiersList,
  getClassifiersListData,
} from '../../reducers/classifiersReducer';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {Spin} from 'antd';
import * as actions from '../../actions/actionCreators/classifiersActionCreators';
import {ClassifiersTable} from '../../components';

export class ClassifiersList extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    fetchClassifiers: PropTypes.func.isRequired,
    classifiersData: PropTypesImmutable.list,
  };

  componentDidMount = () => {
    this.props.fetchClassifiers();
  };

  render() {
    const {classifiersData, loading, t} = this.props;

    return (
      <Spin spinning={loading}>
        <ClassifiersTable t={t} dataSource={classifiersData.toJS()}/>
      </Spin>
    );
  }
}

const getIsLoading = getLoadingSelector(getClassifiersList);

const mapStateToProps = state => ({
  loading: getIsLoading(state),
  classifiersData: getClassifiersListData(state),
});

const mapDispatchToProps = {
  fetchClassifiers: actions.fetchClassifiers,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClassifiersList);
