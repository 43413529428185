import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {connect} from 'react-redux';
import {
  getClassifierSubValuesItem,
  getClassifierSubValuesItemData,
  getClassifierGeneralChange,
} from '../../reducers/classifiersReducer';
import {
  showClassifierSubValuesEditModal,
  hideClassifierSubValuesEditModal,
  getClassifierSubValuesEditModal,
} from '../../actions/actionCreators/modalsActionCreators';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {Spin, Modal, Button} from 'antd';
import {
  classifierSubValuesItemField,
  classifierGeneralField,
} from '../../constants/classifier';
import * as actions from '../../actions/actionCreators/classifiersActionCreators';
import {ClassifierSubValuesTable, TableActions} from '../../components';
import {ClassifierSubValuesEdit as ClassifierSubValuesEditForm} from '../../forms';
import {goToClassifierSubView} from '../../utils/gotoLink';

export class ClassifierSubValuesView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    fetchSubValuesClassifier: PropTypes.func.isRequired,
    classifierData: PropTypesImmutable.map,
    classifierValue: PropTypes.string.isRequired,
    modalVisible: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    const {fetchSubValuesClassifier, subId} = this.props;

    fetchSubValuesClassifier(subId);
  };

  selectedCode = null;

  render() {
    const {
      classifierData,
      loading,
      t,
      classifierValue,
      classifierSub,
      deleteClassifier,
      classifier,
      modalVisible,
      hideModal,
      showModal,
    } = this.props;

    let lang = {};
    let rawMaterialProperties = [];
    if (this.selectedCode && classifierData !== null) {
      const itemCode = classifierData
        .get(classifierGeneralField.ITEMS)
        .find(
          item =>
            item.get(classifierSubValuesItemField.CODE) === this.selectedCode
        );
      if (itemCode) {
        lang = itemCode.get(classifierSubValuesItemField.LANG);
        rawMaterialProperties = itemCode.get(classifierSubValuesItemField.RAW_MATERIAL_PROPERTIES);
      }
    }

    return (
      <Spin spinning={loading}>
        {classifierData && (
          <ClassifierSubValuesTable
            t={t}
            dataSource={classifierData.toJS()}
            classifierValue={classifierValue}
            classifierSub={classifierSub}
            deleteClassifier={deleteClassifier}
            showModal={code => {
              this.selectedCode = code;
              showModal();
            }}
          />
        )}
        {modalVisible && (
          <Modal
            title={t('modalTitle')}
            visible
            footer={null}
            onCancel={hideModal}
            width={940}
          >
            <ClassifierSubValuesEditForm
              initialValues={{
                [classifierSubValuesItemField.ACTIVE]: true,
                [classifierSubValuesItemField.CODE]: this.selectedCode,
                [classifierSubValuesItemField.LANG]: lang,
                [classifierSubValuesItemField.RAW_MATERIAL_PROPERTIES]: rawMaterialProperties
              }}
              classifierCode={classifierValue}
              classifierSub={classifierSub}
              subId={localStorage.getItem('subId')}
              onCancel={hideModal}
              onComplete={hideModal}
              isEdit={!!this.selectedCode}
              t={t}
            />
          </Modal>
        )}
        <TableActions>
          <Button
            onClick={() => goToClassifierSubView(classifier, classifierValue)}>{t('buttonBack')}</Button>
          <Button
            type="primary"
            onClick={() => {
              this.selectedCode = null;
              showModal();
            }}
            className="global-margin-left-10"
          >
            {t('buttonValueAdd')}
          </Button>
        </TableActions>
      </Spin>
    );
  }
}

const getIsLoading = getLoadingSelector(
  getClassifierSubValuesItem,
  getClassifierGeneralChange
);

const mapStateToProps = state => ({
  loading: getIsLoading(state),
  classifierData: getClassifierSubValuesItemData(state),
  modalVisible: getClassifierSubValuesEditModal(state),
});

const mapDispatchToProps = dispatch => ({
  fetchSubValuesClassifier: () => {
    dispatch(actions.fetchClassifierSubValues(localStorage.getItem('subId')));
  },
  deleteClassifier: async (code) => {
    await dispatch(actions.deleteClassifierSubValues(localStorage.getItem('subId'), code));
    dispatch(actions.fetchClassifierSubValues(localStorage.getItem('subId')));
  },
  showModal: () => {
    dispatch(showClassifierSubValuesEditModal());
  },
  hideModal: () => {
    dispatch(hideClassifierSubValuesEditModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  ClassifierSubValuesView
);
