import React, {Component} from 'react';
import {reduxForm, Field, formValueSelector} from 'redux-form/immutable';
import {Map} from 'immutable';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {
  DateField,
  ClassificatorField,
  SelectField,
  CheckboxField,
} from '../../atoms';
import {FormActions, TableActions} from '../../components';
import {connect} from 'react-redux';
import {Collapse, Row, Col, Button} from 'antd';
import {FORM_ACCOUNT_OVERVIEW_FILTER} from '../../constants/formKeys';
import {legalEntityFields} from '../../constants/legalEntity';
import {formAccountOverviewFilter} from '../../constants/forms/formAccountOverviewFilter';
import {productionUnitFields} from '../../constants/productionUnit';
import * as actions from '../../actions/actionCreators/accountActionCreators';
import * as productionUnitActions from '../../actions/actionCreators/productionUinitActionCreators';
import {getUserLegalEntity} from '../../reducers/userReducer';
import {getProductionUnitListEntities} from '../../reducers/legalEntityProductionUnitsReducer';
import moment from 'moment';
import {FORMAT_DEFAULT} from '../../utils';
import {links} from '../../utils';
import {downloadFileByPostURL} from '../../utils/files';

const {Panel} = Collapse;

const getInitialValues = accountId =>
  Map({
    [formAccountOverviewFilter.GROUP_BY_EXPIRATION]: true,
    [formAccountOverviewFilter.BALANCE_DATE]: moment().format(FORMAT_DEFAULT),
    accountId,
  });

export class AccountOverviewFilter extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    fetchAccountOverview: PropTypes.func.isRequired,
    fetchLegalEntityProductionUnits: PropTypes.func.isRequired,
    legalEntity: PropTypesImmutable.map.isRequired,
    productionUnitList: PropTypesImmutable.list,
    accountId: PropTypes.string,
    filterValues: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    const {
      fetchLegalEntityProductionUnits,
      legalEntity,
      fetchAccountOverview,
      accountId,
    } = this.props;

    fetchLegalEntityProductionUnits(legalEntity.get(legalEntityFields.ID));
    // console.log(getInitialValues(accountId));
    fetchAccountOverview(getInitialValues(accountId));
  };

  componentDidUpdate = prevProps => {
    const {fetchAccountOverview, accountId, reset} = this.props;

    if (accountId !== prevProps.accountId) {
      reset();
      fetchAccountOverview(getInitialValues(accountId));
    }
  };

  handleExportTransactions = () => {
    const {filterValues} = this.props;

    downloadFileByPostURL(
      links.goSearchExportCsv,
      filterValues,
      'account-overview.csv'
    );
  };

  render() {
    const {t, handleSubmit, productionUnitList, reset} = this.props;

    return (
      <React.Fragment>
        <Collapse defaultActiveKey={['filters']}>
          <Panel key="filters" header={t('filter.title')}>
            <form onSubmit={handleSubmit}>
              <Row gutter={32}>
                <Col span={16}>
                  <Row gutter={32}>
                    <Col span={12}>
                      <Field
                        className="date-field"
                        name={formAccountOverviewFilter.VALID_UNTIL}
                        component={DateField}
                        label={t('overview.filter.validUntil')}
                        hasFeedback={false}
                      />
                    </Col>
                    <Col span={12}>
                      <Field
                        label={t('overview.filter.productionUnit')}
                        name={formAccountOverviewFilter.PRODUCTION_UNIT_ID}
                        component={SelectField}
                        options={productionUnitList.toJS()}
                        valueKey={productionUnitFields.ID}
                        labelKey={productionUnitFields.NAME}
                        hasFeedback={false}
                      />
                    </Col>
                  </Row>
                  <Row gutter={32}>
                    <Col span={12}>
                      <Field
                        label={t('overview.filter.fuelType')}
                        name={formAccountOverviewFilter.FUEL_TYPE}
                        component={ClassificatorField}
                        type={ClassificatorField.types.fuelType}
                        hasFeedback={false}
                      />
                    </Col>
                    <Col span={12}>
                      <Field
                        label={t('overview.filter.cleaningTechnologyType')}
                        name={
                          formAccountOverviewFilter.CLEANING_TECHNOLOGY_TYPE
                        }
                        component={ClassificatorField}
                        type={ClassificatorField.types.cleaningTechnologyType}
                        hasFeedback={false}
                      />
                    </Col>
                  </Row>
                  <Row gutter={32}>
                    <Col span={12}>
                      <Field
                        className="date-field"
                        name={formAccountOverviewFilter.BALANCE_DATE}
                        component={DateField}
                        label={t('overview.filter.balanceDate')}
                        hasFeedback={false}
                        disabled
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <span className="page-AccountOverviewPage__groupByLabel">
                    {t('overview.filter.groupBy')}
                  </span>
                  <div className="page-AccountOverviewPage__groupBy">
                    <Field
                      name={formAccountOverviewFilter.GROUP_BY_TECHNOLOGY}
                      component={CheckboxField}
                      hasFeedback={false}
                      checkboxText={t('overview.filter.groupByTechnology')}
                    />
                    <Field
                      name={formAccountOverviewFilter.GROUP_BY_FUEL_TYPE}
                      component={CheckboxField}
                      hasFeedback={false}
                      checkboxText={t('overview.filter.groupByFuelType')}
                    />
                    <Field
                      name={formAccountOverviewFilter.GROUP_BY_EXPIRATION}
                      component={CheckboxField}
                      hasFeedback={false}
                      checkboxText={t('overview.filter.groupByExpiration')}
                    />
                  </div>
                </Col>
              </Row>
              <FormActions>
                <Button onClick={reset}>{t('filter.clearBtn')}</Button>
                <Button type="primary" htmlType="submit">
                  {t('filter.submitBtn')}
                </Button>
              </FormActions>
            </form>
          </Panel>
        </Collapse>
        <TableActions>
          <Button onClick={this.handleExportTransactions}>
            {t('exportCsvBtn')}
          </Button>
        </TableActions>
        {/*<b>{t('overview.info')}</b>*/}
      </React.Fragment>
    );
  }
}

const filterForm = formValueSelector(FORM_ACCOUNT_OVERVIEW_FILTER);

const mapStateToProps = (state, ownProps) => ({
  legalEntity: getUserLegalEntity(state),
  productionUnitList: getProductionUnitListEntities(state),
  initialValues: getInitialValues(ownProps.accountId),
  filterValues: filterForm(state, ...Object.values(formAccountOverviewFilter)),
});

const onSubmit = (values, dispatch) =>
  dispatch(actions.fetchAccountOverview(values));

const mapDispatchToProps = {
  fetchLegalEntityProductionUnits:
  productionUnitActions.fetchLegalEntityProductionUnits,
  fetchAccountOverview: actions.fetchAccountOverview,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: FORM_ACCOUNT_OVERVIEW_FILTER,
    onSubmit,
  })(AccountOverviewFilter)
);
