import PropTypes from "prop-types";
import React, {Component} from "react";
import {Button, Col, message, Modal, Row} from "antd";

import {change, Field, reduxForm} from "redux-form/immutable";
import {connect} from "react-redux";
import {FORM_AUCTION_RESULT_CONFIRM} from "../../constants/formKeys";
import {FormActions} from "../../components";
import {getAuctionData} from "../../reducers/tsAuctionReducer";
import * as actions from "../../actions/actionCreators/tsAuctionActionCreators";
import {translateTsFuelTypeClassificator as tFuelType} from "../../utils/translateHelpers";
import {CheckboxField} from "../../atoms";
import TextArea from "antd/es/input/TextArea";
import FuelType from "../../constants/classificators/fuelType";

export class TsAuctionResultConfirm extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    assignment: PropTypes.object.isRequired,
    fetchAuction: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    readonly: PropTypes.bool.isRequired,
    goToAuctionDetails: PropTypes.func.isRequired
  }

  state = {
    isAdditionalInfoVisible: false,
    isAccepted: false,
  };

  componentDidMount() {
    const {assignment, fetchAuction, setFormValue} = this.props;

    fetchAuction(assignment.sourceId);
    setFormValue('isAccepted', assignment.result === 'ACCEPTED');
  }

  goToDetails = (id) => {
    const {goToAuctionDetails, hideModal} = this.props;
    hideModal();
    goToAuctionDetails(id);
  }

  render() {
    const {t, assignment, auction, hideModal, handleSubmit, readonly} = this.props;
    const isGhg = FuelType.KHG === auction.fuelType;

    return (
      <form onSubmit={handleSubmit}>
        <Row>
          <Col className="detail-container">
            <div>
              <span>{t('assignments.resultConfirm.auctionId')}: </span>
              <a onClick={() => this.goToDetails(auction.id)}>{auction.id}</a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <span className="text-bold">{t('assignments.resultConfirm.endDate')}: </span>
              <span>{auction.endDate}</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <span className="text-bold">{t('assignments.resultConfirm.createdDate')}: </span>
              <span>{auction.createdDate}</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <span className="text-bold">{t('assignments.resultConfirm.dueDate')}: </span>
              <span>{assignment.dueDate}</span>
            </div>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col>
            <div>
              <span className="text-bold">{t('assignments.resultConfirm.buyerName')}: </span>
              <span>{auction.buyerName}</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <span className="text-bold">{t('assignments.resultConfirm.buyerRegCode')}: </span>
              <span>{auction.buyerRegCode}</span>
            </div>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col>
            <div>
              <span className="text-bold">{t('assignments.resultConfirm.fuelType')}: </span>
              <span>{tFuelType(auction.fuelType)}</span>
            </div>
          </Col>
        </Row>
        {auction.naturalAmountMj !== null &&
          <Row>
            <Col>
              <div>
                <span
                  className="text-bold">{t('assignments.resultConfirm.naturalAmountMj')}: </span>
                <span>{auction.naturalAmountMj?.toFixed(1)}</span>
              </div>
            </Col>
          </Row>}
        {auction.accountedAmountMj !== null &&
          <Row>
            <Col>
              <div>
                <span
                  className="text-bold">{t('assignments.resultConfirm.accountedAmountMj')}: </span>
                <span>{auction.accountedAmountMj?.toFixed(1)}</span>
              </div>
            </Col>
          </Row>
        }
        {auction.ghgAmount !== null &&
          <Row>
            <Col>
              <div>
                <span className="text-bold">{t('assignments.resultConfirm.ghgAmount')}: </span>
                <span>{auction.ghgAmount}</span>
              </div>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <div>
              <span className="text-bold">{t('assignments.resultConfirm.expirationDate')}: </span>
              <span>{auction.expirationDate}</span>
            </div>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col>
            <div>
                <span className="text-bold">
                  {t(isGhg ? 'assignments.resultConfirm.unitPriceGHG' : 'assignments.resultConfirm.unitPrice')}: </span>
              <span>{auction.unitPrice?.toFixed(3)}</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <span className="text-bold">{t('assignments.resultConfirm.transactionPrice')}: </span>
              <span>{auction.transactionPrice?.toFixed(2)}</span>
            </div>
          </Col>
        </Row>
        <br/>
        {auction.additionalInfo &&
          <Row>
            <Col>
              <div>
                <span className="text-bold">{t('assignments.resultConfirm.additionalInfo')}: </span>
                <Button
                  shape="circle"
                  icon="select"
                  size="small"
                  type="primary"
                  title={t('assignments.resultConfirm.additionalInfoTooltip')}
                  onClick={() => this.setState({isAdditionalInfoVisible: true})}
                />
              </div>
            </Col>
          </Row>
        }
        <br/>
        {assignment.completedDate &&
          <Row>
            <Col>
              <div>
                <span className="text-bold">{t('assignments.resultConfirm.completedDate')}: </span>
                <span>{assignment.completedDate}</span>
              </div>
            </Col>
          </Row>
        }
        <br/>
        {(!this.props.readonly || assignment.result !== 'PENDING') &&
          <Row className="checkbox-tooltip">
            <Col>
              <Field
                name={'isAccepted'}
                component={CheckboxField}
                checkboxText={t('assignments.resultConfirm.confirmationText')}
                tooltip={t('assignments.resultConfirm.confirmationTextTooltip')}
                hasFeedback={false}
                onChange={event => this.setState({isAccepted: event.target.checked})}
                disabled={readonly}
              />
            </Col>
          </Row>
        }
        {!this.props.readonly &&
          <FormActions>
            <Button onClick={hideModal}>{t('assignments.resultConfirm.button.back')}</Button>
            {this.state.isAccepted &&
              <Button type="primary"
                      htmlType="submit">{t('assignments.resultConfirm.button.confirm')}</Button>
            }
          </FormActions>
        }
        {this.state.isAdditionalInfoVisible &&
          <Modal
            onCancel={() => this.setState({isAdditionalInfoVisible: false})}
            visible
            footer={false}
            title={t("assignments.resultConfirm.additionalInfoModal.title")}
          >
            <TextArea
              value={auction.additionalInfo}
              readOnly={true}
              autoSize={{minRows: 10, maxRows: 20}}
            />
          </Modal>
        }
      </form>
    );
  }
}

const onSubmit = async (values, dispatch, props) => {
  await dispatch(actions.confirmAction(props.assignment.id, values.toJS().isAccepted));
};

const onSubmitSuccess = (result, dispatch, props) => {
  const {t, hideModal, onSuccess} = props;
  message.success(t('assignments.resultConfirm.message.success'));
  onSuccess();
  hideModal();
};

const mapStateToProps = state => ({
  auction: getAuctionData(state).toJS(),
});

const mapDispatchToProps = (dispatch) => ({
  fetchAuction: id => dispatch(actions.fetch(id)),
  setFormValue: (fieldName, value) => dispatch(change(FORM_AUCTION_RESULT_CONFIRM, fieldName, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_AUCTION_RESULT_CONFIRM,
    onSubmit,
    onSubmitSuccess
  })(TsAuctionResultConfirm)
);
