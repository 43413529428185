import React, {Component} from 'react';
import {Field, reduxForm, reset} from 'redux-form/immutable';
import {connect} from 'react-redux';
import {TextField, DateField} from '../../atoms';
import {Row, Col, Button, Spin} from 'antd';
import {
  FORM_REGISTER_REPRESENTATIVE_PERSON,
  formRegisterRepresentativePersonFields,
} from '../../constants/formRegister';
import {
  addMandatePerson,
  updateMandatePerson,
} from '../../actions/actionCreators/registerActionCreators';
import {
  getAddMandatePerson,
  getUpdateMandatePerson,
} from '../../reducers/registerAddMandateReducer';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {parseNumber} from '../../utils/formHelpers';
import validate from './validateSchema';
import './RegisterRepresentPerson.css';

export class RegisterRepresentPerson extends Component {
  render() {
    const {handleSubmit, t, isLoading, onClose} = this.props;

    return (
      <Spin spinning={isLoading}>
        <form
          className="form-register-representation-person"
          onSubmit={handleSubmit}
        >
          <Row gutter={32}>
            <Col span={12}>
              <Field
                name={formRegisterRepresentativePersonFields.FIRST_NAME}
                component={TextField}
                type="text"
                label={t('representation.first_name_label')}
                required
              />
            </Col>
            <Col span={12}>
              <Field
                name={formRegisterRepresentativePersonFields.LAST_NAME}
                component={TextField}
                type="text"
                label={t('representation.last_name_label')}
                required
              />
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={12}>
              <Field
                name={formRegisterRepresentativePersonFields.PERSONAL_CODE}
                component={TextField}
                type="text"
                label={t('representation.personal_code_label')}
                parse={parseNumber}
                required
              />
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={12}>
              <Field
                name={formRegisterRepresentativePersonFields.MANDATE_DATE_START}
                component={DateField}
                showTime
                label={t('representation.mandate_date_start_label')}
                className="form-register-representation-person__date-field"
                required
              />
            </Col>
            <Col span={12}>
              <Field
                name={formRegisterRepresentativePersonFields.MANDATE_DATE_END}
                component={DateField}
                showTime
                label={t('representation.mandate_date_end_label')}
                className="form-register-representation-person__date-field"
              />
            </Col>
          </Row>
          <div className="component-register-actions">
            <Button
              className="component-register-actions__button-previous"
              size="large"
              onClick={onClose}
            >
              {t('representation.person_cancel')}
            </Button>
            <Button
              className="component-register-actions__button-next"
              size="large"
              type="primary"
              htmlType="submit"
            >
              {t('representation.person_add')}
            </Button>
          </div>
        </form>
      </Spin>
    );
  }
}

const mapStateToProps = state => {
  const isLoading = getLoadingSelector(
    getAddMandatePerson,
    getUpdateMandatePerson
  )(state);

  return {
    isLoading,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClose: () => {
    dispatch(reset(FORM_REGISTER_REPRESENTATIVE_PERSON));
    ownProps.closeModal();
  },
});

const onSubmit = async (data, dispatch, ownProps) => {
  const formData = data.toJS();
  if (formData[formRegisterRepresentativePersonFields.MANDATE_ID]) {
    await dispatch(updateMandatePerson(formData));
  } else {
    await dispatch(addMandatePerson(formData));
  }
  dispatch(reset(FORM_REGISTER_REPRESENTATIVE_PERSON));
  ownProps.closeModal();
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_REGISTER_REPRESENTATIVE_PERSON,
    validate,
    onSubmit,
  })(RegisterRepresentPerson)
);
