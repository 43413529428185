import {createValidation, validatorRequired} from '../../utils/formValidators';

import {meteringPointFields} from '../../constants/meteringPoint';

const validatorRequiredForNotAvpAndAdmin = (value, form, props) =>
  !props.isAvp && props.isAdmin ? validatorRequired()(value) : undefined;

const validatorRequiredForNotAvp = (value, form, props) =>
  !props.isAvp ? validatorRequired()(value) : undefined;

export default createValidation([
  {
    name: meteringPointFields.EICZ_CODE,
    validators: validatorRequiredForNotAvpAndAdmin,
  },
  {
    name: meteringPointFields.COUNTY,
    validators: validatorRequiredForNotAvpAndAdmin,
  },
  {
    name: meteringPointFields.CITY,
    validators: validatorRequiredForNotAvpAndAdmin,
  },
  {
    name: meteringPointFields.ZIP_CODE,
    validators: validatorRequiredForNotAvpAndAdmin,
  },
  {
    name: meteringPointFields.DISTRICT,
    validators: validatorRequiredForNotAvpAndAdmin,
  },
  {
    name: meteringPointFields.START_DATE,
    validators: validatorRequiredForNotAvp,
  },
  {
    name: meteringPointFields.AUTOMATIC_CANCELLATION,
    validators: validatorRequired(),
  },
  {
    name: meteringPointFields.CONSUMPTION_TYPE,
    validators: validatorRequired(),
  },
]);
