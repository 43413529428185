import React, {Component} from 'react';
import {Table, Button, Popconfirm} from 'antd';
import {formRegisterRepresentativeCompanyFields} from '../../constants/formRegister';
import {tCommon} from '../../i18n';
import _ from 'lodash';
import {getDateFromDateTime} from '../../utils';

class MandateCompaniesTable extends Component {
  static propTypes = {};

  getColumns = (t, editRepresent, deleteRepresent) => [
    {
      title: t('representation.mandateCompanyTitleName'),
      dataIndex: `legalEntity.${
        formRegisterRepresentativeCompanyFields.COMPANY_NAME
      }`,
    },
    {
      title: t('representation.mandateCompanyTitleRegistryCode'),
      dataIndex: `legalEntity.${
        formRegisterRepresentativeCompanyFields.REGISTRATION_CODE
      }`,
    },
    {
      title: t('representation.mandateCompanyTitleStartTime'),
      dataIndex: formRegisterRepresentativeCompanyFields.MANDATE_DATE_START,
      render: dateTime => getDateFromDateTime(dateTime),
    },
    {
      title: t('representation.mandateCompanyTitleEndTime'),
      dataIndex: formRegisterRepresentativeCompanyFields.MANDATE_DATE_END,
      render: dateTime => getDateFromDateTime(dateTime),
    },
    {
      title: t('representation.mandateCompanyTitleActions'),
      render: (value, values) => (
        <span>
          <Button onClick={() => editRepresent(this.normalizeValues(values))}>
            {t('representation.mandateCompanyButtonEdit')}
          </Button>
          <span className="ant-divider"/>
          <Popconfirm
            title={tCommon('popconfirm.title')}
            onConfirm={() => deleteRepresent(values.id)}
            okText={tCommon('popconfirm.buttonYes')}
            cancelText={tCommon('popconfirm.buttonNo')}
          >
            <Button>{t('representation.mandateCompanyButtonDelete')}</Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  normalizeValues = values => {
    const allowed = _.values(formRegisterRepresentativeCompanyFields);
    const filterValues = _.keys(values)
      .filter(key => allowed.includes(key))
      .reduce((obj, key) => {
        obj[key] = values[key];
        return obj;
      }, {});

    return {
      ...filterValues,
      name: values.legalEntity.name,
      registryCode: values.legalEntity.registrationCode,
      mandateId: values.id,
    };
  };

  render() {
    const {data, t, editRepresent, deleteRepresent} = this.props;

    return (
      <Table
        columns={this.getColumns(t, editRepresent, deleteRepresent)}
        dataSource={data}
        bordered
        pagination={false}
      />
    );
  }
}

export default MandateCompaniesTable;
