export const formGoReportFields = {
  MONTH: 'month',
  YEAR: 'year',
  DATE_FROM: 'startPeriod',
  DATE_TO: 'endPeriod',
  LEGAL_ENTITY_ID: 'legalEntityId',
  TRANSACTION_ID: 'transactionId',

  PAGE: 'page',
  PAGE_SIZE: 'size'
};
