import React, {Component} from 'react';
import {reduxForm, Field} from 'redux-form/immutable';
import {NumberField} from '../../atoms';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {Row, Col, Button, Spin, Input} from 'antd';
import {Map} from 'immutable';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormActions} from '../../components';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import {FORM_CLASSIFIERS_PRICE_EDIT} from '../../constants/formKeys';
import {getClassifiersPriceChange} from '../../reducers/classifiersReducer';
import * as actions from '../../actions/actionCreators/classifiersActionCreators';
import {classifierViewFields, classifierFields} from '../../constants/classifier';
import {translateMonthClassificator} from '../../utils/translateHelpers';
import validate from './validateSchema';

export class ClassifierPriceEdit extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    selectedYear: PropTypes.number.isRequired,
    selectedMonth: PropTypes.number.isRequired,
    selectedPrice: PropTypes.number.isRequired,
  };

  render() {
    const {
      t,
      handleSubmit,
      onCancel,
      isLoading,
      selectedYear,
      selectedMonth,
    } = this.props;

    return (
      <Spin spinning={isLoading}>
        <form onSubmit={handleSubmit}>
          <Row gutter={32}>
            <Col span={12}>
              <div>{t('fieldYear')}:</div>
              <Input
                className="global-margin-top-10"
                value={selectedYear}
                disabled
              />
            </Col>
            <Col span={12}>
              {t('fieldMonth')}:{' '}
              <Input
                className="global-margin-top-10"
                value={translateMonthClassificator(selectedMonth.toString())}
                disabled
              />
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={24}>
              <Field
                className="date-field"
                name={classifierViewFields.PRICE}
                label={t('fieldPrice')}
                component={NumberField}
                isFloat
                precision={2}
                min={0.01}
                step={0.01}
              />
            </Col>
          </Row>
          <FormActions>
            <Button onClick={onCancel}>{t('buttonCancel')}</Button>
            <Button htmlType="submit" type="primary">
              {t('buttonSave')}
            </Button>
          </FormActions>
        </form>
      </Spin>
    );
  }
}

const onSubmit = async (values, dispatch, ownProps) => {
  await dispatch(actions.changeClassifierPrice(values.toJS()));
  dispatch(ownProps.onComplete);
};

const getIsLoading = getLoadingSelector(getClassifiersPriceChange);

const mapStateToProps = (state, ownProps) => ({
  initialValues: Map({
    [classifierViewFields.PRICE]: ownProps.selectedPrice,
    [classifierFields.ID]: ownProps.classifierId,
  }),
  isLoading: getIsLoading(state),
});

export default translate(NAMESPACES.classifiers)(
  connect(mapStateToProps)(
    reduxForm({
      form: FORM_CLASSIFIERS_PRICE_EDIT,
      onSubmit,
      validate,
    })(ClassifierPriceEdit)
  )
);
