import React, {Component} from 'react';
import {Field, reduxForm, SubmissionError} from 'redux-form/immutable';
import {isEmpty, isString} from 'lodash';
import {TextField} from '../../atoms';
import {Row, Col, Button, Collapse, message} from 'antd';
import {ClassificatorField} from '../../atoms/reduxForms';
import legalEntityStatus from '../../constants/classificators/legalEntityStatus';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {
  FORM_SEARCH_FILTERS,
  searchFormFields,
} from '../../constants/formSearchFilters';
import {TableActions} from '../../components';
import {links, goToRegisterByAdmin} from '../../utils/gotoLink';
import {downloadFileByPostURL} from '../../utils/files';
import './SearchFilters.css';

const {Panel} = Collapse;

export class SearchFilters extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    searchLegalEntities: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    filterForm: PropTypesImmutable.map,
    submit: PropTypes.func.isRequired,
  };

  onClear = () => {
    const {searchLegalEntities, reset} = this.props;

    reset();
    searchLegalEntities();
  };

  handleExportTransactions = () => {
    const {filterForm} = this.props;

    downloadFileByPostURL(
      links.legalEntityExportCsv,
      filterForm ? filterForm.toJS() : {},
      'market-participants.csv'
    );
  };

  render() {
    const {handleSubmit, t} = this.props;

    return (
      <div className="search-form-container">
        <Collapse>
          <Panel header={`${t('search')} - ${t('market_participants')}`}>
            <div className="dropdown-search-form">
              <form onSubmit={handleSubmit}>
                <Row gutter={50}>
                  <Col span={8}>
                    <Field
                      name={searchFormFields.COMPANY_NAME}
                      component={TextField}
                      type="text"
                      label={t('company_name')}
                      hasFeedback={false}
                    />
                  </Col>
                  <Col span={8}>
                    <Field
                      name={searchFormFields.REGISTER_CODE}
                      component={TextField}
                      type="text"
                      label={t('registry_code')}
                      hasFeedback={false}
                    />
                  </Col>
                  <Col span={8}>
                    <Field
                      name={searchFormFields.EICX_CODE}
                      component={TextField}
                      type="text"
                      label={t('eicx_code')}
                      hasFeedback={false}
                    />
                  </Col>
                </Row>
                <Row gutter={50}>
                  <Col span={8}>
                    <Field
                      label={t('role')}
                      name={searchFormFields.COMPANY_ROLE}
                      component={ClassificatorField}
                      type={ClassificatorField.types.legalEntityType}
                      hasFeedback={false}
                    />
                  </Col>
                  <Col span={8}>
                    <Field
                      allowed={[
                        legalEntityStatus.ACTIVE,
                        legalEntityStatus.INACTIVE,
                        legalEntityStatus.NEW,
                      ]}
                      label={t('status')}
                      name={searchFormFields.COMPANY_STATUS}
                      component={ClassificatorField}
                      type={ClassificatorField.types.legalEntityStatus}
                      hasFeedback={false}
                    />
                  </Col>
                  <Col span={8}/>
                </Row>
                <div className="main-options">
                  <Row type="flex" justify="end">
                    <Button onClick={this.onClear} type="primary" ghost>
                      {t('cancel')}
                    </Button>
                    <Button onClick={handleSubmit} type="primary">
                      {t('submit')}
                    </Button>
                  </Row>
                </div>
              </form>
            </div>
          </Panel>
        </Collapse>
        <TableActions>
          <Button onClick={this.handleExportTransactions}>
            {t('exportCsvBtn')}
          </Button>
          <Button
            className="global-margin-left-10"
            type="primary"
            onClick={goToRegisterByAdmin}
          >
            {t('add_client')}
          </Button>
        </TableActions>
      </div>
    );
  }
}

const onSubmit = (values, dispatch, ownProps) => {
  if (values.every(value => isEmpty(value))) {
    throw new SubmissionError(ownProps.t('shouldFillAnyFilter'));
  }
  ownProps.searchLegalEntities(values);
};

const onSubmitFail = errors => {
  if (isString(errors)) {
    message.error(errors);
  }
};

export default reduxForm({
  form: FORM_SEARCH_FILTERS,
  onSubmit,
  onSubmitFail,
})(SearchFilters);
