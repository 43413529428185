import {handleActions} from 'redux-actions';
import {selectOwner} from '../actions/pureActions/productionUnitAddActions';
import {Map} from 'immutable';

export default handleActions(
  {
    [selectOwner]: (state, {payload}) => state.set('owner', payload),
  },
  Map({owner: null})
);

export const getOwner = state => state.getIn(['productionUnitAdd', 'owner']);
