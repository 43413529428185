import {
  CONSUMER_CHART_SELLER_FETCH,
  CONSUMER_CHART_YEAR_FETCH,
  CONSUMER_SELLERS_FETCH,
} from '../constants/actionTypes/consumerChart';
import {
  createAsyncReducer,
  initialAsyncState,
  listInitialAsyncState,
} from '../utils/asyncHelpers';
import {handleActions} from 'redux-actions';
import {Map} from 'immutable';

const initialState = Map({
  year: initialAsyncState,
  seller: initialAsyncState,
  sellers: listInitialAsyncState,
});

const consumerChartReducer = handleActions(
  {
    ...createAsyncReducer(CONSUMER_CHART_YEAR_FETCH, 'year'),
    ...createAsyncReducer(CONSUMER_CHART_SELLER_FETCH, 'seller'),
    ...createAsyncReducer(CONSUMER_SELLERS_FETCH, 'sellers'),
  },
  initialState
);

export const getYear = state => state.getIn(['consumerChart', 'year']);

export const getYearData = state =>
  state.getIn(['consumerChart', 'year', 'data']);

export const getSeller = state => state.getIn(['consumerChart', 'seller']);

export const getSellerData = state =>
  state.getIn(['consumerChart', 'seller', 'data']);

export const getSellers = state => state.getIn(['consumerChart', 'sellers']);

export const getSellersData = state =>
  state.getIn(['consumerChart', 'sellers', 'data']);

export default consumerChartReducer;
