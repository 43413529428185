import React from 'react';
import PropTypes from 'prop-types';
import {Timeline} from 'antd';
import {
  transactionFields,
  transactionHistoryFields,
} from '../../constants/transaction';
import transactionStatus from '../../constants/classificators/transactionStatus';
import {translateTransactionStatusClassificator} from '../../utils/translateHelpers';

class TransactionHistory extends React.Component {
  static propTypes = {
    info: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  defineColorFromStatus = status => {
    if (status === transactionStatus.WAITING) {
      return '#1890ff';
    } else if (status === transactionStatus.FAILED) {
      return 'red';
    } else if (status === transactionStatus.SUCCEEDED) {
      return 'green';
    } else if (status === transactionStatus.REVOKED) {
      return 'orange';
    }

    return 'grey';
  };

  render() {
    const {info} = this.props;

    const historyInfo = info[transactionFields.HISTORY];

    return (
      <Timeline>
        {historyInfo.map(historyItem => {
          const transactionOb =
            historyItem[transactionHistoryFields.TRANSACTION];
          const transTime = transactionOb
            ? transactionOb[transactionFields.CREATE_TIME]
            : historyItem[transactionHistoryFields.END_TIME];
          return (
            <Timeline.Item
              color={this.defineColorFromStatus(
                historyItem[transactionHistoryFields.STATUS]
              )}
              key={historyItem[transactionHistoryFields.ID]}
            >
              {transTime} {' '}
              [{' '}
              {translateTransactionStatusClassificator(
                historyItem[transactionHistoryFields.STATUS]
              )}{' '}
              ]
            </Timeline.Item>
          );
        })}
      </Timeline>
    );
  }
}

export default TransactionHistory;
