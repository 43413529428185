export const invoiceFields = {
  ID: 'id',
  NUMBER: 'number',
  MONTH: 'month',
  YEAR: 'year',
  DATE: 'date',
  REFERENCE_NUMBER: 'referenceNumber',
  GO_AMOUNT: 'goAmount',
  SUM_WITH_VAT: 'sumWithVAT',
  SUM_WITHOUT_VAT: 'sumWithoutVAT',
  STATUS: 'status',
  PRESENTER: 'presenter',
  REGENERATED: 'regenerated',
};

export const invoiceViewFields = {
  PRESENTER_INFO: 'presenterInfo',
  PRESENTER_CONTACT: 'invoicePresenterContacts',
  PRESENTER_REGISTER_INFO: 'invoicePresenterRegistryInfo',
  INFO: 'invoiceInformation',
  BANK_DETAILS: 'receiverBankDetails',
  ROW: 'invoiceRowsInformation',
  HISTORY: 'invoiceHistory',
  PRESENTER_INVOICE: 'presenterInvoice',
};

export const invoiceViewPresenterInvoice = {
  NAME: 'fileName',
  PATH: 'filePath',
};

export const invoiceViewPresenterInfoFields = {
  NAME: 'name',
  REGISTER_CODE: 'registrationCode',
  KMKR_CODE: 'kmkrCode',
  EICX_CODE: 'eicxCode',
  FORM: 'form',
};

export const invoiceViewPresenterContactFields = {
  ADDRESS: 'address',
  COUNTY: 'city',
  ZIP_CODE: 'zip',
  COUNTRY: 'country',
  PHONE: 'phoneNumber',
  EMAIL: 'email',
};

export const invoiceViewPresenterRegisterInfoFields = {
  EMTAK_CODE: 'emtakCode',
  EHAK_CODE: 'ehakCode',
  EMPLOYEES: 'numberOfEmployees',
};

export const invoiceViewInfoFields = {
  TYPE: 'invoiceType',
  NUMBER: 'invoiceNumber',
  SUBMISSION_DATE: 'dateOfSubmission',
  INVOICE_DATE: 'invoiceDate',
  SUBSIDY_PERIOD_YEAR: 'subsidyPeriodYear',
  SUBSIDY_PERIOD_MONTH: 'subsidyPeriodMonth',
  EICW_CODE: 'eicwCode',
};

export const invoiceViewBankDetailsFields = {
  NAME: 'name',
  REGISTER_CODE: 'registrationCode',
  KMKR_CODE: 'kmkrCode',
  ACCOUNT_NUMBER: 'bankAccount',
};

export const invoiceRowFields = {
  TOTAL_WITH_VAP: 'totalWithVAT',
  TOTAL_WITHOUT_VAP: 'totalWithoutVAT',
  VAP: 'vat',
  LINES: 'invoiceRows',
};

export const invoiceRowTableFields = {
  GO_AMOUNT: 'goAmount',
  UNIT: 'unit',
  SUM: 'sum',
};

export const invoiceHistoryFields = {
  DATE: 'time',
  STATUS: 'invoiceStatus',
  NAME: 'name',
  DECISION: 'decisionNumber',
};
