import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  classifierGeneralField,
  classifierGeneralItemField,
  classifierGeneralItemLangField,
  classifierConsumptionUsageProperties,
} from '../../constants/classifier';
import {Table, Button, Popconfirm} from 'antd';
import {TableRowActions} from '../';
import classificatorTypes from '../../constants/classificators';
import {translateConsumptionUsagePeriodClassificator} from '../../utils/translateHelpers';

class ClassifierSubTable extends Component {
  static propTypes = {
    dataSource: PropTypes.object,
    t: PropTypes.func.isRequired,
    classifierId: PropTypes.string.isRequired,
    changeClassifier: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
  };

  getAdditionalColumns = () => {
    const {t, classifierId} = this.props;

    if (classifierId === classificatorTypes.consumptionUsage) {
      return [
        {
          title: t('tableGeneral.additional.dateStart'),
          dataIndex: `${classifierGeneralItemField.PROPERTIES}.${
            classifierConsumptionUsageProperties.START_DAY
          }`,
        },
        {
          title: t('tableGeneral.additional.dateEnd'),
          dataIndex: `${classifierGeneralItemField.PROPERTIES}.${
            classifierConsumptionUsageProperties.END_DAY
          }`,
        },
        {
          title: t('tableGeneral.additional.supportRegulation'),
          dataIndex: `${classifierGeneralItemField.PROPERTIES}.${
            classifierConsumptionUsageProperties.SUPPORT_REGULATION
          }`,
        },
        {
          title: t('tableGeneral.additional.period'),
          dataIndex: `${classifierGeneralItemField.PROPERTIES}.${
            classifierConsumptionUsageProperties.PERIOD
          }`,
          render: translateConsumptionUsagePeriodClassificator,
        },
        {
          title: t('tableGeneral.additional.subsidyInEnglish'),
          dataIndex: `${classifierGeneralItemField.PROPERTIES}.${
            classifierConsumptionUsageProperties.SUBSIDY_IN_ENGLISH
          }`,
        },

        {
          title: t('tableGeneral.additional.subsidyInEstonian'),
          dataIndex: `${classifierGeneralItemField.PROPERTIES}.${
            classifierConsumptionUsageProperties.SUBSIDY_IN_ESTONIAN
          }`,
        },
      ];
    }

    return [];
  };

  getColumns = () => {
    const {t, deleteClassifier, classifierSubId, showModal} = this.props;

    return [
      {
        title: t('tableGeneral.code'),
        dataIndex: classifierGeneralItemField.CODE,
      },
      {
        title: t('tableGeneral.en'),
        dataIndex: `${classifierGeneralItemField.LANG}.${
          classifierGeneralItemLangField.EN
        }`,
      },
      {
        title: t('tableGeneral.et'),
        dataIndex: `${classifierGeneralItemField.LANG}.${
          classifierGeneralItemLangField.ET
        }`,
      },
      ...this.getAdditionalColumns(),
      {
        title: t('tableGeneral.actions'),
        render: value => (
          <TableRowActions>
            <Button
              shape="circle"
              icon="edit"
              size="small"
              type="primary"
              onClick={() => showModal(value[classifierGeneralItemField.CODE])}
            />
            <span className="ant-divider"/>

            <Popconfirm
              title={t('popconfirm.title')}
              onConfirm={() =>
                deleteClassifier(classifierSubId,
                  value[classifierGeneralItemField.CODE])
              }
              okText={t('popconfirm.buttonYes')}
              cancelText={t('popconfirm.buttonNo')}
            >
              <Button
                shape="circle"
                icon="delete"
                size="small"
                type="primary"
              />
            </Popconfirm>
          </TableRowActions>
        ),
      },
    ];
  };

  render() {
    const {dataSource} = this.props;

    return (
      <React.Fragment>
        <h1>{dataSource[classifierGeneralField.TITLE]}</h1>
        <Table
          rowKey={classifierGeneralItemField.CODE}
          dataSource={dataSource[classifierGeneralField.ITEMS]}
          columns={this.getColumns()}
          pagination={false}
        />
      </React.Fragment>
    );
  }
}

export default ClassifierSubTable;
