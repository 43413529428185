import React, {Component} from 'react';
import {reduxForm, Field, FieldArray} from 'redux-form/immutable';
import {TextField, ClassificatorField, NumberField} from '../../atoms';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {Row, Col, Button, Spin} from 'antd';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormActions} from '../../components';
import {FORM_CLASSIFIERS_SUB_VALUES_EDIT} from '../../constants/formKeys';
import {getClassifierSubValuesChange} from '../../reducers/classifiersReducer';
import * as actions from '../../actions/actionCreators/classifiersActionCreators';
import {
  classifierGeneralItemField,
  classifierGeneralItemLangField,
  classifierSubValuesRawMaterialPropertiesItemField
} from '../../constants/classifier';
import validate from './validateSchema';
import {fromJS} from 'immutable';

export class ClassifierSubValuesEdit extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isEdit: PropTypes.bool.isRequired,
    classifierCode: PropTypes.string
  };

  renderRawMaterialProperties = ({fields}) => {
    const {t} = this.props;
    return (
      <div>
        {fields.map((fieldName, index) => (
            !fields.get(index).get(classifierSubValuesRawMaterialPropertiesItemField.DELETE_PENDING) &&
            <Row key={index} gutter={16}>
              <Col span={6}>
                <Field
                  name={`${fieldName}.${classifierSubValuesRawMaterialPropertiesItemField.LAND_USE_CATEGORY}`}
                  component={ClassificatorField}
                  type={ClassificatorField.types.landUseCategory}
                />
              </Col>
              <Col span={6}>
                <Field
                  name={`${fieldName}.${classifierSubValuesRawMaterialPropertiesItemField.LAND_USE_EMISSIONS}`}
                  component={NumberField}
                  precision={0}
                />
              </Col>
              <Col span={6}>
                <Field
                  name={`${fieldName}.${classifierSubValuesRawMaterialPropertiesItemField.BIOFUEL_TYPE}`}
                  component={ClassificatorField}
                  type={ClassificatorField.types.biofuelType}
                />
              </Col>
              <Col span={5}>
                <Field
                  name={`${fieldName}.${classifierSubValuesRawMaterialPropertiesItemField.CALCULATED_MULTIPLIER}`}
                  component={NumberField}
                  precision={0}
                />
              </Col>
              <Col span={1}>
                <Button
                  value={index}
                  onClick={() => {
                    if (!fields.get(index).toJSON().id) {
                      fields.remove(index);
                    } else {
                      this.props.change(`${fieldName}.${classifierSubValuesRawMaterialPropertiesItemField.DELETE_PENDING}`, true);
                    }
                  }}
                  icon="delete"
                  style={{
                    height: '32px',
                    width: '32px',
                    padding: '0px',
                    margin: '0px',
                    textAlign: 'center',
                    fontWeight: 'bolder',
                    border: 'none',
                  }}
                />
              </Col>
            </Row>
          )
        )}
        <Button onClick={() => {
          fields.push(fromJS({}))
        }}>{t('buttonAddRawMaterialProperties')}</Button>
      </div>
    );
  };

  render() {
    const {
      t,
      handleSubmit,
      onCancel,
      isLoading,
      isEdit
    } = this.props;

    return (
      <Spin spinning={isLoading}>
        <form onSubmit={handleSubmit}>
          <Row gutter={32}>
            <Col span={24}>
              <Field
                name={classifierGeneralItemField.CODE}
                label={t('fieldCode')}
                component={TextField}
                disabled={isEdit}
              />
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={12}>
              <Field
                name={`${classifierGeneralItemField.LANG}.${
                  classifierGeneralItemLangField.EN
                }`}
                label={t('fieldEn')}
                component={TextField}
              />
            </Col>
            <Col span={12}>
              <Field
                name={`${classifierGeneralItemField.LANG}.${
                  classifierGeneralItemLangField.ET
                }`}
                label={t('fieldEt')}
                component={TextField}
              />
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={6} className="wideTableTitle">
              <h4>{t('tableRawMaterialProperties.landUseCategory')}</h4>
            </Col>
            <Col span={6} className="wideTableTitle">
              <h4>{t('tableRawMaterialProperties.landUseEmissions')}</h4>
            </Col>
            <Col span={6} className="wideTableTitle">
              <h4>{t('tableRawMaterialProperties.biofuelType')}</h4>
            </Col>
            <Col span={6} className="wideTableTitle">
              <h4>{t('tableRawMaterialProperties.calculatedMultiplier')}</h4>
            </Col>
          </Row>
          <FieldArray
            name="rawMaterialProperties"
            component={this.renderRawMaterialProperties}
            rerenderOnEveryChange={true}
          />
          <FormActions>
            <Button onClick={onCancel}>{t('buttonCancel')}</Button>
            <Button htmlType="submit" type="primary">
              {t('buttonSave')}
            </Button>
          </FormActions>
        </form>
      </Spin>
    );
  }
}

const onSubmit = async (values, dispatch, ownProps) => {
  const valuesJS = values.toJS();

  await dispatch(
    actions.changeClassifierSubValues(ownProps.subId, valuesJS)
  );
  dispatch(actions.fetchClassifierSubValues(ownProps.subId));
  dispatch(ownProps.onComplete);
};

const getIsLoading = getLoadingSelector(getClassifierSubValuesChange);

const mapStateToProps = state => ({
  isLoading: getIsLoading(state),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_CLASSIFIERS_SUB_VALUES_EDIT,
    onSubmit,
    validate
  })(ClassifierSubValuesEdit)
);
