import React from 'react';
import {searchProductionUnit} from '../../actions/actionHelpers/endpoints';
import {AutoCompleteSelectField} from '../';
import {productionUnitFields} from '../../constants/productionUnit';

const getDataSource = async value => {
  const {data} = await searchProductionUnit(value);
  return data;
};

const AutoCompleteProductionUnitField = props => (
  <AutoCompleteSelectField
    {...props}
    getDataSource={getDataSource}
    valueKey={productionUnitFields.ID}
    labelKey={productionUnitFields.NAME}
  />
);

export default AutoCompleteProductionUnitField;
