import React, {Component} from 'react';
import {AutoCompleteField} from '../../atoms';
import {Field} from 'redux-form/immutable';
import {debounce} from 'lodash';
import PropTypes from 'prop-types';
import {AutoComplete as AutoCompleteAntd} from 'antd';

const renderOption = item => (
  <AutoCompleteAntd.Option key={item.value} text={item.value}>
    {item.text}
  </AutoCompleteAntd.Option>
);

class AutoComplete extends Component {
  static propTypes = {
    fieldName: PropTypes.string.isRequired,
    fieldLabel: PropTypes.string.isRequired,
    getDataSource: PropTypes.func.isRequired,
  };

  state = {
    dataSource: [],
    isLoading: false,
  };

  handleSearch = async value => {
    const {getDataSource} = this.props;

    if (!value) {
      this.setState({
        dataSource: [],
        isLoading: false,
      });
      return;
    }

    this.setState({
      isLoading: true,
    });

    const dataSource = await getDataSource(value);

    this.setState({
      dataSource,
      isLoading: false,
    });
  };

  handleSearchDebounce = debounce(this.handleSearch, 500);

  render() {
    const {dataSource, isLoading} = this.state;
    const {fieldName, fieldLabel} = this.props;

    return (
      <Field
        isLoading={isLoading}
        dataSource={dataSource.map(renderOption)}
        name={fieldName}
        component={AutoCompleteField}
        label={fieldLabel}
        onSearch={this.handleSearchDebounce}
        optionLabelProp="text"
      />
    );
  }
}

export default AutoComplete;
