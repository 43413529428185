import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {connect} from 'react-redux';
import * as actions from '../../actions/actionCreators/meteringPointActionCreators';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {
  getMeteringPointAgrData,
  getMeteringPointAgrDataEntities,
} from '../../reducers/meteringPointReducer';
import {YearSelect} from '../../atoms';
import {MeteringPointAgrDataTable} from '../../components';
import {Spin, Row, Col} from 'antd';
import './MeteringPointAgrData.css';

export class MeteringPointData extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    meteringPointId: PropTypes.number,
    meteringData: PropTypesImmutable.list,
    fetchMeteringPointAgrData: PropTypes.func.isRequired,
    isConsumption: PropTypes.bool.isRequired,
  };

  state = {
    selectedYear: new Date().getFullYear(),
  };

  componentDidMount = () => {
    const {fetchMeteringPointAgrData, meteringPointId} = this.props;

    fetchMeteringPointAgrData({
      meteringPointId,
      year: this.state.selectedYear,
    });
  };

  handleYearChange = year => {
    const {fetchMeteringPointAgrData, meteringPointId} = this.props;

    fetchMeteringPointAgrData({meteringPointId, year});

    this.setState({
      selectedYear: year,
    });
  };

  render() {
    const {t, meteringData, isLoading, isConsumption} = this.props;

    return (
      <Spin spinning={isLoading} className="metering-point-agr-data-container">
        <Row className="metering-point-agr-data-container__year-container">
          <Col span={8}>
            <YearSelect
              className="metering-point-agr-data-container__year-container__select"
              onChange={this.handleYearChange}
              value={this.state.selectedYear}
            />
          </Col>
        </Row>
        <Row>
          <MeteringPointAgrDataTable
            t={t}
            data={meteringData.toJS()}
            isConsumption={isConsumption}
          />
        </Row>
        <Row>
          <p>
            <small>{!isConsumption ? t('amountKwhNotes') : ''}</small>
          </p>
          <p>
            <small>{!isConsumption ? t('correctionsym') : ''}</small>
          </p>
        </Row>
      </Spin>
    );
  }
}

const isDataLoading = getLoadingSelector(getMeteringPointAgrData);

const mapStateToProps = state => ({
  isLoading: isDataLoading(state),
  meteringData: getMeteringPointAgrDataEntities(state),
});

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(MeteringPointData);
