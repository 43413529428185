import {createAsyncAction} from '../../utils/asyncHelpers';
import {
  AVP_METERING_POINTS_FETCH,
  CALCULATE_MEASUREMENT_DATA,
  REFRESH_MEASUREMENT_DATA
} from '../../constants/actionTypes/manualElectricGosIssuingActionTypes';
import {
  calculateMeasurementDataPost,
  loadingOperatorAvpPointsFetch,
  refreshMeasurementDataPost
} from '../actionHelpers/endpoints';

export const fetchAvpElectricMeteringPoints = createAsyncAction(
  AVP_METERING_POINTS_FETCH,
  loadingOperatorAvpPointsFetch,
);

export const refreshMeasurementData = createAsyncAction(
  REFRESH_MEASUREMENT_DATA,
  refreshMeasurementDataPost
);

export const calculateMeasurementData = createAsyncAction(
  CALCULATE_MEASUREMENT_DATA,
  calculateMeasurementDataPost
);
