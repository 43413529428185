import React from 'react';
import PropTypes from 'prop-types';
import {Layout} from 'antd';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import {CancelingCertificates as CancelingCertificatesContainer} from '../../containers';

const CancelingCertificates = props => {
  const {match: {params: {accountId}}, ...rest} = props;

  return (
    <Layout className="main-container">
      <h1>{props.t('title')}</h1>
      <CancelingCertificatesContainer accountId={accountId} {...rest} />
    </Layout>
  );
};

CancelingCertificates.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      accountId: PropTypes.string.isRequired,
    }),
  }),
};

export default translate(NAMESPACES.cancelingCertificates)(
  CancelingCertificates
);
