export const tsAssignmentsFilter = {
  STATE: 'state',
  TYPE: 'type',
  TRADING_METHOD: 'tradingMethod',
  COUNTERPARTY_ID: 'counterpartyId',
  DUE_DATE_START: 'dueDateStart',
  DUE_DATE_END: 'dueDateEnd'
};

export const FORM_ACCOUNT_GOS_TS_ASSIGNMENTS_FILTER = 'FORM_ACCOUNT_GOS_TS_ASSIGNMENTS_FILTER'
