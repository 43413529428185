import React, {Component} from "react";
import {Button, Input, Modal} from "antd";
import PropTypes from "prop-types";
import {TableActions} from "../../components";

const {TextArea} = Input;

export class TransportStatisticsInfoModal extends Component {

  static propTypes = {
    t: PropTypes.func.isRequired,
    selectedRow: PropTypes.object.isRequired,
    dataIndex: PropTypes.string.isRequired,
    setAdditionalInfo: PropTypes.func,

    hideModal: PropTypes.func.isRequired,
  }

  state = {
    value: this.props.selectedRow.additionalInfo,
  };

  onChange = ({target: {value}}) => {
    this.setState({value});
  };

  saveValue = () => {
    const {setAdditionalInfo, selectedRow, dataIndex, hideModal} = this.props;
    const {value} = this.state;

    setAdditionalInfo(value, selectedRow, dataIndex);
    hideModal();
  }

  renderModal = () => {
    const {t, hideModal} = this.props;
    const {value} = this.state;

    return (
      <div>
        <TextArea
          value={value}
          onChange={this.onChange}
          placeholder={t('infoModal.placeholder')}
          maxLength={10000}
          autoSize={{minRows: 10, maxRows: 20}}
        />
        <TableActions>
          <Button onClick={() => hideModal()}>{t('infoModal.cancel')}</Button>
          <Button onClick={this.saveValue} type="primary" className="global-margin-left-10">
            {t('infoModal.save')}
          </Button>
        </TableActions>
      </div>
    );
  };

  render() {
    const {
      t,
      hideModal,
    } = this.props;

    return (
      <Modal
        title={t('infoModal.title')}
        width="800px"
        footer={false}
        onCancel={hideModal}
        visible
      >
        {this.renderModal()}
      </Modal>
    )
  }

}