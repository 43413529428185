import React from 'react';
import PropTypes from 'prop-types';
import {Table} from 'antd';
import {invoiceRowFields, invoiceRowTableFields} from '../../constants/invoice';
import {InvoiceViewItemColComponent} from '../';

class InvoiceViewRow extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  getColumns = () => {
    const {t} = this.props;

    return [
      {
        title: t('tableRow.goAmount'),
        dataIndex: invoiceRowTableFields.GO_AMOUNT,
      },
      {
        title: t('tableRow.unit'),
        dataIndex: invoiceRowTableFields.UNIT,
      },
      {
        title: t('tableRow.sum'),
        dataIndex: invoiceRowTableFields.SUM,
      },
    ];
  };

  render() {
    const {t, data} = this.props;

    const dataSource = data && data[invoiceRowFields.LINES];

    return (
      <div className="global-margin-top-16">
        <Table
          rowKey={invoiceRowTableFields.GO_AMOUNT}
          dataSource={dataSource}
          columns={this.getColumns()}
          pagination={false}
        />
        <div className="global-margin-bottom-10 global-margin-top-16">
          <InvoiceViewItemColComponent
            itemTitle={t('tableRow.withoutVap')}
            itemValue={data[invoiceRowFields.TOTAL_WITHOUT_VAP]}
          />
        </div>
        <div className="global-margin-bottom-10">
          <InvoiceViewItemColComponent
            itemTitle={t('tableRow.vap')}
            itemValue={data[invoiceRowFields.VAP]}
          />
        </div>
        <div className="global-margin-bottom-10">
          <InvoiceViewItemColComponent
            itemTitle={t('tableRow.withVap')}
            itemValue={data[invoiceRowFields.TOTAL_WITH_VAP]}
          />
        </div>
      </div>
    );
  }
}

export default InvoiceViewRow;
