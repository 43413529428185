import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {connect} from 'react-redux';
import * as actions from '../../actions/actionCreators/meteringPointActionCreators';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {
  getMeteringPointReviewList,
  getMeteringPointReviewListEntities,
} from '../../reducers/meteringPointReducer';
import {NotificationViewAdmin} from '../';
import {Spin, List, Modal} from 'antd';
import {
  hideNotificationViewModal,
  showNotificationViewModal,
  getNotificationViewModal,
} from '../../actions/actionCreators/modalsActionCreators';

export class MeteringReview extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    meteringPointId: PropTypes.number.isRequired,
    reviewList: PropTypesImmutable.list,
    fetchMeteringPointReview: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    modalState: PropTypes.bool.isRequired,
  };

  componentDidMount = () => {
    const {fetchMeteringPointReview, meteringPointId} = this.props;

    fetchMeteringPointReview(meteringPointId);
  };

  selectedId = null;

  handleReviewClick = (e, id) => {
    e.preventDefault();
    this.selectedId = id;
    this.props.showModal();
  };

  render() {
    const {t, reviewList, isLoading, modalState, hideModal} = this.props;

    return (
      <Spin spinning={isLoading}>
        <List
          bordered
          dataSource={reviewList.toJS()}
          renderItem={item => (
            <List.Item
              actions={[
                <a
                  href="/reviewDataFile"
                  onClick={e => this.handleReviewClick(e, item.id)}
                >
                  {t('reviewData')}
                </a>,
              ]}
            >
              [ {item.period} ] {item.path}
            </List.Item>
          )}
        />
        {modalState && (
          <Modal
            onCancel={hideModal}
            visible={modalState}
            footer={false}
            title={t('reviewModalTitle')}
            width="600px"
          >
            <NotificationViewAdmin selectedId={this.selectedId} t={t}/>
          </Modal>
        )}
      </Spin>
    );
  }
}

const isDataLoading = getLoadingSelector(getMeteringPointReviewList);

const mapStateToProps = state => ({
  isLoading: isDataLoading(state),
  reviewList: getMeteringPointReviewListEntities(state),
  modalState: getNotificationViewModal(state),
});

const mapDispatchToProps = {
  ...actions,
  showModal: showNotificationViewModal,
  hideModal: hideNotificationViewModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(MeteringReview);
