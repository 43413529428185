import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {electricMeteringPointFields} from '../../constants/electricMeteringPoint';
import {Table} from 'antd';

class ManualElectricGosIssuingMeteringPoints extends Component {
  static propTypes = {
    points: PropTypes.array,
    t: PropTypes.func.isRequired,
    onRowSelect: PropTypes.func.isRequired
  };

  getColumns = () => {
    const {t} = this.props;

    return [
      {
        title: t('table.eiczCode'),
        dataIndex: electricMeteringPointFields.EICZ_CODE
      },
      {
        title: t('table.unitName'),
        dataIndex: electricMeteringPointFields.LOADING_UNIT_NAME
      }
    ];
  };

  render() {
    const {points, onRowSelect} = this.props;

    return (
      <Table
        rowKey={electricMeteringPointFields.ID}
        dataSource={points}
        columns={this.getColumns()}
        rowSelection={{onChange: onRowSelect}}
        pagination={false}
      />
    );
  }
}

export default ManualElectricGosIssuingMeteringPoints;
