import React from 'react';
import PropTypes from 'prop-types';
import {Select} from './';
import _ from 'lodash/fp';

const YearSelect = ({yearDistance, afterCurrent, beforeCurrent, ...rest}) => {
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - yearDistance;
  const endYear = currentYear + yearDistance;
  const years = _.range(
    afterCurrent ? currentYear : startYear,
    beforeCurrent ? currentYear + 1 : endYear
  );
  const mapToOptions = _.map(item => ({
    value: item,
  }));

  const options = mapToOptions(years);

  return <Select {...rest} options={options} labelKey="value"/>;
};

YearSelect.propTypes = {
  yearDistance: PropTypes.number,
  afterCurrent: PropTypes.bool,
  beforeCurrent: PropTypes.bool,
};

YearSelect.defaultProps = {
  yearDistance: 10,
  afterCurrent: false,
  beforeCurrent: false,
};

export default YearSelect;
