import {
  createValidation,
  validatorRequired,
  validatorEmail,
  validatorPhone,
  validatorNumber,
} from '../../utils/formValidators';

import {formRegisterContactFields} from '../../constants/formRegister';

export default createValidation([
  {
    name: formRegisterContactFields.CONTACT_PERSON,
    validators: validatorRequired(),
  },
  {
    name: formRegisterContactFields.EMAIL,
    validators: [validatorRequired(), validatorEmail()],
  },
  {
    name: formRegisterContactFields.PHONE,
    validators: [validatorRequired(), validatorPhone(), validatorNumber()],
  },
]);
