import {createValidation, validatorRequired} from '../../utils/formValidators';

import {formRequestChangeFields} from '../../constants/forms/formRequestChange';

export default createValidation([
  {
    name: formRequestChangeFields.MESSAGE,
    validators: validatorRequired(),
  },
]);
