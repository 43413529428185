import React, {Component} from 'react';
import {
  reduxForm,
  Field,
  Fields,
  formValueSelector,
} from 'redux-form/immutable';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import PropTypesImmutable from 'react-immutable-proptypes';
import {ClassificatorField, CheckboxField, DateRangeField} from '../../atoms';
import {
  FormActions,
  TableActions,
  AutoCompleteProductionUnitField,
} from '../../components';
import {connect} from 'react-redux';
import {Collapse, Row, Col, Button} from 'antd';
import {FORM_ACCOUNT_REPORT_FILTER} from '../../constants/formKeys';
import * as actions from '../../actions/actionCreators/accountActionCreators';
import {getUserLegalEntity} from '../../reducers/userReducer';
import {formAccountOverviewFilter} from '../../constants/forms/formAccountOverviewFilter';
import moment from 'moment';
import {Map} from 'immutable';
import {FORMAT_DEFAULT} from '../../utils/dates';
import {links} from '../../utils/gotoLink';
import {downloadFileByPostURL} from '../../utils/files';

const {Panel} = Collapse;

const getInitialValues = accountId =>
  Map({
    [formAccountOverviewFilter.BALANCE_START_DATE]: moment()
      .startOf('day')
      .format(FORMAT_DEFAULT),
    [formAccountOverviewFilter.BALANCE_END_DATE]: moment()
      .endOf('day')
      .format(FORMAT_DEFAULT),
    accountId,
  });

export class AccountReportFilter extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    legalEntity: PropTypesImmutable.map.isRequired,
    fetchAccountReport: PropTypes.func.isRequired,
    accountId: PropTypes.string,
    filterValues: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    const {fetchAccountReport, initialValues} = this.props;

    fetchAccountReport(initialValues);
  };

  componentDidUpdate = prevProps => {
    const {fetchAccountReport, accountId, reset} = this.props;

    if (accountId !== prevProps.accountId) {
      reset();
      fetchAccountReport(getInitialValues(accountId));
    }
  };

  handleExportTransactions = () => {
    const {filterValues} = this.props;

    downloadFileByPostURL(
      links.periodicReportExportCsv,
      filterValues,
      'periodic-report.csv'
    );
  };

  render() {
    const {t, handleSubmit, reset, accountId} = this.props;

    return (
      <React.Fragment>
        <Collapse defaultActiveKey={['filters']}>
          <Panel key="filters" header={t('filter.title')}>
            <form onSubmit={handleSubmit}>
              <Row gutter={32}>
                <Col span={16}>
                  <Row gutter={32}>
                    <Col span={24}>
                      <Fields
                        className="date-field"
                        label={t('filter.balanceDateRange.title')}
                        names={[
                          formAccountOverviewFilter.BALANCE_START_DATE,
                          formAccountOverviewFilter.BALANCE_END_DATE,
                        ]}
                        placeholder={[
                          t('filter.balanceDateRange.dateFromPlaceholder'),
                          t('filter.balanceDateRange.dateToPlaceholder'),
                        ]}
                        component={DateRangeField}
                        hasFeedback={false}
                        required
                      />
                    </Col>
                  </Row>
                  <Row gutter={32}>
                    <Col span={12}>
                      <Field
                        label={t('overview.filter.fuelType')}
                        name={formAccountOverviewFilter.FUEL_TYPE}
                        component={ClassificatorField}
                        type={ClassificatorField.types.fuelType}
                        hasFeedback={false}
                      />
                    </Col>
                    <Col span={12}>
                      <Field
                        label={t('overview.filter.productionUnit')}
                        name={formAccountOverviewFilter.PRODUCTION_UNIT_ID}
                        component={AutoCompleteProductionUnitField}
                        hasFeedback={false}
                      />
                    </Col>
                  </Row>
                  <Row gutter={32}>
                    <Col span={12}>
                      <Field
                        label={t('overview.filter.cleaningTechnologyType')}
                        name={
                          formAccountOverviewFilter.CLEANING_TECHNOLOGY_TYPE
                        }
                        component={ClassificatorField}
                        type={ClassificatorField.types.cleaningTechnologyType}
                        hasFeedback={false}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <span className="page-AccountOverviewPage__groupByLabel">
                    {t('overview.filter.groupBy')}
                  </span>
                  <div className="page-AccountOverviewPage__groupBy">
                    <Field
                      name={formAccountOverviewFilter.GROUP_BY_TECHNOLOGY}
                      component={CheckboxField}
                      hasFeedback={false}
                      checkboxText={t('overview.filter.groupByTechnology')}
                    />
                    <Field
                      name={formAccountOverviewFilter.GROUP_BY_FUEL_TYPE}
                      component={CheckboxField}
                      hasFeedback={false}
                      checkboxText={t('overview.filter.groupByFuelType')}
                    />
                    {!accountId && (
                      <Field
                        name={
                          formAccountOverviewFilter.GROUP_BY_PRODUCTION_UNIT
                        }
                        component={CheckboxField}
                        hasFeedback={false}
                        checkboxText={t(
                          'overview.filter.groupByProductionUnit'
                        )}
                      />
                    )}
                  </div>
                </Col>
              </Row>
              <FormActions>
                <Button onClick={reset}>{t('filter.clearBtn')}</Button>
                <Button type="primary" htmlType="submit">
                  {t('filter.submitBtn')}
                </Button>
              </FormActions>
            </form>
          </Panel>
        </Collapse>
        <TableActions>
          <Button onClick={this.handleExportTransactions}>
            {t('exportCsvBtn')}
          </Button>
        </TableActions>
      </React.Fragment>
    );
  }
}

const filterForm = formValueSelector(FORM_ACCOUNT_REPORT_FILTER);

const mapStateToProps = (state, ownProps) => ({
  legalEntity: getUserLegalEntity(state),
  initialValues: getInitialValues(ownProps.accountId),
  filterValues: filterForm(state, ...Object.values(formAccountOverviewFilter)),
});

const onSubmit = (values, dispatch) =>
  dispatch(actions.fetchAccountReport(values.toJS()));

const mapDispatchToProps = {
  fetchAccountReport: actions.fetchAccountReport,
};

const AccountReportFilterHoc = connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_ACCOUNT_REPORT_FILTER,
    onSubmit,
  })(AccountReportFilter)
);

export default AccountReportFilterHoc;

export const AccountReportFilterTranslated = translate(NAMESPACES.account)(
  AccountReportFilterHoc
);
