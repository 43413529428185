import {Map} from 'immutable';
import {handleActions} from 'redux-actions';
import {createAsyncReducer, initialAsyncState} from '../utils/asyncHelpers';
import {MEASUREMENT_IMPORT_FILE_ADD} from '../constants/measurementImportActionTypes';

const initialState = Map({
  addFile: initialAsyncState,
});

const measurementImportReducer = handleActions(
  {
    ...createAsyncReducer(MEASUREMENT_IMPORT_FILE_ADD, 'addFile'),
  },
  initialState
);

export const getAddFileMeasurementImport = state =>
  state.getIn(['measurementImport', 'addFile']);

export default measurementImportReducer;
