import {set, isArray, isNumber, isString, isBoolean, isEmpty} from 'lodash';
import moment from 'moment';
import {tCommon as t} from '../i18n';
import {globalErrorKey} from '../constants/errors';
import {FORMAT_DEFAULT, FORMAT_DEFAULT_DATE} from './dates';
import {EIC} from './eic';

export const validatorNumber = message => value => {
  if (!message) {
    message = t('form_field_number');
  }

  if (isEmpty(value)) {
    return undefined;
  }

  const currentValue = isString(value) ? value : String(value);
  return /\D/g.test(currentValue) ? message : undefined;
};

export const validatorMaxLength = (length, message) => value => {
  if (!message) {
    message = t('form_field_max_length', {length});
  }

  if (isEmpty(value)) {
    return undefined;
  }

  const currentValue = isString(value) ? value : String(value);
  return currentValue && currentValue.length > length ? message : undefined;
};

export const validatorMinLength = (length, message) => value => {
  if (!message) {
    message = t('form_field_min_length', {length});
  }

  if (isEmpty(value)) {
    return undefined;
  }

  const currentValue = isString(value) ? value : String(value);
  return currentValue && currentValue.length < length ? message : undefined;
};

export const validatorBankAccountNumber = message => value => {
  if (!message) {
    message = t('form_field_bank_number');
  }

  if (isEmpty(value)) {
    return undefined;
  }

  const currentValue = isString(value) ? value : String(value);
  return currentValue && currentValue.match(/^[A-z]{2}[0-9]{18}$/g)
    ? undefined
    : message;
};

export const validatorLength = (length, message) => value => {
  if (!message) {
    message = t('form_field_length', {length});
  }

  if (isEmpty(value)) {
    return undefined;
  }

  const currentValue = isString(value) ? value : String(value);
  return currentValue && currentValue.length !== length ? message : undefined;
};

export const validatorRequired = message => value => {
  if (!message) {
    message = t('form_field_required');
  }

  if (isNumber(value) || isBoolean(value)) {
    return undefined;
  }

  if (isArray(value)) {
    return value.length ? undefined : message;
  }

  return value ? undefined : message;
};

export const validatorEIC = message => value => {
  if (!message) {
    message = t('form_field_eic');
  }

  if (isEmpty(value)) {
    return undefined;
  }

  const currentValue = isString(value) ? value : String(value);
  return currentValue && EIC.isValid(currentValue) ? undefined : message;
};

export const validatorEmail = message => value => {
  if (!message) {
    message = t('form_field_email');
  }

  if (!value) {
    return undefined;
  }

  return /^.+@.+\..+$/.test(value) ? undefined : message;
};

export const validatorPhone = message => value => {
  if (!message) {
    message = t('form_field_phone');
  }

  if (!value) {
    return undefined;
  }

  return value.length < 12 ? undefined : message;
};

export const validatorZip = message => value => {
  if (!message) {
    message = t('form_field_zip');
  }

  if (!value) {
    return undefined;
  }

  return value.length === 5 ? undefined : message;
};

export const validatorDateGreaterThen = (limit, message) => value => {
  const momentLimit = limit ? moment(limit, FORMAT_DEFAULT) : moment();
  if (!message) {
    message = t('form_field_greater_then', {
      limit: momentLimit.format(momentLimit.format(FORMAT_DEFAULT_DATE)),
    });
  }

  if (isEmpty(value)) {
    return undefined;
  }

  const currentValue = value ? moment(value, FORMAT_DEFAULT) : value;
  return currentValue && currentValue.isBefore(momentLimit)
    ? message
    : undefined;
};

export const validatorDateGreaterThenOrInitial = (limit, message) => (value, values, props, name) => {
  const initialDate = moment(props.initialValues.get(name), FORMAT_DEFAULT_DATE);
  const changedDate = moment(value, FORMAT_DEFAULT_DATE);
  if (!message) {
    message = t('form_field_greater_then_or_current', {
      limit: moment().format(moment().format(FORMAT_DEFAULT_DATE)),
      current: initialDate.format(initialDate.format(FORMAT_DEFAULT_DATE)),
    });
  }

  return !initialDate.isSame(changedDate, 'date')
    ? validatorDateGreaterThen(limit, message)(value, values, props)
    : undefined;
};

export const validatorChecked = message => value => {
  if (!message) {
    message = t('form_field_checked');
  }

  return isBoolean(value) && value ? undefined : message;
};

const addError = (errors, field, errorData) => {
  if (field === globalErrorKey) {
    field = '_error';
  }
  return errorData ? set(errors, field, errorData) : errors;
};

export const createValidation = arr => (form, props) => {
  let errors = {};

  arr.forEach(item => {
    const {validators, name} = item;
    const field = form.getIn([...name.split('.')], null);

    if (!validators) {
      throw new Error('You have to add field "validators"');
    }

    if (isArray(validators)) {
      validators.forEach(validator => {
        errors = addError(errors, name, validator(field, form, props, name));
      });
    } else {
      errors = addError(errors, name, validators(field, form, props, name));
    }
  });

  return errors;
};

export const hasErrors = (fieldsError) => {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
};
