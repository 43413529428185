import React, {Component} from 'react';
import Select from '../Select';
import ComponentWrapper from './ComponentWrapper';
import mapError from './mapError';

export default class SelectField extends Component {
  render() {
    return (
      <ComponentWrapper
        {...this.props}
        mapProps={mapError}
        render={props => <Select {...props} />}
      />
    );
  }
}
