import React, {Component} from "react";
import {Field, getFormValues, reduxForm} from "redux-form/immutable";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import PropTypesImmutable from "react-immutable-proptypes";
import {Button, Col, Collapse, Row} from "antd";
import {ClassificatorField} from "../../atoms";
import {FormActions} from "../../components";
import {notificationFilterFields} from "../../constants/formNotificationsFilter";
import {notificationRelatedObjectGroup} from "../../constants/classificators";

const {Panel} = Collapse;

export class NotificationsFilter extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    filterValues: PropTypesImmutable.map,
    initialValues: PropTypesImmutable.map,
    isAuctionsEnabled: PropTypes.bool.isRequired,
  }

  render() {
    const {
      t,
      reset,
      handleSubmit,
      isAuctionsEnabled
    } = this.props;

    return (
      <React.Fragment>
        <Collapse>
          <Panel key="notifications-filter"
                 header={t('filter.panelTitle')}>
            <form onSubmit={handleSubmit}>
              <Row gutter={24}>
                <Col span={8}>
                  <Field
                    name={notificationFilterFields.RELATED_OBJECT_TYPE}
                    label={t('filter.objectType')}
                    component={ClassificatorField}
                    type={ClassificatorField.types.notificationRelatedObjectGroup}
                    hasFeedback={false}
                    allowed={isAuctionsEnabled ?
                      Object.values(notificationRelatedObjectGroup) :
                      [notificationRelatedObjectGroup.TS_AGREEMENT, notificationRelatedObjectGroup.OTHER]}
                  />
                </Col>
              </Row>
              <FormActions>
                <Button onClick={reset}>
                  {t('filter.clearBtn')}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                >
                  {t('filter.submitBtn')}
                </Button>
              </FormActions>
            </form>
          </Panel>
        </Collapse>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, props) => ({
  filterValues: getFormValues(props.form)(state),
});

export default connect(mapStateToProps)(
  reduxForm({})(NotificationsFilter)
);
