import React, {Component} from 'react';
import {Select} from '../../atoms';
import PropTypes from 'prop-types';
import {mandateRole, accountStatus} from '../../constants/classificators';
import {
  translateAccountTypeClassificator,
  translateLegalEntityStatusClassificator,
  translateMandateRoleClassificator,
} from '../../utils/translateHelpers';
import {mandateFields} from '../../constants/mandate';
import {legalEntityFields} from '../../constants/legalEntity';
import {accountFields} from '../../constants/account';
import {consumerFields} from '../../constants/consumer';
import _ from 'lodash/fp';
import {get, uniq, orderBy} from 'lodash';

const accountsToTypes = accounts =>
  accounts
    ? uniq(
      accounts
        .filter(
          account =>
            get(account, accountFields.STATUS) === accountStatus.ACTIVE
        )
        .map(
          account =>
            `${translateAccountTypeClassificator(
              get(account, accountFields.TYPE)
            )}`
        )
    ).join(', ')
    : '';

const mapMandate = mandate => {
  const consumer = mandate[mandateFields.CONSUMER];

  const consumerName = consumer && consumer[consumerFields.NAME];
  const consumerPersonalCode =
    consumer && consumer[consumerFields.PERSONAL_CODE];
  const consumerRegisterCode =
    consumer && consumer[consumerFields.REGISTER_CODE];

  const name = get(mandate, [
    mandateFields.ORIGINATOR_ENTITY,
    legalEntityFields.NAME,
  ]);
  const registrationCode = get(mandate, [
    mandateFields.ORIGINATOR_ENTITY,
    legalEntityFields.REGISTER_CODE,
  ]);
  const status = get(mandate, [
    mandateFields.ORIGINATOR_ENTITY,
    legalEntityFields.STATUS,
  ]);
  const accounts = get(mandate, [
    mandateFields.ORIGINATOR_ENTITY,
    legalEntityFields.ACCOUNTS,
  ]);

  let label = '';

  switch (mandate.role) {
    case mandateRole.ELERING_ADMINISTRATOR:
      label = translateMandateRoleClassificator(mandate.role);
      break;
    case mandateRole.MARKET_REPRESENTATIVE:
      label = `${name}, ${registrationCode}, ${accountsToTypes(
        accounts
      )} (${translateLegalEntityStatusClassificator(status)})`;
      break;
    case mandateRole.CONSUMER:
      label = `${consumerName}
      ${consumerPersonalCode || consumerRegisterCode}
      (${translateMandateRoleClassificator(mandate.role)})`;
      break;
    default:
      break;
  }

  return {
    value: mandate.id,
    label,
  };
};

const mapMandates = _.map(mapMandate);

export default class SelectMandate extends Component {
  static propTypes = {
    mandates: PropTypes.array.isRequired,
  };

  render() {
    const {mandates, ...rest} = this.props;
    return <Select options={orderBy(mapMandates(mandates), ['label'])} {...rest} />;
  }
}
