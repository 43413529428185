import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Field} from 'redux-form/immutable';
import {TextField, ClassificatorField} from '../../atoms';
import {Row, Col, Form} from 'antd';
import {meteringPointFields} from '../../constants/meteringPoint';
import {productionUnitFields} from '../../constants/productionUnit';
import {links} from '../../utils/gotoLink';
import './productionPoint.css';

const FormItem = Form.Item;

const ProductionPoint = ({t, isAdmin, isAvp, productionUnit}) => {
  const isAvpOrNotAdmin = isAvp || !isAdmin;
  return (
    <div className="production-point">
      <h2>{t('info.title')}</h2>
      <Row gutter={32}>
        <Col span={8}>
          <Field
            name={meteringPointFields.EICZ_CODE}
            component={TextField}
            label={t('info.eiczCode')}
            disabled={isAvpOrNotAdmin}
            required={!isAvpOrNotAdmin}
          />
        </Col>
        <Col span={8}>
          <Field
            name={meteringPointFields.DATA_SOURCE}
            component={ClassificatorField}
            type={ClassificatorField.types.meteringPointDataSource}
            label={t('info.dataSource')}
            disabled
          />
        </Col>
        {isAdmin &&
          productionUnit && (
            <Col span={8}>
              <FormItem label={t('info.productionUnit')}>
                <Link
                  className="production-point__goto-unit"
                  href
                  to={links.productionUnitView(
                    productionUnit[productionUnitFields.ID]
                  )}
                >
                  {productionUnit[productionUnitFields.NAME]}
                </Link>
              </FormItem>
            </Col>
          )}
      </Row>
      <Row gutter={32}>
        <Col span={8}>
          <Field
            name={meteringPointFields.GRID_OPERATOR}
            component={TextField}
            label={t('info.gridOperator')}
            disabled
          />
        </Col>
        <Col span={8}>
          <Field
            name={meteringPointFields.COUNTY}
            component={ClassificatorField}
            type={ClassificatorField.types.counties}
            label={t('info.county')}
            disabled={isAvpOrNotAdmin}
            required={!isAvpOrNotAdmin}
          />
        </Col>
        <Col span={8}>
          <Field
            name={meteringPointFields.CITY}
            component={TextField}
            label={t('info.municipality')}
            disabled={isAvpOrNotAdmin}
            required={!isAvpOrNotAdmin}
          />
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={8}>
          <Field
            name={meteringPointFields.ADDRESS}
            component={TextField}
            label={t('info.address')}
            disabled
          />
        </Col>
        <Col span={8}>
          <Field
            name={meteringPointFields.ZIP_CODE}
            component={TextField}
            label={t('info.zipCode')}
            disabled={isAvpOrNotAdmin}
            required={!isAvpOrNotAdmin}
          />
        </Col>
        <Col span={8}>
          <Field
            name={meteringPointFields.DISTRICT}
            component={TextField}
            label={t('info.district')}
            disabled={isAvpOrNotAdmin}
            required={!isAvpOrNotAdmin}
          />
        </Col>
      </Row>
    </div>
  );
};

ProductionPoint.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  isAvp: PropTypes.bool.isRequired,
  productionUnit: PropTypes.object,
};

export default ProductionPoint;
