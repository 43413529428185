import {createAsyncSelector} from '../utils/asyncHelpers';
import {handleActions} from 'redux-actions';
import {ACCOUNT_GOS_REPORT_LIST_FETCH} from '../constants/accountGOSListActionTypes';
import accountGOSReportSchema from '../schemas/accountGOSReport';
import {
  listInitialAsyncPagingState,
  createAsyncPagingReducer,
} from '../utils/asyncPagingHelpers';

const accountGOSReportList = handleActions(
  {
    ...createAsyncPagingReducer(ACCOUNT_GOS_REPORT_LIST_FETCH),
  },
  listInitialAsyncPagingState
);

export const getAccountGOSReportList = state =>
  state.get('accountGOSReportList');

export const getAccountGOSReportListData = createAsyncSelector(
  [accountGOSReportSchema],
  getAccountGOSReportList
);

export default accountGOSReportList;
