import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Form, Tooltip, Icon} from 'antd';
import {tCommon as t} from '../../i18n';
import {isNil} from 'lodash';

const FormItem = Form.Item;

const placeholderByLabel = label => {
  if (!label) return '';

  return t('placeholder', {label: label.toLowerCase()});
};

export default class ComponentWrapper extends Component {
  static propTypes = {
    render: PropTypes.func.isRequired,
    mapProps: PropTypes.func.isRequired,
  };

  render() {
    const {
      label,
      labelCol,
      wrapperCol,
      help,
      extra,
      required,
      validateStatus,
      hasFeedback = true,
      colon,
      mapProps,
      render,
      placeholder,
      tooltip,
      ...rest
    } = this.props.mapProps(this.props);

    rest.placeholder = isNil(placeholder)
      ? placeholderByLabel(label)
      : placeholder;

    const componentLabel = tooltip ? (
      <span>
        {label}&nbsp;
        <Tooltip title={tooltip}>
          <Icon type="question-circle-o"/>
        </Tooltip>
      </span>
    ) : (
      label
    );

    return (
      <FormItem
        label={componentLabel}
        ref={this.initComponentRef}
        wrapperCol={wrapperCol}
        labelCol={labelCol}
        help={help}
        hasFeedback={hasFeedback}
        extra={extra}
        validateStatus={validateStatus}
        colon={colon}
        required={required}
      >
        {render(rest)}
      </FormItem>
    );
  }
}
