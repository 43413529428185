import React, {Component} from 'react';
import {Table, Radio, Button, Spin} from 'antd';
import PropTypes from 'prop-types';
import {fromJS} from 'immutable';
import {get} from 'lodash';
import {meteringPointFields} from '../../constants/meteringPoint';
import {meteringPointDataSource} from '../../constants/classificators';
import {
  translateMeteringPointDataSourceClassificator,
  translateCountyClassificator,
} from '../../utils/translateHelpers';
import './MeasurementTable.css';

const pointsSorter = (a, b) =>
  a[meteringPointFields.DATA_SOURCE] === meteringPointDataSource.VIRTUAL ||
  a[meteringPointFields.ID] !== null
    ? 1
    : -1;

class MeasurementTable extends Component {
  static propTypes = {
    isAdmin: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    onEditClick: PropTypes.func.isRequired,
    isAdminEdit: PropTypes.bool,
  };

  static defaultProps = {
    isAdminEdit: false,
  };

  getColumns = () => {
    const {t, onEditClick, isEditMode, isAdminEdit} = this.props;

    const columns =
      isEditMode && !isAdminEdit
        ? []
        : [
          {
            title: '',
            dataIndex: meteringPointFields.ID,
            sortOrder: 'descend',
            sorter: pointsSorter,
            render: (value, values) => (
              <Radio
                value={
                  get(values, meteringPointFields.ID)
                    ? get(values, meteringPointFields.ID)
                    : get(values, meteringPointFields.EICZ_CODE)
                }
              />
            ),
          },
        ];

    columns.push(
      {
        title: t('measurement.type'),
        dataIndex: meteringPointFields.DATA_SOURCE,

        render: translateMeteringPointDataSourceClassificator,
      },
      {
        title: t('measurement.eiczCodeLabel'),
        dataIndex: meteringPointFields.EICZ_CODE,
      },
      {
        title: t('measurement.headerCountry'),
        dataIndex: meteringPointFields.COUNTY,
        render: translateCountyClassificator,
      },
      {
        title: t('measurement.headerCity'),
        dataIndex: meteringPointFields.CITY,
      },
      {
        title: t('measurement.headerDistrict'),
        dataIndex: meteringPointFields.DISTRICT,
      },
      {
        title: t('measurement.headerAddress'),
        dataIndex: meteringPointFields.ADDRESS,
      },
      {
        title: t('measurement.actions'),
        dataIndex: meteringPointFields.ID,
        key: 'actions',
        render: (value, values) => (
          <div>
            {(!isEditMode || isAdminEdit) &&
              get(values, meteringPointFields.DATA_SOURCE) ===
              meteringPointDataSource.VIRTUAL && (
                <Button
                  shape="circle"
                  icon="edit"
                  size="small"
                  title={t('measurement.edit')}
                  type="primary"
                  onClick={() => onEditClick(fromJS(values))}
                />
              )}
          </div>
        ),
      }
    );

    return columns;
  };

  renderTable = () => {
    const {data, t} = this.props;

    return (
      <Table
        rowKey={record =>
          record[meteringPointFields.ID] ||
          record[meteringPointFields.EICZ_CODE]
        }
        columns={this.getColumns(t)}
        dataSource={data}
        bordered
      />
    );
  };

  render() {
    const {
      onSelectMeasurement,
      selectedMeasurementId,
      isLoading,
      isEditMode,
      isAdminEdit,
    } = this.props;

    return (
      <Spin spinning={isLoading}>
        {isEditMode && !isAdminEdit ? (
          this.renderTable()
        ) : (
          <Radio.Group
            className="component-measurement-table"
            onChange={e => {
              onSelectMeasurement(e.target.value);
            }}
            value={selectedMeasurementId}
          >
            {this.renderTable()}
          </Radio.Group>
        )}
      </Spin>
    );
  }
}

export default MeasurementTable;
