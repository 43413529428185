export const agreementTransportStatisticFields = {
  TRANSACTION_TIME: 'transactionTime',
  OUTGOING_TRANSACTION_ID: 'outgoingTransactionId',
  INCOMING_TRANSACTION_ID: 'incomingTransactionId',
  FROM_LEGAL_ENTITY_ID: 'fromLegalEntityId',
  TO_LEGAL_ENTITY_ID: 'toLegalEntityId',
  NATURAL_AMOUNT_MJ: 'naturalAmountMj',
  CALCULATED_AMOUNT_MJ: 'calculatedAmountMj',
  GHG_CAPACITY: 'ghgCapacity',
  CONSUMPTION_MONTH: 'consumptionMonth',
  CONSUMPTION_YEAR: 'consumptionYear',
  STATISTICS_ID: 'statisticsId'
};
