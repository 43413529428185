import React, {Component} from 'react';
import {reduxForm, Field} from 'redux-form/immutable';
import {TextField, NumberField, ClassificatorField, SelectField} from '../../atoms';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {Row, Col, Button, Spin} from 'antd';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormActions} from '../../components';
import {FORM_CLASSIFIERS_GENERAL_EDIT} from '../../constants/formKeys';
import {getClassifierGeneralChange} from '../../reducers/classifiersReducer';
import * as actions from '../../actions/actionCreators/classifiersActionCreators';
import {
  classifierGeneralItemField,
  classifierGeneralItemLangField,
  classifierConsumptionUsageProperties,
  classifierGeneralItemPropertiesField,
  classifierProperties,
} from '../../constants/classifier';
import {goToClassifierSubView} from '../../utils/gotoLink';
import classificatorTypes from '../../constants/classificators';
import validate from './validateSchema';
import classifiersStore from "../../utils/classifiersStore";

export class ClassifierGeneralEdit extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isEdit: PropTypes.bool.isRequired,
    classifierCode: PropTypes.string,
  };

  renderAdditional = () => {
    const {t, classifierCode} = this.props;

    if (classifierCode === classificatorTypes.consumptionUsage) {
      return (
        <React.Fragment>
          <Row gutter={32}>
            <Col span={12}>
              <Field
                className="date-field"
                name={`${classifierGeneralItemField.PROPERTIES}.${
                  classifierConsumptionUsageProperties.START_DAY
                }`}
                label={t('fieldDateStart')}
                component={NumberField}
                min={1}
                max={31}
              />
            </Col>
            <Col span={12}>
              <Field
                className="date-field"
                name={`${classifierGeneralItemField.PROPERTIES}.${
                  classifierConsumptionUsageProperties.END_DAY
                }`}
                label={t('fieldDateEnd')}
                component={NumberField}
                min={1}
                max={31}
              />
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={12}>
              <Field
                className="date-field"
                name={`${classifierGeneralItemField.PROPERTIES}.${
                  classifierConsumptionUsageProperties.SUPPORT_REGULATION
                }`}
                label={t('fieldSupportRegulation')}
                component={NumberField}
                min={1}
                max={1000}
                isFloat
              />
            </Col>
            <Col span={12}>
              <Field
                className="date-field"
                name={`${classifierGeneralItemField.PROPERTIES}.${
                  classifierConsumptionUsageProperties.PERIOD
                }`}
                label={t('fieldPeriod')}
                component={ClassificatorField}
                type={ClassificatorField.types.consumptionUsagePeriod}
              />
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={12}>
              <Field
                className="date-field"
                name={`${classifierGeneralItemField.PROPERTIES}.${
                  classifierConsumptionUsageProperties.SUBSIDY_IN_ENGLISH
                }`}
                label={t('fieldSubsidyInEnglish')}
                component={TextField}
              />
            </Col>
            <Col span={12}>
              <Field
                className="date-field"
                name={`${classifierGeneralItemField.PROPERTIES}.${
                  classifierConsumptionUsageProperties.SUBSIDY_IN_ESTONIAN
                }`}
                label={t('fieldSubsidyInEstonian')}
                component={TextField}
              />
            </Col>
          </Row>
        </React.Fragment>
      );
    }

    return null;
  };

  render() {
    const {
      t,
      handleSubmit,
      onCancel,
      isLoading,
      isEdit,
      selectedCode,
      initialValues,
      classifierCode,
    } = this.props;
    const valuesJS = initialValues.toJS();

    return (
      <Spin spinning={isLoading}>
        <form onSubmit={handleSubmit}>
          <Row gutter={32}>
            <Col span={24}>
              <Field
                name={classifierGeneralItemField.CODE}
                label={t('fieldCode')}
                component={TextField}
                disabled={isEdit}
              />
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={12}>
              <Field
                name={`${classifierGeneralItemField.LANG}.${
                  classifierGeneralItemLangField.EN
                }`}
                label={t('fieldEn')}
                component={TextField}
              />
            </Col>
            <Col span={12}>
              <Field
                name={`${classifierGeneralItemField.LANG}.${
                  classifierGeneralItemLangField.ET
                }`}
                label={t('fieldEt')}
                component={TextField}
              />
            </Col>
          </Row>
          {// If classifier_value_property table have related records with code 'property' then show
            valuesJS.properties.property && (
              <Row gutter={32}>
                <Col span={24}>
                  <Field
                    name={`${classifierGeneralItemField.PROPERTIES}.${
                      classifierProperties.PROPERTY
                    }`}
                    label={t('property')}
                    component={TextField}
                  />
                </Col>
              </Row>
            )}
          {// If classifier_value_property table have related records with code 'boolean' then show
            valuesJS.properties.boolean && (
              <Row gutter={32}>
                <Col span={24}>
                  <Field
                    name={`${classifierGeneralItemField.PROPERTIES}.${classifierProperties.BOOLEAN}`}
                    label={t('boolean')}
                    component={SelectField}
                    options={[{value: 'enabled', label: 'Enabled'}, {
                      value: 'disabled',
                      label: 'Disabled'
                    }]}
                  />
                </Col>
              </Row>
            )}
          {this.renderAdditional()}
          <FormActions>
            {selectedCode !== null && classifierCode === 'FUEL_TYPE' && (
              <Button onClick={() => {
                onCancel();
                goToClassifierSubView(classifierCode, initialValues.get("code"))
              }}>{t('buttonSubClassifiers')}</Button>
            )}
            <Button onClick={onCancel}>{t('buttonCancel')}</Button>
            <Button htmlType="submit" type="primary">
              {t('buttonSave')}
            </Button>
          </FormActions>
        </form>
      </Spin>
    );
  }
}

const onSubmit = async (values, dispatch, ownProps) => {
  const valuesJS = values.toJS();

  const oldProperties = valuesJS[classifierGeneralItemField.PROPERTIES];
  valuesJS[classifierGeneralItemField.PROPERTIES] = Object.keys(
    oldProperties
  ).map(key => ({
    [classifierGeneralItemPropertiesField.CODE]: key,
    [classifierGeneralItemPropertiesField.VALUE]: oldProperties[key],
  }));

  await dispatch(
    actions.changeClassifierGeneral(ownProps.classifierCode, valuesJS)
  );
  dispatch(actions.fetchClassifierGeneral(ownProps.classifierCode));
  dispatch(ownProps.onComplete);

  await classifiersStore.update();
};

const getIsLoading = getLoadingSelector(getClassifierGeneralChange);

const mapStateToProps = state => ({
  isLoading: getIsLoading(state),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_CLASSIFIERS_GENERAL_EDIT,
    onSubmit,
    validate,
  })(ClassifierGeneralEdit)
);
