import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {connect} from 'react-redux';
import {DatePicker, Spin, Button, Form, notification} from 'antd';
import * as actions from '../../actions/actionCreators/manualElectricGosIssuingActionCreators';
import {FORMAT_DEFAULT_MONTH, FORMAT_DEFAULT} from '../../utils/dates';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import {
  getMeteringPointsData,
  getMeteringPoints,
  getRefresh, getCalculate
} from '../../reducers/manualElectricGosIssuingReducer';
import {ManualElectricGosIssuingMeteringPoints, TableActions} from '../../components';
import './ManualElectricGosIssuing.css';

const {MonthPicker} = DatePicker;
const FormItem = Form.Item;

export class ManualElectricGosIssuing extends Component {
  static propTypes = {
    legalEntityId: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
    fetchAvpElectricMeteringPoints: PropTypes.func.isRequired,
    refreshMeasurementData: PropTypes.func.isRequired,
    calculateMeasurementData: PropTypes.func.isRequired,
    meteringPoints: PropTypesImmutable.list.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onDone: PropTypes.func.isRequired,
  };

  state = {
    selectedPoints: [],
    month: '',
  };

  componentDidMount() {
    const {fetchAvpElectricMeteringPoints, legalEntityId} = this.props;

    fetchAvpElectricMeteringPoints(legalEntityId);
  }

  handleRowSelected = selectedKeys => {
    this.setState({selectedPoints: selectedKeys});
  };

  handleMonthChange = value => {
    this.setState({month: value && value.format(FORMAT_DEFAULT)});
  };

  handleRefreshMeasurementData = async () => {
    const {refreshMeasurementData, legalEntityId, t} = this.props;
    const {selectedPoints, month} = this.state;

    const result = await refreshMeasurementData(legalEntityId, {
      period: month,
      measurementPointIds: selectedPoints
    });

    if (!result.error) {
      notification.info({
        message: t('refreshInfoTitle'),
        description: t('refreshInfoDescription'),
      });
    }
  };

  handleCalculateElectricGOS = async () => {
    const {calculateMeasurementData, legalEntityId, t} = this.props;
    const {selectedPoints, month} = this.state;

    const result = await calculateMeasurementData(legalEntityId, {
      period: month,
      measurementPointIds: selectedPoints
    });

    if (!result.error) {
      notification.info({
        message: t('calculateInfoTitle'),
        description: t('calculateInfoDescription'),
      });
    }
  };

  render() {
    const {t, meteringPoints, isLoading} = this.props;
    const {selectedPoints, month} = this.state;

    const isRefreshDisabled = !(selectedPoints.length > 0 && month);

    return (
      <React.Fragment>
        <FormItem label={t('month')}>
          <MonthPicker
            format={FORMAT_DEFAULT_MONTH}
            onChange={this.handleMonthChange}
          />
        </FormItem>
        <Spin className="global-margin-top-16" spinning={isLoading}>
          <h4>{t('pointsTitle')}</h4>
          <ManualElectricGosIssuingMeteringPoints
            t={t}
            onRowSelect={this.handleRowSelected}
            points={meteringPoints.toJS()}
          />
          <TableActions>
            <Button
              type="primary"
              disabled={isRefreshDisabled}
              onClick={this.handleRefreshMeasurementData}
            >
              {t('refreshBtn')}
            </Button>
            <Button
              className="margin-left-10"
              type="primary"
              disabled={isRefreshDisabled}
              onClick={this.handleCalculateElectricGOS}
            >
              {t('calculateBtn')}
            </Button>
          </TableActions>
        </Spin>
      </React.Fragment>
    );
  }
}

const getIsLoading = getLoadingSelector(getMeteringPoints, getRefresh, getCalculate);

const mapStateToProps = state => ({
  meteringPoints: getMeteringPointsData(state),
  isLoading: getIsLoading(state)
});

const mapDispatchToProps = {
  fetchAvpElectricMeteringPoints: actions.fetchAvpElectricMeteringPoints,
  refreshMeasurementData: actions.refreshMeasurementData,
  calculateMeasurementData: actions.calculateMeasurementData
};

export default connect(mapStateToProps, mapDispatchToProps)(
  translate(NAMESPACES.manualElectricGosIssuing)(ManualElectricGosIssuing)
);
