import React, {Component} from "react";
import {Col, Modal, Row, Spin} from "antd";
import PropTypes from "prop-types";
import {numberFormatter} from "../../utils/formaters";
import {translateClassificator, translateFuelTypeClassificator} from "../../utils/translateHelpers";
import moment from "moment";
import {fuelType as fuelTypeClassifier,} from '../../constants/classificators';

export class TransportStatisticsDetailsModal extends Component {

  static propTypes = {
    t: PropTypes.func.isRequired,
    agreementData: PropTypes.object.isRequired,
    transportStatisticsData: PropTypes.object.isRequired,
    isTransportStatisticsDataLoading: PropTypes.bool.isRequired,

    hideModal: PropTypes.func.isRequired,
  }

  renderModalDetails = () => {
    const {
      t,
      agreementData,
      transportStatisticsData,
      isTransportStatisticsDataLoading,
    } = this.props;

    const {
      expirationDate,
      naturalAmountMj,
      calculatedMultiplier,
      calculatedAmountMj,
      fuelGeneration,
      ghgCapacity,
      ghgAmount,
      consumptionMonth,
      consumptionYear,
      consumptionProvider,
      consumptionProviderTransactionId,
      productionPeriod,
      productionUnitEicwCode,
      posNumber,
      energyType,
      biofuelComponentCn,
      sustainableBiofuel,
      fuelType,
      feedstock,
      productionPathway,
      lowerCalorificValueKg,
      landUseCategory,
      landUseEmissions,
    } = transportStatisticsData;

    const isBioMethane = agreementData.fuelType === fuelTypeClassifier.BIOMETHANE;
    const isElectricity = agreementData.fuelType === fuelTypeClassifier.RENEWABLE_ELECTRICITY;
    const isEnergy = agreementData.fuelType === fuelTypeClassifier.RENEWABLE_ENERGY;
    const isKHG = false;

    return (
      <Spin spinning={isTransportStatisticsDataLoading}>
        <div>
          <Row className="global-margin-bottom-10">
            <Col>
              <div>
                <b>{t('details.type.title')}: </b>
                {t('details.type.' + agreementData.fuelType)}
              </div>
              <div>
                <b>{t('details.expirationDate')}: </b>
                {expirationDate}
              </div>
              {!isKHG &&
                <div>
                  <b>{t('details.naturalAmountMj')}: </b>
                  {numberFormatter(naturalAmountMj)}
                </div>
              }
              {!isKHG &&
                <div>
                  <b>{t('details.calculatedMultiplier')}: </b>
                  {calculatedMultiplier}
                </div>
              }
              {!isKHG &&
                <div>
                  <b>{t('details.calculatedAmountMj')}: </b>
                  {numberFormatter(calculatedAmountMj)}
                </div>
              }
              {(isBioMethane || isEnergy) &&
                <div>
                  <b>{t('details.biofuelType')}: </b>
                  {translateClassificator('biofuelType')(fuelGeneration)}
                </div>
              }
              {(isBioMethane || isElectricity || isEnergy) &&
                <div>
                  <b>{t('details.ghgCapacity')}: </b>
                  {numberFormatter(ghgCapacity)}
                </div>
              }
              {isKHG &&
                <div>
                  <b>{t('details.ghgAmount')}: </b>
                  {numberFormatter(ghgAmount)}
                </div>
              }
            </Col>
          </Row>
          {!isKHG &&
            <Row className="global-margin-bottom-10">
              <Col>
                {!isKHG &&
                  <div>
                    <b>{t('details.consumptionPeriod')}: </b>
                    {(isBioMethane || isElectricity) ? consumptionMonth + '.' : ''}{consumptionYear}
                  </div>}
                {(isBioMethane || isElectricity) &&
                  <div>
                    <b>{t('details.consumptionProvider')}: </b>
                    {consumptionProvider}
                  </div>
                }
                {(isBioMethane || isElectricity) &&
                  <div>
                    <b>{t('details.consumptionProviderTransactionId')}: </b>
                    {consumptionProviderTransactionId}
                  </div>
                }
                {isBioMethane &&
                  <div>
                    <b>{t('details.productionPeriod')}: </b>
                    {moment(productionPeriod, 'DD.MM.YYYY').format('MM.YYYY')}
                  </div>
                }
                {isBioMethane &&
                  <div>
                    <b>{t('details.productionUnitEicwCode')}: </b>
                    {productionUnitEicwCode}
                  </div>
                }
                {isBioMethane &&
                  <div>
                    <b>{t('details.posNumber')}: </b>
                    {posNumber}
                  </div>
                }
              </Col>
            </Row>}
          {isBioMethane &&
            <Row className="global-margin-bottom-10">
              <Col>
                <div>
                  <b>{t('details.energyType')}: </b>
                  {translateClassificator('energyType')(energyType)}
                </div>
                <div>
                  <b>{t('details.biofuelComponentCn')}: </b>
                  {biofuelComponentCn}
                </div>
                <div>
                  <b>{t('details.sustainableBiofuel')}: </b>
                  {translateClassificator('sustainableBiofuel')(sustainableBiofuel + '')}
                </div>
                <div>
                  <b>{t('details.fuelType')}: </b>
                  {translateFuelTypeClassificator(fuelType)}
                </div>
                <div>
                  <b>{t('details.feedstock')}: </b>
                  {translateClassificator('feedstock')(feedstock)}
                </div>
                <div>
                  <b>{t('details.productionPathway')}: </b>
                  {translateClassificator('productionPathway')(productionPathway)}
                </div>
                <div>
                  <b>{t('details.lowerCalorificValueKg')}: </b>
                  {lowerCalorificValueKg}
                </div>
                <div>
                  <b>{t('details.landUseCategory')}: </b>
                  {translateClassificator('landUseCategory')(landUseCategory)}
                </div>
                <div>
                  <b>{t('details.landUseEmissions')}: </b>
                  {landUseEmissions}
                </div>
              </Col>
            </Row>}
        </div>
      </Spin>
    );
  };

  render() {
    const {
      t,
      hideModal,
    } = this.props;

    return (
      <Modal
        title={t('details.title')}
        width="400px"
        footer={false}
        onCancel={hideModal}
        visible
      >
        {this.renderModalDetails()}
      </Modal>
    )
  }

}
