import {
  createValidation,
  validatorRequired,
  validatorMaxLength,
  validatorNumber,
  validatorLength,
  validatorBankAccountNumber,
} from '../../utils/formValidators';

import {formRegisterBankFields} from '../../constants/formRegister';

export default createValidation([
  {
    name: formRegisterBankFields.BANK_ACCOUNT,
    validators: [validatorRequired(), validatorBankAccountNumber()],
  },
  {
    name: formRegisterBankFields.BANK_NAME,
    validators: validatorRequired(),
  },
  {
    name: formRegisterBankFields.RECEIVER_KMKR,
    validators: [validatorRequired(), validatorMaxLength(30)],
  },
  {
    name: formRegisterBankFields.REFERENCE_NUMBER,
    validators: [validatorMaxLength(20), validatorNumber()],
  },
  {
    name: formRegisterBankFields.RECEIVER_REGISTRY_CODE,
    validators: [validatorRequired(), validatorLength(8), validatorNumber()],
  },
  {
    name: formRegisterBankFields.RECEIVER_NAME,
    validators: [validatorRequired(), validatorMaxLength(30)],
  },
]);
