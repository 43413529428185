import gosTransactionTypes from '../constants/classificators/gosTransactionType';
import transactionStatuses from '../constants/classificators/transactionForGosStatus';
import {isAdmin} from './roles';

/* GOS transactions */

export const hasGosRecallButton = (
  role,
  transactionStatus,
  transactionType,
  stillOwnedByToAccount
) =>
  stillOwnedByToAccount &&
  isAdmin(role) &&
  (transactionType === gosTransactionTypes.ISSUE ||
    transactionType === gosTransactionTypes.INCOMING ||
    transactionType === gosTransactionTypes.OUTGOING ||
    transactionType === gosTransactionTypes.LOCKING) &&
  (transactionStatus === transactionStatuses.SUCCEEDED ||
    transactionStatus === transactionStatuses.WAITING);
