import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {connect} from 'react-redux';
import {Col, Form, Icon, Layout, Row, Spin} from 'antd';
import {CompanyInfo, Logo, MainMenu, SelectMandate} from '../../components';
import {SelectLocale} from '../';
import {translate} from 'react-i18next';
import roles from '../../constants/classificators/mandateRole';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {
  currentPathSelector,
  goToConsumerReports,
  goToDashboard,
  links,
} from '../../utils/gotoLink';
import * as userActions from '../../actions/actionCreators/userActionCreators';
import * as userSelectors from '../../reducers/userReducer';
import {getMandatePerson, getMandatePersons} from '../../schemas/mandatePerson';
import * as actionCreators from '../../actions/actionCreators/notificationActionCreators';
import {mandateFields} from '../../constants/mandate';
import {legalEntityFields} from '../../constants/legalEntity';
import {legalEntityStatus, mandateRole} from '../../constants/classificators';
import {get} from 'lodash';
import {isConsumer} from '../../utils/roles';
import {version} from '../../../package.json';
import './Sidebar.css';
import {fetchApplicationSettings} from "../../actions/actionCreators/settingsActionCreators";
import {getAccountGOSTsAssignmentCount,} from "../../reducers/accountGOSTsAssignmentsListReducer";
import {getNotificationListCount} from "../../reducers/notificationReducer";
import {
  fetchAccountGOSTsAssignmentCount
} from "../../actions/actionCreators/accountGOSTsAssignmentsActionCreators";
import {resetMaster} from "../../actions/pureActions/agreementActions";
import classifiersStore from "../../utils/classifiersStore";
import {history} from '../../store';


const {Sider} = Layout;
const FromItem = Form.Item;

export class Sidebar extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    fetchUserMandates: PropTypes.func.isRequired,
    personalCode: PropTypes.string.isRequired,
    isUserDataLoading: PropTypes.bool.isRequired,
    isUserMandatesLoading: PropTypes.bool.isRequired,
    selectedMandate: PropTypesImmutable.map.isRequired,
    changeUserRole: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    selectUserMandate: PropTypes.func.isRequired,
    userMandates: PropTypesImmutable.list.isRequired,
    userAccounts: PropTypesImmutable.list,
    userData: PropTypesImmutable.map.isRequired,
    userRole: PropTypes.string.isRequired,
    userLegalEntity: PropTypes.number,
    notificationCount: PropTypes.number,
    currentPath: PropTypes.string.isRequired,
    fetchApplicationSettings: PropTypes.func.isRequired,
    assignmentCount: PropTypes.number,
    fetchAccountGOSTsAssignmentCount: PropTypes.func.isRequired,
    notifications: PropTypesImmutable.list,
  };

  componentDidMount = () => {
    const {
      fetchUserMandates,
      fetchNotificationsCount,
      fetchApplicationSettings,
      fetchAccountGOSTsAssignmentCount
    } = this.props;

    fetchUserMandates();
    fetchNotificationsCount();
    fetchApplicationSettings();
    fetchAccountGOSTsAssignmentCount();
  };

  onRoleChanged = async value => {
    const {
      selectUserMandate,
      fetchNotifications,
      fetchNotificationsCount,
      fetchAccountGOSTsAssignmentCount,
      userMandates,
      resetAgreements
    } = this.props;

    const role = userMandates
      .find(m => m.get(mandateFields.ID) === value)
      .get(mandateFields.ROLE);

    await selectUserMandate(value);

    fetchNotificationsCount();
    fetchAccountGOSTsAssignmentCount();

    resetAgreements();

    if (isConsumer(role)) {
      goToConsumerReports();
    } else {
      if (history.location.pathname !== links.notifications) {
        goToDashboard();
      } else {
        fetchNotifications();
      }
    }
  };

  render() {
    const {
      t,
      selectedMandate,
      userMandates,
      isUserDataLoading,
      isUserMandatesLoading,
      userData,
      userRole,
      notificationCount,
      userAccounts,
      currentPath,
      assignmentCount,
    } = this.props;

    const selectedCompany = selectedMandate.get(
      mandateFields.ORIGINATOR_ENTITY
    );
    const selectedCompanyCode = selectedMandate.getIn([
      mandateFields.ORIGINATOR_ENTITY,
      legalEntityFields.REGISTER_CODE,
    ]);

    const userMandatesList = userMandates
      .toJS()
      .filter(
        mandate =>
          get(mandate, mandateFields.ACTIVE) &&
          (get(mandate, [
              mandateFields.ORIGINATOR_ENTITY,
              legalEntityFields.STATUS,
            ]) === legalEntityStatus.ACTIVE ||
            [mandateRole.ELERING_ADMINISTRATOR, mandateRole.CONSUMER].includes(
              get(mandate, mandateFields.ROLE)
            ))
      );

    if (
      !userMandatesList.some(
        m => m[mandateFields.ID] === selectedMandate.get(mandateFields.ID)
      )
    ) {
      userMandatesList.push(selectedMandate.toJS());
    }

    return (
      <Sider className="sidebar" width="300">
        <div className="sidebar__main">
          <Logo t={t}/>
          <hr/>
          <Spin spinning={isUserDataLoading}>
            <Row>
              <Col span={2} className="sidebar__main__icon">
                <Icon type="user"/>
              </Col>
              <Col span={20} className="sidebar__main__user-info">
                {`${userData.get('name')} ${
                  userData.get('lastName') ? userData.get('lastName') : ''
                }`}
              </Col>
              <Col span={2} className="sidebar__main__icon">
                <a href={links.oauthLogout}>
                  <Icon type="logout"/>{' '}
                </a>
              </Col>
            </Row>
          </Spin>
          <SelectLocale/>
          <hr/>
          <Spin spinning={isUserMandatesLoading}>
            <form>
              <FromItem label={t('form_role')} colon={false}>
                <SelectMandate
                  mandates={userMandatesList}
                  value={selectedMandate.get(mandateFields.ID)}
                  onChange={this.onRoleChanged}
                />
              </FromItem>
            </form>
          </Spin>
          {selectedCompany &&
            selectedMandate.get('role') !== roles.ELERING_ADMINISTRATOR && (
              <CompanyInfo t={t} company={selectedCompany.toJS()}/>
            )}
          <hr/>
        </div>
        <MainMenu
          t={t}
          role={userRole}
          companyCode={selectedCompanyCode}
          notificationCount={notificationCount}
          userAccounts={userAccounts.toJS()}
          currentPath={currentPath}
          assignmentCount={assignmentCount}
          isAuctionsEnabled={classifiersStore.getBooleanProperty('IS_AUCTIONS_ENABLED')}
        />
        <div className="sidebar__version">{`v. ${version}`}</div>
      </Sider>
    );
  }
}

const mapStateToProps = state => {
  const userMandatesState = userSelectors.getUserMandates(state);
  const userSelectedMandate = userSelectors.getUserSelectedMandate(state);

  return {
    currentPath: currentPathSelector(state),
    personalCode: userSelectors.getUserPersonalCode(state),
    userRole: userSelectors.getRole(state),
    userData: userSelectors.getUserData(state),
    userMandates: getMandatePersons(state, userMandatesState),
    selectedMandate: getMandatePerson(state, userSelectedMandate),
    isUserDataLoading: getLoadingSelector(userSelectors.getUser)(state),
    isUserMandatesLoading: getLoadingSelector(userSelectors.getUserMandates)(
      state
    ),
    notificationCount: getNotificationListCount(state),
    userLegalEntity: userSelectors.getUserLegalEntityId(state),
    userAccounts: userSelectors.getUserLegalEntityAccounts(state),
    assignmentCount: getAccountGOSTsAssignmentCount(state),
  };
};

const mapDispatchToProps = {
  ...userActions,
  fetchNotifications: actionCreators.fetchNotifications,
  fetchNotificationsCount: actionCreators.fetchNotificationsCount,
  fetchApplicationSettings,
  fetchAccountGOSTsAssignmentCount,
  resetAgreements: resetMaster
};

export default translate('sidebar')(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
