import {createAsyncAction, normalizeHelper} from '../../utils/asyncHelpers';
import {agreementWithdrawFetch, completeAssignment} from "../actionHelpers/endpoints";
import {
  AGREEMENT_WITHDRAW_CONFIRM,
  AGREEMENT_WITHDRAW_FETCH
} from "../../constants/actionTypes/agreementWithdrawViewActionsTypes";
import agreementWithdraw from "../../schemas/agreementWithdraw";

export const fetchWithdrawal = createAsyncAction(
  AGREEMENT_WITHDRAW_FETCH,
  agreementWithdrawFetch,
  normalizeHelper(agreementWithdraw)
);

export const confirmWithdrawAssignment = createAsyncAction(
  AGREEMENT_WITHDRAW_CONFIRM,
  completeAssignment,
);
