import {
  initialAsyncState,
  createAsyncReducer,
  createAsyncSelector,
} from '../utils/asyncHelpers';
import {handleActions} from 'redux-actions';
import {
  INVOICE_RECALL,
  INVOICE_FETCH,
  INVOICE_ADMIN_CONFIRM,
  INVOICE_GENERATE,
} from '../constants/actionTypes/invoiceViewActionTypes';
import invoiceSchema from '../schemas/invoice';
import {Map} from 'immutable';

const initialState = Map({
  view: initialAsyncState,
  recall: initialAsyncState,
  confirm: initialAsyncState,
  generate: initialAsyncState,
});

const invoiceView = handleActions(
  {
    ...createAsyncReducer(INVOICE_FETCH, 'view'),
    ...createAsyncReducer(INVOICE_RECALL, 'recall'),
    ...createAsyncReducer(INVOICE_ADMIN_CONFIRM, 'confirm'),
    ...createAsyncReducer(INVOICE_GENERATE, 'generate'),
  },
  initialState
);

export const getInvoice = state => state.getIn(['invoiceView', 'view']);

export const getInvoiceData = createAsyncSelector(invoiceSchema, getInvoice);

export const getInvoiceRecall = state => state.getIn(['invoiceView', 'recall']);

export const getInvoiceConfirm = state =>
  state.getIn(['invoiceView', 'confirm']);

export const getInvoiceGenerate = state =>
  state.getIn(['invoiceView', 'generate']);

export default invoiceView;
