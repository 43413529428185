import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'antd';

export class VDSRequestResultModal extends Component {
  static propTypes = {
    show: PropTypes.bool,
    hideModal: PropTypes.func.isRequired,
    request: PropTypes.any,
    response: PropTypes.any,
  };

  hideModal = () => {
    const {hideModal} = this.props;
    hideModal();
  };

  render() {
    const {show, request, response} = this.props;

    return show ? (
      <Modal onCancel={this.hideModal}
             visible
             footer={false}
             title={'Päringu tulemus'}
             width="80vw">
        <h2>{'Päring'}</h2>
        <pre className={'block scroll-block vh-30'}>{request}</pre>
        <h2>{'Vastus'}</h2>
        {response ?
          <pre
            className={'block scroll-block vh-30'}>{response.error ? response.error : response.xml}</pre>
          :
          <div className={'block vh-30 text-center pt-4'}>{'mingit vastust'}</div>
        }
      </Modal>
    ) : null;
  }
}

export default VDSRequestResultModal;
