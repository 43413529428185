import * as Actions from '../pureActions/modalsActions';
import * as ModalTypes from '../../constants/ModalsActionTypes';
import {getModalState} from '../../reducers/modalsReducer';

// main actions

export const dispatchShowModal = mode => dispatch =>
  dispatch(Actions.showModal(mode));

export const dispatchHideModal = mode => dispatch =>
  dispatch(Actions.hideModal(mode));

export const dispatchHideAllModals = () => dispatch =>
  dispatch(Actions.hideAllModals());

// custom actions

export const showLoginModal = () =>
  dispatchShowModal(ModalTypes.modalNames.LOGIN);
export const hideLoginModal = () =>
  dispatchHideModal(ModalTypes.modalNames.LOGIN);

export const showRegisterRepresentCompanyModal = () =>
  dispatchShowModal(ModalTypes.modalNames.REGISTER_REPRESENT_COMPANY);
export const hideRegisterRepresentCompanyModal = () =>
  dispatchHideModal(ModalTypes.modalNames.REGISTER_REPRESENT_COMPANY);
export const getRegisterRepresentCompanyModal = state =>
  getModalState(state, ModalTypes.modalNames.REGISTER_REPRESENT_COMPANY);

export const showRegisterRepresentPersonModal = () =>
  dispatchShowModal(ModalTypes.modalNames.REGISTER_REPRESENT_PERSON);
export const hideRegisterRepresentPersonModal = () =>
  dispatchHideModal(ModalTypes.modalNames.REGISTER_REPRESENT_PERSON);
export const getRegisterRepresentPersonModal = state =>
  getModalState(state, ModalTypes.modalNames.REGISTER_REPRESENT_PERSON);

export const showMeasuringUploadModal = () =>
  dispatchShowModal(ModalTypes.modalNames.MEASURING_UPLOAD);
export const hideMeasuringUploadModal = () =>
  dispatchHideModal(ModalTypes.modalNames.MEASURING_UPLOAD);
export const getMeasuringUploadModal = state =>
  getModalState(state, ModalTypes.modalNames.MEASURING_UPLOAD);

export const showNotificationViewModal = () =>
  dispatchShowModal(ModalTypes.modalNames.NOTIFICATION_VIEW);
export const hideNotificationViewModal = () =>
  dispatchHideModal(ModalTypes.modalNames.NOTIFICATION_VIEW);
export const getNotificationViewModal = state =>
  getModalState(state, ModalTypes.modalNames.NOTIFICATION_VIEW);

export const showProductionUnitModal = () =>
  dispatchShowModal(ModalTypes.modalNames.PRODUCTION_UNIT);
export const hideProductionUnitModal = () =>
  dispatchHideModal(ModalTypes.modalNames.PRODUCTION_UNIT);

export const showRegisterContactModal = () =>
  dispatchShowModal(ModalTypes.modalNames.REGISTER_CONTACT);
export const hideRegisterContactModal = () =>
  dispatchHideModal(ModalTypes.modalNames.REGISTER_CONTACT);

export const showAddConsumptionPointConsumerModal = () =>
  dispatchShowModal(ModalTypes.modalNames.ADD_CONSUMPTION_POINT_CONSUMER);
export const hideAddConsumptionPointConsumerModal = () =>
  dispatchHideModal(ModalTypes.modalNames.ADD_CONSUMPTION_POINT_CONSUMER);
export const getAddConsumptionPointConsumerModal = state =>
  getModalState(state, ModalTypes.modalNames.ADD_CONSUMPTION_POINT_CONSUMER);

export const showViewMeteringPointConsumerModal = () =>
  dispatchShowModal(ModalTypes.modalNames.VIEW_METERING_POINT_CONSUMER);
export const hideViewMeteringPointConsumerModal = () =>
  dispatchHideModal(ModalTypes.modalNames.VIEW_METERING_POINT_CONSUMER);
export const getViewMeteringPointConsumerModal = state =>
  getModalState(state, ModalTypes.modalNames.VIEW_METERING_POINT_CONSUMER);

export const showRequestChangeEquipmentModal = () =>
  dispatchShowModal(ModalTypes.modalNames.REQUEST_CHANGE_EQUIPMENT);
export const hideRequestChangeEquipmentModal = () =>
  dispatchHideModal(ModalTypes.modalNames.REQUEST_CHANGE_EQUIPMENT);
export const getRequestChangeEquipmentModal = state =>
  getModalState(state, ModalTypes.modalNames.REQUEST_CHANGE_EQUIPMENT);

export const showRequestChangeLegalEntityModal = () =>
  dispatchShowModal(ModalTypes.modalNames.REQUEST_CHANGE_LEGAL_ENTITY);
export const hideRequestChangeLegalEntityModal = () =>
  dispatchHideModal(ModalTypes.modalNames.REQUEST_CHANGE_LEGAL_ENTITY);
export const getRequestChangeLegalEntityModal = state =>
  getModalState(state, ModalTypes.modalNames.REQUEST_CHANGE_LEGAL_ENTITY);

export const showRequestChangeMeteringModal = () =>
  dispatchShowModal(ModalTypes.modalNames.REQUEST_CHANGE_METERING);
export const hideRequestChangeMeteringModal = () =>
  dispatchHideModal(ModalTypes.modalNames.REQUEST_CHANGE_METERING);
export const getRequestChangeMeteringModal = state =>
  getModalState(state, ModalTypes.modalNames.REQUEST_CHANGE_METERING);

export const showClassifierPriceEditModal = () =>
  dispatchShowModal(ModalTypes.modalNames.CLASSIFIER_PRICE_EDIT);
export const hideClassifierPriceEditModal = () =>
  dispatchHideModal(ModalTypes.modalNames.CLASSIFIER_PRICE_EDIT);
export const getClassifierPriceEditModal = state =>
  getModalState(state, ModalTypes.modalNames.CLASSIFIER_PRICE_EDIT);

export const showInvoiceConfirmModal = () =>
  dispatchShowModal(ModalTypes.modalNames.INVOICE_CONFIRM);
export const hideInvoiceConfirmModal = () =>
  dispatchHideModal(ModalTypes.modalNames.INVOICE_CONFIRM);
export const getInvoiceConfirmModal = state =>
  getModalState(state, ModalTypes.modalNames.INVOICE_CONFIRM);

export const showInvoiceTermsModal = () =>
  dispatchShowModal(ModalTypes.modalNames.INVOICE_TERMS);
export const hideInvoiceTermsModal = () =>
  dispatchHideModal(ModalTypes.modalNames.INVOICE_TERMS);
export const getInvoiceTermsModal = state =>
  getModalState(state, ModalTypes.modalNames.INVOICE_TERMS);

export const showClassifierGeneralEditModal = () =>
  dispatchShowModal(ModalTypes.modalNames.CLASSIFIER_GENERAL_EDIT);
export const hideClassifierGeneralEditModal = () =>
  dispatchHideModal(ModalTypes.modalNames.CLASSIFIER_GENERAL_EDIT);
export const getClassifierGeneralEditModal = state =>
  getModalState(state, ModalTypes.modalNames.CLASSIFIER_GENERAL_EDIT);

export const showClassifierSubEditModal = () =>
  dispatchShowModal(ModalTypes.modalNames.CLASSIFIER_SUB_EDIT);
export const hideClassifierSubEditModal = () =>
  dispatchHideModal(ModalTypes.modalNames.CLASSIFIER_SUB_EDIT);
export const getClassifierSubEditModal = state =>
  getModalState(state, ModalTypes.modalNames.CLASSIFIER_SUB_EDIT);

export const showClassifierSubValuesEditModal = () =>
  dispatchShowModal(ModalTypes.modalNames.CLASSIFIER_SUB_VALUES_EDIT);
export const hideClassifierSubValuesEditModal = () =>
  dispatchHideModal(ModalTypes.modalNames.CLASSIFIER_SUB_VALUES_EDIT);
export const getClassifierSubValuesEditModal = state =>
  getModalState(state, ModalTypes.modalNames.CLASSIFIER_SUB_VALUES_EDIT);

export const showParameterGridOperatorEditModal = () =>
  dispatchShowModal(ModalTypes.modalNames.PARAMETER_GRID_OPERATOR_EDIT);
export const hideParameterGridOperatorEditModal = () =>
  dispatchHideModal(ModalTypes.modalNames.PARAMETER_GRID_OPERATOR_EDIT);
export const getParameterGridOperatorEditModal = state =>
  getModalState(state, ModalTypes.modalNames.PARAMETER_GRID_OPERATOR_EDIT);

export const showParameterSellerEditModal = () =>
  dispatchShowModal(ModalTypes.modalNames.PARAMETER_SELLER_EDIT);
export const hideParameterSellerEditModal = () =>
  dispatchHideModal(ModalTypes.modalNames.PARAMETER_SELLER_EDIT);
export const getParameterSellerEditModal = state =>
  getModalState(state, ModalTypes.modalNames.PARAMETER_SELLER_EDIT);

export const showRecalculateElectricGosModal = () =>
  dispatchShowModal(ModalTypes.modalNames.RECALCULATE_ELECTRIC_GOS_MODAL);
export const hideRecalculateElectricGosModal = () =>
  dispatchHideModal(ModalTypes.modalNames.RECALCULATE_ELECTRIC_GOS_MODAL);
export const getRecalculateElectricGosModal = state =>
  getModalState(state, ModalTypes.modalNames.RECALCULATE_ELECTRIC_GOS_MODAL);

export const showRecalculateGoModal = () =>
  dispatchShowModal(ModalTypes.modalNames.RECALCULATE_GO_MODAL);
export const hideRecalculateGoModal = () =>
  dispatchHideModal(ModalTypes.modalNames.RECALCULATE_GO_MODAL);
export const getRecalculateGoModal = state =>
  getModalState(state, ModalTypes.modalNames.RECALCULATE_GO_MODAL);

export const showLoadingUnitModal = () =>
  dispatchShowModal(ModalTypes.modalNames.LOADING_UNIT);
export const hideLoadingUnitModal = () =>
  dispatchHideModal(ModalTypes.modalNames.LOADING_UNIT);

export const showRequestChangeLoadingUnitModal = () =>
  dispatchShowModal(ModalTypes.modalNames.REQUEST_CHANGE_LOADING_UNIT);

export const getPurchasingAgreementWithdrawModal = state =>
  getModalState(state, ModalTypes.modalNames.PURCHASING_AGREEMENT_WITHDRAW);
export const showPurchasingAgreementWithdrawModal = () =>
  dispatchShowModal(ModalTypes.modalNames.PURCHASING_AGREEMENT_WITHDRAW);
export const hidePurchasingAgreementWithdrawModal = () =>
  dispatchHideModal(ModalTypes.modalNames.PURCHASING_AGREEMENT_WITHDRAW);

export const getSellingAgreementWithdrawModal = state =>
  getModalState(state, ModalTypes.modalNames.SELLING_AGREEMENT_WITHDRAW);
export const showSellingAgreementWithdrawModal = () =>
  dispatchShowModal(ModalTypes.modalNames.SELLING_AGREEMENT_WITHDRAW);
export const hideSellingAgreementWithdrawModal = () =>
  dispatchHideModal(ModalTypes.modalNames.SELLING_AGREEMENT_WITHDRAW);

export const getTsAgreementConfirmModal = state =>
  getModalState(state, ModalTypes.modalNames.TS_AGREEMENT_CONFIRM);
export const showTsAgreementConfirmModal = () =>
  dispatchShowModal(ModalTypes.modalNames.TS_AGREEMENT_CONFIRM);
export const hideTsAgreementConfirmModal = () =>
  dispatchHideModal(ModalTypes.modalNames.TS_AGREEMENT_CONFIRM);

export const getTsAgreementConfirmViewModal = state =>
  getModalState(state, ModalTypes.modalNames.TS_AGREEMENT_CONFIRM_VIEW);
export const showTsAgreementConfirmViewModal = () =>
  dispatchShowModal(ModalTypes.modalNames.TS_AGREEMENT_CONFIRM_VIEW);
export const hideTsAgreementConfirmViewModal = () =>
  dispatchHideModal(ModalTypes.modalNames.TS_AGREEMENT_CONFIRM_VIEW);

export const getAgreementWithdrawViewModal = state =>
  getModalState(state, ModalTypes.modalNames.AGREEMENT_WITHDRAW_VIEW);
export const showAgreementWithdrawViewModal = () =>
  dispatchShowModal(ModalTypes.modalNames.AGREEMENT_WITHDRAW_VIEW);
export const hideAgreementWithdrawViewModal = () =>
  dispatchHideModal(ModalTypes.modalNames.AGREEMENT_WITHDRAW_VIEW);

export const getAgreementWithdrawConfirmModal = state =>
  getModalState(state, ModalTypes.modalNames.AGREEMENT_WITHDRAW_CONFIRM);
export const showAgreementWithdrawConfirmModal = () =>
  dispatchShowModal(ModalTypes.modalNames.AGREEMENT_WITHDRAW_CONFIRM);
export const hideAgreementWithdrawConfirmModal = () =>
  dispatchHideModal(ModalTypes.modalNames.AGREEMENT_WITHDRAW_CONFIRM);

export const getAgreementTransactionConfirmModal = state =>
  getModalState(state, ModalTypes.modalNames.AGREEMENT_TRANSACTION_CONFIRM);
export const showAgreementTransactionConfirmModal = () =>
  dispatchShowModal(ModalTypes.modalNames.AGREEMENT_TRANSACTION_CONFIRM);
export const hideAgreementTransactionConfirmModal = () =>
  dispatchHideModal(ModalTypes.modalNames.AGREEMENT_TRANSACTION_CONFIRM);

export const getAgreementTransactionViewModal = state =>
  getModalState(state, ModalTypes.modalNames.AGREEMENT_TRANSACTION_VIEW);
export const showAgreementTransactionViewModal = () =>
  dispatchShowModal(ModalTypes.modalNames.AGREEMENT_TRANSACTION_VIEW);
export const hideAgreementTransactionViewModal = () =>
  dispatchHideModal(ModalTypes.modalNames.AGREEMENT_TRANSACTION_VIEW);

export const getCertificateDetailsViewModal = state =>
  getModalState(state, ModalTypes.modalNames.CERTIFICATE_DETAILS_VIEW);
export const showCertificateDetailsViewModal = () =>
  dispatchShowModal(ModalTypes.modalNames.CERTIFICATE_DETAILS_VIEW);
export const hideCertificateDetailsViewModal = () =>
  dispatchHideModal(ModalTypes.modalNames.CERTIFICATE_DETAILS_VIEW);

export const getReservationsConfirmModal = state =>
  getModalState(state, ModalTypes.modalNames.RESERVATIONS_CONFIRM);
export const showReservationsConfirmModal = () =>
  dispatchShowModal(ModalTypes.modalNames.RESERVATIONS_CONFIRM);
export const hideReservationsConfirmModal = () =>
  dispatchHideModal(ModalTypes.modalNames.RESERVATIONS_CONFIRM);

export const getReservationDetailsModal = state =>
  getModalState(state, ModalTypes.modalNames.RESERVATION_DETAILS_VIEW);
export const showReservationDetailsModal = () =>
  dispatchShowModal(ModalTypes.modalNames.RESERVATION_DETAILS_VIEW);
export const hideReservationDetailsModal = () =>
  dispatchHideModal(ModalTypes.modalNames.RESERVATION_DETAILS_VIEW);

export const getTransportStatisticsDetailsModal = state =>
  getModalState(state, ModalTypes.modalNames.TRANSPORT_STATISTICS_DETAILS_MODAL);
export const showTransportStatisticsDetailsModal = () =>
  dispatchShowModal(ModalTypes.modalNames.TRANSPORT_STATISTICS_DETAILS_MODAL);
export const hideTransportStatisticsDetailsModal = () =>
  dispatchHideModal(ModalTypes.modalNames.TRANSPORT_STATISTICS_DETAILS_MODAL);

export const getTransportStatisticsAdditionalInfoModal = state =>
  getModalState(state, ModalTypes.modalNames.TRANSPORT_STATISTICS_ADDITIONAL_INFO_MODAL);
export const showTransportStatisticsAdditionalInfoModal = () =>
  dispatchShowModal(ModalTypes.modalNames.TRANSPORT_STATISTICS_ADDITIONAL_INFO_MODAL);
export const hideTransportStatisticsAdditionalInfoModal = () =>
  dispatchHideModal(ModalTypes.modalNames.TRANSPORT_STATISTICS_ADDITIONAL_INFO_MODAL);

export const getTsAuctionBidModal = state =>
  getModalState(state, ModalTypes.modalNames.TS_AUCTION_BID_MODAL);
export const showTsAuctionBidModal = () =>
  dispatchShowModal(ModalTypes.modalNames.TS_AUCTION_BID_MODAL);
export const hideTsAuctionBidModal = () =>
  dispatchHideModal(ModalTypes.modalNames.TS_AUCTION_BID_MODAL);

export const getAuctionResultConfirmModal = state =>
  getModalState(state, ModalTypes.modalNames.AUCTION_RESULT_CONFIRM_MODAL);
export const showAuctionResultConfirmModal = () =>
  dispatchShowModal(ModalTypes.modalNames.AUCTION_RESULT_CONFIRM_MODAL);
export const hideAuctionResultConfirmModal = () =>
  dispatchHideModal(ModalTypes.modalNames.AUCTION_RESULT_CONFIRM_MODAL);

export const getAuctionResultViewModal = state =>
  getModalState(state, ModalTypes.modalNames.AUCTION_RESULT_VIEW_MODAL);
export const showAuctionResultViewModal = () =>
  dispatchShowModal(ModalTypes.modalNames.AUCTION_RESULT_VIEW_MODAL);
export const hideAuctionResultViewModal = () =>
  dispatchHideModal(ModalTypes.modalNames.AUCTION_RESULT_VIEW_MODAL);
