import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {connect} from 'react-redux';
import * as actions from '../../actions/actionCreators/invoiceViewActionCreators';
import {invoiceFields, invoiceViewFields} from '../../constants/invoice';
import {Spin, Button, notification, Popconfirm} from 'antd';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {
  getInvoice,
  getInvoiceData,
  getInvoiceRecall,
  getInvoiceConfirm,
  getInvoiceGenerate,
} from '../../reducers/invoiceViewReducer';
import {translateInvoiceStatusClassificator} from '../../utils/translateHelpers';
import {goToInvoices, getLinkInvoiceExport} from '../../utils/gotoLink';
import {
  TableActions,
  InvoiceViewItemPresenterInfo,
  InvoiceViewItemPresenterContact,
  InvoiceViewItemPresenterRegisterInfo,
  InvoiceViewItemInvoiceInfo,
  InvoiceViewItemBankDetails,
  InvoiceViewHistory,
  InvoiceViewRow,
  InvoiceViewPresenterFile,
} from '../../components';
import {downloadFileByPostURL} from '../../utils/files';
import {invoiceStatus} from '../../constants/classificators';

export class InvoiceView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    invoiceId: PropTypes.string.isRequired,
    recallInvoice: PropTypes.func.isRequired,
    fetchInvoice: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    invoiceData: PropTypesImmutable.map,
    confirmInvoice: PropTypes.func.isRequired,
    generateInvoice: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    const {invoiceId, fetchInvoice} = this.props;
    fetchInvoice(invoiceId);
  };

  handleExportTransactions = () => {
    const {invoiceId} = this.props;

    downloadFileByPostURL(
      getLinkInvoiceExport(invoiceId),
      {},
      `invoice-${invoiceId}.pdf`,
      'get'
    );
  };

  render() {
    const {
      t,
      isLoading,
      invoiceData,
      invoiceId,
      recallInvoice,
      confirmInvoice,
      generateInvoice,
    } = this.props;

    if (isLoading || invoiceData.isEmpty()) {
      return <Spin spinning/>;
    }

    const presenterInfo = invoiceData.get(invoiceViewFields.PRESENTER_INFO);
    const presenterContact = invoiceData.get(
      invoiceViewFields.PRESENTER_CONTACT
    );
    const presenterRegisterInfo = invoiceData.get(
      invoiceViewFields.PRESENTER_REGISTER_INFO
    );
    const invoiceInfo = invoiceData.get(invoiceViewFields.INFO);
    const bankDetails = invoiceData.get(invoiceViewFields.BANK_DETAILS);
    const presenterInvoice = invoiceData.get(
      invoiceViewFields.PRESENTER_INVOICE
    );
    const rowInfo = invoiceData.get(invoiceViewFields.ROW);
    const history = invoiceData.get(invoiceViewFields.HISTORY);

    return (
      <React.Fragment>
        <h1>{t('title', {invoiceId})}</h1>
        <h1
          align="right"
          style={{position: 'relative', top: '-56px', height: 0}}
        >
          {translateInvoiceStatusClassificator(
            invoiceData.get(invoiceFields.STATUS)
          )}
        </h1>

        <h2>{t('subtitle.presenterInfo')}</h2>
        <InvoiceViewItemPresenterInfo t={t} data={presenterInfo}/>

        <h2 className="global-margin-top-42">
          {t('subtitle.presenterContact')}
        </h2>
        <InvoiceViewItemPresenterContact t={t} data={presenterContact}/>

        <h2 className="global-margin-top-42">
          {t('subtitle.presenterRegisterInfo')}
        </h2>
        <InvoiceViewItemPresenterRegisterInfo
          t={t}
          data={presenterRegisterInfo}
        />

        <h2 className="global-margin-top-42">{t('subtitle.info')}</h2>
        <InvoiceViewItemInvoiceInfo t={t} data={invoiceInfo}/>

        <h2 className="global-margin-top-42">{t('subtitle.bankDetails')}</h2>
        <InvoiceViewItemBankDetails t={t} data={bankDetails}/>

        {presenterInvoice && (
          <React.Fragment>
            <h2 className="global-margin-top-42">
              {t('subtitle.presenterInvoice')}
            </h2>
            <InvoiceViewPresenterFile t={t} data={presenterInvoice}/>
          </React.Fragment>
        )}

        <h2 className="global-margin-top-42">{t('subtitle.row')}</h2>
        <InvoiceViewRow t={t} data={rowInfo.toJS()}/>

        <h2 className="global-margin-top-42">{t('subtitle.history')}</h2>
        <InvoiceViewHistory t={t} data={history.toJS()}/>

        <TableActions>
          <Button
            onClick={this.handleExportTransactions}
            className="global-margin-left-10"
          >
            {t('buttonExport')}
          </Button>
          {invoiceData.get(invoiceFields.STATUS) !== invoiceStatus.CANCELED && (
            <Popconfirm
              title={t('popconfirm.title')}
              onConfirm={() => {
                recallInvoice(invoiceId);
              }}
              okText={t('popconfirm.buttonYes')}
              cancelText={t('popconfirm.buttonNo')}
            >
              <Button className="global-margin-left-10">
                {t('buttonRecall')}
              </Button>
            </Popconfirm>
          )}
          {invoiceData.get(invoiceFields.STATUS) === invoiceStatus.CANCELED &&
            !invoiceData.get(invoiceFields.REGENERATED) && (
              <Button
                className="global-margin-left-10"
                onClick={() => generateInvoice(invoiceId)}
              >
                {t('buttonGenerate')}
              </Button>
            )}
          {invoiceData.get(invoiceFields.STATUS) ===
            invoiceStatus.SUBMITTED && (
              <Popconfirm
                title={t('popconfirm.confirmTitle')}
                onConfirm={() => {
                  confirmInvoice(invoiceId);
                }}
                okText={t('popconfirm.buttonYes')}
                cancelText={t('popconfirm.buttonNo')}
              >
                <Button className="global-margin-left-10">
                  {t('buttonConfirm')}
                </Button>
              </Popconfirm>
            )}
          <Button onClick={goToInvoices} className="global-margin-left-10">
            {t('buttonBack')}
          </Button>
        </TableActions>
      </React.Fragment>
    );
  }
}

const getLoading = getLoadingSelector(
  getInvoice,
  getInvoiceRecall,
  getInvoiceConfirm,
  getInvoiceGenerate
);

const mapStateToProps = state => ({
  isLoading: getLoading(state),
  invoiceData: getInvoiceData(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  recallInvoice: async id => {
    await dispatch(actions.recallInvoice(id));

    notification.info({
      message: ownProps.t('recallInfoTitle'),
      description: ownProps.t('recallInfoDescription'),
    });

    dispatch(actions.fetchInvoice(id));
  },
  fetchInvoice: id => {
    dispatch(actions.fetchInvoice(id));
  },
  confirmInvoice: id => {
    dispatch(actions.confirmInvoice(id));
  },
  generateInvoice: async id => {
    const result = await dispatch(actions.generateInvoice(id));

    if (!result.error) {
      notification.info({
        message: ownProps.t('generateInfoTitle'),
        description: ownProps.t('generateInfoDescription'),
      });
    }

    dispatch(actions.fetchInvoice(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceView);
