export const SET_DUPLICATE_EICW_ERROR = 'SET_DUPLICATE_EICW_ERROR';

export const setDuplicateEicwError = hasError => ({
  type: SET_DUPLICATE_EICW_ERROR,
  payload: hasError,
});

const initialState = {
  duplicateEicwError: false,
};

const eicwValidatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DUPLICATE_EICW_ERROR:
      return {
        ...state,
        duplicateEicwError: action.payload,
      };
    default:
      return state;
  }
};

export default eicwValidatorReducer;
