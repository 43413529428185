import React from 'react';
import {TableRowActions} from '../';
import {Button, Spin, Table} from 'antd';

export default function VDSMessagesTable({
                                           content,
                                           totalElements,
                                           loading,
                                           page,
                                           pageSize,
                                           setPage,
                                           showDetails,
                                           t
                                         }) {

  const columns = [
    {
      title: t('table.requestId'),
      dataIndex: 'requestId',
    },
    {
      title: t('table.requestType'),
      dataIndex: 'requestType',
      render: (value) => t('values.requestType_' + value)
    },
    {
      title: t('table.requestDate'),
      dataIndex: 'requestDate'
    },
    {
      title: t('table.success'),
      dataIndex: 'success',
      render: (value) => value ? t('values.successYes') : t('values.successNo')
    },
    {
      title: t('table.actions'),
      width: '75px',
      render: (value, values) => (
        <TableRowActions>
          <Button
            shape="circle"
            icon="select"
            size="small"
            type="primary"
            title={t('table.viewBtn')}
            onClick={() => showDetails(values)}
          />
        </TableRowActions>
      ),
    },
  ];

  return (
    <Spin spinning={loading}>
      <Table
        rowKey={'requestId'}
        dataSource={content}
        columns={columns}
        pagination={{
          onChange: setPage,
          current: page,
          total: totalElements,
          pageSize,
          defaultCurrent: 1
        }}
      />
    </Spin>
  );
}
