import React, { Component } from 'react';
import { reduxForm, Field, SubmissionError } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import { TextField, ClassificatorField } from '../../atoms';
import { FormActions } from '../../components';
import { connect } from 'react-redux';
import { isEmpty, isString } from 'lodash';
import {
  productionUnitFilterFields,
  PRODUCTION_UNIT_FILTER_FORM,
} from '../../constants/formProductionUnitFilter';
import { Collapse, Row, Col, Button, message } from 'antd';
import { parseNumber } from '../../utils/formHelpers';
import * as actions from '../../actions/actionCreators/productionUnitListActionCreators';
import { isHydrogen } from '../../utils/productionUnitUtil';

const { Panel } = Collapse;

export class ProductionUnitFilter extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    searchProductionUnits: PropTypes.func.isRequired,
    fuelType: PropTypes.string,
  };
  componentDidMount = () => this.props.searchProductionUnits();

  onClear = () => {
    const { submit, reset } = this.props;

    reset();
    submit();
  };

  render() {
    const { t, handleSubmit, fuelType } = this.props;

    const isHydro = isHydrogen(fuelType);

    return (
      <div>
        <h2 className="main-header">
          {isHydro ? t('filter.hydroTitleSmall') : t('filter.titleSmall')}
        </h2>
        <div className="search-form-container">
          <Collapse>
            <Panel
              key="filters"
              header={isHydro ? t('filter.hydroTitle') : t('filter.title')}
            >
              <form onSubmit={handleSubmit}>
                <Row gutter={32}>
                  <Col span={8}>
                    <Field
                      label={t('filter.name')}
                      name={productionUnitFilterFields.NAME}
                      component={TextField}
                      hasFeedback={false}
                    />
                  </Col>
                  <Col span={8}>
                    <Field
                      label={t('filter.eicwCode')}
                      name={productionUnitFilterFields.EICW_CODE}
                      component={TextField}
                      hasFeedback={false}
                    />
                  </Col>
                  <Col span={8}>
                    <Field
                      label={t('filter.eicxCode')}
                      name={productionUnitFilterFields.EICX_CODE}
                      component={TextField}
                      hasFeedback={false}
                    />
                  </Col>
                </Row>
                <Row gutter={32}>
                  <Col span={8}>
                    <Field
                      label={t('filter.registerCode')}
                      name={productionUnitFilterFields.REGISTER_CODE}
                      component={TextField}
                      parse={parseNumber}
                      hasFeedback={false}
                    />
                  </Col>
                  <Col span={8}>
                    <Field
                      label={t('filter.status')}
                      name={productionUnitFilterFields.STATUS}
                      component={ClassificatorField}
                      type={ClassificatorField.types.productionUnitStatus}
                      hasFeedback={false}
                    />
                  </Col>
                </Row>
                <FormActions>
                  <Button onClick={this.onClear}>{t('filter.clearBtn')}</Button>
                  <Button type="primary" htmlType="submit">
                    {t('filter.submitBtn')}
                  </Button>
                </FormActions>
              </form>
            </Panel>
          </Collapse>
        </div>
      </div>
    );
  }
}

const onSubmit = (values, dispatch, ownProps) => {
  if (values.every(value => isEmpty(value))) {
    throw new SubmissionError(ownProps.t('shouldFillAnyFilter'));
  }
  dispatch(actions.searchProductionUnits());
};

const onSubmitFail = errors => {
  if (isString(errors)) {
    message.error(errors);
  }
};

const mapStateToProps = (_state, ownProps) => ({
  initialValues: {
    [productionUnitFilterFields.PRODUCTION_DEVICE_TYPE]: ownProps.fuelType,
  },
});

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: PRODUCTION_UNIT_FILTER_FORM,
    onSubmit,
    onSubmitFail,
    enableReinitialize: true,
  })(ProductionUnitFilter)
);
