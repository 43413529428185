import React from "react";
import {translate} from "react-i18next";
import {NAMESPACES} from "../../i18n";
import PropTypes from "prop-types";
import {Layout} from "antd";
import {TradingAccountTransfer} from "../../containers";

class TradingAccountTransferView extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        type: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired
  };

  render() {
    const {t} = this.props;
    const type = this.props.match.params.type;

    return (
      <Layout className="main-container">
        <h1>{t(`title${type}Transfer`)}</h1>
        <TradingAccountTransfer t={t} type={type}/>
      </Layout>
    );
  }
}

export default translate(NAMESPACES.tradingAccount)(
  TradingAccountTransferView
);
