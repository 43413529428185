import {Layout} from 'antd';
import React from 'react';
import {tCommon as t} from '../../i18n';

function CookiesPage() {
  const ref = React.useRef();
  const [height, setHeight] = React.useState("0px");
  const onLoad = () => {
    setHeight((ref.current.contentWindow.document.body.scrollHeight + 50) + "px");
  };

  return (
    <Layout className="main-container">
      <div>
        <h1>{t('cookies.title')}</h1>
        <iframe
          title="Cookies"
          ref={ref}
          onLoad={onLoad}
          src={'/cookies.html'}
          frameBorder="0"
          scrolling="no"
          width="100%"
          height={height}
        />
      </div>
    </Layout>
  );
}

export default CookiesPage;
