import {handleActions} from 'redux-actions';
import {Map} from 'immutable';
import * as actions from '../actions/pureActions/alertsActions';

const initialState = Map();

export default handleActions(
  {
    [actions.showAlert]: (state, {payload}) => Map(payload),
  },
  initialState
);

export const getAlert = state => state.get('alert');
