import React, {Component} from 'react';
import moment from 'moment';
import {reduxForm, Field, Fields} from 'redux-form/immutable';
import PropTypes from 'prop-types';
import {TextField, ClassificatorField, DateRangeField} from '../../atoms';
import {FormActions, AutoCompleteAccountsField} from '../../components';
import {connect} from 'react-redux';
import {FORM_TRANSACTION_SEARCH} from '../../constants/formKeys';
import {formTransactionSearchFields} from '../../constants/forms/formTransactionSearch';
import {Collapse, Row, Col, Button} from 'antd';
import * as actions from '../../actions/actionCreators/transactionSearchActionCreators';

const {Panel} = Collapse;

export class TransactionFilter extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    searchTransactions: PropTypes.func.isRequired,
    accountId: PropTypes.string,
  };
  componentDidMount = () => this.props.searchTransactions();

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    if (this.props.accountId !== prevProps.accountId) {
      this.props.searchTransactions();
    }
  }

  onClear = () => {
    const {submit, reset} = this.props;

    reset();
    submit();
  };

  render() {
    const {t, handleSubmit} = this.props;
    return (
      <div>
        <div className="search-form-container">
          <Collapse defaultActiveKey={['filters']}>
            <Panel key="filters" header={t('filter.panelTitle')}>
              <form onSubmit={handleSubmit}>
                <Row gutter={32}>
                  <Col span={16}>
                    <Fields
                      label={t('filter.dateRange.title')}
                      names={[
                        formTransactionSearchFields.DATE_FROM,
                        formTransactionSearchFields.DATE_TO,
                      ]}
                      placeholder={[
                        t('filter.dateRange.dateFromPlaceholder'),
                        t('filter.dateRange.dateToPlaceholder'),
                      ]}
                      component={DateRangeField}
                      hasFeedback={false}
                      allowClear={false}
                      ranges={{
                        [t('filter.dateRange.currentMonth')]: [
                          moment().startOf('month'),
                          moment().endOf('month'),
                        ],
                        [t('filter.dateRange.beginLastMonth')]: [
                          moment()
                            .subtract(1, 'months')
                            .startOf('month'),
                          moment(),
                        ],
                        [t('filter.dateRange.lastMonth')]: [
                          moment()
                            .subtract(1, 'months')
                            .startOf('month'),
                          moment()
                            .subtract(1, 'months')
                            .endOf('month'),
                        ],
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <Field
                      label={t('filter.transactionType')}
                      name={formTransactionSearchFields.TRANSACTION_TYPE}
                      component={ClassificatorField}
                      type={ClassificatorField.types.transactionType}
                      hasFeedback={false}
                    />
                  </Col>
                </Row>
                <Row gutter={32}>
                  <Col span={8}>
                    <Field
                      label={t('filter.receiverAccount')}
                      name={formTransactionSearchFields.RECEIVER_ACCOUNT_NUMBER}
                      component={AutoCompleteAccountsField}
                      hasFeedback={false}
                    />
                  </Col>
                  <Col span={8}>
                    <Field
                      label={t('filter.senderAccount')}
                      name={formTransactionSearchFields.SENDER_ACCOUNT_NUMBER}
                      component={AutoCompleteAccountsField}
                      hasFeedback={false}
                    />
                  </Col>
                  <Col span={8}>
                    <Field
                      label={t('filter.number')}
                      name={formTransactionSearchFields.NUMBER}
                      component={TextField}
                      hasFeedback={false}
                    />
                  </Col>
                </Row>
                <Row gutter={32}>
                  <Col span={8}>
                    <Field
                      label={t('filter.fuelType')}
                      name={formTransactionSearchFields.FUEL_TYPE}
                      component={ClassificatorField}
                      type={ClassificatorField.types.fuelType}
                      hasFeedback={false}
                    />
                  </Col>
                  <Col span={8}>
                    <Field
                      label={t('filter.cleaningTechnologyType')}
                      name={
                        formTransactionSearchFields.CLEANING_TECHNOLOGY_TYPE
                      }
                      component={ClassificatorField}
                      type={ClassificatorField.types.cleaningTechnologyType}
                      hasFeedback={false}
                    />
                  </Col>
                </Row>
                <FormActions>
                  <Button onClick={this.onClear}>{t('filter.clearBtn')}</Button>
                  <Button type="primary" htmlType="submit">
                    {t('filter.submitBtn')}
                  </Button>
                </FormActions>
              </form>
            </Panel>
          </Collapse>
        </div>
      </div>
    );
  }
}

const onSubmit = (values, dispatch) => dispatch(actions.searchTransactions());

const mapDispatchToProps = {
  ...actions,
};

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    [formTransactionSearchFields.CURRENT_ACCOUNT_ID]: ownProps.accountId,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_TRANSACTION_SEARCH,
    onSubmit,
    enableReinitialize: true,
  })(TransactionFilter)
);
