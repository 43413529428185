import {createAsyncSelector} from '../utils/asyncHelpers';
import {
  listInitialAsyncPagingState,
  createAsyncPagingReducer,
} from '../utils/asyncPagingHelpers';
import {handleActions} from 'redux-actions';
import {TRANSACTION_SEARCH_FETCH} from '../constants/actionTypes/transactionSearchActionTypes';
import {transactionSchema} from '../schemas';

const transactionSearch = handleActions(
  {
    ...createAsyncPagingReducer(TRANSACTION_SEARCH_FETCH),
  },
  listInitialAsyncPagingState
);

export const getTransactionList = state => state.get('transactionSearch');

export const getTransactionListData = createAsyncSelector(
  [transactionSchema],
  getTransactionList
);

export default transactionSearch;
