import {
  initialAsyncState,
  createAsyncReducer,
  createAsyncSelector,
} from '../utils/asyncHelpers';
import {handleActions} from 'redux-actions';
import {
  TRANSACTION_GOS_FETCH,
} from '../constants/actionTypes/transactionGOSViewActionTypes';
import {transactionGOSSchema} from '../schemas';
import {Map} from 'immutable';

const initialState = Map({
  view: initialAsyncState,
});

const transactionGOSView = handleActions(
  {
    ...createAsyncReducer(TRANSACTION_GOS_FETCH, 'view'),
  },
  initialState
);

export const getTransaction = state => state.getIn(['transactionGOSView', 'view']);

export const getTransactionData = createAsyncSelector(
  transactionGOSSchema,
  getTransaction
);

export default transactionGOSView;
