export const productionUnitFields = {
  ID: 'id',
  NAME: 'unitName',
  EICW_CODE: 'eicwCode',
  ADDRESS: 'location',
  FIRST_TIME_USE: 'startUsageTime',
  AMOUNT_OF_INVESTMENT: 'amountInvestmentAid',
  IS_INVESTMENT_SUPPORT: 'unitInvestmentAid',
  LEGAL_ENTITY_ID: 'legalEntityId',
  LEGAL_ENTITY: 'legalEntity',
  TECHNOLOGY_TYPE: 'technologyType',
  HYDROGEN_TECHNOLOGY_TYPE: 'hydrogenTechnologyType',
  ELECTROLYSIS_TECHNOLOGY_TYPE: 'electrolysisTechnologyType',
  NOMINAL_POWER: 'nominalPower',
  SELF_SUFFICIENT_WEIGHT: 'selfSufficientWeight',
  ECONOMIC_CERTIFICATE: 'economicalCertificate',
  CERTIFICATE_NAME: 'certificateName',
  START_DATE: 'subsidyStartDate',
  END_DATE: 'subsidyEndDate',
  ELIGIBLE_FOR_SUBSIDY: 'subsidyEligible',
  DECISION_DATE: 'decisionDate',
  DECISION_NUMBER: 'decisionNumber',
  FUEL_TYPES: 'productionUnitFuels',
  METERING_POINT: 'measurementPoint',
  METERING_POINT_ID: 'measurementPointId',
  METERING_POINT_EICZ: 'measurementPointEicz',
  METERING_POINT_TYPE: 'measurementPointType',
  STATUS: 'status',
  CERTIFICATE_END_DATE: 'certificateEndDate',
  MONTHLY_FUEL_DATA_CONFIRMED: 'monthlyFuelDataConfirmed',
  MONTHLY_GO_ISSUED: 'monthlyGoIssued',
  IDS_OF_FILES: 'idsOfFiles',
  RFNBO: 'rfnbo',
};
