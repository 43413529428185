import React from 'react';
import {Layout} from 'antd';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import PropTypes from 'prop-types';
import {MeteringPointList} from '../../containers';
import {MeteringPointFilter} from '../../forms';
import './MeteringPointList.css';

class MeteringPointListPage extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  render() {
    const {t} = this.props;

    return (
      <Layout className="page-ConsumptionPointList main-container">
        <h1>{t('title')}</h1>
        <div className="page-ConsumptionPointList__filter-container">
          <MeteringPointFilter t={t}/>
        </div>
        <MeteringPointList t={t}/>
      </Layout>
    );
  }
}

export default translate(NAMESPACES.meteringPointList)(MeteringPointListPage);
