import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {connect} from 'react-redux';
import {ConsumerGoTable} from '../../components';
import {
  getConsumerGoList,
  getConsumerGoListData,
} from '../../reducers/consumerGoSearchReducer';
import * as actions from '../../actions/actionCreators/consumerGoSearchActionCreators';

export class ConsumerGoList extends Component {
  static propTypes = {
    consumerGoData: PropTypesImmutable.list,
    consumerGo: PropTypesImmutable.map.isRequired,
    searchConsumerGo: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const {
      consumerGo,
      consumerGoData,
      searchConsumerGo,
      t,
      ...rest
    } = this.props;

    return (
      <ConsumerGoTable
        consumerGo={consumerGoData ? consumerGoData.toJS() : []}
        searchConsumerGo={searchConsumerGo}
        t={t}
        {...rest}
        {...consumerGo.toJS()}
      />
    );
  }
}

const mapStateToProps = state => ({
  consumerGo: getConsumerGoList(state),
  consumerGoData: getConsumerGoListData(state),
});

const mapDispatchToProps = {
  searchConsumerGo: actions.searchConsumerGo,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsumerGoList);
