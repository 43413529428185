import React from 'react';
import {ConsumptionPointAdd} from '../../containers';

const MeteringPointAdd = () => (
  <div className="main-container">
    <ConsumptionPointAdd/>
  </div>
);

export default MeteringPointAdd;
