import {handleActions} from 'redux-actions';
import {
  createAsyncReducer,
  initialAsyncState,
  createAsyncSelector,
} from '../utils/asyncHelpers';
import {loadingUnitScheme} from '../schemas';
import {LOADING_UNIT_FETCH} from '../constants/loadingUnit';

const loadingUnitViewReducer = handleActions(
  createAsyncReducer(LOADING_UNIT_FETCH),
  initialAsyncState
);

export default loadingUnitViewReducer;

export const getLoadingUnit = state => state.get('loadingUnitView');

export const getLoadingUnitData = createAsyncSelector(
  loadingUnitScheme,
  getLoadingUnit
);
