import PropTypes from "prop-types";
import React, {Component} from "react";
import {Button, Col, message, Row} from "antd";

import {change, Field, formValueSelector, reduxForm} from "redux-form/immutable";
import {CheckboxField, DateField, TextAreaField} from "../../atoms";
import {connect} from "react-redux";
import {FORM_AGREEMENT_WITHDRAW} from "../../constants/formKeys";
import {FormActions} from "../../components";
import {tsAgreementWithdrawAction} from "../../actions/actionCreators/tsAgreementActionCreators";
import {processAsyncResult} from "../../utils/formHelpers";
import {formTsAgreementWithdrawFields} from "../../constants/forms/formTsAgreementWithdraw";
import {getAgreementWithdrawData} from "../../reducers/tsAgreementReducer";
import validate from './ValidateSchema';
import './TsAgreementWithdraw.scss';
import moment from "moment";
import {
  fetchAccountGOSTsAssignmentCount
} from "../../actions/actionCreators/accountGOSTsAssignmentsActionCreators";

export class TsAgreementWithdraw extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    selectedRowId: PropTypes.number.isRequired,
    hideWithdrawModal: PropTypes.func.isRequired,
    searchAgreements: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    resetSelection: PropTypes.func,
    updateFormValue: PropTypes.func,
    effectiveDate: PropTypes.string,
    reason: PropTypes.string,
    violation: PropTypes.bool,
  }

  isSubmitDisabled = () => {
    const {effectiveDate, reason} = this.props;
    return !(effectiveDate && reason);
  }

  handleViolationChange = (event) => {
    const {checked} = event.target;
    this.props.updateFormValue(formTsAgreementWithdrawFields.EFFECTIVE_DATE,
      checked ? moment().format('DD.MM.YYYY') : null);
  }

  render() {
    const {t, selectedRowId, violation, hideWithdrawModal, handleSubmit} = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Row gutter={32}>
          <Col span={12}>
            <div className="pb-2">
              <span className="text-bold">{t('agreements.withdraw.agreementNr')}: </span>
              <span>{selectedRowId}</span>
            </div>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={12} className="effective-date">
            <Field
              className="date-field"
              name={formTsAgreementWithdrawFields.EFFECTIVE_DATE}
              label={t('agreements.withdraw.effectiveDate')}
              tooltip={t('agreements.withdraw.effectiveDateTooltip')}
              component={DateField}
              props={{disabled: violation}}
              required
            />
          </Col>
        </Row>
        <Row gutter={64}>
          <Col span={32}>
            <Field
              name={formTsAgreementWithdrawFields.REASON}
              label={t('agreements.withdraw.reason')}
              component={TextAreaField}
              rows={12}
              maxLength={1500}
              required
            />
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={24} className="violation">
            <Field
              name={formTsAgreementWithdrawFields.VIOLATION}
              component={CheckboxField}
              checkboxText={t('agreements.withdraw.violation')}
              tooltip={t('agreements.withdraw.violationTooltip')}
              hasFeedback={false}
              onChange={this.handleViolationChange}
            />
          </Col>
        </Row>
        <FormActions>
          <Button onClick={hideWithdrawModal}>{t('agreements.withdraw.cancel')} </Button>
          <Button disabled={this.isSubmitDisabled()} type="primary" htmlType="submit">
            {t('agreements.withdraw.confirm')}
          </Button>
        </FormActions>
      </form>
    );
  }
}

const withdrawForm = formValueSelector(FORM_AGREEMENT_WITHDRAW);

const onSubmit = async (values, dispatch, props) => {
  const res = await dispatch(tsAgreementWithdrawAction({
      ...values.toJS(),
      agreementId: props.selectedRowId
    })
  );
  processAsyncResult(res);
};

const onSubmitSuccess = (result, dispatch, props) => {
  const {resetSelection, hideWithdrawModal, searchAgreements, fetchAssignmentCount, t} = props;
  message.success(t('agreements.withdraw.performSuccess'));
  resetSelection();
  hideWithdrawModal();
  searchAgreements(0);
  fetchAssignmentCount();
};

const mapStateToProps = state => ({
  initialValues: getAgreementWithdrawData(state),
  effectiveDate: withdrawForm(state, formTsAgreementWithdrawFields.EFFECTIVE_DATE),
  reason: withdrawForm(state, formTsAgreementWithdrawFields.REASON),
  violation: withdrawForm(state, formTsAgreementWithdrawFields.VIOLATION),
});

const mapDispatchToProps = (dispatch) => ({
  updateFormValue: (fieldName, value) => dispatch(change(FORM_AGREEMENT_WITHDRAW, fieldName, value)),
  fetchAssignmentCount: () => dispatch(fetchAccountGOSTsAssignmentCount())
});

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_AGREEMENT_WITHDRAW,
    validate,
    onSubmit,
    onSubmitSuccess
  })(TsAgreementWithdraw)
);
