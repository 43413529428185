import {createAction} from 'redux-actions';
import {normalize, denormalize} from 'normalizr';
import {Map, List, fromJS} from 'immutable';
import {identity, isArray, isEmpty} from 'lodash';
import {showAlert} from '../actions/pureActions/alertsActions';
import alertTypes from '../constants/alertTypes';
import {createSelector} from 'reselect';
import {tCommon} from '../i18n';
import {checkIsResponse} from '../actions/actionHelpers/request';

const normalizeHelper = entity => data => normalize(data, entity);

const toSuccess = constant => `${constant}_SUCCESS`;
const toFail = constant => `${constant}_FAIL`;
const toRequest = constant => `${constant}_REQUEST`;
const toReset = constant => `${constant}_RESET`;

const generateAsyncActionTypes = constant => ({
  requestName: toRequest(constant),
  successName: toSuccess(constant),
  failName: toFail(constant),
  resetName: toReset(constant)
});

const createResetAction = (constant) => () => {
  const {resetName} = generateAsyncActionTypes(
    constant
  );
  const resetType = createAction(resetName);
  return dispatch => dispatch(resetType());
};

const createAsyncAction = (constant, request, after = identity) => (
  ...args
) => {
  const {requestName, successName, failName} = generateAsyncActionTypes(
    constant
  );

  const pendingType = createAction(requestName);
  const completeType = createAction(successName);
  const errorType = createAction(failName);

  return async dispatch => {
    dispatch(pendingType());

    try {
      const response = await request(...args);
      const data =
        checkIsResponse(response) && response.data ? response.data : response;
      const payload = data ? await after(data, dispatch) : null;

      dispatch(completeType(payload));
      return payload;
    } catch (error) {
      if (error.response) {
        const errorMessage = isEmpty(error.response.data.message)
          ? error.response.data
          : tCommon(`backendError.${error.response.data.message}`);
        if (error.response.status !== 401) {
          dispatch(
            showAlert({
              type: alertTypes.ERROR,
              message: errorMessage,
            })
          );
          dispatch(errorType(errorMessage));
        }
        return {error: errorMessage};
      }

      dispatch(errorType(error));

      dispatch(
        showAlert({
          type: alertTypes.ERROR,
          message: error,
        })
      );
      return error;
    }
  };
};

const initialAsyncState = Map({loading: false, data: null, error: ''});
const listInitialAsyncState = Map({loading: false, data: List(), error: ''});

const createAsyncReducer = (asyncType, node) => {
  const mergeFunction = (state, obj) =>
    node ? state.mergeIn([node], obj) : state.merge(obj);

  const {requestName, successName, failName, resetName} = generateAsyncActionTypes(
    asyncType
  );

  return {
    [requestName]: state =>
      mergeFunction(state, {
        loading: true,
      }),
    [successName]: (state, {payload}) =>
      mergeFunction(state, {
        loading: false,
        data:
          payload && payload.result ? fromJS(payload.result) : fromJS(payload),
      }),
    [failName]: (state, {payload}) =>
      mergeFunction(state, {
        loading: false,
        error: payload,
      }),
    [resetName]: (state, {payload}) =>
      mergeFunction(state, {
        loading: false,
        error: '',
        data: List()
      }),
  };
};

/**
 * @deprecated
 */
const entitiesSelectorList = schema => (state, list) => {
  const allEntities = state.get('entities');
  const allList = allEntities.get(schema.key);

  if (!list || !list.get('data')) {
    return List();
  }

  const data = list.get('data').map(id => allList.get(id.toString()));
  return schema ? denormalize(data, [schema], allEntities) : data;
};

/**
 * @deprecated
 */
const entitiesSelectorItem = schema => (state, id) => {
  const allEntities = state.get('entities');
  const allList = allEntities.get(schema.key);

  if (!id || !id.get('data')) {
    return Map();
  }

  const data = allList ? allList.get(id.get('data').toString()) : allList;
  return schema ? denormalize(data, schema, allEntities) : data;
};

const getEntityById = (state, schema, id) =>
  state.getIn(['entities', schema.key, id.toString()]);

const createAsyncSelector = (schema, selector) =>
  createSelector(
    [selector, state => state.get('entities')],
    (listOrId, allList) => {
      const dataListOrId = listOrId ? listOrId.get('data') : null;

      if (!dataListOrId) {
        return isArray(schema) ? List() : Map();
      }

      return schema ? denormalize(dataListOrId, schema, allList) : dataListOrId;
    }
  );

const _getLoadingSelector = state => selector => selector(state).get('loading');

const getLoadingSelector = (...selectors) => state =>
  selectors.some(_getLoadingSelector(state));

const getGOSSelectorItems = schema => () => {
  return null;
};

const compileUrl = (url, values) => {
  const query = Object.keys(values).filter(key => !!values[key]).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(values[key])).join('&');
  return url + ((url.indexOf('\?') === -1 && query) ? '\?' : '') + query;
};

export {
  normalizeHelper,
  generateAsyncActionTypes,
  createAsyncAction,
  createResetAction,
  initialAsyncState,
  listInitialAsyncState,
  createAsyncReducer,
  entitiesSelectorList,
  entitiesSelectorItem,
  toSuccess,
  toRequest,
  toFail,
  createAsyncSelector,
  getLoadingSelector,
  getEntityById,
  getGOSSelectorItems,
  compileUrl
};
