import {TYPE_B, TYPE_E, TYPE_KHG, TYPE_S} from "../../constants/accountGOS";
import {Col, Row} from "antd";
import {numberFormatter} from "../../utils/formaters";
import {translateClassificator} from "../../utils/translateHelpers";
import moment from "moment";
import React from "react";

export function renderModalDetails(t, props) {
  const {
    type,
    expirationDate,
    naturalEnergyAmount,
    calculatedMultiplier,
    calculatedEnergyAmount,
    biofuelType,
    ghgCapacity,
    ghgAmount,
    consumptionMonth,
    consumptionYear,
    consumptionProvider,
    consumptionProviderTransactionId,
    productionPeriod,
    productionUnitEicwCode,
    posNumber,
    energyType,
    biofuelComponentCn,
    sustainableBiofuel,
    translatedFuelType,
    feedstock,
    productionPathway,
    lowerCalorificValueKg,
    landUseCategory,
    landUseEmissions,
  } = props;

  const isB = type === TYPE_B;
  const isE = type === TYPE_E;
  const isS = type === TYPE_S;
  const isKHG = type === TYPE_KHG;

  return (
    <div>
      <Row className="global-margin-bottom-10">
        <Col>
          <div><b>{t('details.type.title')}: </b>{t('details.type' + type)}</div>
          <div><b>{t('details.expirationDate')}: </b>{expirationDate}</div>
          {!isKHG &&
            <div><b>{t('details.naturalEnergyAmount')}: </b>{numberFormatter(naturalEnergyAmount)}
            </div>}
          {!isKHG && <div><b>{t('details.calculatedMultiplier')}: </b>{calculatedMultiplier}</div>}
          {!isKHG && <div>
            <b>{t('details.calculatedEnergyAmountMj')}: </b>{numberFormatter(calculatedEnergyAmount)}
          </div>}
          {(isB || isS) && <div>
            <b>{t('details.biofuelType')}: </b>{translateClassificator('biofuelType')(biofuelType)}
          </div>}
          {(isB || isE || isS) &&
            <div><b>{t('details.ghgCapacity')}: </b>{numberFormatter(ghgCapacity)}</div>}
          {isKHG && <div><b>{t('details.ghgAmount')}: </b>{numberFormatter(ghgAmount)}</div>}
        </Col>
      </Row>
      {!isKHG && <Row className="global-margin-bottom-10">
        <Col>
          {!isKHG && <div>
            <b>{t('details.consumptionPeriod')}: </b>{(isB || isE) ? consumptionMonth + '.' : ''}{consumptionYear}
          </div>}
          {(isB || isE) &&
            <div><b>{t('details.consumptionProvider')}: </b>{consumptionProvider}</div>}
          {(isB || isE) && <div>
            <b>{t('details.consumptionProviderTransactionId')}: </b>{consumptionProviderTransactionId}
          </div>}
          {isB && <div>
            <b>{t('details.productionPeriod')}: </b>{moment(productionPeriod, 'DD.MM.YYYY').format('MM.YYYY')}
          </div>}
          {isB && <div><b>{t('details.productionUnitEicwCode')}: </b>{productionUnitEicwCode}</div>}
          {isB && <div><b>{t('details.posNumber')}: </b>{posNumber}</div>}
        </Col>
      </Row>}
      {isB && <Row className="global-margin-bottom-10">
        <Col>
          <div><b>{t('details.energyType')}: </b>{translateClassificator('energyType')(energyType)}
          </div>
          <div><b>{t('details.biofuelComponentCn')}: </b>{biofuelComponentCn}</div>
          <div>
            <b>{t('details.sustainableBiofuel')}: </b>{translateClassificator('sustainableBiofuel')(sustainableBiofuel + '')}
          </div>
          <div><b>{t('details.fuelType')}: </b>{translatedFuelType}</div>
          <div><b>{t('details.feedstock')}: </b>{translateClassificator('feedstock')(feedstock)}
          </div>
          <div>
            <b>{t('details.productionPathway')}: </b>{translateClassificator('productionPathway')(productionPathway)}
          </div>
          <div><b>{t('details.lowerCalorificValueKg')}: </b>{lowerCalorificValueKg}</div>
          <div>
            <b>{t('details.landUseCategory')}: </b>{translateClassificator('landUseCategory')(landUseCategory)}
          </div>
          <div><b>{t('details.landUseEmissions')}: </b>{landUseEmissions}</div>
        </Col>
      </Row>}
    </div>
  );
}
