import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import {isEmpty} from 'lodash';
import {isAvpConsumer} from '../../utils/roles';
import i18n from '../../i18n';
import {ConsumerGoList} from '../../containers';
import {ConsumerGoFilter} from '../../forms';
import * as actions from '../../actions/actionCreators/settingsActionCreators';
import * as chartsActions from '../../actions/actionCreators/consumerChartActionCreators';
import {getSellers, getSellersData} from '../../reducers/consumerChartReducer';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {Spin, Form} from 'antd';
import {Select} from '../../atoms';
import {getRole} from '../../reducers/userReducer';
import {accountFields} from '../../constants/account';
import {ConsumerChartYear, ConsumerChartSeller} from '../../containers';
import './consumerReports.css';
import {links} from "../../utils";

const FormItem = Form.Item;

const mapEleringPortalLocales = {
  'en-US': 'en',
  'et-EE': 'et',
};

class ConsumerReports extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        locale: PropTypes.string,
      }),
    }),
    changeLocale: PropTypes.func.isRequired,
    fetchConsumerSellers: PropTypes.func.isRequired,
    role: PropTypes.string,
  };

  static defaultProps = {
    role: '',
  };

  state = {
    selectedSeller: null,
  };

  handleSellerChange = value => {
    this.setState({
      selectedSeller: value,
    });
  };

  componentWillReceiveProps(nextProps) {
    const {sellers: oldSellers, role} = this.props;
    const {sellers: newSellers} = nextProps;

    if (
      !isEmpty(role) &&
      newSellers.count() !== oldSellers.count() &&
      newSellers.count() === 1
    ) {
      this.handleSellerChange(newSellers.getIn([0, 'id']));
    }
  }

  componentDidMount() {
    const {
      changeLocale,
      fetchConsumerSellers,
      match: {params: {locale}},
    } = this.props;

    if (locale && mapEleringPortalLocales[locale]) {
      i18n.changeLanguage(mapEleringPortalLocales[locale], () =>
        changeLocale(mapEleringPortalLocales[locale])
      );
    }
    fetchConsumerSellers();
  }

  render() {
    const {t, sellers, isLoading, role} = this.props;
    const {selectedSeller} = this.state;
    const sellerData = sellers.find(s => s.get('id') === selectedSeller);
    const isAvp = isAvpConsumer(role);

    return (
      <div className="main-container">
        {!isAvp && (
          <Spin spinning={isLoading}>

            <div className="ant-form-item-label logout-button">
              <a href={links.oauthLogout}>
                {t('logout')}
              </a>
            </div>

            <div className="consumer-header">
              <FormItem label={t('seller')}>
                <Select
                  options={sellers.toJS()}
                  value={selectedSeller}
                  valueKey={accountFields.ID}
                  labelKey={accountFields.LEGAL_ENTITY_NAME}
                  onChange={this.handleSellerChange}
                />
              </FormItem>
            </div>
          </Spin>
        )}
        <div className="consumer-charts">
          <ConsumerChartYear t={t} isAvp={isAvp}/>
          {!isAvp && (
            <ConsumerChartSeller
              t={t}
              seller={selectedSeller}
              sellerName={
                sellerData
                  ? sellerData.get(accountFields.LEGAL_ENTITY_NAME)
                  : ''
              }
              isAvp={isAvp}
            />
          )}
        </div>
        {!isAvp && (
          <React.Fragment>
            <ConsumerGoFilter t={t}/>
            <ConsumerGoList t={t}/>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const getIsLoading = getLoadingSelector(getSellers);

const mapStateToProps = state => ({
  isLoading: getIsLoading(state),
  sellers: getSellersData(state),
  role: getRole(state),
});

const mapDispatchToProps = {
  changeLocale: actions.changeLocaleActionCreator,
  fetchConsumerSellers: chartsActions.fetchConsumerSellers,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  translate(NAMESPACES.consumerReports)(ConsumerReports)
);
