import React, {Component} from 'react';
import PropTypesImmutable from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import * as actions from '../../actions/actionCreators/accountGOSTsAssignmentsActionCreators';
import {
  getAccountGOSTsAssignmentsList,
  getAccountGOSTsAssignmentsListData
} from "../../reducers/accountGOSTsAssignmentsListReducer";
import {AccountGOSTsAssignmentsSearchTable} from "../../components";

export class AccountGOSTsAssignmentsList extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    tsAssignmentsList: PropTypesImmutable.map.isRequired,
    tsAssignmentsListData: PropTypesImmutable.list,
    registerCode: PropTypes.string.isRequired,
  };

  componentDidMount() {
    const {searchAccountGOSTsAssignments} = this.props;
    searchAccountGOSTsAssignments(0);
  }

  render() {
    const {
      tsAssignmentsList,
      tsAssignmentsListData,
      tsAssignmentsLoading,
      searchAccountGOSTsAssignments,
      ...rest
    } = this.props;

    return (
      <React.Fragment>
        <AccountGOSTsAssignmentsSearchTable tsAssignments={tsAssignmentsListData.toJS()}
                                            search={searchAccountGOSTsAssignments}
                                            {...rest}
                                            {...tsAssignmentsList.toJS()}/>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  tsAssignmentsList: getAccountGOSTsAssignmentsList(state),
  tsAssignmentsListData: getAccountGOSTsAssignmentsListData(state),
  tsAssignmentsLoading: getLoadingSelector(getAccountGOSTsAssignmentsList)(state),
});

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  AccountGOSTsAssignmentsList
);
