import React, {Component} from 'react';
import PropTypesImmutable from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {formValueSelector} from 'redux-form/immutable';
import {FORM_TRANSACTION_FOR_GOS_SEARCH} from '../../constants/formKeys';
import {TransactionGosTable} from '../../components';
import {
  getTransactionForGosList,
  getTransactionForGosListData,
} from '../../reducers/transactionForGosSearchReducer';
import {getRole} from '../../reducers/userReducer';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import * as actions from '../../actions/actionCreators/transactionForGosSearchActionCreators';
import {taTransactionsFilterFields} from '../../constants/formAccountGOSTransactionsFilter';

class TransactionGosList extends Component {
  static propTypes = {
    transactionsData: PropTypesImmutable.list,
    transactions: PropTypesImmutable.map.isRequired,
    filterValues: PropTypes.object.isRequired,
  };

  render() {
    const {transactions, transactionsData, ...rest} = this.props;

    return (
      <React.Fragment>
        <TransactionGosTable
          transactions={transactionsData.toJS()}
          {...rest}
          {...transactions.toJS()}
        />
      </React.Fragment>
    );
  }
}

const getIsLoading = getLoadingSelector(getTransactionForGosList);

const searchForm = formValueSelector(FORM_TRANSACTION_FOR_GOS_SEARCH);

const mapStateToProps = state => ({
  transactions: getTransactionForGosList(state),
  loading: getIsLoading(state),
  transactionsData: getTransactionForGosListData(state),
  userRole: getRole(state),
  filterValues: searchForm(
    state,
    ...Object.values(taTransactionsFilterFields)
  ),
});

const mapDispatchToProps = dispatch => ({
  searchTransactions: page => {
    dispatch(actions.searchTATransactionsAction(page));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionGosList);
