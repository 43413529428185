export default {
  BIOGAS_DRY_MANUR_CNG:
    'BIOGAS_DRY_MANUR_CNG',
  BIOGAS_ORG_WASTE_CNG:
    'BIOGAS_ORG_WASTE_CNG',
  BIOGAS_WET_MANUR_CNG:
    'BIOGAS_WET_MANUR_CNG',
  FARMED_WOOD_DME: 'FARMED_WOOD_DME',
  FARMED_WOOD_ETHANOL: 'FARMED_WOOD_ETHANOL',
  FARMED_WOOD_FISCHER_TROPSCH_DIESEL: 'FARMED_WOOD_FISCHER_TROPSCH_DIESEL',
  FARMED_WOOD_METHANOL: 'FARMED_WOOD_METHANOL',
  HYDROTREATED_VEGETABLE_OIL_FROM_PALM_OIL:
    'HYDROTREATED_VEGETABLE_OIL_FROM_PALM_OIL',
  HYDROTREATED_VEGETABLE_OIL_FROM_PALM_OIL_METHANE:
    'HYDROTREATED_VEGETABLE_OIL_FROM_PALM_OIL_METHANE',
  HYDROTREATED_VEGETABLE_OIL_FROM_RAPE_SEED:
    'HYDROTREATED_VEGETABLE_OIL_FROM_RAPE_SEED',
  HYDROTREATED_VEGETABLE_OIL_FROM_SUNFLOWER:
    'HYDROTREATED_VEGETABLE_OIL_FROM_SUNFLOWER',
  MTBE_RENEWABLE_COMPONENT: 'MTBE_RENEWABLE_COMPONENT',
  PALM_OIL_BIODIESEL: 'PALM_OIL_BIODIESEL',
  PALM_OIL_BIODIESEL_METHANE: 'PALM_OIL_BIODIESEL_METHANE',
  PURE_VEGETABLE_OIL_FROM_RAPE_SEED: 'PURE_VEGETABLE_OIL_FROM_RAPE_SEED',
  RAPE_SEED_BIODIESEL: 'RAPE_SEED_BIODIESEL',
  SOYBEAN_BIODIESEL: 'SOYBEAN_BIODIESEL',
  SUGAR_BEET_ETHANOL: 'SUGAR_BEET_ETHANOL',
  SUGAR_CANE_ETHANOL: 'SUGAR_CANE_ETHANOL',
  SUNFLOWER_BIODIESEL: 'SUNFLOWER_BIODIESEL',
  WASTE_VEGETABLE_OIL_OR_ANIMAL_FAT_BIODIESEL:
    'WASTE_VEGETABLE_OIL_OR_ANIMAL_FAT_BIODIESEL',
  WASTE_WOOD_DME: 'WASTE_WOOD_DME',
  WASTE_WOOD_ETHANOL: 'WASTE_WOOD_ETHANOL',
  WASTE_WOOD_FISCHER_TROPSCH_DIESEL: 'WASTE_WOOD_FISCHER_TROPSCH_DIESEL',
  WASTE_WOOD_METHANOL: 'WASTE_WOOD_METHANOL',
  WHEAT_ETHANOL_LIGNITE_CHP: 'WHEAT_ETHANOL_LIGNITE_CHP',
  WHEAT_ETHANOL_GAS_CHP: 'WHEAT_ETHANOL_GAS_CHP',
  WHEAT_ETHANOL_CONVENTIONAL_BOILER: 'WHEAT_ETHANOL_CONVENTIONAL_BOILER',
  WHEAT_ETHANOL_PROCESS_FUEL_NOT_SPECIFIED:
    'WHEAT_ETHANOL_PROCESS_FUEL_NOT_SPECIFIED',
  WHEAT_STRAW_ETHANOL: 'WHEAT_STRAW_ETHANOL',
  OTHER: 'OTHER',
};
