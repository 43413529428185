import {createAsyncAction, normalizeHelper} from '../../utils/asyncHelpers';
import * as actionTypes from '../../constants/registerActionTypes';
import {mandatePerson} from '../../schemas';
import {
  registerMandateFetch,
  registerMandateUpdate,
} from '../actionHelpers/endpoints';

export const fetchRegisterMandate = createAsyncAction(
  actionTypes.REGISTER_MANDATE_FETCH,
  registerMandateFetch,
  normalizeHelper(mandatePerson)
);
export const updateRegisterMandate = createAsyncAction(
  actionTypes.REGISTER_MANDATE_UPDATE,
  registerMandateUpdate,
  normalizeHelper(mandatePerson)
);
