export const ADD_MODAL = 'ADD_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const HIDE_ALL_MODALS = 'HIDE_ALL_MODALS';

export const modalNames = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  REGISTER_REPRESENT_COMPANY: 'REGISTER_REPRESENT_COMPANY',
  REGISTER_REPRESENT_PERSON: 'REGISTER_REPRESENT_PERSON',
  REGISTER_MEASUREMENT: 'REGISTER_MEASUREMENT',
  PRODUCTION_UNIT: 'PRODUCTION_UNIT',
  LOADING_UNIT: 'LOADING_UNIT',
  REGISTER_CONTACT: 'REGISTER_CONTACT',
  MEASURING_UPLOAD: 'MEASURING_UPLOAD',
  NOTIFICATION_VIEW: 'NOTIFICATION_VIEW',
  ADD_CONSUMPTION_POINT_CONSUMER: 'ADD_CONSUMPTION_POINT_CONSUMER',
  VIEW_METERING_POINT_CONSUMER: 'VIEW_METERING_POINT_CONSUMER',
  REQUEST_CHANGE_EQUIPMENT: 'REQUEST_CHANGE_EQUIPMENT',
  REQUEST_CHANGE_LOADING_UNIT: 'REQUEST_CHANGE_LOADING_UNIT',
  REQUEST_CHANGE_LEGAL_ENTITY: 'REQUEST_CHANGE_LEGAL_ENTITY',
  REQUEST_CHANGE_METERING: 'REQUEST_CHANGE_METERING',
  CLASSIFIER_PRICE_EDIT: 'CLASSIFIER_PRICE_EDIT',
  INVOICE_CONFIRM: 'INVOICE_CONFIRM',
  INVOICE_TERMS: 'INVOICE_TERMS',
  CLASSIFIER_GENERAL_EDIT: 'CLASSIFIER_GENERAL_EDIT',
  CLASSIFIER_SUB_EDIT: 'CLASSIFIER_SUB_EDIT',
  CLASSIFIER_SUB_VALUES_EDIT: 'CLASSIFIER_SUB_VALUES_EDIT',
  PARAMETER_GRID_OPERATOR_EDIT: 'PARAMETER_GRID_OPERATOR_EDIT',
  PARAMETER_SELLER_EDIT: 'PARAMETER_SELLER_EDIT',
  RECALCULATE_ELECTRIC_GOS_MODAL: 'RECALCULATE_EKECTRIC_GOS_MODAL',
  RECALCULATE_GO_MODAL: 'RECALCULATE_GO_MODAL',
  PURCHASING_AGREEMENT_WITHDRAW: 'PURCHASING_AGREEMENT_WITHDRAW',
  SELLING_AGREEMENT_WITHDRAW: 'SELLING_AGREEMENT_WITHDRAW',
  TS_AGREEMENT_CONFIRM: 'TS_AGREEMENT_CONFIRM',
  TS_AGREEMENT_CONFIRM_VIEW: 'TS_AGREEMENT_CONFIRM_VIEW',
  AGREEMENT_WITHDRAW_VIEW: 'AGREEMENT_WITHDRAW_VIEW',
  AGREEMENT_WITHDRAW_CONFIRM: 'AGREEMENT_WITHDRAW_CONFIRM',
  AGREEMENT_TRANSACTION_CONFIRM: 'AGREEMENT_TRANSACTION_CONFIRM',
  CERTIFICATE_DETAILS_VIEW: 'CERTIFICATE_DETAILS_VIEW',
  AGREEMENT_TRANSACTION_VIEW: 'AGREEMENT_TRANSACTION_VIEW',
  RESERVATIONS_CONFIRM: 'RESERVATIONS_CONFIRM',
  RESERVATION_DETAILS_VIEW: 'RESERVATION_DETAILS_VIEW',
  TRANSPORT_STATISTICS_DETAILS_MODAL: 'TRANSPORT_STATISTICS_DETAILS_MODAL',
  TRANSPORT_STATISTICS_ADDITIONAL_INFO_MODAL: 'TRANSPORT_STATISTICS_ADDITIONAL_INFO_MODAL',
  AUCTION_RESULT_CONFIRM_MODAL: 'AUCTION_RESULT_CONFIRM_MODAL',
  AUCTION_RESULT_VIEW_MODAL: 'AUCTION_RESULT_VIEW_MODAL',
  TS_AUCTION_BID_MODAL: 'TS_AUCTION_BID_MODAL'
};
