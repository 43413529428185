import React, {Component} from "react";
import {translate} from "react-i18next";
import {NAMESPACES} from "../../i18n";
import PropTypes from "prop-types";
import _ from "lodash";
import {Button, InputNumber, message, Modal, Popconfirm, Table} from "antd";
import {TYPE_B, TYPE_E, TYPE_KHG, TYPE_S} from "../../constants/accountGOS";
import {
  translateBiofuelTypeClassificator,
  translateFeedstockClassificator,
  translateFuelTypeClassificator,
  translateProductionPathwayClassificator,
} from '../../utils/translateHelpers';
import {TableActions, TableRowActions} from "../../components";
import {goBack} from "../../utils/gotoLink";
import {tradingAccountOverviewTableFields} from "../../constants/TradingAccountOverviewTable";
import moment from "moment";
import {renderModalDetails} from "./OverviewModalDetails";

class TradingAccountOverviewDetailsTable extends Component {
  static propTypes = {
    dataList: PropTypes.array,
    t: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    performAction: PropTypes.func.isRequired,
    goAmountTotal: PropTypes.number,
    isCancelAvailable: PropTypes.bool,
    popconfirmTitle: PropTypes.string,
    isCanceling: PropTypes.bool,
    isLocking: PropTypes.bool
  };

  static defaultProps = {
    isCanceling: false,
    isCancelAvailable: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      dataList: props.dataList,
      selectedValue: null,
      modalDetails: {},
      modalDetailsVisible: false,
      certificatesMjValue: props.type === TYPE_KHG ? this.recalculateGHGSummary(props.dataList).toFixed(1) : this.recalculateMjValue(props.dataList).toFixed(1),
      isB: props.type === TYPE_B,
      isE: props.type === TYPE_E,
      isS: props.type === TYPE_S,
      isKHG: props.type === TYPE_KHG
    };
  }

  onValueMjChange = (value, id) => {
    this.setState(oldState => {
      const oldList = oldState.dataList.slice();

      const index = _.findIndex(
        oldState.dataList,
        item => item[tradingAccountOverviewTableFields.ID] === id
      );

      const findItem = oldList[index];
      const newNumericValue = parseFloat(value) || 0;
      const multiplier = oldList[index][tradingAccountOverviewTableFields.CALCULATED_MULTIPLIER];
      oldList[index] = {
        ...findItem,
        [tradingAccountOverviewTableFields.NATURAL_ENERGY_TRANSFER_AMOUNT]: newNumericValue,
        [tradingAccountOverviewTableFields.CALCULATED_ENERGY_TRANSFER_AMOUNT]: newNumericValue * multiplier
      };

      return {
        ...oldState,
        dataList: oldList,
        certificatesMjValue: this.recalculateMjValue(oldList).toFixed(1),
      };
    });
  };

  onGHGValueChange = (value, id) => {
    this.setState(oldState => {
      const oldList = oldState.dataList.slice();

      const index = _.findIndex(
        oldState.dataList,
        item => item[tradingAccountOverviewTableFields.ID] === id,
      );

      const findItem = oldList[index];
      const newNumericValue = parseFloat(value) || 0;
      oldList[index] = {
        ...findItem,
        [tradingAccountOverviewTableFields.GHG_TRANSFER_AMOUNT]: newNumericValue,
      };

      return {
        ...oldState,
        dataList: oldList,
        certificatesMjValue: this.recalculateGHGSummary(oldList).toFixed(1),
      };
    });
  };

  onRemove = ID => {
    const {t, type} = this.props;
    this.setState(oldState => {
      if (oldState.dataList.length === 1) {
        message.warning(t('warningOne'));
        return oldState;
      }

      const dataList = _.remove(
        oldState.dataList,
        item => item[tradingAccountOverviewTableFields.ID] !== ID
      );

      return {
        dataList,
        certificatesMjValue: type === TYPE_KHG ? this.recalculateGHGSummary(dataList).toFixed(1) : this.recalculateMjValue(dataList).toFixed(1),
      };
    });
  };

  handleShowDetailsModal = (details) => {
    this.setState({modalDetails: details});
    this.setState({modalDetailsVisible: true});
  };

  hideDetailModal = () => {
    this.setState({modalDetailsVisible: false});
    this.setState({modalDetails: {}});
  };

  onCostChange = (value, ID) => {
    this.setState(oldState => {
      const oldList = oldState.dataList.slice();

      const index = _.findIndex(
        oldState.dataList,
        item => item[tradingAccountOverviewTableFields.ID] === ID
      );

      const findItem = oldList[index];

      oldList[index] = {
        ...findItem,
        [tradingAccountOverviewTableFields.COST]: parseFloat(value) || 0,
      };

      return {
        ...oldState,
        dataList: oldList,
      };
    });
  };

  getColumns = () => {
    const {t, isLocking} = this.props;
    const {isB, isE, isS, isKHG} = this.state;

    const columns = [];

    columns.push({
      title: t('table.expirationTime'),
      dataIndex: tradingAccountOverviewTableFields.EXPIRATION_DATE,
    });


    if (isB) {
      columns.push({
        title: t('table.eicwCode'),
        dataIndex: tradingAccountOverviewTableFields.PRODUCTION_UNIT_EICW_CODE
      }, {
        title: t('table.productionTime'),
        dataIndex: tradingAccountOverviewTableFields.PRODUCTION_PERIOD,
        render: v => moment(v, 'DD.MM.YYYY').format('MM.YYYY')
      }, {
        title: t('table.fuelType'),
        dataIndex: tradingAccountOverviewTableFields.FUEL_TYPE,
        render: translateFuelTypeClassificator
      }, {
        title: t('table.feedstock'),
        dataIndex: tradingAccountOverviewTableFields.FEEDSTOCK,
        render: translateFeedstockClassificator
      }, {
        title: t('table.productionPathway'),
        dataIndex: tradingAccountOverviewTableFields.PRODUCTION_PATHWAY,
        render: translateProductionPathwayClassificator
      });
    }


    if (isB || isS) {
      columns.push({
        title: t('table.biofuelType'),
        dataIndex: tradingAccountOverviewTableFields.BIOFUEL_TYPE,
        render: translateBiofuelTypeClassificator
      });
    }

    if (isB || isE) {
      columns.push({
        title: t('table.consumptionTime'),
        render: (_, row) => {
          return moment(`${row[tradingAccountOverviewTableFields.CONSUMPTION_MONTH]}.${row[tradingAccountOverviewTableFields.CONSUMPTION_YEAR]}`, 'MM.YYYY').format('MM.YYYY');
        }
      });
    }

    if (isB || isE || isS) {
      columns.push({
        title: t('table.calculatedMultiplier'),
        dataIndex: tradingAccountOverviewTableFields.CALCULATED_MULTIPLIER
      }, {
        title: t('table.naturalQuantityMj'),
        dataIndex: tradingAccountOverviewTableFields.NATURAL_ENERGY_TRANSFER_AMOUNT,
        render: (value, row) => {
          const ID = row[tradingAccountOverviewTableFields.ID];
          const maxAmount = row[tradingAccountOverviewTableFields.NATURAL_ENERGY_AMOUNT];
          return (
            <InputNumber
              min={0.1}
              step={0.1}
              precision={1}
              max={maxAmount}
              value={value}
              style={{width: '100%'}}
              onChange={numberValueMj =>
                this.onValueMjChange(numberValueMj, ID)
              }
            />
          );
        }
      }, {
        title: t('table.calculatedEnergyAmountMj'),
        dataIndex: tradingAccountOverviewTableFields.CALCULATED_ENERGY_TRANSFER_AMOUNT
      });
    }

    if (isKHG) {
      columns.push({
        title: t('table.ghg'),
        dataIndex: tradingAccountOverviewTableFields.GHG_AMOUNT,
      }, {
        title: t('table.ghg' + (isLocking ? "Report" : "Transfer")),
        dataIndex: tradingAccountOverviewTableFields.GHG_TRANSFER_AMOUNT,
        render: (value, row) => {
          const id = row[tradingAccountOverviewTableFields.ID];
          const minGHGAmount = row[tradingAccountOverviewTableFields.GHG_AMOUNT];
          return (
            <InputNumber
              min={minGHGAmount}
              max={-0.1}
              step={0.1}
              precision={1}
              value={value}
              style={{width: '100%'}}
              onChange={value =>
                this.onGHGValueChange(value, id)
              }
            />
          );
        },
      });
    }

    if (!isLocking) {
      columns.push({
        title: t('table.cost'),
        dataIndex: tradingAccountOverviewTableFields.COST,
        render: (value, row) => {
          const ID = row[tradingAccountOverviewTableFields.ID];

          return (
            <InputNumber
              min={0.0}
              max={99999999.9}
              precision={2}
              value={value}
              onChange={costValue => this.onCostChange(costValue, ID)}
              step={0.01}
            />
          );
        },
      });
    }

    columns.push({
      title: t('table.actions'),
      width: 100,
      dataIndex: tradingAccountOverviewTableFields.ID,
      render: (value, row) => (
        <TableRowActions>
          <Button
            shape="circle"
            icon="delete"
            size="small"
            type="primary"
            onClick={() => this.onRemove(value)}
          />
          <Button
            shape="circle"
            icon="select"
            size="small"
            style={{marginLeft: '8px'}}
            type="primary"
            title={t('table.viewBtn')}
            onClick={() => this.handleShowDetailsModal(row)}
          />
        </TableRowActions>
      ),
    });

    return columns;
  };

  recalculate = newState => {
    return newState;
  };

  recalculateMjValue = newState => newState.reduce((sum, current) => _.get(current, tradingAccountOverviewTableFields.CALCULATED_ENERGY_TRANSFER_AMOUNT) + sum, 0);

  recalculateGHGSummary = newState => newState.reduce((sum, current) => _.get(current, tradingAccountOverviewTableFields.GHG_TRANSFER_AMOUNT) + sum, 0);

  preTransferValidatePrice(dl) {
    let it = 0;
    for (; it < dl.length; it++) {
      const dataO = dl[it];
      if (dataO[tradingAccountOverviewTableFields.COST] == null) {
        return true;
      }
    }
    return false;
  }

  noZerosCheck(dl) {
    let it = 0;
    for (; it < dl.length; it++) {
      const dataO = dl[it];
      if (!dataO[tradingAccountOverviewTableFields.CALCULATED_ENERGY_TRANSFER_AMOUNT]) {
        return true;
      }
    }
    return false;
  }

  noZerosCheckGHG(dl) {
    let it = 0;
    for (; it < dl.length; it++) {
      const dataO = dl[it];
      if (!dataO[tradingAccountOverviewTableFields.GHG_TRANSFER_AMOUNT]) {
        return true;
      }
    }
    return false;
  }

  handlePerformClick = async () => {
    const {t, performAction, isCanceling, isLocking} = this.props;
    const {dataList, isKHG} = this.state;

    if (!isCanceling && !isLocking && this.preTransferValidatePrice(dataList)) {
      // If price is missing, halt transfer
      return message.error(t('errorMissingPrice'));
    }

    if (!isKHG && this.noZerosCheck(dataList)) {
      return message.error(t('errorRemoveZeroQuantityRows'));
    }

    if (isKHG && this.noZerosCheckGHG(dataList)) {
      return message.error(t('errorRemoveZeroGHGRows'));
    }

    const result = await performAction(this.recalculate(dataList));

    if (!result || !result.error) {
      message.success(t('successPerform'));
      goBack();
    }
  };

  render() {
    const {dataList, certificatesMjValue} = this.state;
    const {t, isCanceling, isCancelAvailable, popconfirmTitle} = this.props;

    return (
      <React.Fragment>
        <Modal
          title={t('detailsTitle')}
          visible={this.state.modalDetailsVisible}
          footer={false}
          width="400px"
          onCancel={this.hideDetailModal}
        >
          {renderModalDetails(t, this.state.modalDetails)}
        </Modal>
        <Table
          scroll={{x: 'auto'}}
          rowKey={tradingAccountOverviewTableFields.ID}
          dataSource={dataList}
          columns={this.getColumns()}
          pagination={false}
          footer={() => (
            <div className="global-align-right">
              {t('footer', {value: certificatesMjValue})}
            </div>
          )}
        />
        <TableActions>
          <Button onClick={goBack}>{t('buttonBack')}</Button>
          <Popconfirm
            title={isCanceling ? t('popconfirmTitleCancel') : popconfirmTitle}
            onConfirm={this.handlePerformClick}
            okText={t('popconfirmButtonYes')}
            cancelText={t('popconfirmButtonNo')}
          >
            {(!isCanceling || isCancelAvailable) && (
              <Button type="primary" className="global-margin-left-10">
                {t('buttonPerform')}
              </Button>
            )}
          </Popconfirm>
        </TableActions>
      </React.Fragment>
    );
  }
}

export default translate(NAMESPACES.tradingAccount)(
  TradingAccountOverviewDetailsTable
);
