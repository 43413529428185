import React, {Component} from "react";
import PropTypes from "prop-types";
import {Button, Col, Icon, Row, Tooltip} from "antd";
import {getInfoModal} from "./TsAdditionalInfoModal";
import {numberFormatter} from "../../utils/formaters";
import {
  calculateTransactionCost
} from "../../components/TsAuctionSearchTable/TsAuctionSearchTableColumns";
import FuelType from "../../constants/classificators/fuelType";
import {translateTsFuelTypeClassificator} from "../../utils/translateHelpers";

export class TsAuctionDetails extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    auctionId: PropTypes.string.isRequired,
    auction: PropTypes.object.isRequired,
    year: PropTypes.number
  };

  state = {
    isAdditionalInfoVisible: false
  }

  renderRow = (title, value) => {
    return (
      <div>
        <span><b>{title}: </b></span>
        <span>{value}</span>
      </div>
    )
  }

  renderRowWithTooltip = (label, value, tooltipTitle) => (
    <div>
      <span className="text-bold">
        {label}&nbsp;
        <Tooltip title={tooltipTitle}>
          <Icon type="question-circle-o"/>
        </Tooltip>
      </span>
      <span>: </span>
      <span>{value}</span>
    </div>
  );

  getFulfillmentDate = (auction) => {
    const {t} = this.props;

    if (auction.reservationFulfilled)
      return auction.reservationFulfilled
    if (auction.transactionExpired)
      return t('details.transactionExpired')
    return null;
  }

  render() {
    const {t, auction, auctionId, year} = this.props;
    const {isAdditionalInfoVisible} = this.state;

    const isKhg = auction.fuelType === FuelType.KHG;
    const khg = isKhg ? 'Khg' : '';

    const reservationFulfilled = this.getFulfillmentDate(auction);

    return (
      <React.Fragment>
        {getInfoModal.call(this, isAdditionalInfoVisible, t('details.additionalInfo'), auction.additionalInfo)}

        <h2>{t('details.auctionTitle')}</h2>
        <Row className="mb-4 global-margin-top-24">
          <Col span={8} className="custom-col">
            {this.renderRow(t('details.id'), auctionId)}
            {this.renderRow(t('details.createdDate'), auction.createdDate)}
            {this.renderRow(t('details.endDate'), auction.endDate)}
          </Col>
          <Col span={8} className="custom-col">
            {this.renderRow(t('details.currentBidDate'), auction.currentBidDate ?? "-")}
            {this.renderRowWithTooltip(t('details.currentTransactionCost' + khg),
              auction.currentBid?.toFixed(3) ?? "-",
              t('details.currentTransactionCostTooltip' + khg)
            )}
            {this.renderRowWithTooltip(t('details.currentBid'),
              auction.currentBid ? calculateTransactionCost(auction, auction.currentBid, isKhg).toFixed(2) : "-",
              t('details.currentBidTooltip')
            )}
          </Col>
          <Col span={8}>
            {!!reservationFulfilled && this.renderRow(t('details.reservationFulfilled'), reservationFulfilled)}
            {auction.currentBidderName &&
              this.renderRowWithTooltip(t('details.currentBidderName'),
                auction.currentBidderName,
                t('details.currentBidderNameTooltip')
              )}
          </Col>
        </Row>
        <h2>{t('details.sellerTitle')}</h2>
        <Row className="mb-5 global-margin-top-24">
          <Col span={8}>
            {this.renderRow(t('details.sellerName'), auction.sellerName)}
          </Col>
          <Col span={8}>
            {this.renderRow(t('details.sellerCode'), auction.sellerCode)}
          </Col>
        </Row>
        {auction.additionalInfo &&
          <>
            <h2>{t('details.infoTitle')}</h2>
            <Row className="mb-5 global-margin-top-10">
              <Col span={8}>
                <Button onClick={() => this.setState({isAdditionalInfoVisible: true})}>
                  {t('details.additionalInfo')}
                </Button>
              </Col>
            </Row>
          </>
        }
        <h2>{t('details.tsDetailsTitle')}</h2>
        <Row className="mb-5 global-margin-top-24">
          <Col span={8} className="custom-col">
            {this.renderRow(t('details.year'), year)}
            {this.renderRow(t('details.fuelType'), translateTsFuelTypeClassificator(auction.fuelType))}
          </Col>
          <Col span={8} className="custom-col">
            {isKhg && this.renderRow(t('details.ghgAmount'), numberFormatter(auction.ghgAmount))}
            {!isKhg && this.renderRow(t('details.naturalAmountMj'), numberFormatter(auction.naturalAmountMj))}
            {!isKhg && this.renderRow(t('details.accountedAmountMj'), numberFormatter(auction.accountedAmountMj))}
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
