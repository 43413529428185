import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, DatePicker, Form, Input, InputNumber, Row} from "antd";
import moment from 'moment';
import RadioGroup from "../../atoms/RadioGroup";
import {VDSRequestResultModal} from "../../containers";
import {STATISTICS_TYPE_GHG, STATISTICS_TYPE_VDS} from "../../constants/StatisticsTypes";
import {getStatisticsTypeOptions} from "../../utils/vdsHelpers";
import {v4} from 'uuid';

const typeOptions = getStatisticsTypeOptions();

export class VDSTransmissionStatisticsForm extends Component {
  static propTypes = {
    form: PropTypes.any,
    onSubmit: PropTypes.func,
    onReset: PropTypes.func,
    children: PropTypes.any,
    request: PropTypes.any,
    response: PropTypes.any
  };

  constructor(props) {
    super(props);

    this.state = {
      validated: false,
      type: STATISTICS_TYPE_VDS,
      resultsModalShown: false
    };
  }

  componentDidMount() {
    this.resetTransactionDateTime();
    this.resetTransferID();
  }

  resetTransferID() {
    const {setFieldsValue} = this.props.form;
    setFieldsValue({
      TransferID: v4()
    });
  }

  isFirstMarketSale() {
    const {getFieldValue} = this.props.form;
    return !!getFieldValue('TransferSource.SourceID') || !!getFieldValue('Purchased.QuantityEnergyNatural');
  }

  isSecondMarketSale() {
    const {getFieldValue} = this.props.form;
    return !!getFieldValue('TransferSource.OfferID') || !!getFieldValue('Purchased.GHGAmount') || !!getFieldValue('Purchased.QuantityEnergyNatural2');
  }

  resetTransactionDateTime() {
    const {setFieldsValue} = this.props.form;
    setFieldsValue({
      TransactionDateTime: moment()
    });
  }

  submit = (event) => {
    event.preventDefault();
    const {onSubmit, form} = this.props;
    this.setState({validated: true});
    form.validateFields((error, values) => {
      if (!error) {
        onSubmit && onSubmit(values);
      }
    });
  };

  reset = () => {
    const {form, onReset} = this.props;
    const {resetFields} = form;
    resetFields();
    this.resetTransactionDateTime();
    this.resetTransferID();
    this.setState({validated: false});
    onReset && onReset();
  };

  onTypeChange = (event) => {
    this.setState({type: event.target.value});
  };

  validateStatus = (fieldName) => {
    const {form} = this.props;
    const {getFieldError, isFieldTouched} = form;
    const {validated} = this.state;
    return (validated || isFieldTouched(fieldName)) && getFieldError(fieldName) ? 'error' : '';
  };

  showResult = () => {
    this.setState({resultsModalShown: true});
  };

  hideResult = () => {
    this.setState({resultsModalShown: false});
  };

  render() {
    const {form, children, request, response} = this.props;
    const {getFieldDecorator} = form;
    const {type, resultsModalShown} = this.state;
    const firstMarketSale = this.isFirstMarketSale();
    const secondMarketSale = this.isSecondMarketSale();

    return (
      <Form>
        <h1>{'Elektrienergia või vedelkütuse statistika ülekanne'}</h1>
        <h2>{'Päise parameetrid'}</h2>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item validateStatus={this.validateStatus('TransferID')} label="TransferID">
              {getFieldDecorator('TransferID', {
                rules: [
                  {required: true, message: 'Sisesta TransferID'},
                  {max: 50, message: 'Maksimaalne pikkus on 50'}
                ]
              })(
                <Input placeholder="TransferID"/>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item validateStatus={this.validateStatus('TransactionDateTime')}
                       label="TransactionDateTime">
              {getFieldDecorator('TransactionDateTime', {
                rules: [{required: true, message: 'Sisesta TransactionDateTime'}]
              })(
                <DatePicker className={'w-100'} showTime={{format: 'HH:mm'}}
                            format="YYYY-MM-DD HH:mm"
                            placeholder="TransactionDateTime"/>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item validateStatus={this.validateStatus('BuyerCode')} label="BuyerCode">
              {getFieldDecorator('BuyerCode', {
                rules: [
                  {required: true, message: 'Sisesta BuyerCode'},
                  {max: 8, message: 'Maksimaalne pikkus on 8'}
                ]
              })(
                <Input placeholder="BuyerCode"/>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item validateStatus={this.validateStatus('DocumentNo')} label="DocumentNo">
              {getFieldDecorator('DocumentNo', {
                rules: [
                  {required: true, message: 'Sisesta DocumentNo'},
                  {max: 50, message: 'Maksimaalne pikkus on 50'}
                ]
              })(
                <Input placeholder="DocumentNo"/>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <h2>{'Esimese turu müük'}</h2>
            <div className={'block'}>
              {!secondMarketSale ?
                <Form.Item key={'SourceID'} label="TransferSource"
                           validateStatus={this.validateStatus('TransferSource.SourceID')}>
                  {getFieldDecorator('TransferSource.SourceID', {
                    rules: [
                      {required: true, message: 'Sisesta TransferSource SourceID'},
                      {max: 50, message: 'Maksimaalne pikkus on 50'}
                    ]
                  })(
                    <Input placeholder="SourceID"/>
                  )}
                </Form.Item>
                :
                <Form.Item key={'SourceID_'} label="TransferSource">
                  <Input placeholder="SourceID" disabled={true}/>
                </Form.Item>
              }
              {!secondMarketSale ?
                <Form.Item key={'QuantityEnergyNatural'} label="Purchased"
                           validateStatus={this.validateStatus('Purchased.QuantityEnergyNatural')}>
                  {getFieldDecorator('Purchased.QuantityEnergyNatural', {
                    rules: [{required: true, message: 'Sisesta Purchased QuantityEnergyNatural'}]
                  })(
                    <InputNumber className={'w-100'}
                                 min={0.1} max={99999999999999.9} step={0.1} precision={1}
                                 placeholder="QuantityEnergyNatural (MJ)"/>
                  )}
                </Form.Item>
                :
                <Form.Item key={'QuantityEnergyNatural_'} label="Purchased">
                  <InputNumber className={'w-100'} placeholder="QuantityEnergyNatural (MJ)"
                               disabled={true}/>
                </Form.Item>
              }
            </div>
          </Col>
          <Col span={12}>
            <h2>{'Teise turu müük'}</h2>
            <div className={'block'}>
              {!firstMarketSale ?
                <Form.Item key={'OfferID'} label="TransferSource"
                           validateStatus={this.validateStatus('TransferSource.OfferID')}>
                  {getFieldDecorator('TransferSource.OfferID', {
                    rules: [
                      {required: true, message: 'Sisesta TransferSource OfferID'},
                      {max: 50, message: 'Maksimaalne pikkus on 50'}
                    ]
                  })(
                    <Input placeholder="OfferID"/>
                  )}
                </Form.Item>
                :
                <Form.Item key={'OfferID_'} label="TransferSource">
                  <Input placeholder="OfferID" disabled={true}/>
                </Form.Item>
              }
              <Form.Item label="Source type">
                <RadioGroup options={typeOptions}
                            valueKey={'value'}
                            labelKey={'name'}
                            value={type}
                            disabled={firstMarketSale}
                            onChange={this.onTypeChange}
                />
              </Form.Item>
              {firstMarketSale && <Form.Item key={'Purchased_'} label="Purchased">
                <InputNumber className={'w-100'} disabled={true}
                             placeholder={type === STATISTICS_TYPE_GHG ? 'GHGAmount (t)' : 'QuantityEnergyNatural (MJ)'}/>
              </Form.Item>}
              {!firstMarketSale && type === STATISTICS_TYPE_GHG &&
                <Form.Item key={'Purchased'} label="Purchased"
                           validateStatus={this.validateStatus('Purchased.GHGAmount', !firstMarketSale)}>
                  {getFieldDecorator('Purchased.GHGAmount', {
                    rules: [{required: !firstMarketSale, message: 'Sisesta Purchased GHGAmount'}]
                  })(
                    <InputNumber className={'w-100'}
                                 min={-99999999999999.9}
                                 max={-0.1}
                                 step={0.1} precision={1}
                                 disabled={firstMarketSale}
                                 placeholder={'GHGAmount (t)'}/>
                  )}
                </Form.Item>
              }
              {!firstMarketSale && type === STATISTICS_TYPE_VDS &&
                <Form.Item key={'Purchased'} label="Purchased"
                           validateStatus={this.validateStatus('Purchased.QuantityEnergyNatural2', !firstMarketSale)}>
                  {getFieldDecorator('Purchased.QuantityEnergyNatural2', {
                    rules: [{
                      required: !firstMarketSale,
                      message: 'Sisesta Purchased QuantityEnergyNatural'
                    }]
                  })(
                    <InputNumber className={'w-100'}
                                 min={0.1} max={99999999999999.9} step={0.1} precision={1}
                                 disabled={firstMarketSale}
                                 placeholder="QuantityEnergyNatural (MJ)"/>
                  )}
                </Form.Item>
              }
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button type="primary" onClick={this.submit}>{'Saada'}</Button>
            <Button onClick={this.reset}>{'Puhasta'}</Button>
            <Button onClick={this.showResult} disabled={!request}>{'Päringu tulemus'}</Button>
          </Col>
        </Row>
        {children}
        <VDSRequestResultModal request={request} response={response}
                               show={resultsModalShown} hideModal={this.hideResult}/>
      </Form>
    );
  }
}

export default Form.create()(VDSTransmissionStatisticsForm);
