import React from 'react';
import {Col, Row} from 'antd';
import {
  translateGosTransactionTypeClassificator,
  translateTradeTypeClassificator,
  translateTransactionStatusClassificator,
} from '../../utils/translateHelpers';
import {tradeType as tradeTypeClf} from '../../constants/classificators/';

function TATransactionInfo({
                             t,
                             id,
                             type,
                             tradeType,
                             txType,
                             status,
                             txCreateTime,
                             senderAccountNumber,
                             comment,
                             isAdmin
                           }) {
  return (
    <React.Fragment>
      <Row className="global-margin-bottom-10">
        <Col span={8}>
          <b>{t('info.transactionNumber')}: </b>
          {id}
        </Col>
        <Col span={8}>
          <b>{t('info.type')}: </b>
          {type}
        </Col>
        {(txType === 'INCOMING' || txType === 'OUTGOING') &&
          <Col span={8}>
            <b>{t('info.tradeType')}: </b>
            {translateTradeTypeClassificator(tradeType ? tradeType : tradeTypeClf.TRANSACTION)}
          </Col>
        }
      </Row>
      <Row className="global-margin-bottom-10">
        <Col span={8}>
          <b>{t('info.transactionCreateTime')}: </b>
          {txCreateTime}
        </Col>
        <Col span={8}>
          <b>{t('info.transactionType')}: </b>
          {translateGosTransactionTypeClassificator(txType)}
        </Col>
        <Col span={8}>
          <b>{t('info.transactionStatus')}: </b>
          {translateTransactionStatusClassificator(status)}
        </Col>
        {isAdmin && (
          <React.Fragment>
            <Col span={8}>
              <b>{t('info.senderAccountNumber')}: </b>
              {senderAccountNumber}
            </Col>
          </React.Fragment>
        )}
      </Row>
      <Row className="global-margin-bottom-10">
        {status === 'REVOKED' && (
          <Col span={16}>
            <b>{t('info.recallReason')}: </b>
            {comment}
          </Col>
        )}
      </Row>
    </React.Fragment>
  );
}

export default TATransactionInfo;
