import {schema} from 'normalizr';
import {entitiesSelectorList, entitiesSelectorItem} from '../utils/asyncHelpers';
import legalEntityScheme from './legalEntity';

const meteringPointScheme = new schema.Entity('measurement');

const schemaEntity = new schema.Entity('productionUnit', {
  legalEntity: legalEntityScheme,
  measurementPoint: meteringPointScheme,
});

export const getProductionUnits = entitiesSelectorList(schemaEntity);
export const getProductionUnit = entitiesSelectorItem(schemaEntity);
export default schemaEntity;
