import {Map} from 'immutable';
import {handleActions} from 'redux-actions';
import {createAsyncReducer, initialAsyncState} from '../utils/asyncHelpers';
import {
  MANDATE_COMPANY_ADD,
  MANDATE_COMPANY_DELETE,
  MANDATE_COMPANY_UPDATE,
  MANDATE_PERSON_ADD,
  MANDATE_PERSON_UPDATE,
  MANDATE_PERSON_DELETE,
} from '../constants/registerActionTypes';

const initialState = Map({
  addMandateCompany: initialAsyncState,
  updateMandateCompany: initialAsyncState,
  deleteMandateCompany: initialAsyncState,
  addMandatePerson: initialAsyncState,
  updateMandatePerson: initialAsyncState,
  deleteMandatePerson: initialAsyncState,
});

const registerReducer = handleActions(
  {
    ...createAsyncReducer(MANDATE_COMPANY_ADD, 'addMandateCompany'),
    ...createAsyncReducer(MANDATE_COMPANY_DELETE, 'deleteMandateCompany'),
    ...createAsyncReducer(MANDATE_COMPANY_UPDATE, 'updateMandateCompany'),
    ...createAsyncReducer(MANDATE_PERSON_ADD, 'addMandatePerson'),
    ...createAsyncReducer(MANDATE_PERSON_UPDATE, 'updateMandatePerson'),
    ...createAsyncReducer(MANDATE_PERSON_DELETE, 'deleteMandatePerson'),
  },
  initialState
);

export const getAddMandateCompany = state =>
  state.getIn(['registerAddMandate', 'addMandateCompany']);

export const getUpdateMandateCompany = state =>
  state.getIn(['registerAddMandate', 'updateMandateCompany']);

export const getDeleteMandateCompany = state =>
  state.getIn(['registerAddMandate', 'deleteMandateCompany']);

export const getAddMandatePerson = state =>
  state.getIn(['registerAddMandate', 'addMandatePerson']);

export const getUpdateMandatePerson = state =>
  state.getIn(['registerAddMandate', 'updateMandatePerson']);

export const getDeleteMandatePerson = state =>
  state.getIn(['registerAddMandate', 'deleteMandatePerson']);

export default registerReducer;
