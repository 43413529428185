import {submit} from 'redux-form/immutable';
import {
  FORM_ADD_CONSUMPTION_POINT,
  FORM_ADD_CONSUMER,
} from '../../constants/formKeys';
import {reset, initialize} from 'redux-form';
import {FORM_MEASUREMENT} from '../../constants/formRegister';
import {measurement, consumerSchema, avpMeteringPointScheme} from '../../schemas';
import * as actionTypes from '../../constants/consumptionPointActionTypes';
import {identity} from 'lodash';
import {meteringPointFields} from '../../constants/meteringPoint';
import {v4} from 'uuid';
import {createAsyncAction, normalizeHelper} from '../../utils/asyncHelpers';
import {meteringPointDataSource} from '../../constants/classificators';
import {showAddConsumptionPointConsumerModal} from '../actionCreators/modalsActionCreators';
import {
  consumptionPointUpdateOrAdd,
  avpConsumerPointsFetch,
  consumerFetch,
} from '../actionHelpers/endpoints';
import {
  toggleCreateForm,
  selectPoint as selectPointPure,
  consumerAdded as consumerAddedPure,
  selectOwner as selectOwnerPure,
  resetFields as resetFieldsPure,
} from '../pureActions/consumptionPointActions';

export const submitAddConsumptionFrom = () => dispatch => {
  dispatch(submit(FORM_ADD_CONSUMPTION_POINT));
};

export const resetFields = () => dispatch => dispatch(resetFieldsPure());

export const fetchAvpPoints = createAsyncAction(
  actionTypes.AVP_POINTS_FETCH,
  avpConsumerPointsFetch,
  normalizeHelper([avpMeteringPointScheme])
);

export const createConsumptionPoint = createAsyncAction(
  actionTypes.CONSUMPTION_POINT_CREATE,
  identity,
  normalizeHelper(measurement)
);

export const startAddConsumptionPoint = () => dispatch => {
  dispatch(reset(FORM_MEASUREMENT));
  dispatch(toggleCreateForm(true));
};
export const startEditConsumptionPoint = values => dispatch => {
  dispatch(initialize(FORM_MEASUREMENT, values));
  dispatch(toggleCreateForm(true));
};
export const closePointForm = () => dispatch => {
  dispatch(reset(FORM_MEASUREMENT));
  dispatch(toggleCreateForm(false));
};

export const startEditConsumer = values => dispatch => {
  dispatch(initialize(FORM_ADD_CONSUMER, values));
  dispatch(showAddConsumptionPointConsumerModal());
};

export const startAddConsumer = () => dispatch => {
  dispatch(initialize(FORM_ADD_CONSUMER, {}));
  dispatch(showAddConsumptionPointConsumerModal());
};

export const updateOrAddConsumptionPoint = createAsyncAction(
  actionTypes.CONSUMPTION_POINT_ADD_UPDATE,
  consumptionPointUpdateOrAdd,
  normalizeHelper(measurement)
);

export const editProductionUnitMeasurement = createAsyncAction(
  actionTypes.CONSUMPTION_POINT_EDIT,
  identity,
  normalizeHelper(measurement)
);

export const fetchConsumer = createAsyncAction(
  actionTypes.CONSUMER_FETCH,
  consumerFetch,
  normalizeHelper(consumerSchema)
);

export const selectOwner = id => dispatch => dispatch(selectOwnerPure(id));

export const submitPointForm = (data, dispatch) => {
  if (data.get(meteringPointFields.ID)) {
    if (!data.get(meteringPointFields.CONSUMER)) {
      dispatch(editProductionUnitMeasurement(data.toJS()));
    } else {
      dispatch(updateOrAddConsumptionPoint(data.toJS()));
    }
  } else {
    dispatch(
      createConsumptionPoint(
        data
          .merge({
            [meteringPointFields.ID]: v4(),
            [meteringPointFields.DATA_SOURCE]: meteringPointDataSource.VIRTUAL,
          })
          .toJS()
      )
    );
  }
  dispatch(closePointForm());
};

export const consumerAdded = id => dispatch => dispatch(consumerAddedPure(id));

export const showPointForm = () => dispatch => dispatch(toggleCreateForm(true));

export const selectPoint = pointId => dispatch =>
  dispatch(selectPointPure(pointId));
