import {
  createValidation,
  validatorRequired,
} from '../../utils/formValidators';
import { technicalInformationFormFields } from '../../constants/formsAddEquipment';

export default createValidation([
  {
    name: technicalInformationFormFields.HYDROGEN_TECHNOLOGY_TYPE,
    validators: validatorRequired(),
  },
  {
    name: technicalInformationFormFields.ELECTROLYSIS_TECHNOLOGY_TYPE,
    validators: validatorRequired(),
  },
  {
    name: technicalInformationFormFields.RFNBO,
    validators: validatorRequired(),
  },
  {
    name: technicalInformationFormFields.NOMINAL_POWER,
    validators: validatorRequired(),
  },
]);
