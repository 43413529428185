import {createAsyncPagingAction} from '../../utils/asyncPagingHelpers.js';
import * as actionTypes from '../../constants/actionTypes/reportGoActionTypes';
import {reportGoItemsFetch} from '../actionHelpers/endpoints';
import {goBiomethaneReportResetMaster} from '../../reducers/reportGoReducer';

export const fetchReportGoItemsPage = createAsyncPagingAction(
  actionTypes.REPORT_GO_ITEMS_FETCH,
  reportGoItemsFetch
);

export const goBiomethaneReportResetMasterActionCreator = () => dispatch =>
  dispatch(goBiomethaneReportResetMaster());
