import * as Actions from '../pureActions/registerActions';
import * as actionProdTypes from '../../constants/productionUnitActionTypes';
import * as actionTypes from '../../constants/registerActionTypes';
import {createAsyncAction, createResetAction, normalizeHelper} from '../../utils/asyncHelpers';
import {
  mandateCompany,
  mandatePerson,
  avpMeteringPointScheme,
  legalEntitySchema,
  accountGOSSchema,
  accountGOSTransactionsSchema,
  accountGOSReportSchema,
} from '../../schemas';
import {
  registerMandateCompanyDelete,
  registerMandateCompanyUpdate,
  registerMandateCompanyAdd,
  registerMandateCompanyFetch,
  registerMandatePersonAdd,
  registerMandatePersonFetch,
  getLegalEntity,
  updateLegalEntity,
  avpMeteringPointsFetch,
  registerMandatePersonUpdate,
  registerMandatePersonDelete,
  legalEntityActivate,
  legalEntityDeactivate,
  companyDataFromBusinessRegisterFetch,
  searchTAEntries,
  searchTATransactions,
  searchAccountGOSReport, productionUnitMeasurementInactiveFetch,
} from '../actionHelpers/endpoints';
import {
  showRegisterRepresentCompanyModal,
  showRegisterRepresentPersonModal,
} from '../actionCreators/modalsActionCreators';
import {
  FORM_REGISTER_REPRESENTATIVE_COMPANY,
  FORM_REGISTER_REPRESENTATIVE_PERSON,
  FORM_REGISTER_GENERAL,
} from '../../constants/formRegister';
import {initialize, change} from 'redux-form/immutable';
import {showAlert} from '../actionCreators/alertsActionCreators';
import {tRegister as t} from '../../i18n';
import alertTypes from '../../constants/alertTypes';
import {
  legalEntityFields,
  businessRegisterFields,
} from '../../constants/legalEntity';
import {Map} from 'immutable';
import {replaceToRegister} from '../../utils/gotoLink';
import _ from 'lodash/fp';

export const goNextStepActionCreator = () => dispatch =>
  dispatch(Actions.goNextStep());

export const goPreviousStepActionCreator = () => dispatch =>
  dispatch(Actions.goPreviousStep());

export const resetMaster = () => dispatch => dispatch(Actions.resetMaster());

export const addMandateCompany = createAsyncAction(
  actionTypes.MANDATE_COMPANY_ADD,
  registerMandateCompanyAdd,
  normalizeHelper(mandateCompany)
);

export const fetchMandateCompany = createAsyncAction(
  actionTypes.MANDATE_COMPANY_FETCH,
  registerMandateCompanyFetch,
  _.flow(_.filter(item => item.legalEntity), normalizeHelper([mandateCompany]))
);

export const updateMandateCompany = createAsyncAction(
  actionTypes.MANDATE_COMPANY_UPDATE,
  registerMandateCompanyUpdate,
  normalizeHelper(mandateCompany)
);

export const deleteMandateCompany = createAsyncAction(
  actionTypes.MANDATE_COMPANY_DELETE,
  registerMandateCompanyDelete
);

export const addMandatePerson = createAsyncAction(
  actionTypes.MANDATE_PERSON_ADD,
  registerMandatePersonAdd,
  normalizeHelper(mandatePerson)
);

export const updateMandatePerson = createAsyncAction(
  actionTypes.MANDATE_PERSON_UPDATE,
  registerMandatePersonUpdate,
  normalizeHelper(mandatePerson)
);

export const deleteMandatePerson = createAsyncAction(
  actionTypes.MANDATE_PERSON_DELETE,
  registerMandatePersonDelete
);

export const fetchMandatePerson = createAsyncAction(
  actionTypes.MANDATE_PERSON_FETCH,
  registerMandatePersonFetch,
  _.flow(
    _.filter(item => item.physicalPerson),
    normalizeHelper([mandatePerson])
  )
);

export const fetchLegalEntityActionCreator = createAsyncAction(
  actionTypes.LEGAL_ENTITY_FETCH,
  getLegalEntity,
  normalizeHelper(legalEntitySchema)
);

export const fetchCompanyDataFromBusinessRegisterActionCreator = createAsyncAction(
  actionTypes.LEGAL_ENTITY_FROM_BR_FETCH,
  companyDataFromBusinessRegisterFetch,
  (data, dispatch) => {
    businessRegisterFields.forEach(key =>
      dispatch(change(FORM_REGISTER_GENERAL, key, data[key]))
    );
  }
);

export const resetBusinessRegisterValues = () => dispatch =>
  businessRegisterFields.forEach(key =>
    dispatch(change(FORM_REGISTER_GENERAL, key, null))
  );

export const updateLegalEntityActionCreator = createAsyncAction(
  actionTypes.LEGAL_ENTITY_UPDATE,
  updateLegalEntity,
  data => {
    delete data[legalEntityFields.ACCOUNTS];
    return normalizeHelper(legalEntitySchema)(data);
  }
);

export const addLegalEntity = createAsyncAction(
  actionTypes.LEGAL_ENTITY_ADD,
  updateLegalEntity,
  data => {
    replaceToRegister(data[legalEntityFields.REGISTER_CODE]);
    return normalizeHelper(legalEntitySchema)(data);
  }
);

export const editRepresentCompanyActionCreator = data => dispatch => {
  dispatch(initialize(FORM_REGISTER_REPRESENTATIVE_COMPANY, Map(data)));
  dispatch(showRegisterRepresentCompanyModal());
};

export const editRepresentPersonActionCreator = data => dispatch => {
  dispatch(initialize(FORM_REGISTER_REPRESENTATIVE_PERSON, Map(data)));
  dispatch(showRegisterRepresentPersonModal());
};

export const fetchMeasurementsInactiveActionCreator = createAsyncAction(
  actionTypes.PRODUCTION_UNIT_MEASUREMENT_INACTIVE_FETCH,
  productionUnitMeasurementInactiveFetch,
  normalizeHelper([avpMeteringPointScheme])
);

export const resetMeasurementsActionCreator = createResetAction(
  actionTypes.MEASUREMENT_FETCH
);

export const resetInactiveMeasurementsActionCreator = createResetAction(
  actionTypes.PRODUCTION_UNIT_MEASUREMENT_INACTIVE_FETCH
);

export const resetProductionUnitMeasurementActionCreator = createResetAction(
  actionProdTypes.PRODUCTION_UNIT_MEASUREMENT_FETCH
);


export const fetchMeasurementsActionCreator = createAsyncAction(
  actionTypes.MEASUREMENT_FETCH,
  avpMeteringPointsFetch,
  normalizeHelper([avpMeteringPointScheme])
);

export const activateLegalEntity = createAsyncAction(
  actionTypes.LEGAL_ENTITY_ACTIVATE,
  legalEntityActivate,
  (data, dispatch) => {
    dispatch(
      showAlert({
        type: alertTypes.SUCCESS,
        message: t('general.activateSuccess'),
      })
    );
    return normalizeHelper(legalEntitySchema)(data);
  }
);

export const deactivateLegalEntity = createAsyncAction(
  actionTypes.LEGAL_ENTITY_DEACTIVATE,
  legalEntityDeactivate,
  (data, dispatch) => {
    dispatch(
      showAlert({
        type: alertTypes.SUCCESS,
        message: t('general.deactivateSuccess'),
      })
    );
    return normalizeHelper(legalEntitySchema)(data);
  }
);

export const fetchAccountGOSActionCreator = createAsyncAction(
  actionTypes.ACCOUNT_GOS_FETCH,
  searchTAEntries,
  normalizeHelper(accountGOSSchema)
);

export const fetchAccountGOSTransactionsActionCreator = createAsyncAction(
  actionTypes.ACCOUNT_GOS_TRANSACTIONS_FETCH,
  searchTATransactions,
  normalizeHelper(accountGOSTransactionsSchema)
);

export const fetchAccountGOSReportActionCreator = createAsyncAction(
  actionTypes.ACCOUNT_GOS_REPORT_FETCH,
  searchAccountGOSReport,
  normalizeHelper(accountGOSReportSchema)
);
