import React, {Component} from 'react';
import PropTypesImmutable from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
  getAccountGOSPurchasingAgreementsList,
  getAccountGOSPurchasingAgreementsListData,
} from '../../reducers/accountGOSPurchasingAgreementsListReducer';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import * as actions from '../../actions/actionCreators/accountGOSAgreementsActionCreators';
import {AccountGOSAgreementsSearchTable} from "../../components";
import {message, Modal} from "antd";
import {TsAgreementWithdraw} from "../../forms";
import {
  getPurchasingAgreementWithdrawModal,
  hidePurchasingAgreementWithdrawModal,
  showPurchasingAgreementWithdrawModal
} from "../../actions/actionCreators/modalsActionCreators";
import {tsAgreementDeleteAction} from "../../actions/actionCreators/tsAgreementActionCreators";

export class AccountGOSPurchasingAgreementsList extends Component {
  static propTypes = {
    purchasingAgreements: PropTypesImmutable.list,
    purchasingAgreementsList: PropTypesImmutable.map.isRequired,
    t: PropTypes.func.isRequired,
    registerCode: PropTypes.string.isRequired,
    stateWithdrawModal: PropTypes.bool.isRequired,
    showWithdrawModal: PropTypes.func.isRequired,
  };

  state = {
    selectedRowId: -1
  }

  componentDidMount() {
    const {searchAccountGOSPurchasingAgreements} = this.props;
    searchAccountGOSPurchasingAgreements(0);
  }

  showModal = (rowId, resetFunction) => {
    const {showWithdrawModal} = this.props;
    this.setState({selectedRowId: rowId, resetSelection: resetFunction});
    showWithdrawModal();
  }

  deleteAgreement = async (id) => {
    const {t, deleteTsAgreement, searchAccountGOSPurchasingAgreements} = this.props;
    const result = await deleteTsAgreement(id);
    if (!result || !result.error) {
      message.success(t('agreements.table.delete.success'));
      searchAccountGOSPurchasingAgreements(0);
    }
  }

  render() {
    const {
      t,
      stateWithdrawModal,
      hideWithdrawModal,
      purchasingAgreements,
      purchasingAgreementsList,
      purchasingAgreementsLoading,
      searchAccountGOSPurchasingAgreements,
      registerCode,
      ...rest
    } = this.props;

    return (
      <React.Fragment>
        <AccountGOSAgreementsSearchTable agreements={purchasingAgreements.toJS()}
                                         search={searchAccountGOSPurchasingAgreements}
                                         counterparty={'sellerName'}
                                         registerCode={registerCode}
                                         showModal={this.showModal}
                                         deleteAgreement={this.deleteAgreement}
                                         t={t}
                                         {...rest}
                                         {...purchasingAgreementsList.toJS()}
        />
        {stateWithdrawModal && (
          <Modal
            onCancel={hideWithdrawModal}
            visible
            footer={false}
            title={t('agreements.withdraw.title')}
            width="600px"
          >
            <TsAgreementWithdraw
              t={t}
              selectedRowId={this.state.selectedRowId}
              hideWithdrawModal={hideWithdrawModal}
              searchAgreements={searchAccountGOSPurchasingAgreements}
              resetSelection={this.state.resetSelection}
            />
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  purchasingAgreementsList: getAccountGOSPurchasingAgreementsList(state),
  purchasingAgreements: getAccountGOSPurchasingAgreementsListData(state),
  loading: getLoadingSelector(getAccountGOSPurchasingAgreementsList)(state),
  stateWithdrawModal: getPurchasingAgreementWithdrawModal(state),
});

const mapDispatchToProps = {
  showWithdrawModal: showPurchasingAgreementWithdrawModal,
  hideWithdrawModal: hidePurchasingAgreementWithdrawModal,
  deleteTsAgreement: tsAgreementDeleteAction,
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  AccountGOSPurchasingAgreementsList
);
