import React, {Component} from 'react';
import PropTypesImmutable from 'react-immutable-proptypes';
import {connect} from 'react-redux';
import {ProductionUnitSearchTable} from '../../components';
import {
  getProductionUnitList,
  getProductionUnitListData,
} from '../../reducers/productionUnitListReducer';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import * as actions from '../../actions/actionCreators/productionUnitListActionCreators';

export class ProductionUnitList extends Component {
  static propTypes = {
    productionUnits: PropTypesImmutable.list,
    productionUnitList: PropTypesImmutable.map.isRequired,
  };

  render() {
    const {productionUnits, productionUnitList, ...rest} = this.props;

    return (
      <React.Fragment>
        <ProductionUnitSearchTable
          productionUnits={productionUnits.toJS()}
          {...rest}
          {...productionUnitList.toJS()}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const productionUnitList = getProductionUnitList(state);

  return {
    productionUnitList,
    loading: getLoadingSelector(getProductionUnitList)(state),
    productionUnits: getProductionUnitListData(state),
  };
};

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductionUnitList);
