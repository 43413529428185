import {normalizeHelper} from '../../utils/asyncHelpers';
import {
  createAsyncPagingAction,
  createAsyncActionStoreWrapper,
} from '../../utils/asyncPagingHelpers';
import {TRANSACTION_SEARCH_FETCH} from '../../constants/actionTypes/transactionSearchActionTypes';
import {transactionsSearch} from '../actionHelpers/endpoints';
import {transactionSchema} from '../../schemas';
import {getFormValues} from 'redux-form/immutable';
import {getTransactionList} from '../../reducers/transactionSearchReducer';
import {FORM_TRANSACTION_SEARCH} from '../../constants/formKeys';

const _searchTransactions = createAsyncPagingAction(
  TRANSACTION_SEARCH_FETCH,
  transactionsSearch,
  normalizeHelper([transactionSchema])
);

const filterFormSelector = getFormValues(FORM_TRANSACTION_SEARCH);

export const searchTransactions = createAsyncActionStoreWrapper(
  _searchTransactions,
  filterFormSelector,
  getTransactionList
);
