import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm} from 'redux-form/immutable';
import {connect} from 'react-redux';
import {TextField, ClassificatorField} from '../../atoms';
import {Row, Col, Button, Spin} from 'antd';
import {
  FORM_MEASUREMENT,
  formRegisterMeasurementFields,
} from '../../constants/formRegister';
import {getProdUnitMeasurementAddState} from '../../reducers/prodUnitMeasurementPointReducer';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {parseNumber} from '../../utils/formHelpers';

import validate from './validateSchema';

export class RegisterMeasurement extends Component {
  static propTypes = {
    productionUnitId: PropTypes.number,
    inEditForNotAdmin: PropTypes.bool.isRequired,
    closeForm: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };

  render() {
    const {
      handleSubmit,
      t,
      isLoading,
      closeForm,
      inEditForNotAdmin,
      isAdmin,
    } = this.props;

    return (
      <Spin spinning={isLoading}>
        <form
          className="form-register-representation-company"
          onSubmit={handleSubmit}
        >
          <h2>{t('measurement.titleModal')}</h2>
          <Row gutter={32}>
            <Col span={12}>
              <Field
                label={t('measurement.countryLabel')}
                name={formRegisterMeasurementFields.COUNTY}
                component={ClassificatorField}
                type={ClassificatorField.types.counties}
                required
                disabled={inEditForNotAdmin}
              />
            </Col>
            <Col span={12}>
              <Field
                name={formRegisterMeasurementFields.CITY}
                component={TextField}
                type="text"
                label={t('measurement.cityLabel')}
                required
                disabled={inEditForNotAdmin}
              />
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={12}>
              <Field
                name={formRegisterMeasurementFields.DISTRICT}
                component={TextField}
                type="text"
                label={t('measurement.districtLabel')}
                required
                disabled={inEditForNotAdmin}
              />
            </Col>
            <Col span={12}>
              <Field
                name={formRegisterMeasurementFields.ADDRESS}
                component={TextField}
                type="text"
                label={t('measurement.addressLabel')}
                required
                disabled={inEditForNotAdmin}
              />
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={12}>
              <Field
                name={formRegisterMeasurementFields.ZIP_CODE}
                component={TextField}
                type="text"
                parse={parseNumber}
                label={t('measurement.zipCodeLabel')}
                required
                disabled={inEditForNotAdmin}
              />
            </Col>
            <Col span={12}>
              <Field
                name={formRegisterMeasurementFields.EICZ_CODE}
                component={TextField}
                type="text"
                label={t('measurement.eiczCodeLabel')}
                required={isAdmin}
                disabled={!isAdmin}
              />
            </Col>
          </Row>
          <div className="component-register-actions">
            <Button
              className="component-register-actions__button-previous"
              size="large"
              onClick={closeForm}
            >
              {inEditForNotAdmin
                ? t('measurement.buttonClose')
                : t('measurement.buttonCancel')}
            </Button>
            {!inEditForNotAdmin && (
              <Button
                className="component-register-actions__button-next"
                size="large"
                type="primary"
                htmlType="submit"
              >
                {t('measurement.buttonSave')}
              </Button>
            )}
          </div>
        </form>
      </Spin>
    );
  }
}

const mapStateToProps = state => {
  const isLoading = getLoadingSelector(getProdUnitMeasurementAddState)(state);

  return {
    isLoading,
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_MEASUREMENT,
    validate,
  })(RegisterMeasurement)
);
