import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import {Layout} from 'antd';
import {ClassifiersList} from '../../containers';

class Classifiers extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  render() {
    const {t} = this.props;

    return (
      <Layout className="main-container">
        <h1>{t('title')}</h1>
        <ClassifiersList t={t}/>
      </Layout>
    );
  }
}

export default translate(NAMESPACES.classifiers)(Classifiers);
