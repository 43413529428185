export const getValidateStatus = (touched, error, warning, valid) => {
  if (touched) {
    if (error) return 'error';
    if (warning) return 'warning';
    if (valid) return 'success';
  }
  return '';
};

const mapError = ({
                    meta: {touched, error, warning, valid} = {},
                    input: {...inputProps} = {},
                    ...props
                  }) => ({
  ...props,
  ...inputProps,
  validateStatus: getValidateStatus(
    touched,
    error,
    warning,
    valid
  ),
  help: touched && (error || warning),
});

const mapErrorFields = fields => {
  const {names, ...props} = fields;
  const value = [];

  const validationStatus = names.reduce((status, name) => {
    const {meta: {touched, error, warning, valid} = {}} = fields[name];
    return (
      status ||
      getValidateStatus(touched, error, warning, valid)
    );
  }, '');

  let helpInner;
  const help = names.reduce((status, name) => {
    const {meta: {touched, error, warning} = {}} = fields[name];
    helpInner = (touched && (error || warning));
    return helpInner;
  }, undefined) || helpInner;

  return {
    value,
    help,
    validationStatus,
    ...props,
  };
};

export const customMap = customPropsFun => props => ({
  ...mapError(props),
  ...customPropsFun(props),
});

export const customMapFields = customPropsFun => fields => ({
  ...mapErrorFields(fields),
  ...customPropsFun(fields),
});

export default mapError;
