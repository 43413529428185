import React from 'react';
import PropTypes from 'prop-types';
import {Timeline, Modal} from 'antd';
import {MeasuringUpload} from '../../containers';
import {notificationCompanyFields} from '../../constants/notificationCompanyFields';
import {acceptStatus} from '../../constants/classificators';
import './NotificationCompanyList.css';

class NotificationCompanyList extends React.Component {
  static propTypes = {
    list: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    stateImportModal: PropTypes.bool.isRequired,
    hideImportModal: PropTypes.func.isRequired,
    showImportModal: PropTypes.func.isRequired,
    closeNotification: PropTypes.func.isRequired,
  };

  selectedProductionUnitId = null;
  selectedConsumerId = null;

  handleClickClose = (e, id) => {
    e.preventDefault();
    this.props.closeNotification(id);
  };

  handleClickFix = (e, productionUnitId, consumerId) => {
    const {showImportModal} = this.props;
    e.preventDefault();
    this.selectedProductionUnitId = productionUnitId;
    this.selectedConsumerId = consumerId;
    showImportModal();
  };

  renderAccepted = (item, t) => (
    <div>
      {t('notification.notificationAcceptedText', {
        period: item[notificationCompanyFields.PERIOD],
      })}
      <a
        href="/notification"
        className="component-NotificationCompanyList__view-button"
        onClick={e =>
          this.handleClickClose(e, item[notificationCompanyFields.ID])
        }
      >
        {t('notification.closeButton')}
      </a>
    </div>
  );

  renderDeclined = (item, t) => (
    <div>
      {t('notification.notificationDeclinedText', {
        period: item[notificationCompanyFields.PERIOD],
      })}
      <a
        href="/notification"
        className="component-NotificationCompanyList__view-button"
        onClick={e =>
          this.handleClickFix(
            e,
            item[notificationCompanyFields.PRODUCTION_UNIT_ID],
            item[notificationCompanyFields.CONSUMER_ID]
          )
        }
      >
        {t('notification.fixButton')}
      </a>
    </div>
  );

  render() {
    const {list, t, stateImportModal, hideImportModal} = this.props;

    return (
      <div>
        <Timeline className="component-NotificationCompanyList">
          {list.map(item => (
            <Timeline.Item key={item[notificationCompanyFields.ID]}>
              {item[notificationCompanyFields.TYPE] === acceptStatus.ACCEPTED &&
                this.renderAccepted(item, t)}
              {item[notificationCompanyFields.TYPE] !== acceptStatus.ACCEPTED &&
                this.renderDeclined(item, t)}
            </Timeline.Item>
          ))}
        </Timeline>
        {stateImportModal && (
          <Modal
            onCancel={hideImportModal}
            visible={stateImportModal}
            footer={false}
            title={t('notification.importTitle')}
            width="600px"
          >
            <MeasuringUpload
              onComplete={hideImportModal}
              productionUnitId={this.selectedProductionUnitId}
              consumerId={this.selectedConsumerId}
            />
          </Modal>
        )}
      </div>
    );
  }
}

export default NotificationCompanyList;
