import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {connect} from 'react-redux';
import {getGoReport} from '../../reducers/reportGoReducer';
import {Fields, reduxForm, getFormValues} from 'redux-form/immutable';
import {MonthRangeField, MonthYearField} from '../../atoms';
import {FormActions} from '../../components';
import {formGoReportFields} from '../../constants/forms/formGoReport';
import {goReportFields} from '../../constants/GoReport.js';
import {FORM_GO_REPORT} from '../../constants/formKeys';
import {Row, Col, Button, Spin, Radio, Table, Modal} from 'antd';
import {getRole} from '../../reducers/userReducer';
import {isAdmin} from '../../utils/roles';
import {links} from '../../utils/gotoLink';
import * as actions from '../../actions/actionCreators/reportGoActionCreators';
import TableRowActions from "../../components/TableRowActions/TableRowActions";
import {withRouter} from "react-router-dom";
import queryString from 'query-string';
import {
  translateBiofuelTypeClassificator,
  translateBooleanClassificator, translateEnergyTypeClassificator,
  translateFeedstockClassificator,
  translateFuelTypeClassificator, translatelandUseCategoryClassificator,
  translateProductionPathwayClassificator,
  translateTransactionStatusClassificator
} from "../../utils/translateHelpers";
import moment from "moment";
import {FORMAT_DEFAULT_MONTH} from "../../utils";
import {numberFormatter} from "../../utils/formaters";

export class GoReport extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    isUserAdmin: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    formValues: PropTypesImmutable.map,
    fetchReportGoItemsPage: PropTypes.func.isRequired,
    resetGoBiomethaneReport: PropTypes.func.isRequired,
    goReport: PropTypes.object,
    location: PropTypes.object
  };

  state = {
    filter: true,
    loading: false,
    monthFilter: true,
    detailModalVisible: false,
    goReportDetails: {}
  };

  handleClear = () => {
    this.props.reset();
  };

  handleShowDetailsModal = (details) => {
    this.setState({goReportDetails: details});
    this.setState({detailModalVisible: true});
  };

  hideDetailModal = () => {
    this.setState({detailModalVisible: false});
    this.setState({goReportDetails: {}});
  };

  handleGenerate = async (newPage) => {
    const {goReport} = this.props;
    const {formValues} = this.props;
    const {monthFilter} = this.state;
    const {fetchReportGoItemsPage} = this.props;
    const {location} = this.props;
    const {pageSize, page} = goReport.toJSON();

    const params = {};

    if (formValues) {
      Object.assign(params, Object.entries(formValues.toJS()).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {}));

      if (monthFilter) {
        delete params[formGoReportFields.DATE_FROM];
        delete params[formGoReportFields.DATE_TO];
      } else {
        delete params[formGoReportFields.MONTH];
        delete params[formGoReportFields.YEAR];
      }
    }

    const query = queryString.parse(location.search);
    if (query.transactionId) {
      params[formGoReportFields.TRANSACTION_ID] = query.transactionId;
    }

    params[formGoReportFields.PAGE] = newPage ? newPage - 1 : (page ? page - 1 : 0);
    params[formGoReportFields.PAGE_SIZE] = pageSize || 20;

    this.setState({loading: true});

    await fetchReportGoItemsPage(params);

    this.setState({loading: false});
  };

  getGenerateXlsUrl() {
    const {formValues} = this.props;
    const {monthFilter} = this.state;

    const params = {};

    if (formValues) {
      Object.assign(params, Object.entries(formValues.toJS()).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {}));

      if (monthFilter) {
        delete params[formGoReportFields.DATE_FROM];
        delete params[formGoReportFields.DATE_TO];
      } else {
        delete params[formGoReportFields.MONTH];
        delete params[formGoReportFields.YEAR];
      }
    }

    const paramString = new URLSearchParams(params);
    return links.reportGoXls + '?' + paramString.toString();
  }

  renderFilterField = () => {
    const {t} = this.props;
    const {monthFilter} = this.state;
    if (monthFilter) {
      return (
        <Fields
          names={[
            formGoReportFields.MONTH,
            formGoReportFields.YEAR,
          ]}
          className="date-field"
          component={MonthYearField}
          hasFeedback={false}
        />
      );
    }
    return (
      <Fields
        names={[
          formGoReportFields.DATE_FROM,
          formGoReportFields.DATE_TO
        ]}
        placeholder={[
          t('goReport.dateRange.dateFromPlaceholder'),
          t('goReport.dateRange.dateToPlaceholder')
        ]}
        component={MonthRangeField}
        hasFeedback={false}
      />
    );
  };

  renderGoReportDetails = () => {
    const {t} = this.props;

    return (
      <div>
        <Row className="global-margin-bottom-10">
          <Col span={8}>
            <div>
              <b>{t('goReport.transactionDate')}: </b>
              {this.state.goReportDetails[goReportFields.TRANSACTION_DATE]}
            </div>
            <div>
              <b>{t('goReport.transactionNumber')}: </b>
              {this.state.goReportDetails[goReportFields.TRANSACTION_NUMBER]}
            </div>
            <div>
              <b>{t('goReport.transactionStatus')}: </b>
              {translateTransactionStatusClassificator(this.state.goReportDetails[goReportFields.TRANSACTION_STATUS])}
            </div>
            <div>
              <b>{t('goReport.cancelledGoCount')}: </b>
              {this.state.goReportDetails[goReportFields.CANCELLED_GO_COUNT]}
            </div>
            <div>
              <b>{t('goReport.naturalEnergyAmount')}: </b>
              {numberFormatter(this.state.goReportDetails[goReportFields.NATURAL_ENERGY_AMOUNT])}
            </div>
            <div>
              <b>{t('goReport.calculatedMultiplier')}: </b>
              {this.state.goReportDetails[goReportFields.CALCULATED_MULTIPLIER]}
            </div>
            <div>
              <b>{t('goReport.calculatedEnergyAmount')}: </b>
              {numberFormatter(this.state.goReportDetails[goReportFields.CALCULATED_ENERGY_AMOUNT])}
            </div>
            <div>
              <b>{t('goReport.consumptionPeriod')}: </b>
              {this.state.goReportDetails[goReportFields.CONSUMPTION_PERIOD]}
            </div>
          </Col>
          <Col span={8}>
            <div>
              <b>{t('goReport.productionPeriod')}: </b>
              {this.state.goReportDetails[goReportFields.PRODUCTION_PERIOD]}
            </div>
            <div>
              <b>{t('goReport.eicwCode')}: </b>
              {this.state.goReportDetails[goReportFields.EICW_CODE]}
            </div>
            <div>
              <b>{t('goReport.posNumber')}: </b>
              {this.state.goReportDetails[goReportFields.POS_NUMBER]}
            </div>
            <div>
              <b>{t('goReport.energyType')}: </b>
              {translateEnergyTypeClassificator(this.state.goReportDetails[goReportFields.ENERGY_TYPE])}
            </div>
            <div>
              <b>{t('goReport.biofuelComponentCn')}: </b>
              {this.state.goReportDetails[goReportFields.BIOFUEL_COMPONENT_CN]}
            </div>
            <div>
              <b>{t('goReport.sustainableBiofuel')}: </b>
              {translateBooleanClassificator(this.state.goReportDetails[goReportFields.SUSTAINABLE_BIOFUEL] + '')}
            </div>
            <div>
              <b>{t('goReport.fuelType')}: </b>
              {translateFuelTypeClassificator(this.state.goReportDetails[goReportFields.FUEL_TYPE])}
            </div>
            <div>
              <b>{t('goReport.feedstock')}: </b>
              {translateFeedstockClassificator(this.state.goReportDetails[goReportFields.FEEDSTOCK], this.state.goReportDetails)}
            </div>
            <div>
              <b>{t('goReport.productionPathway')}: </b>
              {translateProductionPathwayClassificator(this.state.goReportDetails[goReportFields.PRODUCTION_PATHWAY], this.state.goReportDetails)}
            </div>
          </Col>
          <Col span={8}>
            <div>
              <b>{t('goReport.amount')}: </b>
              {numberFormatter(this.state.goReportDetails[goReportFields.AMOUNT])}
            </div>
            <div>
              <b>{t('goReport.lowerCalorificValueKg')}: </b>
              {numberFormatter(this.state.goReportDetails[goReportFields.LOWER_CALORIFIC_VALUE_KG])}
            </div>
            <div>
              <b>{t('goReport.higherCalorificValueKg')}: </b>
              {numberFormatter(this.state.goReportDetails[goReportFields.HIGHER_CALORIFIC_VALUE_KG])}
            </div>
            <div>
              <b>{t('goReport.ghgCapacity')}: </b>
              {numberFormatter(this.state.goReportDetails[goReportFields.GHG_CAPACITY])}
            </div>
            <div>
              <b>{t('goReport.biofuelType')}: </b>
              {translateBiofuelTypeClassificator(this.state.goReportDetails[goReportFields.BIOFUEL_TYPE])}
            </div>
            <div>
              <b>{t('goReport.landUseCategory')}: </b>
              {translatelandUseCategoryClassificator(this.state.goReportDetails[goReportFields.LAND_USE_CATEGORY])}
            </div>
            <div>
              <b>{t('goReport.landUseEmissions')}: </b>
              {this.state.goReportDetails[goReportFields.LAND_USE_EMISSIONS]}
            </div>
            <div>
              <b>{t('goReport.productionUnitStartUsageDate')}: </b>
              {this.state.goReportDetails[goReportFields.PRODUCTION_UNIT_START_USAGE_DATE]}
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  getReportColumns = () => {
    const {t} = this.props;

    return [
      {
        title: t('goReport.transactionDate'),
        dataIndex: goReportFields.TRANSACTION_DATE
      },
      {
        title: t('goReport.transactionNumber'),
        dataIndex: goReportFields.TRANSACTION_NUMBER
      },
      {
        title: t('goReport.cancelledGoCount'),
        dataIndex: goReportFields.CANCELLED_GO_COUNT
      },
      {
        title: t('goReport.naturalEnergyAmount'),
        dataIndex: goReportFields.NATURAL_ENERGY_AMOUNT,
        render: numberFormatter
      },
      {
        title: t('goReport.calculatedEnergyAmount'),
        dataIndex: goReportFields.CALCULATED_ENERGY_AMOUNT,
        render: numberFormatter
      },
      {
        title: t('goReport.feedstock'),
        dataIndex: goReportFields.FEEDSTOCK,
        render: translateFeedstockClassificator
      },
      {
        title: t('goReport.consumptionPeriod'),
        dataIndex: goReportFields.CONSUMPTION_PERIOD
      },
      {
        title: t('goReport.transactionStatus'),
        dataIndex: goReportFields.TRANSACTION_STATUS,
        render: translateTransactionStatusClassificator
      },
      {
        dataIndex: goReportFields.ID,
        render: (value, row) => (
          <TableRowActions>
            <Button
              shape="circle"
              icon="select"
              size="small"
              type="primary"
              title={t('table.viewBtn')}
              onClick={() => this.handleShowDetailsModal(row)}
            />
          </TableRowActions>
        )
      }
    ];
  };

  onPageChange = page => {
    return this.handleGenerate(page);
  };

  // ugly workaround for empty formValues in componentDidMount
  waitInit() {
    if (!this.props.formValues) {
      setTimeout(() => {
        this.waitInit();
      }, 100);
    } else {
      this.handleGenerate(0);
    }
  }

  componentDidMount() {
    this.waitInit();
  }

  componentWillUnmount() {
    this.props.resetGoBiomethaneReport();
  }

  render() {
    const {t, goReport, location} = this.props;
    const {monthFilter} = this.state;

    const {data, page, totalElements, pageSize} = goReport.toJSON();

    return (
      <div>
        <Spin spinning={this.state.loading}>
          <form>
            <Row gutter={32}>
              <Col span={8}>
                <Radio.Group
                  name="filterModeGroup"
                  value={monthFilter}
                  onChange={e => this.setState({monthFilter: e.target.value})}
                >
                  <Radio value>{t('goReport.filterModeGroup.monthMode')}</Radio>
                  <Radio value={false}>{t('goReport.filterModeGroup.rangeMode')}</Radio>
                </Radio.Group>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={8}>{this.renderFilterField()}</Col>
            </Row>
            <Row gutter={32}>
              <Table
                scroll={{x: 'auto'}}
                rowKey={goReportFields.ID}
                columns={this.getReportColumns(t)}
                dataSource={data}
                bordered
                pagination={{
                  onChange: this.onPageChange,
                  current: page,
                  total: totalElements,
                  pageSize,
                  defaultCurrent: 1
                }}
              />
            </Row>
            <FormActions>
              <Button onClick={this.handleClear}>
                {t('goReport.emptyButton')}
              </Button>
              <Button type="primary" onClick={() => {
                //remove transactionId from query if any present
                this.props.history.replace(location.pathname);
                this.handleGenerate();
              }}>
                {t('goReport.generate')}
              </Button>
              <a href={this.getGenerateXlsUrl()} target="_blank" rel="noopener noreferrer" download>
                <Button>
                  {t('goReport.generateXls')}
                </Button>
              </a>
            </FormActions>
          </form>
        </Spin>
        <Modal
          title={t('goReport.reportDetailModalTitle')}
          visible={this.state.detailModalVisible}
          footer={false}
          width="1200px"
          onCancel={this.hideDetailModal}
        >
          {this.renderGoReportDetails()}
        </Modal>
      </div>
    );
  }
}

const calcInitialDates = () => {
  return moment().month() === 0
    ? {
      [formGoReportFields.MONTH]: 12, // december
      [formGoReportFields.YEAR]: new Date().getFullYear() - 1,
      [formGoReportFields.DATE_FROM]: moment().subtract(1, 'years').startOf('year').format(FORMAT_DEFAULT_MONTH),
      [formGoReportFields.DATE_TO]: moment().subtract(1, 'years').endOf('year').format(FORMAT_DEFAULT_MONTH),
    }
    : {
      [formGoReportFields.MONTH]: new Date().getMonth(), // previous month
      [formGoReportFields.YEAR]: new Date().getFullYear(),
      [formGoReportFields.DATE_FROM]: moment().startOf('year').format(FORMAT_DEFAULT_MONTH),
      [formGoReportFields.DATE_TO]: moment().format(FORMAT_DEFAULT_MONTH),
    };
};

const formValuesSelector = getFormValues(FORM_GO_REPORT);

const mapStateToProps = state => ({
  isUserAdmin: isAdmin(getRole(state)),
  initialValues: calcInitialDates(),
  formValues: formValuesSelector(state),
  goReport: getGoReport(state)
});

const mapDispatchToProps = {
  fetchReportGoItemsPage: actions.fetchReportGoItemsPage,
  resetGoBiomethaneReport: actions.goBiomethaneReportResetMasterActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_GO_REPORT,
    enableReinitialize: true
  })(withRouter(GoReport))
);
