export const accountTableFields = {
  GO_SEARCH_RESPONSE_ROW_ID: 'goSearchResponseRowId',
  EICW_CODE: 'eicwCode',
  QUANTITY: 'quantity',
  MAX_QUANTITY: 'maxQuantity',
  PRODUCTION_TIME: 'issueDate',
  CONSUMPTION_TIME: 'consumptionDate',
  EXPIRATION_TIME: 'expirationDate',
  TRANSFER_DEADLINE: 'transferDeadline',
  TRANSFER_DEADLINE_PASSED: 'transferDeadlinePassed',
  TECHNOLOGY: 'technologyType',
  FUEL_TYPE: 'fuelType',
  FEEDSTOCK: 'feedstock',
  BIOFUEL_TYPE: 'biofuelType',
  PRODUCTION_PATHWAY: 'productionPathway',
  GOS_COST: 'gosCost',
  GOS_PRICE: 'gosPrice'
};

export const accountReportTableFields = {
  TECHNOLOGY: 'technologyType',
  FUEL_TYPE: 'fuelType',
  EICW_CODE: 'eicwCode',
  CANCELLED_GO: 'cancelledGos',
  END_BALANCE: 'endBalance',
  ENTERED_SET: 'enteredTransferGoSet',
  EXPIRED_SET: 'expiredGoSet',
  ISSUED_GO: 'issuedGos',
  START_BALANCE: 'startBalance',
  TRANSFERRED_GO: 'transferredGos',
};
