import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {connect} from 'react-redux';
import {DatePicker, Spin, Button, Form, notification} from 'antd';
import * as actions from '../../actions/actionCreators/manualGoIssuingActionCreators';
import {FORMAT_DEFAULT_MONTH, FORMAT_DEFAULT} from '../../utils/dates';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import {
  getMeteringPointsData,
  getMeteringPoints,
  getRecalculate,
} from '../../reducers/manualGoIssuingReducer';
import {ManualGoIssuingMeteringPoints, TableActions} from '../../components';

const {MonthPicker} = DatePicker;
const FormItem = Form.Item;

export class ManualGoIssuing extends Component {
  static propTypes = {
    legalEntityId: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
    fetchAvpMeteringPoints: PropTypes.func.isRequired,
    meteringPoints: PropTypesImmutable.list.isRequired,
    isLoading: PropTypes.bool.isRequired,
    recalculateGo: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
  };

  state = {
    selectedPoints: [],
    month: '',
  };

  componentDidMount() {
    const {fetchAvpMeteringPoints, legalEntityId} = this.props;

    fetchAvpMeteringPoints(legalEntityId);
  }

  handleRowSelected = selectedKeys => {
    this.setState({selectedPoints: selectedKeys});
  };

  handleMonthChange = value => {
    this.setState({month: value && value.format(FORMAT_DEFAULT)});
  };

  handleRecalculate = async () => {
    const {recalculateGo, t, onDone} = this.props;
    const {selectedPoints, month} = this.state;

    const result = await recalculateGo({
      generationPeriod: month,
      measurementPointIds: selectedPoints,
    });

    if (!result.error) {
      notification.info({
        message: t('recalcInfoTitle'),
        description: t('recalcInfoDescription'),
      });
    }

    onDone();
  };

  render() {
    const {t, meteringPoints, isLoading} = this.props;
    const {selectedPoints, month} = this.state;

    const isRecalculateDisabled = !(selectedPoints.length > 0 && month);

    return (
      <React.Fragment>
        <FormItem label={t('month')}>
          <MonthPicker
            format={FORMAT_DEFAULT_MONTH}
            onChange={this.handleMonthChange}
          />
        </FormItem>
        <Spin className="global-margin-top-16" spinning={isLoading}>
          <h4>{t('pointsTitle')}</h4>
          <ManualGoIssuingMeteringPoints
            t={t}
            onRowSelect={this.handleRowSelected}
            points={meteringPoints.toJS()}
          />
          <TableActions>
            <Button
              type="primary"
              disabled={isRecalculateDisabled}
              onClick={this.handleRecalculate}
            >
              {t('recalculateBtn')}
            </Button>
          </TableActions>
        </Spin>
      </React.Fragment>
    );
  }
}

const getIsLoading = getLoadingSelector(getMeteringPoints, getRecalculate);

const mapStateToProps = state => ({
  meteringPoints: getMeteringPointsData(state),
  isLoading: getIsLoading(state),
});

const mapDispatchToProps = {
  fetchAvpMeteringPoints: actions.fetchAvpMeteringPoints,
  recalculateGo: actions.recalculateGo,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  translate(NAMESPACES.manualGoIssuing)(ManualGoIssuing)
);
