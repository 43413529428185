import {handleActions} from 'redux-actions';
import {createAsyncReducer, initialAsyncState} from '../utils/asyncHelpers';
import {REQUEST_CHANGE} from '../constants/actionTypes/requestChangeActionTypes';

export default handleActions(
  createAsyncReducer(REQUEST_CHANGE),
  initialAsyncState
);

export const getChangeRequest = state => state.getIn(['requestChange']);
