import {handleActions} from 'redux-actions';
import {Map} from 'immutable';
import * as actions from '../actions/pureActions/tsAgreementActions';
import {createAsyncReducer, initialAsyncState,} from '../utils/asyncHelpers';
import {
  REGISTER_TS_AGREEMENT,
  WITHDRAW_TS_AGREEMENT,
} from '../constants/actionTypes/tsAgreementActionTypes';

const initialState = Map({
  selectedReceiver: null,
  tsAgreementRow: initialAsyncState,
  withdraw: Map({
    effectiveDate: null,
    reason: '',
    violation: false
  }),
});

export default handleActions(
  {
    ...createAsyncReducer(REGISTER_TS_AGREEMENT, 'tsAgreementRow'),
    ...createAsyncReducer(WITHDRAW_TS_AGREEMENT, 'withdraw'),
    [actions.selectReceiver]: (state, {payload}) =>
      state.merge({selectedReceiver: payload}),
  },
  initialState,
);

export const getTsAgreementRow = state =>
  state.getIn(['tsAgreement', 'tsAgreementRow']);

export const getSelectedReceiver = state =>
  state.getIn(['tsAgreement', 'selectedReceiver']);

export const getAgreementWithdrawData = state =>
  state.getIn(['tsAgreement', 'withdraw'])
