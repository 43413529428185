import {handleActions} from 'redux-actions';
import {Map} from 'immutable';
import {
  auctionBidResetMaster,
  auctionDetailResetMaster
} from "../actions/pureActions/auctionActions";
import {
  createAsyncReducer,
  createAsyncSelector,
  initialAsyncState,
  listInitialAsyncState,
} from '../utils/asyncHelpers';
import {auction} from "../schemas";
import {createAsyncPagingReducer, listInitialAsyncPagingState} from "../utils/asyncPagingHelpers";
import {
  AUCTION_DETAILS,
  AUCTION_FETCH,
  HISTORY_AUCTIONS_CREATED,
  HISTORY_AUCTIONS_ENDED,
  HISTORY_AUCTIONS_WON,
  SELECT_TS_AUCTION_ROWS,
  SEND_TO_AUCTION,
  STATISTICS_DETAILS,
  TS_BID_DETAILS
} from "../constants/actionTypes/tsAuctionActionTypes";
import tsAuctionDetailsSchema from "../schemas/TsAuctionDetails";
import {tradingAccountOverviewTableFields} from "../constants/TradingAccountOverviewTable";
import {groupTs} from "../utils/tsGroupHelpers";

const initialState = Map({
  sendToAuction: initialAsyncState,
  view: initialAsyncState,
  selectedAuctionRows: listInitialAsyncState,
  tsBidDetails: initialAsyncState,
  bidOnAuction: initialAsyncState,
  auctionsWon: listInitialAsyncPagingState,
  auctionsCreated: listInitialAsyncPagingState,
  auctionsEnded: listInitialAsyncPagingState,
  auctionDetails: initialAsyncState,
  statisticsDetails: listInitialAsyncState
});

export default handleActions(
  {
    ...createAsyncReducer(SEND_TO_AUCTION, 'sendToAuction'),
    ...createAsyncReducer(SELECT_TS_AUCTION_ROWS, 'selectedAuctionRows'),
    ...createAsyncReducer(AUCTION_FETCH, 'view'),
    ...createAsyncReducer(TS_BID_DETAILS, 'tsBidDetails'),
    ...createAsyncReducer(AUCTION_DETAILS, 'auctionDetails'),
    ...createAsyncReducer(STATISTICS_DETAILS, 'statisticsDetails'),
    ...createAsyncPagingReducer(HISTORY_AUCTIONS_CREATED, 'auctionsCreated'),
    ...createAsyncPagingReducer(HISTORY_AUCTIONS_WON, 'auctionsWon'),
    ...createAsyncPagingReducer(HISTORY_AUCTIONS_ENDED, 'auctionsEnded'),
    [auctionBidResetMaster]: state => state.merge({
      tsBidDetails: initialAsyncState
    }),
    [auctionDetailResetMaster]: state => state.merge({
      auctionDetails: initialAsyncState,
      statisticsDetails: listInitialAsyncState
    }),
  },
  initialState,
);

export const getSelectedAuctionRows = state =>
  state.getIn(['tsAuction', 'selectedAuctionRows']);

export const getSelectedAuctionRowsData = state => {
  const data = getSelectedAuctionRows(state).get('data').toJS();
  data.forEach(item => item[tradingAccountOverviewTableFields.MIN_BID_STEP] = 0.001);
  return data;
}

export const getAuctionDetails = state =>
  state.getIn(['tsAuction', 'auctionDetails']);

export const getAuctionDetailsData = createAsyncSelector(
  tsAuctionDetailsSchema,
  getAuctionDetails
);

export const getStatisticsDetails = state => {
  return state.getIn(['tsAuction', 'statisticsDetails']);
}

export const getStatisticsDetailsDataAndSummary = state => {
  const data = getStatisticsDetails(state).get('data').toJS();
  const sumNatural = data.reduce((total, item) => total + item.naturalAmountMj, 0);
  const sumAccounted = data.reduce((total, item) => total + (item.naturalAmountMj * item.multiplier), 0);
  const sumKhg = data.reduce((total, item) => total + item.ghgAmount, 0);
  const groupedData = groupTs(data, getAuctionDetailsData(state).toJS().fuelType)
  groupedData.push({
    isSummary: true,
    naturalAmountMj: sumNatural,
    accountedAmountMj: sumAccounted,
    ghgAmount: sumKhg
  });
  return groupedData;
}

export const getToAuction = state =>
  state.getIn(['tsAuction', 'sendToAuction']);

export const getAuctionHistoryCreated = state => {
  return state.getIn(['tsAuction', 'auctionsCreated']);
};

export const getAuctionHistoryCreatedEntities = createAsyncSelector(
  [auction],
  getAuctionHistoryCreated
);

export const getAuctionHistoryWon = state =>
  state.getIn(['tsAuction', 'auctionsWon']);

export const getAuctionHistoryWonEntities = createAsyncSelector(
  [auction],
  getAuctionHistoryWon
);

export const getAuctionHistoryEnded = state =>
  state.getIn(['tsAuction', 'auctionsEnded']);

export const getAuctionHistoryEndedEntities = createAsyncSelector(
  [auction],
  getAuctionHistoryEnded
);

export const getAuction = state => state.getIn(['tsAuction', 'view']);

export const getAuctionData = createAsyncSelector(
  auction,
  getAuction
);

export const getTsBidDetails = state =>
  state.getIn(['tsAuction', 'tsBidDetails']);

export const getTsBidDetailsData = state =>
  state.getIn(['tsAuction', 'tsBidDetails', 'data']);

export const getBidOnAuction = state =>
  state.getIn(['tsAuction', 'bidOnAuction']);
