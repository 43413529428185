import {createAction} from 'redux-actions';

export const goNextStepAction = createAction('LOADING_UNIT_ADD_NEXT_STEP');
export const goPrevStepAction = createAction('LOADING_UNIT_ADD_PREV_STEP');
export const resetMaster = createAction('LOADING_UNIT_ADD_RESET_MASTER');
export const resetMasterLegalList = createAction('LOADING_UNIT_LEGAL_LIST_RESET_MASTER');
export const toggleAddMode = createAction('LOADING_UNIT_TOGGLE_ADD_MODE');
export const selectOwner = createAction('LOADING_UNIT_ADD_SELECT_OWNER');

export const changeMeteringPointSelectionAction = createAction('LOADING_UNIT_ADD_CHANGE_METERING_POINT_SELECTION');
export const changeMeteringPointConsumerAction = createAction('LOADING_UNIT_ADD_CHANGE_METERING_POINT_CUSTOMER');
export const changeMeteringPointHasDirectConnectionAction = createAction('LOADING_UNIT_ADD_CHANGE_METERING_POINT_HAS_DIRECT_CONNECTION');
export const changeMeteringPointProdUnitEICWAction = createAction('LOADING_UNIT_ADD_CHANGE_METERING_POINT_PROD_UNIT_EICW');
