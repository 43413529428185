import {handleActions} from 'redux-actions';
import {
  createAsyncReducer,
  createAsyncSelector,
  initialAsyncState,
  listInitialAsyncState,
  toSuccess,
} from '../utils/asyncHelpers';
import {Map} from 'immutable';
import {
  measurement as meteringPointSchema,
  meteringPointReviewSchema,
} from '../schemas';
import {
  METERING_POINT_FETCH,
  METERING_POINT_UPDATE,
  METERING_POINT_AGR_DATA_FETCH,
  METERING_POINT_ACTIVATION,
  METERING_POINT_REVIEW_FETCH,
} from '../constants/meteringPointActionTypes';
import {
  NOTIFICATION_ADMIN_APPROVE,
  NOTIFICATION_ADMIN_DECLINE,
} from '../constants/notificationActionTypes';

const meteringPointReducer = handleActions(
  {
    ...createAsyncReducer(METERING_POINT_FETCH, 'point'),
    ...createAsyncReducer(METERING_POINT_UPDATE, 'point'),
    ...createAsyncReducer(METERING_POINT_AGR_DATA_FETCH, 'data'),
    ...createAsyncReducer(METERING_POINT_ACTIVATION, 'activation'),
    ...createAsyncReducer(METERING_POINT_REVIEW_FETCH, 'reviewList'),
    [toSuccess(NOTIFICATION_ADMIN_APPROVE)]: (state, {payload}) =>
      state.setIn(
        ['reviewList', 'data'],
        state.getIn(['reviewList', 'data']).filter(id => id !== payload)
      ),
    [toSuccess(NOTIFICATION_ADMIN_DECLINE)]: (state, {payload}) =>
      state.setIn(
        ['reviewList', 'data'],
        state.getIn(['reviewList', 'data']).filter(id => id !== payload)
      ),
  },
  Map({
    point: initialAsyncState,
    data: listInitialAsyncState,
    activation: initialAsyncState,
    reviewList: listInitialAsyncState,
  })
);

export default meteringPointReducer;

export const getMeteringPoint = state =>
  state.getIn(['meteringPoint', 'point']);
export const getMeteringPointEntities = createAsyncSelector(
  meteringPointSchema,
  getMeteringPoint
);

export const getMeteringPointAgrData = state =>
  state.getIn(['meteringPoint', 'data']);
export const getMeteringPointAgrDataEntities = state =>
  state.getIn(['meteringPoint', 'data', 'data']);

export const getMeteringPointReviewList = state =>
  state.getIn(['meteringPoint', 'reviewList']);
export const getMeteringPointReviewListEntities = createAsyncSelector(
  [meteringPointReviewSchema],
  getMeteringPointReviewList
);

export const getMeteringPointActivation = state =>
  state.getIn(['meteringPoint', 'activation']);
