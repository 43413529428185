import React from "react";
import {Layout} from "antd";
import VDSMessagesList from "../../containers/VDSMessagesList/VDSMessagesList";

function VDSMessages() {
  return (
    <Layout className="main-container">
      <VDSMessagesList/>
    </Layout>
  );
}

export default VDSMessages;
