export default {
  ACID_OIL_COOK_OIL: 'ACID_OIL_COOK_OIL',
  ALGAE: 'ALGAE',
  ANIMAL_FATS:
    'ANIMAL_FATS',
  ANIMAL_MANU_SEW_SLUD: 'ANIMAL_MANU_SEW_SLUD',
  BAGASSE: 'BAGASSE',
  BARLEY: 'BARLEY',
  BIOMASS_INDUST_WASTE: 'BIOMASS_INDUST_WASTE',
  BIOMASS_MIX_WASTE:
    'BIOMASS_MIX_WASTE',
  BIOMASS_WASTE_FOREST:
    'BIOMASS_WASTE_FOREST',
  BIO_WASTE: 'BIO_WASTE',
  BROWN_GREASE: 'BROWN_GREASE',
  COBS_KERNELS_CORN: 'COBS_KERNELS_CORN',
  CORN: 'CORN',
  CRUDE_GLYCERINE: 'CRUDE_GLYCERINE',
  FARMED_WOOD: 'FARMED_WOOD',
  GRAPE_MAR_WINE_LEES: 'GRAPE_MAR_WINE_LEES',
  HUSKS: 'HUSKS',
  NUT_SHELLS: 'NUT_SHELLS',
  PALM_OIL: 'PALM_OIL',
  PALM_OIL_MILL: 'PALM_OIL_MILL',
  PALM_OIL_MILL_BUNCH:
    'PALM_OIL_MILL_BUNCH',
  RAPESEED: 'RAPESEED',
  SOAPSTOCK_SULPHUR:
    'SOAPSTOCK_SULPHUR',
  SOYBEANS: 'SOYBEANS',
  SPENT_BLEACHED_EARTH: 'SPENT_BLEACHED_EARTH',
  STARCH_SLURRY: 'STARCH_SLURRY',
  STRAW: 'STRAW',
  SUGAR_BEET: 'SUGAR_BEET',
  SUGAR_CANE: 'SUGAR_CANE',
  SUNFLOWER_SEED: 'SUNFLOWER_SEED',
  TALL_OIL_PITCH: 'TALL_OIL_PITCH',
  TALLOW_CAT_3_UNKN: 'TALLOW_CAT_3_UNKN',
  USED_COOK_OIL: 'USED_COOK_OIL',
  WASTE_PRESNG_VEG_OIL:
    'WASTE_PRESNG_VEG_OIL',
  WASTE_VEG_ANIM_OIL: 'WASTE_VEG_ANIM_OIL',
  WASTE_WOOD: 'WASTE_WOOD',
  WHEAT: 'WHEAT',
  OTHER_CEREALS: 'OTHER_CEREALS',
  OTHER_OIL_CROPS: 'OTHER_OIL_CROPS',
  OTHER_SUGAR_CROPS: 'OTHER_SUGAR_CROPS',
  OTHER: 'OTHER',
};
