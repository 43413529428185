import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'antd';
import {links} from '../../utils';
import {Login as LoginForm} from '../../forms';

class Login extends React.Component {
  static propTypes = {
    loginCode: PropTypes.string,
    t: PropTypes.func.isRequired,
  };

  render() {
    const {loginCode, t} = this.props;
    return (
      <div className="landing-main__login-box__btn-container">
        <a
          href={links.oauth}
          className="landing-main__login-box__btn-container__btn"
        >
          {t('loginBtn')}
        </a>
        <Modal footer={null} visible={!!loginCode} closable={false}>
          <LoginForm t={t} loginCode={loginCode}/>
        </Modal>
      </div>
    );
  }
}

export default Login;
