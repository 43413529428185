import {
  listInitialAsyncPagingState,
  createAsyncPagingReducer,
} from '../utils/asyncPagingHelpers';
import {Map} from 'immutable';
import {
  createAsyncReducer,
  listInitialAsyncState,
  createAsyncSelector,
} from '../utils/asyncHelpers';
import {handleActions} from 'redux-actions';
import {
  CONSUMER_GO_SEARCH_FETCH,
  CONSUMER_METERING_POINTS_FETCH,
} from '../constants/actionTypes/consumerGoSearch';
import {measurement as meteringPointScheme} from '../schemas';

const initialState = Map({
  gos: listInitialAsyncPagingState,
  points: listInitialAsyncState,
});

const consumerGoSearch = handleActions(
  {
    ...createAsyncPagingReducer(CONSUMER_GO_SEARCH_FETCH, 'gos'),
    ...createAsyncReducer(CONSUMER_METERING_POINTS_FETCH, 'points'),
  },
  initialState
);

export const getConsumerGoList = state =>
  state.getIn(['consumerGoSearch', 'gos']);

export const getConsumerGoListData = state =>
  state.getIn(['consumerGoSearch', 'gos', 'data']);

export const getMeteringPoints = state =>
  state.getIn(['consumerGoSearch', 'points']);

export const getMeteringPointsData = createAsyncSelector(
  [meteringPointScheme],
  getMeteringPoints
);

export default consumerGoSearch;
