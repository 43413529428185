import {createAsyncSelector} from '../utils/asyncHelpers';
import {handleActions} from 'redux-actions';
import {ACCOUNT_GOS_SELLING_AGREEMENTS_LIST_FETCH} from '../constants/accountGOSListActionTypes';
import accountGOSAgreementsSchema from '../schemas/accountGOSTransactions';
import {createAsyncPagingReducer, listInitialAsyncPagingState,} from '../utils/asyncPagingHelpers';
import {resetMaster} from "../actions/pureActions/agreementActions";

const accountGOSSellingAgreementsList = handleActions(
  {
    ...createAsyncPagingReducer(ACCOUNT_GOS_SELLING_AGREEMENTS_LIST_FETCH),
    [resetMaster]: state => state.merge(listInitialAsyncPagingState),
  },
  listInitialAsyncPagingState
);

export const getAccountGOSSellingAgreementsList = state => {
  return state.get('accountGOSSellingAgreementsList');
};

export const getAccountGOSSellingAgreementsListData = createAsyncSelector(
  [accountGOSAgreementsSchema],
  getAccountGOSSellingAgreementsList
);

export default accountGOSSellingAgreementsList;
