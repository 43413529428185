import React, {Component} from 'react';
import {connect} from 'react-redux';
import {message, Spin} from 'antd';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {
  getGroupedCertificates,
  getGroupedCertificatesData,
} from '../../reducers/accountOverviewReducer';
import * as selectors from '../../reducers/transferringCertificateReducer';
import * as actions from '../../actions/actionCreators/certificateActionCreators';
import {
  PerformCertificatesTable,
  AutoCompleteAccountsSelect,
} from '../../components';
import {accountTableFields} from '../../constants/accountTable';
import {accountFields} from "../../constants/account";
import {isNumber} from "lodash";

export class TransferringCertificates extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    selectReceiver: PropTypes.func.isRequired,
    transferCertificates: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    selectedReceiver: PropTypes.number,
    accountId: PropTypes.string.isRequired,
    groupedCertificates: PropTypesImmutable.list.isRequired,
  };

  componentDidMount = () => {
    this.props.selectReceiver(null);
  };

  isUsersOtherAccount(selectedAccountId) {
    const {accounts} = this.props;
    for (let i = 0; i < accounts.size; i++) {
      if (accounts.get(i).get(accountFields.ID) === selectedAccountId)
        return true;
    }
    return false;
  }

  validateTransferCertificates(selectedRows, isUsersOtherAccount) {
    const {t} = this.props;
    let error = false;
    selectedRows.forEach((element, index) => {
      if (!isUsersOtherAccount) {
        if (!isNumber(selectedRows[index][accountTableFields.GOS_PRICE]) || selectedRows[index][accountTableFields.GOS_PRICE] < 0) {
          message.error(t('errorGosPrice'));
          error = true;
        }
      }
    });
    return error;
  }

  render() {
    const {
      isLoading,
      t,
      groupedCertificates,
      selectedReceiver,
      selectReceiver,
      transferCertificates,
      accountId,
    } = this.props;

    if (!isLoading && groupedCertificates.isEmpty()) {
      return t('groupedEmpty');
    }

    return (
      <Spin spinning={isLoading}>
        <h2>{t('receiver')}</h2>
        <AutoCompleteAccountsSelect
          unacceptableValues={[accountId]}
          value={selectedReceiver}
          onChange={selectReceiver}
          placeholder={t('chooseReceiver')}
        />
        {selectedReceiver && (
          <React.Fragment>
            <h2 className="global-margin-top-24">{t('defineGO')}</h2>
            <PerformCertificatesTable
              dataList={groupedCertificates.toJS()}
              isUsersOtherAccount={this.isUsersOtherAccount(selectedReceiver)}
              validateAction={this.validateTransferCertificates.bind(this)}
              performAction={dataList =>
                transferCertificates({
                  receiverAccountId: selectedReceiver,
                  currentAccountId: parseInt(accountId, 10),
                  selectedRows: dataList,
                })
              }
            />
          </React.Fragment>
        )}
      </Spin>
    );
  }
}

const getLoading = getLoadingSelector(
  getGroupedCertificates,
  selectors.getTransferring
);

const mapStateToProps = state => ({
  isLoading: getLoading(state),
  groupedCertificates: getGroupedCertificatesData(state),
  selectedReceiver: selectors.getSelectedReceiver(state),
});

const mapDispatchToProps = {
  selectReceiver: actions.selectReceiver,
  transferCertificates: actions.transferCertificates,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  TransferringCertificates
);
