import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as actions from '../../actions/actionCreators/userActionCreators';
import {Spin} from 'antd';
import './consumerLogin.css';

class ConsumerLogin extends Component {
  static propTypes = {
    findConsumerMandate: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const {findConsumerMandate} = this.props;

    findConsumerMandate();
  }

  render() {
    return (
      <div className="consumer-login">
        <Spin size="large"/>
      </div>
    );
  }
}

const mapDispatchToProps = {
  findConsumerMandate: actions.findConsumerMandate,
};

export default connect(null, mapDispatchToProps)(ConsumerLogin);
