import {createAsyncSelector} from '../utils/asyncHelpers';
import {handleActions} from 'redux-actions';
import {ACCOUNT_GOS_TRANSACTOINS_LIST_FETCH} from '../constants/accountGOSListActionTypes';
import accountGOSAvailableStatistics from '../schemas/accountGOSTransactions';
import {
  listInitialAsyncPagingState,
  createAsyncPagingReducer,
} from '../utils/asyncPagingHelpers';

const accountGOSTransactionsList = handleActions(
  {
    ...createAsyncPagingReducer(ACCOUNT_GOS_TRANSACTOINS_LIST_FETCH),
  },
  listInitialAsyncPagingState
);

export const getAccountGOSTransactionsList = state =>
  state.get('accountGOSTransactionsList');

export const getAccountGOSTransactionsListData = createAsyncSelector(
  [accountGOSAvailableStatistics],
  getAccountGOSTransactionsList
);

export default accountGOSTransactionsList;
