import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {connect} from 'react-redux';
import {getSeller, getSellerData} from '../../reducers/consumerChartReducer';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {Spin} from 'antd';
import * as actions from '../../actions/actionCreators/consumerChartActionCreators';
import {ConsumerReportSection} from '../../components';

export class ConsumerChartSeller extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    fetchConsumerChart: PropTypes.func.isRequired,
    reportData: PropTypesImmutable.map,
    seller: PropTypes.number,
    sellerName: PropTypes.string,
    isAvp: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    seller: null,
  };

  componentWillReceiveProps(nextProps) {
    const {seller: oldSeller, fetchConsumerChart} = this.props;
    const {seller: newSeller} = nextProps;

    if (newSeller && newSeller !== oldSeller) {
      fetchConsumerChart(newSeller);
    }
  }

  render() {
    const {reportData, loading, t, sellerName, isAvp} = this.props;

    const data = reportData ? reportData.toJS() : {};

    return (
      <Spin
        spinning={loading}
        wrapperClassName="consumer-charts__chart-wrapper"
      >
        <ConsumerReportSection
          data={data}
          t={t}
          sellerName={sellerName}
          isYear
          isAvp={isAvp}
          showProductionCountry
          isSeller
        />
      </Spin>
    );
  }
}

const getIsLoading = getLoadingSelector(getSeller);

const mapStateToProps = state => ({
  loading: getIsLoading(state),
  reportData: getSellerData(state),
});

const mapDispatchToProps = {
  fetchConsumerChart: actions.fetchConsumerChartSeller,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  ConsumerChartSeller
);
