export const transactionFields = {
  ID: 'id',
  TYPE: 'transactionType',
  CREATE_TIME: 'transactionCreateTime',
  GO_AMOUNT: 'transactionGoAmount',
  GO_INFORMATION: 'goInformation',
  GOS_INFORMATION: 'gosInformation',
  SENDER_ACCOUNT: 'fromAccount',
  RECEIVER_ACCOUNT: 'toAccount',
  END_DATE: 'endDate',
  START_DATE: 'startDate',
  STATUS: 'status',
  HISTORY: 'history',
  COMMENT: 'comment',
  MANUAL_ISSUE: 'manualIssue',
  SENDER_INFORMATION: 'senderInformation',
  RECEIVER_INFORMATION: 'receiverInformation',
  CONSUMER_INFORMATION: 'consumerInformation',
  CONSUMPTION_INFORMATION: 'consumptionInformation',
  CAN_RECALL: 'canBeRecalled',
  CAN_SEND_VDS_REQUEST: 'canSendVdsRequest',
  GOS_PRICE: 'gosPrice',
};

export const transactionAccountFields = {
  ID: 'id',
  COMPANY: 'companyName',
  TYPE: 'accountType',
};

export const transactionHistoryFields = {
  ID: 'id',
  END_TIME: 'endTime',
  START_TIME: 'startTime',
  STATUS: 'status',
  TRANSACTION: 'transaction',
};

export const transactionGOInfoFields = {
  GO_AMOUNT: 'goAmount',
  START_BALANCE: 'startBalance',
  END_BALANCE: 'endBalance',
  ISSUE_PERIOD: 'issuePeriod',
  UNIT_EICX: 'unitEicx',
  TECHNOLOGY: 'technology',
  FUEL_TYPE: 'fuelType',
};

export const transactionParticipantFields = {
  NAME: 'name',
  CODE: 'code',
  ACCOUNT_NUMBER: 'accountNumber',
};

export const transactionConsumerFields = {
  NAME: 'name',
  CODE: 'code',
  EICX: 'eicx',
  ACCOUNT_NUMBER: 'accountNumber',
  CONSUMER_TYPE: 'consumerType',
};

export const transactionConsumptionFields = {
  ADDRESS: 'address',
  CONSUMED_AMOUNT: 'consumedAmount',
  CONSUMPTION_USAGE: 'consumptionUsage',
  DATA_SOURCE: 'dataSource',
  METERING_EIXZ: 'meteringPointEixz',
  PERIOD: 'period',
};
