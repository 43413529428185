import {initialize, submit} from 'redux-form';
import {
  FORM_REGISTER_CONTACT,
  FORM_REGISTER_ENTITY_CONTACT,
  formRegisterContactFields,
} from '../../constants/formRegister';
import {legalEntityContactSchema} from '../../schemas';
import {Map} from 'immutable';
import * as actionTypes from '../../constants/registerContactsActionTypes';
import {createAsyncAction, normalizeHelper} from '../../utils/asyncHelpers';
import {
  legalEntityContactsFetch,
  legalEntityContactAdd,
  legalEntityContactUpdate,
  legalEntityContactDelete,
} from '../actionHelpers/endpoints';
import {showRegisterContactModal} from './modalsActionCreators';

export const fetchLegalEntityContacts = createAsyncAction(
  actionTypes.ENTITY_CONTACTS_FETCH,
  legalEntityContactsFetch,
  normalizeHelper([legalEntityContactSchema])
);

export const addLegalEntityContact = createAsyncAction(
  actionTypes.ENTITY_CONTACTS_ADD,
  legalEntityContactAdd,
  normalizeHelper(legalEntityContactSchema)
);

export const startAddLegalEntityContact = legalEntityId => dispatch => {
  dispatch(
    initialize(
      FORM_REGISTER_CONTACT,
      Map({legalEntityId, [formRegisterContactFields.PHONE_CODE]: '+372'})
    )
  );
  dispatch(showRegisterContactModal());
};
export const startEditLegalEntityContact = values => dispatch => {
  dispatch(initialize(FORM_REGISTER_CONTACT, values));
  dispatch(showRegisterContactModal());
};

export const updateLegalEntityContact = createAsyncAction(
  actionTypes.ENTITY_CONTACTS_UPDATE,
  legalEntityContactUpdate,
  normalizeHelper(legalEntityContactSchema)
);

export const deleteLegalEntityContact = createAsyncAction(
  actionTypes.ENTITY_CONTACTS_DELETE,
  legalEntityContactDelete
);

export const submitEntityContactForm = () => dispatch =>
  dispatch(submit(FORM_REGISTER_ENTITY_CONTACT));
