import React, {Component} from 'react';
import {Button, Col, DatePicker, Form, Input, Row, Select} from 'antd';
import PropTypes from "prop-types";
import {VDS_REQUEST_TYPES} from '../../constants/vdsMessage';


class VDSMessagesFilterForm extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onFilter: PropTypes.func.isRequired
  };

  onClear = () => {
    this.props.form.resetFields();
    this.props.onFilter({});
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.form.validateFields((error, values) => this.props.onFilter(values));
  };

  render() {
    const {t, form} = this.props;
    const {getFieldDecorator} = form;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item label={t('filter.requestId')} hasFeedback={false}>
              {getFieldDecorator('requestId')(
                <Input placeholder={t('filter.requestIdPlaceholder')}/>
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={t('filter.requestType')} hasFeedback={false}>
              {getFieldDecorator('requestType')(
                <Select placeholder={t('filter.requestTypePlaceholder')} allowClear>
                  {VDS_REQUEST_TYPES.map((type, index) =>
                    <Select.Option key={index}
                                   value={type}>{t('values.requestType_' + type)}</Select.Option>
                  )}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={t('filter.success')} hasFeedback={false}>
              {getFieldDecorator('success')(
                <Select placeholder={t('filter.successPlaceholder')} allowClear>
                  <Select.Option value="1">{t('values.successYes')}</Select.Option>
                  <Select.Option value="0">{t('values.successNo')}</Select.Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={t('filter.requestDate')} hasFeedback={false}>
              {getFieldDecorator('requestDate')(
                <DatePicker.RangePicker format="DD.MM.YYYY"/>
              )}
            </Form.Item>
          </Col>
        </Row>
        <div className={'component-form-actions'}>
          <Button onClick={this.onClear}>{t('filter.clearBtn')}</Button>
          <Button type="primary" htmlType="submit">
            {t('filter.submitBtn')}
          </Button>
        </div>
      </Form>
    );
  }
}

const VDSMessagesFilter = Form.create({})(VDSMessagesFilterForm);
export default VDSMessagesFilter;
