import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {TableRowActions} from "../../components";
import {Button, Spin, Table} from "antd";
import {agreementTransportStatisticFields} from "../../constants/agreementTransportStatistics";
import {numberFormatter} from "../../utils/formaters";
import * as actions from "../../actions/actionCreators/agreementViewActionCreators";
import {
  getTransportStatisticsDetails,
  getTransportStatisticsDetailsEntity
} from "../../reducers/agreementViewReducer";
import {
  TransportStatisticsDetailsModal
} from "../TransportStatisticsDetailsModal/TransportStatisticsDetailsModal";
import {
  getTransportStatisticsDetailsModal,
  hideTransportStatisticsDetailsModal,
  showTransportStatisticsDetailsModal
} from "../../actions/actionCreators/modalsActionCreators";
import {getLoadingSelector} from "../../utils/asyncHelpers";
import {getUserLegalEntityId} from "../../reducers/userReducer";

export class TransportStatisticsList extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,

    agreement: PropTypes.object.isRequired,
    search: PropTypes.func.isRequired,
    transactions: PropTypes.array.isRequired,
    isTransactionsLoading: PropTypes.bool.isRequired,

    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    totalElements: PropTypes.number.isRequired,

    fetchDetails: PropTypes.func.isRequired,
    transportStatisticsDetails: PropTypes.object,
    isTransportStatisticsDetailsLoading: PropTypes.bool.isRequired,

    isDetailsModalVisible: PropTypes.bool.isRequired,
    showDetailsModal: PropTypes.func.isRequired,
    hideDetailsModal: PropTypes.func.isRequired,
  };

  getColumns = () => {
    const {t, userLegalEntityId} = this.props;

    return [
      {
        title: t('table.transactionDate'),
        dataIndex: agreementTransportStatisticFields.TRANSACTION_TIME,
      },
      {
        title: t('table.transactionNumber'),
        render: row => {
          if (userLegalEntityId === row[agreementTransportStatisticFields.FROM_LEGAL_ENTITY_ID]) {
            return row[agreementTransportStatisticFields.OUTGOING_TRANSACTION_ID];
          }
          return row[agreementTransportStatisticFields.INCOMING_TRANSACTION_ID];
        }
      },
      {
        title: t('table.naturalAmountMj'),
        dataIndex: agreementTransportStatisticFields.NATURAL_AMOUNT_MJ,
        render: val => numberFormatter(val)
      },
      {
        title: t('table.calculatedAmountMj'),
        dataIndex: agreementTransportStatisticFields.CALCULATED_AMOUNT_MJ,
        render: val => numberFormatter(val)
      },
      {
        title: t('table.ghgAmount'),
        dataIndex: agreementTransportStatisticFields.GHG_CAPACITY,
        render: val => numberFormatter(val)
      },
      {
        title: t('table.consumptionTime'),
        render: (_, row) => {
          const month = row[agreementTransportStatisticFields.CONSUMPTION_MONTH];
          const year = row[agreementTransportStatisticFields.CONSUMPTION_YEAR];

          return [
            month ? month.padStart(2, 0) : undefined,
            year
          ]
            .filter(Boolean)
            .join('.');
        }
      },
      {
        title: t('table.actions'),
        render: (row) => {
          return (
            <TableRowActions>
              <Button
                shape="circle"
                icon="select"
                size="small"
                type="primary"
                title={t('table.viewBtn')}
                onClick={() => this.viewDetails(row[agreementTransportStatisticFields.STATISTICS_ID])}
              />
            </TableRowActions>
          );
        },
        fixed: 'right',
        width: 100,
      },
    ];
  };

  onPageChange = page => {
    const {agreement, search, pageSize} = this.props;

    search(agreement.id, {
      page: page - 1,
      size: pageSize
    })
  }

  viewDetails = (statisticsId) => {
    const {
      agreement,
      fetchDetails,
      showDetailsModal
    } = this.props;

    fetchDetails(agreement.id, statisticsId);
    showDetailsModal();
  };

  render() {
    const {
      t,

      page,
      pageSize,
      totalElements,

      transactions,
      isTransactionsLoading,

      isTransportStatisticsDetailsLoading,
      transportStatisticsDetails,
      isDetailsModalVisible,
      hideDetailsModal,

      agreement,
    } = this.props;

    return (
      <React.Fragment>
        {isDetailsModalVisible &&
          <TransportStatisticsDetailsModal
            t={t}
            agreementData={agreement}
            transportStatisticsData={transportStatisticsDetails}
            isTransportStatisticsDataLoading={isTransportStatisticsDetailsLoading}
            hideModal={hideDetailsModal}
          />
        }

        <Spin spinning={isTransactionsLoading}>
          <Table
            scroll={{x: 'auto'}}
            rowKey={agreementTransportStatisticFields.TRANSACTION_ID}
            className="account-gos-agreements-table"
            dataSource={transactions}
            columns={this.getColumns()}
            pagination={{
              onChange: this.onPageChange,
              current: page,
              total: totalElements,
              pageSize,
              defaultCurrent: 1,
            }}
          />
        </Spin>
      </React.Fragment>
    );
  }
}

const findTransactionByTs = (transactions, statisticsId) => {
  return transactions.find(t => {
    return t[agreementTransportStatisticFields.STATISTICS_ID] === statisticsId;
  });
}

const mapTransactionToTs = (transactions, ts) => {
  const tsData = ts.toJS();
  const transaction = findTransactionByTs(transactions, tsData[agreementTransportStatisticFields.STATISTICS_ID]);

  if (transaction) {
    tsData.naturalAmountMj = transaction[agreementTransportStatisticFields.NATURAL_AMOUNT_MJ];
    tsData.calculatedAmountMj = transaction[agreementTransportStatisticFields.CALCULATED_AMOUNT_MJ];
  }
  return tsData;
}

const mapStateToProps = (state, props) => ({
  transportStatisticsDetails: mapTransactionToTs(props.transactions, getTransportStatisticsDetailsEntity(state)),
  isTransportStatisticsDetailsLoading: getLoadingSelector(getTransportStatisticsDetails)(state),
  isDetailsModalVisible: getTransportStatisticsDetailsModal(state),
  userLegalEntityId: getUserLegalEntityId(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchDetails: (agreementId, statisticsId) => {
    dispatch(actions.fetchTransportStatisticsDetails(agreementId, statisticsId))
  },
  showDetailsModal: () => {
    dispatch(showTransportStatisticsDetailsModal());
  },
  hideDetailsModal: () => {
    dispatch(hideTransportStatisticsDetailsModal());
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(
  TransportStatisticsList
);
