import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {accountTableFields} from '../../constants/accountTable';
import {Button, Icon, Table} from 'antd';
import _ from 'lodash/fp';
import {
  translateFuelTypeClassificator,
  translateCleaningTechnologyClassificator,
} from '../../utils/translateHelpers';
import {TableActions} from '../';
import moment from 'moment/moment';
import {accountStatus, accountType} from '../../constants/classificators';
import './AccountTable.css';

const hasField = field => _.flow(_.first, _.get(field), _.isEmpty, x => !x);

class AccountTable extends Component {
  static propTypes = {
    dataList: PropTypes.array,
    t: PropTypes.func.isRequired,
    accountId: PropTypes.string.isRequired,
    transferCertificates: PropTypes.func.isRequired,
    cancelCertificates: PropTypes.func.isRequired,
    isUserSeller: PropTypes.bool,
  };

  state = {
    selectedRows: []
  };

  getColumns = () => {
    const {t, dataList} = this.props;

    const columns = [];
    if (hasField(accountTableFields.EXPIRATION_TIME)(dataList)) {
      columns.push(
        {
          title: t('table.productionTime'),
          dataIndex: accountTableFields.PRODUCTION_TIME,
          render: value => {
            if (typeof value !== 'undefined') {
              return moment(value, 'DD/MM/YYYY').format('MM.YYYY');
            }
          },
        },
        {
          title: t('table.transferDeadline'),
          dataIndex: accountTableFields.TRANSFER_DEADLINE,
        },
        {
          title: t('table.expirationTime'),
          dataIndex: accountTableFields.EXPIRATION_TIME,
          // Total quantity summary
          render: (value, record) => {
            if (record.summary === true)
              return <b className={'summaryText'}> {t('table.total')}</b>
            return value
          }
        }
      );
    }

    if (hasField(accountTableFields.FUEL_TYPE)(dataList)) {
      columns.push({
        title: t('table.fuelType'),
        dataIndex: accountTableFields.FUEL_TYPE,
        render: translateFuelTypeClassificator,
      });
    }

    if (hasField(accountTableFields.TECHNOLOGY)(dataList)) {
      columns.push({
        title: t('table.technology'),
        dataIndex: accountTableFields.TECHNOLOGY,
        render: translateCleaningTechnologyClassificator,
      });
    }

    columns.push({
      title: t('table.quantity'),
      dataIndex: accountTableFields.QUANTITY,
      // Total quantity summary
      render: (value, record) => {
        if (record.summary === true)
          return <b className={'summaryAmount'}> {value}</b>
        return value
      }
    });

    columns.push({
      title: '',
      dataIndex: accountTableFields.TRANSFER_DEADLINE_PASSED,
      render: (value) => {
        if (!value) {
          return null;
        }

        return <Icon title={t('deadlinePassed')} type="info-circle" className={'danger-icon'}/>;
      }
    });

    return columns;
  };

  isTrader = () => {
    const {userAccounts, accountId} = this.props;
    let i;

    for (i = 0; i < userAccounts.size; i++) {
      if (
        String(userAccounts.get(i).get('id')) === String(accountId) &&
        userAccounts.get(i).get('accountType') === accountType.TRADER &&
        userAccounts.get(i).get('accountStatus') === accountStatus.ACTIVE
      )
        return true;
    }

    return false;
  };

  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({selectedRows: selectedRows});
    },
    // Disable last table row selection
    getCheckboxProps: (record) => ({
      disabled: record.summary === true,
      summary: record.summary,
    }),
  };

  render() {
    const {
      dataList,
      t,
      transferCertificates,
      cancelCertificates,
    } = this.props;
    const {selectedRows} = this.state;

    const isDeadlinePassed = !!(this.state.selectedRows || []).find(item => item.transferDeadlinePassed);

    return (
      <React.Fragment>
        <Table
          rowKey={accountTableFields.GO_SEARCH_RESPONSE_ROW_ID}
          dataSource={dataList}
          columns={this.getColumns()}
          pagination={false}
          rowSelection={this.rowSelection}
        />
        {selectedRows.length > 0 && (
          <TableActions>
            <Button
              type="primary"
              disabled={isDeadlinePassed}
              title={isDeadlinePassed ? t('deadlinePassed') : ''}
              onClick={() => transferCertificates(selectedRows)}
            >
              {t('buttonTransfer')}
            </Button>
            {this.isTrader() && (
              <Button
                type="primary"
                onClick={() => cancelCertificates(selectedRows)}
                className="global-margin-left-10"
              >
                {t('buttonCancel')}
              </Button>
            )}
          </TableActions>
        )}
      </React.Fragment>
    );
  }
}

export default AccountTable;
