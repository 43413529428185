import React, {Component} from 'react';
import RadioGroup from '../RadioGroup';
import ComponentWrapper from './ComponentWrapper';
import mapError from './mapError';

export default class RadioField extends Component {
  render() {
    return (
      <ComponentWrapper
        {...this.props}
        mapProps={mapError}
        render={props => <RadioGroup {...props} />}
      />
    );
  }
}
