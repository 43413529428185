import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import {Layout} from 'antd';
import {
  ClassifierView,
  ClassifierGeneralEdit,
  ParameterGridOperatorList,
  ParameterSellerList,
} from '../../containers';
import {
  ClassifiersTaxEdit,
  ClassifierInvoiceConfirmationEdit,
  ParameterGoAutoEdit,
  ParameterEleringEdit,
  ParameterSubsidyDateEdit,
  ParameterInvoiceDateEdit,
  ParameterGosAutoEdit,
  ParameterEAVPLoadCronEdit,
  ParameterGoExpirationEdit,
  ParameterAggregatedDataConfirmationCronEdit
} from '../../forms';
import classificatorTypes from '../../constants/classificators';
import parameterTypes from '../../constants/parameters';
import {
  ParameterConsumptionTypeEdit
} from "../../forms/ParameterConsumptionTypeEdit/ParameterConsumptionTypeEdit";
import ParameterConsumptionReportEndDayEdit
  from "../../forms/ParameterBiomethaneReportEndDayEdit/ParameterReportEndDayEdit";

class ClassifierViewPage extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        classifierId: PropTypes.string.isRequired,
      }),
    }),
  };

  render() {
    const {t, match} = this.props;

    let container = null;
    switch (match.params.classifierId) {
      case classificatorTypes.tax:
        container = <ClassifiersTaxEdit t={t}/>;
        break;
      case classificatorTypes.invoiceConfirmation:
        container = <ClassifierInvoiceConfirmationEdit t={t}/>;
        break;
      case classificatorTypes.averageStockPrice:
        container = (
          <ClassifierView t={t} classifierId={match.params.classifierId}/>
        );
        break;
      case classificatorTypes.averageHigherCalorific:
        container = (
          <ClassifierView t={t} classifierId={match.params.classifierId}/>
        );
        break;
      case parameterTypes.goAutomaticCancel:
        container = <ParameterGoAutoEdit t={t}/>;
        break;
      case parameterTypes.eavpLoadDataCron:
        container = <ParameterEAVPLoadCronEdit t={t}/>;
        break;
      case parameterTypes.gosAutomaticExpiration:
        container = <ParameterGosAutoEdit t={t}/>;
        break;
      case parameterTypes.goExpirationChangeDate:
        container = <ParameterGoExpirationEdit t={t}/>;
        break;
      case parameterTypes.gridOperator:
        container = <ParameterGridOperatorList t={t}/>;
        break;
      case parameterTypes.seller:
        container = <ParameterSellerList t={t}/>;
        break;
      case parameterTypes.elering:
        container = <ParameterEleringEdit t={t}/>;
        break;
      case parameterTypes.subsidyDate:
        container = <ParameterSubsidyDateEdit t={t}/>;
        break;
      case parameterTypes.invoiceDate:
        container = <ParameterInvoiceDateEdit t={t}/>;
        break;
      case parameterTypes.consumptionType:
        container = <ParameterConsumptionTypeEdit t={t} classifierId={match.params.classifierId}/>;
        break;
      case parameterTypes.monthlyAggregatedDataAutoConfirmationCron:
        container = <ParameterAggregatedDataConfirmationCronEdit t={t}/>;
        break;
      case parameterTypes.consumptionReportEndDay:
        container = <ParameterConsumptionReportEndDayEdit t={t}/>
        break;
      default:
        container = (
          <ClassifierGeneralEdit
            t={t}
            classifierId={match.params.classifierId}
          />
        );
    }

    return <Layout className="main-container">{container}</Layout>;
  }
}

export default translate(NAMESPACES.classifiers)(ClassifierViewPage);
