import {isEmpty} from 'lodash';
import moment from 'moment';
import {
  createValidation,
  validatorRequired,
  validatorDateGreaterThen,
} from '../../utils/formValidators';
import {FORMAT_DEFAULT} from '../../utils';
import {tRegister as t} from '../../i18n';

import {formRegisterRepresentativeCompanyFields} from '../../constants/formRegister';

const mandateLengthValidator = (value, form) => {
  const startDate = form.get(
    formRegisterRepresentativeCompanyFields.MANDATE_DATE_START
  );

  if (isEmpty(value)) {
    return undefined;
  }
  if (
    moment(value, FORMAT_DEFAULT).diff(
      moment(startDate, FORMAT_DEFAULT),
      'days'
    ) < 3
  ) {
    return t('representation.companyMandateLengthError');
  }
  return undefined;
};

export default createValidation([
  {
    name: formRegisterRepresentativeCompanyFields.LEGAL_ENTITY_ID,
    validators: validatorRequired(),
  },
  {
    name: formRegisterRepresentativeCompanyFields.MANDATE_DATE_START,
    validators: validatorRequired(),
  },
  {
    name: formRegisterRepresentativeCompanyFields.MANDATE_DATE_END,
    validators: [validatorDateGreaterThen(), mandateLengthValidator],
  },
]);
