import {createAsyncAction, normalizeHelper} from '../../utils/asyncHelpers';
import {
  consumerPointFetch,
  goAmountFetch,
  cancelCertificatesPost,
} from '../actionHelpers/endpoints';
import {measurement as meteringPointScheme} from '../../schemas';
import {
  CONSUMPTION_POINTS_FETCH,
  GO_AMOUNT_FETCH,
  CANCEL_CERTIFICATES,
} from '../../constants/actionTypes/cancelingCertificates';
import {
  selectConsumerAction,
  selectPointAction,
  selectPeriodsAction,
  resetAction,
} from '../pureActions/cancelingCertificates';

export const selectConsumer = id => dispatch =>
  dispatch(selectConsumerAction(id));

export const reset = () => dispatch => dispatch(resetAction());

export const selectPoint = id => dispatch => dispatch(selectPointAction(id));

export const selectPeriods = period => dispatch =>
  dispatch(selectPeriodsAction(period));

export const fetchConsumerPoints = createAsyncAction(
  CONSUMPTION_POINTS_FETCH,
  consumerPointFetch,
  normalizeHelper([meteringPointScheme])
);

export const fetchGoAmount = createAsyncAction(GO_AMOUNT_FETCH, goAmountFetch);
export const cancelCertificates = createAsyncAction(
  CANCEL_CERTIFICATES,
  cancelCertificatesPost
);
