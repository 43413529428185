import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Spin, Table} from 'antd';
import {FormActions, TableRowActions} from '../index';
import './TsAuctionSearchTable.scss';
import {auctionFields} from "../../constants/auction";
import {downloadFileByPostURL} from "../../utils/files";
import * as TsAuctionSearchTableColumns from "./TsAuctionSearchTableColumns";

export class TsAuctionSearchTable extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,

    search: PropTypes.func.isRequired,
    auctions: PropTypes.array.isRequired,
    isAuctionsLoading: PropTypes.bool.isRequired,

    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    totalElements: PropTypes.number.isRequired,

    goToAuctionDetails: PropTypes.func.isRequired,

    csv: PropTypes.object.isRequired,
  };

  onPageChange = page => {
    const {search, pageSize} = this.props;

    search({
      page: page - 1,
      size: pageSize
    });
  }

  handleCsvExport() {
    downloadFileByPostURL(
      this.props.csv.url,
      {},
      this.props.csv.filename + '.csv'
    );
  }

  getActionsColumn(t) {
    return {
      title: t('table.actions.title'),
      dataIndex: [auctionFields.ID, 'action'],
      render: (txId, record) => {
        return (
          <TableRowActions>
            <Button
              shape="circle"
              icon="select"
              size="small"
              type="primary"
              style={{marginLeft: '8px'}}
              title={t('table.actions.button.view')}
              onClick={() => this.props.goToAuctionDetails(record.id)}
            />
          </TableRowActions>
        );
      },
      fixed: 'right',
      width: 100,
    };
  }

  getColumns() {
    const {t} = this.props;
    const columns = TsAuctionSearchTableColumns.getCommonColumns(t);
    columns.push(this.getActionsColumn(t));
    return columns;
  }

  render() {
    const {
      title,
      auctions,
      isAuctionsLoading,
      totalElements,
      page,
      pageSize,
    } = this.props;

    return (
      <React.Fragment>
        <FormActions>
          <h3 style={{float: 'left'}}>{title}</h3>
          <Button style={{float: 'right'}} onClick={() => this.handleCsvExport()}>
            {this.props.csv.button}
          </Button>
        </FormActions>
        <Spin spinning={isAuctionsLoading}>
          <Table
            scroll={{x: 'auto'}}
            rowKey={auctionFields.ID}
            dataSource={auctions}
            columns={this.getColumns()}
            pagination={{
              onChange: this.onPageChange,
              current: page,
              total: totalElements,
              pageSize,
              defaultCurrent: 1,
            }}
            rowClassName={(rec) => rec.transactionExpired ? 'transaction-expired' : ''}
          />
        </Spin>
      </React.Fragment>
    );
  }
}

export default (TsAuctionSearchTable);
