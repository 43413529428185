import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'antd';
import {invoiceViewBankDetailsFields} from '../../constants/invoice';
import {InvoiceViewItemColComponent} from '../';

class InvoiceViewItemBankDetails extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const {t, data} = this.props;

    return (
      <div className="global-margin-top-16">
        <Row className="global-margin-bottom-10">
          <Col span={8}>
            <InvoiceViewItemColComponent
              itemTitle={t('bankDetails.name')}
              itemValue={data.get(invoiceViewBankDetailsFields.NAME)}
            />
          </Col>
          <Col span={8}>
            <InvoiceViewItemColComponent
              itemTitle={t('bankDetails.registerCode')}
              itemValue={data.get(invoiceViewBankDetailsFields.REGISTER_CODE)}
            />
          </Col>
          <Col span={8}>
            <InvoiceViewItemColComponent
              itemTitle={t('bankDetails.kmkrCode')}
              itemValue={data.get(invoiceViewBankDetailsFields.KMKR_CODE)}
            />
          </Col>
        </Row>
        <Row className="global-margin-bottom-10">
          <Col span={8}>
            <InvoiceViewItemColComponent
              itemTitle={t('bankDetails.accountNumber')}
              itemValue={data.get(invoiceViewBankDetailsFields.ACCOUNT_NUMBER)}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default InvoiceViewItemBankDetails;
