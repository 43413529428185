import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Icon, Spin, Table, Tooltip} from 'antd';
import {TableRowActions} from '../';
import {currentPathSelector, goToAccountGOSPreTradingAgreementDetails} from '../../utils/gotoLink';
import {connect} from 'react-redux';
import {
  translateAgreementTypeClassificator,
  translateBiofuelTypeClassificator,
  translateTsFuelTypeClassificator,
  translateTsStatusClassificator
} from "../../utils/translateHelpers";
import {numberFormatter} from "../../utils/formaters";
import {tradingPlatformAgreementsTable} from "../../constants/tradingPlatformAgreementsTable";

export class TradingPlatformAgreementsTable extends Component {

  static propTypes = {
    t: PropTypes.func.isRequired,

    agreements: PropTypes.array,
    agreementsLoading: PropTypes.bool.isRequired,

    search: PropTypes.func.isRequired,

    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    totalElements: PropTypes.number.isRequired,
  };

  onPageChange = page => {
    const {search} = this.props;
    search(page);
  };

  handleView(id) {
    goToAccountGOSPreTradingAgreementDetails('0000', id);
  }

  getColumns = () => {
    const {t} = this.props;

    function ghgTitle() {
      return <span>
        {t('agreements.table.ghgCapacity')}&nbsp;
        <Tooltip title={t('agreements.table.ghgTooltip')}>
          <Icon type="question-circle-o"/>
        </Tooltip>
      </span>;
    }

    return [
      {
        title: t('agreements.table.id'),
        dataIndex: tradingPlatformAgreementsTable.ID,
        width: "6%",
      },
      {
        title: t('agreements.table.status'),
        dataIndex: tradingPlatformAgreementsTable.status,
        width: "8%",
        render: translateTsStatusClassificator
      },
      {
        title: t('agreements.table.queueNo'),
        dataIndex: tradingPlatformAgreementsTable.queueNo,
        width: "6%",
      },
      {
        title: t('agreements.table.seller'),
        dataIndex: tradingPlatformAgreementsTable.seller,
        width: "12%",
      },
      {
        title: t('agreements.table.buyer'),
        dataIndex: tradingPlatformAgreementsTable.buyer,
        width: "12%",
      },
      {
        title: t('agreements.table.finalConsumptionYear'),
        dataIndex: tradingPlatformAgreementsTable.finalConsumptionYear,
        width: "5%",
      },
      {
        title: t('agreements.table.fuelType'),
        dataIndex: tradingPlatformAgreementsTable.fuelType,
        render: translateTsFuelTypeClassificator
      },
      {
        title: t('agreements.table.fuelGeneration'),
        dataIndex: tradingPlatformAgreementsTable.fuelGeneration,
        render: translateBiofuelTypeClassificator
      },
      {
        title: ghgTitle,
        dataIndex: tradingPlatformAgreementsTable.ghgCapacity,
        render: numberFormatter
      },
      {
        title: t('agreements.table.agreementType'),
        dataIndex: tradingPlatformAgreementsTable.agreementType,
        render: translateAgreementTypeClassificator
      },
      {
        title: t('agreements.table.naturalEnergyAmount'),
        dataIndex: tradingPlatformAgreementsTable.naturalEnergyAmount,
        width: "13%",
        render: (text, record) => {
          if (record[tradingPlatformAgreementsTable.agreementType] === 'ENTIRE') {
            return t('agreements.table.all')
          }
          return record[tradingPlatformAgreementsTable.naturalEnergyAmount];
        }
      },
      {
        title: t('agreements.table.calculatedEnergyAmount'),
        dataIndex: tradingPlatformAgreementsTable.calculatedEnergyAmount,
        width: "13%",
        render: (text, record) => {
          if (record[tradingPlatformAgreementsTable.agreementType] === 'ENTIRE') {
            return t('agreements.table.all')
          }
          return record[tradingPlatformAgreementsTable.calculatedEnergyAmount];
        }
      },
      {
        title: t('agreements.table.fulfilledPercentage'),
        dataIndex: tradingPlatformAgreementsTable.fulfilledPercentage,
        render: (text, record) => {
          if (record[tradingPlatformAgreementsTable.agreementType] === 'ENTIRE') {
            return '-';
          }
          return record[tradingPlatformAgreementsTable.fulfilledPercentage];
        }
      },
      {
        title: t('agreements.table.actions'),
        dataIndex: tradingPlatformAgreementsTable.actions + tradingPlatformAgreementsTable.ID,
        render: (txId, record) => {
          return (
            <TableRowActions>
              <Button
                shape="circle"
                icon="select"
                size="small"
                type="primary"
                title={t('agreements.table.viewBtn')}
                onClick={() => this.handleView(record.id)}
              />
            </TableRowActions>
          );
        },
        fixed: 'right',
        width: 100,
      },
    ];
  };

  render() {
    const {
      agreements,
      agreementsLoading,
      page,
      pageSize,
      totalElements,
    } = this.props;

    return (
      <Spin spinning={agreementsLoading}>
        <Table
          scroll={{x: 'auto'}}
          rowKey={tradingPlatformAgreementsTable.ID}
          className="account-gos-agreements-table"
          dataSource={agreements}
          columns={this.getColumns()}
          pagination={{
            onChange: this.onPageChange,
            current: page,
            total: totalElements,
            pageSize,
            defaultCurrent: 1,
          }}
        />
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  currentPath: currentPathSelector(state),
});

export default connect(mapStateToProps)(TradingPlatformAgreementsTable);
