import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Spin} from 'antd';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {goToTransferring, goToCancelingCertificates} from '../../utils/gotoLink';
import {AccountTable} from '../../components';
import * as actions from '../../actions/actionCreators/accountActionCreators';
import {getUserLegalEntityAccounts} from '../../reducers/userReducer';
import {
  getOverviewList,
  getOverviewListData,
  getGroupedCertificates,
} from '../../reducers/accountOverviewReducer';
import {formAccountOverviewFilter} from '../../constants/forms/formAccountOverviewFilter';

export class AccountSummary extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    fetchGroupedCertificatesAndTransfer: PropTypes.func.isRequired,
    fetchGroupedCertificatesAndCancel: PropTypes.func.isRequired,
    dataList: PropTypesImmutable.list.isRequired,
    accountId: PropTypes.string,
  };

  render() {
    const {
      isLoading,
      t,
      dataList,
      fetchGroupedCertificatesAndTransfer,
      fetchGroupedCertificatesAndCancel,
      accountId,
      userAccounts,
    } = this.props;

    // Add total quantity as the last element of json
    let data = dataList.toJS();
    if (data.length > 0) {
      let totalQuantity = data.map(row => row.quantity).reduce((acc, amount) => acc + amount, 0);
      data.push({quantity: totalQuantity, summary: true});
    }

    return (
      <Spin spinning={isLoading}>
        <AccountTable
          t={t}
          accountId={accountId}
          dataList={data}
          transferCertificates={fetchGroupedCertificatesAndTransfer}
          cancelCertificates={fetchGroupedCertificatesAndCancel}
          userAccounts={userAccounts}
        />
      </Spin>
    );
  }
}

const getLoading = getLoadingSelector(getOverviewList, getGroupedCertificates);

const mapStateToProps = state => ({
  isLoading: getLoading(state),
  dataList: getOverviewListData(state),
  userAccounts: getUserLegalEntityAccounts(state),
  info: state,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchGroupedCertificatesAndTransfer: async rows => {
    await dispatch(
      actions.fetchGroupedCertificates({
        [formAccountOverviewFilter.SELECTED_ROWS]: rows,
        [formAccountOverviewFilter.ACCOUNT_ID]: ownProps.accountId,
      })
    );
    goToTransferring(ownProps.accountId);
  },
  fetchGroupedCertificatesAndCancel: async rows => {
    await dispatch(
      actions.fetchCancelGroupedCertificates({
        [formAccountOverviewFilter.SELECTED_ROWS]: rows,
        [formAccountOverviewFilter.ACCOUNT_ID]: ownProps.accountId,
      })
    );
    goToCancelingCertificates(ownProps.accountId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSummary);
