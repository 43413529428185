import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Alert, Modal, Spin} from 'antd';
import {VDSTransmissionCancellationForm} from "../../forms";
import {
  generateTransmissionCancellationRequestToVDSXML,
  parseResponse
} from "../../utils/vdsHelpers";
import {sendTransmissionCancellationRequestToVDS} from "../../actions/actionHelpers/endpoints";

export class VDSTransmissionCancellationModal extends Component {
  static propTypes = {
    show: PropTypes.bool,
    hideModal: PropTypes.func.isRequired,
    data: PropTypes.any,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: '',
      request: '',
      response: null
    };
  }

  hideModal = () => {
    const {hideModal} = this.props;
    this.clearMessages();
    hideModal();
  };

  clearMessages = () => {
    this.setState({error: '', request: '', response: null});
  };

  send = async (values) => {
    this.clearMessages();
    const xml = generateTransmissionCancellationRequestToVDSXML(values);
    this.setState({loading: true, request: xml, response: null});
    try {
      const responseObject = await sendTransmissionCancellationRequestToVDS({xml});
      const response = parseResponse(responseObject);
      this.setState({
        loading: false,
        error: response.error ? 'Viga andmete saatmisel VDS-i' : '',
        response
      });
    } catch (error) {
      console.error(error);
      const response = {error: error.message};
      this.setState({loading: false, error: 'Viga andmete saatmisel VDS-i', response});
    }
  };

  render() {
    const {show, data} = this.props;
    const {loading, error, response, request} = this.state;

    return show ? (
      <Modal onCancel={this.hideModal}
             visible
             footer={false}
             title={'Ostu-müügitehingu (ülekande) täielik tühistamine'}
             width="80vw"
      >
        <Spin spinning={loading}>
          <VDSTransmissionCancellationForm onSubmit={this.send}
                                           request={request}
                                           response={response}
                                           onReset={this.clearMessages}
                                           data={data}>
            {!error && !!response &&
              <Alert message={'Andmed saadeti VDS-ile'} type="success" closable/>}
            {!!error && <Alert message={error} type="error" closable/>}
          </VDSTransmissionCancellationForm>
        </Spin>
      </Modal>
    ) : null;
  }
}

export default VDSTransmissionCancellationModal;
