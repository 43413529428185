import {
  createValidation,
  validatorRequired,
  validatorZip,
} from '../../utils/formValidators';

import {fromRegisterEntityContactFields} from '../../constants/formRegister';

export default createValidation([
  {
    name: fromRegisterEntityContactFields.ADDRESS,
    validators: validatorRequired(),
  },
  {
    name: fromRegisterEntityContactFields.CITY,
    validators: validatorRequired(),
  },
  {
    name: fromRegisterEntityContactFields.COUNTRY,
    validators: validatorRequired(),
  },
  {
    name: fromRegisterEntityContactFields.ZIP_CODE,
    validators: [validatorRequired(), validatorZip()],
  },
]);
