import React, {Component} from 'react';
import {reduxForm, Field, SubmissionError} from 'redux-form/immutable';
import PropTypes from 'prop-types';
import {TextField, ClassificatorField} from '../../atoms';
import {FormActions} from '../../components';
import {connect} from 'react-redux';
import {isEmpty, isString} from 'lodash';
import {
  loadingUnitFilterFields,
  LOADING_UNIT_FILTER_FORM,
} from '../../constants/loadingUnit';
import {Collapse, Row, Col, Button, message} from 'antd';
import {parseNumber} from '../../utils/formHelpers';
import {searchLoadingUnits} from '../../actions/actionCreators/loadingUnitActionCreator';

const {Panel} = Collapse;

export class LoadingUnitFilter extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    searchLoadingUnits: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    this.props.searchLoadingUnits();
  }

  onClear = () => {
    const {submit, reset} = this.props;

    reset();
    submit();
  };

  render() {
    const {t, handleSubmit, isAdmin} = this.props;
    return (
      <div>
        <div className="search-form-container">
          <Collapse>
            <Panel key="filters" header={t('filter.title')}>
              <form onSubmit={handleSubmit}>
                <Row gutter={32}>
                  <Col span={8}>
                    <Field
                      label={t('filter.name')}
                      name={loadingUnitFilterFields.NAME}
                      component={TextField}
                      hasFeedback={false}
                    />
                  </Col>

                  {isAdmin &&
                    <Col span={8}>
                      <Field
                        label={t('filter.registerCode')}
                        name={loadingUnitFilterFields.REGISTER_CODE}
                        component={TextField}
                        parse={parseNumber}
                        hasFeedback={false}
                      />
                    </Col>
                  }

                  {isAdmin &&
                    <Col span={8}>
                      <Field
                        label={t('filter.status')}
                        name={loadingUnitFilterFields.STATUS}
                        component={ClassificatorField}
                        type={ClassificatorField.types.loadingUnitStatus}
                        hasFeedback={false}
                      />
                    </Col>
                  }

                  {!isAdmin &&
                    <Col span={8}>
                      <Field
                        label={t('filter.marker')}
                        name={loadingUnitFilterFields.MODEL}
                        component={TextField}
                        hasFeedback={false}
                      />
                    </Col>
                  }
                </Row>
                <FormActions>
                  <Button onClick={this.onClear}>{t('filter.clearBtn')}</Button>
                  <Button type="primary" htmlType="submit">
                    {t('filter.submitBtn')}
                  </Button>
                </FormActions>
              </form>
            </Panel>
          </Collapse>
        </div>
      </div>
    );
  }
}

const onSubmit = (values, dispatch, ownProps) => {
  if (values.every(value => isEmpty(value))) {
    throw new SubmissionError(ownProps.t('shouldFillAnyFilter'));
  }
  dispatch(searchLoadingUnits());
};

const onSubmitFail = errors => {
  if (isString(errors)) {
    message.error(errors);
  }
};

const mapDispatchToProps = {
  searchLoadingUnits
};

export default connect(null, mapDispatchToProps)(
  reduxForm({
    form: LOADING_UNIT_FILTER_FORM,
    onSubmit,
    onSubmitFail,
  })(LoadingUnitFilter)
);
