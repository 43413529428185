import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {translateMonthClassificator} from '../../utils/translateHelpers';
import {gridReportFields} from '../../constants/gridReport';
import {Collapse, Divider} from 'antd';
import {downloadFileByPostURL} from '../../utils/files';

const {Panel} = Collapse;

class GridReportView extends Component {
  static propTypes = {
    dataSource: PropTypes.object,
    t: PropTypes.func.isRequired,
    selectedYear: PropTypes.number.isRequired,
  };

  handleDownload = (e, id, name) => {
    e.preventDefault();
    downloadFileByPostURL(`/grid/report/${id}`, {}, `${name}.xls`, 'get');
  };

  render() {
    const {dataSource} = this.props;
    const panels = [];

    for (let i = 0; i < 12; i++) {
      const key = (i + 1).toString();
      const dataItem = dataSource[key];

      if (dataItem) {
        const fileItems = dataItem.map((fileItem, index) => (
          <React.Fragment key={index}>
            {index !== 0 && <Divider/>}
            <a
              href="gridReportDownload"
              onClick={e =>
                this.handleDownload(
                  e,
                  fileItem[gridReportFields.ID],
                  fileItem[gridReportFields.NAME]
                )
              }
            >
              {fileItem[gridReportFields.NAME]}
            </a>
          </React.Fragment>
        ));

        panels.push(
          <Panel header={translateMonthClassificator(key)} key={key}>
            {fileItems}
          </Panel>
        );
      }
    }

    return <Collapse>{panels}</Collapse>;
  }
}

export default GridReportView;
