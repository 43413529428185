import React, {Component} from 'react';
import PropTypesImmutable from 'react-immutable-proptypes';
import {reduxForm, Field, Fields, getFormValues} from 'redux-form/immutable';
import PropTypes from 'prop-types';
import {getLoadingSelector, getEntityById} from '../../utils/asyncHelpers';
import {DateRangeField, SelectField, ClassificatorField} from '../../atoms';
import {FormActions} from '../../components';
import {connect} from 'react-redux';
import {FORM_CONSUMER_GO_SEARCH} from '../../constants/formKeys';
import {formConsumerGoSearchFields} from '../../constants/forms/formConsumerGoSearch';
import {
  getMeteringPoints,
  getMeteringPointsData,
} from '../../reducers/consumerGoSearchReducer';
import {Collapse, Row, Col, Button, Spin} from 'antd';
import {downloadFileByPostURL} from '../../utils/files';
import {meteringPointFields} from '../../constants/meteringPoint';
import {getPhysicalPersonId} from '../../reducers/userReducer';
import moment from 'moment';
import {FORMAT_DEFAULT_DATE} from '../../utils/dates';
import {measurement as meteringPointSchema} from '../../schemas';
import {links} from '../../utils/gotoLink';
import * as actions from '../../actions/actionCreators/consumerGoSearchActionCreators';
import MeteringPoint from './MeteringPoint';

const {Panel} = Collapse;

export class ConsumerGoFilter extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    selectedPoint: PropTypesImmutable.map,
    filterValues: PropTypesImmutable.map,
    meteringPoints: PropTypesImmutable.list.isRequired,
    searchConsumerGo: PropTypes.func.isRequired,
    isLoadingPoints: PropTypes.bool.isRequired,
    fetchConsumerPoints: PropTypes.func.isRequired,
    consumerId: PropTypes.number.isRequired,
  };

  componentDidMount = () => {
    const {searchConsumerGo, fetchConsumerPoints, consumerId} = this.props;

    searchConsumerGo();
    fetchConsumerPoints(consumerId);
  };

  onClear = () => {
    const {submit, reset} = this.props;

    reset();
    submit();
  };

  handleExportData = () => {
    const {filterValues} = this.props;

    downloadFileByPostURL(
      links.consumerReportExportCsv,
      filterValues ? filterValues.toJS() : {},
      'gos.csv'
    );
  };

  render() {
    const {
      t,
      handleSubmit,
      isLoadingPoints,
      meteringPoints,
      selectedPoint,
    } = this.props;

    return (
      <div className="global-margin-top-16">
        <Collapse defaultActiveKey={['filters']}>
          <Panel key="filters" header={t('filter.panelTitle')}>
            <form onSubmit={handleSubmit}>
              <Row gutter={32}>
                <Col span={8}>
                  <Fields
                    label={t('filter.dateRange')}
                    names={[
                      formConsumerGoSearchFields.START_DATE,
                      formConsumerGoSearchFields.END_DATE,
                    ]}
                    component={DateRangeField}
                    allowClear={false}
                    hasFeedback={false}
                    dateFormat={FORMAT_DEFAULT_DATE}
                  />
                </Col>
                <Col span={8}>
                  <Spin spinning={isLoadingPoints}>
                    <Field
                      label={t('filter.meteringPoint')}
                      name={formConsumerGoSearchFields.METERING_POINT_ID}
                      component={SelectField}
                      options={meteringPoints.toJS()}
                      valueKey={meteringPointFields.ID}
                      labelKey={meteringPointFields.EICZ_CODE}
                      hasFeedback={false}
                    />
                  </Spin>
                </Col>
                <Col span={8}>
                  <Field
                    label={t('filter.consumptionUsage')}
                    name={formConsumerGoSearchFields.CONSUMPTION_USAGE_ID}
                    component={ClassificatorField}
                    type={ClassificatorField.types.consumptionUsage}
                    hasFeedback={false}
                  />
                </Col>
              </Row>
              <FormActions>
                <Button onClick={this.onClear}>{t('filter.clearBtn')}</Button>
                <Button type="primary" htmlType="submit">
                  {t('filter.submitBtn')}
                </Button>
              </FormActions>
            </form>
          </Panel>
        </Collapse>
        <FormActions>
          <Button onClick={this.handleExportData}>
            {t('filter.exportCsvBtn')}
          </Button>
        </FormActions>
        {selectedPoint && (
          <MeteringPoint meteringPoint={selectedPoint.toJS()} t={t}/>
        )}
      </div>
    );
  }
}

const onSubmit = (values, dispatch) => dispatch(actions.searchConsumerGo());

const getValues = getFormValues(FORM_CONSUMER_GO_SEARCH);

const getIsLoadingPoints = getLoadingSelector(getMeteringPoints);

const mapStateToProps = state => {
  const filterValues = getValues(state);
  const initialValues = {
    [formConsumerGoSearchFields.START_DATE]: moment()
      .startOf('year')
      .format(FORMAT_DEFAULT_DATE),
    [formConsumerGoSearchFields.END_DATE]: moment().format(FORMAT_DEFAULT_DATE),
  };

  return {
    filterValues,
    initialValues,
    isLoadingPoints: getIsLoadingPoints(state),
    meteringPoints: getMeteringPointsData(state),
    consumerId: getPhysicalPersonId(state),
    selectedPoint: getEntityById(
      state,
      meteringPointSchema,
      filterValues
        ? filterValues.get(formConsumerGoSearchFields.METERING_POINT_ID, 0)
        : 0
    ),
  };
};

const mapDispatchToProps = {
  searchConsumerGo: actions.searchConsumerGo,
  fetchConsumerPoints: actions.fetchConsumerPoints,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_CONSUMER_GO_SEARCH,
    onSubmit,
  })(ConsumerGoFilter)
);
