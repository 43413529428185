import {handleActions} from 'redux-actions';
import {combineReducers} from 'redux-immutable';
import {
  createAsyncReducer,
  initialAsyncState,
  listInitialAsyncState,
  toSuccess,
} from '../utils/asyncHelpers';
import {
  ENTITY_CONTACTS_FETCH,
  ENTITY_CONTACTS_ADD,
  ENTITY_CONTACTS_UPDATE,
  ENTITY_CONTACTS_DELETE,
} from '../constants/registerContactsActionTypes';

const legalEntityContacts = handleActions(
  {
    ...createAsyncReducer(ENTITY_CONTACTS_FETCH),
    [toSuccess(ENTITY_CONTACTS_ADD)]: (state, {payload}) =>
      state.set('data', state.get('data').concat([payload.result])),
    [toSuccess(ENTITY_CONTACTS_DELETE)]: (state, {payload}) =>
      state.set('data', state.get('data').filter(id => id !== payload)),
  },
  listInitialAsyncState
);
const registerContactsReducer = combineReducers({
  legalEntityContacts,
  legalEntityContactsAdd: handleActions(
    createAsyncReducer(ENTITY_CONTACTS_ADD),
    initialAsyncState
  ),
  legalEntityContactsUpdate: handleActions(
    createAsyncReducer(ENTITY_CONTACTS_UPDATE),
    initialAsyncState
  ),
  legalEntityContactsDelete: handleActions(
    createAsyncReducer(ENTITY_CONTACTS_DELETE),
    initialAsyncState
  ),
});

export const getEntityContactsList = state =>
  state.getIn(['registerContacts', 'legalEntityContacts']);

export const getEntityContactsAddState = state =>
  state.getIn(['registerContacts', 'legalEntityContactsAdd']);

export const getEntityContactsUpdateState = state =>
  state.getIn(['registerContacts', 'legalEntityContactsUpdate']);

export const getEntityContactsDeleteState = state =>
  state.getIn(['registerContacts', 'legalEntityContactsDelete']);

export default registerContactsReducer;
