import {schema} from 'normalizr';
import {meteringPointFields} from '../constants/meteringPoint';
import avpConsumerScheme from './avpConsumer';

const avpMeteringPoint = new schema.Entity(
  'avpMeteringPoint',
  {consumer: avpConsumerScheme},
  {idAttribute: meteringPointFields.EICZ_CODE}
);

export default avpMeteringPoint;
