import React, {Component} from 'react';
import {reduxForm, Field} from 'redux-form/immutable';
import {TextAreaField} from '../../atoms';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {Row, Col, Button, Spin, notification} from 'antd';
import {Map} from 'immutable';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormActions} from '../../components';
import {FORM_CLASSIFIERS_INVOICE_CONFIRMATION_EDIT} from '../../constants/formKeys';
import {
  getClassifiersInvoiceConfirmationChange,
  getClassifiersInvoiceConfirmationItemDataEn,
  getClassifiersInvoiceConfirmationItemDataEt,
  getClassifiersInvoiceConfirmationItem,
} from '../../reducers/classifiersReducer';
import * as actions from '../../actions/actionCreators/classifiersActionCreators';
import {classifierInvoiceConfirmationEditFields} from '../../constants/classifier';
import {goToClassifiers} from '../../utils/gotoLink';

export class ClassifierInvoiceConfirmationEdit extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    fetchClassifierInvoiceConfirmation: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  componentDidMount = () => {
    this.props.fetchClassifierInvoiceConfirmation();
  };

  render() {
    const {t, handleSubmit, isLoading} = this.props;

    return (
      <Spin spinning={isLoading}>
        <h1>{t('invoiceConfirmationTitle')}</h1>
        <form onSubmit={handleSubmit}>
          <Row gutter={32}>
            <Col span={12}>
              <Field
                name={classifierInvoiceConfirmationEditFields.EN}
                label={t('fieldEn')}
                component={TextAreaField}
                rows={12}
              />
            </Col>
            <Col span={12}>
              <Field
                name={classifierInvoiceConfirmationEditFields.ET}
                label={t('fieldEt')}
                component={TextAreaField}
                rows={12}
              />
            </Col>
          </Row>
          <FormActions>
            <Button onClick={goToClassifiers}>{t('buttonBack')}</Button>
            <Button htmlType="submit" type="primary">
              {t('buttonSave')}
            </Button>
          </FormActions>
        </form>
      </Spin>
    );
  }
}

const onSubmit = async (values, dispatch, ownProps) => {
  await dispatch(actions.changeClassifierInvoiceConfirmation(values.toJS()));

  notification.info({
    message: ownProps.t('completeTitle'),
    description: ownProps.t('completeBody'),
  });
};

const getIsLoading = getLoadingSelector(
  getClassifiersInvoiceConfirmationChange,
  getClassifiersInvoiceConfirmationItem
);

const mapStateToProps = state => ({
  initialValues: Map({
    [classifierInvoiceConfirmationEditFields.ET]: getClassifiersInvoiceConfirmationItemDataEt(
      state
    ),
    [classifierInvoiceConfirmationEditFields.EN]: getClassifiersInvoiceConfirmationItemDataEn(
      state
    ),
  }),
  isLoading: getIsLoading(state),
});

const mapDispatchToProps = {
  fetchClassifierInvoiceConfirmation:
  actions.fetchClassifierInvoiceConfirmation,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_CLASSIFIERS_INVOICE_CONFIRMATION_EDIT,
    onSubmit,
    enableReinitialize: true,
  })(ClassifierInvoiceConfirmationEdit)
);
