import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {accountGOSTsAssignmentFields} from '../../constants/accountGOS';
import {Button, Modal, Spin, Table} from 'antd';
import {TableRowActions} from '../index';
import {currentPathSelector, goToAccountGOSAuctionDetails} from '../../utils/gotoLink';
import {connect} from 'react-redux';
import './accountGOSTsAssignmentsSearchTable.scss';
import {
  translateTradingMethodClassificator,
  translateTsAssignmentStateClassificator,
  translateTsAssignmentTypeClassificator
} from "../../utils/translateHelpers";
import {
  TsAgreementConfirm,
  TsAgreementTransaction,
  TsAgreementWithdrawAssignment,
  TsAuctionResultConfirm
} from "../../forms";
import {
  getAgreementTransactionConfirmModal,
  getAgreementTransactionViewModal,
  getAgreementWithdrawConfirmModal,
  getAgreementWithdrawViewModal,
  getAuctionResultConfirmModal,
  getAuctionResultViewModal,
  getTsAgreementConfirmModal,
  getTsAgreementConfirmViewModal,
  hideAgreementTransactionConfirmModal,
  hideAgreementTransactionViewModal,
  hideAgreementWithdrawConfirmModal,
  hideAgreementWithdrawViewModal,
  hideAuctionResultConfirmModal,
  hideAuctionResultViewModal,
  hideTsAgreementConfirmModal,
  hideTsAgreementConfirmViewModal,
  showAgreementTransactionConfirmModal,
  showAgreementTransactionViewModal,
  showAgreementWithdrawConfirmModal,
  showAgreementWithdrawViewModal,
  showAuctionResultConfirmModal,
  showAuctionResultViewModal,
  showTsAgreementConfirmModal,
  showTsAgreementConfirmViewModal
} from "../../actions/actionCreators/modalsActionCreators";
import {
  searchAccountGOSPurchasingAgreements
} from "../../actions/actionCreators/accountGOSAgreementsActionCreators";
import {
  fetchAccountGOSTsAssignmentCount
} from "../../actions/actionCreators/accountGOSTsAssignmentsActionCreators";
import {
  getAccountGOSPurchasingAgreementsListData
} from "../../reducers/accountGOSPurchasingAgreementsListReducer";

export class AccountGOSTsAssignmentsSearchTable extends Component {
  static propTypes = {
    tsAssignments: PropTypes.array,
    totalElements: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    pageSize: PropTypes.number.isRequired,
    search: PropTypes.func.isRequired,
    stateConfirmModal: PropTypes.bool.isRequired,
    showConfirmModal: PropTypes.func.isRequired,
    stateConfirmViewModal: PropTypes.bool.isRequired,
    showConfirmViewModal: PropTypes.func.isRequired,
    withdrawViewModal: PropTypes.bool.isRequired,
    showWithdrawViewModal: PropTypes.func.isRequired,
    showWithdrawConfirmModal: PropTypes.func.isRequired,
    stateTransactionConfirmModal: PropTypes.bool.isRequired,
    showTransactionConfirmModal: PropTypes.func.isRequired,
    stateTransactionViewModal: PropTypes.bool.isRequired,
    showTransactionViewModal: PropTypes.func.isRequired,
    registerCode: PropTypes.string.isRequired,
    searchAgreements: PropTypes.func.isRequired,
    agreementsList: PropTypes.array.isRequired,
  };

  state = {
    selectedAssignment: null
  }

  onPageChange = page => {
    const {search} = this.props;
    search(page);
  };

  setActiveTsState = (assignment) => {
    this.setState({
      selectedAssignment: assignment,
    });
  }

  handleConfirm(assignment) {
    this.openAssignmentModal(assignment, 'confirm');
  }

  handleView(assignment) {
    this.openAssignmentModal(assignment, 'view');
  }

  goToAuctionDetails = (id) => {
    goToAccountGOSAuctionDetails(this.props.registerCode, id);
  }

  openAssignmentModal(assignment, modalType) {
    const {
      showConfirmModal,
      showWithdrawConfirmModal,
      showTransactionConfirmModal,
      showTransactionViewModal,
      showConfirmViewModal,
      showWithdrawViewModal,
      showAuctionResultConfirmModal,
      showAuctionResultViewModal
    } = this.props;

    this.setActiveTsState(assignment);

    const modalFunction = {
      PRE_TRADING_AGREEMENT: {
        AGREEMENT_REGISTRATION: {
          confirm: showConfirmModal,
          view: showConfirmViewModal
        },
        AGREEMENT_WITHDRAWAL: {
          confirm: showWithdrawConfirmModal,
          view: showWithdrawViewModal
        },
        TRANSACTION_CONFIRMATION: {
          confirm: showTransactionConfirmModal,
          view: showTransactionViewModal,
        }
      },
      AUCTION: {
        TRANSACTION_CONFIRMATION: {
          confirm: showAuctionResultConfirmModal,
          view: showAuctionResultViewModal,
        }
      }
    };

    const assignmentType = assignment[accountGOSTsAssignmentFields.type];
    const assignmentTradingMethod = assignment[accountGOSTsAssignmentFields.tradingMethod];

    const modalAction = modalFunction[assignmentTradingMethod]?.[assignmentType]?.[modalType];

    if (modalAction) {
      modalAction();
    }
  }

  getColumns = () => {
    const {t} = this.props;
    return [
      {
        title: t('assignments.table.state'),
        dataIndex: accountGOSTsAssignmentFields.state,
        width: "20%",
        render: translateTsAssignmentStateClassificator
      },
      {
        title: t('assignments.table.type'),
        dataIndex: accountGOSTsAssignmentFields.type,
        render: translateTsAssignmentTypeClassificator,
        width: "20%",
      },
      {
        title: t('assignments.table.tradingMethod'),
        dataIndex: accountGOSTsAssignmentFields.tradingMethod,
        render: translateTradingMethodClassificator,
        width: "20%",
      },
      {
        title: t('assignments.table.counterparty'),
        dataIndex: accountGOSTsAssignmentFields.counterparty,
        width: "20%",
      },
      {
        title: t('assignments.table.dueDate'),
        dataIndex: accountGOSTsAssignmentFields.dueDate,
        width: "20%",
      },
      {
        title: t('assignments.table.actions'),
        dataIndex: accountGOSTsAssignmentFields.ID,
        render: (txId, record) => {
          return (
            <TableRowActions>
              <Button
                shape="circle"
                icon="select"
                size="small"
                type="primary"
                title={t('assignments.table.viewBtn')}
                onClick={() => this.handleView(record)}
              />
              {record.state === 'CREATED' &&
                <Button
                  shape="circle"
                  icon="exclamation-circle-o"
                  size="small"
                  type="primary"
                  title={t('assignments.table.confirmBtn')}
                  onClick={() => this.handleConfirm(record)}
                />}
            </TableRowActions>
          );
        },
        fixed: 'right',
        width: 100,
      },
    ];
  };

  render() {
    const {
      t,
      search,
      searchAgreements,
      fetchAccountGOSTsAssignmentCount,
      registerCode,
      stateConfirmModal,
      hideConfirmModal,
      stateConfirmViewModal,
      hideConfirmViewModal,
      withdrawViewModal,
      hideWithdrawViewModal,
      withdrawConfirmModal,
      hideWithdrawConfirmModal,
      stateTransactionConfirmModal,
      hideTransactionConfirmModal,
      stateTransactionViewModal,
      hideTransactionViewModal,
      auctionResultViewModal,
      auctionResultConfirmModal,
      hideAuctionResultViewModal,
      hideAuctionResultConfirmModal,
      totalElements,
      tsAssignments,
      loading,
      page,
      pageSize,
      agreementsList
    } = this.props;

    const onAssignmentCompleted = () => {
      search(0);
      if (agreementsList.length > 0) {
        searchAgreements(0);
      }
      fetchAccountGOSTsAssignmentCount();
    }

    return (
      <Spin spinning={loading}>
        <Table
          scroll={{x: 'auto'}}
          rowKey={accountGOSTsAssignmentFields.ID}
          className="account-gos-agreements-table"
          dataSource={tsAssignments}
          columns={this.getColumns()}
          pagination={{
            onChange: this.onPageChange,
            current: page,
            total: totalElements,
            pageSize,
            defaultCurrent: 1,
          }}
          rowSelection={this.rowSelection}
        />
        {stateConfirmModal && (
          <Modal
            onCancel={hideConfirmModal}
            visible
            footer={false}
            title={t("assignments.confirm.title")}
            witdth="600px"
          >
            <TsAgreementConfirm
              t={t}
              assignment={this.state.selectedAssignment}
              hideConfirmModal={hideConfirmModal}
              registerCode={registerCode}
              isConfirmable={true}
              onSuccess={onAssignmentCompleted}
            />
          </Modal>
        )}
        {stateConfirmViewModal && (
          <Modal
            onCancel={hideConfirmViewModal}
            visible
            footer={false}
            title={t("assignments.confirm.title")}
            witdth="600px"
          >
            <TsAgreementConfirm
              t={t}
              assignment={this.state.selectedAssignment}
              hideConfirmModal={hideConfirmViewModal}
              registerCode={registerCode}
              isConfirmable={false}
            />
          </Modal>
        )}
        {
          withdrawViewModal && (
            <Modal
              onCancel={hideWithdrawViewModal}
              visible
              footer={false}
              title={t("assignments.withdraw.title")}
              witdth="600px"
            >
              <TsAgreementWithdrawAssignment
                t={t}
                assignment={this.state.selectedAssignment}
                registerCode={registerCode}
                hideModal={hideWithdrawViewModal}
                isConfirmable={false}/>
            </Modal>
          )
        }
        {
          withdrawConfirmModal && (
            <Modal
              onCancel={hideWithdrawConfirmModal}
              visible
              footer={false}
              title={t("assignments.withdraw.title")}
              witdth="600px"
            >
              <TsAgreementWithdrawAssignment
                t={t}
                assignment={this.state.selectedAssignment}
                registerCode={registerCode}
                hideModal={hideWithdrawConfirmModal}
                isConfirmable={true}
                onSuccess={onAssignmentCompleted}/>
            </Modal>
          )
        }
        {
          stateTransactionViewModal && (
            <Modal
              onCancel={hideTransactionViewModal}
              visible
              footer={false}
              title={t("assignments.transaction.title")}
              witdth="600px"
            >
              <TsAgreementTransaction
                t={t}
                assignment={this.state.selectedAssignment}
                registerCode={registerCode}
                hideModal={hideTransactionViewModal}
                isConfirmable={false}
                onSuccess={onAssignmentCompleted}/>
            </Modal>
          )
        }
        {
          stateTransactionConfirmModal && (
            <Modal
              onCancel={hideTransactionConfirmModal}
              visible
              footer={false}
              title={t("assignments.transaction.title")}
              witdth="600px"
            >
              <TsAgreementTransaction
                t={t}
                assignment={this.state.selectedAssignment}
                registerCode={registerCode}
                hideModal={hideTransactionConfirmModal}
                isConfirmable={true}
                onSuccess={onAssignmentCompleted}/>
            </Modal>
          )
        }
        {
          auctionResultViewModal && (
            <Modal
              onCancel={hideAuctionResultViewModal}
              visible
              footer={false}
              title={t("assignments.resultConfirm.title")}
              width={400}
            >
              <TsAuctionResultConfirm
                t={t}
                assignment={this.state.selectedAssignment}
                hideModal={hideAuctionResultViewModal}
                onSuccess={onAssignmentCompleted}
                goToAuctionDetails={this.goToAuctionDetails}
                readonly={true}/>
            </Modal>
          )
        }
        {
          auctionResultConfirmModal && (
            <Modal
              onCancel={hideAuctionResultConfirmModal}
              visible
              footer={false}
              title={t("assignments.resultConfirm.title")}
              width={400}
            >
              <TsAuctionResultConfirm
                t={t}
                assignment={this.state.selectedAssignment}
                hideModal={hideAuctionResultConfirmModal}
                onSuccess={onAssignmentCompleted}
                goToAuctionDetails={this.goToAuctionDetails}
                readonly={false}/>
            </Modal>
          )
        }
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  currentPath: currentPathSelector(state),

  stateConfirmModal: getTsAgreementConfirmModal(state),
  stateConfirmViewModal: getTsAgreementConfirmViewModal(state),

  withdrawViewModal: getAgreementWithdrawViewModal(state),
  withdrawConfirmModal: getAgreementWithdrawConfirmModal(state),

  stateTransactionConfirmModal: getAgreementTransactionConfirmModal(state),
  stateTransactionViewModal: getAgreementTransactionViewModal(state),

  auctionResultViewModal: getAuctionResultViewModal(state),
  auctionResultConfirmModal: getAuctionResultConfirmModal(state),

  agreementsList: getAccountGOSPurchasingAgreementsListData(state).toJS(),
});

const mapDispatchToProps = {
  showConfirmModal: showTsAgreementConfirmModal,
  hideConfirmModal: hideTsAgreementConfirmModal,

  showConfirmViewModal: showTsAgreementConfirmViewModal,
  hideConfirmViewModal: hideTsAgreementConfirmViewModal,

  showWithdrawViewModal: showAgreementWithdrawViewModal,
  hideWithdrawViewModal: hideAgreementWithdrawViewModal,

  showWithdrawConfirmModal: showAgreementWithdrawConfirmModal,
  hideWithdrawConfirmModal: hideAgreementWithdrawConfirmModal,

  showTransactionConfirmModal: showAgreementTransactionConfirmModal,
  hideTransactionConfirmModal: hideAgreementTransactionConfirmModal,

  showTransactionViewModal: showAgreementTransactionViewModal,
  hideTransactionViewModal: hideAgreementTransactionViewModal,

  showAuctionResultConfirmModal,
  hideAuctionResultConfirmModal,

  showAuctionResultViewModal,
  hideAuctionResultViewModal,

  searchAgreements: searchAccountGOSPurchasingAgreements,
  fetchAccountGOSTsAssignmentCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountGOSTsAssignmentsSearchTable);
