import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {meteringPointFields} from '../../constants/meteringPoint';
import {legalEntityFields} from '../../constants/legalEntity';
import {TableRowActions} from '../';
import {Table, Button, Spin, Modal} from 'antd';
import {MeasuringUpload} from '../../containers';
import {goToMeteringPointView} from '../../utils/gotoLink';
import {
  translateMeteringPointDataSourceClassificator,
  translateMeteringPointStatusClassificator,
  translateMeteringPointTypeClassificator,
} from '../../utils/translateHelpers';

export class MeteringPointSearchTable extends Component {
  static propTypes = {
    consumptionPoints: PropTypes.array,
    totalElements: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
    searchConsumptionPoints: PropTypes.func.isRequired,
    showImportModal: PropTypes.func.isRequired,
    hideImportModal: PropTypes.func.isRequired,
    stateImportModal: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    pageSize: PropTypes.number.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };

  onPageChange = page => {
    const {searchConsumptionPoints} = this.props;

    searchConsumptionPoints(page);
  };

  getColumns = () => {
    const {t, showImportModal, isAdmin} = this.props;
    const columns = [
      {
        title: t('table.type'),
        dataIndex: meteringPointFields.METERING_POINT_TYPE,
        render: translateMeteringPointTypeClassificator,
      },
      {
        title: t('table.dataSource'),
        dataIndex: meteringPointFields.DATA_SOURCE,
        render: translateMeteringPointDataSourceClassificator,
      },
      {
        title: t('table.iecz'),
        dataIndex: meteringPointFields.EICZ_CODE,
      },
      {
        title: t('table.consumerName'),
        dataIndex: `${meteringPointFields.CONSUMER}.${
          meteringPointFields.NAME
        }`,
      },
    ];

    if (isAdmin) {
      columns.push({
        title: t('table.ownerName'),
        dataIndex: `${meteringPointFields.LEGAL_ENTITY}.${
          legalEntityFields.NAME
        }`,
      });
    }

    columns.push(
      {
        title: t('table.status'),
        dataIndex: meteringPointFields.STATUS,
        render: translateMeteringPointStatusClassificator,
      },
      {
        title: t('table.actions'),
        dataIndex: meteringPointFields.ID,
        render: (value, values) => {
          const isVirtual =
            values[meteringPointFields.DATA_SOURCE] === 'VIRTUAL';
          return (
            <TableRowActions>
              {isVirtual &&
                !isAdmin && (
                  <span>
                    <Button
                      shape="circle"
                      icon="upload"
                      size="small"
                      type="primary"
                      title={t('importButton')}
                      onClick={() => {
                        this.selectedIdToImport = value;
                        showImportModal();
                      }}
                    />
                    <span className="ant-divider"/>
                  </span>
                )}
              <Button
                shape="circle"
                icon="edit"
                size="small"
                type="primary"
                title={t('viewButton')}
                onClick={() => goToMeteringPointView(value)}
              />
            </TableRowActions>
          );
        },
      }
    );
    return columns;
  };

  selectedIdToImport = null;

  render() {
    const {
      consumptionPoints,
      totalElements,
      loading,
      page,
      pageSize,
      t,
      stateImportModal,
      hideImportModal,
    } = this.props;

    return (
      <Spin spinning={loading}>
        <Table
          rowKey={meteringPointFields.ID}
          dataSource={consumptionPoints}
          columns={this.getColumns()}
          pagination={{
            onChange: this.onPageChange,
            current: page,
            total: totalElements,
            pageSize,
            defaultCurrent: 1,
          }}
        />
        {stateImportModal && (
          <Modal
            onCancel={hideImportModal}
            visible={stateImportModal}
            footer={false}
            title={t('uploadImportTitle')}
            width="600px"
          >
            <MeasuringUpload
              onComplete={hideImportModal}
              meteringPointId={this.selectedIdToImport}
            />
          </Modal>
        )}
      </Spin>
    );
  }
}

export default MeteringPointSearchTable;
