import React from 'react';
import PropTypes from 'prop-types';
import {Row} from 'antd';
import {transactionGOSSetFields} from '../../constants/transactionGOS';

class TransactionGOSSetInformation extends React.Component {
  static propTypes = {
    info: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const {t, info, transactionType} = this.props;

    const isTransfer =
      transactionType === 'INCOMING' || transactionType === 'OUTGOING';
    const gosSetIndex = info[transactionGOSSetFields.GOS_SET_INDEX];

    return (
      <React.Fragment>
        <Row className="global-margin-bottom-10">
          <Row>
            <b>{t('gosSetInfo.title', {gosSetIndex})}: </b>
          </Row>
          <Row>
            {t('gosSetInfo.startGosId')}:{' '}
            {info[transactionGOSSetFields.GOS_SET_START_ID]}
          </Row>
          <Row>
            {t('gosSetInfo.endGosId')}:{' '}
            {info[transactionGOSSetFields.GOS_SET_END_ID]}
          </Row>
          <Row>
            {t('gosSetInfo.gosQuantityMj')}:{' '}
            {info[transactionGOSSetFields.GOS_VALUE_MJ_IN_SET]}
          </Row>
          <Row>
            {t('gosSetInfo.gosNaturalQuantityMj')}:{' '}
            {info[transactionGOSSetFields.GOS_NATURAL_VALUE_MJ_IN_SET]}
          </Row>
          {isTransfer && (
            <Row>
              {t('gosSetInfo.cost')}: {info[transactionGOSSetFields.GOS_COST]}
            </Row>
          )}
          {transactionType === 'LOCKING' && (
            <Row>
              {t('gosSetInfo.lockingPeriod')}:{' '}
              {info[transactionGOSSetFields.GOS_LOCKING_PERIOD_START]}
              {' - '}
              {info[transactionGOSSetFields.GOS_LOCKING_PERIOD_END]}
            </Row>
          )}
          <Row>
            {t('gosSetInfo.issuingPeriod')}:{' '}
            {info[transactionGOSSetFields.GOS_ISSUING_PERIOD]}
          </Row>
        </Row>
      </React.Fragment>
    );
  }
}

export default TransactionGOSSetInformation;
