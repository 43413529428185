import {handleActions} from 'redux-actions';
import {combineReducers} from 'redux-immutable';
import {
  createAsyncReducer,
  initialAsyncState,
  listInitialAsyncState,
  toSuccess,
} from '../utils/asyncHelpers';
import {
  PRODUCTION_UNIT_CERTIFICATES_ADD,
  PRODUCTION_UNIT_CERTIFICATES_DELETE,
  PRODUCTION_UNIT_CERTIFICATES_FETCH,
  PRODUCTION_UNIT_UPDATE,
} from '../constants/productionUnitActionTypes';
import {resetListCertificatesAction} from '../actions/pureActions/productionUnitListActions';
import {fromJS} from "immutable";

const list = handleActions(
  {
    ...createAsyncReducer(PRODUCTION_UNIT_CERTIFICATES_FETCH),
    [toSuccess(PRODUCTION_UNIT_CERTIFICATES_FETCH)]: (state, {payload}) => {
      return state.merge({
        loading: false,
        data: fromJS(payload.result.map(id => payload.entities.productionUnitCertificate[id].file)),
      });
    },
    [toSuccess(PRODUCTION_UNIT_CERTIFICATES_ADD)]: (state, {payload}) =>
      state.set('data', state.get('data').concat(payload.result)),
    [toSuccess(PRODUCTION_UNIT_CERTIFICATES_DELETE)]: (state, {payload}) => {
      return state.set('data', state.get('data').filter(id => id !== payload.uid));
    },
    [resetListCertificatesAction]: state => state.merge(initialAsyncState),
  },
  initialAsyncState
);
const productionUnitCertificatesReducer = combineReducers({
  list,
  add: handleActions(
    createAsyncReducer(PRODUCTION_UNIT_CERTIFICATES_ADD),
    initialAsyncState
  ),
  delete: handleActions({
      ...createAsyncReducer(PRODUCTION_UNIT_CERTIFICATES_DELETE),
      [toSuccess(PRODUCTION_UNIT_CERTIFICATES_FETCH)]: state => state.merge(listInitialAsyncState),
      [toSuccess(PRODUCTION_UNIT_UPDATE)]: state => state.merge(listInitialAsyncState),
      [resetListCertificatesAction]: state => state.merge(listInitialAsyncState),
      [toSuccess(PRODUCTION_UNIT_CERTIFICATES_DELETE)]: (state, {payload}) => {
        if (!state.get('data').filter(o => o.uid === payload.uid).isEmpty()) {
          return state;
        }
        return state.set('data', state.get('data').concat(payload));
      },
    },
    listInitialAsyncState
  ),
});

export const getProductionUnitCertificatesList = state =>
  state.getIn(['productionUnitCertificates', 'list']);

export const getProductionUnitCertificatesAddState = state =>
  state.getIn(['productionUnitCertificates', 'add']);

export const getProductionUnitCertificatesDeleteState = state =>
  state.getIn(['productionUnitCertificates', 'delete', 'data']);

export default productionUnitCertificatesReducer;
