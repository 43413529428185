import {createAsyncSelector} from '../utils/asyncHelpers';
import {handleActions} from 'redux-actions';
import accountGOSAgreementsSchema from '../schemas/accountGOSTransactions';
import {createAsyncPagingReducer, listInitialAsyncPagingState,} from '../utils/asyncPagingHelpers';
import {resetMaster} from "../actions/pureActions/agreementActions";
import {TRADING_PLATFORM_AGREEMENTS_SEARCH} from "../constants/tradingPlatformActionTypes";

const tradingPlatformAgreementsList = handleActions(
  {
    ...createAsyncPagingReducer(TRADING_PLATFORM_AGREEMENTS_SEARCH),
    [resetMaster]: state => state.merge(listInitialAsyncPagingState),
  },
  listInitialAsyncPagingState
);

export const getTradingPlatformAgreementsList = state => {
  return state.get('tradingPlatformAgreementsList');
};

export const getTradingPlatformAgreementsListData = createAsyncSelector(
  [accountGOSAgreementsSchema],
  getTradingPlatformAgreementsList
);

export default tradingPlatformAgreementsList;
