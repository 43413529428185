import React, {Component} from 'react';
import PropTypesImmutable from 'react-immutable-proptypes';
import {reduxForm, getFormValues, Field, Fields} from 'redux-form/immutable';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Collapse, Row, Col, Button} from 'antd';
import {getRole, getIsUserSeller} from '../../reducers/userReducer';
import * as actions from '../../actions/actionCreators/accountGOSReportListActionCreator';
import {TextField, DateRangeField} from '../../atoms';
import {FormActions} from '../../components';
import {downloadFileByPostURL} from '../../utils/files';
import {links} from '../../utils/gotoLink';
import {
  accountGOSReportFilterFields,
  FORM_ACCOUNT_GOS_REPORT_FILTER,
} from '../../constants/formAccountGOSReportFilter';
import moment from 'moment';

const {Panel} = Collapse;

export class AccountGOSReportFilter extends Component {
  dateFrom = '';
  dateTo = '';

  static propTypes = {
    t: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    filterValues: PropTypesImmutable.map,
    userRole: PropTypes.string.isRequired,
  };

  handleExportReport = () => {
    let params = {
      filter: {
        registerCode: this.props.initialValues.get(
          accountGOSReportFilterFields.REGISTER_CODE
        ),
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        isSeller: this.props.isUserSeller,
      },
    };

    downloadFileByPostURL(
      links.accountGOSReportCsv,
      params,
      'account-gos-report.csv'
    );
  };

  updateFilterFields = () => {
    let filterString = this.props.filterValues.toString();

    let filterJson = JSON.parse(
      filterString.substring(filterString.indexOf('{'))
    );

    if (filterJson.dateFrom !== 'undefined')
      this.dateFrom = filterJson.dateFrom;
    if (filterJson.dateTo !== 'undefined') this.dateTo = filterJson.dateTo;
  };

  render() {
    const {t, handleSubmit, reset} = this.props;

    return (
      <React.Fragment>
        <Collapse>
          <Panel key="filters" header={t('report.filter.panelTitle')}>
            <form onSubmit={handleSubmit}>
              <Row gutter={24}>
                <Col span={14}>
                  <Fields
                    ref="dateRange"
                    label={t('report.filter.dateRange.title')}
                    names={[
                      accountGOSReportFilterFields.DATE_FROM,
                      accountGOSReportFilterFields.DATE_TO,
                    ]}
                    placeholder={[
                      t('report.filter.dateRange.dateFromPlaceholder'),
                      t('report.filter.dateRange.dateToPlaceholder'),
                    ]}
                    component={DateRangeField}
                    allowClear={false}
                    hasFeedback={false}
                    ranges={{
                      [t('report.filter.dateRange.currentMonth')]: [
                        moment().startOf('month'),
                        moment(),
                      ],
                      [t('report.filter.dateRange.beginLastMonth')]: [
                        moment()
                          .subtract(1, 'months')
                          .startOf('month'),
                        moment(),
                      ],
                      [t('report.filter.dateRange.lastMonth')]: [
                        moment()
                          .subtract(1, 'months')
                          .startOf('month'),
                        moment()
                          .subtract(1, 'months')
                          .endOf('month'),
                      ],
                      [t('report.filter.dateRange.currentYear')]: [
                        moment().startOf('year'),
                        moment(),
                      ],
                      [t('report.filter.dateRange.lastYear')]: [
                        moment()
                          .subtract(1, 'years')
                          .startOf('year'),
                        moment()
                          .subtract(1, 'years')
                          .endOf('year'),
                      ],
                      [t('report.filter.dateRange.last12Months')]: [
                        moment().subtract(12, 'months'),
                        moment(),
                      ],
                    }}
                  />
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <Field
                    name={accountGOSReportFilterFields.REGISTER_CODE}
                    component={TextField}
                    hasFeedback={false}
                    type="hidden"
                  />
                </Col>
              </Row>
              <FormActions>
                <Button onClick={reset}>{t('report.filter.clearBtn')}</Button>
                <Button
                  onClick={this.updateFilterFields}
                  type="primary"
                  htmlType="submit"
                >
                  {t('report.filter.submitBtn')}
                </Button>
              </FormActions>
            </form>
          </Panel>
        </Collapse>
        <FormActions>
          <Button onClick={this.handleExportReport}>{t('exportCsvBtn')}</Button>
        </FormActions>
      </React.Fragment>
    );
  }
}

const onSubmit = (values, dispatch) =>
  dispatch(actions.searchAccountGOSReport(values));

const getValues = getFormValues(FORM_ACCOUNT_GOS_REPORT_FILTER);

const mapStateToProps = state => ({
  filterValues: getValues(state),
  userRole: getRole(state),
  isUserSeller: getIsUserSeller(state),
});

const mapDispatchToProps = {
  accountGOSReport: actions.searchAccountGOSReport,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: FORM_ACCOUNT_GOS_REPORT_FILTER,
    onSubmit,
  })(AccountGOSReportFilter)
);
