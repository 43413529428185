import React from 'react';
import {AccountGOSOverview as AccountGOSOverviewContainer} from '../../containers';
import {Layout} from 'antd';
import './AccountGOSOverview.css';
import classifiersStore from "../../utils/classifiersStore";

class AccountGOSOverview extends React.Component {
  render() {
    return (
      <Layout className="main-container">
        <Layout.Content>
          <AccountGOSOverviewContainer
            registerCode={this.props.match.params.registerCode}
            isAuctionsEnabled={classifiersStore.getBooleanProperty('IS_AUCTIONS_ENABLED')}
          />
        </Layout.Content>
      </Layout>
    );
  }
}

export default AccountGOSOverview;
