export const PRODUCTION_UNIT_ENTITY_FETCH = 'PRODUCTION_UNIT_ENTITY_FETCH';
export const PRODUCTION_UNIT_ADD = 'PRODUCTION_UNIT_ADD';
export const PRODUCTION_UNIT_DELETE = 'PRODUCTION_UNIT_DELETE';
export const PRODUCTION_UNIT_UPDATE = 'PRODUCTION_UNIT_UPDATE';
export const PRODUCTION_UNIT_FUEL_UPDATE = 'PRODUCTION_UNIT_FUEL_UPDATE';
export const PRODUCTION_UNIT_UPDATE_FUELS_DELETE_NON_INCLUDED =
  'PRODUCTION_UNIT_UPDATE_FUELS_DELETE_NON_INCLUDED';
export const PRODUCTION_UNIT_FUEL_DELETE = 'PRODUCTION_UNIT_FUEL_DELETE';
export const PRODUCTION_UNIT_CLASSIFIERS = 'PRODUCTION_UNIT_CLASSIFIERS';
export const PRODUCTION_UNIT_FETCH = 'PRODUCTION_UNIT_FETCH';
export const PRODUCTION_UNIT_FETCH_BY_EICW = 'PRODUCTION_UNIT_FETCH_BY_EICW';
export const PRODUCTION_UNIT_ACTIVATION = 'PRODUCTION_UNIT_ACTIVATION';

export const PRODUCTION_UNIT_DOCUMENTS_FETCH =
  'PRODUCTION_UNIT_ADD_DOCUMENTS_FETCH';
export const PRODUCTION_UNIT_DOCUMENTS_ADD =
  'PRODUCTION_UNIT_ADD_DOCUMENTS_ADD';
export const PRODUCTION_UNIT_DOCUMENTS_DELETE =
  'PRODUCTION_UNIT_ADD_DOCUMENTS_DELETE';

export const PRODUCTION_UNIT_CERTIFICATES_FETCH =
  'PRODUCTION_UNIT_ADD_CERTIFICATES_FETCH';
export const PRODUCTION_UNIT_CERTIFICATES_ADD =
  'PRODUCTION_UNIT_ADD_CERTIFICATES_ADD';
export const PRODUCTION_UNIT_CERTIFICATES_DELETE =
  'PRODUCTION_UNIT_ADD_CERTIFICATES_DELETE';

export const PRODUCTION_UNIT_MEASUREMENT_FETCH =
  'PRODUCTION_UNIT_MEASUREMENT_FETCH';
export const PRODUCTION_UNIT_MEASUREMENT_ADD =
  'PRODUCTION_UNIT_MEASUREMENT_ADD';
export const PRODUCTION_UNIT_MEASUREMENT_UPDATE =
  'PRODUCTION_UNIT_MEASUREMENT_UPDATE';
export const PRODUCTION_UNIT_MEASUREMENT_EDIT =
  'PRODUCTION_UNIT_MEASUREMENT_EDIT';
export const PRODUCTION_UNIT_MEASUREMENT_DELETE =
  'PRODUCTION_UNIT_MEASUREMENT_DELETE';

export const SET_DUPLICATE_EICW_ERROR = 'SET_DUPLICATE_EICW_ERROR';
