import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, message, Modal, Popconfirm, Spin, Table} from 'antd';
import {TableRowActions} from '../index';
import {connect} from 'react-redux';
import './AccountGOSAuctionsSearchTable.scss';
import {
  getTsAuctionBidModal,
  hideTsAuctionBidModal,
  showTsAuctionBidModal
} from "../../actions/actionCreators/modalsActionCreators";
import {TsAuctionBid} from "../../forms";
import {getRefreshTimer} from "../../reducers/accountGOSAuctionsListReducer";
import {refreshSearchTimer} from "../../actions/actionCreators/accountGOSAuctionActionCreators";
import * as TsAuctionSearchTableColumns from "../TsAuctionSearchTable/TsAuctionSearchTableColumns";
import {goToAccountGOSAuctionDetails} from "../../utils/gotoLink";
import {isAdmin} from "../../utils/roles";
import {getRole} from "../../reducers/userReducer";
import {tCommon} from "../../i18n";
import {auctionFields} from "../../constants/auction";
import {deleteAction} from "../../actions/actionCreators/tsAuctionActionCreators";

export class AccountGOSAuctionsSearchTable extends Component {
  static propTypes = {
    tsAuctions: PropTypes.array,
    totalElements: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    pageSize: PropTypes.number.isRequired,
    search: PropTypes.func.isRequired,
    registerCode: PropTypes.string,
    searchTimer: PropTypes.number,
    deleteAuction: PropTypes.func.isRequired,
  };

  state = {
    currentPage: 1,
    selectedRow: null
  }

  componentDidMount() {
    this.startTimer();
  }

  componentWillUnmount() {
    const {refreshTimer} = this.props;
    refreshTimer();
    clearInterval(this.timerInerval);
  }

  refreshTable = () => {
    const {refreshTimer} = this.props;
    refreshTimer(1);
  }

  startTimer = () => {
    this.timerInerval = setInterval(async () => {
      const {search, searchTimer, refreshTimer} = this.props;
      const {currentPage} = this.state;

      if (searchTimer - 1 === 0) {
        await search(currentPage);
        refreshTimer();
      } else {
        refreshTimer(searchTimer - 1);
      }
    }, 1000);
  }

  onPageChange = page => {
    const {search, refreshTimer} = this.props;
    search(page);
    refreshTimer();
    this.setState({currentPage: page})
  };

  showBidModal = (row) => {
    const {showBidModal} = this.props;
    this.setState({selectedRow: row}, () => showBidModal());
  }

  handleView = (id) => {
    goToAccountGOSAuctionDetails(this.props.registerCode, id);
  }

  delete = async id => {
    const {t, deleteAuction, search} = this.props;
    const result = await deleteAuction(id);

    if (!result || !result.error) {
      message.success(t('table.actions.delete.success'));
      search(0);
    }
  }

  getActionsColumn = (t) => {
    const {isUserAdmin} = this.props;

    return {
      title: t('table.actions.title'),
      key: auctionFields.ACTIONS,
      render: (_, record) => {
        return (
          <TableRowActions>
            <Button
              shape="circle"
              icon="select"
              size="small"
              type="primary"
              title={t('table.actions.button.view')}
              onClick={() => this.handleView(record.id)}
            />
            {!isUserAdmin &&
              <Button
                shape="circle"
                icon="edit"
                size="small"
                type="primary"
                title={t('table.actions.button.bid')}
                onClick={() => this.showBidModal(record)}
              />
            }
            {isUserAdmin &&
              <Popconfirm
                title={t('table.actions.delete.confirm')}
                onConfirm={() => this.delete(record.id)}
                okText={tCommon('popconfirm.buttonYes')}
                cancelText={tCommon('popconfirm.buttonNo')}
              >
                <Button
                  shape="circle"
                  icon="delete"
                  size="small"
                  type="primary"
                  title={t('table.actions.button.delete')}
                />
              </Popconfirm>
            }
          </TableRowActions>
        );
      },
      fixed: 'right',
      width: 100,
    }
  };

  getColumns = () => {
    const {t} = this.props;
    const columns = TsAuctionSearchTableColumns.getCommonColumns(t);
    columns.push(this.getActionsColumn(t));
    return columns;
  };

  render() {
    const {
      t,
      totalElements,
      loading,
      page,
      pageSize,
      isBidModalVisible,
      hideBidModal,
      tsAuctions,
      searchTimer
    } = this.props;

    return (
      <Spin spinning={loading}>
        {isBidModalVisible && (
          <Modal
            onCancel={hideBidModal}
            visible
            footer={false}
            title={t('bidModal.title')}
            width="450px"
          >
            <TsAuctionBid
              t={t}
              selectedRow={this.state.selectedRow}
              hideBidModal={hideBidModal}
              handleView={this.handleView}
              refreshTable={this.refreshTable}
            />
          </Modal>
        )}

        <div className="mb-1 ant-row-flex ant-row-flex-end">
          {t('table.refreshTimer')}: {searchTimer}s
        </div>
        <Table
          scroll={{x: 'auto'}}
          rowKey={'id'}
          className="account-gos-auctions-table"
          dataSource={tsAuctions}
          columns={this.getColumns()}
          pagination={{
            onChange: this.onPageChange,
            current: page,
            total: totalElements,
            pageSize,
            defaultCurrent: 1,
          }}
          rowSelection={this.rowSelection}
          rowClassName={(rec) => rec.highestBidder ? 'highest-bidder' : null}
        />
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  isBidModalVisible: getTsAuctionBidModal(state),
  searchTimer: getRefreshTimer(state),
  isUserAdmin: isAdmin(getRole(state)),
});

const mapDispatchToProps = (dispatch) => ({
  refreshTimer: (seconds) => dispatch(refreshSearchTimer(seconds)),
  showBidModal: () => {
    dispatch(showTsAuctionBidModal());
  },
  hideBidModal: () => {
    dispatch(hideTsAuctionBidModal());
  },
  deleteAuction: id => dispatch(deleteAction(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountGOSAuctionsSearchTable);
