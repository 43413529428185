import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {accountGOSAgreementFields} from '../../constants/accountGOS';
import {Button, Icon, Popconfirm, Spin, Table, Tooltip} from 'antd';
import {TableActions, TableRowActions} from '../';
import {
  currentPathSelector,
  goToAccountGOSPreTradingAgreementDetails,
  goToAccountGOSReservations
} from '../../utils/gotoLink';
import {connect} from 'react-redux';
import './accountGOSAgreementsSearchTable.scss';
import {
  translateAgreementTypeClassificator,
  translateBiofuelTypeClassificator,
  translateTsFuelTypeClassificator,
  translateTsStatusClassificator
} from "../../utils/translateHelpers";
import {tsStatus as status,} from '../../constants/classificators';
import {numberFormatter} from "../../utils/formaters";

export class AccountGOSAgreementsSearchTable extends Component {
  static propTypes = {
    agreements: PropTypes.array,
    totalElements: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    pageSize: PropTypes.number.isRequired,
    search: PropTypes.func.isRequired,
    currentPath: PropTypes.string.isRequired,
    counterparty: PropTypes.string.isRequired,
    registerCode: PropTypes.string.isRequired,
    showModal: PropTypes.func.isRequired,
    deleteAgreement: PropTypes.func.isRequired,
  };

  state = {
    selectedRows: []
  }

  resetSelectedState = () => {
    this.setState({selectedRows: []})
  }

  onPageChange = page => {
    const {search} = this.props;
    search(page);
  };

  handleView(id) {
    goToAccountGOSPreTradingAgreementDetails(this.props.registerCode, id);
  }

  handleReservation(id) {
    goToAccountGOSReservations(this.props.registerCode, id);
  }

  isShowWithdrawBtn() {
    const {selectedRows} = this.state;
    if (selectedRows.length < 1) {
      return false;
    }
    return selectedRows[0].status === status.REGISTERED && !selectedRows[0].isBeingWithdrawn;
  }

  getColumns = () => {
    const {t, counterparty, deleteAgreement} = this.props;
    const isSales = counterparty === 'buyerName';

    const columns = [
      {
        title: t('agreements.table.id'),
        dataIndex: accountGOSAgreementFields.ID,
        width: "6%",
      },
      {
        title: t('agreements.table.status'),
        dataIndex: accountGOSAgreementFields.status,
        width: "8%",
        render: translateTsStatusClassificator
      },
      {
        title: t('agreements.table.queueNo'),
        dataIndex: accountGOSAgreementFields.queueNo,
        width: "6%",
      },
      {
        title: t('agreements.table.counterparty'),
        dataIndex: counterparty,
        width: "12%",
      },
      {
        title: t('agreements.table.finalConsumptionYear'),
        dataIndex: accountGOSAgreementFields.finalConsumptionYear,
        width: "5%",
      },
      {
        title: t('agreements.table.fuelType'),
        dataIndex: accountGOSAgreementFields.fuelType,
        render: translateTsFuelTypeClassificator
      },
      {
        title: t('agreements.table.fuelGeneration'),
        dataIndex: accountGOSAgreementFields.fuelGeneration,
        render: translateBiofuelTypeClassificator
      },
      {
        title: ghgTitle,
        dataIndex: accountGOSAgreementFields.ghgCapacity,
        render: numberFormatter
      },
      {
        title: t('agreements.table.agreementType'),
        dataIndex: accountGOSAgreementFields.agreementType,
        render: translateAgreementTypeClassificator
      },
      {
        title: t('agreements.table.naturalEnergyAmount'),
        dataIndex: accountGOSAgreementFields.naturalEnergyAmount,
        width: "13%",
        render: (text, record) => {
          if (record[accountGOSAgreementFields.agreementType] === 'ENTIRE') {
            return t('agreements.table.all')
          }
          return record[accountGOSAgreementFields.naturalEnergyAmount];
        }
      },
      {
        title: t('agreements.table.calculatedEnergyAmount'),
        dataIndex: accountGOSAgreementFields.calculatedEnergyAmount,
        width: "13%",
        render: (text, record) => {
          if (record[accountGOSAgreementFields.agreementType] === 'ENTIRE') {
            return t('agreements.table.all')
          }
          return record[accountGOSAgreementFields.calculatedEnergyAmount];
        }
      },
      {
        title: t('agreements.table.fulfilledPercentage'),
        dataIndex: accountGOSAgreementFields.fulfilledPercentage,
        render: (text, record) => {
          if (record[accountGOSAgreementFields.agreementType] === 'ENTIRE') {
            return '-';
          }
          return record[accountGOSAgreementFields.fulfilledPercentage];
        }
      },
      {
        title: t('agreements.table.actions'),
        dataIndex: accountGOSAgreementFields.ID,
        render: (txId, record) => {
          return (
            <TableRowActions>
              {record.isBeingWithdrawn &&
                <Icon title={t('agreements.table.isBeingWithdrawn')} type="info-circle"
                      className={'danger-icon'}/>}
              {!record.isBeingWithdrawn &&
                <span style={{width: 14}}/>}

              {isSales && record.status === status.CREATED &&
                <Popconfirm
                  title={t('agreements.table.delete.title')}
                  onConfirm={() => deleteAgreement(txId)}
                  okText={t('agreements.table.delete.buttonYes')}
                  cancelText={t('agreements.table.delete.buttonNo')}
                >
                  <Button
                    title={t('agreements.table.delete.deleteBtn')}
                    shape="circle"
                    icon="delete"
                    size="small"
                    type="primary"
                  />
                </Popconfirm>}

              <Button
                shape="circle"
                icon="select"
                size="small"
                type="primary"
                title={t('agreements.table.viewBtn')}
                onClick={() => this.handleView(txId)}
              />
              {
                isSales && record.status === status.REGISTERED &&
                <Button
                  shape="circle"
                  icon="edit"
                  size="small"
                  type="primary"
                  title={t('agreements.table.assignBtn')}
                  onClick={() => this.handleReservation(txId)}
                />
              }
            </TableRowActions>
          );
        },
        fixed: 'right',
        width: 100,
      },
    ];

    function ghgTitle() {
      return <span>
        {t('agreements.table.ghgCapacity')}&nbsp;
        <Tooltip title={t('agreements.table.ghgTooltip')}>
          <Icon type="question-circle-o"/>
        </Tooltip>
      </span>;
    }

    return columns;
  };

  render() {
    const {
      showModal,
      totalElements,
      agreements,
      loading,
      page,
      pageSize,
      t,
    } = this.props;
    const {selectedRows} = this.state;

    const rowSelection = {
      type: 'checkbox',
      selectedRowKeys: selectedRows.length > 0 ? [selectedRows[0].id] : [],

      onChange: (selectedRowKey, selectedRow) => {
        this.setState({selectedRows: selectedRow});
      },

      getCheckboxProps: record => {
        const {selectedRows} = this.state;
        return {
          disabled: (selectedRows.length > 0 && selectedRows[0].id !== record.id)
            || record[accountGOSAgreementFields.status] !== 'REGISTERED' || record.isBeingWithdrawn === true,
        };
      }
    };

    return (
      <Spin spinning={loading}>
        <Table
          scroll={{x: 'auto'}}
          rowKey={accountGOSAgreementFields.ID}
          className="account-gos-agreements-table"
          dataSource={agreements}
          columns={this.getColumns()}
          pagination={{
            onChange: this.onPageChange,
            current: page,
            total: totalElements,
            pageSize,
            defaultCurrent: 1,
          }}
          rowSelection={rowSelection}
        />
        {this.isShowWithdrawBtn() && <TableActions>
          <Button type="primary"
                  onClick={() => showModal(this.state.selectedRows[0].id, this.resetSelectedState)}>
            {t('agreements.table.withdrawalBtn')}
          </Button>
        </TableActions>}
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  currentPath: currentPathSelector(state),
});

export default connect(mapStateToProps)(AccountGOSAgreementsSearchTable);
