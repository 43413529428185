export const consumerGoReportFields = {
  MONTH: 'month',
  YEAR: 'year',
  CONSUMED_AMOUNT: 'consumedAmount',
  CONSUMED_AMOUNT_BIOMETHANE: 'consumedAmountBiomethane',
  PERCENTAGE: 'percentage',
  DETAILS: 'details',
};

export const consumerGoReportDetailsFields = {
  AMOUNT: 'amount',
  TECHNOLOGY_TYPE: 'technologyType',
  FUEL_TYPE: 'fuelType',
  PRODUCTION_COUNTRY: 'productionCountry',
};
