import PropTypes from "prop-types";
import React, {Component} from "react";
import {Button, Col, message, Row, Spin} from "antd";
import {connect} from "react-redux";
import './TsAgreementWithdraw.scss';
import {goToAccountGOSPreTradingAgreementDetails} from "../../utils/gotoLink";
import * as agreementWithdrawViewActions
  from "../../actions/actionCreators/agreementWithdrawViewActionCreators";
import {
  confirmWithdrawAssignment
} from "../../actions/actionCreators/agreementWithdrawViewActionCreators";
import {getAgreementWithdrawData} from "../../reducers/agreementWithdrawViewReducer";
import {Field, formValueSelector, reduxForm} from "redux-form/immutable";
import {RadioField} from "../../atoms";
import {FormActions} from "../../components";
import {FROM_AGREEMENT_WITHDRAW_ASSIGNMENT} from "../../constants/formKeys";
import validate from "./ValidateSchema";
import {formAssignmentFields} from "../../constants/forms/formAssignment";

export class TsAgreementWithdrawAssignment extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    assignment: PropTypes.object.isRequired,
    hideModal: PropTypes.func.isRequired,
    fetchWithdrawal: PropTypes.func.isRequired,
    isConfirmable: PropTypes.bool.isRequired,
    search: PropTypes.func,
    searchAgreements: PropTypes.func,
    onSuccess: PropTypes.func,
  }

  componentDidMount = () => {
    const {assignment, fetchWithdrawal} = this.props;
    fetchWithdrawal(assignment.sourceId);
  };

  openAgreement(agreementId) {
    this.props.hideModal();
    goToAccountGOSPreTradingAgreementDetails(this.props.registerCode, agreementId);
  }

  renderRow = (label, value) => (
    <div>
      <span>{label}: </span>
      <span>{value}</span>
    </div>
  );

  render() {
    const {
      t,
      withdrawal,
      assignment,
      hideModal,
      handleSubmit,
      isConfirmable,
      isConfirmAssignment
    } = this.props;

    const isLoading = Object.keys(withdrawal).length === 0;
    if (isLoading) return (<Spin/>)

    return (
      <form onSubmit={handleSubmit}>
        <Row gutter={32}>
          <Col span={24} className="detail-container">
            <div>
              <span>{t('assignments.withdraw.agreementId')}: </span>
              <a onClick={() => this.openAgreement(withdrawal.tsAgreementId)}>
                {withdrawal.tsAgreementId}
              </a>
            </div>
            {this.renderRow(t('assignments.withdraw.counterpartyName'), withdrawal.counterpartyName)}
            {this.renderRow(t('assignments.withdraw.createdDate'), withdrawal.createdDate)}
            {this.renderRow(t('assignments.withdraw.dueDate'), assignment.dueDate)}
            {this.renderRow(t('assignments.withdraw.effectiveDate'), withdrawal.effectiveDate)}
            <br/>
            <div style={{paddingBottom: 5}}>
              <span><strong>{t('assignments.withdraw.reason')}:</strong></span>
            </div>
            <textarea className={'ant-input'}
                      rows={16}
                      value={withdrawal.reason}
                      readOnly={true}/>
          </Col>
        </Row>
        {
          isConfirmable &&
          (<Row gutter={32}>
            <Col span={12}>
              <Field
                className="radio-field pt-5"
                name={formAssignmentFields.CONFIRM}
                component={RadioField}
                options={[
                  {
                    value: false,
                    label: t('assignments.withdraw.reject'),
                    tooltip: t('assignments.withdraw.rejectTooltip')
                  },
                  {
                    value: true,
                    label: t('assignments.withdraw.confirm'),
                    tooltip: t('assignments.withdraw.confirmTooltip')
                  },
                ]}
              />
            </Col>
          </Row>)
        }
        {
          isConfirmable &&
          (<FormActions>
            <Button onClick={hideModal}>
              {t('assignments.confirm.cancel')}
            </Button>
            <Button type="primary" htmlType="submit"
                    disabled={isConfirmAssignment === undefined}>
              {t('assignments.confirm.confirm')}
            </Button>
          </FormActions>)
        }
      </form>
    );
  }
}

const onSubmit = async (values, dispatch, props) => {
  dispatch(confirmWithdrawAssignment(props.assignment.id, values.toJS().confirm));
};

const onSubmitSuccess = (result, dispatch, props) => {
  const {t, onSuccess, hideModal} = props;
  message.success(t('agreements.withdraw.performSuccess'));

  onSuccess()
  hideModal();
};

const mapStateToProps = state => ({
  isConfirmAssignment: formValueSelector(FROM_AGREEMENT_WITHDRAW_ASSIGNMENT)(state, formAssignmentFields.CONFIRM),
  withdrawal: getAgreementWithdrawData(state).toJS(),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchWithdrawal: id => {
    dispatch(agreementWithdrawViewActions.fetchWithdrawal(id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FROM_AGREEMENT_WITHDRAW_ASSIGNMENT,
    validate,
    onSubmit,
    onSubmitSuccess
  })(TsAgreementWithdrawAssignment)
);
