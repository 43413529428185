import React, {useEffect, useState} from 'react';
import {
  TATransactionAccountInfo,
  TATransactionInfo,
  TableActions,
  TATransactionAmount
} from '../../components';
import {Button, Spin} from 'antd';
import {taTransactionFields} from '../../constants/transactionGOS';
import {tradingAccountTransactionFetch} from '../../actions/actionHelpers/endpoints';
import {TYPE_KHG} from '../../constants/accountGOS';
import {goBack} from '../../utils/gotoLink';

function TradingAccountTransactionView({t, transactionId, type}) {
  const [taTransaction, setTATransaction] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    tradingAccountTransactionFetch(transactionId, type)
      .then(response => setTATransaction(response.data) & setLoading(false))
      .catch(error => console.error(error) & setLoading(false))
  }, [transactionId, type]);

  const transactionType = taTransaction[taTransactionFields.TRANSACTION_TYPE];
  const isTransferTransaction =
    transactionType === 'INCOMING' || transactionType === 'OUTGOING';

  if (loading) {
    return <Spin spinning/>;
  }

  return (
    <React.Fragment>
      <h2>{t('subtitle.transactionInfo')}</h2>
      <div className="global-margin-top-24">
        <TATransactionInfo
          t={t}
          id={transactionId}
          type={type}
          tradeType={taTransaction[taTransactionFields.TRADE_TYPE]}
          txType={transactionType}
          status={taTransaction[taTransactionFields.TRANSACTION_STATUS]}
          txCreateTime={taTransaction[taTransactionFields.TRANSACTION_DATE_TIME]}
          senderAccountNumber={taTransaction[taTransactionFields.SENDER_NUMBER]}
          isAdmin={false}
        />
      </div>

      <div>
        <h2 className="global-margin-top-42">
          {t('subtitle.transactionGOS')}
        </h2>
        <div className="global-margin-top-24">
          {type !== TYPE_KHG && (
            <TATransactionAmount
              t={t}
              naturalAmount={taTransaction[taTransactionFields.TRANSACTION_NATURAL_AMOUNT_MJ]}
              accountingAmount={taTransaction[taTransactionFields.TRANSACTION_AMOUNT_MJ]}/>
          )}
          {type === TYPE_KHG && (
            <TATransactionAmount
              t={t}
              ghgAmount={taTransaction[taTransactionFields.TRANSACTION_AMOUNT_MJ]}/>
          )}
        </div>
      </div>

      {isTransferTransaction && (
        <div>
          <h2 className="global-margin-top-42">
            {transactionType === 'INCOMING' &&
              t('gosAccountInfo.senderInfo')}
            {transactionType === 'OUTGOING' &&
              t('gosAccountInfo.recipientInfo')}
          </h2>
          <div className="global-margin-top-24">
            <TATransactionAccountInfo
              t={t}
              companyName={transactionType === 'INCOMING'
                ? taTransaction[taTransactionFields.SENDER]
                : taTransaction[taTransactionFields.RECEIVER]}
              accountNumber={transactionType === 'INCOMING'
                ? taTransaction[taTransactionFields.SENDER_NUMBER]
                : taTransaction[taTransactionFields.RECEIVER_NUMBER]}
              registrationCode={transactionType === 'INCOMING'
                ? taTransaction[taTransactionFields.SENDER_REGISTRATION_CODE]
                : taTransaction[taTransactionFields.RECEIVER_REGISTRATION_CODE]}
            />
          </div>
        </div>
      )}

      <TableActions>
        <Button
          onClick={() => goBack()}
          className="global-margin-left-10"
        >
          {t('buttonBack')}
        </Button>
      </TableActions>
    </React.Fragment>
  );
}

export default TradingAccountTransactionView;
