import { Map } from 'immutable';
import { handleActions } from 'redux-actions';
import {
  goNextStep,
  goPreviousStep,
  resetMaster,
} from '../actions/pureActions/registerActions';
import _ from 'lodash/fp';
import {
  createAsyncReducer,
  initialAsyncState,
  listInitialAsyncState,
  toSuccess,
  createAsyncSelector,
} from '../utils/asyncHelpers';
import {
  MANDATE_COMPANY_ADD,
  MANDATE_COMPANY_FETCH,
  MANDATE_COMPANY_DELETE,
  MANDATE_PERSON_ADD,
  MANDATE_PERSON_DELETE,
  MANDATE_PERSON_FETCH,
  LEGAL_ENTITY_FETCH,
  MEASUREMENT_ADD,
  MEASUREMENT_FETCH,
  LEGAL_ENTITY_UPDATE,
  LEGAL_ENTITY_ADD,
  LEGAL_ENTITY_ACTIVATE,
  LEGAL_ENTITY_DEACTIVATE,
  LEGAL_ENTITY_FROM_BR_FETCH,
  PRODUCTION_UNIT_MEASUREMENT_INACTIVE_FETCH,
} from '../constants/registerActionTypes';
import {
  mandateCompany,
  mandatePerson,
  avpMeteringPointScheme,
} from '../schemas';

const getMaxStep = isTrader => (isTrader ? 2 : 6);

const initialState = Map({
  currentStep: 0,
  addMandateCompany: initialAsyncState,
  addMandatePerson: initialAsyncState,
  updateLegalEntity: initialAsyncState,
  listMandateCompany: initialAsyncState,
  listMandatePerson: initialAsyncState,
  legalEntity: initialAsyncState,
  listMeasurement: listInitialAsyncState,
  listMeasurementInactive: listInitialAsyncState,
  activation: initialAsyncState,
  fetchFromBr: initialAsyncState,
});

const registerReducer = handleActions(
  {
    ...createAsyncReducer(MANDATE_COMPANY_FETCH, 'listMandateCompany'),
    ...createAsyncReducer(LEGAL_ENTITY_FROM_BR_FETCH, 'fetchFromBr'),
    ...createAsyncReducer(MANDATE_PERSON_FETCH, 'listMandatePerson'),
    ...createAsyncReducer(LEGAL_ENTITY_FETCH, 'legalEntity'),
    ...createAsyncReducer(MEASUREMENT_FETCH, 'listMeasurement'),
    ...createAsyncReducer(
      PRODUCTION_UNIT_MEASUREMENT_INACTIVE_FETCH,
      'listMeasurementInactive'
    ),
    ...createAsyncReducer(LEGAL_ENTITY_UPDATE, 'updateLegalEntity'),
    ...createAsyncReducer(LEGAL_ENTITY_ACTIVATE, 'activation'),
    ...createAsyncReducer(LEGAL_ENTITY_DEACTIVATE, 'activation'),
    [resetMaster]: state =>
      state.merge({
        currentStep: 0,
        legalEntity: initialAsyncState,
      }),
    [goNextStep]: (state, { payload }) =>
      state.merge({
        currentStep: Math.min(
          state.get('currentStep') + 1,
          getMaxStep(payload)
        ),
      }),
    [goPreviousStep]: state =>
      state.merge({
        currentStep: Math.max(state.get('currentStep') - 1, 0),
      }),
    [toSuccess(MANDATE_PERSON_ADD)]: (state, { payload }) =>
      state.setIn(
        ['listMandatePerson', 'data'],
        state.getIn(['listMandatePerson', 'data']).concat(payload.result)
      ),
    [toSuccess(MANDATE_PERSON_DELETE)]: (state, { payload }) =>
      state.setIn(
        ['listMandatePerson', 'data'],
        state.getIn(['listMandatePerson', 'data']).filter(id => id !== payload)
      ),
    [toSuccess(MANDATE_COMPANY_ADD)]: (state, { payload }) =>
      state.setIn(
        ['listMandateCompany', 'data'],
        state.getIn(['listMandateCompany', 'data']).concat(payload.result)
      ),
    [toSuccess(MANDATE_COMPANY_DELETE)]: (state, { payload }) =>
      state.setIn(
        ['listMandateCompany', 'data'],
        state.getIn(['listMandateCompany', 'data']).filter(id => id !== payload)
      ),
    [toSuccess(MEASUREMENT_ADD)]: (state, { payload }) =>
      state.setIn(
        ['listMeasurement', 'data'],
        state.getIn(['listMeasurement', 'data']).concat(payload.result)
      ),
    [toSuccess(LEGAL_ENTITY_ADD)]: (state, { payload }) =>
      state.setIn(['legalEntity', 'data'], payload.result),
  },
  initialState
);

export const getMandatePersonListPure = state =>
  state.getIn(['register', 'listMandatePerson']);

export const getMandatePersonList = createAsyncSelector(
  [mandatePerson],
  getMandatePersonListPure
);

export const getMandateCompanyListPure = state =>
  state.getIn(['register', 'listMandateCompany']);

export const getMandateCompanyList = createAsyncSelector(
  [mandateCompany],
  getMandateCompanyListPure
);

export const legalEntitySelector = state =>
  state.getIn(['register', 'legalEntity']);

export const accountGOSSelector = state =>
  state.getIn(['register', 'accountGOS']);

export const getMeasurementList = state =>
  state.getIn(['register', 'listMeasurement']);

export const getMeasurementListInactive = state =>
  state.getIn(['register', 'listMeasurementInactive']);

export const getEntityMeteringPointsInactiveData = createAsyncSelector(
  [avpMeteringPointScheme],
  getMeasurementListInactive
);

export const getEntityMeteringPointsData = createAsyncSelector(
  [avpMeteringPointScheme],
  getMeasurementList
);

export const getUpdateLegalEntity = state =>
  state.getIn(['register', 'updateLegalEntity']);

export const getActivationState = state =>
  state.getIn(['register', 'activation']);

export const getFetchFromBr = state => state.getIn(['register', 'fetchFromBr']);

export const getCurrentStep = state => state.getIn(['register', 'currentStep']);
export const hasPrevious = _.flow(getCurrentStep, _.lt(0));
export const hasNext = (state, isTrader) =>
  getCurrentStep(state) < getMaxStep(isTrader);
export const hasDone = (state, isTrader) =>
  getCurrentStep(state) === getMaxStep(isTrader);

export const getMaxStepNew = (
  isOperator,
  isManufacturer,
  isHydroManufacturer
) => {
  if (isManufacturer && isHydroManufacturer) return 6;
  if (isManufacturer) return 5;
  if (isHydroManufacturer) return 4;
  if (isOperator) return 3;
  return 2;
};
export const hasDoneNew = (
  state,
  isOperator,
  isManufacturer,
  isHydroManufacturer
) =>
  getCurrentStep(state) ===
  getMaxStepNew(isOperator, isManufacturer, isHydroManufacturer);

export default registerReducer;
