import React from 'react';
import {Row, Col, Form} from 'antd';
import PropTypes from 'prop-types';

const FormItem = Form.Item;

const MeteringPointOwnerInfo = ({t, name, code}) => (
  <div>
    <h2>{t('owner.title')}</h2>
    <Row gutter={32}>
      <Col span={8}>
        <FormItem label={t('owner.name')}>
          <span>{name}</span>
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem label={t('owner.code')}>
          <span>{code}</span>
        </FormItem>
      </Col>
    </Row>
  </div>
);

MeteringPointOwnerInfo.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string,
  code: PropTypes.string,
};

export default MeteringPointOwnerInfo;
