import React from 'react';
import {Upload, Icon, Button, notification, Spin} from 'antd';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {MeasuringSample} from '../../components';
import {connect} from 'react-redux';
import {
  measurementImportFilePointAddActionCreator,
  measurementImportFileUnitAddActionCreator,
} from '../../actions/actionCreators/measurementImportActionCreators';
import {getAddFileMeasurementImport} from '../../reducers/measurementImportReducer';
import {getUserLegalEntity} from '../../reducers/userReducer';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {NAMESPACES} from '../../i18n';
import {translate} from 'react-i18next';
import {legalEntityFields} from '../../constants/legalEntity';
import './MeasuringUpload.css';
import {getCsrfCookie} from "../../utils/cookieUtil";
import {FILE_URL} from "../../constants/file";

const uploadProps = {
  action: FILE_URL,
  accept:
    '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  headers: {
    'X-XSRF-TOKEN': getCsrfCookie()
  },
  name: 'file',
  multiple: false,
  showUploadList: true,
};

const openWarning = (message, description) => {
  notification.warning({
    message,
    description,
  });
};

class MeasuringUpload extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    measurementImportFilePointAdd: PropTypes.func.isRequired,
    measurementImportFileUnitAdd: PropTypes.func.isRequired,
    productionUnitId: PropTypes.number,
    meteringPointId: PropTypes.number,
    userLegalEntity: PropTypesImmutable.map,
  };

  state = {
    list: [],
  };

  onChange = obj => {
    this.setState({
      list: obj.fileList,
    });
  };

  onSave = () => {
    const {
      t,
      measurementImportFilePointAdd,
      measurementImportFileUnitAdd,
      productionUnitId,
      meteringPointId,
    } = this.props;
    const {list} = this.state;

    if (list.length !== 1) {
      openWarning(t('uploadWarningTitle'), t('uploadWarningBody'));
    } else {
      const item = list[0];
      if (item.status === 'done') {
        if (meteringPointId) {
          measurementImportFilePointAdd({
            fileId: item.response,
            measurementPointId: meteringPointId,
          });
        } else if (productionUnitId) {
          measurementImportFileUnitAdd({
            fileId: item.response,
            productionUnitId,
          });
        }
      }
    }
  };

  render() {
    const {t, isLoading, userLegalEntity} = this.props;

    const legalEntityName = userLegalEntity
      ? userLegalEntity.get(legalEntityFields.NAME)
      : '';

    const legalEntityAddress = userLegalEntity
      ? userLegalEntity.get(legalEntityFields.ADDRESS)
      : '';

    return (
      <Spin spinning={isLoading}>
        <div className="containerMeasuringUpload">
          <h3>{t('titleSample')}</h3>
          <MeasuringSample
            t={t}
            legalEntityName={legalEntityName}
            legalEntityAddress={legalEntityAddress}
          />
          <h3 className="containerMeasuringUpload__title">
            {t('titleUpload')}
          </h3>
          <Upload.Dragger {...uploadProps} onChange={this.onChange}>
            <p className="ant-upload-drag-icon">
              <Icon type="inbox"/>
            </p>
            <p className="ant-upload-text">{t('upload.text')}</p>
            <p className="ant-upload-hint">{t('upload.hint')}</p>
          </Upload.Dragger>
          <div className="containerMeasuringUpload__actions">
            <Button onClick={this.onSave} type="primary">
              {t('upload.buttonSave')}
            </Button>
          </div>
        </div>
      </Spin>
    );
  }
}

const getLoading = getLoadingSelector(getAddFileMeasurementImport);

const mapStateToProps = state => ({
  isLoading: getLoading(state),
  userLegalEntity: getUserLegalEntity(state),
});

const mapDispatchToProps = {
  measurementImportFilePointAdd: measurementImportFilePointAddActionCreator,
  measurementImportFileUnitAdd: measurementImportFileUnitAddActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  translate(NAMESPACES.measuring)(MeasuringUpload)
);
