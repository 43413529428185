import React from 'react';
import { ProductionUnitAdd } from '../../containers';
import { Layout } from 'antd';
import PropTypes from 'prop-types';

class LegalEntityEdit extends React.Component {
  static propTypes = {
    fuelType: PropTypes.string,
  };

  render() {
    const { fuelType } = this.props;

    return (
      <Layout className="main-container">
        <Layout.Content>
          <ProductionUnitAdd fuelType={fuelType} />
        </Layout.Content>
      </Layout>
    );
  }
}

export default LegalEntityEdit;
