import {createValidation, validatorRequired} from '../../utils/formValidators';
import {consumerFields} from '../../constants/consumer';
import consumerTypeClassificator from '../../constants/classificators/consumerType';

const validatorRequiredCompany = () => (field, form) =>
  form.get(consumerFields.CONSUMER_TYPE) ===
  consumerTypeClassificator.MANUFACTURER
    ? validatorRequired()(field)
    : '';

const validatorRequiredPerson = () => (field, form) =>
  form.get(consumerFields.CONSUMER_TYPE) === consumerTypeClassificator.CONSUMER
    ? validatorRequired()(field)
    : '';

export default createValidation([
  {
    name: consumerFields.COMPANY_NAME,
    validators: validatorRequiredCompany(),
  },
  {
    name: consumerFields.EICX_CODE,
    validators: validatorRequired(),
  },
  {
    name: consumerFields.REGISTER_CODE,
    validators: validatorRequiredCompany(),
  },
  {
    name: consumerFields.NAME,
    validators: validatorRequiredPerson(),
  },
  {
    name: consumerFields.PERSONAL_CODE,
    validators: validatorRequiredPerson(),
  },
  {
    name: consumerFields.CONSUMER_TYPE,
    validators: validatorRequired(),
  },
]);
