import * as actions from '../pureActions/certificateActions';
import {transferCertificatesPost} from '../actionHelpers/endpoints';
import {createAsyncAction} from '../../utils/asyncHelpers';
import {
  TRANSFER_CERTIFICATES
} from '../../constants/actionTypes/transferringCertificatesActionTypes';

export const selectReceiver = accountId => dispatch =>
  dispatch(actions.selectReceiver(accountId));

export const transferCertificates = createAsyncAction(
  TRANSFER_CERTIFICATES,
  transferCertificatesPost
);
