import {
  CLASSIFIER_LIST_FETCH,
  CLASSIFIER_ITEM_FETCH,
  CLASSIFIER_PRICE_CHANGE,
  CLASSIFIER_TAX_CHANGE,
  CLASSIFIER_TAX_FETCH,
  CLASSIFIER_INVOICE_CONFIRMATION_FETCH,
  CLASSIFIER_INVOICE_CONFIRMATION_CHANGE,
  CLASSIFIER_GENERAL_FETCH,
  CLASSIFIER_SUB_FETCH,
  CLASSIFIER_SUB_VALUES_FETCH,
  CLASSIFIER_GENERAL_CHANGE,
  CLASSIFIER_SUB_CHANGE,
  CLASSIFIER_SUB_VALUES_CHANGE,
  CLASSIFIER_GO_AUTO_FETCH,
  CLASSIFIER_GO_AUTO_CHANGE,
  CLASSIFIER_EAVP_LOAD_FETCH,
  CLASSIFIER_EAVP_LOAD_CHANGE,
  CLASSIFIER_GOS_AUTO_FETCH,
  CLASSIFIER_GOS_AUTO_CHANGE,
  CLASSIFIER_GRID_OPERATOR_CHANGE,
  CLASSIFIER_GRID_OPERATOR_FETCH,
  CLASSIFIER_GRID_OPERATOR_LIST_FETCH,
  CLASSIFIER_GRID_OPERATOR_DELETE,
  CLASSIFIER_SELLER_LIST_FETCH,
  CLASSIFIER_SELLER_FETCH,
  CLASSIFIER_SELLER_DELETE,
  CLASSIFIER_SELLER_CHANGE,
  CLASSIFIER_ELERING_CHANGE,
  CLASSIFIER_ELERING_FETCH,
  CLASSIFIER_SUBSIDY_DATE_CHANGE,
  CLASSIFIER_INVOICE_DATE_CHANGE,
  CLASSIFIER_SUBSIDY_DATE_FETCH,
  CLASSIFIER_INVOICE_DATE_FETCH
} from '../constants/actionTypes/classifiersActionTypes';
import {
  createAsyncReducer,
  listInitialAsyncState,
  initialAsyncState,
} from '../utils/asyncHelpers';
import {handleActions} from 'redux-actions';
import {Map} from 'immutable';
import {
  classifierTaxEditFields,
  classifierInvoiceConfirmationEditFields,
} from '../constants/classifier';
import {goAutomaticCancel} from '../constants/parameters';
import {gosAutomaticExpiration} from '../constants/parameters';
import eavpLoadDataCron from "../constants/parameters/eavpLoadDataCron";

const initialState = Map({
  classifiersList: listInitialAsyncState,
  classifiersItem: initialAsyncState,
  classifiersPriceChange: initialAsyncState,
  taxItem: initialAsyncState,
  taxChange: initialAsyncState,
  goAutoItem: initialAsyncState,
  goAutoChange: initialAsyncState,
  eavpLoadItem: initialAsyncState,
  eavpLoadChange: initialAsyncState,
  gosAutoItem: initialAsyncState,
  gosAutoChange: initialAsyncState,
  subsidyDateItem: initialAsyncState,
  subsidyDateChange: initialAsyncState,
  eleringItem: initialAsyncState,
  eleringChange: initialAsyncState,
  gridOperatorList: initialAsyncState,
  gridOperatorDelete: initialAsyncState,
  gridOperatorItem: initialAsyncState,
  gridOperatorChange: initialAsyncState,
  sellerList: initialAsyncState,
  sellerDelete: initialAsyncState,
  sellerItem: initialAsyncState,
  sellerChange: initialAsyncState,
  invoiceConfirmationItem: initialAsyncState,
  invoiceConfirmationChange: initialAsyncState,
  classifierGeneralItem: initialAsyncState,
  classifierSubItem: initialAsyncState,
  classifierSubValuesItem: initialAsyncState,
  classifierGeneralChange: initialAsyncState,
  classifierSubChange: initialAsyncState,
  classifierSubValuesChange: initialAsyncState,
  invoiceDateItem: initialAsyncState,
  invoiceDateChange: initialAsyncState
});

const classifierReducer = handleActions(
  {
    ...createAsyncReducer(CLASSIFIER_LIST_FETCH, 'classifiersList'),
    ...createAsyncReducer(CLASSIFIER_ITEM_FETCH, 'classifiersItem'),
    ...createAsyncReducer(CLASSIFIER_PRICE_CHANGE, 'classifiersPriceChange'),
    ...createAsyncReducer(CLASSIFIER_TAX_FETCH, 'taxItem'),
    ...createAsyncReducer(CLASSIFIER_TAX_CHANGE, 'taxChange'),
    ...createAsyncReducer(CLASSIFIER_GO_AUTO_FETCH, 'goAutoItem'),
    ...createAsyncReducer(CLASSIFIER_GO_AUTO_CHANGE, 'goAutoChange'),
    ...createAsyncReducer(CLASSIFIER_EAVP_LOAD_FETCH, 'eavpLoadItem'),
    ...createAsyncReducer(CLASSIFIER_EAVP_LOAD_CHANGE, 'eavpLoadChange'),
    ...createAsyncReducer(CLASSIFIER_GOS_AUTO_FETCH, 'gosAutoItem'),
    ...createAsyncReducer(CLASSIFIER_GOS_AUTO_CHANGE, 'gosAutoChange'),
    ...createAsyncReducer(CLASSIFIER_SUBSIDY_DATE_FETCH, 'subsidyDateItem'),
    ...createAsyncReducer(CLASSIFIER_SUBSIDY_DATE_CHANGE, 'subsidyDateChange'),
    ...createAsyncReducer(CLASSIFIER_INVOICE_DATE_FETCH, 'invoiceDateItem'),
    ...createAsyncReducer(CLASSIFIER_INVOICE_DATE_CHANGE, 'invoiceDateChange'),
    ...createAsyncReducer(CLASSIFIER_ELERING_FETCH, 'eleringItem'),
    ...createAsyncReducer(CLASSIFIER_ELERING_CHANGE, 'eleringChange'),
    ...createAsyncReducer(CLASSIFIER_GRID_OPERATOR_FETCH, 'gridOperatorItem'),
    ...createAsyncReducer(
      CLASSIFIER_GRID_OPERATOR_LIST_FETCH,
      'gridOperatorList'
    ),
    ...createAsyncReducer(
      CLASSIFIER_GRID_OPERATOR_DELETE,
      'gridOperatorDelete'
    ),
    ...createAsyncReducer(
      CLASSIFIER_GRID_OPERATOR_CHANGE,
      'gridOperatorChange'
    ),
    ...createAsyncReducer(CLASSIFIER_SELLER_FETCH, 'sellerItem'),
    ...createAsyncReducer(CLASSIFIER_SELLER_LIST_FETCH, 'sellerList'),
    ...createAsyncReducer(CLASSIFIER_SELLER_DELETE, 'sellerDelete'),
    ...createAsyncReducer(CLASSIFIER_SELLER_CHANGE, 'sellerChange'),
    ...createAsyncReducer(
      CLASSIFIER_INVOICE_CONFIRMATION_FETCH,
      'invoiceConfirmationItem'
    ),
    ...createAsyncReducer(
      CLASSIFIER_INVOICE_CONFIRMATION_CHANGE,
      'invoiceConfirmationChange'
    ),
    ...createAsyncReducer(CLASSIFIER_GENERAL_FETCH, 'classifierGeneralItem'),
    ...createAsyncReducer(CLASSIFIER_SUB_FETCH, 'classifierSubItem'),
    ...createAsyncReducer(CLASSIFIER_SUB_VALUES_FETCH, 'classifierSubValuesItem'),
    ...createAsyncReducer(CLASSIFIER_GENERAL_CHANGE, 'classifierGeneralChange'),
    ...createAsyncReducer(CLASSIFIER_SUB_CHANGE, 'classifierSubChange'),
    ...createAsyncReducer(CLASSIFIER_SUB_VALUES_CHANGE, 'classifierSubValuesChange'),
  },
  initialState
);

export const getClassifiersList = state =>
  state.getIn(['classifiers', 'classifiersList']);

export const getClassifiersListData = state =>
  state.getIn(['classifiers', 'classifiersList', 'data']);

export const getClassifierGeneralItem = state =>
  state.getIn(['classifiers', 'classifierGeneralItem']);

export const getClassifierSubItem = state =>
  state.getIn(['classifiers', 'classifierSubItem']);

export const getClassifierSubValuesItem = state =>
  state.getIn(['classifiers', 'classifierSubValuesItem']);

export const getClassifierGeneralItemData = state =>
  state.getIn(['classifiers', 'classifierGeneralItem', 'data']);

export const getClassifierSubItemData = state =>
  state.getIn(['classifiers', 'classifierSubItem', 'data']);

export const getClassifierSubValuesItemData = state =>
  state.getIn(['classifiers', 'classifierSubValuesItem', 'data']);

export const getClassifierGeneralChange = state =>
  state.getIn(['classifiers', 'classifierGeneralChange']);

export const getClassifierSubChange = state =>
  state.getIn(['classifiers', 'classifierSubChange']);

export const getClassifierSubValuesChange = state =>
  state.getIn(['classifiers', 'classifierSubValuesChange']);

export const getClassifiersItem = state =>
  state.getIn(['classifiers', 'classifiersItem']);

export const getClassifiersItemData = state =>
  state.getIn(['classifiers', 'classifiersItem', 'data']);

export const getClassifiersPriceChange = state =>
  state.getIn(['classifiers', 'classifiersPriceChange']);

export const getClassifiersTaxItem = state =>
  state.getIn(['classifiers', 'taxItem']);

export const getClassifiersTaxItemData = state =>
  state.getIn(['classifiers', 'taxItem', 'data', classifierTaxEditFields.TAX]);

export const getClassifiersTaxChange = state =>
  state.getIn(['classifiers', 'taxChange']);

export const getClassifiersGoAutoItem = state =>
  state.getIn(['classifiers', 'goAutoItem']);

export const getClassifiersGoAutoItemData = state =>
  state.getIn(['classifiers', 'goAutoItem', 'data', goAutomaticCancel.VALUE]);

export const getClassifiersGoAutoChange = state =>
  state.getIn(['classifiers', 'goAutoChange']);

export const getClassifiersEAVPLoadItem = state =>
  state.getIn(['classifiers', 'eavpLoadItem']);

export const getClassifiersEAVPLoadItemData = state =>
  state.getIn(['classifiers', 'eavpLoadItem', 'data', eavpLoadDataCron.VALUE]);

export const getClassifiersEAVPLoadChange = state =>
  state.getIn(['classifiers', 'eavpLoadChange']);

export const getClassifiersGosAutoItem = state =>
  state.getIn(['classifiers', 'gosAutoItem']);

export const getClassifiersGosAutoItemData = state =>
  state.getIn(['classifiers', 'gosAutoItem', 'data', gosAutomaticExpiration.VALUE]);

export const getClassifiersGosAutoChange = state =>
  state.getIn(['classifiers', 'gosAutoChange']);

export const getClassifiersSubsidyDateItem = state =>
  state.getIn(['classifiers', 'subsidyDateItem']);

export const getClassifiersSubsidyDateItemData = state =>
  state.getIn(['classifiers', 'subsidyDateItem', 'data']);

export const getClassifiersSubsidyDateChange = state =>
  state.getIn(['classifiers', 'subsidyDateChange']);

export const getClassifiersInvoiceDateItem = state =>
  state.getIn(['classifiers', 'invoiceDateItem']);

export const getClassifiersInvoiceDateItemData = state =>
  state.getIn(['classifiers', 'invoiceDateItem', 'data']);

export const getClassifiersInvoiceDateChange = state =>
  state.getIn(['classifiers', 'invoiceDateChange']);

export const getClassifiersEleringItem = state =>
  state.getIn(['classifiers', 'eleringItem']);

export const getClassifiersEleringItemData = state =>
  state.getIn(['classifiers', 'eleringItem', 'data']);

export const getClassifiersEleringChange = state =>
  state.getIn(['classifiers', 'eleringChange']);

export const getClassifiersGridOperatorItem = state =>
  state.getIn(['classifiers', 'gridOperatorItem']);

export const getClassifiersGridOperatorItemData = state =>
  state.getIn(['classifiers', 'gridOperatorItem', 'data']);

export const getClassifiersGridOperatorDelete = state =>
  state.getIn(['classifiers', 'gridOperatorDelete']);

export const getClassifiersGridOperatorList = state =>
  state.getIn(['classifiers', 'gridOperatorList']);

export const getClassifiersGridOperatorListData = state =>
  state.getIn(['classifiers', 'gridOperatorList', 'data']);

export const getClassifiersGridOperatorChange = state =>
  state.getIn(['classifiers', 'gridOperatorChange']);

export const getClassifiersSellerItem = state =>
  state.getIn(['classifiers', 'sellerItem']);

export const getClassifiersSellerItemData = state =>
  state.getIn(['classifiers', 'sellerItem', 'data']);

export const getClassifiersSellerDelete = state =>
  state.getIn(['classifiers', 'sellerDelete']);

export const getClassifiersSellerList = state =>
  state.getIn(['classifiers', 'sellerList']);

export const getClassifiersSellerListData = state =>
  state.getIn(['classifiers', 'sellerList', 'data']);

export const getClassifiersSellerChange = state =>
  state.getIn(['classifiers', 'sellerChange']);

export const getClassifiersInvoiceConfirmationItem = state =>
  state.getIn(['classifiers', 'invoiceConfirmationItem']);

export const getClassifiersInvoiceConfirmationItemDataEt = state =>
  state.getIn([
    'classifiers',
    'invoiceConfirmationItem',
    'data',
    classifierInvoiceConfirmationEditFields.ET,
  ]);

export const getClassifiersInvoiceConfirmationItemDataEn = state =>
  state.getIn([
    'classifiers',
    'invoiceConfirmationItem',
    'data',
    classifierInvoiceConfirmationEditFields.EN,
  ]);

export const getClassifiersInvoiceConfirmationChange = state =>
  state.getIn(['classifiers', 'invoiceConfirmationChange']);

export default classifierReducer;
