import React, {useEffect, useState} from "react";
import {Button, Modal, Popconfirm, Spin, Table} from "antd";
import {
  classifierConsumptionUsageProperties,
  classifierGeneralField,
  classifierGeneralItemField,
  classifierGeneralItemLangField
} from "../../constants/classifier";
import {translateConsumptionUsagePeriodClassificator} from "../../utils/translateHelpers";
import {TableActions, TableRowActions} from "../index";
import {
  classifiersGeneralChange,
  classifiersGeneralFetch
} from "../../actions/actionHelpers/endpoints";
import {mapPropertiesToObject} from "../../utils/classifiersStore";
import {ClassifierGeneralEdit as ClassifierGeneralEditForm} from "../../forms";

export function ParameterConsumptionTypeTable({t, classifierId}) {

  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [classifierData, setClassifierData] = useState([]);
  const [selectedCode, setSelectedCode] = useState(null);

  useEffect(() => {
    loadClassifierData();
  }, [classifierId]);

  const loadClassifierData = () => {
    !loading && setLoading(true);

    classifiersGeneralFetch(classifierId)
      .then(response => {
        response.data[classifierGeneralField.ITEMS]
          .filter(item => item[classifierGeneralItemField.ACTIVE])
          .forEach(item => {
            item[classifierGeneralItemField.PROPERTIES] = mapPropertiesToObject(item[classifierGeneralItemField.PROPERTIES]);
          });
        return setClassifierData(response.data) & setLoading(false);
      })
      .catch(error => console.error(error) & setLoading(false));
  };

  const showModal = () => {
    setModalVisible(true);
  };

  const hideModal = () => {
    setModalVisible(false);
  };

  const onChangeClassifier = async (code, data) => {
    await classifiersGeneralChange(code, data);
    loadClassifierData();
  };

  const getFormInitialValues = () => {
    let lang = {};
    let properties = {};

    if (selectedCode) {
      const itemCode = classifierData[classifierGeneralField.ITEMS].find(item => item[classifierGeneralItemField.CODE] === selectedCode);
      if (itemCode) {
        lang = itemCode[classifierGeneralItemField.LANG];
        properties = itemCode[classifierGeneralItemField.PROPERTIES];
      }
    }

    return {
      [classifierGeneralItemField.ACTIVE]: true,
      [classifierGeneralItemField.CODE]: selectedCode,
      [classifierGeneralItemField.LANG]: lang,
      [classifierGeneralItemField.PROPERTIES]: properties
    }
  };

  const getColumns = () => {
    return [
      {
        title: t('tableGeneral.code'),
        dataIndex: classifierGeneralItemField.CODE
      },
      {
        title: t('tableGeneral.en'),
        dataIndex: `${classifierGeneralItemField.LANG}.${classifierGeneralItemLangField.EN}`
      },
      {
        title: t('tableGeneral.et'),
        dataIndex: `${classifierGeneralItemField.LANG}.${classifierGeneralItemLangField.ET}`
      },
      {
        title: t('tableGeneral.additional.dateStart'),
        dataIndex: `${classifierGeneralItemField.PROPERTIES}.${classifierConsumptionUsageProperties.START_DAY}`
      },
      {
        title: t('tableGeneral.additional.dateEnd'),
        dataIndex: `${classifierGeneralItemField.PROPERTIES}.${classifierConsumptionUsageProperties.END_DAY}`
      },
      {
        title: t('tableGeneral.additional.supportRegulation'),
        dataIndex: `${classifierGeneralItemField.PROPERTIES}.${classifierConsumptionUsageProperties.SUPPORT_REGULATION}`,
        render: value => Number(value).toFixed(2)
      },
      {
        title: t('tableGeneral.additional.period'),
        dataIndex: `${classifierGeneralItemField.PROPERTIES}.${classifierConsumptionUsageProperties.PERIOD}`,
        render: translateConsumptionUsagePeriodClassificator
      },
      {
        title: t('tableGeneral.additional.subsidyInEnglish'),
        dataIndex: `${classifierGeneralItemField.PROPERTIES}.${classifierConsumptionUsageProperties.SUBSIDY_IN_ENGLISH}`
      },
      {
        title: t('tableGeneral.additional.subsidyInEstonian'),
        dataIndex: `${classifierGeneralItemField.PROPERTIES}.${classifierConsumptionUsageProperties.SUBSIDY_IN_ESTONIAN}`
      },
      {
        title: t('tableGeneral.actions'),
        render: value => (
          <TableRowActions>
            <Button
              shape="circle"
              icon="edit"
              size="small"
              type="primary"
              onClick={() => {
                setSelectedCode(value[classifierGeneralItemField.CODE]);
                showModal();
              }}
            />
            <span className="ant-divider"/>
            <Popconfirm
              title={t('popconfirm.title')}
              onConfirm={() =>
                onChangeClassifier(classifierId, {
                  [classifierGeneralItemField.CODE]: value[classifierGeneralItemField.CODE],
                  [classifierGeneralItemField.ACTIVE]: false
                })
              }
              okText={t('popconfirm.buttonYes')}
              cancelText={t('popconfirm.buttonNo')}
            >
              <Button
                shape="circle"
                icon="delete"
                size="small"
                type="primary"
              />
            </Popconfirm>
          </TableRowActions>
        )
      }
    ];
  };

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <h1>{t('parameterConsumptionTypeTitle')}</h1>
        {classifierData && (
          <Table
            rowKey={classifierGeneralItemField.CODE}
            dataSource={classifierData[classifierGeneralField.ITEMS]}
            columns={getColumns()}
            pagination={false}
          />
          // <ParameterConsumptionTypeTable
          //   t={t}
          //   dataSource={classifierData}
          //   classifierId={classifierId}
          //   changeClassifier={onChangeClassifier}
          //   showModal={code => {
          //     setSelectedCode(code);
          //     showModal();
          //   }}
          // />
        )}
        {modalVisible && (
          <Modal
            title={t('modalTitle')}
            visible
            footer={null}
            onCancel={hideModal}
            width={640}
          >
            <ClassifierGeneralEditForm
              initialValues={getFormInitialValues()}
              classifierCode={classifierId}
              onCancel={hideModal}
              selectedCode={selectedCode}
              onComplete={() => {
                loadClassifierData();
                hideModal();
              }}
              isEdit={!!selectedCode}
              t={t}
            />
          </Modal>
        )}
        <TableActions>
          {/*<Button*/}
          {/*type="primary"*/}
          {/*onClick={() => {*/}
          {/*setSelectedCode(null);*/}
          {/*showModal();*/}
          {/*}}*/}
          {/*className="global-margin-left-10"*/}
          {/*>*/}
          {/*{t('buttonValueAdd')}*/}
          {/*</Button>*/}
        </TableActions>
      </Spin>
    </React.Fragment>
  );
}
