import React, {Component} from 'react';
import {TableActions} from "../../components";
import {Button, Col, Row, message} from "antd";
import {
  FORM_FUELS_USED_HISTORY,
  fuelsUsedHistoryFormFields
} from "../../constants/formsAddEquipment";
import { downloadFileByGetURL, downloadFileByPostURL } from '../../utils/files';
import {FORMAT_DEFAULT_DATE, links} from "../../utils";
import PropTypes from "prop-types";
import moment from "moment";
import {getFormValues, reduxForm} from "redux-form/lib/immutable";
import {connect} from "react-redux";
import PropTypesImmutable from "react-immutable-proptypes";
import {MonthYearField} from "../../atoms";
import {Fields} from "redux-form/immutable";
import { productionUnitFuelsHistoryExists } from '../../actions/actionHelpers/endpoints';

class FuelsUsedHistory extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    productionUnitId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    initialValues: PropTypesImmutable.map,
    formValues: PropTypesImmutable.map,
    isHydrogenContext:PropTypes.bool,
  };

  handleExportFuelsUsedHistory = () => {
    const {t, formValues, isHydrogenContext} = this.props;

    if (!formValues || !formValues.get(fuelsUsedHistoryFormFields.MONTH) || !formValues.get(fuelsUsedHistoryFormFields.YEAR)) {
      message.error(t('fuelsUsed.history.monthNotEnteredError'));
    } else {
      if(!isHydrogenContext){
        const params = {
          productionUnitId: this.props.productionUnitId,
          historyDate: moment().month(formValues.get(fuelsUsedHistoryFormFields.MONTH) - 1).year(formValues.get(fuelsUsedHistoryFormFields.YEAR)).format(FORMAT_DEFAULT_DATE)
        };
        productionUnitFuelsHistoryExists(params).then(response => {
          if (response.data > 0) {
            downloadFileByPostURL(
              links.productionUnitFuelsUsedHistoryCsv,
              params,
              'biofuel-data.xls'
            );
          } else {
            message.error(t('fuelsUsed.history.monthNoDataError'))
          }
        })
      } else {
        const params = {
          productionUnitId: this.props.productionUnitId,
          month: formValues.get(fuelsUsedHistoryFormFields.MONTH),
          year: formValues.get(fuelsUsedHistoryFormFields.YEAR)
        }

        downloadFileByGetURL(links.hydrogenFuelExport, params, 'fuel-data.xls', 'get', t)
      }
    }
  };

  render() {
    const {t} = this.props;
    return (
      <TableActions>
        <Row style={{lineHeight: '40px'}} gutter={24}>
          <Col span={12}>
            <h3>{t('fuelsUsed.history.title')}:</h3>
          </Col>
          <Col span={5}>
            <Fields
              names={[
                fuelsUsedHistoryFormFields.MONTH,
                fuelsUsedHistoryFormFields.YEAR,
              ]}
              className="date-field"
              disabledDate={current =>
                current.isAfter(moment().subtract(1, 'months'))
              }
              defaultPickerValue={moment().subtract(1, 'months')}
              component={MonthYearField}
              hasFeedback={false}
            />
          </Col>
          <Col span={5}>
            <Button onClick={this.handleExportFuelsUsedHistory}>
              {t('fuelsUsed.history.exportCsvBtn')}
            </Button>
          </Col>
        </Row>
      </TableActions>
    );
  }
}

const formValuesSelector = getFormValues(FORM_FUELS_USED_HISTORY);

const mapStateToProps = state => ({
  formValues: formValuesSelector(state),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_FUELS_USED_HISTORY,
  })(FuelsUsedHistory)
);

