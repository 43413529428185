import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {tradingAccountTransactionsFields, TYPE_KHG} from '../../constants/accountGOS';
import {Table, Spin, Button} from 'antd';
import {
  translateGosTransactionTypeClassificator,
  translateTransactionForGosStatusClassificator,
} from '../../utils/translateHelpers';
import {TableRowActions} from '../';
import {currentPathSelector, links} from '../../utils/gotoLink';
import {history} from '../../store';
import {connect} from 'react-redux';

export class TATransactionsSearchTable extends Component {
  static propTypes = {
    tsTransactions: PropTypes.array,
    totalElements: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    pageSize: PropTypes.number.isRequired,
    searchAccountGOSTransactions: PropTypes.func.isRequired,
    currentPath: PropTypes.string.isRequired,
  };

  onPageChange = page => {
    const {searchAccountGOSTransactions} = this.props;

    searchAccountGOSTransactions(page);
  };

  getColumns = () => {
    const {t, currentPath} = this.props;
    const columns = [
      {
        title: t('transactions.table.transactionType'),
        dataIndex: tradingAccountTransactionsFields.TRANSACTION_TYPE,
        render: translateGosTransactionTypeClassificator,
      },
      {
        title: t('transactions.table.transactionType'),
        dataIndex: tradingAccountTransactionsFields.TYPE,
      },
      {
        title: t('transactions.table.quantity'),
        dataIndex: tradingAccountTransactionsFields.TRANSACTION_AMOUNT_MJ,
        render: (amount, {type}) => {
          if (type !== TYPE_KHG) {
            return amount;
          }
        }
      },
      {
        title: t('transactions.table.ghgQuantity'),
        render: (_, {transactionAmountMj, type}) => {
          if (type === TYPE_KHG) {
            return transactionAmountMj;
          }
        }
      },
      {
        title: t('transactions.table.sender'),
        dataIndex: tradingAccountTransactionsFields.SENDER,
      },
      {
        title: t('transactions.table.recipient'),
        dataIndex: tradingAccountTransactionsFields.RECEIVER,
      },
      {
        title: t('transactions.table.transactionDate'),
        dataIndex: tradingAccountTransactionsFields.TRANSACTION_DATE_TIME,
      },
      {
        title: t('transactions.table.transactionStatus'),
        dataIndex: tradingAccountTransactionsFields.TRANSACTION_STATUS,
        render: translateTransactionForGosStatusClassificator,
      },
      {
        title: t('transactions.table.details'),
        dataIndex: tradingAccountTransactionsFields.TRANSACTION_ID,
        render: (txId, {type}) => {
          return (
            <TableRowActions>
              <Button
                shape="circle"
                icon="select"
                size="small"
                type="primary"
                title={t('transactions.table.viewBtn')}
                onClick={() => {
                  history.push({
                    pathname: links.tsTransactionView(txId, type),
                    state: {prevPath: currentPath},
                  });
                }}
              />
            </TableRowActions>
          );
        },
        fixed: 'right',
        width: 100,
      },
    ];

    return columns;
  };

  render() {
    const {
      totalElements,
      tsTransactions,
      loading,
      page,
      pageSize,
    } = this.props;

    return (
      <Spin spinning={loading}>
        <Table
          scroll={{x: 1000}}
          rowKey={tradingAccountTransactionsFields.ID}
          dataSource={tsTransactions}
          columns={this.getColumns()}
          pagination={{
            onChange: this.onPageChange,
            current: page,
            total: totalElements,
            pageSize,
            defaultCurrent: 1,
          }}
        />
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  currentPath: currentPathSelector(state),
});

export default connect(mapStateToProps)(TATransactionsSearchTable);
