import {createAsyncAction} from '../../utils/asyncHelpers';
import * as actionTypes from '../../constants/actionTypes/consumerChart';
import {
  consumerChartSellerFetch,
  consumerChartYearFetch,
  consumerSellersFetch,
} from '../actionHelpers/endpoints';

export const fetchConsumerChartYear = createAsyncAction(
  actionTypes.CONSUMER_CHART_YEAR_FETCH,
  consumerChartYearFetch
);

export const fetchConsumerChartSeller = createAsyncAction(
  actionTypes.CONSUMER_CHART_SELLER_FETCH,
  consumerChartSellerFetch
);

export const fetchConsumerSellers = createAsyncAction(
  actionTypes.CONSUMER_SELLERS_FETCH,
  consumerSellersFetch
);
