import React from 'react';
import {Layout} from 'antd';
import {MarketParticipants as MarketParticipantsContainer} from '../../containers';

import './MarketParticipants.css';

export default class MarketParticipants extends React.Component {
  render() {
    return (
      <Layout className="main-container">
        <MarketParticipantsContainer/>
      </Layout>
    );
  }
}
