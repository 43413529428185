import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import PropTypesImmutable from 'react-immutable-proptypes';
import {reduxForm} from 'redux-form/immutable';
import {METERING_POINT_FORM} from '../../constants/formMeteringPoint';
import {meteringPointFields} from '../../constants/meteringPoint';
import {
  meteringPointDataSource,
  meteringPointType,
} from '../../constants/classificators';
import {
  updateMeteringPoint,
  updateConsumptionPoint,
} from '../../actions/actionCreators/meteringPointActionCreators';
import ConsumptionPoint from './ConsumptionPoint';
import ProductionPoint from './ProductionPoint';
import validate from './validateSchema';

class MeteringPointMain extends Component {
  static propTypes = {
    initialValues: PropTypesImmutable.map,
    t: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  render() {
    const {initialValues, t, isAdmin, handleSubmit} = this.props;
    const isAvp =
      initialValues.get(meteringPointFields.DATA_SOURCE) ===
      meteringPointDataSource.AVP;
    const pointType = initialValues.get(
      meteringPointFields.METERING_POINT_TYPE
    );
    const productionUnit =
      initialValues.get(meteringPointFields.PRODUCTION_UNIT) &&
      initialValues.get(meteringPointFields.PRODUCTION_UNIT).toJS();

    return (
      <form onSubmit={handleSubmit}>
        {pointType === meteringPointType.PRODUCTION ? (
          <ProductionPoint
            t={t}
            isAvp={isAvp}
            isAdmin={isAdmin}
            productionUnit={productionUnit}
          />
        ) : (
          <ConsumptionPoint t={t} isAvp={isAvp} isAdmin={isAdmin}/>
        )}
      </form>
    );
  }
}

const onSubmit = (values, dispatch, ownProps) => {
  if (
    ownProps.initialValues.get(meteringPointFields.METERING_POINT_TYPE) ===
    meteringPointType.PRODUCTION
  ) {
    dispatch(updateMeteringPoint(values));
  } else {
    dispatch(updateConsumptionPoint(values));
  }
};

const mapStateToProps = (state, ownProps) => ({
  initialValues: ownProps.meteringPoint,
});

export default connect(mapStateToProps)(
  reduxForm({
    form: METERING_POINT_FORM,
    enableReinitialize: true,
    onSubmit,
    validate,
  })(MeteringPointMain)
);
