import React from 'react';
import PropTypes from 'prop-types';
import {Layout} from 'antd';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import {PreTradingAgreementViewContainer} from "../../containers";

const PreTradingAgreementView = ({match: {params: {agreementId, registerCode}}, t}) => (
  <Layout className="main-container">
    <PreTradingAgreementViewContainer agreementId={agreementId} registerCode={registerCode} t={t}/>
  </Layout>
);

PreTradingAgreementView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      agreementId: PropTypes.string.isRequired,
      registerCode: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default translate(NAMESPACES.agreementView)(PreTradingAgreementView);
