import React from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {withRouter} from 'react-router-dom';
import {intersection} from 'lodash';
import {connect} from 'react-redux';
import {Error403} from '../../pages';
import {Spin} from 'antd';
import {isAdmin, isConsumer} from '../../utils/roles';
import {accountFields} from '../../constants/account';
import {accountStatus} from '../../constants/classificators';
import * as userActions from '../../actions/actionCreators/userActionCreators';
import * as actions from '../../actions/actionCreators/settingsActionCreators';
import i18n from '../../i18n';
import {
  getRole,
  getUserPersonalCode,
  getUserLegalEntityAccounts,
  isLegalEntityActive,
} from '../../reducers/userReducer';

const mapEleringPortalLocales = {
  'en-US': 'en',
  'et-EE': 'et',
};

const Authorization = (
  allowedRoles,
  allowAccounts,
  activeOnly = true,
  ErrorComponent = Error403
) => WrappedComponent => {
  class WithAuthorization extends React.Component {
    static propTypes = {
      personalCode: PropTypes.string,
      fetchCurrentUser: PropTypes.func.isRequired,
      fetchCurrentMandate: PropTypes.func.isRequired,
      role: PropTypes.string,
      accounts: PropTypesImmutable.list.isRequired,
      isActive: PropTypes.bool.isRequired,
      match: PropTypes.shape({
        params: PropTypes.shape({
          locale: PropTypes.string,
        }),
      }),
      changeLocale: PropTypes.func.isRequired,
    };

    componentDidMount = () => {
      const {
        personalCode,
        fetchCurrentMandate,
        fetchCurrentUser,
        changeLocale,
        match: {params: {locale}},
      } = this.props;

      if (!personalCode) {
        fetchCurrentUser();
        fetchCurrentMandate();
      }

      if (locale && mapEleringPortalLocales[locale]) {
        i18n.changeLanguage(mapEleringPortalLocales[locale], () =>
          changeLocale(mapEleringPortalLocales[locale])
        );
      }
    };

    render() {
      const {role, personalCode, accounts, isActive} = this.props;

      const activeAccountTypes = accounts
        .filter(
          account => account.get(accountFields.STATUS) === accountStatus.ACTIVE
        )
        .map(account => account.get(accountFields.TYPE))
        .toJS();

      if (!personalCode || !role) {
        return <Spin/>;
      }

      if (
        (!activeOnly || isActive || isAdmin(role) || isConsumer(role)) &&
        allowedRoles.includes(role) &&
        (!allowAccounts ||
          isAdmin(role) ||
          isConsumer(role) ||
          intersection(allowAccounts, activeAccountTypes).length > 0)
      ) {
        return <WrappedComponent {...this.props} />;
      }
      return <ErrorComponent/>;
    }
  }

  const mapStateToProps = state => ({
    role: getRole(state),
    personalCode: getUserPersonalCode(state),
    accounts: getUserLegalEntityAccounts(state),
    isActive: isLegalEntityActive(state),
  });

  const mapDispatchToProps = {
    ...userActions,
    changeLocale: actions.changeLocaleActionCreator,
  };

  return connect(mapStateToProps, mapDispatchToProps)(
    withRouter(WithAuthorization)
  );
};

export default Authorization;
