import moment from 'moment';
import {FORMAT_DEFAULT, FORMAT_DEFAULT_DATE, FORMAT_DEFAULT_MONTH} from '../../utils';
import {customMap, customMapFields} from './mapError';

export const textFieldMap = customMap(({input: {onChange}}) => ({
  onChange: v => onChange(v.nativeEvent.target.value),
}));

export const autoCompleteFieldMap = customMap(({input: {onChange}}) => ({
  onChange,
}));

const _parseDataValue = value => {
  if (value !== '') {
    return moment(value, FORMAT_DEFAULT);
  }
  return undefined;
};

const _parseMonthValue = value => {
  if (value !== '') {
    return moment(value, FORMAT_DEFAULT_MONTH);
  }
  return undefined;
};

const _parseMomentValue = (value, format = FORMAT_DEFAULT) => {
  if (moment.isMoment(value)) {
    return value.format(format);
  }
  if (value) {
    return value;
  }
  return null;
};

export const datePickerMap = hasTime =>
  customMap(({input: {value, onChange}}) => {
    const parsedValue = _parseDataValue(value);

    return {
      onChange: date => {
        onChange(_parseMomentValue(date));
      },
      format: hasTime ? FORMAT_DEFAULT : FORMAT_DEFAULT_DATE,
      value: parsedValue,
    };
  });

export const checkboxMap = customMap(({input: {value}}) => ({
  checked: value || false,
}));

export const monthYearMap = customMapFields(fields => {
  const {names, ...props} = fields;
  let value;

  const {input: inputField1} = fields[names[0]];

  const {input: inputField2} = fields[names[1]];

  if (inputField1.value && inputField2.value) {
    value = moment([inputField2.value, inputField1.value - 1]);
  }

  const onChange = date => {
    if (date) {
      inputField1.onChange(date.month() + 1);
      inputField2.onChange(date.year());
    } else {
      inputField1.onChange(null);
      inputField2.onChange(null);
    }
  };

  return {
    onChange,
    value,
    ...props,
  };
});

export const dateRangeNoTimeMap = customMapFields(fields => {
  const {names, dateFormat = FORMAT_DEFAULT_DATE, ...props} = fields;
  const value = [];

  const {input: inputField1} = fields[names[0]];

  const {input: inputField2} = fields[names[1]];

  value.push(_parseDataValue(inputField1.value));
  value.push(_parseDataValue(inputField2.value));

  const onChange = dates => {
    inputField1.onChange(_parseMomentValue(dates[0], dateFormat));
    inputField2.onChange(_parseMomentValue(dates[1], dateFormat));
  };

  return {
    onChange,
    value,
    format: dateFormat,
    ...props,
  };
});

export const dateRangeMap = customMapFields(fields => {
  const {names, dateFormat = FORMAT_DEFAULT, ...props} = fields;
  const value = [];

  const {input: inputField1} = fields[names[0]];

  const {input: inputField2} = fields[names[1]];

  value.push(_parseDataValue(inputField1.value));
  value.push(_parseDataValue(inputField2.value));

  const onChange = dates => {
    inputField1.onChange(_parseMomentValue(dates[0], dateFormat));
    inputField2.onChange(_parseMomentValue(dates[1], dateFormat));
  };

  return {
    onChange,
    value,
    format: dateFormat,
    ...props,
  };
});

export const monthRangeMap = customMapFields(fields => {
  const {names, dateFormat = FORMAT_DEFAULT_MONTH, ...props} = fields;
  const value = [];

  const {input: inputField1} = fields[names[0]];

  const {input: inputField2} = fields[names[1]];

  value.push(_parseMonthValue(inputField1.value));
  value.push(_parseMonthValue(inputField2.value));

  const onChange = dates => {
    inputField1.onChange(_parseMomentValue(dates[0], dateFormat));
    inputField2.onChange(_parseMomentValue(dates[1], dateFormat));
  };

  const onPanelChange = (dates, mode) => {
    inputField1.onChange(_parseMomentValue(dates[0], dateFormat));
    inputField2.onChange(_parseMomentValue(dates[1], dateFormat));
  };

  return {
    onChange,
    onPanelChange,
    value,
    format: dateFormat,
    mode: ['month', 'month'],
    ...props,
  };
});
