import {
  createAsyncReducer,
  createAsyncSelector,
  listInitialAsyncState,
} from '../utils/asyncHelpers';
import {handleActions} from 'redux-actions';
import {accountGOSAuctionsSchema} from "../schemas";
import {createAsyncPagingReducer, listInitialAsyncPagingState,} from '../utils/asyncPagingHelpers';
import {Map} from "immutable";
import {
  ACCOUNT_GOS_TS_AUCTIONS_LIST_FETCH,
  COMPLETED_TS_AUCTIONS_RESULT_FETCH,
  REFRESH_SEARCH_TIMER
} from "../constants/accountGOSListActionTypes";

const initialState = Map({
  list: listInitialAsyncPagingState,
  refreshAuctionsTimer: 30,
  completedAuctionsResult: listInitialAsyncState
});

const accountGOSAuctionsList = handleActions(
  {
    ...createAsyncPagingReducer(ACCOUNT_GOS_TS_AUCTIONS_LIST_FETCH, 'list'),
    ...createAsyncReducer(COMPLETED_TS_AUCTIONS_RESULT_FETCH, 'completedAuctionsResult'),
    [REFRESH_SEARCH_TIMER]: (state, action) => {
      const seconds = action.payload || 30;
      return state.set('refreshAuctionsTimer', seconds);
    },
  },
  initialState
);

export const getAccountGOSAuctionsList = state => {
  return state.get('accountGOSAuctionsList').get('list');
};

const sortEndDates = data => {
  const result = [];
  Object.entries(Object.groupBy(data, i => i.endDate)).forEach(([endDate, items]) => {
    Object.entries(Object.groupBy(items, i => i.fuelType)).forEach(([fuelType, items]) => {
      for (let i = 0; i < items.length; i++) {
        if (items[i]) {
          items[i]['endDate'] = (items[i]['endDate'] + i);
          result.push(items[i]);
        }
      }
    });
  });
  return result;
}

export const getCompletedAuctionsResultData = state => {
  const data = state.getIn(['accountGOSAuctionsList', 'completedAuctionsResult', 'data']).toJS();
  const result = [];

  Object.entries(Object.groupBy(sortEndDates(data), i => i.endDate)).forEach(([endDate, items]) => {
    const dateAggregate = {};
    dateAggregate['endDate'] = endDate;
    items.forEach(item => {
      dateAggregate[item.fuelType] = item.bidAmount;
    })
    result.push(dateAggregate);
  });

  return result;
}

export const getRefreshTimer = state => {
  return state.get('accountGOSAuctionsList').get('refreshAuctionsTimer');
};

export const getAccountGOSAuctionsListData = createAsyncSelector(
  [accountGOSAuctionsSchema],
  getAccountGOSAuctionsList
);

export default accountGOSAuctionsList;
