export const tradingPlatformAgreementsTable = {
  ID: 'id',
  status: 'status',
  queueNo: 'queueNo',
  buyer: 'buyerName',
  seller: 'sellerName',
  finalConsumptionYear: 'finalConsumptionYear',
  fuelType: 'fuelType',
  fuelGeneration: 'fuelGeneration',
  ghgCapacity: 'ghgCapacity',
  agreementType: 'agreementType',
  naturalEnergyAmount: 'naturalAmountMj',
  calculatedEnergyAmount: 'calculatedAmountMj',
  fulfilledPercentage: 'fulfilledPercentage',
  actions: 'actions'
}