import React from 'react';
import PropTypes from 'prop-types';
import {InvoiceFilter} from '../../forms';
import {InvoiceList} from '../../containers';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';

const InvoiceSearch = props => (
  <div className="main-container">
    <h1>{props.t('title')}</h1>
    <InvoiceFilter {...props} />
    <InvoiceList {...props} />
  </div>
);

InvoiceSearch.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(NAMESPACES.invoiceSearch)(InvoiceSearch);
