import {normalizeHelper} from '../../utils/asyncHelpers';
import {
  createAsyncActionStoreWrapper,
  createAsyncPagingAction,
} from '../../utils/asyncPagingHelpers';
import {ACCOUNT_GOS_LIST_FETCH} from '../../constants/accountGOSListActionTypes';
import {searchTAEntries as accountGOSSearch} from '../actionHelpers/endpoints';
import accountGOSSchema from '../../schemas/accountGOS';
import {getFormValues} from 'redux-form/immutable';
import {getAccountGOSList} from '../../reducers/accountGOSListReducer';
import {FORM_ACCOUNT_GOS_FILTER} from '../../constants/formAccountGOSFilter';

const _searchAccountGOS = createAsyncPagingAction(
  ACCOUNT_GOS_LIST_FETCH,
  accountGOSSearch,
  normalizeHelper([accountGOSSchema])
);

const filterFormSelector = getFormValues(FORM_ACCOUNT_GOS_FILTER);

export const searchAccountGOS = createAsyncActionStoreWrapper(
  _searchAccountGOS,
  filterFormSelector,
  getAccountGOSList
);
