import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {connect} from 'react-redux';
import {
  getClassifiersGridOperatorList,
  getClassifiersGridOperatorListData,
  getClassifiersGridOperatorDelete,
} from '../../reducers/classifiersReducer';
import {
  showParameterGridOperatorEditModal,
  hideParameterGridOperatorEditModal,
  getParameterGridOperatorEditModal,
} from '../../actions/actionCreators/modalsActionCreators';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {Spin, Modal, Button} from 'antd';
import * as actions from '../../actions/actionCreators/classifiersActionCreators';
import {ParameterGridOperatorTable, TableActions} from '../../components';
import {ParameterGridOperatorEdit as ParameterGridOperatorEditForm} from '../../forms';
import {goToClassifiers} from '../../utils/gotoLink';

export class ParameterGridOperatorList extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    fetchClassifier: PropTypes.func.isRequired,
    deleteClassifier: PropTypes.func.isRequired,
    classifierData: PropTypesImmutable.list,
    modalVisible: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    const {fetchClassifier} = this.props;
    fetchClassifier();
  };

  selectedCode = null;

  render() {
    const {
      classifierData,
      loading,
      t,
      deleteClassifier,
      modalVisible,
      hideModal,
      showModal,
    } = this.props;

    return (
      <Spin spinning={loading}>
        <h1>{t('parameterGridOperatorTitle')}</h1>
        {classifierData && (
          <ParameterGridOperatorTable
            t={t}
            dataSource={classifierData.toJS()}
            deleteClassifier={deleteClassifier}
            showModal={code => {
              this.selectedCode = code;
              showModal();
            }}
          />
        )}
        {modalVisible && (
          <Modal
            title={t('modalGridOperatorTitle')}
            visible
            footer={null}
            onCancel={hideModal}
            width={640}
          >
            <ParameterGridOperatorEditForm
              onCancel={hideModal}
              onComplete={hideModal}
              operatorCode={this.selectedCode}
              t={t}
            />
          </Modal>
        )}
        <TableActions>
          <Button onClick={goToClassifiers}>{t('buttonBack')}</Button>
          <Button
            type="primary"
            onClick={() => {
              this.selectedCode = null;
              showModal();
            }}
            className="global-margin-left-10"
          >
            {t('buttonValueAdd')}
          </Button>
        </TableActions>
      </Spin>
    );
  }
}

const getIsLoading = getLoadingSelector(
  getClassifiersGridOperatorList,
  getClassifiersGridOperatorDelete
);

const mapStateToProps = state => ({
  loading: getIsLoading(state),
  classifierData: getClassifiersGridOperatorListData(state),
  modalVisible: getParameterGridOperatorEditModal(state),
});

const mapDispatchToProps = dispatch => ({
  fetchClassifier: () => {
    dispatch(actions.fetchClassifierGridOperatorList());
  },
  deleteClassifier: async code => {
    await dispatch(actions.deleteClassifierGridOperator(code));
    dispatch(actions.fetchClassifierGridOperatorList());
  },
  showModal: () => {
    dispatch(showParameterGridOperatorEditModal());
  },
  hideModal: () => {
    dispatch(hideParameterGridOperatorEditModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  ParameterGridOperatorList
);
