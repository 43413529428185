import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getRole} from '../../reducers/userReducer';
import {isAdmin} from '../../utils/roles';
import {LoadingUnitEdit} from '../../containers';

export class LoadingUnitView extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        loadingUnitId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    userRole: PropTypes.node.isRequired,
  };

  render() {
    const {userRole, match: {params: {loadingUnitId}}} = this.props;

    return (
      <div className="main-container">
        <LoadingUnitEdit
          isAdmin={isAdmin(userRole)}
          isEditMode
          loadingUnitId={loadingUnitId}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userRole: getRole(state),
});

export default connect(mapStateToProps)(LoadingUnitView);
