import {
  initialAsyncState,
  createAsyncReducer,
  createAsyncSelector,
} from '../utils/asyncHelpers';
import {handleActions} from 'redux-actions';
import {
  TRANSACTION_FETCH,
  TRANSACTION_RECALL,
} from '../constants/actionTypes/transactionViewActionTypes';
import {transactionSchema} from '../schemas';
import {Map} from 'immutable';

const initialState = Map({
  view: initialAsyncState,
  recall: initialAsyncState,
});

const transactionView = handleActions(
  {
    ...createAsyncReducer(TRANSACTION_FETCH, 'view'),
    ...createAsyncReducer(TRANSACTION_RECALL, 'recall'),
  },
  initialState
);

export const getTransaction = state => state.getIn(['transactionView', 'view']);

export const getTransactionData = createAsyncSelector(
  transactionSchema,
  getTransaction
);

export const getTransactionRecall = state =>
  state.getIn(['transactionView', 'recall']);

export default transactionView;
