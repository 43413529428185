import {handleActions} from 'redux-actions';
import {Map, List, fromJS} from 'immutable';
import {createSelector} from 'reselect';
import {
  createAsyncReducer,
  listInitialAsyncState,
  createAsyncSelector,
  getEntityById,
} from '../utils/asyncHelpers';
import {goAmountTableFields} from '../constants/cancelingCertificates';
import * as actionTypes from '../constants/actionTypes/cancelingCertificates';
import * as actions from '../actions/pureActions/cancelingCertificates';
import {
  ACCOUNT_CANCEL_GROUPED_CERTIFICATES_FETCH
} from '../constants/actionTypes/accountActionTypes';
import {measurement as meteringPointScheme} from '../schemas';

const initialState = Map({
  ui: Map({consumer: null, pointId: null, periods: List()}),
  points: listInitialAsyncState,
  gos: listInitialAsyncState,
  certificates: listInitialAsyncState,
  isCancellingCertificates: listInitialAsyncState,
});

const cancelingCertificatesReducer = handleActions(
  {
    ...createAsyncReducer(actionTypes.CONSUMPTION_POINTS_FETCH, 'points'),
    ...createAsyncReducer(actionTypes.CANCEL_CERTIFICATES, 'isCancellingCertificates'),
    ...createAsyncReducer(
      ACCOUNT_CANCEL_GROUPED_CERTIFICATES_FETCH,
      'certificates'
    ),
    [actions.resetAction]: state =>
      state.merge({
        ui: Map({consumer: null, pointId: null, periods: List()}),
        points: listInitialAsyncState,
        gos: listInitialAsyncState,
      }),
    ...createAsyncReducer(actionTypes.GO_AMOUNT_FETCH, 'gos'),
    [actions.selectConsumerAction]: (state, {payload}) =>
      state.merge({
        ui: {consumerId: payload, pointId: null, periods: List()},
        points: listInitialAsyncState,
      }),
    [actions.selectPointAction]: (state, {payload}) =>
      state.setIn(['ui', 'pointId'], payload),
    [actions.selectPeriodsAction]: (state, {payload}) =>
      state.setIn(['ui', 'periods'], fromJS(payload)),
  },
  initialState
);

export default cancelingCertificatesReducer;

export const getConsumerId = state =>
  state.getIn(['cancelingCertificates', 'ui', 'consumerId']);
export const getPointId = state =>
  state.getIn(['cancelingCertificates', 'ui', 'pointId']);
export const getPoints = state =>
  state.getIn(['cancelingCertificates', 'points']);
export const getGoAmount = state =>
  state.getIn(['cancelingCertificates', 'gos']);
export const getIsCancellingCertificates = state =>
  state.getIn(['cancelingCertificates', 'isCancellingCertificates']);
export const getGoAmountData = state =>
  state.getIn(['cancelingCertificates', 'gos', 'data']);
export const getPointsData = createAsyncSelector(
  [meteringPointScheme],
  getPoints
);
export const getCertificates = state =>
  state.getIn(['cancelingCertificates', 'certificates']);
export const getCertificatesData = state =>
  state.getIn(['cancelingCertificates', 'certificates', 'data']);
export const getPeriods = state =>
  state.getIn(['cancelingCertificates', 'ui', 'periods']);
export const getGoAmountTotal = createSelector(
  [getGoAmountData, getPeriods],
  (gos, selectedPeriods) => {
    const selectedMonths = selectedPeriods.map(period =>
      period.get(goAmountTableFields.MONTH)
    );
    return gos
      .filter(go => selectedMonths.includes(go.get(goAmountTableFields.MONTH)))
      .reduce(
        (sum, go) => sum + go.get(goAmountTableFields.AVAILABLE_AMOUNT),
        0
      );
  }
);
export const getSelectedPoint = state =>
  getEntityById(state, meteringPointScheme, getPointId(state) || 0);
