import {
  createAsyncActionStoreWrapper,
  createAsyncPagingAction,
} from '../../utils/asyncPagingHelpers';
import {getFormValues} from 'redux-form/immutable';
import {
  FORM_ACCOUNT_GOS_TS_ASSIGNMENTS_FILTER
} from "../../constants/formAccountGOSTsAssignmentsFilter";
import {
  ACCOUNT_GOS_TS_ASSIGNMENTS_COUNT_FETCH,
  ACCOUNT_GOS_TS_ASSIGNMENTS_LIST_FETCH
} from "../../constants/accountGOSListActionTypes";
import {getAccountGOSTsAssignmentsList} from "../../reducers/accountGOSTsAssignmentsListReducer";
import {
  getGOSTsAssignmentCount,
  searchAccountGOSTsAssignments as accountGOSTsAssignmentsSearch
} from '../actionHelpers/endpoints';
import {createAsyncAction} from "../../utils/asyncHelpers";


export const searchAccountGOSTsAssignments = createAsyncActionStoreWrapper(
  createAsyncPagingAction(
    ACCOUNT_GOS_TS_ASSIGNMENTS_LIST_FETCH,
    accountGOSTsAssignmentsSearch
  ),
  getFormValues(FORM_ACCOUNT_GOS_TS_ASSIGNMENTS_FILTER),
  getAccountGOSTsAssignmentsList
);

export const fetchAccountGOSTsAssignmentCount = createAsyncAction(
  ACCOUNT_GOS_TS_ASSIGNMENTS_COUNT_FETCH,
  getGOSTsAssignmentCount,
);
