// @flow

import moment from 'moment';

export const FORMAT_DEFAULT_DATE = 'DD.MM.YYYY';
export const FORMAT_DEFAULT_TIME = 'HH:mm:ss';
export const FORMAT_DEFAULT_MONTH = 'MM.YYYY';
export const FORMAT_DEFAULT = `${FORMAT_DEFAULT_DATE} ${FORMAT_DEFAULT_TIME}`;

export const formatDate = (
  date: string,
  formatTo: string,
  formatFrom: string = FORMAT_DEFAULT
) => (date ? moment(date, formatFrom).format(formatTo) : '');

export const createDateFormater = (
  formatTo: string = FORMAT_DEFAULT_DATE,
  formatFrom: string = FORMAT_DEFAULT
) => (date: string) => formatDate(date, formatTo, formatFrom);

export const defaultDateFormater = createDateFormater();
export const monthFormater = createDateFormater(FORMAT_DEFAULT_MONTH);

export const getDateFromDateTime = (
  date: string,
  formatTo: string = FORMAT_DEFAULT_DATE
) => formatDate(date, formatTo);

export const getTimeFromDateTime = (
  date: string,
  formatTo: string = FORMAT_DEFAULT_TIME
) => formatDate(date, formatTo);

export const getTodaysDateStr = () => {
  const td = new Date();

  const tmpDay = td.getDate();
  const day = tmpDay < 10 ? "0" + tmpDay : tmpDay;
  const tmpMonth = td.getMonth() + 1;
  const month = tmpMonth < 10 ? "0" + tmpMonth : tmpMonth;
  const year = td.getFullYear();

  const today = day + "." + month + "." + year + " 00:00:00";

  return today;
}
