import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { Spin, Modal, Button, message } from 'antd';
import { List } from 'immutable';
import {
  ProductionUnitsTable,
  TableActions,
  RegisterActions,
} from '../../components';
import {
  showProductionUnitModal,
  hideProductionUnitModal,
  showMeasuringUploadModal,
  hideMeasuringUploadModal,
  getMeasuringUploadModal,
} from '../../actions/actionCreators/modalsActionCreators';
import * as actions from '../../actions/actionCreators/productionUinitActionCreators';
import { modalNames } from '../../constants/ModalsActionTypes';
import { getModalState } from '../../reducers/modalsReducer';
import { getLoadingSelector } from '../../utils/asyncHelpers';
import { AddEquipment, AddEquipmentEdit } from '../';
import { formRegisterGeneralFields } from '../../constants/formRegister';
import { legalEntityFields } from '../../constants/legalEntity';
import * as productionUnitSelectors from '../../reducers/legalEntityProductionUnitsReducer';
import { fuelType } from '../../constants/classificators';
import { productionUnitFilterFields } from '../../constants/formProductionUnitFilter';

export class RegisterProductionUnits extends Component {
  static propTypes = {
    productionUnits: PropTypesImmutable.list,
    modalAddEquipmentVisible: PropTypes.bool.isRequired,
    legalEntity: PropTypesImmutable.map.isRequired,
    productionUnitsState: PropTypesImmutable.map.isRequired,
    fetchLegalEntityProductionUnitsPage: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    productionUnitId: PropTypes.number,
    showRequestChangeModal: PropTypes.func,
    queryParams: PropTypes.object,
    isHydrogenContext: PropTypes.bool,
  };

  static defaultProps = {
    productionUnits: List(),
  };

  componentDidMount = () => {
    const {
      fetchLegalEntityProductionUnitsPage,
      legalEntity,
      productionUnitsState,
      queryParams,
      startEditProductionUnit,
      isHydrogenContext,
    } = this.props;

    fetchLegalEntityProductionUnitsPage({
      filter: {
        legalEntityId: legalEntity.get(formRegisterGeneralFields.ID),
        [productionUnitFilterFields.PRODUCTION_DEVICE_TYPE]: isHydrogenContext
          ? fuelType.HYDROGEN
          : fuelType.BIOMETHANE,
      },
      pager: {
        page: productionUnitsState.get('page') - 1,
        size: productionUnitsState.get('pageSize'),
      },
    });

    if (queryParams && queryParams.productionUnitId) {
      startEditProductionUnit(queryParams.productionUnitId);
    }
  };

  goNext = () => {
    const { onSubmitSuccess, productionUnits, t } = this.props;

    if (productionUnits.isEmpty()) {
      message.error(t('productionUnit.emptyError'));
    } else {
      onSubmitSuccess();
    }
  };

  render() {
    const {
      t,
      productionUnits,
      modalAddEquipmentVisible,
      startAddProductionUnit,
      hideModal,
      hasPrevious,
      hasDone,
      goPrevious,
      legalEntity,
      isLoading,
      startEditProductionUnit,
      deleteProductionUnit,
      isEditMode,
      isAdmin,
      hideImportModal,
      showImportModal,
      stateImportModal,
      productionUnitId,
      isProductionUnitAdd,
      productionUnitsState,
      showRequestChangeModal,
      fetchLegalEntityProductionUnitsPage,
      queryParams,
      isHydrogenContext,
    } = this.props;

    const inEditForNotAdmin = isEditMode && !isAdmin;
    const modalCancelProps = isProductionUnitAdd
      ? {
          onCancel: () => {
            deleteProductionUnit(productionUnitId);
            hideModal();
          },
        }
      : { onCancel: hideModal };

    const productionUnitTab =
      queryParams && queryParams.productionUnitTab
        ? queryParams.productionUnitTab
        : '1';

    return (
      <Spin spinning={isLoading}>
        <h2 className="register-management-container__subtitle">
          {inEditForNotAdmin
            ? isHydrogenContext
              ? t('productionUnit.hydrogenTitleView')
              : t('productionUnit.titleView')
            : isHydrogenContext
              ? t('productionUnit.hydrogenTitle')
              : t('productionUnit.title')}
        </h2>
        <ProductionUnitsTable
          t={t}
          data={productionUnits.toJS()}
          onEdit={startEditProductionUnit}
          onDelete={deleteProductionUnit}
          isAdmin={isAdmin}
          isEditMode={isEditMode}
          hideImportModal={hideImportModal}
          showImportModal={showImportModal}
          stateImportModal={stateImportModal}
          legalEntityId={legalEntity.get(formRegisterGeneralFields.ID)}
          isHydrogenContext={isHydrogenContext}
          fetchLegalEntityProductionUnitsPage={
            fetchLegalEntityProductionUnitsPage
          }
          {...productionUnitsState.delete('data').toJS()}
        />
        {(isEditMode || productionUnits.isEmpty()) && (
          <TableActions>
            <Button
              onClick={startAddProductionUnit}
              size="large"
              type="primary"
            >
              {isHydrogenContext
                ? t('productionUnit.addHydro')
                : t('productionUnit.add')}
            </Button>
          </TableActions>
        )}
        {modalAddEquipmentVisible && (
          <Modal
            {...modalCancelProps}
            visible
            footer={false}
            title={
              isHydrogenContext
                ? t('productionUnit.addHydroEquipmentTitle')
                : t('productionUnit.addEquipmentTitle')
            }
            width="80%"
          >
            {isEditMode && !isProductionUnitAdd ? (
              <AddEquipmentEdit
                legalEntity={legalEntity}
                legalEntityId={legalEntity.get(legalEntityFields.ID)}
                isAdmin={isAdmin}
                isEditMode={isEditMode}
                productionUnitId={productionUnitId}
                queryParams={{ tab: productionUnitTab }}
                isHydrogenContext={isHydrogenContext}
              />
            ) : (
              <AddEquipment
                onSubmitSuccess={hideModal}
                legalEntity={legalEntity}
                legalEntityId={legalEntity.get(legalEntityFields.ID)}
                isAdmin={isAdmin}
                isEditMode={false}
                isProductionUnitAdd={isProductionUnitAdd}
                selectProductionUnitOwner={false}
                isHydrogenContext={isHydrogenContext}
              />
            )}
          </Modal>
        )}
        <RegisterActions
          showRequestChangeModal={showRequestChangeModal}
          hasPrevious={hasPrevious}
          hasDone={hasDone}
          goPrevious={goPrevious}
          goNext={this.goNext}
          isEditMode={isEditMode}
          isAdmin={isAdmin}
          showSaveButton={!isEditMode}
          t={t}
        />
      </Spin>
    );
  }
}

const mapStateToProps = state => {
  const modalAddEquipmentVisible = getModalState(
    state,
    modalNames.PRODUCTION_UNIT
  );

  return {
    modalAddEquipmentVisible,
    productionUnits: productionUnitSelectors.getProductionUnitListEntities(
      state
    ),
    productionUnitsState: productionUnitSelectors.getProductionUnitList(state),
    isLoading: getLoadingSelector(
      productionUnitSelectors.getProductionUnitList,
      productionUnitSelectors.getProductionUnitsAddState,
      productionUnitSelectors.getProductionUnitsEditState,
      productionUnitSelectors.getProductionUnitsDeleteState
    )(state),
    stateImportModal: getMeasuringUploadModal(state),
    productionUnitId: productionUnitSelectors.getProductionUnitsEditId(state),
    isProductionUnitAdd: productionUnitSelectors.getIsAddMode(state),
  };
};

const mapDispatchToProps = {
  hideModal: hideProductionUnitModal,
  showModal: showProductionUnitModal,
  showImportModal: showMeasuringUploadModal,
  hideImportModal: hideMeasuringUploadModal,
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  RegisterProductionUnits
);
