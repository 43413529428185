import {createValidation, validatorRequired} from '../../utils/formValidators';

import {invoiceFields} from '../../constants/invoice';

export default createValidation([
  {
    name: invoiceFields.NUMBER,
    validators: validatorRequired(),
  },
  {
    name: invoiceFields.DATE,
    validators: validatorRequired(),
  },
]);
