import React, { Component } from 'react';
import { Table, Button, Modal } from 'antd';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  generalFormFields,
  technicalInformationFormFields,
} from '../../constants/formsAddEquipment';
import { MeasuringUpload } from '../../containers';
import { productionUnitFields } from '../../constants/productionUnit';
import { meteringPointFields } from '../../constants/meteringPoint';
import {
  fuelType,
  meteringPointDataSource,
  productionUnitStatus,
} from '../../constants/classificators';
import {
  translateCleaningTechnologyClassificator,
  translateHydrogenTechnologyClassificator,
  translateProductionUnitStatusClassificator,
} from '../../utils/translateHelpers';
import { productionUnitFilterFields } from '../../constants/formProductionUnitFilter';

class ProductionUnitsTable extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    hideImportModal: PropTypes.func.isRequired,
    showImportModal: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    stateImportModal: PropTypes.bool,
    onEdit: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    totalElements: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    fetchLegalEntityProductionUnitsPage: PropTypes.func.isRequired,
    legalEntityId: PropTypes.number.isRequired,
    isHydrogenContext: PropTypes.bool,
  };

  onPageChange = page => {
    const {
      fetchLegalEntityProductionUnitsPage,
      legalEntityId,
      pageSize,
      isHydrogenContext,
    } = this.props;

    fetchLegalEntityProductionUnitsPage({
      filter: {
        legalEntityId,
        [productionUnitFilterFields.PRODUCTION_DEVICE_TYPE]: isHydrogenContext
          ? fuelType.HYDROGEN
          : fuelType.BIOMETHANE,
      },
      pager: {
        page: page - 1,
        size: pageSize,
      },
    });
  };

  getColumns = () => {
    const { t } = this.props;

    return [
      {
        title: t('productionUnit.name'),
        dataIndex: generalFormFields.NAME,
      },
      {
        title: t('productionUnit.startDate'),
        dataIndex: productionUnitFields.FIRST_TIME_USE,
      },
      {
        title: t('productionUnit.nominalPower'),
        dataIndex: technicalInformationFormFields.NOMINAL_POWER,
      },
      {
        title: t('productionUnit.technologyType'),
        dataIndex: technicalInformationFormFields.CLEANING_TECHNOLOGY_TYPE,
        render: translateCleaningTechnologyClassificator,
      },
      {
        title: t('productionUnit.eicz'),
        dataIndex: technicalInformationFormFields.METERING_POINT_EICZ,
      },
      {
        title: t('productionUnit.status'),
        dataIndex: productionUnitFields.STATUS,
        render: translateProductionUnitStatusClassificator,
      },
      {
        title: t('productionUnit.actions'),
        key: 'actions',
        render: (value, values) => (
          <span>
            <Button
              shape="circle"
              icon="edit"
              size="small"
              title={t('productionUnit.edit')}
              type="primary"
              onClick={() =>
                this.props.onEdit(get(values, generalFormFields.ID))
              }
            />
            {this.renderImport(t, values)}
          </span>
        ),
      },
    ];
  };

  selectedIdToImport = null;

  renderImport = (t, values) => {
    const { isAdmin } = this.props;

    const buttonComponent = (
      <span>
        <span className="ant-divider" />
        <Button
          shape="circle"
          icon="upload"
          size="small"
          title={t('productionUnit.importButton')}
          type="primary"
          onClick={() => {
            this.selectedIdToImport = get(values, generalFormFields.ID);
            this.props.showImportModal();
          }}
        />
      </span>
    );

    return !isAdmin &&
      get(values, [
        productionUnitFields.METERING_POINT,
        meteringPointFields.DATA_SOURCE,
      ]) === meteringPointDataSource.VIRTUAL &&
      get(values, productionUnitFields.STATUS) === productionUnitStatus.ACTIVE
      ? buttonComponent
      : null;
  };

  render() {
    const {
      data,
      t,
      hideImportModal,
      stateImportModal,
      isEditMode,
      page,
      totalElements,
      pageSize,
      isHydrogenContext,
    } = this.props;

    let columns = this.getColumns(t);
    if (isHydrogenContext) {
      columns.splice(4, 1);
      columns[3] = {
        title: t('productionUnit.productionTechnologyType'),
        dataIndex: technicalInformationFormFields.HYDROGEN_TECHNOLOGY_TYPE,
        render: translateHydrogenTechnologyClassificator,
      };
    }

    return (
      <div>
        <Table
          rowKey={generalFormFields.ID}
          columns={columns}
          dataSource={data}
          bordered
          pagination={
            isEditMode
              ? {
                  onChange: this.onPageChange,
                  current: page,
                  total: totalElements,
                  pageSize,
                  defaultCurrent: 1,
                }
              : false
          }
        />
        {stateImportModal && (
          <Modal
            onCancel={hideImportModal}
            visible={stateImportModal}
            footer={false}
            title={t('productionUnit.importTitle')}
            width="600px"
          >
            <MeasuringUpload
              onComplete={hideImportModal}
              productionUnitId={this.selectedIdToImport}
            />
          </Modal>
        )}
      </div>
    );
  }
}

export default ProductionUnitsTable;
