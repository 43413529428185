import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {connect} from 'react-redux';
import {
  getClassifierGeneralItem,
  getClassifierGeneralItemData,
  getClassifierGeneralChange,
} from '../../reducers/classifiersReducer';
import {
  showClassifierGeneralEditModal,
  hideClassifierGeneralEditModal,
  getClassifierGeneralEditModal,
} from '../../actions/actionCreators/modalsActionCreators';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {Spin, Modal, Button} from 'antd';
import {
  classifierGeneralItemField,
  classifierGeneralField,
} from '../../constants/classifier';
import * as actions from '../../actions/actionCreators/classifiersActionCreators';
import {ClassifierGeneralTable, TableActions} from '../../components';
import {ClassifierGeneralEdit as ClassifierGeneralEditForm} from '../../forms';
import {goToClassifiers} from '../../utils/gotoLink';
import classificatorTypes from '../../constants/classificators';

export class ClassifierGeneralEdit extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    fetchClassifier: PropTypes.func.isRequired,
    changeClassifier: PropTypes.func.isRequired,
    classifierData: PropTypesImmutable.map,
    classifierId: PropTypes.string.isRequired,
    modalVisible: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    const {fetchClassifier, classifierId} = this.props;
    fetchClassifier(classifierId);
  };

  selectedCode = null;

  render() {
    const {
      classifierData,
      loading,
      t,
      changeClassifier,
      classifierId,
      modalVisible,
      hideModal,
      showModal,
    } = this.props;

    let lang = {};
    let properties = {};
    if (this.selectedCode) {
      const itemCode = classifierData
        .get(classifierGeneralField.ITEMS)
        .find(
          item =>
            item.get(classifierGeneralItemField.CODE) === this.selectedCode
        );
      if (itemCode) {
        lang = itemCode.get(classifierGeneralItemField.LANG);
        properties = itemCode.get(classifierGeneralItemField.PROPERTIES);
      }
    }

    return (
      <Spin spinning={loading}>
        {classifierData && (
          <ClassifierGeneralTable
            t={t}
            dataSource={classifierData.toJS()}
            classifierId={classifierId}
            changeClassifier={changeClassifier}
            showModal={code => {
              this.selectedCode = code;
              showModal();
            }}
          />
        )}
        {modalVisible && (
          <Modal
            title={t('modalTitle')}
            visible
            footer={null}
            onCancel={hideModal}
            width={640}
          >
            <ClassifierGeneralEditForm
              initialValues={{
                [classifierGeneralItemField.ACTIVE]: true,
                [classifierGeneralItemField.CODE]: this.selectedCode,
                [classifierGeneralItemField.LANG]: lang,
                [classifierGeneralItemField.PROPERTIES]: properties,
              }}
              classifierCode={classifierId}
              onCancel={hideModal}
              selectedCode={this.selectedCode}
              onComplete={hideModal}
              isEdit={!!this.selectedCode}
              t={t}
            />
          </Modal>
        )}
        <TableActions>
          <Button onClick={goToClassifiers}>{t('buttonBack')}</Button>
          {classifierId !== classificatorTypes.consumptionUsage &&
            <Button
              type="primary"
              onClick={() => {
                this.selectedCode = null;
                showModal();
              }}
              className="global-margin-left-10"
            >
              {t('buttonValueAdd')}
            </Button>
          }
        </TableActions>
      </Spin>
    );
  }
}

const getIsLoading = getLoadingSelector(
  getClassifierGeneralItem,
  getClassifierGeneralChange
);

const mapStateToProps = state => ({
  loading: getIsLoading(state),
  classifierData: getClassifierGeneralItemData(state),
  modalVisible: getClassifierGeneralEditModal(state),
});

const mapDispatchToProps = dispatch => ({
  fetchClassifier: code => {
    dispatch(actions.fetchClassifierGeneral(code));
  },
  changeClassifier: async (code, data) => {
    await dispatch(actions.changeClassifierGeneral(code, data));
    dispatch(actions.fetchClassifierGeneral(code));
  },
  showModal: () => {
    dispatch(showClassifierGeneralEditModal());
  },
  hideModal: () => {
    dispatch(hideClassifierGeneralEditModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  ClassifierGeneralEdit
);
