import React, {Component} from 'react';
import PropTypesImmutable from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {TradingPlatformAgreementsTable} from "../../components";
import {
  getTradingPlatformAgreementsList,
  getTradingPlatformAgreementsListData
} from "../../reducers/tradingPlatformAgreementsListReducer";
import * as actions from '../../actions/actionCreators/tradingPlatformAgreementsActionCreators';


export class TradingPlatformAgreementsList extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    agreements: PropTypesImmutable.list,
    agreementsList: PropTypesImmutable.map.isRequired,
  };

  componentDidMount() {
    const {searchTradingPlatformAgreements} = this.props;
    searchTradingPlatformAgreements(0);
  }

  render() {
    const {
      t,
      agreements,
      agreementsList,
      agreementsLoading,
      searchTradingPlatformAgreements,
      ...rest
    } = this.props;

    return (
      <React.Fragment>
        <TradingPlatformAgreementsTable t={t}
                                        search={searchTradingPlatformAgreements}
                                        agreements={agreements.toJS()}
                                        agreementsLoading={agreementsLoading}
                                        {...agreementsList.toJS()}
                                        {...rest}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  agreementsList: getTradingPlatformAgreementsList(state),
  agreements: getTradingPlatformAgreementsListData(state),
  agreementsLoading: getLoadingSelector(getTradingPlatformAgreementsList)(state),
});

const mapDispatchToProps = {
  ...actions
};

export default connect(mapStateToProps, mapDispatchToProps)(
  TradingPlatformAgreementsList
);
