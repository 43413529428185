import React from 'react';
import PropTypes from 'prop-types';
import {Icon} from 'antd';
import moment from 'moment';
import {PieChart, Pie, Cell} from 'recharts';
import {consumerChartFields} from '../../constants/consumerChart';
import classifiersStore from '../../utils/classifiersStore';
import {translateFuelTypeClassificator} from '../../utils/translateHelpers';
import './ConsumerReportSection.css';

function getDecimal(num) {
  var str = '' + num;
  var zeroPos = str.indexOf('.');
  if (zeroPos === -1) return 0;
  str = str.slice(zeroPos);
  return +str;
}

const CustomizedLabel = ({x, y, fill, value, payload, index}) => {
  const text = payload[consumerChartFields.FUEL_TYPE]
    ? `${translateFuelTypeClassificator(
      payload[consumerChartFields.FUEL_TYPE]
    )} ${value}%`
    : `${value}%`;

  const length = text.toString().length;
  const additional = length * 5;

  return (
    <text
      x={x > 250 ? x + additional : x - additional}
      y={y}
      dy={5 * (index % 2 === 0 ? -1 : 1)}
      fill={fill}
      textAnchor="middle"
    >
      {text}
    </text>
  );
};

const COLORS = ['#84BD00', '#006272'];

class ConsumerReportSection extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    isYear: PropTypes.bool,
    isFillChangeable: PropTypes.bool,
    showProductionCountry: PropTypes.bool,
    showLegendValues: PropTypes.bool,
    showTotal: PropTypes.bool,
    isAvp: PropTypes.bool,
    sellerName: PropTypes.string,
  };

  static defaultProps = {
    isFillChangeable: false,
    showProductionCountry: false,
    showLegendValues: false,
    showTotal: false,
    isAvp: false,
    sellerName: '',
  };

  state = {
    isConsumedFull: false,
  };

  handleChartClick = (chart, index) => {
    if (!this.props.isFillChangeable) return;

    if (index > 0) {
      this.setState(state => ({
        isConsumedFull: !state.isConsumedFull,
      }));
    }
  };

  render() {
    const {
      data,
      t,
      isYear,
      showProductionCountry,
      showLegendValues,
      showTotal,
      isAvp,
      isSeller,
      sellerName,
    } = this.props;
    const {isConsumedFull} = this.state;
    const year =
      data[consumerChartFields.YEAR] ||
      (isSeller ? moment().year() - 1 : moment().year());
    const month = data[consumerChartFields.MONTH] || 12;
    const total = data[consumerChartFields.TOTAL] || 0;
    const consumed = data[consumerChartFields.CONSUMED] || [];

    const consumedGasAmount = consumed.reduce(
      (acc, current) => acc + current[consumerChartFields.AMOUNT],
      0
    );
    const consumedGasPercentage = Math.round(consumedGasAmount / total * 100);

    const naturalGasAmount = Math.max(0, total - consumedGasAmount);
    const naturalGasPercentage = Math.round(naturalGasAmount / total * 100);

    const chartData = [
      {
        name: `${naturalGasPercentage}%`,
        value: naturalGasPercentage,
        color: COLORS[1],
      },
    ];

    if (isConsumedFull) {
      let plusOne = 0;
      let totalPercentage = naturalGasPercentage;
      let totalPercentages = [];
      consumed.forEach(item => {
        totalPercentage += Math.round(
          item[consumerChartFields.AMOUNT] / total * 100
        );
        totalPercentages.push({
          name: item[consumerChartFields.FUEL_TYPE],
          value: getDecimal(item[consumerChartFields.AMOUNT] / total * 100),
        });
      });

      var max = Math.max.apply(
        null,
        Object.keys(totalPercentages).map(function (e) {
          return totalPercentages[e]['value'];
        })
      );

      let keyOfObjectForincreasing = '';
      for (var key in totalPercentages) {
        if (totalPercentages[key]['value'] === max)
          keyOfObjectForincreasing = totalPercentages[key]['name'];
      }

      if (totalPercentage < 100) {
        plusOne = 100 - totalPercentage;
      }

      consumed.forEach(item => {
        let itemAmount = 0;
        if (
          plusOne &&
          keyOfObjectForincreasing !== '' &&
          item[consumerChartFields.FUEL_TYPE] === keyOfObjectForincreasing
        ) {
          itemAmount =
            Math.round(item[consumerChartFields.AMOUNT] / total * 100) +
            plusOne;
        } else {
          itemAmount = Math.round(
            item[consumerChartFields.AMOUNT] / total * 100
          );
        }
        chartData.push({
          name: item[consumerChartFields.FUEL_TYPE],
          value: itemAmount,
          color: classifiersStore.getFuelTypeColor(
            item[consumerChartFields.FUEL_TYPE]
          ),
          fuelType: item[consumerChartFields.FUEL_TYPE],
        });
      });
    } else {
      chartData.push({
        name: 'consumed',
        value: consumedGasPercentage,
        color: COLORS[0],
      });
    }

    return (
      <div className="consumer-report-section">
        <div className="consumer-report-section__header">
          <div className="consumer-report-section__header__year">{year}</div>
          01.{year} - {(month < 10 ? '0' : '') + month}.{year}
        </div>
        <div className="consumer-report-section__content">
          <div className="consumer-report-section__content__header">
            <div className="consumer-report-section__content__header-icon">
              <Icon type="info-circle"/>
            </div>
            <div className="consumer-report-section__content__header-text">
              {isYear ? t('chart.infoYear') : t('chart.info')}
            </div>
          </div>

          <div>
            <PieChart width={560} height={300}>
              <Pie
                dataKey="value"
                data={chartData}
                cx={270}
                cy={150}
                outerRadius={120}
                onClick={this.handleChartClick}
                label={<CustomizedLabel/>}
              >
                {chartData.map((entry, index) => (
                  <Cell key={index} fill={entry.color}/>
                ))}
              </Pie>
            </PieChart>
          </div>

          {showTotal && (
            <div className="consumer-report-section__content__total">
              {t('chart.total')}:{' '}
              <b>
                {total} {t('chart.mwh')}
              </b>
            </div>
          )}

          {sellerName && (
            <div className="consumer-report-section__content__total">
              {t('chart.seller')}: <b>{sellerName}</b>
            </div>
          )}

          {showProductionCountry && (
            <div className="consumer-report-section__content__total">
              {isSeller
                ? t('chart.productionSellerLabel')
                : isAvp
                  ? t('chart.productionAvpLabel')
                  : t('chart.productionLabel')}:{' '}
              <b>{t('chart.productionAvpCountry')} 100%</b>
            </div>
          )}

          <div className="consumer-report-section__content__legend">
            {(!isAvp || isSeller) && (
              <div className="consumer-report-section__content__legend-item">
                <div className="consumer-report-section__content__legend-color">
                  <svg width={20} height={20}>
                    <circle cx="10" cy="10" r="10" fill={COLORS[0]}/>
                  </svg>
                </div>
                <div className="consumer-report-section__content__legend-color">
                  {t('chart.biomethane')}
                </div>
                {showLegendValues && (
                  <div className="consumer-report-section__content__legend-color">
                    <b>
                      {consumedGasAmount} {t('chart.mwh')}
                    </b>
                  </div>
                )}
              </div>
            )}
            <div className="consumer-report-section__content__legend-item">
              <div className="consumer-report-section__content__legend-color">
                <svg width={20} height={20}>
                  <circle cx="10" cy="10" r="10" fill={COLORS[1]}/>
                </svg>
              </div>
              <div className="consumer-report-section__content__legend-color">
                {t('chart.naturalGas')}
              </div>
              {showLegendValues && (
                <div className="consumer-report-section__content__legend-color">
                  <b>
                    {naturalGasAmount} {t('chart.mwh')}
                  </b>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConsumerReportSection;
