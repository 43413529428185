import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Col, Row, Spin} from 'antd';
import PropTypes from 'prop-types';
import {getEntityById, getLoadingSelector} from '../../utils/asyncHelpers';
import {notification} from '../../schemas';
import {notification as notificationFields} from '../../constants/notification';
import {notificationRelatedObjectType} from '../../constants/classificators';
import {FileList, TableActions} from '../../components';
import {legalEntityFields} from '../../constants/legalEntity';
import * as actionCreators from '../../actions/actionCreators/notificationActionCreators';
import {getNotificationClose} from '../../reducers/notificationActionReducer';
import {
  goToAccountGOSAuctionDetails,
  goToAccountGOSPreTradingAgreementDetails,
  goToLegalEntity,
  goToLoadingUnitView,
  goToMeteringPointView,
  goToProductionUnitView,
} from '../../utils/gotoLink';
import './NotificationView.css';

export class NotificationView extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    closeNotification: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    selectedId: PropTypes.number.isRequired,
    notificationData: PropTypes.object,
  };

  closeNotification = () => {
    const {selectedId, closeNotification} = this.props;
    closeNotification(selectedId);
  };

  handleRelatedClick = (e, relatedObjectType, relatedObjectId, legalEntity) => {
    e.preventDefault();
    this.props.hideModal();

    switch (relatedObjectType) {
      case notificationRelatedObjectType.MEASUREMENT_POINT:
        goToMeteringPointView(relatedObjectId);
        break;
      case notificationRelatedObjectType.MEASUREMENT_POINT_REVIEW:
        goToMeteringPointView(relatedObjectId, 'review');
        break;
      case notificationRelatedObjectType.LEGAL_ENTITY:
        goToLegalEntity(legalEntity.get(legalEntityFields.REGISTER_CODE));
        break;
      case notificationRelatedObjectType.PRODUCTION_UNIT:
        goToProductionUnitView(relatedObjectId);
        break;
      case notificationRelatedObjectType.LOADING_UNIT:
        goToLoadingUnitView(relatedObjectId);
        break;
      case notificationRelatedObjectType.TS_AGREEMENT:
        goToAccountGOSPreTradingAgreementDetails(legalEntity.get(legalEntityFields.REGISTER_CODE), relatedObjectId);
        break;
      case notificationRelatedObjectType.TS_AUCTION:
        goToAccountGOSAuctionDetails(legalEntity.get(legalEntityFields.REGISTER_CODE), relatedObjectId);
        break;
      default:
        break;
    }
  };

  render() {
    const {isLoading, t, notificationData} = this.props;

    return (
      <Spin spinning={isLoading} className="container-NotificationView">
        <Row gutter={32}>
          <Col span={24}>
            <h2>{notificationData.get(notificationFields.TITLE)}</h2>
          </Col>
        </Row>
        <Row
          gutter={32}
          className="global-margin-top-10 global-margin-bottom-10"
        >
          <Col span={12}>
            <span>{t('relatedObject')}: </span>
            <a
              href="/relatedObject"
              onClick={e =>
                this.handleRelatedClick(
                  e,
                  notificationData.get(notificationFields.RELATED_OBJECT_TYPE),
                  notificationData.get(notificationFields.RELATED_OBJECT_ID),
                  notificationData.get(notificationFields.LEGAL_ENTITY)
                )
              }
            >
              {notificationData.get(notificationFields.RELATED_OBJECT_NAME) || t('missingName')}
            </a>
          </Col>
          <Col span={12}>
            <span>{t('creationDate')}: </span>
            <b>{notificationData.get(notificationFields.CREATED_DATE)}</b>
          </Col>
        </Row>
        <Row
          gutter={32}
          className="global-margin-top-10 global-margin-bottom-10"
        >
          <Col span={12}>
            <span>{t('sender')}: </span>
            <b>{notificationData.get(notificationFields.SENDER)}</b>
          </Col>
          <Col span={12}>
            <span>{t('receiver')}: </span>
            <b>
              {notificationData.get(notificationFields.ADMIN)
                ? t('adminReceiver')
                : notificationData.getIn([
                  notificationFields.LEGAL_ENTITY,
                  legalEntityFields.NAME,
                ])}
            </b>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={24}>
            <div
              className="container-NotificationView__html"
              dangerouslySetInnerHTML={{
                __html: notificationData.get(notificationFields.DESCRIPTION),
              }}
            />
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={12}>
            <FileList fileList={notificationData.get('files').toJS()} t={t}/>
          </Col>
        </Row>
        <TableActions>
          <Button type="danger" onClick={this.closeNotification}>
            {t('closeNotification')}
          </Button>
        </TableActions>
      </Spin>
    );
  }
}

const getLoading = getLoadingSelector(getNotificationClose);

const mapStateToProps = (state, ownProps) => ({
  isLoading: getLoading(state),
  notificationData: getEntityById(state, notification, ownProps.selectedId),
});

const mapDispatchToProps = {
  closeNotification: actionCreators.closeNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationView);
