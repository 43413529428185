import React, {Component} from 'react';
import PropTypesImmutable from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {InvoiceTable} from '../../components';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {Modal} from 'antd';
import {
  getInvoiceList,
  getInvoiceListData,
  getInvoiceConfirm,
} from '../../reducers/invoiceSearchReducer';
import {getRole} from '../../reducers/userReducer';
import {
  getInvoiceConfirmModal,
  hideInvoiceConfirmModal,
  showInvoiceConfirmModal,
  getInvoiceTermsModal,
  showInvoiceTermsModal,
  hideInvoiceTermsModal,
} from '../../actions/actionCreators/modalsActionCreators';
import {InvoiceConfirm, InvoiceTermsConditions} from '../../forms';
import * as actions from '../../actions/actionCreators/invoiceSearchActionCreators';

export class InvoiceList extends Component {
  static propTypes = {
    userRole: PropTypes.string.isRequired,
    invoicesData: PropTypesImmutable.list,
    invoices: PropTypesImmutable.map.isRequired,
    hideConfirmModal: PropTypes.func.isRequired,
    stateConfirmModal: PropTypes.bool.isRequired,
    stateTermsModal: PropTypes.bool.isRequired,
    hideTermsModal: PropTypes.func.isRequired,
    showTermsModal: PropTypes.func.isRequired,
    isConfirmLoading: PropTypes.bool.isRequired,
  };

  render() {
    const {
      invoices,
      invoicesData,
      hideConfirmModal,
      hideTermsModal,
      stateConfirmModal,
      stateTermsModal,
      showTermsModal,
      isConfirmLoading,
      t,
      ...rest
    } = this.props;

    return (
      <React.Fragment>
        <InvoiceTable
          invoices={invoicesData.toJS()}
          t={t}
          {...rest}
          {...invoices.toJS()}
        />
        {stateConfirmModal && (
          <Modal
            onCancel={hideConfirmModal}
            visible
            footer={false}
            title={t('confirm.title')}
            width="600px"
          >
            <InvoiceConfirm
              t={t}
              showTermsModal={showTermsModal}
              hideTermsModal={hideTermsModal}
              hideConfirmModal={hideConfirmModal}
            />
          </Modal>
        )}
        {stateTermsModal && (
          <Modal
            onCancel={hideTermsModal}
            visible
            footer={false}
            title={t('terms.title')}
            width="600px"
          >
            <InvoiceTermsConditions
              t={t}
              hideTermsModal={hideTermsModal}
              hideConfirmModal={hideConfirmModal}
              isLoading={isConfirmLoading}
            />
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

const getInvoiceConfirmLoading = getLoadingSelector(getInvoiceConfirm);

const mapStateToProps = state => ({
  invoices: getInvoiceList(state),
  invoicesData: getInvoiceListData(state),
  userRole: getRole(state),
  stateConfirmModal: getInvoiceConfirmModal(state),
  stateTermsModal: getInvoiceTermsModal(state),
  isConfirmLoading: getInvoiceConfirmLoading(state),
});

const mapDispatchToProps = {
  searchInvoices: actions.searchInvoices,
  selectInvoice: actions.selectInvoice,
  hideConfirmModal: hideInvoiceConfirmModal,
  showConfirmModal: showInvoiceConfirmModal,
  hideTermsModal: hideInvoiceTermsModal,
  showTermsModal: showInvoiceTermsModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList);
