import {createAction} from 'redux-actions';

export const toggleCreateForm = createAction('CREATE_CONSUMPTION_FORM_TOGGLE');

export const selectPoint = createAction('SELECT_CONSUMPTION_POINT');

export const selectOwner = createAction('SELECT_CONSUMPTION_POINT_OWNER');

export const consumerAdded = createAction('CONSUMPTION_POINT_CONSUMER_ADDED');

export const resetFields = createAction('CONSUMPTION_POINT_RESET_FIELDS');
