import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {meteringPointFields} from '../../constants/meteringPoint';
import {productionUnitFields} from '../../constants/productionUnit';
import {Table} from 'antd';

class ManualGoIssuingMeteringPoints extends Component {
  static propTypes = {
    points: PropTypes.array,
    t: PropTypes.func.isRequired,
    onRowSelect: PropTypes.func.isRequired,
  };

  getColumns = () => {
    const {t} = this.props;

    const columns = [
      {
        title: t('table.eiczCode'),
        dataIndex: meteringPointFields.EICZ_CODE,
      },
      {
        title: t('table.eicwCode'),
        dataIndex: `${meteringPointFields.PRODUCTION_UNIT}.${
          productionUnitFields.EICW_CODE
        }`,
      },
    ];

    return columns;
  };

  render() {
    const {points, onRowSelect} = this.props;

    return (
      <Table
        rowKey={meteringPointFields.ID}
        dataSource={points}
        columns={this.getColumns()}
        rowSelection={{onChange: onRowSelect}}
        pagination={false}
      />
    );
  }
}

export default ManualGoIssuingMeteringPoints;
