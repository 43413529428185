import React from 'react';
import PropTypes from 'prop-types';
import './HttpError.css';

class HttpError extends React.Component {
  static propTypes = {
    code: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
  };

  render() {
    const {code, message} = this.props;

    return (
      <div className="http-error">
        <div className="http-error__fill"/>
        <h1 className="http-error__block code">{code}</h1>
        <p className="http-error__block message">{message}</p>
      </div>
    );
  }
}

export default HttpError;
