import {Authorization} from '../containers';
import _ from 'lodash/fp';
import {accountType, mandateRole} from '../constants/classificators';
import {PortletError} from '../pages';

const isAdmin = _.isEqual(mandateRole.ELERING_ADMINISTRATOR);
const isUser = _.isEqual(mandateRole.MARKET_REPRESENTATIVE);
const isConsumer = role =>
  [mandateRole.CONSUMER, mandateRole.NOT_REGISTERED_CONSUMER].includes(role);
const isAvpConsumer = _.isEqual(mandateRole.NOT_REGISTERED_CONSUMER);

const Admin = Authorization([mandateRole.ELERING_ADMINISTRATOR]);
const UserOrAdmin = Authorization([
  mandateRole.MARKET_REPRESENTATIVE,
  mandateRole.ELERING_ADMINISTRATOR,
]);

const UserOrAdminOrConsumer = Authorization([
  mandateRole.MARKET_REPRESENTATIVE,
  mandateRole.ELERING_ADMINISTRATOR,
  mandateRole.CONSUMER,
]);

const UserProvider = Authorization(
  [mandateRole.MARKET_REPRESENTATIVE],
  [accountType.PROVIDER]
);

const UserSeller = Authorization(
  [mandateRole.MARKET_REPRESENTATIVE],
  [accountType.TRADER]
);
const UserSellerOrAdmin = Authorization(
  [mandateRole.MARKET_REPRESENTATIVE, mandateRole.ELERING_ADMINISTRATOR],
  [accountType.TRADER]
);
const UserProducer = Authorization(
  [mandateRole.MARKET_REPRESENTATIVE],
  [accountType.MANUFACTURER]
);
const UserProviderOrAdmin = Authorization(
  [mandateRole.MARKET_REPRESENTATIVE, mandateRole.ELERING_ADMINISTRATOR],
  [accountType.PROVIDER]
);

const UserProducerOrAdmin = Authorization(
  [mandateRole.MARKET_REPRESENTATIVE, mandateRole.ELERING_ADMINISTRATOR],
  [accountType.MANUFACTURER]
);
const UserTraderOrAdmin = Authorization(
  [mandateRole.MARKET_REPRESENTATIVE, mandateRole.ELERING_ADMINISTRATOR],
  [accountType.TRADER]
);

const User = Authorization([mandateRole.MARKET_REPRESENTATIVE]);

const UserConsumer = Authorization(
  [mandateRole.CONSUMER, mandateRole.NOT_REGISTERED_CONSUMER],
  [accountType.CONSUMER],
  true,
  PortletError
);

const UserSellerProviderLoadingOperatorOrAdmin = Authorization(
  [mandateRole.MARKET_REPRESENTATIVE, mandateRole.ELERING_ADMINISTRATOR],
  [accountType.TRADER, accountType.PROVIDER, accountType.LOADING_OPERATOR]
);

const InactiveUserOrAdmin = Authorization(
  [mandateRole.MARKET_REPRESENTATIVE, mandateRole.ELERING_ADMINISTRATOR],
  null,
  false
);

const UserLoadingOperator = Authorization(
  [mandateRole.MARKET_REPRESENTATIVE],
  [accountType.LOADING_OPERATOR]
);

const UserLoadingOperatorOrAdmin = Authorization(
  [mandateRole.MARKET_REPRESENTATIVE, mandateRole.ELERING_ADMINISTRATOR],
  [accountType.LOADING_OPERATOR]
);

export {
  UserOrAdmin,
  UserOrAdminOrConsumer,
  UserSellerProviderLoadingOperatorOrAdmin,
  Admin,
  UserSeller,
  UserSellerOrAdmin,
  UserProducer,
  UserProducerOrAdmin,
  UserTraderOrAdmin,
  User,
  UserProvider,
  UserConsumer,
  InactiveUserOrAdmin,
  isAdmin,
  isUser,
  isConsumer,
  isAvpConsumer,
  UserProviderOrAdmin,
  UserLoadingOperator,
  UserLoadingOperatorOrAdmin
};
