import {
  createValidation,
  validatorRequired,
  validatorMaxLength,
} from '../../utils/formValidators';

import {formRegisterAdditionalFields} from '../../constants/formRegister';

export default createValidation([
  {
    name: formRegisterAdditionalFields.EMTAK_CODE,
    validators: [validatorRequired(), validatorMaxLength(10)],
  },
  {
    name: formRegisterAdditionalFields.WORKERS_NUMBER,
    validators: validatorRequired(),
  },
  {
    name: formRegisterAdditionalFields.EHAK_CODE,
    validators: validatorRequired(),
  },
]);
