export const classifierFields = {
  ID: 'code',
  NAME: 'name',
  VALUE: 'value',
  VALUES: 'values',
};

export const classifierViewFields = {
  MONTH: 'month',
  PRICE: 'price',
  YEAR: 'year',
};

export const classifierTaxEditFields = {
  TAX: 'taxValue',
};

export const classifierInvoiceConfirmationEditFields = {
  EN: 'en',
  ET: 'et',
};

export const classifierGeneralField = {
  CODE: 'code',
  ID: 'id',
  TITLE: 'translation',
  ITEMS: 'lines',
};

export const classifierGeneralItemField = {
  ID: 'id',
  CODE: 'code',
  ACTIVE: 'active',
  LANG: 'lang',
  PROPERTIES: 'properties'
};

export const classifierSubValuesItemField = {
  CODE: 'code',
  ACTIVE: 'active',
  LANG: 'lang',
  CLASSIFIER: 'sub_classifier',
  RAW_MATERIAL_PROPERTIES: 'rawMaterialProperties'
};

export const classifierSubValuesRawMaterialPropertiesItemField = {
  ID: 'id',
  LAND_USE_CATEGORY: 'landUseCategory',
  LAND_USE_EMISSIONS: 'landUseEmissions',
  BIOFUEL_TYPE: 'biofuelType',
  CALCULATED_MULTIPLIER: 'calculatedMultiplier',
  DELETE_PENDING: 'deletePending'
};

export const classifierGeneralItemPropertiesField = {
  CODE: 'code',
  VALUE: 'value',
};

export const classifierGeneralItemLangField = {
  ET: 'et',
  EN: 'en',
};

export const classifierConsumptionUsageProperties = {
  START_DAY: 'start_day',
  END_DAY: 'end_day',
  SUPPORT_REGULATION: 'support_regulations_sum',
  SUBSIDY_IN_ENGLISH: 'subsidy_in_english',
  SUBSIDY_IN_ESTONIAN: 'subsidy_in_estonian',
  PERIOD: 'period',
};

export const classifierFuelTypeProperties = {
  CO2: 'co2emission',
};

export const classifierProperties = {
  PROPERTY: 'property',
  BOOLEAN: 'boolean'
};

export const classifierLegalEntityConsumptionTypeField = {
  ID: 'id',
  LEGAL_ENTITY_ID: 'legalEntityId',
  LEGAL_ENTITY_NAME: 'legalEntityName',
  LEGAL_ENTITY_REGISTRY_CODE: 'legalEntityRegistryCode',
  CONSUMPTION_TYPE_CODE: 'consumptionTypeCode',
  SUPPORT_REGULATIONS_SUM: 'supportRegulationsSum'
};
