import {
  createValidation,
  validatorRequired,
  validatorDateGreaterThenOrInitial,
} from '../../utils/formValidators';

import {activityPeriodFormFields} from '../../constants/formsAddEquipment';

export default createValidation([
  {
    name: activityPeriodFormFields.START_DATE,
    validators: [validatorRequired(), validatorDateGreaterThenOrInitial()],
  },
  {
    name: activityPeriodFormFields.DECISION_DATE,
    validators: [validatorRequired()],
  },
  {
    name: activityPeriodFormFields.ELIGIBLE_FOR_SUBSIDY,
    validators: [validatorRequired()],
  },
]);
