import React from 'react';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Layout} from 'antd';
import {Login, SelectLocale} from '../';
import {logo} from '../../utils/imagesImport';
import {getLocale} from '../../reducers/settingsReducer';
import {popupCenter} from "../../utils/popup";

class Landing extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    params: PropTypes.object,
    locale: PropTypes.string.isRequired,
  };

  render() {
    const {t, params} = this.props;

    return (
      <Layout className="landing-container">
        <header className="landing-header">
          <div className="landing-header__container">
            <img
              className="landing-header__logo"
              src={logo}
              alt="logo"
            />
            <h2 className="landing-header__title">{t('title')}</h2>
          </div>
        </header>
        <div className="landing-main">
          <div className="landing-main__features landing-main__block">
            <h1 className="landing-main__features__welcome">
              {t('titleWelcome')}!
            </h1>

            <h2 className="landing__heading--list">{t('features.title')}:</h2>

            <ul className="landing-main__features__list">
              <li className="landing__list__item">{t('features.feature_1')}</li>
              <li className="landing__list__item">{t('features.feature_2')}</li>
              <li className="landing__list__item">{t('features.feature_3')}</li>
              <li className="landing__list__item">{t('features.feature_4')}</li>
              <li className="landing__list__item">{t('features.feature_5')}</li>
              <li className="landing__list__item">{t('features.feature_6')}</li>
              <li className="landing__list__item">{t('features.feature_7')}</li>
              <li className="landing__list__item">{t('features.feature_8')}</li>
            </ul>
            <footer className="landing-main__features__footer">
              <div dangerouslySetInnerHTML={{
                __html: t('tech_support', {
                  email: 'biometaan@elering.ee',
                  pageUrl: 'https://www.elering.ee/biometaan'
                })
              }}>
              </div>
              <div className="landing-main__features__footer--disclaimer"
                   dangerouslySetInnerHTML={{__html: t('disclaimer')}}>
              </div>
            </footer>
            <div className="landing-main__cookies-link">
              <a href='/' onClick={(e) => {
                popupCenter({url: '/cookies.html', title: `Cookies`, w: 600, h: 400});
                e.preventDefault();
              }}>
                {t("cookies")}
              </a>
            </div>
          </div>
          <div className="landing-main__login-box landing-main__block">
            <div className="landing-main__login-box__locale">
              <SelectLocale/>
            </div>
            <Login t={t} loginCode={params.loginCode}/>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  locale: getLocale(state),
});

export default connect(mapStateToProps)(translate(NAMESPACES.landing)(Landing));
