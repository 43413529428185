import {
  createAsyncActionStoreWrapper,
  createAsyncPagingAction,
} from '../../utils/asyncPagingHelpers';
import {searchAccountGOSAgreements as accountGOSAgreementsSearch} from '../actionHelpers/endpoints';
import {getFormValues} from 'redux-form/immutable';
import {TRADING_PLATFORM_AGREEMENTS_SEARCH} from "../../constants/tradingPlatformActionTypes";
import {
  FORM_TRADING_PLATFORM_AGREEMENTS_FILTER
} from "../../constants/tradingPlatformAgreementsFilter";
import {
  getTradingPlatformAgreementsList
} from "../../reducers/tradingPlatformAgreementsListReducer";


export const searchTradingPlatformAgreements = createAsyncActionStoreWrapper(
  createAsyncPagingAction(
    TRADING_PLATFORM_AGREEMENTS_SEARCH,
    accountGOSAgreementsSearch
  ),
  getFormValues(FORM_TRADING_PLATFORM_AGREEMENTS_FILTER),
  getTradingPlatformAgreementsList
);
