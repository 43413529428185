import React, {Component} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {last, isEmpty, head} from 'lodash';
import {Row, Col, Table, DatePicker, Form} from 'antd';
import {goAmountTableFields} from '../../constants/cancelingCertificates';
import {FORMAT_DEFAULT_DATE} from '../../utils';
import {
  translateConsumptionUsageClassificator,
  translateMeteringPointDataSourceClassificator,
} from '../../utils/translateHelpers';

const {MonthPicker} = DatePicker;
const FormItem = Form.Item;
const MONTH_FORMAT = 'MM.YYYY';

const createMomentFromPeriod = period =>
  moment(
    `1.${period[goAmountTableFields.MONTH]}.${
      period[goAmountTableFields.YEAR]
    }`,
    FORMAT_DEFAULT_DATE
  );

export default class CancelingCertificatesGoAmount extends Component {
  static propTypes = {
    goAmounts: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    periods: PropTypes.array.isRequired,
    selectPeriods: PropTypes.func.isRequired,
    goAmountTotal: PropTypes.number.isRequired,
  };

  state = {
    monthFrom: null,
    monthTo: null,
  };

  getColumns = () => {
    const {t} = this.props;

    return [
      {
        title: t('goTable.period'),
        dataIndex: 'date',
        render: (value, row) => row.date.format(MONTH_FORMAT),
      },
      {
        title: t('goTable.consumptionType'),
        dataIndex: goAmountTableFields.CONSUMPTION_TYPE,
        render: translateConsumptionUsageClassificator,
      },
      {
        title: t('goTable.dataSource'),
        dataIndex: goAmountTableFields.DATA_SOURCE,
        render: translateMeteringPointDataSourceClassificator,
      },
      {
        title: t('goTable.amount'),
        dataIndex: goAmountTableFields.AVAILABLE_AMOUNT,
      },
    ];
  };

  handleMonthFromChange = value => {
    const {periods, selectPeriods} = this.props;
    const monthFrom = value && value.startOf('month');

    this.setState({
      monthFrom,
    });

    if (monthFrom) {
      selectPeriods(
        periods.filter(period =>
          createMomentFromPeriod(period).isSameOrAfter(monthFrom)
        )
      );
    }
  };

  handleMonthToChange = value => {
    const {periods, selectPeriods} = this.props;
    const monthTo = value && value.startOf('month');

    this.setState({
      monthTo,
    });

    if (monthTo) {
      selectPeriods(
        periods.filter(period =>
          createMomentFromPeriod(period).isSameOrBefore(monthTo)
        )
      );
    }
  };

  handleRowSelect = (selectedRowKeys, selectedRows) => {
    const {selectPeriods} = this.props;
    selectPeriods(
      selectedRows.map(row => ({
        [goAmountTableFields.MONTH]: row[goAmountTableFields.MONTH],
        [goAmountTableFields.YEAR]: row[goAmountTableFields.YEAR],
      }))
    );
  };

  prepareData = () => {
    const {goAmounts} = this.props;
    const {monthFrom, monthTo} = this.state;

    const isShowFilter = goAmounts.length > 1;
    const dataWithDates = goAmounts
      .map(goAmount => ({
        ...goAmount,
        date: createMomentFromPeriod(goAmount),
      }))
      .sort((a, b) => (a.date.isBefore(b.date) ? -1 : 1));

    const minDate = isEmpty(dataWithDates) ? null : head(dataWithDates).date;
    const maxDate = isEmpty(dataWithDates) ? null : last(dataWithDates).date;

    const filteredData = isShowFilter
      ? dataWithDates.filter(
        goAmount =>
          goAmount.date.isSameOrAfter(monthFrom || minDate) &&
          goAmount.date.isSameOrBefore(monthTo || maxDate)
      )
      : dataWithDates;

    return {data: filteredData, isShowFilter, minDate, maxDate};
  };

  render() {
    const {t, periods, goAmountTotal} = this.props;

    const rowSelection = {
      selectedRowKeys: periods.map(
        period =>
          `${period[goAmountTableFields.MONTH]}` +
          `${period[goAmountTableFields.YEAR]}`
      ),
      onChange: this.handleRowSelect,
    };

    const preparedData = this.prepareData();

    return (
      <React.Fragment>
        <h2>{t('goTable.title')}</h2>
        {preparedData.isShowFilter && (
          <Row gutter={32}>
            <Col span={8}>
              <FormItem label={t('goTable.monthFrom')}>
                <MonthPicker
                  onChange={this.handleMonthFromChange}
                  format={MONTH_FORMAT}
                  disabledDate={current =>
                    current.isBefore(preparedData.minDate) ||
                    current.isAfter(preparedData.maxDate.endOf('month'))
                  }
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label={t('goTable.monthTo')}>
                <MonthPicker
                  onChange={this.handleMonthToChange}
                  format={MONTH_FORMAT}
                  disabledDate={current =>
                    current.isBefore(preparedData.minDate) ||
                    current.isAfter(preparedData.maxDate.endOf('month'))
                  }
                />
              </FormItem>
            </Col>
          </Row>
        )}
        <Table
          rowKey={record => `${record.month}${record.year}`}
          columns={this.getColumns()}
          dataSource={preparedData.data}
          pagination={false}
          rowSelection={rowSelection}
          footer={() => (
            <div className="global-align-right">
              {t('goTable.footer', {count: goAmountTotal})}
            </div>
          )}
        />
      </React.Fragment>
    );
  }
}
