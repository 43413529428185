import React from 'react';
import {Col, Row} from "antd";
import {FormDetailsItem} from "../../components/FormDetailsItem/FormDetailsItem";
import {goToLoadingUnitView} from '../../utils/gotoLink';

const handleRelatedClick = (e, id) => {
  e.preventDefault();
  goToLoadingUnitView(id)
};

export const ConsumptionPointDetails = ({t, meteringPoint}) => {
  return (
    <div>
      <h2>{t('info.title')}</h2>
      <Row gutter={32}>
        <Col span={8}>
          <FormDetailsItem label={t('info.eiczCode')} value={meteringPoint.eicZ}/>
        </Col>
        <Col span={8}>
          <FormDetailsItem label={t('info.loadingUnitName')} value={
            <a
              href="#"
              onClick={e => handleRelatedClick(e, meteringPoint.loadingUnitId)}
            >
              {meteringPoint.loadingUnitName}
            </a>
          }/>
        </Col>
        <Col span={8}>
          <FormDetailsItem label={t('info.loadingOperator')} value={meteringPoint.loadingOperator}/>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={8}>
          <FormDetailsItem label={t('info.electricGridOperator')}
                           value={meteringPoint.gridOperator}/>
        </Col>
        <Col span={8}>
          <FormDetailsItem label={t('info.county')} value={meteringPoint.county}/>
        </Col>
        <Col span={8}>
          <FormDetailsItem label={t('info.municipality')} value={meteringPoint.municipality}/>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={8}>
          <FormDetailsItem label={t('info.address')} value={meteringPoint.address}/>
        </Col>
        <Col span={8}>
          <FormDetailsItem label={t('info.zipCode')} value={meteringPoint.zipCode}/>
        </Col>
        <Col span={8}>
          <FormDetailsItem label={t('info.district')} value={meteringPoint.district}/>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={8}>
          <FormDetailsItem label={t('info.directConnection.title')}
                           value={t(`info.directConnection.${meteringPoint.isDirectlyConnectedToProductionUnit}`)}/>
        </Col>
      </Row>
    </div>
  );
};
