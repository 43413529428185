import React, {Component} from 'react';
import {DatePicker} from 'antd';
import ComponentWrapper from './ComponentWrapper';
import {monthRangeMap} from './componentMappers';

const {RangePicker} = DatePicker;

export default class DateField extends Component {
  render() {
    return (
      <ComponentWrapper
        {...this.props}
        mapProps={monthRangeMap}
        render={props => <RangePicker {...props} />}
      />
    );
  }
}
