import React, {Component} from 'react';
import {DatePicker} from 'antd';
import PropTypes from 'prop-types';
import ComponentWrapper from './ComponentWrapper';
import {datePickerMap} from './componentMappers';

export default class DateField extends Component {
  static propTypes = {
    showTime: PropTypes.bool,
  };

  static defaultProps = {
    showTime: false,
  };

  render() {
    return (
      <ComponentWrapper
        {...this.props}
        mapProps={datePickerMap(this.props.showTime)}
        render={props => (
          <DatePicker
            {...props}
            onOpenChange={status => (status ? props.onFocus() : props.onBlur())}
          />
        )}
      />
    );
  }
}
