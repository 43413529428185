import {
  createAsyncActionStoreWrapper,
  createAsyncPagingAction,
} from '../../utils/asyncPagingHelpers';
import {
  ACCOUNT_GOS_PURCHASING_AGREEMENTS_LIST_FETCH,
  ACCOUNT_GOS_SELLING_AGREEMENTS_LIST_FETCH,
} from '../../constants/accountGOSListActionTypes';
import {searchAccountGOSAgreements as accountGOSAgreementsSearch} from '../actionHelpers/endpoints';
import {getFormValues} from 'redux-form/immutable';
import {
  FORM_ACCOUNT_GOS_PURCHASING_AGREEMENTS_FILTER
} from '../../constants/formAccountGOSPurchasingAgreementsFilter';
import {
  FORM_ACCOUNT_GOS_SELLING_AGREEMENTS_FILTER
} from '../../constants/formAccountGOSSellingAgreementsFilter';
import {
  getAccountGOSPurchasingAgreementsList
} from "../../reducers/accountGOSPurchasingAgreementsListReducer";
import {
  getAccountGOSSellingAgreementsList
} from "../../reducers/accountGOSSellingAgreementsListReducer";


export const searchAccountGOSPurchasingAgreements = createAsyncActionStoreWrapper(
  createAsyncPagingAction(
    ACCOUNT_GOS_PURCHASING_AGREEMENTS_LIST_FETCH,
    accountGOSAgreementsSearch
  ),
  getFormValues(FORM_ACCOUNT_GOS_PURCHASING_AGREEMENTS_FILTER),
  getAccountGOSPurchasingAgreementsList
);

export const searchAccountGOSSellingAgreements = createAsyncActionStoreWrapper(
  createAsyncPagingAction(
    ACCOUNT_GOS_SELLING_AGREEMENTS_LIST_FETCH,
    accountGOSAgreementsSearch
  ),
  getFormValues(FORM_ACCOUNT_GOS_SELLING_AGREEMENTS_FILTER),
  getAccountGOSSellingAgreementsList
);
