import { handleActions } from 'redux-actions';
import { createAsyncReducer, initialAsyncState } from '../utils/asyncHelpers';

import { HYDROGEN_FUEL_DATA_FETCH } from '../constants/actionTypes/hydrogenActionTypes';

const productionUnitHydrogenFuelReducer = handleActions(
  {
    ...createAsyncReducer(HYDROGEN_FUEL_DATA_FETCH)
  },
  initialAsyncState
);

export default productionUnitHydrogenFuelReducer;

export const getHydrogenFuelData = state =>
  state.getIn(['hydrogenFuelData', 'data']);
