import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'antd';
import './selectLocale.css';

export default class SelectLanguage extends PureComponent {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
  };

  localeButton = (locale, title) => {
    const {onClick, locale: selectedLocale} = this.props;
    return (
      <Button
        onClick={() => onClick(locale)}
        className={selectedLocale === locale ? 'active' : null}
      >
        {title}
      </Button>
    );
  };

  render() {
    return (
      <div className="select-locale">
        {this.localeButton('et', 'eesti keel')}
        {this.localeButton('en', 'English')}
      </div>
    );
  }
}
