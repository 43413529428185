import React from "react";
import {translate} from "react-i18next";
import {NAMESPACES} from "../../i18n";
import PropTypes from "prop-types";
import {Layout} from "antd";
import TsAgreementRegistration from "../../containers/TsAgreement/TsAgreementRegistration";

class TsAgreementRegistrationView extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  render() {
    const {t} = this.props;

    return (
      <Layout className="main-container">
        <h1>{t('tsRegistration')}</h1>
        <TsAgreementRegistration t={t}/>
      </Layout>
    );
  }
}

export default translate(NAMESPACES.tsAgreement)(
  TsAgreementRegistrationView
);
