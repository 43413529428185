import {
  createValidation,
  validatorMaxLength,
  validatorRequired,
} from '../../utils/formValidators';
import {luGeneralFormFields} from '../../constants/loadingUnit';

export default createValidation([
  {
    name: luGeneralFormFields.NAME,
    validators: [validatorRequired(), validatorMaxLength(40)],
  },
  {
    name: luGeneralFormFields.POWER,
    validators: [validatorRequired(), validatorMaxLength(40)],
  },
  {
    name: luGeneralFormFields.UNIT_MODEL,
    validators: validatorRequired(),
  }
]);
