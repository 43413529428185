import {handleActions} from 'redux-actions';
import {combineReducers} from 'redux-immutable';
import {
  createAsyncReducer,
  initialAsyncState,
  toSuccess,
} from '../utils/asyncHelpers';
import {
  ENTITY_DOCUMENT_ADD,
  ENTITY_DOCUMENT_DELETE,
  ENTITY_DOCUMENT_FETCH,
} from '../constants/registerActionTypes';

const list = handleActions(
  {
    ...createAsyncReducer(ENTITY_DOCUMENT_FETCH),
    [toSuccess(ENTITY_DOCUMENT_ADD)]: (state, {payload}) =>
      state.set('data', state.get('data').concat(payload.result)),
    [toSuccess(ENTITY_DOCUMENT_DELETE)]: (state, {payload}) =>
      state.set('data', state.get('data').filter(id => id !== payload)),
  },
  initialAsyncState
);
const registerEntityDocumentsReducer = combineReducers({
  list,
  add: handleActions(
    createAsyncReducer(ENTITY_DOCUMENT_ADD),
    initialAsyncState
  ),
  delete: handleActions(
    createAsyncReducer(ENTITY_DOCUMENT_DELETE),
    initialAsyncState
  ),
});

export const getEntityDocumentsList = state =>
  state.getIn(['registerEntityDocuments', 'list']);

export const getEntityDocumentsAddState = state =>
  state.getIn(['registerEntityDocuments', 'add']);

export const getEntityDocumentsDeleteState = state =>
  state.getIn(['registerEntityDocuments', 'delete']);

export default registerEntityDocumentsReducer;
