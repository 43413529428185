import {createAsyncPagingAction} from '../../utils/asyncPagingHelpers';
import * as actionTypes from '../../constants/actionTypes/electricConsumptionReportActionTypes';
import {reportElectricConsumptionReportItemsFetch} from '../actionHelpers/endpoints';
import {ecrResetMaster} from '../../reducers/electricConsumptionReportReducer';

export const fetchElectricConsumptionReportItemsPage = createAsyncPagingAction(
  actionTypes.ELECTRIC_CONSUMPTION_REPORT_ITEMS_FETCH,
  reportElectricConsumptionReportItemsFetch,
);

export const ecrResetMasterActionCreator = () => dispatch =>
  dispatch(ecrResetMaster());
