import {
  ACCOUNT_GROUPED_GOS_CERTIFICATES_FETCH
} from '../constants/actionTypes/accountGOSActionTypes';
import {createAsyncReducer, listInitialAsyncState} from '../utils/asyncHelpers';
import {handleActions} from 'redux-actions';
import {Map} from 'immutable';

const initialState = Map({
  groupedGOSCertificates: listInitialAsyncState,
});

const gosCertificateTransferReducer = handleActions(
  {
    ...createAsyncReducer(
      ACCOUNT_GROUPED_GOS_CERTIFICATES_FETCH,
      'groupedGOSCertificates'
    ),
  },
  initialState
);

export const getGroupedGOSCertificates = state =>
  state.getIn(['gosCertificateTransfer', 'groupedGOSCertificates']);

export const getGroupedGOSCertificatesData = state =>
  getGroupedGOSCertificates(state).get('data');

export default gosCertificateTransferReducer;
