import React, {Component} from 'react';
import PropTypesImmutable from 'react-immutable-proptypes';
import {Field, getFormValues, reduxForm} from 'redux-form/immutable';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Button, Col, Collapse, Row} from 'antd';
import {getRole} from '../../reducers/userReducer';
import * as actions from '../../actions/actionCreators/accountGOSListActionCreators';
import {DateField} from '../../atoms';
import {FormActions} from '../../components';
import {links} from '../../utils/gotoLink';
import {
  accountGOSFilterFields,
  FORM_ACCOUNT_GOS_FILTER,
} from '../../constants/formAccountGOSFilter';
import {downloadFileByGetURL} from "../../utils/files";
import moment from "moment";

const {Panel} = Collapse;

export class AccountGOSOverviewFilter extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    filterValues: PropTypesImmutable.map,
    userRole: PropTypes.string.isRequired,
    expirationDateChanged: PropTypes.func.isRequired,
    legalEntityId: PropTypes.any,
  };

  expirationDate = '';

  updateFilterFields = () => {
    this.expirationDate = this.expirationDateStr;
  };

  fieldObjToString = fieldObjValues => {
    let result = '';

    const objValues = fieldObjValues;

    let key = 0;

    for (key in objValues) {
      if (typeof objValues[key] === 'string') result += objValues[key];
    }

    return result;
  };

  expirationDateChange = (value, expirationDateChanged) => {
    if (!isNaN(value[0])) {
      const date = this.fieldObjToString(value);
      this.expirationDateStr = date;
      expirationDateChanged(date);
    } else {
      this.expirationDateStr = undefined;
      expirationDateChanged(null);
    }
  };

  updateSubmit = () => {
    this.updateFilterFields();
  };

  handleDownloadCSV = () => {
    const expirationDate = this.expirationDate ? moment(this.expirationDate, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY') : null;
    const params = expirationDate ? {expirationDate} : null;
    downloadFileByGetURL(links.accountGOSOverviewCsv, params, 'account-overview.csv');
  };

  render() {
    const {
      t,
      handleSubmit,
      reset,
      expirationDateChanged,
    } = this.props;

    return (
      <React.Fragment>
        <Collapse>
          <Panel key="filters" header={t('overview.filter.panelTitle')}>
            <form onSubmit={handleSubmit}>
              <Row gutter={24}>
                <Col span={12}>
                  <Field
                    className="date-field"
                    name={accountGOSFilterFields.EXPIRATION_DATE}
                    component={DateField}
                    label={t('overview.filter.expirationDate')}
                    hasFeedback={false}
                    onChange={value => {
                      this.expirationDateChange(value, expirationDateChanged);
                    }}
                  />
                </Col>
              </Row>
              <FormActions>
                <Button onClick={reset}>{t('overview.filter.clearBtn')}</Button>
                <Button
                  onClick={() => this.updateSubmit()}
                  type="primary"
                  htmlType="submit"
                >
                  {t('overview.filter.submitBtn')}
                </Button>
              </FormActions>
            </form>
          </Panel>
        </Collapse>
        <FormActions>
          <Button onClick={this.handleDownloadCSV}>
            {t('exportCsvBtn')}
          </Button>
        </FormActions>
      </React.Fragment>
    );
  }
}

const onSubmit = (values, dispatch) =>
  dispatch(actions.searchAccountGOS(values));

const getValues = getFormValues(FORM_ACCOUNT_GOS_FILTER);

const mapStateToProps = state => ({
  filterValues: getValues(state),
  userRole: getRole(state),
});

const mapDispatchToProps = {
  accountGOS: actions.searchAccountGOS,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_ACCOUNT_GOS_FILTER,
    onSubmit,
  })(AccountGOSOverviewFilter)
);
