import {createAsyncAction} from '../../utils/asyncHelpers';
import * as actionTypes from '../../constants/actionTypes/accountActionTypes';
import {
  accountReportSearchFetch,
  accountGroupedCertificatesFetch,
  accountCancelGroupedCertificatesFetch
} from '../actionHelpers/endpoints';

export const fetchAccountOverview = createAsyncAction(
  actionTypes.ACCOUNT_OVERVIEW_SEARCH_FETCH,
  accountGroupedCertificatesFetch
);

export const fetchGroupedCertificates = createAsyncAction(
  actionTypes.ACCOUNT_GROUPED_CERTIFICATES_FETCH,
  accountGroupedCertificatesFetch
);

export const fetchCancelGroupedCertificates = createAsyncAction(
  actionTypes.ACCOUNT_CANCEL_GROUPED_CERTIFICATES_FETCH,
  accountCancelGroupedCertificatesFetch
);

export const fetchAccountReport = createAsyncAction(
  actionTypes.ACCOUNT_REPORT_SEARCH_FETCH,
  accountReportSearchFetch
);
