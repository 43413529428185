import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Modal, Spin, notification} from 'antd';
import {RegisterActions} from '../../components';
import {RegisterRepresentPerson} from '../../forms';
import PropTypes from 'prop-types';
import {getPhysicalPersonId} from '../../reducers/userReducer';
import PropTypesImmutable from 'react-immutable-proptypes';
import {
  fetchMandatePerson,
  editRepresentPersonActionCreator,
  deleteMandatePerson,
  updateLegalEntityActionCreator,
} from '../../actions/actionCreators/registerActionCreators';
import {
  showRegisterRepresentPersonModal,
  hideRegisterRepresentPersonModal,
  getRegisterRepresentPersonModal,
} from '../../actions/actionCreators/modalsActionCreators';
import {getDeleteMandatePerson} from '../../reducers/registerAddMandateReducer';
import {
  getMandatePersonList,
  getMandatePersonListPure,
} from '../../reducers/registerReducer';
import {RegisterManagementCompany} from '../';
import MandatePersonsTable from '../../components/MandatePersonsTable/MandatePersonsTable';
import {formRegisterGeneralFields} from '../../constants/formRegister';
import legalEntityStatus from '../../constants/classificators/legalEntityStatus';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {goToLogin} from '../../utils/gotoLink';
import './RegisterManagement.css';

export class RegisterManagement extends Component {
  static propTypes = {
    fetchMandatePerson: PropTypes.func,
    isLoading: PropTypes.bool,
    mandatePersons: PropTypesImmutable.list,
    updateLegalEntity: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
    isAdmin: PropTypes.bool,
    physicalPersonId: PropTypes.number,
    showRequestChangeModal: PropTypes.func,
  };

  componentDidMount() {
    this.props.fetchMandatePerson(
      this.props.legalEntity.get(formRegisterGeneralFields.ID)
    );
  }

  onMasterSubmit = async () => {
    const {updateLegalEntity, legalEntity, t, isAdmin} = this.props;

    await updateLegalEntity(
      legalEntity
        .set(
          formRegisterGeneralFields.STATUS,
          isAdmin ? legalEntityStatus.ACTIVE : legalEntityStatus.NEW
        )
        .toJS()
    );

    notification.success({
      message: t('registerSuccess.title'),
      description: t('registerSuccess.message'),
    });

    goToLogin();
  };

  render() {
    const {
      hasPrevious,
      hasDone,
      goPrevious,
      t,
      isLoading,
      mandatePersons,
      modalPersonVisible,
      hideRepresentPersonModal,
      showRepresentPersonModal,
      editRepresentPerson,
      deleteRepresentPerson,
      isEditMode,
      isAdmin,
      legalEntity,
      physicalPersonId,
      showRequestChangeModal,
    } = this.props;

    return (
      <Spin spinning={isLoading}>
        <div className="register-management-container">
          <h2 className="register-management-container__subtitle">
            {t('representation.subtitlePersons')}
          </h2>
          <MandatePersonsTable
            editRepresent={editRepresentPerson}
            deleteRepresent={deleteRepresentPerson}
            t={t}
            data={mandatePersons.toJS()}
            physicalPersonId={physicalPersonId}
          />
          <div className="register-management-container__add-button">
            <Button
              onClick={showRepresentPersonModal}
              size="large"
              type="primary"
            >
              {t('representation.buttonAddPersons')}
            </Button>
          </div>
          {isEditMode && (
            <RegisterManagementCompany t={t} legalEntity={legalEntity}/>
          )}
          <RegisterActions
            showRequestChangeModal={showRequestChangeModal}
            hasPrevious={hasPrevious}
            hasDone={hasDone}
            goPrevious={goPrevious}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
            showSaveButton={!isEditMode}
            goNext={() => {
              Modal.confirm({
                title: t('complete.title'),
                content: t('complete.content'),
                okText: t('complete.okText'),
                cancelText: t('complete.cancelText'),
                onOk: this.onMasterSubmit,
              });
            }}
            t={t}
          />
          {modalPersonVisible && (
            <Modal
              title={t('representation.titleModalPersons')}
              wrapClassName="register-management-container__modal"
              visible={modalPersonVisible}
              footer={null}
              onCancel={hideRepresentPersonModal}
              width={640}
            >
              <RegisterRepresentPerson
                closeModal={hideRepresentPersonModal}
                initialValues={{
                  originatorLegalEntityId: legalEntity.get('id'),
                }}
                t={t}
              />
            </Modal>
          )}
        </div>
      </Spin>
    );
  }
}

const getLoading = getLoadingSelector(
  getDeleteMandatePerson,
  getMandatePersonListPure
);

const mapStateToProps = state => ({
  isLoading: getLoading(state),
  mandatePersons: getMandatePersonList(state),
  modalPersonVisible: getRegisterRepresentPersonModal(state),
  physicalPersonId: getPhysicalPersonId(state),
});

const mapDispatchToProps = {
  editRepresentPerson: editRepresentPersonActionCreator,
  deleteRepresentPerson: deleteMandatePerson,
  hideRepresentPersonModal: hideRegisterRepresentPersonModal,
  showRepresentPersonModal: showRegisterRepresentPersonModal,
  updateLegalEntity: updateLegalEntityActionCreator,
  fetchMandatePerson,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterManagement);
