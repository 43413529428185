import {createAsyncAction, normalizeHelper} from '../../utils/asyncHelpers';
import legalEntityDocumentSchema from '../../schemas/legalEntityDocument';
import {
  legalEntityDocumentsAdd,
  legalEntityDocumentsDelete,
  legalEntityDocumentsFetch,
} from '../actionHelpers/endpoints';
import {
  ENTITY_DOCUMENT_ADD,
  ENTITY_DOCUMENT_DELETE,
  ENTITY_DOCUMENT_FETCH,
} from '../../constants/registerActionTypes';

export const fetchLegalEntityDocuments = createAsyncAction(
  ENTITY_DOCUMENT_FETCH,
  legalEntityDocumentsFetch,
  normalizeHelper([legalEntityDocumentSchema])
);

export const addLegalEntityDocument = createAsyncAction(
  ENTITY_DOCUMENT_ADD,
  legalEntityDocumentsAdd,
  normalizeHelper(legalEntityDocumentSchema)
);

export const deleteLegalEntityDocument = createAsyncAction(
  ENTITY_DOCUMENT_DELETE,
  legalEntityDocumentsDelete
);
