import React, {Component} from 'react';
import {Field, Fields, getFormValues, reduxForm} from 'redux-form/immutable';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Button, Checkbox, Col, Collapse, Row} from 'antd';
import * as actions from '../../actions/actionCreators/accountGOSTsAssignmentsActionCreators';
import {ClassificatorField, DateRangeField} from '../../atoms';
import {FormActions} from '../../components';
import {
  FORM_ACCOUNT_GOS_TS_ASSIGNMENTS_FILTER,
  tsAssignmentsFilter
} from "../../constants/formAccountGOSTsAssignmentsFilter";
import {tradingMethod, tsAssignmentState} from "../../constants/classificators";
import {change} from "redux-form";
import PropTypesImmutable from "react-immutable-proptypes";
import AutoCompleteAccountsIdField
  from "../../components/AutoCompleteAccountsField/AutoCompleteAccountsIdField";

const {Panel} = Collapse;

export class AccountGOSTsAssignmentsFilter extends Component {

  isShowCompletedAssignments = false;

  static propTypes = {
    t: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    filterValues: PropTypesImmutable.map,
    setIncludeCompleted: PropTypes.func,
    isAuctionsEnabled: PropTypes.bool.isRequired,
  };

  render() {
    const {t, handleSubmit, reset, setIncludeCompleted, isAuctionsEnabled} = this.props;

    const allowedTradingMethods = isAuctionsEnabled ?
      Object.values(tradingMethod) : tradingMethod.PRE_TRADING_AGREEMENT;

    return (
      <React.Fragment>
        <Collapse>
          <Panel key="purchasing-agreement-filters"
                 header={t('assignments.filter.panelTitle')}>
            <form onSubmit={handleSubmit(onSubmit.bind(this))}>
              <Row gutter={24}>
                <Col span={8}>
                  <Field
                    name={tsAssignmentsFilter.STATE}
                    component={ClassificatorField}
                    type={ClassificatorField.types.tsAssignmentState}
                    allowed={[tsAssignmentState.CREATED, tsAssignmentState.COMPLETED]}
                    label={t('assignments.filter.state')}
                    hasFeedback={false}
                  />
                </Col>
                <Col span={8}>
                  <Field
                    name={tsAssignmentsFilter.TYPE}
                    component={ClassificatorField}
                    type={ClassificatorField.types.tsAssignmentType}
                    label={t('assignments.filter.type')}
                    hasFeedback={false}
                  />
                </Col>
                <Col span={8}>
                  <Field
                    name={tsAssignmentsFilter.TRADING_METHOD}
                    component={ClassificatorField}
                    type={ClassificatorField.types.tradingMethod}
                    label={t('assignments.filter.tradingMethod')}
                    hasFeedback={false}
                    allowed={allowedTradingMethods}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Field
                    name={tsAssignmentsFilter.COUNTERPARTY_ID}
                    component={AutoCompleteAccountsIdField}
                    label={t('assignments.filter.counterparty')}
                    hasFeedback={false}
                  />
                </Col>
                <Col span={8}>
                  <Fields
                    names={[
                      tsAssignmentsFilter.DUE_DATE_START,
                      tsAssignmentsFilter.DUE_DATE_END,
                    ]}
                    component={DateRangeField}
                    allowClear={false}
                    hasFeedback={false}
                    label={t('assignments.filter.dueDate')}
                    placeholder={[
                      t('assignments.filter.dateFromPlaceholder'),
                      t('assignments.filter.dateToPlaceholder'),
                    ]}
                    useTime={false}
                  />
                </Col>
              </Row>
              <FormActions>
                <Button onClick={() => {
                  reset();
                  setIncludeCompleted(this.isShowCompletedAssignments);
                }}>
                  {t('assignments.filter.clearBtn')}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    this.isShowCompletedAssignments = true;
                    setIncludeCompleted(true);
                  }}>
                  {t('assignments.filter.submitBtn')}
                </Button>
              </FormActions>
            </form>
          </Panel>
        </Collapse>

        <FormActions>
          <div style={{
            float: 'left',
            paddingBottom: '15px'
          }}>
            <Checkbox
              onChange={event => {
                this.isShowCompletedAssignments = event.target.checked;
                setIncludeCompleted(event.target.checked);
                handleSubmit(onSubmit.bind(this))();
              }}
              checked={this.isShowCompletedAssignments}
            >
              {t('assignments.filter.showCompletedAssignments')}
            </Checkbox>
          </div>
        </FormActions>
      </React.Fragment>
    );
  }
}

const getValues = getFormValues(FORM_ACCOUNT_GOS_TS_ASSIGNMENTS_FILTER);

function onSubmit(values, dispatch) {
  return dispatch(actions.searchAccountGOSTsAssignments(values));
}

const mapStateToProps = state => ({
  filterValues: getValues(state)
});

const mapDispatchToProps = (dispatch) => ({
  setIncludeCompleted: value => dispatch(change(FORM_ACCOUNT_GOS_TS_ASSIGNMENTS_FILTER, 'isIncludeCompleted', value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_ACCOUNT_GOS_TS_ASSIGNMENTS_FILTER
  })(AccountGOSTsAssignmentsFilter)
);
