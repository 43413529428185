import React, {Component} from 'react';
import {reduxForm, Field} from 'redux-form/immutable';
import {AutoCompleteSelectField} from '../../components';
import {searchCompany} from '../../actions/actionHelpers/endpoints';
import {
  FORM_COMPANIES_AUTOCOMPLETE,
  companiesAutocompleteFormFields,
} from '../../constants/formAutocompleteCompanies';

export class AutocompleteCompanies extends Component {
  getDataSource = async value => {
    const {data} = await searchCompany(value);
    return data.map(item => ({
      value: item.id.toString(),
      label: `${item.name}, ${item.registrationCode}`,
    }));
  };

  render() {
    const {handleSubmit} = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Field
          component={AutoCompleteSelectField}
          name={companiesAutocompleteFormFields.COMPANY}
          label="Company"
          getDataSource={this.getDataSource}
        />
      </form>
    );
  }
}

const onSubmit = () => {
};

export default reduxForm({
  form: FORM_COMPANIES_AUTOCOMPLETE,
  onSubmit,
})(AutocompleteCompanies);
