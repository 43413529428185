import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'antd';
import {downloadFileByURL} from '../../utils/files';

class ReportFileUpload extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  };

  handleClick = async () => {
    const {name, url} = this.props;
    downloadFileByURL(url, name);
  };

  render() {
    const {t} = this.props;

    return (
      <Button icon="download" onClick={this.handleClick}>
        {t('downloadReport')}
      </Button>
    );
  }
}

export default ReportFileUpload;
