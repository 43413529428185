import {
  createAsyncActionStoreWrapper,
  createAsyncPagingAction
} from "../../utils/asyncPagingHelpers";
import {
  ACCOUNT_GOS_TS_AUCTIONS_LIST_FETCH,
  COMPLETED_TS_AUCTIONS_RESULT_FETCH,
  REFRESH_SEARCH_TIMER
} from "../../constants/accountGOSListActionTypes";
import {getFormValues} from "redux-form/immutable";
import {accountGOSAuctionsSearch, completedAuctionsResultFetch} from "../actionHelpers/endpoints";
import {FORM_ACCOUNT_GOS_AUCTIONS_FILTER} from "../../constants/formAccountGOSAuctionsFilter";
import {getAccountGOSAuctionsList} from "../../reducers/accountGOSAuctionsListReducer";
import {createAsyncAction} from "../../utils/asyncHelpers";

export const searchAccountGOSAuctions = createAsyncActionStoreWrapper(
  createAsyncPagingAction(
    ACCOUNT_GOS_TS_AUCTIONS_LIST_FETCH,
    accountGOSAuctionsSearch
  ),
  getFormValues(FORM_ACCOUNT_GOS_AUCTIONS_FILTER),
  getAccountGOSAuctionsList
);

export const getLast10CompletedAuctionResults = createAsyncAction(
  COMPLETED_TS_AUCTIONS_RESULT_FETCH,
  completedAuctionsResultFetch
)

export const refreshSearchTimer = (seconds) => {
  return {
    type: REFRESH_SEARCH_TIMER,
    payload: seconds,
  };
};
