import React, {Component} from 'react';
import {reduxForm, Field} from 'redux-form/immutable';
import {TextField} from '../../atoms';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {Row, Col, Button, Spin, notification} from 'antd';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormActions} from '../../components';
import {FORM_CLASSIFIERS_SUBSIDY_DATE_EDIT} from '../../constants/formKeys';
import {
  getClassifiersSubsidyDateChange,
  getClassifiersSubsidyDateItem,
  getClassifiersSubsidyDateItemData,
} from '../../reducers/classifiersReducer';
import * as actions from '../../actions/actionCreators/classifiersActionCreators';
import {subsidyDate} from '../../constants/parameters';
import {goToClassifiers} from '../../utils/gotoLink';

export class ParameterSubsidyDateEdit extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    fetchClassifierSubsidyDate: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  componentDidMount = () => {
    this.props.fetchClassifierSubsidyDate();
  };

  render() {
    const {t, handleSubmit, isLoading} = this.props;

    return (
      <Spin spinning={isLoading}>
        <h1>{t('parameterSubsidyDateTitle')}</h1>
        <form onSubmit={handleSubmit}>
          <Row gutter={32}>
            <Col span={24}>
              <Field
                className="date-field"
                name={subsidyDate.VALUE}
                label={t('fieldSubsidyDate')}
                component={TextField}
              />
            </Col>
          </Row>
          <FormActions>
            <Button onClick={goToClassifiers}>{t('buttonBack')}</Button>
            <Button htmlType="submit" type="primary">
              {t('buttonSave')}
            </Button>
          </FormActions>
        </form>
      </Spin>
    );
  }
}

const onSubmit = async (values, dispatch, ownProps) => {
  await dispatch(actions.changeClassifierSubsidyDate(values.toJS()));

  notification.info({
    message: ownProps.t('completeTitle'),
    description: ownProps.t('completeBody'),
  });
};

const getIsLoading = getLoadingSelector(
  getClassifiersSubsidyDateChange,
  getClassifiersSubsidyDateItem
);

const mapStateToProps = state => ({
  initialValues: getClassifiersSubsidyDateItemData(state),
  isLoading: getIsLoading(state),
});

const mapDispatchToProps = {
  fetchClassifierSubsidyDate: actions.fetchClassifierSubsidyDate,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_CLASSIFIERS_SUBSIDY_DATE_EDIT,
    onSubmit,
    enableReinitialize: true,
  })(ParameterSubsidyDateEdit)
);
