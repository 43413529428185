import {handleActions} from 'redux-actions';
import {Map} from 'immutable';
import * as actions from '../actions/pureActions/certificateActions';
import {createAsyncReducer, initialAsyncState} from '../utils/asyncHelpers';
import {TRANSFER_CERTIFICATES} from '../constants/actionTypes/transferringCertificatesActionTypes';

const initialState = Map({
  selectedReceiver: null,
  transferring: initialAsyncState,
});

export default handleActions(
  {
    ...createAsyncReducer(TRANSFER_CERTIFICATES, 'transferring'),
    [actions.selectReceiver]: (state, {payload}) =>
      state.merge({selectedReceiver: payload}),
  },
  initialState
);

export const getSelectedReceiver = state =>
  state.getIn(['transferringCertificate', 'selectedReceiver']);

export const getTransferring = state =>
  state.getIn(['transferringCertificate', 'transferring']);
