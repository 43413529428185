import {createAsyncPagingAction} from '../../utils/asyncPagingHelpers.js';
import * as actionTypes from '../../constants/actionTypes/reportGosActionTypes';
import {reportGosItemsFetch} from '../actionHelpers/endpoints';
import {resetGosRenewableReport} from '../../reducers/reportGosReducer';

export const fetchReportGosItemsPage = createAsyncPagingAction(
  actionTypes.REPORT_GOS_ITEMS_FETCH,
  reportGosItemsFetch
);

export const gosRenewableReportResetMasterActionCreator = () => dispatch =>
  dispatch(resetGosRenewableReport());
