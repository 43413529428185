import React from "react";
import {translate} from "react-i18next";
import {Button, Collapse, Layout} from 'antd';
import {VDSMessagesFilter} from "../../forms";
import {VDSMessagesListSearch} from "../../actions/actionHelpers/endpoints";
import VDSMessagesTable from "../../components/VDSMessagesTable/VDSMessagesTable";
import moment from "moment";
import {TableActions} from '../../components';
import {downloadFileByPostURL} from '../../utils/files';
import {links} from '../../utils';
import VDSMessageDetailsModal from "../VDSMessageDetailsModal/VDSMessageDetailsModal";

class VDSMessagesList extends React.Component {

  state = {
    filter: {},
    response: {content: [], totalElements: 0, size: 0},
    pager: {page: 1, size: 10},
    details: null,
    loading: true
  };

  componentDidMount() {
    this.load();
  }

  onFilter = (filter) => this.setState({filter, pager: {...this.state.pager, page: 1}}, this.load);

  handleExportVDSMessages = () => {
    const filterForm = this.createFilterForm();

    downloadFileByPostURL(
      links.vdsMessagesExportCsv,
      filterForm ? filterForm : {},
      'vds-messages.csv'
    );
  };

  createFilterForm = () => {
    const {filter} = this.state;
    const {requestId, requestType} = filter;
    const success = filter.success === '1' ? true : filter.success === '0' ? false : undefined;
    const requestDateFrom = filter.requestDate ? moment(filter.requestDate[0]).format('YYYY-MM-DD') : undefined;
    const requestDateTo = filter.requestDate ? moment(filter.requestDate[1]).format('YYYY-MM-DD') : undefined;

    return {requestId, requestType, success, requestDateFrom, requestDateTo};
  };

  load = () => {
    const {pager, filter} = this.state;
    !this.state.loading && this.setState({loading: true});
    const {requestId, requestType} = filter;
    const success = filter.success === '1' ? true : filter.success === '0' ? false : undefined;
    const requestDateFrom = filter.requestDate ? moment(filter.requestDate[0]).format('YYYY-MM-DD') : undefined;
    const requestDateTo = filter.requestDate ? moment(filter.requestDate[1]).format('YYYY-MM-DD') : undefined;
    const pagerCopy = Object.assign({}, pager); // ugly page adjustment
    pagerCopy.page = pagerCopy.page - 1;
    VDSMessagesListSearch({
      pager: pagerCopy,
      filter: {requestId, requestType, success, requestDateFrom, requestDateTo}
    })
      .then(response => this.setState({response: response.data, loading: false}))
      .catch(error => console.error(error) & this.setState({loading: false}));
  };

  setPage = (page) => this.setState({pager: {...this.state.pager, page: page}}, this.load);

  showDetails = (details) => this.setState({details});

  hideDetails = () => this.setState({details: null});

  render() {
    const {t} = this.props;
    const {response, pager, details, loading} = this.state || {};
    const {content, totalElements, size} = response || {};
    const {page} = pager || {};

    return (
      <Layout.Content>
        <h1>{t('title')}</h1>
        <div className="search-form-container">
          <Collapse>
            <Collapse.Panel key="filters" header={t('filter.title')}>
              <VDSMessagesFilter t={t} onFilter={this.onFilter}/>
            </Collapse.Panel>
          </Collapse>
          <TableActions>
            <Button onClick={this.handleExportVDSMessages}>
              {t('filter.exportCsvBtn')}
            </Button>
          </TableActions>
        </div>
        <div className="search-results-container">
          <VDSMessagesTable
            content={content}
            totalElements={totalElements}
            pageSize={size}
            page={page}
            loading={loading}
            setPage={this.setPage}
            showDetails={this.showDetails}
            t={t}
          />
        </div>
        <VDSMessageDetailsModal show={!!details} hideModal={this.hideDetails} details={details}
                                t={t}/>
      </Layout.Content>
    );
  }
}

export default translate('vdsMessages')(VDSMessagesList);
