export const TRANSFER_STATISTICS =
  'TRANSFER_STATISTICS';
export const LOCK_STATISTICS =
  'LOCK_STATISTICS';
export const ACCOUNT_STATISTICS_LIST_FETCH =
  'ACCOUNT_STATISTICS_LIST_FETCH';

export const statisticsConstants = {
  GHG: 'GHG',
  ENERGY_STATISTICS: 'ENERGY_STATISTICS'
};
