export const electricConsumptionReportFields = {
  ID: 'id',
  TRANSACTION_NUMBER: 'transactionNumber',
  CONSUMPTION_PERIOD: 'consumptionPeriod',
  ORIGINAL_ENERGY_AMOUNT: 'originalEnergyAmount',
  NATURAL_ENERGY_AMOUNT: 'naturalEnergyAmount',
  CALCULATED_MULTIPLIER: 'calculatedMultiplier',
  CALCULATED_ENERGY_AMOUNT: 'calculatedEnergyAmount',
  ADJUSTED_ENERGY_AMOUNT: 'adjustedEnergyAmount',
  GHG_CAPACITY: 'ghgCapacity'
};
