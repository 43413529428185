import React, {Component} from 'react';
import {reduxForm, Field} from 'redux-form/immutable';
import {NumberField} from '../../atoms';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {Row, Col, Button, Spin, notification} from 'antd';
import {Map} from 'immutable';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormActions} from '../../components';
import {FORM_CLASSIFIERS_TAX_EDIT} from '../../constants/formKeys';
import {
  getClassifiersTaxChange,
  getClassifiersTaxItem,
  getClassifiersTaxItemData,
} from '../../reducers/classifiersReducer';
import * as actions from '../../actions/actionCreators/classifiersActionCreators';
import {classifierTaxEditFields} from '../../constants/classifier';
import {goToClassifiers} from '../../utils/gotoLink';

export class ClassifiersTaxEdit extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    fetchClassifierTax: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  componentDidMount = () => {
    this.props.fetchClassifierTax();
  };

  render() {
    const {t, handleSubmit, isLoading} = this.props;

    return (
      <Spin spinning={isLoading}>
        <h1>{t('taxTitle')}</h1>
        <form onSubmit={handleSubmit}>
          <Row gutter={32}>
            <Col span={24}>
              <Field
                className="date-field"
                name={classifierTaxEditFields.TAX}
                label={t('fieldTax')}
                component={NumberField}
                min={0.01}
                step={0.01}
              />
            </Col>
          </Row>
          <FormActions>
            <Button onClick={goToClassifiers}>{t('buttonBack')}</Button>
            <Button htmlType="submit" type="primary">
              {t('buttonSave')}
            </Button>
          </FormActions>
        </form>
      </Spin>
    );
  }
}

const onSubmit = async (values, dispatch, ownProps) => {
  await dispatch(actions.changeClassifierTax(values.toJS()));

  notification.info({
    message: ownProps.t('completeTitle'),
    description: ownProps.t('completeBody'),
  });
};

const getIsLoading = getLoadingSelector(
  getClassifiersTaxChange,
  getClassifiersTaxItem
);

const mapStateToProps = state => ({
  initialValues: Map({
    [classifierTaxEditFields.TAX]: getClassifiersTaxItemData(state),
  }),
  isLoading: getIsLoading(state),
});

const mapDispatchToProps = {
  fetchClassifierTax: actions.fetchClassifierTax,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_CLASSIFIERS_TAX_EDIT,
    onSubmit,
    enableReinitialize: true,
  })(ClassifiersTaxEdit)
);
