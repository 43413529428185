import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form/immutable';
import {connect} from 'react-redux';
import {TextField} from '../../atoms';
import {Row, Col} from 'antd';
import PropTypes from 'prop-types';
import {
  FORM_REGISTER_ENTITY_CONTACT,
  fromRegisterEntityContactFields,
} from '../../constants/formRegister';
import {updateLegalEntityActionCreator} from '../../actions/actionCreators/registerActionCreators';
import {parseCharAndSpace, parseNumber} from '../../utils/formHelpers';
import validate from './validateSchema';

export class RegisterLegalEntityContact extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    inEditForNotAdmin: PropTypes.bool.isRequired,
  };

  render() {
    const {handleSubmit, t, inEditForNotAdmin} = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Row gutter={32}>
          <Col className="gutter-row" span={12}>
            <Field
              label={t('entityContact.country')}
              required
              name={fromRegisterEntityContactFields.COUNTRY}
              component={TextField}
              parse={parseCharAndSpace}
              disabled={inEditForNotAdmin}
            />
          </Col>
          <Col className="gutter-row" span={12}>
            <Field
              name={fromRegisterEntityContactFields.CITY}
              component={TextField}
              type="text"
              label={t('entityContact.city')}
              parse={parseCharAndSpace}
              required
              disabled={inEditForNotAdmin}
            />
          </Col>
        </Row>
        <Row gutter={32}>
          <Col className="gutter-row" span={12}>
            <Field
              name={fromRegisterEntityContactFields.ADDRESS}
              component={TextField}
              type="text"
              label={t('entityContact.address')}
              required
              disabled={inEditForNotAdmin}
            />
          </Col>
          <Col className="gutter-row" span={12}>
            <Field
              name={fromRegisterEntityContactFields.ZIP_CODE}
              component={TextField}
              type="text"
              label={t('entityContact.zipCode')}
              parse={parseNumber}
              required
              disabled={inEditForNotAdmin}
            />
          </Col>
        </Row>
      </form>
    );
  }
}

const mapStateToProps = (state, props) => {
  const initialValues = props.legalEntity;
  return {
    initialValues,
  };
};

const onSubmit = (values, dispatch) => {
  dispatch(updateLegalEntityActionCreator(values.toJS()));
};

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_REGISTER_ENTITY_CONTACT,
    validate,
    enableReinitialize: true,
    onSubmit,
  })(RegisterLegalEntityContact)
);
