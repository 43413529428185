import React, {Component} from 'react';
import {debounce} from 'lodash';
import {Select} from '../../atoms';
import PropTypes from 'prop-types';

class AutoComplete extends Component {
  static propTypes = {
    getDataSource: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  state = {
    options: [],
    isLoading: false,
  };

  handleSearch = async value => {
    const {getDataSource, unacceptableValues} = this.props;

    if (!value) {
      this.setState({
        isLoading: false,
      });
      return;
    }

    this.setState({
      isLoading: true,
    });

    let options = await getDataSource(value);

    if (unacceptableValues && unacceptableValues.length > 0) {
      options = options.filter(
        item => !unacceptableValues.includes(item.value.toString())
      );
    }

    this.setState({
      options,
      isLoading: false,
    });
  };

  handleSearchDebounce = debounce(this.handleSearch, 500);

  render() {
    const {isLoading, options} = this.state;
    const {value} = this.props;

    return (
      <Select
        {...this.props}
        value={options.length === 0 ? null : value}
        style={{width: '100%'}}
        showSearch
        options={options}
        isLoading={isLoading}
        showArrow={false}
        onSearch={this.handleSearchDebounce}
        filterOption={false}
      />
    );
  }
}

export default AutoComplete;
