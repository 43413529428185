import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { NAMESPACES } from '../../i18n';
import { getRole } from '../../reducers/userReducer';
import { isAdmin } from '../../utils/roles';
import {
  goToHydrogenUnitSearch,
  goToProductionUnitSearch,
} from '../../utils/gotoLink';
import { AddEquipment } from '../';
import { getOwner } from '../../reducers/productionUnitAddReducer';
import { resetMasterActionCreator } from '../../actions/actionCreators/addEquipmentActionCreators';
import { isHydrogen } from '../../utils/productionUnitUtil';

export class ProductionUnitAdd extends Component {
  static propTypes = {
    fuelType: PropTypes.string,
    legalEntityId: PropTypes.number,
    role: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    resetMasterActionCreator: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    this.props.resetMasterActionCreator();
  };

  render() {
    const { t, legalEntityId, role, fuelType } = this.props;

    const isHydrogenContext = isHydrogen(fuelType);
    return (
      <div>
        <h2 className="register-management-container__subtitle">
          {isHydrogenContext
            ? t('productionUnit.hydrogenTitle')
            : t('productionUnit.title')}
        </h2>
        <AddEquipment
          fuelType={fuelType}
          legalEntityId={legalEntityId}
          isProductionUnitAdd={true}
          selectProductionUnitOwner={true}
          isAdmin={isAdmin(role)}
          isEditMode={false}
          onSubmitSuccess={
            isHydrogenContext
              ? goToHydrogenUnitSearch
              : goToProductionUnitSearch
          }
          isHydrogenContext={isHydrogenContext}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  legalEntityId: getOwner(state),
  role: getRole(state),
});

const mapDispatchToProps = {
  resetMasterActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  translate(NAMESPACES.register)(ProductionUnitAdd)
);
