import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Icon, Table} from 'antd';
import {isEmpty, isString} from 'lodash';
import {meteringPointDataFields} from '../../constants/meteringPoint';
import {translateMonthClassificator} from '../../utils/translateHelpers';

const totalName = 'total';

export default class MeteringPointAgrDataTable extends Component {
  static propTypes = {
    data: PropTypes.array,
    t: PropTypes.func.isRequired,
    isConsumption: PropTypes.bool.isRequired,
  };

  getColumns = () => {
    const {t, isConsumption} = this.props;

    const columns = [
      {
        dataIndex: meteringPointDataFields.MONTH,
        title: t('data.month'),
        render: value =>
          value !== totalName
            ? translateMonthClassificator(value)
            : t(`data.${totalName}`),
      },
      {
        dataIndex: this.getAmountFiledKwh(),
        title: (isConsumption) ? t('data.amountKwh') : t('data.amountKwh*'),
      },
    ];

    if (!isConsumption) {
      columns.push({
        dataIndex: meteringPointDataFields.PRODUCED_SALDO,
        title: t('data.correctionMJ'),
        render: value => isString(value) ? value : (value || 0).toFixed(3)
      });
    }

    columns.push({
      dataIndex: this.getAmountFiledM(),
      title: t('data.amountM'),
    });

    if (!isConsumption) {
      columns.push({
        dataIndex: meteringPointDataFields.CONFIRMED,
        title: t('data.confirmed'),
        render: value => value === null ? '' : (value ?
          <span className={'ant-message-success'}><Icon type="check"/></span> :
          <span className={'ant-message-error'}><Icon type="close"/></span>),
      });
    }

    columns.push({
      dataIndex: meteringPointDataFields.GO,
      title: t('data.go'),
    });

    if (!isConsumption) {
      columns.push({
        dataIndex: meteringPointDataFields.RECEIVED_SUPPORT,
        title: t('data.receivedSupport'),
      });
    }

    return columns;
  };

  getAmountFiledKwh = () => {
    const {isConsumption} = this.props;

    return isConsumption
      ? meteringPointDataFields.CONSUMED_AMOUNT_KWH
      : meteringPointDataFields.PRODUCED_AMOUNT_KWH;
  };

  getAmountFiledM = () => {
    const {isConsumption} = this.props;

    return isConsumption
      ? meteringPointDataFields.CONSUMED_AMOUNT_M
      : meteringPointDataFields.PRODUCED_AMOUNT_M;
  };

  sumObjects = (base, adding) => {
    const result = {...base};
    Object.keys(result)
      .filter(key => key !== meteringPointDataFields.MONTH && key !== meteringPointDataFields.CONFIRMED)
      .forEach(key => {
        result[key] += adding[key] ? adding[key] : 0;
      });

    return result;
  };

  totalToFixed = total => {
    const result = {...total};
    Object.keys(result)
      .filter(key => key !== meteringPointDataFields.MONTH && key !== meteringPointDataFields.CONFIRMED)
      .forEach(key => {
        result[key] = result[key].toFixed(2);
      });

    return result;
  };

  createDataWithSaldoRows = (data) => {
    const dataWithSaldoRows = [];
    data.forEach(row => {
      dataWithSaldoRows.push(row);
      if (meteringPointDataFields.AMOUNT_BY_MONTH) {
        const amountByMonth = Object.keys(row[meteringPointDataFields.AMOUNT_BY_MONTH]);
        if (amountByMonth.length > 1) {
          amountByMonth.forEach(key => {
            dataWithSaldoRows.push({
              [meteringPointDataFields.MONTH]: `${row[meteringPointDataFields.MONTH]} - ${key}`,
              [this.getAmountFiledKwh()]: `${translateMonthClassificator(key)}* ${row[meteringPointDataFields.AMOUNT_BY_MONTH][key].toFixed(3)}`,
              [meteringPointDataFields.PRODUCED_SALDO]: '',
              [this.getAmountFiledM()]: '',
              [meteringPointDataFields.CONFIRMED]: null,
              [meteringPointDataFields.GO]: '',
              [meteringPointDataFields.RECEIVED_SUPPORT]: '',
            });
          })
        }
      }
    });
    return dataWithSaldoRows;
  };

  calculateTotal = (data) => {
    const {isConsumption} = this.props;

    if (isEmpty(data)) return [];

    if (!isConsumption) {
      const dataWithSaldoRows = this.createDataWithSaldoRows(data);

      let totalRow = {
        [meteringPointDataFields.MONTH]: totalName,
        [this.getAmountFiledKwh()]: 0,
        [meteringPointDataFields.PRODUCED_SALDO]: 0,
        [this.getAmountFiledM()]: 0,
        [meteringPointDataFields.CONFIRMED]: null,
        [meteringPointDataFields.GO]: 0,
        [meteringPointDataFields.RECEIVED_SUPPORT]: 0,
      };

      totalRow = data.reduce(this.sumObjects, totalRow);
      totalRow = this.totalToFixed(totalRow);


      dataWithSaldoRows.push(totalRow);

      return dataWithSaldoRows;
    } else {
      let totalRow = {
        [meteringPointDataFields.MONTH]: totalName,
        [this.getAmountFiledKwh()]: 0,
        [this.getAmountFiledM()]: 0,
        [meteringPointDataFields.CONFIRMED]: null,
        [meteringPointDataFields.GO]: 0,
        [meteringPointDataFields.RECEIVED_SUPPORT]: 0,
      };

      totalRow = data.reduce(this.sumObjects, totalRow);

      return [...data, this.totalToFixed(totalRow)];
    }
  };


  render() {
    const {data} = this.props;

    return (
      <Table
        columns={this.getColumns()}
        rowKey={meteringPointDataFields.MONTH}
        dataSource={this.calculateTotal(data)}
        pagination={false}
      />
    );
  }
}
