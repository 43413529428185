import {schema} from 'normalizr';
import {consumerFields} from '../constants/consumer';

const avpConsumerScheme = new schema.Entity(
  'avpConsumer',
  {},
  {idAttribute: consumerFields.EICX_CODE}
);

export default avpConsumerScheme;
