import {createAsyncSelector} from '../utils/asyncHelpers';
import {handleActions} from 'redux-actions';
import {ACCOUNT_GOS_LIST_FETCH} from '../constants/accountGOSListActionTypes';
import accountGOSSchema from '../schemas/accountGOS';
import {
  listInitialAsyncPagingState,
  createAsyncPagingReducer,
} from '../utils/asyncPagingHelpers';

const accountGOSList = handleActions(
  {
    ...createAsyncPagingReducer(ACCOUNT_GOS_LIST_FETCH),
  },
  listInitialAsyncPagingState
);

export const getAccountGOSList = state =>
  state.get('accountGOSList');

export const getAccountGOSListData = createAsyncSelector(
  [accountGOSSchema],
  getAccountGOSList
);

export default accountGOSList;
