import React from 'react';
import {Layout} from 'antd';
import {LoadingUnit as LoadingUnitContainer} from '../../containers';

import './LoadingUnitList.css';

export default class LoadingUnitList extends React.Component {
  render() {
    return (
      <Layout className="main-container">
        <LoadingUnitContainer/>
      </Layout>
    );
  }
}
