import React from 'react';
import { searchCompany } from '../../actions/actionHelpers/endpoints';
import { AutoCompleteSelectField } from '../';
import { legalEntityFields } from '../../constants/legalEntity';

const getDataSource = async value => {
  const { data } = await searchCompany(value);
  return data
    .filter(
      item =>
        item[legalEntityFields.MANUFACTURER] ||
        item[legalEntityFields.HYDROGEN_MANUFACTURER] ||
        item[legalEntityFields.PROVIDER]
    )
    .map(item => ({
      value: item.id,
      label: `${item.name}, ${item.registrationCode}`,
    }));
};

const AutoCompleteProducersField = props => (
  <AutoCompleteSelectField {...props} getDataSource={getDataSource} />
);

export default AutoCompleteProducersField;
