import {Map} from 'immutable';
import {handleActions} from 'redux-actions';
import {
  createAsyncReducer,
  listInitialAsyncState,
  initialAsyncState,
  createAsyncSelector,
} from '../utils/asyncHelpers';
import {
  AVP_METERING_POINTS_FETCH,
  RECALCULATE_GO,
} from '../constants/actionTypes/manualGoIssuingActionTypes';
import {measurement as meteringPoint} from '../schemas';

const initialState = Map({
  points: listInitialAsyncState,
  recalculate: initialAsyncState,
});

const manualGoIssuingReducer = handleActions(
  {
    ...createAsyncReducer(AVP_METERING_POINTS_FETCH, 'points'),
    ...createAsyncReducer(RECALCULATE_GO, 'recalculate'),
  },
  initialState
);

export const getMeteringPoints = state =>
  state.getIn(['manualGoIssuing', 'points']);

export const getRecalculate = state =>
  state.getIn(['manualGoIssuing', 'recalculate']);

export const getMeteringPointsData = createAsyncSelector(
  [meteringPoint],
  getMeteringPoints
);

export default manualGoIssuingReducer;
