import {handleActions} from 'redux-actions';
import {combineReducers} from 'redux-immutable';
import {Map} from 'immutable';
import {
  createAsyncReducer,
  initialAsyncState,
  toSuccess,
  createAsyncSelector,
} from '../utils/asyncHelpers';
import {
  createAsyncPagingReducer,
  listInitialAsyncPagingState,
} from '../utils/asyncPagingHelpers';
import {
  toggleAddMode,
  resetMaster,
  resetMasterLegalList,
} from '../actions/pureActions/loadingUnitActions';
import {
  LOADING_UNIT_ADD,
  LOADING_UNIT_DELETE,
  LOADING_UNIT_ENTITY_FETCH,
  LOADING_UNIT_UPDATE,
} from '../constants/loadingUnit';
import {loadingUnitScheme} from '../schemas';

const list = handleActions(
  {
    ...createAsyncPagingReducer(LOADING_UNIT_ENTITY_FETCH),
    [toSuccess(LOADING_UNIT_ADD)]: (state, {payload}) =>
      state.set('data', state.get('data').concat([payload.result])),
    [toSuccess(LOADING_UNIT_DELETE)]: (state, {payload}) =>
      state.set('data', state.get('data').filter(id => id !== payload)),
    [resetMasterLegalList]: state => state.merge(listInitialAsyncPagingState),
  },
  listInitialAsyncPagingState
);
const ui = handleActions(
  {
    [toggleAddMode]: (state, {payload}) => state.set('addMode', payload),
  },
  Map({addMode: false})
);
const legalEntityLoadingUnitsReducer = combineReducers({
  list,
  add: handleActions(
    createAsyncReducer(LOADING_UNIT_ADD),
    initialAsyncState
  ),
  edit: handleActions(
    {
      ...createAsyncReducer(LOADING_UNIT_UPDATE),
      [resetMaster]: state => state.merge(initialAsyncState),
    },
    initialAsyncState
  ),
  delete: handleActions(
    createAsyncReducer(LOADING_UNIT_DELETE),
    initialAsyncState
  ),
  ui,
});

export const getLoadingUnitList = state =>
  state.getIn(['legalEntityLoadingUnits', 'list']);

export const getLoadingUnitListEntities = createAsyncSelector(
  [loadingUnitScheme],
  getLoadingUnitList
);

export const getLoadingUnitsAddState = state =>
  state.getIn(['legalEntityLoadingUnits', 'add']);

export const getLoadingUnitsEditState = state =>
  state.getIn(['legalEntityLoadingUnits', 'edit']);

export const getIsAddMode = state =>
  state.getIn(['legalEntityLoadingUnits', 'ui', 'addMode']);

export const getLoadingUnitsEditId = state =>
  state.getIn(['legalEntityLoadingUnits', 'edit', 'data']);

export const getLoadingUnitsAddId = state =>
  state.getIn(['legalEntityLoadingUnits', 'add', 'data']);

export const getLoadingUnitsDeleteState = state =>
  state.getIn(['legalEntityLoadingUnits', 'delete']);

export default legalEntityLoadingUnitsReducer;
