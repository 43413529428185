import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form/immutable';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {TextField, SelectField} from '../../atoms';
import {Row, Col, Spin, Button} from 'antd';
import {FormActions} from '../../components';
import {
  FORM_REGISTER_CONTACT,
  formRegisterContactFields,
} from '../../constants/formRegister';
import * as actions from '../../actions/actionCreators/registerContactsActionCreators';
import {hideRegisterContactModal} from '../../actions/actionCreators/modalsActionCreators';
import * as selectors from '../../reducers/registerContactsReducer';
import {phoneCodes} from '../../utils/phoneCodes';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {parseCharAndSpace} from '../../utils/formHelpers';
import validate from './validateSchema';
import './RegisterContact.css';

const codes = phoneCodes.map(item => ({value: item, label: item}));

class RegisterContact extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
  };

  render() {
    const {handleSubmit, t, isLoading} = this.props;

    return (
      <Spin spinning={isLoading}>
        <form className="form-register-contact" onSubmit={handleSubmit}>
          <Row gutter={32}>
            <Col className="gutter-row" span={12}>
              <Field
                name={formRegisterContactFields.CONTACT_PERSON}
                component={TextField}
                type="text"
                placeholder={t('contact.contact_person_placeholder')}
                label={t('contact.contact_person_label')}
                parse={parseCharAndSpace}
                required
              />
            </Col>
            <Col className="gutter-row" span={12}>
              <Row gutter={1}>
                <Col span={4}>
                  <Field
                    label={t('contact.phone_label')}
                    name={formRegisterContactFields.PHONE_CODE}
                    component={SelectField}
                    options={codes}
                    hasFeedback={false}
                    required
                  />
                </Col>
                <Col span={20}>
                  <Field
                    colon={false}
                    name={formRegisterContactFields.PHONE}
                    component={TextField}
                    type="text"
                    label=" "
                    placeholder={t('contact.phone_placeholder')}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col className="gutter-row" span={12}>
              <Field
                name={formRegisterContactFields.EMAIL}
                component={TextField}
                type="text"
                placeholder={t('contact.email_placeholder')}
                label={t('contact.email_label')}
                required
              />
            </Col>
          </Row>
          <FormActions>
            <Button size="large" type="primary" htmlType="submit">
              {t('contact.save')}
            </Button>
          </FormActions>
        </form>
      </Spin>
    );
  }
}

const mapStateToProps = state => {
  const isLoading = getLoadingSelector(
    selectors.getEntityContactsAddState,
    selectors.getEntityContactsDeleteState
  )(state);
  return {
    isLoading,
  };
};

const onSubmit = async (values, dispatch) => {
  if (values.get(formRegisterContactFields.ID)) {
    await dispatch(actions.updateLegalEntityContact(values.toJS()));
  } else {
    await dispatch(actions.addLegalEntityContact(values.toJS()));
  }
  dispatch(hideRegisterContactModal());
};

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_REGISTER_CONTACT,
    onSubmit,
    validate,
  })(RegisterContact)
);
