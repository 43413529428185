import {createAsyncSelector} from '../utils/asyncHelpers';
import {
  listInitialAsyncPagingState,
  createAsyncPagingReducer,
} from '../utils/asyncPagingHelpers';
import {handleActions} from 'redux-actions';
import {PRODUCTION_UNIT_LIST_FETCH} from '../constants/productionUnitListActionTypes';
import productionUnitSchema from '../schemas/productionUnit';

const productionUnitList = handleActions(
  {
    ...createAsyncPagingReducer(PRODUCTION_UNIT_LIST_FETCH),
  },
  listInitialAsyncPagingState
);

export const getProductionUnitList = state => state.get('productionUnitList');

export const getProductionUnitListData = createAsyncSelector(
  [productionUnitSchema],
  getProductionUnitList
);

export default productionUnitList;
