import {Map} from 'immutable';
import {handleActions} from 'redux-actions';
import {
  createAsyncReducer,
  listInitialAsyncState,
  initialAsyncState,
  createAsyncSelector,
} from '../utils/asyncHelpers';
import {
  AVP_METERING_POINTS_FETCH,
  CALCULATE_MEASUREMENT_DATA,
  REFRESH_MEASUREMENT_DATA
} from '../constants/actionTypes/manualElectricGosIssuingActionTypes';
import {measurement as meteringPoint} from '../schemas';

const initialState = Map({
  points: listInitialAsyncState,
  refresh: initialAsyncState,
  calculate: initialAsyncState
});

const manualElectricGosIssuingReducer = handleActions(
  {
    ...createAsyncReducer(AVP_METERING_POINTS_FETCH, 'points'),
    ...createAsyncReducer(REFRESH_MEASUREMENT_DATA, 'refresh'),
    ...createAsyncReducer(CALCULATE_MEASUREMENT_DATA, 'calculate')
  },
  initialState
);

export const getMeteringPoints = state =>
  state.getIn(['manualElectricGosIssuing', 'points']);

export const getRefresh = state =>
  state.getIn(['manualElectricGosIssuing', 'refresh']);

export const getCalculate = state =>
  state.getIn(['manualElectricGosIssuing', 'calculate']);

export const getMeteringPointsData = createAsyncSelector(
  [meteringPoint],
  getMeteringPoints
);

export default manualElectricGosIssuingReducer;
