import React from 'react';
import {connect} from 'react-redux';
import {Spin, Tabs} from 'antd';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import PropTypes from 'prop-types';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {getLocale} from '../../reducers/settingsReducer';
import {showRequestChangeLoadingUnitModal} from '../../actions/actionCreators/modalsActionCreators';
import {fetchAndEditLoadingUnit} from '../../actions/actionCreators/loadingUnitActionCreator';
import {getLoadingUnit, getLoadingUnitData} from '../../reducers/loadingUnitViewReducer';
import {luGeneralFormFields} from '../../constants/loadingUnit';
import {AddLoadingUnitGeneral, RegisterElectricMeasurementPoints} from '../../forms';

const {TabPane} = Tabs;

class LoadingUnitEdit extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    loadingUnit: PropTypes.object,
    loadingUnitId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isAdmin: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    fetchUnit: PropTypes.func.isRequired,
    legalEntityId: PropTypes.number,
    showRequestChange: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    const {loadingUnitId, fetchUnit} = this.props;

    if (loadingUnitId) fetchUnit(loadingUnitId);
  };

  render() {
    const {
      t,
      isEditMode,
      isAdmin,
      loadingUnit,
      isLoading,
      legalEntityId: passedLegalEntityId,
      showRequestChange,
    } = this.props;

    const legalEntityId = !loadingUnit.isEmpty()
      ? loadingUnit.get(luGeneralFormFields.LEGAL_ENTITY_ID)
      : passedLegalEntityId;

    return (
      <Spin spinning={isLoading}>
        <h1>{isEditMode ? t('titleEdit') : t('title')}</h1>
        <Tabs type="card">
          <TabPane tab={t('title_general')} key="1">
            <AddLoadingUnitGeneral
              isEditMode={isEditMode}
              t={t}
              legalEntityId={legalEntityId}
              loadingUnit={loadingUnit}
              isAdmin={isAdmin}
              inEditForNotAdmin={isEditMode && !isAdmin}
              isLoadingUnitAdd={false}
              showRequestChangeModal={showRequestChange}
            />
          </TabPane>
          <TabPane tab={t('title_metering_point')} key="2">
            <RegisterElectricMeasurementPoints
              isEditMode={isEditMode}
              t={t}
              legalEntityId={legalEntityId}
              loadingUnit={loadingUnit}
              isAdmin={isAdmin}
              inEditForNotAdmin={isEditMode && !isAdmin}
              onSubmitSuccess={() => undefined}
              showRequestChangeModal={showRequestChange}
            />
          </TabPane>
        </Tabs>
      </Spin>
    );
  }
}

const getIsLoading = getLoadingSelector(getLoadingUnit);

const mapStateToProps = state => ({
  loadingUnit: getLoadingUnitData(state),
  isLoading: getIsLoading(state),
  locale: getLocale(state),
});

const mapDispatchToProps = {
  fetchUnit: fetchAndEditLoadingUnit,
  showRequestChange: showRequestChangeLoadingUnitModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  translate(NAMESPACES.loadingUnit)(LoadingUnitEdit)
);
