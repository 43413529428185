import React, {Component} from 'react';
import {
  searchProviderAccountByNameOrProviderAccountNumber
} from '../../actions/actionHelpers/endpoints';
import {AutoCompleteSelectGOS} from '../';

const getDataSource = async value => {
  const {data} = await searchProviderAccountByNameOrProviderAccountNumber(value);
  return data.map(item => ({
    value: item.id,
    label: `${item.companyName} [${item.registrationCode}]`,
  }));
};

class AutoCompleteGOSAccountsSelect extends Component {
  render() {
    return <AutoCompleteSelectGOS {...this.props} getDataSource={getDataSource}/>;
  }
}

export default AutoCompleteGOSAccountsSelect;
