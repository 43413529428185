import {createAsyncAction, normalizeHelper} from '../../utils/asyncHelpers';
import {
  ACCOUNT_GOS_AGREEMENT_TRANSACTIONS_LIST_FETCH,
  AGREEMENT_FETCH,
  AGREEMENT_TRANSACTION_TRANSPORT_STATISTICS_FETCH
} from "../../constants/actionTypes/agreementViewActionTypes";
import agreement from "../../schemas/agreement";
import {
  agreementFetch,
  agreementFetchByTransaction,
  agreementTransactionsListFetch,
  transportStatisticsDetailsFetch
} from "../actionHelpers/endpoints";
import * as Actions from "../pureActions/agreementActions";
import {createAsyncPagingAction} from "../../utils/asyncPagingHelpers";

export const fetchAgreement = createAsyncAction(
  AGREEMENT_FETCH,
  agreementFetch,
  normalizeHelper(agreement)
);

export const fetchAgreementByPreTransaction = createAsyncAction(
  AGREEMENT_FETCH,
  agreementFetchByTransaction,
  normalizeHelper(agreement)
);

export const fetchAgreementTransactions = createAsyncPagingAction(
  ACCOUNT_GOS_AGREEMENT_TRANSACTIONS_LIST_FETCH,
  agreementTransactionsListFetch
)

export const fetchTransportStatisticsDetails = createAsyncAction(
  AGREEMENT_TRANSACTION_TRANSPORT_STATISTICS_FETCH,
  transportStatisticsDetailsFetch,
)

export const resetMaster = () => dispatch => dispatch(Actions.resetMaster());
