import {Modal} from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";

export function getInfoModal(isAdditionalInfoVisible, title, additionalInfo) {
  return <Modal
    onCancel={() => this.setState({isAdditionalInfoVisible: false})}
    visible={isAdditionalInfoVisible}
    footer={false}
    title={title}
    width="600px"
  >
    <TextArea
      value={additionalInfo}
      readOnly
      placeholder={"-"}
      autoSize={{minRows: 10, maxRows: 20}}
    />
  </Modal>;
}