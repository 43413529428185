import React, {Component} from 'react';
import ComponentWrapper from '../../atoms/reduxForms/ComponentWrapper';
import mapError from '../../atoms/reduxForms//mapError';
import AutoCompleteSelect from './AutoCompleteSelect';

export default class SelectField extends Component {
  render() {
    return (
      <ComponentWrapper
        {...this.props}
        mapProps={mapError}
        render={props => <AutoCompleteSelect {...props} />}
      />
    );
  }
}
