import React, {Component} from 'react';
import PropTypesImmutable from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {AccountGOSSearchTable} from '../../components';
import {getAccountGOSList, getAccountGOSListData,} from '../../reducers/accountGOSListReducer';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {
  getIsUserLoadingOperator,
  getIsUserProvider,
  getIsUserSeller,
  getRole
} from '../../reducers/userReducer';
import * as actions from '../../actions/actionCreators/accountGOSListActionCreators';
import * as selectActions from '../../actions/actionCreators/gosCertificateActionCreators';
import {
  goToAddAuction,
  goToTradingAccountLock,
  goToTradingAccountTransfer
} from '../../utils/gotoLink';
import {selectAuctionRows} from "../../actions/actionCreators/tsAuctionActionCreators";
import classifiersStore from "../../utils/classifiersStore";

export class AccountGOSList extends Component {
  static propTypes = {
    accountGOS: PropTypesImmutable.list,
    accountGOSList: PropTypesImmutable.map.isRequired,
    t: PropTypes.func.isRequired,
    searchAccountGOS: PropTypes.func.isRequired,
    isUserProvider: PropTypes.bool.isRequired,
    isUserTrader: PropTypes.bool.isRequired,
    isUserLoadingOperator: PropTypes.bool.isRequired,
    isAuctionsEnabled: PropTypes.bool.isRequired
  };

  componentDidMount() {
    const {searchAccountGOS} = this.props;
    searchAccountGOS(0);
  }

  render() {
    const {
      accountGOS,
      accountGOSList,
      selectAndGoToTransfer,
      selectAndGoToReport,
      selectAndGoToAddAuction,
      isUserProvider,
      isUserTrader,
      isUserLoadingOperator,
      isAuctionsEnabled,
      isBilateralTradingEnabled,
      ...rest
    } = this.props;

    return (
      <React.Fragment>
        <AccountGOSSearchTable
          accountGOS={accountGOS.toJS()}
          goToTransferCertificates={selectAndGoToTransfer}
          goToReportCertificates={selectAndGoToReport}
          goToAddAuction={selectAndGoToAddAuction}
          sendToMarketGOSCertificates={() => alert('TODO: implement')}
          isUserProvider={isUserProvider}
          isUserTrader={isUserTrader}
          isUserLoadingOperator={isUserLoadingOperator}
          isAuctionsEnabled={isAuctionsEnabled}
          isBilateralTradingEnabled={classifiersStore.getBooleanProperty('IS_BILATERAL_TRADING_ENABLED')}
          {...rest}
          {...accountGOSList.toJS()}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  accountGOSList: getAccountGOSList(state),
  accountGOS: getAccountGOSListData(state),
  loading: getLoadingSelector(getAccountGOSList)(state),
  userRole: getRole(state),
  isUserProvider: getIsUserProvider(state),
  isUserTrader: getIsUserSeller(state),
  isUserLoadingOperator: getIsUserLoadingOperator(state),
});

const mapDispatchToProps = dispatch => ({
  searchAccountGOS: page => dispatch(actions.searchAccountGOS(page)),
  selectAndGoToTransfer: async (selectedType, selectedTransferRows) => {
    await dispatch(selectActions.selectTransferRows(selectedTransferRows));
    goToTradingAccountTransfer(selectedType);
  },
  selectAndGoToReport: async (selectedType, selectedReportRows) => {
    await dispatch(selectActions.selectReportRows(selectedReportRows));
    goToTradingAccountLock(selectedType);
  },
  selectAndGoToAddAuction: async (selectedType, selectedAuctionRows) => {
    await dispatch(selectAuctionRows(selectedAuctionRows));
    goToAddAuction(selectedType);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountGOSList);
