import {createAction} from 'redux-actions';
import {Map, List, fromJS} from 'immutable';
import {showAlert} from '../actions/pureActions/alertsActions';
import alertTypes from '../constants/alertTypes';
import {generateAsyncActionTypes} from './asyncHelpers';

const createAsyncPagingAction = (
  constant,
  request,
  after = result => ({
    result,
  })
) => (...args) => {
  const {requestName, successName, failName} = generateAsyncActionTypes(
    constant
  );

  const pendingType = createAction(requestName);
  const completeType = createAction(successName);
  const errorType = createAction(failName);

  return async dispatch => {
    dispatch(pendingType());

    try {
      const response = await request(...args);
      const {
        totalPages,
        totalElements,
        number: page,
        content,
      } = response.data;
      const processedData = await after(content, dispatch);
      const payload = {
        ...processedData,
        totalPages,
        totalElements,
        page: page + 1,
      };
      dispatch(completeType(payload));
      return payload;
    } catch (error) {
      if (error.response) {
        dispatch(
          showAlert({
            type: alertTypes.ERROR,
            message: error.response.data.error,
          })
        );
        dispatch(errorType(error.response.data));
        return error.response.data;
      }
      dispatch(errorType(error));
      dispatch(
        showAlert({
          type: alertTypes.ERROR,
          message: error,
        })
      );

      return error;
    }
  };
};

const listInitialAsyncPagingState = Map({
  loading: false,
  data: List(),
  error: '',
  totalPages: 0,
  totalElements: 0,
  page: 1,
  pageSize: 10,
});

const _createAsyncPagingReducerRoot = asyncType => {
  const {requestName, successName, failName} = generateAsyncActionTypes(
    asyncType
  );

  return {
    [requestName]: state => state.merge({loading: true}),
    [successName]: (
      state,
      {payload: {result, page, totalPages, totalElements}}
    ) =>
      state.merge({
        loading: false,
        data: fromJS(result),
        page,
        totalPages,
        totalElements,
      }),
    [failName]: (state, {payload}) =>
      state.merge({
        loading: false,
        error: payload,
      }),
  };
};

const _createAsyncPagingReducerNode = (asyncType, node) => {
  const {requestName, successName, failName} = generateAsyncActionTypes(
    asyncType
  );

  return {
    [requestName]: state =>
      state.mergeIn([node], {
        loading: true,
      }),
    [successName]: (
      state,
      {payload: {result, page, totalPages, totalElements}}
    ) =>
      state.mergeIn([node], {
        loading: false,
        data: fromJS(result),
        page,
        totalPages,
        totalElements,
      }),
    [failName]: (state, {payload}) =>
      state.mergeIn([node], {
        loading: false,
        error: payload,
      }),
  };
};

const createAsyncPagingReducer = (asyncType, node) => {
  if (node) {
    return _createAsyncPagingReducerNode(asyncType, node);
  }

  return _createAsyncPagingReducerRoot(asyncType);
};

const getPageSizeHelper = list => list.get('pageSize');

const createAsyncActionStoreWrapper = (
  wrappedAction,
  filterSelector,
  listSelector
) => (currentPage = 0) => (dispatch, getState) => {
  const state = getState();

  const filterImmutable = filterSelector(state);
  const filter = filterImmutable ? filterImmutable.toJS() : {};
  const list = listSelector(state);
  const size = getPageSizeHelper(list);
  const page = currentPage !== 0 ? currentPage - 1 : 0;

  dispatch(wrappedAction({pager: {page, size}, filter}));
};

export {
  createAsyncPagingAction,
  createAsyncPagingReducer,
  listInitialAsyncPagingState,
  getPageSizeHelper,
  createAsyncActionStoreWrapper,
};
