import {createAsyncAction} from '../../utils/asyncHelpers';
import {
  TRANSACTION_GOS_RECALL,
} from '../../constants/actionTypes/transactionGOSViewActionTypes';
import {
  transactionGosRecall,
} from '../actionHelpers/endpoints';

export const recallGosTransaction = createAsyncAction(
  TRANSACTION_GOS_RECALL,
  transactionGosRecall,
);
