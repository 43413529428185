import React from 'react';
import {Icon, Layout, Menu} from "antd";
import {
  VDSSalesCancellationView,
  VDSSalesPromotionMessagesView,
  VDSStatisticsSendView,
  VDSTransmissionCancellationView,
  VDSTransmissionStatisticsView
} from "../../containers";
import './VDSTools.css';
import {translate} from 'react-i18next';
import {history} from "../../store";
import {Route, Switch} from "react-router-dom";
import {links} from "../../utils";
import {Admin} from "../../utils/roles";
import {ConnectedRouter} from "react-router-redux";
import {version} from "../../../package.json";
import {Logo} from "../../components";
import PropTypes from "prop-types";
import {
  currentPathSelector,
  goToVDSOfferCancelRequest,
  goToVDSOfferRequest,
  goToVDSReportRequest,
  goToVDSTransferCancelRequest,
  goToVDSTransferRequest
} from "../../utils/gotoLink";
import {ReactReduxContext} from "react-redux";
import {connect} from 'react-redux';

const {Content} = Layout;
const {Sider} = Layout;
const {SubMenu} = Menu;

class VDSTools extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    currentPath: PropTypes.string.isRequired
  };

  handleClick = (event) => {
    const menuHandlers = {
      reportRequest: () => goToVDSReportRequest(),
      transferRequest: () => goToVDSTransferRequest(),
      transferCancelRequest: () => goToVDSTransferCancelRequest(),
      offerRequest: () => goToVDSOfferRequest(),
      offerCancelRequest: () => goToVDSOfferCancelRequest(),
    };

    menuHandlers[event.key] && menuHandlers[event.key]();
  };

  determineActive = (path) => {
    const parts = path.split('/');
    let lastPart = parts.pop();
    if (!lastPart) {
      lastPart = parts.pop();
    }
    return [lastPart];
  };

  render() {
    const {t, currentPath} = this.props;
    const componentToFunction = (component) => (props) => React.createElement(component, props);

    return (
      <ReactReduxContext.Consumer>
        {({store}) => <ConnectedRouter history={history} store={store}>
          <Layout className="vds-tools">
            <Sider className="sidebar" width="300">
              <div className="sidebar__main">
                <Logo t={t}/>
                <hr/>
                <div className={'clearfix'}>
                  <a href={links.oauthLogout} className={'pull-right'}>
                    <Icon type="logout"/>{' '}
                  </a>
                </div>
              </div>
              <Menu mode="inline"
                    defaultOpenKeys={['vdsTools']}
                    onClick={this.handleClick}
                    selectedKeys={this.determineActive(currentPath)}>
                <SubMenu key="vdsTools" title={<span>{'VDS tööriist'}</span>}>
                  <Menu.Item key="reportRequest">{'Statistics report'}</Menu.Item>
                  <Menu.Item key="transferRequest">{'Statistics transfer'}</Menu.Item>
                  <Menu.Item key="transferCancelRequest">{'Transfer cancel'}</Menu.Item>
                  <Menu.Item key="offerRequest">{'Statistics offer'}</Menu.Item>
                  <Menu.Item key="offerCancelRequest">{'Offer cancel'}</Menu.Item>
                </SubMenu>
              </Menu>
              <div className="sidebar__version">{`v. ${version}`}</div>
            </Sider>
            <Content className={'main-container'}>
              <Switch>
                <Route exact path="/admin/vdsTools/reportRequest"
                       component={componentToFunction(Admin(VDSStatisticsSendView))}/>
                <Route exact path="/admin/vdsTools/transferRequest"
                       component={componentToFunction(Admin(VDSTransmissionStatisticsView))}/>
                <Route exact path="/admin/vdsTools/transferCancelRequest"
                       component={componentToFunction(Admin(VDSTransmissionCancellationView))}/>
                <Route exact path="/admin/vdsTools/offerRequest"
                       component={componentToFunction(Admin(VDSSalesPromotionMessagesView))}/>
                <Route exact path="/admin/vdsTools/offerCancelRequest"
                       component={componentToFunction(Admin(VDSSalesCancellationView))}/>
              </Switch>
            </Content>
          </Layout>
        </ConnectedRouter>}
      </ReactReduxContext.Consumer>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentPath: currentPathSelector(state)
  }
};

export default translate('sidebar')(connect(mapStateToProps)(VDSTools));
