import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Fields} from 'redux-form/immutable';
import {NumberField, TextField, ClassificatorField, StaticField} from '../../atoms';
import {Row, Col, Checkbox, Button, Spin, Select} from 'antd';
import {fuelsUsedFormFields} from '../../constants/formsAddEquipment';
import {translateFuelTypeClassificator} from '../../utils/translateHelpers';
import {tCommon} from '../../i18n';
import {get} from 'lodash';
import _ from 'lodash';

const FUEL_TYPE = 'FUEL_TYPE';
const FEEDSTOCK = 'FEEDSTOCK';
const PRODUCTION_PATHWAY = 'PRODUCTION_PATHWAY';

const {Option} = Select;

export default class FuelUnit extends Component {
  static propTypes = {
    fields: PropTypes.object.isRequired,
    totalPercentage: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
    inEditForNotAdmin: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    fetchClassifiers: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    monthlyFuelDataConfirmed: PropTypes.bool.isRequired,
    monthlyGoIssued: PropTypes.bool.isRequired
  };

  deleteField = e => {
    const {fields} = this.props;

    fields.remove(e.target.value);
  };

  renderInput = fields => {
    const {
      names,
      inEditForNotAdmin,
      max,
      step,
      isFloat,
      precision,
      defaultValue,
      isOpen,
      min,
      negativeAllowed = false,
      monthlyFuelDataConfirmed,
      monthlyGoIssued
    } = fields;
    if (
      get(fields, names[0]).input.value &&
      defaultValue &&
      !get(fields, names[1]).input.value
    ) {
      get(fields, names[1]).input.value = defaultValue;
      get(fields, names[1]).input.onChange(defaultValue);
    }
    if (!get(fields, names[0]).input.value) {
      get(fields, names[1]).input.value = '';
      get(fields, names[1]).input.onChange('');
    }

    return (
      <div>
        <NumberField
          {...get(fields, names[1]).input}
          max={max}
          min={min}
          negativeAllowed={negativeAllowed}
          precision={precision}
          step={step}
          isFloat={isFloat}
          onChange={value => get(fields, names[1]).input.onChange(value)}
          disabled={inEditForNotAdmin && !isOpen || (monthlyFuelDataConfirmed && monthlyGoIssued)}
        />
      </div>
    );
  };

  renderTxtInput = fields => {
    const {names, inEditForNotAdmin, isOpen, monthlyFuelDataConfirmed, monthlyGoIssued} = fields;
    return (
      <div>
        <TextField
          {...get(fields, names[1])}
          onChange={value => get(fields, names[1]).onChange(value)}
          disabled={inEditForNotAdmin && !isOpen || (monthlyFuelDataConfirmed && monthlyGoIssued)}
        />
      </div>
    );
  };

  renderClassificatorInput = fields => {
    const {names, inEditForNotAdmin, type, isOpen, defaultValue} = fields;

    if (
      get(fields, names[0]).input.value &&
      defaultValue &&
      !get(fields, names[1]).input.value
    ) {
      get(fields, names[1]).input.value = defaultValue;
      get(fields, names[1]).input.onChange(defaultValue);
    }

    if (!get(fields, names[0]).input.value) {
      get(fields, names[1]).input.value = null;
      get(fields, names[1]).input.onChange(null);
    }

    return (
      <div>
        <ClassificatorField
          {...get(fields, names[1]).input}
          type={type}
          onChange={value => get(fields, names[1]).input.onChange(value)}
          disabled={inEditForNotAdmin && !isOpen}
        />
      </div>
    );
  };

  renderClassificator = fields => {
    const {
      names,
      inEditForNotAdmin,
      type,
      isOpen,
      listData,
      locale,
      monthlyFuelDataConfirmed,
      monthlyGoIssued
    } = fields;

    const fuelType = get(fields, names[2]).input.value;
    const selectorValue = get(fields, names[1]).input.value;

    const classifierValue = get(listData.lines, fuelType);
    const classifierSub = (classifierValue !== undefined) ? get(classifierValue.mapLines, type) : undefined;


    let selectOptions = undefined;
    let currentValue = undefined;

    if (classifierSub !== undefined && classifierSub.classifierSubValues !== undefined && get(fields, names[0]).input.value) {
      let currentField = get(classifierSub.classifierSubValues, selectorValue);


      if (currentField !== undefined) {
        currentValue = currentField.code;
      }

      selectOptions = _.map(classifierSub.classifierSubValues, value => (
        <Option
          key={value.code}
          value={value.code}
        >
          {get(value.lang, locale)}
        </Option>
      ));
    }

    return (
      <div className={'ant-form-item-control'}>
        <Select
          disabled={inEditForNotAdmin && !isOpen || (monthlyFuelDataConfirmed && monthlyGoIssued)}
          dropdownMatchSelectWidth={false}
          value={currentValue}
          onChange={
            (value) => {
              get(fields, names[1]).input.onChange(value);
            }
          }
        >
          {selectOptions}
        </Select>
      </div>
    );
  };

  renderRawMaterialProperties = fields => {
    const {
      names,
      inEditForNotAdmin,
      type,
      isOpen,
      listData,
      monthlyFuelDataConfirmed,
      monthlyGoIssued
    } = fields;

    const fuelType = get(fields, names[2]).input.value;
    const feedstockValue = get(fields, names[1]).input.value;
    const biofuelType = get(fields, names[3]).input.value;

    const classifierValue = get(listData.lines, fuelType);
    const classifierSub = (classifierValue !== undefined) ? get(classifierValue.mapLines, type) : undefined;
    const classifierSubValue = (classifierSub !== undefined) ? get(classifierSub.classifierSubValues, feedstockValue) : undefined;

    let selectOptions = undefined;
    let currentValue = undefined;

    if (classifierSubValue !== undefined && classifierSubValue.biofuelTypes !== undefined && get(fields, names[0]).input.value) {
      let currentField = get(classifierSubValue.biofuelTypes, biofuelType);

      if (currentField !== undefined) {
        currentValue = currentField.biofuelType;
      }

      selectOptions = _.map(classifierSubValue.biofuelTypes, value => (
        <Option key={value.biofuelType} value={value.biofuelType}>
          {tCommon(`classificators.biofuelType.${value.biofuelType}`)}
        </Option>
      ));
    }

    return (
      <div className={'ant-form-item-control'}>
        <Select
          disabled={inEditForNotAdmin && !isOpen || (monthlyFuelDataConfirmed && monthlyGoIssued)}
          dropdownMatchSelectWidth={false}
          value={currentValue}
          onChange={
            (value) => {
              get(fields, names[3]).input.onChange(value);
            }
          }
        >
          {selectOptions}
        </Select>
      </div>
    );
  };

  renderRawMaterialPropertiesInput = fields => {
    const {
      names,
      propertyName,
      translationPrefix
    } = fields;

    const fuelType = get(fields, names[2]).input.value;
    const feedstockValue = get(fields, names[1]).input.value;
    const biofuelType = get(fields, names[3]).input.value;

    const classifierValue = get(this.state.classifierList.lines, fuelType);
    const classifierSub = (classifierValue !== undefined) ? get(classifierValue.mapLines, FEEDSTOCK) : undefined;
    const classifierSubValue = (classifierSub !== undefined) ? get(classifierSub.classifierSubValues, feedstockValue) : undefined;
    const currentBiofuelType = (classifierSubValue !== undefined) ? get(classifierSubValue.biofuelTypes, biofuelType) : undefined;

    if (currentBiofuelType !== undefined && get(fields, names[0]).input.value) {
      get(fields, names[4]).input.onChange(currentBiofuelType[propertyName]);
    }

    return (
      <div>
        <StaticField
          {...get(fields, names[4]).input}
          value={(currentBiofuelType !== undefined) ? tCommon(`${translationPrefix}${currentBiofuelType[propertyName]}`, currentBiofuelType[propertyName]) : undefined}
          disabled={true}
        />
      </div>
    );
  };

  renderCheckbox = fields => {
    const {names, inEditForNotAdmin, isOpen, monthlyFuelDataConfirmed, monthlyGoIssued} = fields;

    return (
      <div>
        <Checkbox
          onChange={value => get(fields, names[0]).input.onChange(value)}
          checked={get(fields, names[0]).input.value}
          disabled={inEditForNotAdmin && !isOpen || (monthlyFuelDataConfirmed && monthlyGoIssued)}
        >
          {translateFuelTypeClassificator(get(fields, names[1]).input.value)}
        </Checkbox>
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      clssifierList: null,
      isLoading: true,
    };
  }

  componentDidMount = () => {
    const {fetchClassifiers} = this.props;

    const classifiersFetch = async () =>
      await fetchClassifiers(FUEL_TYPE).then(data => this.setState({
        classifierList: data,
        isLoading: false
      }));

    classifiersFetch();
  };

  render() {
    const {
      fields,
      t,
      totalPercentage,
      locale,
      inEditForNotAdmin,
      monthlyFuelDataConfirmed,
      monthlyGoIssued,
      isAdmin,
    } = this.props;

    let isOpen = false;

    if (
      fields !== undefined &&
      (fields.get(0).get('status') === undefined ||
        fields.get(0).get('status') === 'OPEN')
    )
      isOpen = true;
    const showCountryFields = false;

    return (
      <Spin spinning={this.state.isLoading}>
        {this.state.isLoading === false &&
          <div style={{'overflowX': 'auto'}}>
            <Row type="flex" gutter={16} className="inline-flex">
              <Col className="wideTableTitle width-200">
                <h4>{t('fuelsUsed.fuelType')}</h4>
              </Col>
              <Col className="wideTableTitle width-200">
                <h4>{t('fuelsUsed.percentage')}</h4>
              </Col>
              <Col className="wideTableTitle width-200">
                <h4>{t('fuelsUsed.posNumber')}</h4>
              </Col>
              <Col className="wideTableTitle width-200">
                <h4>{t('fuelsUsed.feedstock')}</h4>
              </Col>
              <Col className="wideTableTitle width-200">
                <h4>{t('fuelsUsed.biofuelType')}</h4>
              </Col>
              <Col className="wideTableTitle width-200">
                <h4>{t('fuelsUsed.productionPathway')}</h4>
              </Col>
              <Col className="wideTableTitle width-200">
                <h4>{t('fuelsUsed.lowerCalorificValueKg')}</h4>
              </Col>
              <Col className="width-200">
                <h4>{t('fuelsUsed.higherCalorificValueKg')}</h4>
              </Col>
              <Col className="width-200">
                <h4>{t('fuelsUsed.chgIntensity')}</h4>
              </Col>
              {showCountryFields === true && (
                <Col className="width-200">
                  <h4>{t('fuelsUsed.feedstockCountry')}</h4>
                </Col>)}
              {showCountryFields === true && (
                <Col className="width-200">
                  <h4>{t('fuelsUsed.purchaseCountry')}</h4>
                </Col>)}
              <Col className="wideTableTitle width-100">
                <h4>{t('fuelsUsed.calculatedMultiplier')}</h4>
              </Col>
              <Col className="wideTableTitle width-100">
                <h4>{t('fuelsUsed.landUseCategory')}</h4>
              </Col>
              <Col className="wideTableTitle width-100">
                <h4>{t('fuelsUsed.landUseEmissions')}</h4>
              </Col>
            </Row>
            {fields.map((member, index) => (
              <Row key={index} type="flex" gutter={16} className="inline-flex">
                <Col className="width-200">
                  <Fields
                    names={[
                      `${member}.${fuelsUsedFormFields.IS_FUEL_USED}`,
                      `${member}.${fuelsUsedFormFields.FUEL_TYPE}`,
                    ]}
                    component={this.renderCheckbox}
                    t={t}
                    inEditForNotAdmin={inEditForNotAdmin}
                    monthlyFuelDataConfirmed={monthlyFuelDataConfirmed}
                    monthlyGoIssued={monthlyGoIssued}
                    isOpen={isOpen}
                  />
                </Col>
                <Col className="width-200">
                  <Fields
                    hasFeedback={false}
                    names={[
                      `${member}.${fuelsUsedFormFields.IS_FUEL_USED}`,
                      `${member}.${fuelsUsedFormFields.PERCENTAGE}`,
                    ]}
                    max={100}
                    min={0}
                    precision={0}
                    component={this.renderInput}
                    inEditForNotAdmin={inEditForNotAdmin}
                    monthlyFuelDataConfirmed={monthlyFuelDataConfirmed}
                    monthlyGoIssued={monthlyGoIssued}
                    isOpen={isOpen}
                  />
                </Col>
                <Col className="width-200">
                  <Fields
                    hasFeedback={false}
                    names={[
                      `${member}.${fuelsUsedFormFields.IS_FUEL_USED}`,
                      `${member}.${fuelsUsedFormFields.POS_NUMBER}`,
                    ]}
                    component={this.renderTxtInput}
                    inEditForNotAdmin={inEditForNotAdmin}
                    monthlyFuelDataConfirmed={monthlyFuelDataConfirmed}
                    monthlyGoIssued={monthlyGoIssued}
                    isOpen={isOpen}
                  />
                </Col>
                <Col className="width-200">
                  <Fields
                    names={[
                      `${member}.${fuelsUsedFormFields.IS_FUEL_USED}`,
                      `${member}.${fuelsUsedFormFields.FEEDSTOCK}`,
                      `${member}.${fuelsUsedFormFields.FUEL_TYPE}`,
                    ]}
                    hasFeedback={false}
                    component={this.renderClassificator}
                    member={member}
                    listData={this.state.classifierList}
                    inEditForNotAdmin={inEditForNotAdmin}
                    monthlyFuelDataConfirmed={monthlyFuelDataConfirmed}
                    monthlyGoIssued={monthlyGoIssued}
                    locale={locale}
                    isOpen={isOpen}
                    type={FEEDSTOCK}
                  />
                </Col>
                <Col className="width-200">
                  <Fields
                    names={[
                      `${member}.${fuelsUsedFormFields.IS_FUEL_USED}`,
                      `${member}.${fuelsUsedFormFields.FEEDSTOCK}`,
                      `${member}.${fuelsUsedFormFields.FUEL_TYPE}`,
                      `${member}.${fuelsUsedFormFields.BIOFUEL_TYPE}`
                    ]}
                    hasFeedback={false}
                    component={this.renderRawMaterialProperties}
                    member={member}
                    listData={this.state.classifierList}
                    inEditForNotAdmin={inEditForNotAdmin}
                    monthlyFuelDataConfirmed={monthlyFuelDataConfirmed}
                    monthlyGoIssued={monthlyGoIssued}
                    locale={locale}
                    isOpen={isOpen}
                    type={FEEDSTOCK}
                  />
                </Col>
                <Col className="width-200">
                  <Fields
                    names={[
                      `${member}.${fuelsUsedFormFields.IS_FUEL_USED}`,
                      `${member}.${fuelsUsedFormFields.PRODUCTION_PATHWAY}`,
                      `${member}.${fuelsUsedFormFields.FUEL_TYPE}`,
                    ]}
                    hasFeedback={false}
                    member={member}
                    component={this.renderClassificator}
                    listData={this.state.classifierList}
                    inEditForNotAdmin={inEditForNotAdmin}
                    monthlyFuelDataConfirmed={monthlyFuelDataConfirmed}
                    monthlyGoIssued={monthlyGoIssued}
                    locale={locale}
                    isOpen={isOpen}
                    type={PRODUCTION_PATHWAY}
                  />
                </Col>
                <Col className="width-200">
                  <Fields
                    hasFeedback={false}
                    step={0.1}
                    precision={1}
                    min={0}
                    isFloat
                    names={[
                      `${member}.${fuelsUsedFormFields.IS_FUEL_USED}`,
                      `${member}.${fuelsUsedFormFields.LOWER_CALORIFIC_VALUE_KG}`,
                    ]}
                    component={this.renderInput}
                    inEditForNotAdmin={inEditForNotAdmin}
                    monthlyFuelDataConfirmed={monthlyFuelDataConfirmed}
                    monthlyGoIssued={monthlyGoIssued}
                    isOpen={isOpen}
                  />
                </Col>
                <Col className="width-200">
                  <Fields
                    hasFeedback={false}
                    step={0.1}
                    precision={1}
                    min={0}
                    isFloat
                    names={[
                      `${member}.${fuelsUsedFormFields.IS_FUEL_USED}`,
                      `${member}.${fuelsUsedFormFields.HIGHER_CALORIFIC_VALUE_KG}`,
                    ]}
                    component={this.renderInput}
                    inEditForNotAdmin={inEditForNotAdmin}
                    monthlyFuelDataConfirmed={monthlyFuelDataConfirmed}
                    monthlyGoIssued={monthlyGoIssued}
                    isOpen={isOpen}
                  />
                </Col>
                <Col className="width-200">
                  <Fields
                    hasFeedback={false}
                    step={0.1}
                    precision={1}
                    isFloat
                    names={[
                      `${member}.${fuelsUsedFormFields.IS_FUEL_USED}`,
                      `${member}.${fuelsUsedFormFields.CHG_INTENSITY}`,
                    ]}
                    negativeAllowed={true}
                    component={this.renderInput}
                    inEditForNotAdmin={inEditForNotAdmin}
                    monthlyFuelDataConfirmed={monthlyFuelDataConfirmed}
                    monthlyGoIssued={monthlyGoIssued}
                    isOpen={isOpen}
                  />
                </Col>
                {showCountryFields === true && (
                  <Col className="width-200">
                    <Fields
                      hasFeedback={false}
                      defaultValue="EESTI"
                      names={[
                        `${member}.${fuelsUsedFormFields.IS_FUEL_USED}`,
                        `${member}.${fuelsUsedFormFields.FEEDSTOCK_COUNTRY}`,
                      ]}
                      component={this.renderClassificatorInput}
                      inEditForNotAdmin={inEditForNotAdmin}
                      monthlyFuelDataConfirmed={monthlyFuelDataConfirmed}
                      monthlyGoIssued={monthlyGoIssued}
                      isOpen={isOpen}
                      type={ClassificatorField.types.country}
                    />
                  </Col>)}
                {showCountryFields === true && (
                  <Col className="width-200">
                    <Fields
                      hasFeedback={false}
                      defaultValue="EESTI"
                      names={[
                        `${member}.${fuelsUsedFormFields.IS_FUEL_USED}`,
                        `${member}.${fuelsUsedFormFields.PURCHASE_COUNTRY}`,
                      ]}
                      component={this.renderClassificatorInput}
                      inEditForNotAdmin={inEditForNotAdmin}
                      monthlyFuelDataConfirmed={monthlyFuelDataConfirmed}
                      monthlyGoIssued={monthlyGoIssued}
                      isOpen={isOpen}
                      type={ClassificatorField.types.country}
                    />
                  </Col>)}
                <Col className="width-100">
                  <Fields
                    names={[
                      `${member}.${fuelsUsedFormFields.IS_FUEL_USED}`,
                      `${member}.${fuelsUsedFormFields.FEEDSTOCK}`,
                      `${member}.${fuelsUsedFormFields.FUEL_TYPE}`,
                      `${member}.${fuelsUsedFormFields.BIOFUEL_TYPE}`,
                      `${member}.${fuelsUsedFormFields.CALCULATED_MULTIPLIER}`
                    ]}
                    propertyName={fuelsUsedFormFields.CALCULATED_MULTIPLIER}
                    translationPrefix={''}
                    component={this.renderRawMaterialPropertiesInput}
                  />
                </Col>
                <Col className="width-100">
                  <Fields
                    names={[
                      `${member}.${fuelsUsedFormFields.IS_FUEL_USED}`,
                      `${member}.${fuelsUsedFormFields.FEEDSTOCK}`,
                      `${member}.${fuelsUsedFormFields.FUEL_TYPE}`,
                      `${member}.${fuelsUsedFormFields.BIOFUEL_TYPE}`,
                      `${member}.${fuelsUsedFormFields.LAND_USE_CATEGORY}`
                    ]}
                    translationPrefix={'classificators.landUseCategory.'}
                    propertyName={fuelsUsedFormFields.LAND_USE_CATEGORY}
                    component={this.renderRawMaterialPropertiesInput}
                  />
                </Col>
                <Col className="width-100">
                  <Fields
                    names={[
                      `${member}.${fuelsUsedFormFields.IS_FUEL_USED}`,
                      `${member}.${fuelsUsedFormFields.FEEDSTOCK}`,
                      `${member}.${fuelsUsedFormFields.FUEL_TYPE}`,
                      `${member}.${fuelsUsedFormFields.BIOFUEL_TYPE}`,
                      `${member}.${fuelsUsedFormFields.LAND_USE_EMISSIONS}`
                    ]}
                    translationPrefix={''}
                    propertyName={fuelsUsedFormFields.LAND_USE_EMISSIONS}
                    component={this.renderRawMaterialPropertiesInput}
                  />
                </Col>
                {(isOpen || isAdmin) && (
                  <React.Fragment>
                    <Col className="width-50">
                      <Button
                        value={index}
                        onClick={() => {
                          const newIndex = index + 1;
                          fields.insert(
                            newIndex,
                            fields.get(index).set(fuelsUsedFormFields.ID, null)
                          );
                        }}
                        icon="plus"
                        style={{
                          height: '16px',
                          width: '32px',
                          padding: '0px',
                          margin: '0px',
                          textAlign: 'center',
                          fontWeight: 'bolder',
                          border: 'none',
                        }}
                        disabled={monthlyFuelDataConfirmed && monthlyGoIssued}
                      />
                      <Button
                        value={index}
                        onClick={this.deleteField}
                        icon="minus"
                        style={{
                          height: '16px',
                          width: '32px',
                          margin: '0px',
                          padding: '0px',
                          textAlign: 'center',
                          fontWeight: 'bolder',
                          border: 'none',
                        }}
                        disabled={monthlyFuelDataConfirmed && monthlyGoIssued}
                      />
                    </Col>
                  </React.Fragment>
                )}
              </Row>
            ))}
            <Row type="flex" gutter={32}>
              <Col className="width-200"/>
              <Col className="width-200">{totalPercentage} %</Col>
            </Row>
          </div>
        }
      </Spin>
    );
  }
}
