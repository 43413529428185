export const consumerFields = {
  ID: 'id',
  PERSONAL_CODE: 'personalCode',
  REGISTER_CODE: 'registrationCode',
  EICX_CODE: 'eicxCode',
  COMPANY_NAME: 'name',
  NAME: 'name',
  CONSUMER_TYPE: 'consumerType',
  LEGAL_ENTITY: 'legalEntity',
  LEGAL_ENTITY_ID: 'legalEntityId',
};
