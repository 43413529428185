import {createAction} from 'redux-actions';

export const selectConsumerAction = createAction(
  'CANCELING_CERTIFICATE_CONSUMER_SELECT'
);
export const selectPointAction = createAction(
  'CANCELING_CERTIFICATE_METERING_POINT_SELECT'
);
export const selectPeriodsAction = createAction(
  'CANCELING_CERTIFICATE_PERIODS_SELECT'
);
export const resetAction = createAction('CANCELING_CERTIFICATE_RESET');
