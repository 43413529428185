import React, {useEffect, useState} from "react";
import {Col, Row, Spin} from "antd";
import {YearSelect} from "../../atoms";
import {electricIssuedTSCertificatesAmountsDataFetch} from "../../actions/actionHelpers/endpoints";
import {
  ElectricIssuesTSCertificatesAmountsTable
} from "../../components/ElectricIssuesTSCertificatesAmountsTable/ElectricIssuesTSCertificatesAmountsTable";

export function ElectricIssuedTSCertificatesAmounts({t}) {
  const [loading, setLoading] = useState(true);
  const [issuedData, setIssuedData] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    !loading && setLoading(true);
    electricIssuedTSCertificatesAmountsDataFetch({year})
      .then(response => setIssuedData(response.data) & setLoading(false))
      .catch(error => console.error(error) & setLoading(false));
  }, [year]);

  return (
    <Spin spinning={loading} className="metering-point-agr-data-container">
      <Row className="metering-point-agr-data-container__year-container">
        <Col span={8}>
          <YearSelect
            className="metering-point-agr-data-container__year-container__select"
            onChange={setYear}
            value={year}
          />
        </Col>
      </Row>
      <Row>
        <ElectricIssuesTSCertificatesAmountsTable t={t} data={issuedData}/>
      </Row>
    </Spin>
  );
}
