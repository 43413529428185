import React, {Component} from 'react';
import {reduxForm, Field} from 'redux-form/immutable';
import {TextField} from '../../atoms';
import {Map} from 'immutable';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {Row, Col, Button, Spin, notification} from 'antd';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormActions} from '../../components';
import {FORM_CLASSIFIERS_GRID_OPERATOR_EDIT} from '../../constants/formKeys';
import {
  getClassifiersGridOperatorChange,
  getClassifiersGridOperatorItem,
  getClassifiersGridOperatorItemData,
} from '../../reducers/classifiersReducer';
import * as actions from '../../actions/actionCreators/classifiersActionCreators';
import {gridOperator} from '../../constants/parameters';
import validate from './validateSchema';

export class ParameterGridOperatorEdit extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    fetchClassifierGridOperator: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    operatorCode: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    const {operatorCode} = this.props;

    if (operatorCode) {
      this.props.fetchClassifierGridOperator(operatorCode);
    }
  };

  render() {
    const {t, handleSubmit, isLoading, operatorCode, onCancel} = this.props;

    return (
      <Spin spinning={isLoading}>
        <form onSubmit={handleSubmit}>
          <Row gutter={32}>
            <Col span={24}>
              <Field
                name={gridOperator.CODE}
                label={t('fieldCode')}
                component={TextField}
                disabled={!!operatorCode}
                required
              />
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={12}>
              <Field
                name={gridOperator.NAME}
                label={t('fieldGridOperatorName')}
                component={TextField}
                required
              />
            </Col>
            <Col span={12}>
              <Field
                name={gridOperator.EMAIL}
                label={t('fieldGridOperatorEmail')}
                component={TextField}
                required
              />
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={12}>
              <Field
                name={gridOperator.DATE_CRON}
                label={t('fieldGridOperatorDate')}
                component={TextField}
                required
              />
            </Col>
          </Row>
          <FormActions>
            <Button onClick={onCancel}>{t('buttonCancel')}</Button>
            <Button htmlType="submit" type="primary">
              {t('buttonSave')}
            </Button>
          </FormActions>
        </form>
      </Spin>
    );
  }
}

const onSubmit = async (values, dispatch, ownProps) => {
  const result = await dispatch(
    actions.changeClassifierGridOperator(values.toJS())
  );

  if (!result.error) {
    notification.info({
      message: ownProps.t('completeTitle'),
      description: ownProps.t('completeBody'),
    });

    ownProps.onComplete();
  }
  await dispatch(actions.fetchClassifierGridOperatorList());
};

const getIsLoading = getLoadingSelector(
  getClassifiersGridOperatorChange,
  getClassifiersGridOperatorItem
);

const mapStateToProps = (state, ownProps) => ({
  initialValues: ownProps.operatorCode
    ? getClassifiersGridOperatorItemData(state)
    : Map({}),
  isLoading: getIsLoading(state),
});

const mapDispatchToProps = {
  fetchClassifierGridOperator: actions.fetchClassifierGridOperator,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_CLASSIFIERS_GRID_OPERATOR_EDIT,
    onSubmit,
    validate,
    enableReinitialize: true,
  })(ParameterGridOperatorEdit)
);
