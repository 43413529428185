import React from 'react';
import PropTypes from 'prop-types';
import {Table} from 'antd';
import {invoiceHistoryFields} from '../../constants/invoice';
import {translateInvoiceStatusClassificator} from '../../utils/translateHelpers';

class InvoiceViewHistory extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
  };

  getColumns = () => {
    const {t} = this.props;

    return [
      {
        title: t('tableHistory.date'),
        dataIndex: invoiceHistoryFields.DATE,
      },
      {
        title: t('tableHistory.status'),
        dataIndex: invoiceHistoryFields.STATUS,
        render: translateInvoiceStatusClassificator,
      },
      {
        title: t('tableHistory.name'),
        dataIndex: invoiceHistoryFields.NAME,
      },
      {
        title: t('tableHistory.decision'),
        dataIndex: invoiceHistoryFields.DECISION,
      },
    ];
  };

  render() {
    const {data} = this.props;

    return (
      <div className="global-margin-top-16">
        <Table
          rowKey={invoiceHistoryFields.DATE}
          dataSource={data}
          columns={this.getColumns()}
          pagination={false}
        />
      </div>
    );
  }
}

export default InvoiceViewHistory;
