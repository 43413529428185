export const formTransactionSearchFields = {
  DATE_FROM: 'dateFrom',
  DATE_TO: 'dateTo',
  TRANSACTION_TYPE: 'transactionType',
  RECEIVER_ACCOUNT_NUMBER: 'receiverAccountId',
  SENDER_ACCOUNT_NUMBER: 'senderAccountId',
  FUEL_TYPE: 'fuelType',
  CLEANING_TECHNOLOGY_TYPE: 'technologyType',
  NUMBER: 'id',
  CURRENT_ACCOUNT_ID: 'currentAccountId',
};
