import {SubmissionError} from 'redux-form/immutable';

export const processAsyncResult = result => {
  if (result.error) throw new SubmissionError();
};

export const parseNumber = value =>
  value && value.toString().replace(/\D/g, '');

export const parseFloat = (precision, negativeAllowed = false) => value => {
  const replaceRegex = negativeAllowed ? /[^0-9.-]/g : /[^0-9.]/g;
  let replaced = value.replace(replaceRegex, '');
  if (negativeAllowed && replaced.startsWith('-')) {
    replaced = '-' + replaced.replaceAll('-', '');
  }
  const splitArr = replaced.split('.');
  if (splitArr.length > 1)
    return `${splitArr[0]}.${splitArr[1].slice(0, precision)}`;
  return replaced;
};

export const parseMegawatt = parseFloat(3);

export const parseCharAndSpace = value => value.replace(/\d/g, '');
