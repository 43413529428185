import * as actions from '../pureActions/loadingUnitActions';
import {createAsyncAction, normalizeHelper, toSuccess} from '../../utils/asyncHelpers';
import {
  activateLoadingUnit,
  deactivateLoadingUnit,
  electricMeasurementPointsFetch,
  loadingUnitFetch,
  loadingUnitAdd,
  loadingUnitUpdate,
  loadingUnitListSearch, loadingUnitDelete,
} from '../actionHelpers/endpoints';
import {
  LOADING_UNIT_ENTITY_FETCH,
  LOADING_UNIT_FETCH,
  LOADING_UNIT_UPDATE,
  LOADING_UNIT_MEASUREMENT_POINTS_FETCH,
  LOADING_UNIT_ACTIVATION,
  LOADING_UNIT_ADD,
  LOADING_UNIT_LIST_FETCH,
  LOADING_UNIT_FILTER_FORM, LOADING_UNIT_MEASUREMENT_POINTS_RESET, LOADING_UNIT_DELETE,
} from '../../constants/loadingUnit';
import {
  createAsyncActionStoreWrapper,
  createAsyncPagingAction,
} from '../../utils/asyncPagingHelpers';
import {loadingUnitScheme} from '../../schemas';
import {resetMaster, toggleAddMode} from '../pureActions/loadingUnitActions';
import {showLoadingUnitModal} from './modalsActionCreators';
import {MEASUREMENT_POINT_MODIFIED} from '../../reducers/loadingUnitMeasurementPointReducer';
import {getFormValues} from 'redux-form/immutable';
import {getLoadingUnitList} from '../../reducers/loadingUnitListReducer';

export const addLoadingUnitAction = createAsyncAction(
  LOADING_UNIT_ADD,
  loadingUnitAdd,
  (unit, dispatch) => {
    const normalized = normalizeHelper(loadingUnitScheme)(unit);
    dispatch({
      type: toSuccess(LOADING_UNIT_UPDATE),
      payload: normalized,
    });
    return normalized;
  }
);

export const updateLoadingUnitAction = createAsyncAction(
  LOADING_UNIT_UPDATE,
  loadingUnitUpdate,
  normalizeHelper(loadingUnitScheme)
);
export const deleteAction = createAsyncAction(
  LOADING_UNIT_DELETE,
  loadingUnitDelete,
);
export const fetchMeasurementPointsAction = createAsyncAction(
  LOADING_UNIT_MEASUREMENT_POINTS_FETCH,
  electricMeasurementPointsFetch,
  data => {
    const newData = data
      .map(point => {
        return {...point, [MEASUREMENT_POINT_MODIFIED]: false};
      });

    // .sort((p1, p2) => (p1.selected === p2.selected) ? 0 : (p1.selected ? -1 : 1));

    return newData;
  }
);

export const resetMeasurementPointsAction = () => dispatch => dispatch({type: LOADING_UNIT_MEASUREMENT_POINTS_RESET});

export const activateLoadingUnitAction = createAsyncAction(
  LOADING_UNIT_ACTIVATION,
  activateLoadingUnit,
  normalizeHelper(loadingUnitScheme)
);

export const deactivateLoadingUnitAction = createAsyncAction(
  LOADING_UNIT_ACTIVATION,
  deactivateLoadingUnit,
  normalizeHelper(loadingUnitScheme)
);

export const fetchLegalEntityLoadingUnitsPage = createAsyncPagingAction(
  LOADING_UNIT_ENTITY_FETCH,
  loadingUnitListSearch,
  async (data, dispatch) => {
    // const promises = [];
    // const normalData = data.map(unit => {
    //   promises.push(dispatch(fetchProductionUnitMeasurement(unit.id)));
    //   return { ...unit, measurementPoint: unit.measurementPointId };
    // });
    //
    // await Promise.all(promises);

    return normalizeHelper([loadingUnitScheme])(data);
  }
);

export const startEditLoadingUnit = id => dispatch => {
  dispatch(toggleAddMode(false));
  dispatch(resetMaster());
  dispatch({
    type: toSuccess(LOADING_UNIT_UPDATE),
    payload: {result: id},
  });
  dispatch(showLoadingUnitModal());
};

export const startAddLoadingUnit = () => dispatch => {
  dispatch(toggleAddMode(true));
  dispatch({
    type: toSuccess(LOADING_UNIT_UPDATE),
    payload: {result: null},
  });
  dispatch(resetMaster());
  dispatch(showLoadingUnitModal());
};

export const fetchAndEditLoadingUnit = createAsyncAction(
  LOADING_UNIT_FETCH,
  loadingUnitFetch,
  (data, dispatch) => {
    dispatch({
      type: toSuccess(LOADING_UNIT_UPDATE),
      payload: {result: data.id},
    });
    return normalizeHelper(loadingUnitScheme)(data);
  }
);

// admin search
const _searchLoadingUnits = createAsyncPagingAction(
  LOADING_UNIT_LIST_FETCH,
  loadingUnitListSearch,
  async (data, dispatch) => {
    // const promises = [];
    // const normalData = data.map(unit => {
    //   promises.push(dispatch(fetchProductionUnitMeasurement(unit.id)));
    //   return { ...unit, measurementPoint: unit.measurementPointId };
    // });
    //
    // await Promise.all(promises);

    // return normalizeHelper([loadingUnitScheme])(normalData);

    return normalizeHelper([loadingUnitScheme])(data);
  }
);

const filterFormSelector = getFormValues(LOADING_UNIT_FILTER_FORM);

export const searchLoadingUnits = createAsyncActionStoreWrapper(
  _searchLoadingUnits,
  filterFormSelector,
  getLoadingUnitList
);

export const changeMeteringPointSelectionAction = (eicZ, selected) => dispatch =>
  dispatch(actions.changeMeteringPointSelectionAction({eicZ, selected}));

export const changeMeteringPointConsumerAction = (eicZ, consumer) => dispatch =>
  dispatch(actions.changeMeteringPointConsumerAction({eicZ, consumer}));

export const changeMeteringPointHasDirectConnection = (eicZ, hasDirectConn) => dispatch =>
  dispatch(actions.changeMeteringPointHasDirectConnectionAction({eicZ, hasDirectConn}));

export const changeMeteringPointProdUnitEicw = (eicZ, eicw) => dispatch =>
  dispatch(actions.changeMeteringPointProdUnitEICWAction({eicZ, eicw}));

export const goNextActionCreator = () => dispatch =>
  dispatch(actions.goNextStepAction());

export const goPrevActionCreator = () => dispatch =>
  dispatch(actions.goPrevStepAction());

export const resetMasterActionCreator = () => dispatch =>
  dispatch(actions.resetMaster());

export const resetMasterLegalListActionCreator = () => dispatch =>
  dispatch(actions.resetMasterLegalList());

export const selectOwnerActionCreator = id => dispatch => dispatch(actions.selectOwner(id));
