import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Map} from 'immutable';
import {Field, reduxForm, SubmissionError} from 'redux-form/immutable';
import {TextField} from '../../atoms';
import {Button, Col, Row} from 'antd';
import {connect} from 'react-redux';
import validate from './validateSchema';
import RegisterActions from '../../components/RegisterActions/RegisterActions';
import {
  FORM_LU_ADD_GENERAL,
  loadingUnitStatus,
  luGeneralFormFields,
} from '../../constants/loadingUnit';
import {
  activateLoadingUnitAction,
  addLoadingUnitAction,
  deactivateLoadingUnitAction,
  selectOwnerActionCreator,
  updateLoadingUnitAction,
} from '../../actions/actionCreators/loadingUnitActionCreator';
import {parseMegawatt} from '../../utils/formHelpers';
import PropTypesImmutable from 'react-immutable-proptypes';
import {AutoCompleteCompanies} from '../../components';
import {legalEntityFields} from '../../constants/legalEntity';
import {legalEntityStatus} from '../../constants/classificators';

export class AddLoadingUnitGeneral extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func,
    hasPrevious: PropTypes.bool,
    hasDone: PropTypes.bool,
    goPrevious: PropTypes.func,
    inEditForNotAdmin: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    loadingUnit: PropTypesImmutable.map,
    legalEntityId: PropTypes.number,
    activate: PropTypes.func.isRequired,
    deactivate: PropTypes.func.isRequired,
    allowToSelectCompany: PropTypes.bool,
  };

  render() {
    const {
      handleSubmit,
      hasPrevious,
      hasDone,
      goPrevious,
      t,
      isEditMode,
      isAdmin,
      initialValues,
      activate,
      deactivate,
      legalEntityId,
      selectOwner,
      allowToSelectCompany,
    } = this.props;

    const unitStatus = initialValues.get(luGeneralFormFields.STATUS);
    const unitId = initialValues.get(luGeneralFormFields.ID);

    return (
      <div>
        {isAdmin && allowToSelectCompany && (
          <AutoCompleteCompanies
            onChange={selectOwner}
            companiesFilter={item =>
              item[legalEntityFields.STATUS] === legalEntityStatus.ACTIVE &&
              item[legalEntityFields.LOADING_OPERATOR]
            }
            value={legalEntityId}/>
        )}
        <form onSubmit={handleSubmit}>
          <h2>{t('general.title')}</h2>
          <Row gutter={32}>
            <Col span={12}>
              <Field
                label={t('general.name')}
                tooltip={t('general.nameTooltip')}
                required
                name={luGeneralFormFields.NAME}
                component={TextField}
              />
            </Col>
            <Col span={12}>
              <Field
                name={luGeneralFormFields.POWER}
                component={TextField}
                addonAfter={t('general.power.units')}
                type="text"
                parse={parseMegawatt}
                label={t('general.power.label')}
                required
              />
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={12}>
              <Field
                name={luGeneralFormFields.UNIT_MODEL}
                component={TextField}
                required
                type="text"
                label={t('general.unitModel')}
              />
            </Col>
          </Row>
          <RegisterActions
            hasPrevious={hasPrevious}
            hasDone={hasDone}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
            goPrevious={goPrevious}
            showSaveButton={true}
            showRequestChangeButton={false}
            t={t}
          >
            {unitId &&
              isEditMode &&
              isAdmin && (
                <Button
                  size="large"
                  type="primary"
                  onClick={
                    unitStatus === loadingUnitStatus.ACTIVE
                      ? () => deactivate(unitId)
                      : () => activate(unitId)
                  }
                >
                  {unitStatus === loadingUnitStatus.ACTIVE
                    ? t('general.deactivateBtn')
                    : t('general.activateBtn')}
                </Button>
              )}

            {unitId &&
              isEditMode &&
              !isAdmin &&
              unitStatus === loadingUnitStatus.ACTIVE && (
                <Button
                  size="large"
                  type="primary"
                  onClick={() => deactivate(unitId)}
                >
                  {t('general.deactivateBtn')}
                </Button>
              )}
          </RegisterActions>
        </form>
      </div>
    );
  }
}

const onSubmit = async (values, dispatch, props) => {
  let prePayload = values;

  const hasId = !!values.get(luGeneralFormFields.ID);

  if (!hasId) {
    prePayload = prePayload.set(luGeneralFormFields.LEGAL_ENTITY_ID, props.legalEntityId);
  }

  const payload = prePayload.toJS();
  const luAction = hasId ? updateLoadingUnitAction(payload) : addLoadingUnitAction(payload);

  const result = await dispatch(luAction);

  if ('error' in result) {
    throw new SubmissionError();
  }
};

const mapStateToProps = (state, ownProps) => {
  const initialValues = ownProps.loadingUnit || Map();
  return ({
    initialValues,
  });
};

const mapDispatchToProps = {
  selectOwner: selectOwnerActionCreator,
  activate: activateLoadingUnitAction,
  deactivate: deactivateLoadingUnitAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_LU_ADD_GENERAL,
    validate,
    enableReinitialize: true,
    forceUnregisterOnUnmount: true,
    onSubmit,
  })(AddLoadingUnitGeneral),
);
