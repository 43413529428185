import {createAsyncSelector} from '../utils/asyncHelpers';
import {
  listInitialAsyncPagingState,
  createAsyncPagingReducer,
} from '../utils/asyncPagingHelpers';
import {handleActions} from 'redux-actions';
import {CONSUMPTION_POINT_LIST_FETCH} from '../constants/consumptionPointListActionTypes';
import consumptionPointSchema from '../schemas/consumptionPoint';

const consumptionPointList = handleActions(
  {
    ...createAsyncPagingReducer(CONSUMPTION_POINT_LIST_FETCH),
  },
  listInitialAsyncPagingState
);

export const getConsumptionPointList = state =>
  state.get('consumptionPointList');

export const getConsumptionPointListData = createAsyncSelector(
  [consumptionPointSchema],
  getConsumptionPointList
);

export default consumptionPointList;
