import {
  createAsyncPagingAction,
  createAsyncActionStoreWrapper,
} from '../../utils/asyncPagingHelpers';
import {createAsyncAction, normalizeHelper} from '../../utils/asyncHelpers';
import {
  CONSUMER_GO_SEARCH_FETCH,
  CONSUMER_METERING_POINTS_FETCH,
} from '../../constants/actionTypes/consumerGoSearch';
import {
  consumerGoSearch,
  loggedConsumerPointFetch,
} from '../actionHelpers/endpoints';
import {getFormValues} from 'redux-form/immutable';
import {getConsumerGoList} from '../../reducers/consumerGoSearchReducer';
import {FORM_CONSUMER_GO_SEARCH} from '../../constants/formKeys';
import {measurement as meteringPointScheme} from '../../schemas';

const _searchConsumerGo = createAsyncPagingAction(
  CONSUMER_GO_SEARCH_FETCH,
  consumerGoSearch
);

export const fetchConsumerPoints = createAsyncAction(
  CONSUMER_METERING_POINTS_FETCH,
  loggedConsumerPointFetch,
  normalizeHelper([meteringPointScheme])
);

const filterFormSelector = getFormValues(FORM_CONSUMER_GO_SEARCH);

export const searchConsumerGo = createAsyncActionStoreWrapper(
  _searchConsumerGo,
  filterFormSelector,
  getConsumerGoList
);
