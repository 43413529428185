import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form/immutable';
import {TextField, ClassificatorField, DateField, RadioField} from '../../atoms';
import {Row, Col} from 'antd';
import {meteringPointFields} from '../../constants/meteringPoint';

const ConsumptionPoint = ({t, isAdmin, isAvp}) => {
  const isAvpOrNotAdmin = isAvp || !isAdmin;
  return (
    <div>
      <h2>{t('info.title')}</h2>
      <Row gutter={32}>
        <Col span={8}>
          <Field
            name={meteringPointFields.START_DATE}
            component={DateField}
            label={t('info.startDate')}
            disabled={isAvp}
            required={!isAvp}
          />
        </Col>
        <Col span={8}>
          <Field
            name={meteringPointFields.END_DATE}
            component={DateField}
            label={t('info.endDate')}
            disabled={isAvp}
          />
        </Col>
        <Col span={8}>
          <Field
            name={meteringPointFields.AUTOMATIC_CANCELLATION}
            component={RadioField}
            required
            label={t('info.automaticCancellation.title')}
            options={[
              {
                value: true,
                label: t('info.automaticCancellation.true'),
              },
              {
                value: false,
                label: t('info.automaticCancellation.false'),
              },
            ]}
          />
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={8}>
          <Field
            name={meteringPointFields.CONSUMPTION_TYPE}
            component={ClassificatorField}
            type={ClassificatorField.types.consumptionUsage}
            label={t('info.consumptionType')}
            required
          />
        </Col>
        <Col span={8}>
          <Field
            name={meteringPointFields.EICZ_CODE}
            component={TextField}
            label={t('info.eiczCode')}
            disabled={isAvpOrNotAdmin}
            required={!isAvpOrNotAdmin}
          />
        </Col>
        <Col span={8}>
          <Field
            name={meteringPointFields.DATA_SOURCE}
            component={ClassificatorField}
            type={ClassificatorField.types.meteringPointDataSource}
            label={t('info.dataSource')}
            disabled
          />
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={8}>
          <Field
            name={meteringPointFields.GRID_OPERATOR}
            component={TextField}
            label={t('info.gridOperator')}
            disabled
          />
        </Col>
        <Col span={8}>
          <Field
            name={meteringPointFields.COUNTY}
            component={ClassificatorField}
            type={ClassificatorField.types.counties}
            label={t('info.county')}
            disabled={isAvpOrNotAdmin}
            required={!isAvpOrNotAdmin}
          />
        </Col>
        <Col span={8}>
          <Field
            name={meteringPointFields.CITY}
            component={TextField}
            label={t('info.municipality')}
            disabled={isAvpOrNotAdmin}
            required={!isAvpOrNotAdmin}
          />
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={8}>
          <Field
            name={meteringPointFields.ADDRESS}
            component={TextField}
            label={t('info.address')}
            disabled
          />
        </Col>
        <Col span={8}>
          <Field
            name={meteringPointFields.ZIP_CODE}
            component={TextField}
            label={t('info.zipCode')}
            disabled={isAvpOrNotAdmin}
            required={!isAvpOrNotAdmin}
          />
        </Col>
        <Col span={8}>
          <Field
            name={meteringPointFields.DISTRICT}
            component={TextField}
            label={t('info.district')}
            disabled={isAvpOrNotAdmin}
            required={!isAvpOrNotAdmin}
          />
        </Col>
      </Row>
    </div>
  );
};

ConsumptionPoint.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  isAvp: PropTypes.bool.isRequired,
};

export default ConsumptionPoint;
