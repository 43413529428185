import {createValidation, validatorRequired} from '../../utils/formValidators';
import {
  classifierGeneralItemField,
  classifierGeneralItemLangField,
  classifierConsumptionUsageProperties,
  classifierFuelTypeProperties,
} from '../../constants/classifier';

export default createValidation([
  {
    name: classifierGeneralItemField.CODE,
    validators: validatorRequired(),
  },
  {
    name: `${classifierGeneralItemField.LANG}.${
      classifierGeneralItemLangField.EN
    }`,
    validators: validatorRequired(),
  },
  {
    name: `${classifierGeneralItemField.LANG}.${
      classifierGeneralItemLangField.ET
    }`,
    validators: validatorRequired(),
  },
  {
    name: `${classifierGeneralItemField.PROPERTIES}.${
      classifierConsumptionUsageProperties.START_DAY
    }`,
    validators: validatorRequired(),
  },
  {
    name: `${classifierGeneralItemField.PROPERTIES}.${
      classifierConsumptionUsageProperties.END_DAY
    }`,
    validators: validatorRequired(),
  },
  {
    name: `${classifierGeneralItemField.PROPERTIES}.${
      classifierFuelTypeProperties.CO2
    }`,
    validators: validatorRequired(),
  },
]);
