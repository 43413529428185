import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {connect} from 'react-redux';
import {
  getClassifierSubItem,
  getClassifierSubItemData,
  getClassifierGeneralChange,
} from '../../reducers/classifiersReducer';
import {
  showClassifierSubEditModal,
  hideClassifierSubEditModal,
  getClassifierSubEditModal,
} from '../../actions/actionCreators/modalsActionCreators';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {Spin, Modal, Button} from 'antd';
import {
  classifierGeneralItemField,
  classifierGeneralField,
} from '../../constants/classifier';
import * as actions from '../../actions/actionCreators/classifiersActionCreators';
import {ClassifierSubTable, TableActions} from '../../components';
import {ClassifierSubEdit as ClassifierSubEditForm} from '../../forms';
import {goToClassifierView} from '../../utils/gotoLink';

export class ClassifierSubView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    fetchSubClassifier: PropTypes.func.isRequired,
    classifierData: PropTypesImmutable.map,
    classifierId: PropTypes.string.isRequired,
    modalVisible: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    const {fetchSubClassifier, classifierSubId, hideModal} = this.props;

    fetchSubClassifier(classifierSubId);
    hideModal();
  };

  selectedCode = null;

  render() {
    const {
      classifierData,
      loading,
      t,
      classifierId,
      classifierSubId,
      deleteClassifier,
      modalVisible,
      hideModal,
      showModal,
    } = this.props;

    let lang = {};
    let id = 0;
    if (this.selectedCode && classifierData !== null) {
      const itemCode = classifierData
        .get(classifierGeneralField.ITEMS)
        .find(
          item =>
            item.get(classifierGeneralItemField.CODE) === this.selectedCode
        );
      if (itemCode) {
        lang = itemCode.get(classifierGeneralItemField.LANG);
        id = itemCode.get(classifierGeneralItemField.ID);
      }
    }

    return (
      <Spin spinning={loading}>
        {classifierData && (
          <ClassifierSubTable
            t={t}
            dataSource={classifierData.toJS()}
            classifierId={classifierId}
            classifierSubId={classifierSubId}
            deleteClassifier={deleteClassifier}
            showModal={code => {
              this.selectedCode = code;
              showModal();
            }}
          />
        )}
        {modalVisible && (
          <Modal
            title={t('modalTitle')}
            visible
            footer={null}
            onCancel={hideModal}
            width={640}
          >
            <ClassifierSubEditForm
              initialValues={{
                [classifierGeneralItemField.ACTIVE]: true,
                [classifierGeneralItemField.CODE]: this.selectedCode,
                [classifierGeneralItemField.LANG]: lang,
                [classifierGeneralItemField.ID]: id,
              }}
              classifierCode={classifierId}
              classifierSubId={classifierSubId}
              onCancel={hideModal}
              onComplete={hideModal}
              selectedCode={this.selectedCode}
              isEdit={!!this.selectedCode}
              t={t}
            />
          </Modal>
        )}
        <TableActions>
          <Button
            onClick={() => goToClassifierView(classifierId, classifierSubId)}>{t('buttonBack')}</Button>
          <Button
            type="primary"
            onClick={() => {
              this.selectedCode = null;
              showModal();
            }}
            className="global-margin-left-10"
          >
            {t('buttonValueAdd')}
          </Button>
        </TableActions>
      </Spin>
    );
  }
}

const getIsLoading = getLoadingSelector(
  getClassifierSubItem,
  getClassifierGeneralChange
);

const mapStateToProps = state => ({
  loading: getIsLoading(state),
  classifierData: getClassifierSubItemData(state),
  modalVisible: getClassifierSubEditModal(state),
});

const mapDispatchToProps = dispatch => ({
  fetchSubClassifier: (classifierSubId) => {
    dispatch(actions.fetchClassifierSub(classifierSubId));
  },
  deleteClassifier: async (classifierSubId, classifierCode) => {
    await dispatch(actions.deleteClassifierSub(classifierSubId, classifierCode));
    dispatch(actions.fetchClassifierSub(classifierSubId));
  },
  showModal: () => {
    dispatch(showClassifierSubEditModal());
  },
  hideModal: () => {
    dispatch(hideClassifierSubEditModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  ClassifierSubView
);
