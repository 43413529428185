export const transactionFields = {
  ID: 'id',
  TYPE: 'transactionType',
  CREATE_TIME: 'transactionCreateTime',
  GOS_AMOUNT: 'transactionGosAmount',
  GOS_INFORMATION: 'gosInformation',
  SENDER_ACCOUNT: 'fromAccount',
  RECEIVER_ACCOUNT: 'toAccount',
  END_DATE: 'endDate',
  START_DATE: 'startDate',
  STATUS: 'status',
  HISTORY: 'history',
  COMMENT: 'comment',
  SENDER_INFORMATION: 'senderInformation',
  RECEIVER_INFORMATION: 'receiverInformation',
  CONSUMER_INFORMATION: 'consumerInformation',
  CONSUMPTION_INFORMATION: 'consumptionInformation',
  TRANSACTION_CREATE_TIME: 'transactionCreateTime',
  GOS_SET_INFORMATION_LIST: 'gosSetInformationList',
  STILL_OWNED_BY_TO_ACCOUNT: 'stillOwnedByToAccount',
  TRANSACTION_GOS_AMOUNT_MJ: 'transactionGosAmountMj',
  TRANSACTION_GOS_NATURAL_AMOUNT_MJ: 'transactionGosNaturalAmountMj',
  TRADE_TYPE: 'tradeType'
};

export const taTransactionFields = {
  ROW_TYPE: 'type',
  IDS: 'ids',
  TRANSACTION_ID: 'transactionId',
  TRANSACTION_TYPE: 'transactionType',
  TRANSACTION_STATUS: 'transactionStatus',
  QUANTITY: 'quantity',
  SENDER_ID: 'senderId',
  SENDER: 'sender',
  SENDER_NUMBER: 'senderNumber',
  SENDER_REGISTRATION_CODE: 'senderRegistrationCode',
  RECEIVER_ID: 'receiverId',
  RECEIVER: 'receiver',
  RECEIVER_NUMBER: 'receiverNumber',
  RECEIVER_REGISTRATION_CODE: 'receiverRegistrationCode',
  TRANSACTION_AMOUNT_MJ: 'transactionAmountMj',
  TRANSACTION_NATURAL_AMOUNT_MJ: 'transactionNaturalAmountMj',
  TRANSACTION_DATE: 'transactionDate',
  TRANSACTION_DATE_TIME: 'transactionDateTime',
  TRADE_TYPE: 'tradeType'
};

export const transactionHistoryFields = {
  ID: 'id',
  END_TIME: 'endTime',
  START_TIME: 'startTime',
  STATUS: 'status',
  CREATED_DATE: 'createdDate',
};

export const transactionAccountFields = {
  ID: 'id',
  COMPANY: 'companyName',
  TYPE: 'accountType',
  ACCOUNT_NUMBER: 'accountNumber',
};

export const transactionGOSInfoFields = {
  GOS_AMOUNT: 'gosAmount',
  START_BALANCE: 'startBalance',
  END_BALANCE: 'endBalance',
  ISSUE_PERIOD: 'issuePeriod',
  UNIT_EICX: 'unitEicx',
  TECHNOLOGY: 'technology',
  FUEL_TYPE: 'fuelType',
  TRANSACTION_GOS_AMOUNT_MJ: 'transactionGosAmountMj',
  TRANSACTION_GOS_NATURAL_AMOUNT_MJ: 'transactionGosNaturalAmountMj',
};

export const transactionGOSLockFields = {
  BIO_DATE_START: 'bioDateStart',
  BIO_DATE_END: 'bioDateEnd',
};

export const transactionGOSSetFields = {
  GOS_SET_INDEX: 'gosSetIndex',
  GOS_SET_START_ID: 'gosSetStartId',
  GOS_SET_END_ID: 'gosSetEndId',
  GOS_AMOUNT_IN_SET: 'gosAmountInSet',
  GOS_VALUE_MJ_IN_SET: 'gosValueMjInSet',
  GOS_NATURAL_VALUE_MJ_IN_SET: 'gosNaturalValueMjInSet',
  GOS_COST: 'cost',
  GOS_LOCKING_PERIOD_START: 'lockingPeriodStart',
  GOS_LOCKING_PERIOD_END: 'lockingPeriodEnd',
  GOS_ISSUING_PERIOD: 'issuingPeriod',
};

export const transactionGOSAccountFields = {
  ACCOUNT_NUMBER: 'accountNumber',
  COMPANY_NAME: 'companyName',
  REGISTRATION_CODE: 'registrationCode',
};
