import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Checkbox, Col, message, Modal, Row, Spin} from 'antd';
import {connect} from 'react-redux';
import './TsResercationsConfirm.scss';
import {
  translateAgreementTypeClassificator as tAgreementType,
  translateTsFuelGenerationClassificator as tFuelGeneration,
  translateTsFuelTypeClassificator as tFuelType,
} from '../../utils/translateHelpers';
import {goToAccountGOSPreTradingAgreementDetails} from '../../utils/gotoLink';
import {FormActions} from '../../components';
import {change, Field, reduxForm} from 'redux-form/immutable';
import {FORM_RESERVATION_CONFIRM} from '../../constants/formKeys';
import {NumberField} from '../../atoms';
import validate from './ValidateSchema';
import {
  getReservationDetailsModal,
  hideReservationDetailsModal,
  showReservationDetailsModal,
} from '../../actions/actionCreators/modalsActionCreators';
import {formReservationConfirmFields} from '../../constants/forms/formReservationConfirm';
import moment from 'moment';
import {availableStatisticsTableFields} from '../../constants/availableStatisticsTableFields';
import {
  TsReservationsConfirmDetailsView
} from '../TsReservationsConfirmDetails/TsReservationsConfirmDetailsView';
import {
  reserveAndConfirm
} from '../../actions/actionCreators/accountGOSAvailableStatisticsActionCreators';
import {isString} from 'lodash';

export class TsReservationsConfirm extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    registerCode: PropTypes.string.isRequired,
    agreement: PropTypes.object.isRequired,
    statistics: PropTypes.array.isRequired,
    hideModal: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    reservationDetailsModal: PropTypes.bool.isRequired,
    showReservationDetailsModal: PropTypes.func.isRequired,
    setFormValue: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    checkboxState: false,
  };

  componentDidMount = () => {
    this.props.setFormValue(formReservationConfirmFields.PRICE, this.props.agreement.unitPrice);
  };

  handleView(agreementId) {
    this.props.hideModal();
    goToAccountGOSPreTradingAgreementDetails(this.props.registerCode, agreementId);
  }

  handleReservationDetailsModal = () => {
    const {showReservationDetailsModal} = this.props;
    showReservationDetailsModal();
  };

  renderRow = (label, value) => (
    <div>
      <span>{label}: </span>
      <span>{value}</span>
    </div>
  );

  render() {
    const {
      t,
      agreement,
      statistics,
      hideModal,
      handleSubmit,
      reservationDetailsModal,
      hideReservationDetailsModal,
    } = this.props;

    const {
      loading,
      checkboxState,
    } = this.state;

    const naturalAmountMj = statistics.reduce((total, item) => total + item[availableStatisticsTableFields.NATURAL_ENERGY_TRANSFER_AMOUNT], 0);
    const calculatedAmountMj = statistics.reduce((total, item) => total + item[availableStatisticsTableFields.CALCULATED_ENERGY_TRANSFER_AMOUNT], 0);
    const fulfilledPercentage = Math.round(naturalAmountMj / agreement.naturalAmountMj * 100);

    return (
      <Spin key="ReservationConfirmLoading" spinning={loading}>
        <form onSubmit={handleSubmit}>
          <Row gutter={32}>
            <Col span={24} className="detail-container">
              <div>
                <span>{t('confirm.agreementNr')}: </span>
                <a onClick={() => this.handleView(agreement.id)}>{agreement.id}</a>
              </div>
              {this.renderRow(t('confirm.counterparty'), agreement.counterpartyName)}
              {this.renderRow(t('confirm.createdAt'), moment().format('DD.MM.YYYY'))}
              <br/>
              {this.renderRow(t('confirm.agreementType'), tAgreementType(agreement.agreementType))}
              {this.renderRow(t('confirm.fuelType'), tFuelType(agreement.fuelType))}
              {this.renderRow(t('confirm.fuelGeneration'), tFuelGeneration(agreement.fuelGeneration))}
              <br/>
              {this.renderRow(t('confirm.naturalAmountMj'), naturalAmountMj.toFixed(1))}
              {this.renderRow(t('confirm.multiplier'), agreement.multiplier)}
              {this.renderRow(t('confirm.calculatedAmountMj'), calculatedAmountMj.toFixed(1))}
              {this.renderRow(t('confirm.fulfilledPercentage'),
                agreement.agreementType === 'ENTIRE' ?
                  t('confirm.none') :
                  fulfilledPercentage)}
              <br/>
              {this.renderRow(t('confirm.details'),
                <Button
                  shape="circle"
                  icon="select"
                  size="small"
                  type="primary"
                  title={t('confirm.viewBtn')}
                  onClick={() => this.handleReservationDetailsModal()}
                />)}
              <br/>
              <Field
                min={0}
                step={0.001}
                precision={3}
                name={formReservationConfirmFields.PRICE}
                label={t('confirm.price')}
                component={NumberField}
                isFloat
              />
              <br/>
            </Col>
          </Row>
          <>
            <Row gutter={32}>
              <Col span={24}>
                <Checkbox
                  onChange={event => this.setState({
                    checkboxState: event.target.checked,
                  })}
                  checked={checkboxState}
                >
                  {t('confirm.checkboxInfo')}
                </Checkbox>
              </Col>
            </Row>
            <br/>
            <FormActions>
              <Button onClick={hideModal}>{t('confirm.cancel')} </Button>
              <Button onClick={event => this.setState({loading: true})} disabled={!checkboxState}
                      type="primary"
                      htmlType="submit">
                {t('confirm.confirm')}
              </Button>
            </FormActions>
          </>
          {
            reservationDetailsModal && (
              <Modal
                onCancel={hideReservationDetailsModal}
                visible
                footer={false}
                title={t('confirm.details')}
                witdth="600px"
              >
                <TsReservationsConfirmDetailsView
                  t={t}
                  agreement={agreement}
                  statistics={statistics}
                  hideModal={hideReservationDetailsModal}
                />
              </Modal>
            )
          }
        </form>
      </Spin>
    );
  }
}

const onSubmit = async (values, dispatch, props) => {
  const tsPairs = props.statistics.map(item => {
    return {
      tsIds: item[availableStatisticsTableFields.GROUPED_STATISTICS_IDS],
      amount: item[availableStatisticsTableFields.NATURAL_ENERGY_TRANSFER_AMOUNT],
    };
  });

  return await dispatch(reserveAndConfirm({
    transferNow: true,
    tsAgreementId: props.agreement.id,
    tsPairs,
    ...values.toJS(),
  }));
};

const onSubmitSuccess = async (result, dispatch, props) => {
  const {onSuccess, hideModal} = props;
  if (result.error) {
    hideModal();
    return;
  }
  await onSuccess();
  hideModal();
};

const mapStateToProps = state => ({
  reservationDetailsModal: getReservationDetailsModal(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setFormValue: (fieldName, value) => dispatch(change(FORM_RESERVATION_CONFIRM, fieldName, value)),
  showReservationDetailsModal: () => dispatch(showReservationDetailsModal()),
  hideReservationDetailsModal: () => dispatch(hideReservationDetailsModal()),
});

const onSubmitFail = errors => {
  if (isString(errors)) {
    message.error(errors);
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_RESERVATION_CONFIRM,
    validate,
    onSubmit,
    onSubmitFail,
    onSubmitSuccess,
  })(TsReservationsConfirm),
);
