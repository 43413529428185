import {isEmpty} from 'lodash';
import moment from 'moment';
import {
  createValidation,
  validatorRequired,
  validatorLength,
  validatorDateGreaterThen,
} from '../../utils/formValidators';
import {FORMAT_DEFAULT} from '../../utils';
import {tRegister as t} from '../../i18n';

import {formRegisterRepresentativePersonFields} from '../../constants/formRegister';

const mandateLengthValidator = (value, form) => {
  const startDate = form.get(
    formRegisterRepresentativePersonFields.MANDATE_DATE_START
  );

  if (isEmpty(value)) {
    return undefined;
  }
  if (
    moment(value, FORMAT_DEFAULT).diff(
      moment(startDate, FORMAT_DEFAULT),
      'days'
    ) < 3
  ) {
    return t('representation.personMandateLengthError');
  }
  return undefined;
};

export default createValidation([
  {
    name: formRegisterRepresentativePersonFields.FIRST_NAME,
    validators: validatorRequired(),
  },
  {
    name: formRegisterRepresentativePersonFields.LAST_NAME,
    validators: validatorRequired(),
  },
  {
    name: formRegisterRepresentativePersonFields.MANDATE_DATE_START,
    validators: validatorRequired(),
  },
  {
    name: formRegisterRepresentativePersonFields.MANDATE_DATE_END,
    validators: [validatorDateGreaterThen(), mandateLengthValidator],
  },
  {
    name: formRegisterRepresentativePersonFields.PERSONAL_CODE,
    validators: [validatorRequired(), validatorLength(11)],
  },
]);
