import React, {Component} from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {connect} from 'react-redux';
import {getAlert} from '../../reducers/alertReducer';
import {message as antdMessage} from 'antd';

export class Alerts extends Component {
  static propTypes = {
    alert: ImmutablePropTypes.map,
  };

  componentDidUpdate = prevProps => {
    const {type, message} = this.props.alert.toJS();

    if (this.props.alert !== prevProps.alert && type && message) {
      antdMessage[type](message);
    }
  };

  render() {
    return <div/>;
  }
}

const mapStateToProps = state => ({
  alert: getAlert(state),
});

export default connect(mapStateToProps)(Alerts);
