export const auctionFields = {
  ID: 'id',
  FUEL_TYPE: 'fuelType',
  FUEL_GENERATION: 'fuelGeneration',
  NATURAL_AMOUNT_MJ: 'naturalAmountMj',
  ACCOUNTED_AMOUNT_MJ: 'accountedAmountMj',
  GHG_CAPACITY: 'ghgCapacity',
  GHG_AMOUNT: 'ghgAmount',
  CURRENT_BID: 'currentBid',
  CURRENT_BID_KHG: 'currentBidKhg',
  TRANSACTION_COST: 'transactionPrice',
  END_DATE: 'endDate',
  MULTIPLIER: 'multiplier',
  CONSUMPTION_PERIOD: 'consumptionPeriod',

  ACTIONS: 'actions'
}