import {handleActions} from 'redux-actions';
import {Map} from 'immutable';
import _ from 'lodash/fp';
import {
  goNextStepAction,
  goPrevStepAction,
  resetMaster,
} from '../actions/pureActions/addEquipmentActions';

const initialState = Map({
  currentStep: 0,
});

const STEPS_COUNT = 6;

export default handleActions(
  {
    [goNextStepAction]: state =>
      state.merge({
        currentStep: Math.min(state.get('currentStep') + 1, STEPS_COUNT),
      }),
    [goPrevStepAction]: state =>
      state.merge({
        currentStep: Math.max(state.get('currentStep') - 1, 0),
      }),
    [resetMaster]: state =>
      state.merge({
        currentStep: 0,
      }),
  },
  initialState
);

export const getCurrentStep = state =>
  state.getIn(['addEquipment', 'currentStep']);

export const hasPrevious = _.flow(getCurrentStep, _.lt(0));
export const hasNext = _.flow(getCurrentStep, _.gt(STEPS_COUNT - 1));
export const hasDone = _.flow(getCurrentStep, _.eq(STEPS_COUNT - 1));
