import {normalizeHelper} from '../../utils/asyncHelpers';
import {
  createAsyncPagingAction,
  createAsyncActionStoreWrapper,
} from '../../utils/asyncPagingHelpers';
import {PRODUCTION_UNIT_LIST_FETCH} from '../../constants/productionUnitListActionTypes';
import {productionUnitListSearch} from '../actionHelpers/endpoints';
import {
  fetchProductionUnitMeasurement
} from '../actionCreators/prodUnitMeasurementPointActionCreators';
import productionUnitSchema from '../../schemas/productionUnit';
import {getFormValues} from 'redux-form/immutable';
import {getProductionUnitList} from '../../reducers/productionUnitListReducer';
import {PRODUCTION_UNIT_FILTER_FORM} from '../../constants/formProductionUnitFilter';

const _searchProductionUnits = createAsyncPagingAction(
  PRODUCTION_UNIT_LIST_FETCH,
  productionUnitListSearch,
  async (data, dispatch) => {
    const promises = [];
    const normalData = data.map(unit => {
      promises.push(dispatch(fetchProductionUnitMeasurement(unit.id)));
      return {...unit, measurementPoint: unit.measurementPointId};
    });

    await Promise.all(promises);

    return normalizeHelper([productionUnitSchema])(normalData);
  }
);

const filterFormSelector = getFormValues(PRODUCTION_UNIT_FILTER_FORM);

export const searchProductionUnits = createAsyncActionStoreWrapper(
  _searchProductionUnits,
  filterFormSelector,
  getProductionUnitList
);
