import {
  createAsyncReducer,
  createAsyncSelector,
  initialAsyncState,
} from '../utils/asyncHelpers';
import {handleActions} from 'redux-actions';
import {
  ACCOUNT_GOS_TS_ASSIGNMENTS_COUNT_FETCH,
  ACCOUNT_GOS_TS_ASSIGNMENTS_LIST_FETCH
} from '../constants/accountGOSListActionTypes';
import {accountGOSTsAssignmentsSchema} from "../schemas";
import {createAsyncPagingReducer, listInitialAsyncPagingState,} from '../utils/asyncPagingHelpers';
import {Map} from "immutable";

const initialState = Map({
  list: listInitialAsyncPagingState,
  count: initialAsyncState,
});

const accountGOSTsAssignmentsList = handleActions(
  {
    ...createAsyncPagingReducer(ACCOUNT_GOS_TS_ASSIGNMENTS_LIST_FETCH, 'list'),
    ...createAsyncReducer(ACCOUNT_GOS_TS_ASSIGNMENTS_COUNT_FETCH, 'count'),
  },
  initialState
);

export const getAccountGOSTsAssignmentsList = state => {
  return state.get('accountGOSTsAssignmentsList').get('list');
};

export const getAccountGOSTsAssignmentCount = state => {
  return state.getIn(['accountGOSTsAssignmentsList', 'count', 'data', 'count']);
};

export const getAccountGOSTsAssignmentsListData = createAsyncSelector(
  [accountGOSTsAssignmentsSchema],
  getAccountGOSTsAssignmentsList
);

export default accountGOSTsAssignmentsList;
