import React from 'react';
import {Upload, Button, Icon} from 'antd';
import {FILE_URL} from '../../constants/file';
import PropTypes from 'prop-types';
import {getCsrfCookie} from "../../utils/cookieUtil";

const propsUpload = {
  action: FILE_URL,
  headers: {
    'X-XSRF-TOKEN': getCsrfCookie()
  }
};

export default class FileUpload extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  };

  render() {
    const {t, disabled} = this.props;

    return (
      <Upload {...propsUpload} {...this.props}>
        <Button disabled={disabled || false}>
          <Icon type="upload"/> {t('button_upload')}
        </Button>
      </Upload>
    );
  }
}
