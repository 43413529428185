import {STATISTICS_TYPE_GHG, STATISTICS_TYPE_VDS} from "../constants/StatisticsTypes";

export const generateBioToVDSXML = (csvString) => {
  const {data, rows} = parseCSV(csvString);
  if (!data || !data.length || !rows || !rows.length) {
    return '';
  }
  const row = rows[0];
  return `<?xml version="1.0" encoding="UTF-8"?>
<vds:BiofuelToReportRequest xsi:schemaLocation="http://emta_vds.x-road.eu/Elering_x-tee_liides_v3.xsd" xmlns:vds="http://emta_vds.x-road.eu" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
    <vds:EntryID>${data[0] || ''}</vds:EntryID>
    <vds:SourceID>${data[1] || ''}</vds:SourceID>
    <vds:SupplierCode>${data[2] || ''}</vds:SupplierCode>
    <vds:ReportYear>${data[3] || ''}</vds:ReportYear>
    <vds:ReportMonth>${data[4] || ''}</vds:ReportMonth>
    <vds:BiofuelRow>
        <vds:FuelTypeCode>${row[0] || ''}</vds:FuelTypeCode>
        <vds:CNCode>${row[1] || ''}</vds:CNCode>${row[2] ? `
        <vds:ConformityDocument>${row[2]}</vds:ConformityDocument>` : ''}${row[3] ? `
        <vds:IssuerCode>${row[3]}</vds:IssuerCode>` : ''}${row[4] ? `
        <vds:IssuerName>${row[4]}</vds:IssuerName>` : ''}
        <vds:QuantityEnergy>${row[5] || ''}</vds:QuantityEnergy>
        <vds:OfferQuantityEnergy>${row[6] || ''}</vds:OfferQuantityEnergy>${row[7] ? `
        <vds:PoSNumber>${row[7]}</vds:PoSNumber>` : ''}
        <vds:SustainableFuelFlag>${row[8] || ''}</vds:SustainableFuelFlag>
        <vds:FeedstockCode>${row[9] || ''}</vds:FeedstockCode>${row[10] ? `
        <vds:FeedstockValue>${row[10]}</vds:FeedstockValue>` : ''}
        <vds:ProductionPathwayCode>${row[11] || ''}</vds:ProductionPathwayCode>
        <vds:ProductionPathwayValue>${row[12] || ''}</vds:ProductionPathwayValue>
        <vds:LowerCalorificValue>${row[13] || ''}</vds:LowerCalorificValue>${row[14] ? `
        <vds:GHGIntensity>${row[14]}</vds:GHGIntensity>` : ''}${row[15] ? `
        <vds:AdvancedBiofuel>${row[15]}</vds:AdvancedBiofuel>` : ''}${row[16] ? `
        <vds:AccountingCoeff>${row[16]}</vds:AccountingCoeff>` : ''}${row[17] ? `
        <vds:InstallationStart>${row[17]}</vds:InstallationStart>` : ''}
    </vds:BiofuelRow>
</vds:BiofuelToReportRequest>`;
};

export const generateElectricityToVDSXML = (csvString) => {
  const {data, rows} = parseCSV(csvString);
  if (!data || !data.length || !rows || !rows.length) {
    return '';
  }
  return `<?xml version="1.0" encoding="UTF-8"?>
<vds:ElectricityToReportRequest xsi:schemaLocation="http://emta_vds.x-road.eu/Elering_x-tee_liides_v3.xsd" xmlns:vds="http://emta_vds.x-road.eu" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
    <vds:EntryID>${data[0] || ''}</vds:EntryID>
    <vds:SupplierCode>${data[1] || ''}</vds:SupplierCode>
    <vds:ReportYear>${data[2] || ''}</vds:ReportYear>
    <vds:ReportMonth>${data[3] || ''}</vds:ReportMonth>
    <vds:SourceID>${data[4] || ''}</vds:SourceID>
    <vds:OfferEnergy>${data[5] || ''}</vds:OfferEnergy>
    <vds:ElectricityRows>
${rows.map(row => `        <vds:ElectricityRow>
            <vds:ReferenceNo>${row[0] || ''}</vds:ReferenceNo>
            <vds:ExcludingPowertrain>${row[1] || ''}</vds:ExcludingPowertrain>
            <vds:SustainableInRoadTransport>${row[2] || ''}</vds:SustainableInRoadTransport>
        </vds:ElectricityRow>
`).join('')}    </vds:ElectricityRows>
</vds:ElectricityToReportRequest>`;
};

const parseCSV = (csvString) => {
  const lines = csvString.split('\n');
  let data = lines.splice(0, 1)[0];
  data = data.split(',');
  const rows = [];
  lines.forEach(line => {
    if (line) {
      rows.push(line.split(','));
    }
  });
  return {data, rows};
};

export const generateStatisticsRequestToVDSXML = (data) => {
  if (!data) {
    return '';
  }
  let transferSource = '';
  if (data.TransferSource.OfferID) {
    transferSource += `<vds:OfferID>${data.TransferSource.OfferID || ''}</vds:OfferID>`;
  }
  if (data.TransferSource.SourceID) {
    transferSource += `<vds:SourceID>${data.TransferSource.SourceID || ''}</vds:SourceID>`;
  }
  let purchased = '';
  if (data.Purchased) {
    if (data.Purchased.GHGAmount) {
      purchased += `<vds:GHGAmount>${data.Purchased.GHGAmount || ''}</vds:GHGAmount>`;
    } else if (data.Purchased.QuantityEnergyNatural) {
      purchased += `<vds:QuantityEnergyNatural>${data.Purchased.QuantityEnergyNatural || ''}</vds:QuantityEnergyNatural>`;
    } else if (data.Purchased.QuantityEnergyNatural2) {
      purchased += `<vds:QuantityEnergyNatural>${data.Purchased.QuantityEnergyNatural2 || ''}</vds:QuantityEnergyNatural>`;
    }
  }
  return `<?xml version="1.0" encoding="UTF-8"?>
<vds:StatisticsTransferRequest xsi:schemaLocation="http://emta_vds.x-road.eu/Elering_x-tee_liides_v3.xsd" xmlns:vds="http://emta_vds.x-road.eu" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
    <vds:TransferID>${data.TransferID || ''}</vds:TransferID>
    <vds:BuyerCode>${data.BuyerCode || ''}</vds:BuyerCode>
    <vds:TransactionDateTime>${data.TransactionDateTimeProcessed || ''}Z</vds:TransactionDateTime>
    <vds:DocumentNo>${data.DocumentNo || ''}</vds:DocumentNo>
    <vds:TransferSource>
        ${transferSource}
    </vds:TransferSource>
    <vds:Purchased>
        ${purchased}
    </vds:Purchased>
</vds:StatisticsTransferRequest>`;
};

export const getStatisticsTypeOptions = () => {
  return [{value: STATISTICS_TYPE_VDS, name: 'VDS'}, {value: STATISTICS_TYPE_GHG, name: 'GHG'}];
};

export const generateTransmissionCancellationRequestToVDSXML = (data) => {
  if (!data) {
    return '';
  }
  return `<?xml version="1.0" encoding="UTF-8"?>
<vds:TransferCancelRequest xsi:schemaLocation="http://emta_vds.x-road.eu/Elering_x-tee_liides_v3.xsd" xmlns:vds="http://emta_vds.x-road.eu" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
    <vds:CancelID>${data.CancelID || ''}</vds:CancelID>
    <vds:TransferID>${data.TransferID || ''}</vds:TransferID>
    <vds:CancelTime>${data.CancelTimeProcessed || ''}Z</vds:CancelTime>
</vds:TransferCancelRequest>`;
};

export const generateSalesCancellationRequestToVDSXML = (data) => {
  if (!data) {
    return '';
  }
  let offerSource = '';
  if (data.OfferSource.OfferID) {
    offerSource += `<vds:OfferID>${data.OfferSource.OfferID || ''}</vds:OfferID>`;
  }
  if (data.OfferSource.SourceID) {
    offerSource += `<vds:SourceID>${data.OfferSource.SourceID || ''}</vds:SourceID>`;
  }
  let cancelled = '';
  if (data.Cancelled.GHGAmount) {
    cancelled += `<vds:GHGAmount>${data.Cancelled.GHGAmount || ''}</vds:GHGAmount>`;
  } else if (data.Cancelled.QuantityEnergyNatural) {
    cancelled += `<vds:QuantityEnergyNatural>${data.Cancelled.QuantityEnergyNatural || ''}</vds:QuantityEnergyNatural>`;
  } else if (data.Cancelled.QuantityEnergyNatural2) {
    cancelled += `<vds:QuantityEnergyNatural>${data.Cancelled.QuantityEnergyNatural2 || ''}</vds:QuantityEnergyNatural>`;
  }
  return `<?xml version="1.0" encoding="UTF-8"?>
<vds:OfferCancelRequest xsi:schemaLocation="http://emta_vds.x-road.eu/Elering_x-tee_liides_v3.xsd" xmlns:vds="http://emta_vds.x-road.eu" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
    <vds:CancelID>${data.CancelID || ''}</vds:CancelID>
    <vds:OfferSource>
        ${offerSource}
    </vds:OfferSource>
    <vds:Cancelled>
        ${cancelled}
    </vds:Cancelled>
</vds:OfferCancelRequest>`;
};

export const parseResponse = (response) => {
  let result = {};
  if (response) {
    try {
      const parsed = 'string' === typeof response ? JSON.parse(response) : response;
      const data = parsed.data;
      result.xml = data.xml;
      result.error = data.error;
    } catch (error) {
      result.error = error.message;
      console.error(error);
    }
  } else {
    result = null;
  }
  return result;
};
