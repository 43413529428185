import React, {useEffect, useState} from 'react';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import {MeteringPointOwnerInfo,} from '../../components';
import {Spin, Tabs} from 'antd';
import {electricMeteringPointFetch} from "../../actions/actionHelpers/endpoints";
import {ConsumptionPointDetails} from "../../forms/MeteringPoint/ConsumptionPointDetails";
import ElectricMeteringPointAgrData
  from "../ElectricMeteringPointAgrData/ElectricMeteringPointAgrData";

function ElectricMeteringPoint({t, meteringPointId, tabKey}) {
  const [activeTab, setActiveTab] = useState(tabKey || 'general');
  const [meteringPoint, setMeteringPoint] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    electricMeteringPointFetch(meteringPointId)
      .then(response => setMeteringPoint(response.data) & setLoading(false))
      .catch(error => console.error(error) & setLoading(false))
  }, [meteringPointId]);

  const {id, consumer, eicX} = meteringPoint;

  return (
    <>
      <h1>{t('titleConsumption')}</h1>
      <Spin spinning={loading}>
        <Tabs type="card" onChange={setActiveTab} activeKey={activeTab}>
          <Tabs.TabPane key="general" tab={t('generalTab')}>
            <MeteringPointOwnerInfo t={t} name={consumer} code={eicX}/>
            <ConsumptionPointDetails t={t} meteringPoint={meteringPoint}/>
          </Tabs.TabPane>
          <Tabs.TabPane key="data" tab={t('dataTab')}>
            <ElectricMeteringPointAgrData t={t} meteringPointId={id}/>
          </Tabs.TabPane>
        </Tabs>
      </Spin>
    </>
  );
}

export default translate(NAMESPACES.meteringPoint)(ElectricMeteringPoint);
