import React from 'react';
import PropTypes from 'prop-types';
import {Layout} from 'antd';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import {LoadingUnitFilter} from '../../forms';
import {LoadingUnitList} from '../index';

const {Content} = Layout;

class LoadingUnit extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired
  };

  render() {
    const {t} = this.props;

    return (
      <Content>
        <h2 className="main-header">{t('loading_units')}</h2>
        <LoadingUnitFilter
          t={t}
          isAdmin={false}
        />
        <LoadingUnitList
          t={t}
          isAdmin={false}
        />
      </Content>
    );
  }
}

export default translate(NAMESPACES.loadingUnit)(LoadingUnit);
