import React from 'react';
import {Layout} from 'antd';
import {Notifications} from '../../containers';

class NotificationsPage extends React.Component {
  render() {
    return (
      <Layout className="main-container">
        <Notifications/>
      </Layout>
    );
  }
}

export default NotificationsPage;
