import {productionUnitFields} from '../productionUnit';

export const formAccountOverviewFilter = {
  BALANCE_DATE: 'balanceDate',
  ACCOUNT_ID: 'accountId',
  IDS: 'ids',
  BALANCE_START_DATE: 'balanceStartTime',
  BALANCE_END_DATE: 'balanceEndTime',
  FUEL_TYPE: 'fuelType',
  CLEANING_TECHNOLOGY_TYPE: productionUnitFields.TECHNOLOGY_TYPE,
  VALID_UNTIL: 'goValidUntil',
  PRODUCTION_UNIT_ID: 'productionUnitId',
  GROUP_BY_EXPIRATION: 'groupByExpiration',
  GROUP_BY_FUEL_TYPE: 'groupByFuelType',
  GROUP_BY_TECHNOLOGY: 'groupByTechnology',
  GROUP_BY_PRODUCTION_UNIT: 'groupByProductionUnit',
  SELECTED_ROWS: 'selectedRows'
};
