import React, {Component} from 'react';
import {VDSSalesCancellationModal, VDSTransmissionCancellationModal} from "../";
import {
  getStatisticsSalesRequestFromVDS,
  getProviders
} from "../../actions/actionHelpers/endpoints";
import {Alert, Button, Col, Row, Select, Spin, Table} from "antd";
import TableRowActions from "../../components/TableRowActions/TableRowActions";

const {Option} = Select;
const PAGE_SIZE = 20;

export class VDSSalesPromotionMessagesView extends Component {
  static propTypes = {};

  columns = [];

  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      loading: false,
      traders: [],
      response: [],
      error: '',
      modalData: null,
      selectedTrader: undefined,
      page: 1
    };
  }

  componentDidMount = () => {
    this.columns = this.getColumns();
    this.loadProviders();
  };

  getColumns() {
    return [
      {title: 'MessageID', dataIndex: 'MessageID'},
      {title: 'OfferID', dataIndex: 'OfferID'},
      {title: 'ReportYear', dataIndex: 'ReportYear'},
      // { title: 'SupplierCode', dataIndex: 'SupplierCode' },
      {title: 'DocumentNo', dataIndex: 'DocumentNo'},
      // { title: 'SourceID', dataIndex: 'SourceID' },
      {title: 'Type', render: (value, row) => row.GHG && row.GHG.GHGAmount ? 'GHG' : 'VDS'},
      {title: 'Quantity Energy Natural', dataIndex: 'OfferType.Energy.QuantityEnergyNatural'},
      {title: 'Quantity Energy Accounting', dataIndex: 'OfferType.Energy.QuantityEnergyAccounting'},
      {title: 'Accounting Coeff', dataIndex: 'OfferType.Energy.AccountingCoeff'},
      {title: 'Advanced Biofuel', dataIndex: 'OfferType.Energy.AdvancedBiofuel'},
      {title: 'GHG Intensity', dataIndex: 'OfferType.Energy.GHGIntensity'},
      {title: 'GHG Amount', dataIndex: 'OfferType.GHG.GHGAmount'},
      {
        render: (value, row) => (
          <TableRowActions>
            <Button shape="circle"
                    icon="close"
                    size="small"
                    type="danger"
                    title={'Cancel Offer'}
                    onClick={() => this.showSalesCancellationModal(row)}
            />
            {/*<Button shape="circle"
                    icon="delete"
                    size="small"
                    type="danger"
                    title={'Cancel Transfer'}
                    onClick={() => this.showTransmissionCancellationModal(row)}
            />*/}
          </TableRowActions>
        )
      }
    ];
  }

  async loadProviders() {
    this.setState({loading: true, traders: [], error: ''});
    try {
      const providers = await getProviders();
      this.setState({loading: false, traders: providers.data.content});
    } catch (error) {
      console.error(error);
      this.setState({loading: false, error: 'Andmete hankimisel tekkis viga'});
    }
  }

  loadOffers = async () => {
    const {selectedTrader} = this.state;
    this.setState({loading: true, response: [], error: ''});
    try {
      const responseObject = await getStatisticsSalesRequestFromVDS(selectedTrader);
      const response = this.mapResponse([...responseObject.data]);
      this.setState({loading: false, response});
    } catch (error) {
      console.error(error);
      this.setState({loading: false, error: 'VDS-i andmete hankimisel tekkis viga'});
    }
  };

  mapResponse(response) {
    return response.map(item => {
      console.log('item', item);
      return {
        "MessageID": item.messageId,
        "OfferID": item.offerId,
        "SupplierCode": item.sourceId,
        "ReportYear": item.reportYear,
        "DocumentNo": item.documentNo,
        "SourceID": item.sourceId,
        "OfferType": item.offerType ? {
          "Energy": item.offerType.energy ? {
            "QuantityEnergyNatural": item.offerType.energy.quantityEnergyNatural,
            "QuantityEnergyAccounting": item.offerType.energy.quantityEnergyAccounting,
            "AccountingCoeff": item.offerType.energy.accountingCoeff,
            "AdvancedBiofuel": item.offerType.energy.advancedBiofuel,
            "GHGIntensity": item.offerType.energy.ghgIntensity
          } : undefined,
          "GHG": item.offerType.ghg ? {
            "GHGAmount": item.offerType.ghg.ghgAmount
          } : undefined
        } : {}
      }
    });
  }

  showSalesCancellationModal = (modalSalesCancellationData) => this.setState({
    showSalesCancellationModal: true,
    modalSalesCancellationData
  });
  hideSalesCancellationModal = () => this.setState({
    showSalesCancellationModal: false,
    modalSalesCancellationData: null
  });
  showTransmissionCancellationModal = (modalTransmissionCancellationData) => this.setState({
    showTransmissionCancellationModal: true,
    modalTransmissionCancellationData
  });
  hideTransmissionCancellationModal = () => this.setState({
    showTransmissionCancellationModal: false,
    modalTransmissionCancellationData: null
  });
  onPageChange = (page) => this.setState({page});
  selectTrader = (selectedTrader) => this.setState({selectedTrader}, this.loadOffers);

  render() {
    const {
      loading,
      traders,
      response,
      error,
      showSalesCancellationModal,
      modalSalesCancellationData,
      showTransmissionCancellationModal,
      modalTransmissionCancellationData,
      page,
      selectedTrader
    } = this.state;
    return (
      <Spin spinning={loading}>
        <h1>{'Müügipakkumise sõnumid'}</h1>
        <Row gutter={24}>
          <Col span={12}>
            <Select className={'w-100'}
                    dropdownMatchSelectWidth={false}
                    value={selectedTrader}
                    placeholder={'Valige kaupleja'}
                    onChange={this.selectTrader}>
              {traders.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
            </Select>
          </Col>
          <Col span={12}>
            <Button onClick={this.loadOffers} disabled={!selectedTrader}>{'Värskenda'}</Button>
          </Col>
        </Row>
        <Table
          rowKey={'MessageID'}
          columns={this.columns}
          dataSource={response}
          bordered
          pagination={{
            onChange: this.onPageChange,
            current: page,
            total: response.length,
            pageSize: PAGE_SIZE,
            defaultCurrent: 1
          }}
        />
        <VDSSalesCancellationModal show={showSalesCancellationModal}
                                   data={modalSalesCancellationData}
                                   hideModal={this.hideSalesCancellationModal}/>
        <VDSTransmissionCancellationModal show={showTransmissionCancellationModal}
                                          data={modalTransmissionCancellationData}
                                          hideModal={this.hideTransmissionCancellationModal}/>
        {!!error && <Alert message={error} type="error" closable/>}
      </Spin>
    );
  }
}

export default VDSSalesPromotionMessagesView;
