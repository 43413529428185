import {schema} from 'normalizr';
import {entitiesSelectorList, entitiesSelectorItem} from '../utils/asyncHelpers';
import physicalPerson from './physicalPerson';
import legalEntity from './legalEntity';

const schemaEntity = new schema.Entity('mandatePerson', {
  physicalPerson,
  originatorLegalEntity: legalEntity,
});

export const getMandatePersons = entitiesSelectorList(schemaEntity);
export const getMandatePerson = entitiesSelectorItem(schemaEntity);
export default schemaEntity;
