import React, {Component} from 'react';
import PropTypesImmutable from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {getRole} from '../../reducers/userReducer';
import * as actions from '../../actions/actionCreators/accountGOSAgreementsActionCreators';
import {AccountGOSAgreementsSearchTable} from "../../components";
import {
  getAccountGOSSellingAgreementsList,
  getAccountGOSSellingAgreementsListData
} from "../../reducers/accountGOSSellingAgreementsListReducer";
import {
  getSellingAgreementWithdrawModal,
  hideSellingAgreementWithdrawModal,
  showSellingAgreementWithdrawModal
} from "../../actions/actionCreators/modalsActionCreators";
import {message, Modal} from "antd";
import {TsAgreementWithdraw} from "../../forms";
import {tsAgreementDeleteAction} from "../../actions/actionCreators/tsAgreementActionCreators";

export class AccountGOSSellingAgreementsList extends Component {
  static propTypes = {
    sellingAgreements: PropTypesImmutable.list,
    sellingAgreementsList: PropTypesImmutable.map.isRequired,
    t: PropTypes.func.isRequired,
    registerCode: PropTypes.string.isRequired,
    stateWithdrawModal: PropTypes.bool.isRequired,
    showWithdrawModal: PropTypes.func.isRequired,
  };

  state = {
    selectedRowId: -1,
    resetSelection: null
  }

  componentDidMount() {
    const {searchAccountGOSSellingAgreements} = this.props;
    searchAccountGOSSellingAgreements(0);
  }

  showModal = (rowId, resetFunction) => {
    const {showWithdrawModal} = this.props;
    this.setState({selectedRowId: rowId, resetSelection: resetFunction});
    showWithdrawModal();
  }

  deleteAgreement = async (id) => {
    const {t, deleteTsAgreement, searchAccountGOSSellingAgreements} = this.props;
    const result = await deleteTsAgreement(id);
    if (!result || !result.error) {
      message.success(t('agreements.table.delete.success'));
      searchAccountGOSSellingAgreements(0);
    }
  }

  render() {
    const {
      t,
      stateWithdrawModal,
      hideWithdrawModal,
      sellingAgreements,
      sellingAgreementsList,
      searchAccountGOSSellingAgreements,
      registerCode,
      ...rest
    } = this.props;

    return (
      <React.Fragment>
        <AccountGOSAgreementsSearchTable agreements={sellingAgreements.toJS()}
                                         search={searchAccountGOSSellingAgreements}
                                         counterparty={'buyerName'}
                                         registerCode={registerCode}
                                         showModal={this.showModal}
                                         deleteAgreement={this.deleteAgreement}
                                         t={t}
                                         {...rest}
                                         {...sellingAgreementsList.toJS()}
        />
        {stateWithdrawModal && (
          <Modal
            onCancel={hideWithdrawModal}
            visible
            footer={false}
            title={t('agreements.withdraw.title')}
            width="600px"
          >
            <TsAgreementWithdraw
              t={t}
              selectedRowId={this.state.selectedRowId}
              hideWithdrawModal={hideWithdrawModal}
              searchAgreements={searchAccountGOSSellingAgreements}
              resetSelection={this.state.resetSelection}
            />
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  sellingAgreementsList: getAccountGOSSellingAgreementsList(state),
  sellingAgreements: getAccountGOSSellingAgreementsListData(state),
  loading: getLoadingSelector(getAccountGOSSellingAgreementsList)(state),
  stateWithdrawModal: getSellingAgreementWithdrawModal(state),
  userRole: getRole(state),
});

const mapDispatchToProps = {
  showWithdrawModal: showSellingAgreementWithdrawModal,
  hideWithdrawModal: hideSellingAgreementWithdrawModal,
  deleteTsAgreement: tsAgreementDeleteAction,
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  AccountGOSSellingAgreementsList
);
