import moment from "moment";
import _ from "lodash";

const defaultAuctionTimeValues = () => {
  return {
    disabledHours: () => {
      return _.range(0, 10).concat(_.range(16, 24))
    },
    disabledMinutes: (selectedHour) => {
      if (selectedHour === 15) return _.range(1, 60);
      return _.range(1, 30).concat(_.range(31, 60));
    }
  };
}

const firstDayAuctionTimeValues = (now, firstHour, selectedHour) => {
  return {
    disabledHours: () => {
      return _.range(0, firstHour).concat(_.range(16, 24))
    },
    disabledMinutes: () => {
      if (selectedHour === 15) return _.range(1, 60);
      const startFromZero = selectedHour <= firstHour && now.minutes() < 30;
      return _.range(startFromZero ? 0 : 1, 30).concat(_.range(31, 60));
    }
  };
}

const lastDaysAuctionTimeValues = () => {
  return {
    disabledHours: () => {
      return _.range(0, 15).concat(_.range(16, 24))
    },
    disabledMinutes: () => {
      return _.range(1, 60);
    }
  }
}

const isFirstDaySelected = (value, now) => {
  const threeDaysAhead = now.add((now.hours() > 15 ? 4 : 3), 'days');
  return now.hours() < 15 && threeDaysAhead.startOf('day').isSame(value.startOf('day'));
}

const isWithin72hOfLastDay = (expirationDate, now) => {
  const parsedExpirationDate = getParsedExpirationDate(expirationDate);
  const diff = parsedExpirationDate.diff(now.endOf('day'), 'days');
  return diff < 3 && diff >= 0;
}

const getParsedExpirationDate = (expirationDate) => {
  return moment(expirationDate, 'DD.MM.YYYY').endOf('day');
}

export const disabledDate = (current, expirationDate) => {
  const now = moment();
  const currentHour = now.hours();
  const threeDaysAhead = now.clone().add((currentHour >= 15 ? 3 : 2), 'days').endOf('day');
  const parsedExpirationDate = getParsedExpirationDate(expirationDate);

  if (isWithin72hOfLastDay(expirationDate, now.clone())) {
    const isLastDay = now.endOf('day').diff(parsedExpirationDate, 'days') === 0;
    return current && (current < parsedExpirationDate.clone()
      .add(isLastDay && currentHour >= 14 ? 0 : -1, 'days') || current > parsedExpirationDate)
  }
  return current && (current < threeDaysAhead || current > parsedExpirationDate);
}

export const disabledTime = (expirationDate, val = moment()) => {
  const value = val.clone();
  const now = moment();
  const hours = now.hours();

  if (isWithin72hOfLastDay(expirationDate, now.clone()))
    return lastDaysAuctionTimeValues();

  if (!isFirstDaySelected(value, now.clone()))
    return defaultAuctionTimeValues();

  const firstHour = now.minutes() > 30 ? hours + 1 : hours;
  return firstDayAuctionTimeValues(now, firstHour, val.hours());
}
