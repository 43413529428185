import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {connect} from 'react-redux';
import {getGridItem, getGridItemData} from '../../reducers/reportGridReducer';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {Spin} from 'antd';
import * as actions from '../../actions/actionCreators/reportGridActionCreators';
import {YearSelect} from '../../atoms';
import {GridReportView} from '../../components';

export class ReportGrid extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    fetchReportGridItems: PropTypes.func.isRequired,
    reportData: PropTypesImmutable.map,
  };

  state = {
    selectedYear: new Date().getFullYear(),
  };

  componentDidMount = () => {
    const {fetchReportGridItems} = this.props;

    fetchReportGridItems({year: this.state.selectedYear});
  };

  handleYearChange = year => {
    const {fetchReportGridItems} = this.props;

    fetchReportGridItems({year});

    this.setState({
      selectedYear: year,
    });
  };

  render() {
    const {reportData, loading, t} = this.props;
    const {selectedYear} = this.state;
    const dataSourceJs = reportData ? reportData.toJS() : {};

    return (
      <Spin spinning={loading}>
        <div style={{width: '200px', marginBottom: '24px'}}>
          <YearSelect
            beforeCurrent
            onChange={this.handleYearChange}
            value={this.state.selectedYear}
          />
        </div>
        <GridReportView
          t={t}
          dataSource={dataSourceJs}
          selectedYear={selectedYear}
        />
      </Spin>
    );
  }
}

const getIsLoading = getLoadingSelector(getGridItem);

const mapStateToProps = state => ({
  loading: getIsLoading(state),
  reportData: getGridItemData(state),
});

const mapDispatchToProps = {
  fetchReportGridItems: actions.fetchReportGridItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportGrid);
