export const agreementFields = {
  ID: 'id',
  COUNTERPARTY_NAME: 'counterpartyName',
  COUNTERPARTY_REGISTER_CODE: 'counterpartyRegisterCode',
  CREATED_AT: 'createdAt',
  REGISTERED_AT: 'registeredAt',
  STATUS: 'status',
  AGREEMENT_TYPE: 'agreementType',
  FINAL_CONSUMPTION_YEAR: 'finalConsumptionYear',
  FUEL_TYPE: 'fuelType',
  FUEL_GENERATION: 'fuelGeneration',
  GHG_CAPACITY: 'ghgCapacity',
  NATURAL_AMOUNT_MJ: 'naturalAmountMj',
  MULTIPLIER: 'multiplier',
  CALCULATED_AMOUNT_MJ: 'calculatedAmountMj',
  TOTAL_TRANSACTION_COST: 'totalTransactionCost',
  UNIT_PRICE: 'unitPrice',
  FULFILLED_PERCENTAGE: 'fulfilledPercentage',
  FULFILLED_NATURAL_AMOUNT_MJ: 'fulfilledNaturalAmountMj',
  FULFILLED_CALCULATED_AMOUNT_MJ: 'fulfilledCalculatedAmountMj',
  AGREEMENT_WITHDRAWAL: 'agreementWithdrawal',
  SELLER_NAME: 'sellerName',
  SELLER_CODE: 'sellerCode',
  BUYER_NAME: 'buyerName',
  BUYER_CODE: 'buyerCode'
};
