import React, {Component} from 'react';
import PropTypesImmutable from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {AccountGOSReportSearchTable} from '../../components';
import {
  getAccountGOSReportList,
  getAccountGOSReportListData,
} from '../../reducers/accountGOSReportListReducer';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {getRole} from '../../reducers/userReducer';
import * as actions from '../../actions/actionCreators/accountGOSReportListActionCreator';

export class AccountGOSReportList extends Component {
  static propTypes = {
    accountGOSReport: PropTypesImmutable.list,
    accountGOSReportList: PropTypesImmutable.map.isRequired,
    t: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const {searchAccountGOSReport} = this.props;
    searchAccountGOSReport(0);
  }

  render() {
    const {accountGOSReport, accountGOSReportList, ...rest} = this.props;

    return (
      <React.Fragment>
        <AccountGOSReportSearchTable
          accountGOSReport={accountGOSReport.toJS()}
          {...rest}
          {...accountGOSReportList.toJS()}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  accountGOSReportList: getAccountGOSReportList(state),
  accountGOSReport: getAccountGOSReportListData(state),
  loading: getLoadingSelector(getAccountGOSReportList)(state),
  userRole: getRole(state),
});

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  AccountGOSReportList
);
