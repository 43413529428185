import {createAsyncSelector} from '../utils/asyncHelpers';
import {handleActions} from 'redux-actions';
import {ACCOUNT_GOS_PURCHASING_AGREEMENTS_LIST_FETCH} from '../constants/accountGOSListActionTypes';
import accountGOSAgreementsSchema from '../schemas/accountGOSTransactions';
import {createAsyncPagingReducer, listInitialAsyncPagingState,} from '../utils/asyncPagingHelpers';
import {resetMaster} from "../actions/pureActions/agreementActions";

const accountGOSPurchasingAgreementsList = handleActions(
  {
    ...createAsyncPagingReducer(ACCOUNT_GOS_PURCHASING_AGREEMENTS_LIST_FETCH),
    [resetMaster]: state => state.merge(listInitialAsyncPagingState),
  },
  listInitialAsyncPagingState
);

export const getAccountGOSPurchasingAgreementsList = state => {
  return state.get('accountGOSPurchasingAgreementsList');
};

export const getAccountGOSPurchasingAgreementsListData = createAsyncSelector(
  [accountGOSAgreementsSchema],
  getAccountGOSPurchasingAgreementsList
);

export default accountGOSPurchasingAgreementsList;
