import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {fromJS} from 'immutable';
import {Table, Button} from 'antd';
import {get} from 'lodash';
import {formRegisterContactFields} from '../../constants/formRegister';

class RegisterContactsTable extends Component {
  static propTypes = {
    data: PropTypes.array,
    onDeleteContact: PropTypes.func.isRequired,
    onEditContact: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    inEditForNotAdmin: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    data: [],
  };

  getColumns = () => {
    const {t, onEditContact, onDeleteContact, inEditForNotAdmin} = this.props;

    const columns = [
      {
        title: t('contacts.contactPerson'),
        dataIndex: formRegisterContactFields.CONTACT_PERSON,
      },
      {
        title: t('contacts.email'),
        dataIndex: formRegisterContactFields.EMAIL,
      },
      {
        title: t('contacts.phone'),
        dataIndex: formRegisterContactFields.PHONE,
        render: (phone, values) =>
          `${get(values, formRegisterContactFields.PHONE_CODE)} ${phone}`,
      },
    ];

    if (!inEditForNotAdmin) {
      columns.push({
        title: t('contacts.actions'),
        key: 'actions',
        render: (value, values) => (
          <span>
            <Button
              shape="circle"
              icon="edit"
              size="small"
              title={t('contacts.edit')}
              type="primary"
              onClick={() => onEditContact(fromJS(values))}
            />

            <span className="ant-divider"/>
            <Button
              shape="circle"
              icon="delete"
              size="small"
              title={t('contacts.delete')}
              type="primary"
              onClick={() =>
                onDeleteContact(get(values, formRegisterContactFields.ID))
              }
            />
          </span>
        ),
      });
    }

    return columns;
  };

  render() {
    const {data, t} = this.props;

    return (
      <Table
        rowKey={formRegisterContactFields.ID}
        columns={this.getColumns(t)}
        dataSource={data}
        bordered
        pagination={false}
      />
    );
  }
}

export default RegisterContactsTable;
