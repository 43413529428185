import React, {Component} from 'react';
import moment from 'moment';
import {reduxForm, Field, getFormValues, Fields} from 'redux-form/immutable';
import PropTypes from 'prop-types';
import {TextField, ClassificatorField, DateRangeField} from '../../atoms';
import {
  FormActions,
  AutoCompleteCompaniesField,
} from '../../components';
import {connect} from 'react-redux';
import {downloadTradingAccountFileByGetURL} from '../../utils/files';
import {links} from '../../utils/gotoLink';
import {FORM_TRANSACTION_FOR_GOS_SEARCH} from '../../constants/formKeys';
import {Collapse, Row, Col, Button} from 'antd';
import * as actions from '../../actions/actionCreators/transactionForGosSearchActionCreators';
import {taTransactionsFilterFields} from '../../constants/formAccountGOSTransactionsFilter';

const {Panel} = Collapse;

class TransactionGosFilter extends Component {
  dateFrom = '';
  dateTo = '';
  senderAccountId = undefined;
  receiverAccountId = undefined;
  transactionForGosStatus = undefined;
  transactionType = undefined;
  transactionNumber = undefined;
  currentAccountId = undefined;
  type = undefined;

  static propTypes = {
    t: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    searchTATransactions: PropTypes.func.isRequired,
    accountId: PropTypes.string,
  };

  componentDidMount = () => this.props.searchTATransactions();

  handleExportTransactions = () => {
    let params = {
      transactionDateFrom: this.dateFrom,
      transactionDateTo: this.dateTo,
      transactionStatus: this.transactionStatus,
      transactionType: this.transactionType,
      senderId: this.senderAccountId,
      receiverId: this.receiverAccountId,
      currentAccountId: this.props.initialValues.get(
        taTransactionsFilterFields.CURRENT_ACCOUNT_ID
      ),
      transactionId: this.transactionNumber,
      type: this.type
    };

    downloadTradingAccountFileByGetURL(
      links.tradingAccountCsv,
      params,
      'gos-account-transactions.csv'
    );
  };

  updateFilterFields = () => {
    let filterValuesMap = this.props.filterValues;

    this.dateTo = filterValuesMap.get(taTransactionsFilterFields.DATE_TO);
    this.dateFrom = filterValuesMap.get(taTransactionsFilterFields.DATE_FROM);
    this.transactionStatus = filterValuesMap.get(taTransactionsFilterFields.TRANSACTION_STATUS);
    this.transactionType = filterValuesMap.get(taTransactionsFilterFields.TRANSACTION_TYPE);
    this.senderAccountId = filterValuesMap.get(taTransactionsFilterFields.SENDER_ID);
    this.receiverAccountId = filterValuesMap.get(taTransactionsFilterFields.RECEIVER_ID);
    this.transactionNumber = filterValuesMap.get(taTransactionsFilterFields.TRANSACTION_ID);
    this.type = filterValuesMap.get(taTransactionsFilterFields.TYPE);
  };

  render() {
    const {t, handleSubmit, reset} = this.props;
    return (
      <div>
        <div className="search-form-container">
          <Collapse defaultActiveKey={['filters']}>
            <Panel key="filters" header={t('filter.panelTitle')}>
              <form onSubmit={handleSubmit}>
                <Row gutter={32}>
                  <Col span={16}>
                    <Fields
                      label={t('filter.dateRange.title')}
                      names={[
                        taTransactionsFilterFields.DATE_FROM,
                        taTransactionsFilterFields.DATE_TO,
                      ]}
                      placeholder={[
                        t('filter.dateRange.dateFromPlaceholder'),
                        t('filter.dateRange.dateToPlaceholder'),
                      ]}
                      component={DateRangeField}
                      hasFeedback={false}
                      allowClear={false}
                      ranges={{
                        [t('filter.dateRange.currentMonth')]: [
                          moment().startOf('month'),
                          moment().endOf('month'),
                        ],
                        [t('filter.dateRange.beginLastMonth')]: [
                          moment()
                            .subtract(1, 'months')
                            .startOf('month'),
                          moment(),
                        ],
                        [t('filter.dateRange.lastMonth')]: [
                          moment()
                            .subtract(1, 'months')
                            .startOf('month'),
                          moment()
                            .subtract(1, 'months')
                            .endOf('month'),
                        ],
                      }}
                    />
                  </Col>
                </Row>
                <Row gutter={32}>
                  <Col span={8}>
                    <Field
                      label={t('filter.number')}
                      name={taTransactionsFilterFields.TRANSACTION_ID}
                      component={TextField}
                      hasFeedback={false}
                    />
                  </Col>
                  <Col span={8}>
                    <Field
                      label={t('filter.transactionType')}
                      name={taTransactionsFilterFields.TRANSACTION_TYPE}
                      component={ClassificatorField}
                      type={ClassificatorField.types.gosTransactionType}
                      hasFeedback={false}
                    />
                  </Col>
                  <Col span={8}>
                    <Field
                      label={t('filter.type')}
                      name={taTransactionsFilterFields.TYPE}
                      component={ClassificatorField}
                      type={ClassificatorField.types.tradingAccountTransactionRowType}
                      hasFeedback={false}
                    />
                  </Col>
                </Row>
                <Row gutter={32}>
                  <Col span={8}>
                    <Field
                      label={t('filter.senderAccount')}
                      name={
                        taTransactionsFilterFields.SENDER_ID
                      }
                      component={AutoCompleteCompaniesField}
                      hasFeedback={false}
                    />
                  </Col>
                  <Col span={8}>
                    <Field
                      label={t('filter.receiverAccount')}
                      name={
                        taTransactionsFilterFields.RECEIVER_ID
                      }
                      component={AutoCompleteCompaniesField}
                      hasFeedback={false}
                    />
                  </Col>
                </Row>
                <Row gutter={32}>
                  <Col span={8}>
                    <Field
                      label={t('filter.transactionStatus')}
                      name={taTransactionsFilterFields.TRANSACTION_STATUS}
                      component={ClassificatorField}
                      type={ClassificatorField.types.transactionForGosStatus}
                      hasFeedback={false}
                    />
                  </Col>
                </Row>
                <FormActions>
                  <Button onClick={reset}>{t('filter.clearBtn')}</Button>
                  <Button
                    onClick={this.updateFilterFields}
                    type="primary"
                    htmlType="submit"
                  >
                    {t('filter.submitBtn')}
                  </Button>
                </FormActions>
              </form>
            </Panel>
          </Collapse>
          <FormActions>
            <Button onClick={this.handleExportTransactions}>
              {t('exportCsvBtn')}
            </Button>
          </FormActions>
        </div>
      </div>
    );
  }
}

const onSubmit = (values, dispatch) => dispatch(actions.searchTATransactionsAction());

const mapDispatchToProps = {
  ...actions,
  searchTATransactions: actions.searchTATransactionsAction,
};

const getValues = getFormValues(FORM_TRANSACTION_FOR_GOS_SEARCH);

const mapStateToProps = (state, ownProps) => ({
  filterValues: getValues(state),
  initialValues: {
    [taTransactionsFilterFields.CURRENT_ACCOUNT_ID]: ownProps.accountId,
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: FORM_TRANSACTION_FOR_GOS_SEARCH,
    onSubmit,
    enableReinitialize: true,
  })(TransactionGosFilter)
);
