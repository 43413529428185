import {normalizeHelper, createAsyncAction} from '../../utils/asyncHelpers';
import {
  INVOICE_FETCH,
  INVOICE_RECALL,
  INVOICE_ADMIN_CONFIRM,
  INVOICE_GENERATE,
} from '../../constants/actionTypes/invoiceViewActionTypes';
import {
  invoiceFetch,
  invoiceRecall,
  invoiceAdminConfirm,
  invoiceGenerate,
} from '../actionHelpers/endpoints';
import invoiceSchema from '../../schemas/invoice';

export const fetchInvoice = createAsyncAction(
  INVOICE_FETCH,
  invoiceFetch,
  normalizeHelper(invoiceSchema)
);

export const recallInvoice = createAsyncAction(INVOICE_RECALL, invoiceRecall);
export const confirmInvoice = createAsyncAction(
  INVOICE_ADMIN_CONFIRM,
  invoiceAdminConfirm,
  normalizeHelper(invoiceSchema)
);
export const generateInvoice = createAsyncAction(
  INVOICE_GENERATE,
  invoiceGenerate
);
