import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, Field, formValueSelector} from 'redux-form/immutable';
import {Row, Col, Button, message, Form} from 'antd';
import {fromInvoiceConfirmFields} from '../../constants/forms/formInvoiceConfirm';
import {TextField, DateField} from '../../atoms';
import {FormActions, FileUpload} from '../../components';
import {getInvoiceForConfirm} from '../../reducers/invoiceSearchReducer';
import {invoiceFields} from '../../constants/invoice';
import {FORM_INVOICE_CONFIRM} from '../../constants/formKeys';
import {confirmInvoicePost} from '../../actions/actionCreators/invoiceSearchActionCreators';
import {processAsyncResult} from '../../utils/formHelpers';
import {downloadFileByPostURL} from '../../utils/files';
import {getLinkInvoiceExport} from '../../utils/gotoLink';
import validate from './validateSchema';

const FormItem = Form.Item;

export class InvoiceConfirm extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    file: PropTypes.number,
    showTermsModal: PropTypes.func.isRequired,
    valid: PropTypes.bool.isRequired,
    touch: PropTypes.func.isRequired,
    hideTermsModal: PropTypes.func.isRequired,
    hideConfirmModal: PropTypes.func.isRequired,
  };

  handleFileChange = info => {
    const {change} = this.props;

    if (info.file.status === 'done') {
      change(fromInvoiceConfirmFields.FILE, info.file.response);
    } else if (info.file.status === 'removed') {
      change(fromInvoiceConfirmFields.FILE, null);
    }
  };

  handleBeforeUpload = () => {
    const {file, t} = this.props;

    if (file) {
      message.error(t('confirm.uploadError'));
      return false;
    }

    return true;
  };

  handleNext = () => {
    const {valid, showTermsModal, touch} = this.props;

    touch(invoiceFields.NUMBER, invoiceFields.DATE);

    if (valid) {
      showTermsModal();
    }
  };

  handleDownload = () => {
    const {initialValues} = this.props;
    const invoiceId = initialValues.get(invoiceFields.ID);

    downloadFileByPostURL(
      getLinkInvoiceExport(invoiceId),
      {},
      `invoice-${invoiceId}.pdf`,
      'get'
    );
  };

  render() {
    const {t, handleSubmit} = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Row gutter={32}>
          <Col span={12}>
            <Field
              name={invoiceFields.NUMBER}
              label={t('confirm.number')}
              component={TextField}
              required
            />
          </Col>
          <Col span={12}>
            <Field
              className="date-field"
              name={invoiceFields.DATE}
              label={t('confirm.date')}
              component={DateField}
              required
            />
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={24}>
            <Field
              name={invoiceFields.REFERENCE_NUMBER}
              label={t('confirm.referenceNumber')}
              component={TextField}
            />
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={12}>
            <FormItem label={t('confirm.file')}>
              <FileUpload
                t={t}
                onChange={this.handleFileChange}
                beforeUpload={this.handleBeforeUpload}
              />
            </FormItem>
          </Col>
        </Row>
        <FormActions>
          <Button onClick={this.handleDownload}>
            {t('confirm.downloadBtn')}
          </Button>
          <Button onClick={this.handleNext}>{t('confirm.nextBtn')}</Button>
        </FormActions>
      </form>
    );
  }
}

const getFormValue = formValueSelector(FORM_INVOICE_CONFIRM);

const onSubmit = async (values, dispatch) => {
  const res = await dispatch(confirmInvoicePost(values));

  processAsyncResult(res);
};

const onSubmitSuccess = (result, dispatch, props) => {
  const {hideConfirmModal, hideTermsModal} = props;

  hideTermsModal();
  hideConfirmModal();
};

const mapStateToProps = state => ({
  initialValues: getInvoiceForConfirm(state),
  file: getFormValue(state, fromInvoiceConfirmFields.FILE),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_INVOICE_CONFIRM,
    validate,
    onSubmit,
    onSubmitSuccess,
  })(InvoiceConfirm)
);
