import React from 'react';
import {Layout, Tabs} from 'antd';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import PropTypes from 'prop-types';
import {AccountOverviewFilter, AccountReportFilter} from '../../forms';
import {AccountSummary, AccountReport} from '../../containers';
import {getEntityById} from '../../utils/asyncHelpers';
import {connect} from 'react-redux';
import accountSchema from '../../schemas/account';
import PropTypesImmutable from 'react-immutable-proptypes';
import {translateLegalEntityTypeClassificator} from '../../utils/translateHelpers';
import {accountFields} from '../../constants/account';
import {currentPathSelector} from '../../utils/gotoLink';
import './AccountOverview.css';

const tabKeys = {
  ACCOUNT_OVERVIEW: 'accountOverview',
  ACCOUNT_REPORT: 'accountReport',
};

class AccountOverviewPage extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    accountEntity: PropTypesImmutable.map,
    accountId: PropTypes.string,
    currentPath: PropTypes.string.isRequired,
  };

  render() {
    const {t, accountEntity, accountId, currentPath} = this.props;

    let roleCaption = '';
    if (accountEntity) {
      roleCaption = translateLegalEntityTypeClassificator(
        accountEntity.get(accountFields.TYPE)
      );
    }

    if (!accountId) {
      return (
        <Layout className="page-AccountOverviewPage main-container">
          <h1>{t('titleAdmin')}</h1>
          <AccountReportFilter t={t}/>
          <div className="page-AccountOverviewPage__table-container">
            <AccountReport t={t}/>
          </div>
        </Layout>
      );
    }

    return (
      <Layout className="page-AccountOverviewPage main-container">
        <h1>{t('title', {roleCaption: roleCaption.toLowerCase()})}</h1>
        <Tabs
          type="card"
          onChange={this.handleTabChange}
          defaultActiveKey={
            currentPath.includes('/accountReport')
              ? tabKeys.ACCOUNT_REPORT
              : tabKeys.ACCOUNT_OVERVIEW
          }
        >
          <Tabs.TabPane
            key={tabKeys.ACCOUNT_OVERVIEW}
            tab={t('accountOverviewTab')}
            className="page-AccountOverviewPage__filter-container"
          >
            <AccountOverviewFilter accountId={accountId} t={t}/>
            <div className="page-AccountOverviewPage__table-container">
              <AccountSummary accountId={accountId} t={t}/>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane
            key={tabKeys.ACCOUNT_REPORT}
            tab={t('accountReportTab')}
          >
            <AccountReportFilter accountId={accountId} t={t}/>
            <div className="page-AccountOverviewPage__table-container">
              <AccountReport accountId={accountId} t={t}/>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </Layout>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  currentPath: currentPathSelector(state),
  accountId: ownProps.match.params.accountId,
  accountEntity:
    ownProps.match.params.accountId &&
    getEntityById(state, accountSchema, ownProps.match.params.accountId),
});

export default translate(NAMESPACES.account)(
  connect(mapStateToProps)(AccountOverviewPage)
);
