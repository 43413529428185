import React, {Component} from 'react';
import Classificator from '../Classificator';
import ComponentWrapper from './ComponentWrapper';
import mapError from './mapError';

export default class ClassificatorField extends Component {
  static types = Classificator.types;

  render() {
    return (
      <ComponentWrapper
        {...this.props}
        mapProps={mapError}
        render={props => <Classificator {...props} />}
      />
    );
  }
}
