import React, {Component} from 'react';
import {reduxForm, Field} from 'redux-form/immutable';
import {TextAreaField, TextField} from '../../atoms';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {Row, Col, Button, Spin} from 'antd';
import {Map, List} from 'immutable';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FileUpload, FormActions} from '../../components';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import {FORM_REQUEST_CHANGE} from '../../constants/formKeys';
import {getUserLegalEntityName} from '../../reducers/userReducer';
import {notificationRelatedObjectType} from '../../constants/classificators';
import {getChangeRequest} from '../../reducers/requestChangeReducer';
import * as actions from '../../actions/actionCreators/requestChangeActionCreators';
import {formRequestChangeFields} from '../../constants/forms/formRequestChange';
import validate from './validateSchema';

export class RequestChange extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    relatedObjectId: PropTypes.number.isRequired,
    relatedObjectType: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  onFileChange = info => {
    const {change} = this.props;

    if (['done', 'removed'].includes(info.file.status)) {
      change(
        formRequestChangeFields.FILE_IDS,
        info.fileList.map(file => file.response)
      );
    }
  };

  render() {
    const {t, handleSubmit, onCancel, isLoading} = this.props;

    return (
      <Spin spinning={isLoading}>
        <form onSubmit={handleSubmit} className="form-request-change">
          <Row gutter={32}>
            <Col span={12}>
              <Field
                name={formRequestChangeFields.SENDER}
                label={t('sender')}
                component={TextField}
                disabled
                hasFeedback={false}
              />
            </Col>
            <Col span={12}>
              <Field
                name={formRequestChangeFields.RECEIVER}
                label={t('receiver')}
                component={TextField}
                disabled
                hasFeedback={false}
              />
            </Col>
          </Row>
          <Field
            name={formRequestChangeFields.MESSAGE}
            label={t('message')}
            component={TextAreaField}
            rows={6}
          />
          <FileUpload t={t} onChange={this.onFileChange}/>
          <FormActions>
            <Button onClick={onCancel}>{t('cancel')}</Button>
            <Button htmlType="submit" type="primary">
              {t('send')}
            </Button>
          </FormActions>
        </form>
      </Spin>
    );
  }
}

const onSubmit = (values, dispatch, ownProps) => {
  const typeToActionMap = {
    [notificationRelatedObjectType.LEGAL_ENTITY]:
    actions.requestChangeLegalEntity,
    [notificationRelatedObjectType.PRODUCTION_UNIT]:
    actions.requestChangeProductionUnit,
    [notificationRelatedObjectType.MEASUREMENT_POINT]:
    actions.requestChangeMeteringPoint,
  };
  dispatch(typeToActionMap[ownProps.relatedObjectType](values.toJS()));
  dispatch(ownProps.onComplete);
};

const getIsLoading = getLoadingSelector(getChangeRequest);

const mapStateToProps = (state, ownProps) => ({
  initialValues: Map({
    [formRequestChangeFields.FILE_IDS]: List(),
    [formRequestChangeFields.RECEIVER]: ownProps.t('eleringAdmin'),
    [formRequestChangeFields.SENDER]: getUserLegalEntityName(state),
    [formRequestChangeFields.RELATED_OBJECT_ID]: ownProps.relatedObjectId,
  }),
  isLoading: getIsLoading(state),
});

export default translate(NAMESPACES.requestChange)(
  connect(mapStateToProps)(
    reduxForm({
      form: FORM_REQUEST_CHANGE,
      onSubmit,
      validate,
    })(RequestChange)
  )
);
