import React from "react";

export function FormDetailsItem({label, value}) {
  return (
    <div className={'ant-form-item'}>
      <label className={'ant-form-item-label'}>{label}:</label>
      <div className={'ant-form-item-control-wrapper'}>
        <div className={'ant-form-item-control'}>
          {value}
        </div>
      </div>
    </div>
  );
}
