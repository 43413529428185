import {handleActions} from 'redux-actions';
import {Map} from 'immutable';
import {
  createAsyncReducer,
  initialAsyncState,
  listInitialAsyncState,
} from '../utils/asyncHelpers';
import {
  LOCK_GOS_CERTIFICATES,
  SELECT_REPORT_ROWS,
} from '../constants/actionTypes/lockingGosCertificatesActionTypes';

const initialState = Map({
  gosLocking: initialAsyncState,
  selectedReportRows: listInitialAsyncState,
});

export default handleActions(
  {
    ...createAsyncReducer(LOCK_GOS_CERTIFICATES, 'gosLocking'),
    ...createAsyncReducer(SELECT_REPORT_ROWS, 'selectedReportRows'),
  },
  initialState
);

export const getSelectedReportRows = state =>
  state.getIn(['lockingGOSCertificate', 'selectedReportRows']);

export const getSelectedReportRowsData = state =>
  state.getIn(['lockingGOSCertificate', 'selectedReportRows', 'data']);

export const getLocking = state =>
  state.getIn(['lockingGOSCertificate', 'gosLocking']);
