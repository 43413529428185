import {useEffect, useState} from "react";
import {Button/*, Modal, Spin*/} from "antd";
import React from "react";
import {
  ParameterConsumptionTypeTable
} from "../../components/ParameterConsumptionTypeTable/ParameterConsumptionTypeTable";
//import {classifiersGeneralChange, classifiersGeneralFetch} from "../../actions/actionHelpers/endpoints";
import {goToClassifiers} from "../../utils/gotoLink";
import {TableActions} from "../../components";
//import {ClassifierGeneralEdit as ClassifierGeneralEditForm} from "../index";
//import {classifierGeneralField, classifierGeneralItemField} from "../../constants/classifier";
//import {mapPropertiesToObject} from "../../utils/classifiersStore";
import {
  ParameterLegalEntityConsumptionTypeTable
} from "../../components/ParameterConsumptionTypeTable/ParameterLegalEntityConsumptionTypeTable";

export function ParameterConsumptionTypeEdit({t, classifierId}) {
  // const [classifierLoading, setClassifierLoading] = useState(true);
  const [legalEntitySubsidyLoading, setLegalEntitySubsidyLoading] = useState(true);

  // const [classifierData, setClassifierData] = useState([]);
  // const [selectedCode, setSelectedCode] = useState(null);

  // const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    // !classifierLoading && setClassifierLoading(true);
    !legalEntitySubsidyLoading && setLegalEntitySubsidyLoading(true);

    // loadClassifierData();
    // loadLegalEntitySubsidyData();
  }, [classifierId]);

  // const loadClassifierData = () => {
  //   classifiersGeneralFetch(code)
  //     .then(response => {
  //       response.data[classifierGeneralField.ITEMS]
  //         .filter(item => item[classifierGeneralItemField.ACTIVE])
  //         .forEach(item => { item[classifierGeneralItemField.PROPERTIES] = mapPropertiesToObject(item[classifierGeneralItemField.PROPERTIES]); });
  //       return setClassifierData(response.data) & setClassifierLoading(false);
  //     })
  //     .catch(error => console.error(error) & setClassifierLoading(false));
  // };
  /*
    const loadLegalEntitySubsidyData = () => {
      //TODO load legal entity data
    };
    */
  // const showModal = () => {
  //   setModalVisible(true);
  // };

  // const hideModal = () => {
  //   setModalVisible(false);
  // };

  // const onChangeClassifier = async (code, data) => {
  //   await classifiersGeneralChange(code, data);
  //   loadClassifierData();
  // };

  // const getInitialValues = () => {
  //   let lang = {};
  //   let properties = {};
  //
  //   if (selectedCode) {
  //     const itemCode = classifierData[classifierGeneralField.ITEMS].find(item => item[classifierGeneralItemField.CODE] === selectedCode);
  //     if (itemCode) {
  //       lang = itemCode[classifierGeneralItemField.LANG];
  //       properties = itemCode[classifierGeneralItemField.PROPERTIES];
  //     }
  //   }
  //
  //   return {
  //     [classifierGeneralItemField.ACTIVE]: true,
  //     [classifierGeneralItemField.CODE]: selectedCode,
  //     [classifierGeneralItemField.LANG]: lang,
  //     [classifierGeneralItemField.PROPERTIES]: properties
  //   }
  // };

  return (
    <React.Fragment>
      <ParameterConsumptionTypeTable
        classifierId={classifierId}
        t={t}
      />
      <ParameterLegalEntityConsumptionTypeTable
        t={t}
      />
      {/*<Spin spinning={classifierLoading}>*/}
      {/*<h1>{t('parameterGridOperatorTitle')}</h1>*/}
      {/*{classifierData && (*/}
      {/*<ParameterConsumptionTypeTable */}
      {/*t={t} */}
      {/*dataSource={classifierData}*/}
      {/*classifierId={classifierId}*/}
      {/*changeClassifier={onChangeClassifier}*/}
      {/*showModal={code => {*/}
      {/*setSelectedCode(code);*/}
      {/*showModal();*/}
      {/*}}*/}
      {/*/>*/}
      {/*)}*/}
      {/*{modalVisible && (*/}
      {/*<Modal*/}
      {/*title={t('modalTitle')}*/}
      {/*visible*/}
      {/*footer={null}*/}
      {/*onCancel={hideModal}*/}
      {/*width={640}*/}
      {/*>*/}
      {/*<ClassifierGeneralEditForm*/}
      {/*initialValues={getInitialValues()}*/}
      {/*classifierCode={classifierId}*/}
      {/*onCancel={hideModal}*/}
      {/*selectedCode={selectedCode}*/}
      {/*onComplete={hideModal}*/}
      {/*isEdit={!!selectedCode}*/}
      {/*t={t}*/}
      {/*/>*/}
      {/*</Modal>*/}
      {/*)}*/}
      {/*<TableActions>*/}
      {/*<Button onClick={goToClassifiers}>{t('buttonBack')}</Button>*/}
      {/*<Button*/}
      {/*type="primary"*/}
      {/*onClick={() => {*/}
      {/*setSelectedCode(null);*/}
      {/*showModal();*/}
      {/*}}*/}
      {/*className="global-margin-left-10"*/}
      {/*>*/}
      {/*{t('buttonValueAdd')}*/}
      {/*</Button>*/}
      {/*</TableActions>*/}
      {/*</Spin>*/}

      {/*<Spin spinning={legalEntitySubsidyLoading}>*/}
      {/*<LegalEntityConsumptionTypeClassifierTable*/}
      {/**/}
      {/*t={t}*/}
      {/*/>*/}
      {/**/}
      {/**/}
      {/**/}
      {/*</Spin>*/}
      <TableActions>
        <Button onClick={goToClassifiers}>{t('buttonBack')}</Button>
      </TableActions>
    </React.Fragment>
  );
}
