import {handleActions} from 'redux-actions';
import {Map} from 'immutable';
import * as actions from '../actions/pureActions/gosCertificateActions';
import {
  createAsyncReducer,
  initialAsyncState,
  listInitialAsyncState,
} from '../utils/asyncHelpers';
import {
  SELECT_TRANSFER_ROWS,
  TRANSFER_GOS_CERTIFICATES,
} from '../constants/actionTypes/transferringCertificatesActionTypes';

const initialState = Map({
  selectedReceiver: null,
  gosTransferring: initialAsyncState,
  selectedTransferRows: listInitialAsyncState
});

export default handleActions(
  {
    ...createAsyncReducer(TRANSFER_GOS_CERTIFICATES, 'gosTransferring'),
    ...createAsyncReducer(SELECT_TRANSFER_ROWS, 'selectedTransferRows'),
    [actions.selectReceiver]: (state, {payload}) =>
      state.merge({selectedReceiver: payload}),
  },
  initialState,
);

export const getSelectedTransferRows = state =>
  state.getIn(['transferringGOSCertificate', 'selectedTransferRows']);

export const getSelectedTransferRowsData = state =>
  state.getIn(['transferringGOSCertificate', 'selectedTransferRows', 'data']);

export const getSelectedReceiver = state =>
  state.getIn(['transferringGOSCertificate', 'selectedReceiver']);

export const getTransferring = state =>
  state.getIn(['transferringGOSCertificate', 'gosTransferring']);
