import {createAsyncAction, normalizeHelper} from "../../utils/asyncHelpers";
import {
  AUCTION_DELETE,
  AUCTION_DETAILS,
  AUCTION_FETCH,
  BID_ON_AUCTION,
  CONFIRM_RESULT,
  HISTORY_AUCTIONS_CREATED,
  HISTORY_AUCTIONS_ENDED,
  HISTORY_AUCTIONS_WON,
  SELECT_TS_AUCTION_ROWS,
  SEND_TO_AUCTION,
  STATISTICS_DETAILS,
  STATISTICS_OVERVIEW_DETAILS,
  TS_BID_DETAILS
} from "../../constants/actionTypes/tsAuctionActionTypes";
import {
  auctionDelete,
  auctionDetailsFetch,
  auctionFetch,
  completeAssignment,
  getTsAuctionHistoryCreated,
  getTsAuctionHistoryEnded,
  getTsAuctionHistoryWon,
  statisticOverviewDetailsFetch,
  statisticsDetailsFetch,
  tradingAccountAuctionOverviewDetailsPost,
  tradingAccountAuctionPost,
  tsAuctionBidDataFetch,
  tsAuctionBidPost
} from "../actionHelpers/endpoints";
import * as Actions from "../pureActions/auctionActions";
import auction from "../../schemas/auction";
import {createAsyncPagingAction} from "../../utils/asyncPagingHelpers";

export const tradingAccountAuctionAction = createAsyncAction(
  SEND_TO_AUCTION,
  tradingAccountAuctionPost
);
export const selectAuctionRows = createAsyncAction(
  SELECT_TS_AUCTION_ROWS,
  tradingAccountAuctionOverviewDetailsPost
);

export const fetchAuctionHistoryCreated = createAsyncPagingAction(
  HISTORY_AUCTIONS_CREATED,
  getTsAuctionHistoryCreated
);

export const fetchAuctionHistoryWon = createAsyncPagingAction(
  HISTORY_AUCTIONS_WON,
  getTsAuctionHistoryWon
);

export const fetchAuctionHistoryEnded = createAsyncPagingAction(
  HISTORY_AUCTIONS_ENDED,
  getTsAuctionHistoryEnded
);

export const fetchBidDetailsById = createAsyncAction(
  TS_BID_DETAILS,
  tsAuctionBidDataFetch
);

export const fetchAuctionForView = createAsyncAction(
  AUCTION_DETAILS,
  auctionDetailsFetch
);

export const fetchStatisticsForView = createAsyncAction(
  STATISTICS_DETAILS,
  statisticsDetailsFetch
);

export const fetchStatisticsOverviewDetails = createAsyncAction(
  STATISTICS_OVERVIEW_DETAILS,
  statisticOverviewDetailsFetch
)

export const bidOnAuction = createAsyncAction(
  BID_ON_AUCTION,
  tsAuctionBidPost
);

export const confirmAction = createAsyncAction(
  CONFIRM_RESULT,
  completeAssignment
);

export const fetch = createAsyncAction(
  AUCTION_FETCH,
  auctionFetch,
  normalizeHelper(auction)
);

export const deleteAction = createAsyncAction(
  AUCTION_DELETE,
  auctionDelete,
);

export const auctionBidResetMaster = () => dispatch => dispatch(Actions.auctionBidResetMaster());
export const auctionDetailResetMaster = () => dispatch => dispatch(Actions.auctionDetailResetMaster());
