import React, {Component} from 'react';
import {Checkbox} from 'antd';
import ComponentWrapper from './ComponentWrapper';
import {checkboxMap} from './componentMappers';

export default class CheckboxField extends Component {
  render() {
    return (
      <ComponentWrapper
        {...this.props}
        mapProps={checkboxMap}
        render={props => <Checkbox {...props}>{props.checkboxText}</Checkbox>}
      />
    );
  }
}
