import React from 'react';
import PropTypes from 'prop-types';
import {Timeline} from 'antd';
import moment from 'moment';
import {FORMAT_DEFAULT} from '../../utils/dates';
import './NotificationList.css';

const notificationDateSorter = (a, b) =>
  moment(a.createdDate, FORMAT_DEFAULT).isBefore(
    moment(b.createdDate, FORMAT_DEFAULT)
  )
    ? 1
    : -1;

class NotificationList extends React.Component {
  static propTypes = {
    list: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
  };

  handleClickView = (e, id) => {
    const {showModal} = this.props;
    e.preventDefault();
    showModal(id);
  };

  render() {
    const {list, t} = this.props;

    return (
      <Timeline className="component-NotificationList">
        {list.sort(notificationDateSorter).map(item => (
          <Timeline.Item key={item.id}>
            <span> [{item.createdDate}]</span>
            <b> {item.title}</b>
            <a
              href="/notification"
              className="component-NotificationList__view-button"
              onClick={e => this.handleClickView(e, item.id)}
            >
              {t('viewButton')}
            </a>
          </Timeline.Item>
        ))}
      </Timeline>
    );
  }
}

export default NotificationList;
