import {auctionFields} from "../../constants/auction";
import {
  translateBiofuelTypeClassificator,
  translateTsFuelTypeClassificator
} from "../../utils/translateHelpers";
import {numberFormatter} from "../../utils/formaters";
import FuelType from "../../constants/classificators/fuelType";

export const getCommonColumns = t => {
  const isKhg = (fuelType) => fuelType === FuelType.KHG;
  return [
    {
      title: t('table.id'),
      dataIndex: auctionFields.ID
    },
    {
      title: t('table.fuelType'),
      dataIndex: auctionFields.FUEL_TYPE,
      render: translateTsFuelTypeClassificator
    },
    {
      title: t('table.fuelGeneration'),
      dataIndex: auctionFields.FUEL_GENERATION,
      render: translateBiofuelTypeClassificator,
    },
    {
      title: t('table.naturalAmountMj'),
      dataIndex: auctionFields.NATURAL_AMOUNT_MJ,
      render: numberFormatter
    },
    {
      title: t('table.calculatedAmountMj'),
      dataIndex: auctionFields.ACCOUNTED_AMOUNT_MJ,
      render: numberFormatter
    },
    {
      title: t('table.ghgCapacity'),
      dataIndex: auctionFields.GHG_CAPACITY,
      render: numberFormatter
    },
    {
      title: t('table.ghgAmount'),
      dataIndex: auctionFields.GHG_AMOUNT,
      render: numberFormatter
    },
    {
      title: t('table.currentBid'),
      dataIndex: auctionFields.CURRENT_BID,
      render: (txId, record) => {
        return !isKhg(record.fuelType) ? record.currentBid?.toFixed(3) : null
      }
    },
    {
      title: t('table.currentBidKhg'),
      dataIndex: auctionFields.CURRENT_BID_KHG,
      render: (txId, record) => {
        return isKhg(record.fuelType) ? record.currentBid?.toFixed(3) : null
      }
    },
    {
      title: t('table.transactionCost'),
      dataIndex: auctionFields.TRANSACTION_COST,
      render: (txId, record) => {
        return record[auctionFields.TRANSACTION_COST] ? record[auctionFields.TRANSACTION_COST].toFixed(2) : null;
      }
    },
    {
      title: t('table.endDateTime'),
      dataIndex: auctionFields.END_DATE,
    },
  ];
};

export const calculateTransactionCost = (data, unitPrice, isKhg) => {
  return isKhg
    ? -(data.ghgAmount * unitPrice)
    : data.accountedAmountMj * unitPrice * 0.001;
}
